import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import formik from './formik';
import Message from '../../../shared/message/Message';
import { requestPasswordChange } from '../../services/auth';
import '../loginForm/loginForm.css';

const ForgotPasswordForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const navigate = useNavigate();
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors
  } = formik();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      await handleSendEmail();
    }
  };

  const handleSendEmail = async () => {
    setStatus('loading');
    const res = await requestPasswordChange(email);
    setStatus(res.ok ? 'success' : 'error');
  };

  const { email } = values;

  if (status !== 'idle') {
    return (
      <div className="login-container container-fluid">
        {status === 'loading' && (
          <Message
            type="loading"
            title="Cargando..."
            content="Esta operación podría demorar algunos minutos. Por favor, espere."
          />
        )}
        {status === 'success' && (
          <Message
            type="success"
            img="/assets/img/alerts/mail-black.webp"
            title="Solicitud enviada"
            content="¿No recibiste ningún correo?"
            contentConfirmText="Enviar de nuevo"
            contentOnConfirm={handleSendEmail}
            message="Te hemos enviado un correo electrónico con las instrucciones
            para reestablecer tu contraseña."
            onConfirm={() => navigate('/login')}
            confirmText="Cerrar"
          />
        )}
        {status === 'error' && (
          <Message
            type="error"
            title="Ha ocurrido un error"
            content="Vuelve a intenarlo más tarde"
            onConfirm={() => setStatus('idle')}
            confirmText="Volver"
          />
        )}
      </div>
    );
  }

  return (
    <div className="login-container container-fluid">
      <Row className="d-flex justify-content-center align-items-center h-100">
        <Col className="img-fluid">
          <Row className="justify-content-center align-items-end  h-100">
            <Col md={3} className="content-logo">
              <img src="/assets/img/logo-mdp-v-white.webp" alt="" className="logo-inicio" />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-center align-items-center">
            <Col md={10}>
              <div className="form-body">
                <form className="mt-m form-body" onSubmit={handleSubmit} noValidate>
                  <h2>Recuperar contraseña</h2>
                  <br />
                  <p>
                    Introduce la dirección de correo electrónico que
                    utilizas para ingresar a tu cuenta.
                  </p>
                  <div>
                    <label htmlFor="sample" className="label mt-m  input-content">
                      Correo electrónico
                      <input
                        id="sample"
                        type="email"
                        className={`input ${submitted && errors.email && 'has-error'}`}
                        value={email}
                        placeholder="Email"
                        onChange={handleChange('email')}
                      />
                      {submitted && errors.email && (
                        <small className="caption-error">
                          {errors.email}
                        </small>
                      )}
                    </label>
                  </div>
                  <div className="mt-m">
                    <Row className="justify-content-md-center align-items-center">
                      <Col>
                        <button className="button" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
                          Continuar
                        </button>
                      </Col>
                      <Col className="align-middle">
                        <Link to="/login" className="input-label-float-right button-tertiary ">
                          Cancelar
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPasswordForm;
