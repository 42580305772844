/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { FEditDataP } from '../../formik/formik';

const FrmDataBeneficiaries = ({
  setDataForm, defVal, closeFormEvent
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FEditDataP();
  const closeForm = () => {
    closeFormEvent();
  };

  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    fechaNacimiento,
    parentesco,
    telefono,
    edad,
  } = values;
  const [submitted, setSubmitted] = useState(false);
  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth()) || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };
  useEffect(() => {
    if (fechaNacimiento) {
      const tempAge = age(new Date(fechaNacimiento));
      setFieldValue('edad', tempAge);
    } else {
      setFieldValue('edad', 0);
    }
  }, [fechaNacimiento]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      setSubmitted(false);
      setDataForm(values);
    } else {
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    if (defVal) {
      setFieldValue('titulo', defVal.titulo);
      setFieldValue('nombre', defVal.nombre);
      setFieldValue('apellidoPaterno', defVal.apellidoPaterno);
      setFieldValue('apellidoMaterno', defVal.apellidoMaterno);
      setFieldValue('fechaNacimiento', defVal.fechaNacimiento);
      setFieldValue('parentesco', defVal.parentesco);
      setFieldValue('telefono', defVal.telefono);
      setFieldValue('edad', defVal.edad);
    }
  }, [defVal]);

  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-xxl">
            <h2>Datos pesonales</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Nombre(s)
                    <input
                      onChange={handleChange('nombre')}
                      value={nombre}
                      id="nombre"
                      type="text"
                      className={`input ${submitted && errors.nombre && 'has-error'}`}
                    />
                    {submitted && errors.nombre && <small className="caption-error">{errors.nombre}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoPaterno" className="label mt-m">
                    Primer apellido
                    <input
                      id="apellidoPaterno"
                      type="text"
                      className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                      onChange={handleChange('apellidoPaterno')}
                      value={apellidoPaterno}
                    />
                    {
                      submitted && errors.apellidoPaterno && <small className="caption-error">{errors.apellidoPaterno}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoMaterno" className="label mt-m">
                    Segundo apellido
                    <input
                      id="apellidoMaterno"
                      type="text"
                      className="input"
                      onChange={handleChange('apellidoMaterno')}
                      value={apellidoMaterno}
                    />
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fechaNacimiento" className="label mt-m">
                    Fecha de nacimiento
                    <input
                      id="fechaNacimiento"
                      type="date"
                      className={`input input-date ${submitted && errors.fechaNacimiento && 'has-error'}`}
                      onChange={handleChange('fechaNacimiento')}
                      value={fechaNacimiento}
                    />
                    {
                      submitted && errors.fechaNacimiento && <small className="caption-error">{errors.fechaNacimiento}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="parentesco" className="label mt-m">
                    Parentesco
                    <input
                      id="parentesco"
                      type="text"
                      className="input"
                      value={parentesco}
                      onChange={handleChange('parentesco')}
                    />
                    {
                      submitted && errors.parentesco && <small className="caption-error">{errors.parentesco}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="telefono" className="label mt-m">
                    Teléfono
                    <input
                      id="telefono"
                      type="text"
                      placeholder="10 dígitos"
                      className={`input ${submitted && errors.telefono && 'has-error'}`}
                      onChange={handleChange('telefono')}
                      value={telefono}
                    />
                    {
                      submitted && errors.telefono && <small className="caption-error">{errors.telefono}</small>
                    }
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <div className="row mt-m">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <button className="input-label-float-right button-tertiary" onClick={closeForm}>
                  Cancelar
                </button>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <button className="button" type="submit">
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default FrmDataBeneficiaries;
