import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    apellidoMaterno: '',
    apellidoPaterno: '',
    rfc: '',
    fechaNacimiento: '',
    estadoCivil: '',
    ocupacion: '',
    genero: 'masculino',
    telefono: '',
    email: '',
    edad: 0,
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    etreCalles: '',
    direccion: '',
    role: 'user',
    referencias: [{
      nombre: '',
      apellidoMaterno: '',
      apellidoPaterno: '',
      telefono: '',
      email: '',
      cp: '',
      estado: '',
      ciudad: '',
      colonia: '',
      direccion: '',
    },
    {
      nombre: '',
      apellidoMaterno: '',
      apellidoPaterno: '',
      telefono: '',
      email: '',
      cp: '',
      estado: '',
      ciudad: '',
      colonia: '',
      direccion: '',
    }]
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre del cliente es requerido'),
    apellidoPaterno: Yup.string()
      .required('Ingresa el primer apellido'),
    fechaNacimiento: Yup.string()
      .required('Ingresa la fecha de nacimiento'),
    telefono: Yup.string()
      .required('El teléfono es requerido')
      .matches(/^[0-9]+$/, 'No es un número válido')
      .max(10, 'El número debe tener máximo 10 dígitos')
      .min(10, 'El número debe tener máximo 10 dígitos'),
    email: Yup.string()
      .email('La dirección de correo no es válida')
      .required('El correo electrónico es requerido'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('Selecciona un estado'),
    ciudad: Yup.string()
      .required('Selecciona una localidad o municipio'),
    colonia: Yup.string()
      .required('La colonia es requerida'),
    direccion: Yup.string()
      .required('La calle es requerida'),
    role: Yup.string()
      .required('Selecciona un rol'),
    referencias: Yup.array().of(
      Yup.object().shape({
        nombre: Yup.string()
          .required('El nombre del contacto es requerido'),
        apellidoPaterno: Yup.string()
          .required('Ingresa el primer apellido'),
        telefono: Yup.string()
          .required('El teléfono es requerido')
          .matches(/^[0-9]+$/, 'No es un número válido')
          .max(10, 'El número debe tener máximo 10 dígitos')
          .min(10, 'El número debe tener máximo 10 dígitos'),
        email: Yup.string()
          .email('La dirección de correo no es válida')
          .required('El correo electrónico es requerido'),
        cp: Yup.string()
          .required('El Código postal es requerido')
          .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
          .min(5, 'El código postal debe tener mínimo 5 caracteres')
          .max(5, 'El código postal debe tener maximo 5 caracteres'),
        estado: Yup.string()
          .required('Selecciona un estado'),
        ciudad: Yup.string()
          .required('Selecciona una localidad o municipio'),
        colonia: Yup.string()
          .required('La colonia es requerida'),
        direccion: Yup.string()
          .required('La calle es requerida'),
      })
    )
  })
});
