/* eslint-disable no-unused-vars */
import parse from 'html-react-parser';
import Styles from './message.module.css';

const MessageInfo = ({
  message, type, icon
}) => {
  const checkType = () => {
    if (type) {
      switch (type) {
        case 'default':
          return Styles.default;
        case 'error':
          return Styles.error;
        case 'warning':
          return Styles.warning;
        default:
          break;
      }
    }
    return Styles.default;
  };
  const checkContentType = () => {
    if (type) {
      switch (type) {
        case 'default':
          return Styles.defaultC;
        case 'error':
          return Styles.errorC;
        case 'warning':
          return Styles.warningC;
        default:
          break;
      }
    }
    return Styles.defaultC;
  };
  return (
    <div className={`container-fluid ${checkContentType()}`}>
      <div className="row">
        <div className="col-2  col-sm-2 content-logo">
          <i className={`${Styles.Micon} ${checkType()}`}>{icon || parse('&#59668;')}</i>
        </div>
        <div className="col-10 col-sm-10 ">
          <p className={Styles.text_m}>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default MessageInfo;
