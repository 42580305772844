import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ruta que puede ser visitada por usuarios sin sesión activa
const PublicRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      {user._id
        ? <Navigate to="/" />
        : children}
    </>
  );
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
