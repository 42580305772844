import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import ListNotifications from './components/listNotifications';
import NoNotifications from './components/noNotifications';
import { markAsRead } from './services/index';
import './menu.css';
import { useMyNotifications } from '../notifications/hooks/queries';

const GMenuForm = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuProf, setOpenMenuProf] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [openNotif, setOpenNotif] = useState(false);

  const perPage = 5;

  const menuAction = () => {
    setOpenMenu(!openMenu);
  };
  const menuActionProf = () => {
    setOpenMenuProf(!openMenuProf);
  };

  const openCompNotif = () => {
    setOpenNotif(!openNotif);
  };

  const { data } = useMyNotifications({
    page: 1,
    pagination: perPage,
    order: 'asc',
    refetchInterval: 60 * 5000,
  });

  const { data: notifications, noRead, total } = data;
  const notFound = total === 0;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const goProfilePage = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    navigate(`/profile/${user._id}`);
  };

  const checNavigation = (url = []) => {
    if (url.includes(location.pathname)) {
      return true;
    }
    let tempPath = location.pathname.split('/');
    tempPath = `/${tempPath[1]}`;
    if (url.includes(tempPath)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const tempUser = JSON.parse(localStorage.getItem('user'));
    setUserInfo(tempUser || null);
  }, []);

  return (
    <>
      <div className="menu-top-padding" />
      <div className="menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 col-sm-2 col-md-2">
              <button onClick={menuAction} type="button" className="button-icon btn-menu">{openMenu ? <>&#59655;</> : <>&#59660;</>}</button>
            </div>
            <div className="col-9 col-sm-6 col-md-6 img-ccontent-menu">
              <img src="/assets/img/ogo-mdp-h-green.webp" alt="Morada de La Paz" className="img-menu" />
            </div>
            <div className="col-12 col-sm-12 col-md-4 p-r">
              {openNotif && !notFound && (
                <ListNotifications
                  listNotif={notifications}
                  markAsRead={markAsRead}
                />
              )}
              {openNotif && notFound && <NoNotifications />}
              <div className="content-prof">
                <button className="notifications" onClick={openCompNotif}>
                  {openNotif ? (parse('&#59684;')) : (parse('&#59680;'))}
                  {noRead > 0 && !openNotif && (<small><label>{noRead}</label></small>)}
                </button>
                {userInfo && userInfo.imagen_perfil && userInfo.imagen_perfil !== '' && <img src={userInfo.imagen_perfil} alt="Perfil" className="img-profile" />}
                {userInfo && userInfo.imagen_perfil && userInfo.imagen_perfil === '' && <img className="img-profile" src="/assets/img/def-user.webp" alt="Perfil" />}
                {userInfo && !userInfo.imagen_perfil && <img className="img-profile" src="/assets/img/def-user.webp" alt="Perfil" />}
                <label className="username">{userInfo ? userInfo.nombre : 'user'}</label>
                <button className="btn-profile" onClick={menuActionProf}><i className="button-icon">&#59657;</i></button>
              </div>
            </div>
          </div>
        </div>
        {openMenuProf && (
          <div className="prof-menu">
            <button className="button light" type="button" onClick={goProfilePage}>Mi Perfil</button>
            <button className="button" type="button" onClick={closeSession}>Cerrar sesi&oacute;n</button>
          </div>
        )}
      </div>
      <div className={openMenu ? ('menu-left fadeIn') : ('menu-left fadeOut')}>
        {((checkPermisionRoute('Contratos', 'listarContratos')
          || checkPermisionRoute('Clientes', 'listarClientes')
          || checkPermisionRoute('Prospectos', 'listarProspectos')
          || checkPermisionRoute('Productos', 'listarProductos')
          || checkPermisionRoute('Cupones', 'listarCupones')
          || checkPermisionRoute('Descuentos', 'listarDescuentos'))) && (
            <div className="container-fluid">
              <label className="subt-menu-l">
                Comercial
              </label>
              {checkPermisionRoute('Contratos', 'listarContratos') && (
                <div className="menu-l-content">
                  <Link to="/contracts" className="link-menu">
                    <div className={checNavigation(['/contracts']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/contracts']) ? <>&#59707;</> : <>&#59704;</>}</label>
                      <label>Contratos</label>
                    </div>
                  </Link>
                </div>
              )}
              {checkPermisionRoute('Clientes', 'listarClientes') && (
                <div className="menu-l-content">
                  <Link to="/clients" className="link-menu">
                    <div className={checNavigation(['/clients']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/clients']) ? <>&#59664;</> : <>&#59650;</>}</label>
                      <label>Clientes</label>
                    </div>
                  </Link>
                </div>
              )}
              {checkPermisionRoute('Prospectos', 'listarProspectos') && (
                <div className="menu-l-content">
                  <Link to="/prospects" className="link-menu">
                    <div className={checNavigation(['/prospects', '/add-prospects']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/prospects', '/add-prospects']) ? <>&#59677;</> : <>&#59676;</>}</label>
                      <label>Prospectos</label>
                    </div>
                  </Link>
                </div>
              )}
              {checkPermisionRoute('Productos', 'listarProductos') && (
                <div className="menu-l-content">
                  <Link to="/products" className="link-menu">
                    <div className={checNavigation(['/products']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/products']) ? <>&#59692;</> : <>&#59691;</>}</label>
                      <label>Productos</label>
                    </div>
                  </Link>
                </div>
              )}
              {((checkPermisionRoute('Cupones', 'listarCupones') || checkPermisionRoute('Descuentos', 'listarDescuentos'))) && (
                <div className="menu-l-content">
                  <Link to="/promotions" className="link-menu">
                    <div className={checNavigation(['/promotions']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/promotions']) ? <>&#59706;</> : <>&#59703;</>}</label>
                      <label>Promociones</label>
                    </div>
                  </Link>
                </div>
              )}
            </div>
        )}
        {(checkPermisionRoute('Empleados', 'listarEmpleados')
          || checkPermisionRoute('Sucursales', 'listarSucursales')) && (
            <div className="container-fluid">
              <label className="subt-menu-l">
                Corporativo
              </label>
              {checkPermisionRoute('Empleados', 'listarEmpleados') && (
                <div className="menu-l-content">
                  <Link to="/contributors" className="link-menu">
                    <div className={checNavigation(['/contributors']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/contributors']) ? <>&#59665;</> : <>&#59651;</>}</label>
                      <label>Empleados</label>
                    </div>
                  </Link>
                </div>
              )}
              {checkPermisionRoute('Sucursales', 'listarSucursales') && (
                <div className="menu-l-content">
                  <Link to="/branchOffice" className="link-menu">
                    <div className={checNavigation(['/branchOffice']) ? 'active' : ''}>
                      <label className="icon-menu">{checNavigation(['/branchOffice']) ? <>&#59679;</> : <>&#59679;</>}</label>
                      <label>Sucursales</label>
                    </div>
                  </Link>
                </div>
              )}
            </div>
        )}
      </div>
    </>
  );
};

export default GMenuForm;
