import React from 'react';
import { formatMoney, styleMoney } from '../../../../helpers/currency';
import ProfileDefComponent from '../../../shared/profileDef';
import { monthDayYearFormat } from '../../../../helpers/dates';

const PriceAndAvailiability = ({ allowEdit, data, openEditForm }) => (
  <>
    <div className="d-flex justify-content-between mt-xxl">
      <h2>Precio y disponibilidad</h2>
      {allowEdit && (
        <button
          className="button-secondary"
          onClick={() => openEditForm('PriceAndAvailiability')}
        >
          Actualizar
          <span className="button-icon pencil-line" />
        </button>
      )}
    </div>
    <div className="row mt-5">
      <div className="col-12 col-lg-6">
        <label className="datos-title">Stock</label>
        <span className="datos-info">{data.stock}</span>
      </div>
      <div className="col-12 col-lg-6">
        <label className="datos-title">De venta en</label>
        <span className="datos-info text-capitalize">{data.sucursal.ciudad}</span>
      </div>
      <div className="col-12 col-lg-6">
        <label className="datos-title">Precio de uso inmediato</label>
        {data.precio ? (
          <span className="datos-info text-money">
            {styleMoney(formatMoney.format(data.precio))}
          </span>
        ) : <span className="datos-info">N/A</span>}
      </div>
      <div className="col-12 col-lg-6">
        <label className="datos-title">Precio a previsión</label>
        {data.precioPrevision ? (
          <span className="datos-info text-money">
            {styleMoney(formatMoney.format(data.precioPrevision))}
          </span>
        ) : <span className="datos-info">N/A</span>}
      </div>
    </div>

    <hr />
    <div className="row">
      <div className="col-12">
        <div className="d-flex justify-content-between mt-xxl">
          <h2>Sistema</h2>
        </div>
        <div className="row mt-4">
          {data.creadoPor && (
            <div className="col-12 col-md-6">
              <label className="datos-title">Creado por</label>
              <div className="d-flex align-items-center mb-5">
                <ProfileDefComponent
                  name="Leonardo Rodríguez"
                  size=""
                  imgProf={data.creadoPor.imagen_perfil || '/assets/img/def-user.webp'}
                />
                <div className="ms-2">
                  <label className="datos-info text-capitalize m-0">{data.creadoPor.nombre}</label>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 col-lg-6">
            <label className="datos-title">Fecha de creación</label>
            <span className="datos-info">{monthDayYearFormat(data.createdAt)}</span>
          </div>
        </div>
        {data.borradoPor?.fecha && (
          <div className="row">
            <div className="col-12 col-md-6">
              <label className="datos-title">Desactivado por</label>
              <div className="d-flex align-items-center mb-5">
                <ProfileDefComponent
                  name="Leonardo Rodríguez"
                  size=""
                  imgProf={data.borradoPor.imagen_perfil || '/assets/img/def-user.webp'}
                />
                <div className="ms-2">
                  <label className="datos-info text-capitalize m-0">{data.borradoPor.nombre}</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <label className="datos-title">Fecha</label>
              <span className="datos-info">
                {monthDayYearFormat(data.borradoPor.fecha)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  </>
);

export default PriceAndAvailiability;
