export const monthDayYearFormat = (d) => {
  try {
    if (!d) {
      return '-';
    }
    const date = new Date(d);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    const month = date.toLocaleString('es-mx', {
      month: 'long',
    });
    const day = date.getDate();
    return `${day < 10 ? '0' : ''}${day} de ${month} de ${date.getFullYear()}`;
  } catch (_error) {
    return '-';
  }
};
