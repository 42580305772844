import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import defaultImg from '../../../../images/imageProDef.png';

const ProductImage = ({
  allowEdit, coverImg, imagesArray, openEditForm,
}) => {
  const [index, setIndex] = useState(1);

  const handleSelect = (idx) => {
    setIndex(idx + 1);
  };

  const hasCover = coverImg && coverImg.url.includes('http');

  return (
    <>
      <div className="d-flex justify-content-between mt-xxl">
        <h2>Portada y galería</h2>
        {allowEdit && (
          <button
            className="button-secondary"
            onClick={() => openEditForm('ProductImage')}
          >
            Actualizar
            <span className="button-icon pencil-line" />
          </button>
        )}
      </div>

      <div className="row mt-5">
        <div className="carousel-container">
          <div className="carousel-pagination">
            <p className="small-bold">
              {
                hasCover ? `${index}/${imagesArray.length + 1}` : 0
              }
            </p>
          </div>
          <Carousel
            slide
            interval={null}
            variant="dark"
            controls={!!hasCover && imagesArray.length > 0}
            onSelect={handleSelect}
            indicators={false}
          >
            <Carousel.Item>
              <img
                className="carousel-image"
                src={hasCover ? coverImg.url : defaultImg}
                alt="Sin imagen"
              />
            </Carousel.Item>
            {hasCover && imagesArray.map((uri) => (
              <Carousel.Item key={uri}>
                <img
                  className="carousel-image"
                  src={uri}
                  alt="Sin imagen"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ProductImage;
