import { useState } from 'react';
import { useNavigate } from 'react-router';
import parse from 'html-react-parser';
import '../../emple.css';
import '../../StyleInputs.css';
import FrmDatosEmpleado from './FrmDatosEmpleado';
import FrmDomicilio from './FrmDomicilio';
import FrmRolEmpleado from './FrmRolEmpleado';
import FrmDocsEmpleado from './FrmDocsEmpleado';
import { actualizarEmpleado, postImagen, registrarEmpleado } from '../../services';
import Message from '../../../shared/message/Message';

const FormularioEmpleados = () => {
  const [response, setResponse] = useState('idle');
  const [errorMsg, setErrorMsg] = useState('');
  const [step1, setStep1] = useState('&#59666;');
  const [step2, setStep2] = useState('&#59652;');
  const [step3, setStep3] = useState('&#59652;');
  const [step4, setStep4] = useState('&#59652;');

  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [stateStep4, setStateStep4] = useState('disabled');
  const [activeStep, setActiveStep] = useState(1);

  const [dataForm1, setData1] = useState(null);
  const [dataForm2, setData2] = useState(null);
  const [dataForm3, setData3] = useState(null);
  const [dataForm4, setData4] = useState(null);

  const navigate = useNavigate();

  const nextStep = () => {
    let tempActS = activeStep;
    // console.log(tempActS);
    if (tempActS === 1) {
      setStateStep1('complete');
      setStateStep2('active');
      setStep1('&#59654;');
      setStep2('&#59666;');
    }
    if (tempActS === 2) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('active');
      setStep2('&#59654;');
      setStep3('&#59666;');
    }
    if (tempActS === 3) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('complete');
      setStateStep4('active');
      setStep3('&#59654;');
      setStep4('&#59666;');
    }
    setActiveStep(tempActS += 1);
  };

  const prevStep = () => {
    let tempActS = activeStep;
    // console.log(tempActS);
    if (tempActS === 2) {
      setStateStep1('active');
      setStateStep2('disabled');
      setStateStep3('disabled');
      setStateStep4('disabled');
      setStep1('&#59654;');
      setStep2('&#59652;');
      setStep3('&#59652;');
      setStep4('&#59652;');
    }
    if (tempActS === 3) {
      setStateStep1('complete');
      setStateStep2('active');
      setStateStep3('disabled');
      setStateStep4('disabled');
      setStep1('&#59654;');
      setStep2('&#59666;');
      setStep3('&#59652;');
      setStep4('&#59652;');
    }
    if (tempActS === 4) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('active');
      setStateStep4('disabled');
      setStep1('&#59654;');
      setStep2('&#59654;');
      setStep3('&#59666;');
      setStep4('&#59652;');
    }
    setActiveStep(tempActS -= 1);
  };

  const setDataForm1 = async (data) => {
    await setData1(data);
  };
  const setDataForm2 = async (data) => {
    await setData2(data);
  };
  const setDataForm3 = async (data) => {
    await setData3(data);
  };
  const setDataForm4 = async (data) => {
    await setData4(data);
    createEmpleado(data);
  };

  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth())
      || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };

  const createEmpleado = async (dataF3) => {
    setResponse('loading');
    const tempAge = age(new Date(dataForm1.fechaN));
    const dateNow = new Date();
    const empleado = {
      nombre: dataForm1.nombre || '',
      apellidoPaterno: dataForm1.apellidoP || '',
      apellidoMaterno: dataForm1.apellidoM || '',
      email: dataForm1.email || '',
      sexo: dataForm1.genero || '',
      telefono: dataForm1.telefono.toString() || '',
      fecha_nacimiento: dataForm1.fechaN || '',
      estado_civil: dataForm1.estadoc || '',
      rfc: dataForm1.RFC || '',
      // direccion: dataForm2.calle || '',
      colonia: dataForm2.colonia || '',
      ciudad: dataForm2.municipio || '',
      estado: dataForm2.estado || '',
      cp: parseInt(dataForm2.zipCode, 10) || '',
      direccion: `${dataForm2.calle}# ${dataForm2.noExt}` || '',
      entreCalle: dataForm2.entreCalle || '',
      puesto: dataForm3.puesto || '',
      edad: tempAge || 0,
      /* antecedentes: {
        url_antecedentes: dataF3.fileant.name,
        fecha_upd: dateNow.toString(),
      },
      curriculum: {
        url_curriculum: dataF3.fileCV.name,
        fecha_upd: dateNow.toString(),
      },
      comprobante_domicilio: {
        url_comprobante_domicilio: dataF3.fileCD.name,
        fecha_upd: dateNow.toString(),
      },
      cif: {
        url_cif: dataF3.fileCIF.name,
        fecha_upd: dateNow.toString(),
      },
      ine: {
        url_ine: dataF3.fileINE.name,
        fecha_upd: dateNow.toString(),
      },
      licencia: {
        url_licencia: 'a',
        fecha_upd: dateNow.toString(),
      },
      /* solicitudempleo: {
        url_solicitudempleo: 'a',
        fecha_upd: dateNow.toString(),
      }, */
      password: '123456789',
      role: dataForm3.rol,
      sucursales: {
        idSucursal: dataForm3.sucursal
      }
    };
    const resEmpleado = await registrarEmpleado(empleado);
    if (resEmpleado._id) {
      const idEmpleado = resEmpleado._id;
      let imgant = null;
      if (dataF3.fileant) {
        imgant = await postImagen(dataF3.fileant, idEmpleado);
      }
      // console.log(imgant);
      const pathant = imgant?.file || '-';

      let imgacd = null;
      if (dataF3.fileCD) {
        imgacd = await postImagen(dataF3.fileCD, idEmpleado);
      }
      // console.log(imgacd);
      const pathcd = imgacd?.file || '-';

      let imgacv = null;
      if (dataF3.fileCV) {
        imgacv = await postImagen(dataF3.fileCV, idEmpleado);
      }
      // console.log(imgacv);
      const pathcv = imgacv?.file || '-';

      let imgcif = null;
      if (dataF3.fileCIF) {
        imgcif = await postImagen(dataF3.fileCIF, idEmpleado);
      }
      // console.log(imgcif);
      const pathcif = imgcif?.file || '-';

      let imgine = null;
      if (dataF3.fileINE) {
        imgine = await postImagen(dataF3.fileINE, idEmpleado);
      }
      // console.log(imgine);
      const pathine = imgine?.file || '-';

      let imgLic = null;
      if (dataF3.fileLicencia) {
        imgLic = await postImagen(dataF3.fileLicencia, idEmpleado);
      }
      // console.log(imgLic);
      const pathLic = imgLic?.file || '-';

      /* const imgSol = await postImagen(dataF3.solicitudEmpleo, idEmpleado);
      console.log(imgSol);
      const pathSol = imgine.file; */

      let todayUp = `${dateNow.getUTCFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`;
      // console.log(todayUp);

      const empleadoActualizado = {
        antecedentes: {
          url_antecedentes: pathant,
          fecha_upd: todayUp,
        },
        curriculum: {
          url_curriculum: pathcv,
          fecha_upd: todayUp,
        },
        comprobante_domicilio: {
          url_comprobante_domicilio: pathcd,
          fecha_upd: todayUp,
        },
        cif: {
          url_cif: pathcif,
          fecha_upd: todayUp,
        },
        ine: {
          url_ine: pathine,
          fecha_upd: todayUp,
        },
        licencia: {
          url_licencia: pathLic,
          fecha_upd: todayUp,
        }
      };
      // eslint-disable-next-line max-len
      const resEmpleadoActualizado = await actualizarEmpleado(idEmpleado, empleadoActualizado);
      // console.log(resEmpleadoActualizado);
      if (resEmpleadoActualizado) {
        setResponse('success');
      }
    } else {
      const { errors: { message } } = resEmpleado;
      setResponse('error');
      if (message.includes('duplicate key')) {
        setErrorMsg('El correo electrónico ya está registrado con otro empleado.');
      } else {
        setErrorMsg('Vuelve a intentarlo más tarde');
      }
    }
  };

  if (response !== 'idle') {
    return (
      <div className="login-container container-fluid">
        {response === 'loading' && (
          <Message
            type="loading"
            title="Cargando..."
            subtitle="Esta operación podría demorar algunos minutos. Por favor, espere."
          />
        )}
        {response === 'success' && (
          <Message
            type="success"
            title="Empleado registrado"
            message="El empleado se ha registrado exitosamente."
            onConfirm={() => navigate('/contributors')}
            confirmText="Salir"
          />
        )}
        {response === 'error' && (
          <Message
            type="error"
            title="Ha ocurrido un error"
            message={errorMsg}
            onConfirm={() => setResponse('idle')}
            confirmText="Volver"
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-12">
          <div className="row">
            <div className="col-12 col-md-3">
              <i className="step-prosp-icon">{parse(step1)}</i>
              <label className="step-prosp small-bold">Personal</label>
            </div>
            <div className={`col-12 col-md-3 ${stateStep2}`}>
              <i className="step-prosp-icon">{parse(step2)}</i>
              <label className="step-prosp small-bold">Domicilio</label>
            </div>
            <div className={`col-12 col-md-3 ${stateStep3}`}>
              <i className="step-prosp-icon">{parse(step3)}</i>
              <label className="step-prosp small-bold">Laboral</label>
            </div>
            <div className={`col-12 col-md-3 ${stateStep4}`}>
              <i className="step-prosp-icon">{parse(step4)}</i>
              <label className="step-prosp small-bold">Documentación</label>
            </div>
          </div>
          <div className="col-12">
            {stateStep1 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Datos personales</h2>
                </div>
                <FrmDatosEmpleado
                  setDataForm={setDataForm1}
                  nextStep={nextStep}
                  defVal={dataForm1}
                />
              </div>
            )}
            {stateStep2 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Domicilio</h2>
                </div>
                <FrmDomicilio
                  setDataForm={setDataForm2}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  defVal={dataForm2}
                />
              </div>
            )}
            {stateStep3 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Datos del usuario</h2>
                </div>
                <FrmRolEmpleado
                  setDataForm={setDataForm3}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  defVal={dataForm3}
                />
              </div>
            )}
            {stateStep4 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Datos del usuario</h2>
                </div>
                <FrmDocsEmpleado
                  dataForm={dataForm4}
                  setDataForm={setDataForm4}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  defVal={dataForm4}
                  typeEmployee={dataForm3}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormularioEmpleados;
