/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

const FormStept1p5 = ({
  cambiarOpcion, opcionAnterior, saveData, dataP, defDataP
}) => {
  const [fileINE, setFileINE] = useState(null);
  const [fileAdds, setFileAdds] = useState(null);
  const [errorFileINE, setErrorFileINE] = useState(null);
  const [errorFileAdds, setErrorFileAdds] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const getFileINE = async (e, _typeFile) => {
    if (e.target.files) {
      setFileINE(e.target.files[0]);
      setErrorFileINE(null);
    } else {
      setFileINE(null);
      setErrorFileINE('El INE es requerido.');
    }
  };
  const getFileAdds = async (e, _typeFile) => {
    if (e.target.files) {
      setFileAdds(e.target.files[0]);
      setErrorFileAdds(null);
    } else {
      setFileAdds(null);
      setErrorFileAdds('El Comprobante de Domicilio es requerido.');
    }
  };

  const handleSubmit = () => {
    if (isValid) {
      let tempData = {
        fileINE,
        fileAdds
      };
      saveData(tempData);
      cambiarOpcion();
    } else {
      window.scrollTo(0, 0);
      if (!fileINE) {
        setErrorFileINE('El INE es requerido.');
      }
      if (!fileAdds) {
        setErrorFileAdds('El Comprobante de Domicilio es requerido.');
      }
    }
  };
  useEffect(() => {
    if (defDataP) {
      setFileINE(defDataP.fileINE);
      setFileAdds(defDataP.fileAdds);
    }
  }, [defDataP]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (fileINE && fileAdds) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [fileINE, fileAdds]);
  return (
    <div className="container">
      <div className="mt-xxl">
        <h2>Documentos del cliente</h2>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="col-12">
            <label htmlFor="fileINE" className="label mt-m ">
              INE
            </label>
            <label htmlFor="fileINE" className="label mt-m ">
              Los Tipo de Archivos admitidos son JPG, PNG y PDF
              <input
                id="fileINE"
                className={`input ${errorFileINE && 'input has-error fileant'}`}
                type="file"
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                onChange={(e) => getFileINE(e, 'fileINE')}
                hidden
              />
            </label>
            <button
              className="botonsubida button"
              onClick={() => document.querySelector('#fileINE').click()}
            >
              Subir Archivo&nbsp;&nbsp;
              <i className="btn-icon">&#59661;</i>
            </button>
            <br />
            {errorFileINE && <small className="caption-error">{errorFileINE}</small>}
            {fileINE && fileINE.name && (
              <div className="subido">
                {fileINE.name}
                {' '}
                <i className="btn-icon">&#59662;</i>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <div className="col-12">
            <label htmlFor="fileAdds" className="label mt-m ">
              Comprobante de domicilio
            </label>
            <label htmlFor="fileAdds" className="label mt-m ">
              Los Tipo de Archivos admitidos son JPG, PNG y PDF
              <input
                id="fileAdds"
                className={`input ${errorFileAdds && 'input has-error fileant'}`}
                type="file"
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                onChange={(e) => getFileAdds(e, 'fileAdds')}
                hidden
              />
            </label>
            <button
              className="botonsubida button"
              onClick={() => document.querySelector('#fileAdds').click()}
            >
              Subir Archivo&nbsp;&nbsp;
              <i className="btn-icon">&#59661;</i>
            </button>
            <br />
            {errorFileAdds && <small className="caption-error">{errorFileAdds}</small>}
            {fileAdds && fileAdds.name && (
              <div className="subido">
                {fileAdds.name}
                {' '}
                <i className="btn-icon">&#59662;</i>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-xxl">
        <div className="row">
          <div className="col-12 col-md-3">
            <button className="button-secondary mt-m" onClick={() => opcionAnterior()}>
              Regresar
            </button>
          </div>
          <div className="col-12 col-md-5">
            <button className="button mt-m" type="submit" onClick={() => handleSubmit()}>
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormStept1p5;
