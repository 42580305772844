import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import ProfileDefComponent from '../../../shared/profileDef';
import { monthDayYearFormat } from '../../../../helpers/dates';

const ListContractComponent = ({ data }) => {
  const navigate = useNavigate();
  const redirectContractClient = (idContract) => {
    navigate(`/clients/contract-view/${idContract}`);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const styleMoney = (money) => {
    const tempM = money.split('.');
    const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
    return tempHtml;
  };
  const capitalizeFirstLowercaseRest = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return '-';
  };
  return (
    <div className="m-t-l">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="row">
              <div className="col-6 col-md-3">
                <small className="text-title-tab">CONTRATO</small>
              </div>
              <div className="col-6 col-md-4">
                <small className="text-title-tab">VENDIDO POR</small>
              </div>
              <div className="col-6 col-md-2 p-r">
                <small className="text-title-tab ">SALDO</small>
              </div>
              <div className="col-6 col-md-3 p-r">
                <small className="text-title-tab text-f-r text-custom-title-tab">ESTATUS</small>
              </div>
            </div>
          </div>
          {
            data && data.length > 0 && data.map((item) => (
              <div className="row" key={item._id}>
                <div className="row row-cursor" onClick={() => redirectContractClient(item._id)}>
                  <div className="col-6 col-md-3 p-r row-mov-h">
                    <label className="text-name">
                      {item.noContrato}
                      &nbsp;
                    </label>
                    <label className="text-dir wb-ba">
                      <i>&#59679;</i>
                      {item?.sucursaless?.nombre}
                    </label>
                  </div>
                  <div className="col-6 col-md-4 row-mov-h">
                    <div className="row">
                      <div className="col-2">
                        {
                          item?.nombre_empleado.trim() === ''
                            ? (
                              <ProfileDefComponent
                                name={item?.vendedor}
                                size=""
                                imgProf={item?.foto_empleado || null}
                              />
                            ) : (
                              <ProfileDefComponent
                                name={item?.nombre_empleado}
                                size=""
                                imgProf={item?.foto_empleado || null}
                              />
                            )
                        }
                      </div>
                      <div className="col-10">
                        <div className="row">
                          <div className="col-12  wb-bw">
                            {item?.nombre_empleado && item.nombre_empleado.trim() !== '' ? item.nombre_empleado : (item?.vendedor && item.vendedor.trim() !== '' ? item.vendedor : '-')}
                          </div>
                          <div className="col-12">
                            {monthDayYearFormat(item.fecha)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-2 row-mov-h">
                    <label className="text-name text-money">
                      {item?.saldo && item?.saldo > 0
                        ? styleMoney(formatMoney.format(item.saldo))
                        : <i className="text-liq">Liquidado</i>}
                    </label>
                    <label className="text-dir text-money">
                      Total:&nbsp;
                      {styleMoney(formatMoney.format(item?.precioVenta))}
                    </label>
                  </div>
                  <div className="col-6 col-md-3 row-mov-h">
                    <label className="text-f-r">
                      {item?.estatus_contrato?.toUpperCase() === 'FIRMADO'
                        ? (
                          <i className="text-valid">
                            <i className="icon">&#59662;</i>
                            Validado
                          </i>
                        )
                        : (
                          <i className="text-no-valid">
                            <i className="icon">&#59678;</i>
                            {capitalizeFirstLowercaseRest(item?.estatus_contrato)}
                          </i>
                        )}
                    </label>
                  </div>
                </div>
                <hr className="line-spacing" />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ListContractComponent;
