import { useEffect, useState } from 'react';
import { StepP1 } from '../../formik/formik';
import categoryProd from '../../../../global/data/typeProducts.json';

const FrmDetailsProduct = ({ setDataForm, nextStep, defVal }) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [textCaract, setTextCaract] = useState('');

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = StepP1();

  const {
    // eslint-disable-next-line no-unused-vars
    titulo, categoria, descripcion, caracteristicas, controlF
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    // nextStep();
    if (isValid && dirty) {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (defVal) {
      setFieldValue('titulo', defVal.titulo);
      setFieldValue('descripcion', defVal.descripcion);
      setFieldValue('categoria', defVal.categoria);
      if (defVal.caracteristicas) {
        const strValue = defVal.caracteristicas.join('\r\n');
        // setFieldValue('caracteristicas', strValue);
        setTextCaract(strValue);
      }
      setFieldValue('controlF', defVal.controlF);
    }
  }, [defVal]);

  useEffect(() => {
    if (!isValid || !dirty) {
      setStatus('');
    } else {
      setStatus('idle');
    }
  }, [isValid, dirty, values]);
  useEffect(() => {
    try {
      const result = textCaract.split(/\r?\n/);
      const tempCaract = [];
      result.forEach((element) => {
        const whitespaceRemoved = element.replace(/\s/g, '');
        if (whitespaceRemoved !== '') {
          tempCaract.push(element);
        }
      });
      setFieldValue('caracteristicas', tempCaract);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [textCaract]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="content-buttom">&nbsp;</div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <h2>Información general</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          <label htmlFor="titulo" className="label mt-m">
            Título
            <input
              id="titulo"
              type="text"
              className={`input ${submitted && errors.titulo && 'has-error'}`}
              name="titulo"
              value={titulo}
              onChange={handleChange('titulo')}
            />
            {submitted && errors.titulo && (
              <small className="caption-error">
                {errors.titulo}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <label htmlFor="categoria" className="label mt-m">
            Categoría
            <select
              id="categoria"
              type="select"
              className={`input ${submitted && errors.categoria && 'has-error'}`}
              value={categoria}
              name="categoria"
              onChange={handleChange('categoria')}
            >
              <option value="">Selecciona una opción</option>
              {categoryProd.map((_option) => (
                <option key={_option.value} value={_option.value}>
                  {_option.label}
                </option>
              ))}
            </select>
            {submitted && errors.categoria && (
              <small className="caption-error">
                {errors.categoria}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          <label htmlFor="descripcion" className="label mt-m">
            Descripción
            <textarea
              type="text"
              className="input sizein"
              value={descripcion}
              onChange={handleChange('descripcion')}
            />
            {submitted && errors.descripcion && (
              <small className="caption-error">
                {errors.descripcion}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-9">
          <h2>Características</h2>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12">
        <label htmlFor="caracteristicas" className="label mt-m">
          <textarea
            id="caracteristicas"
            placeholder="Ingresa aquí las característica"
            className={`input textarea ${submitted && errors.caracteristicas && 'has-error'}`}
            name="caracteristicas"
            value={textCaract}
            onChange={(e) => setTextCaract(e.target.value)}
            rows="10"
          />
          {submitted && errors.caracteristicas && (
            <small className="caption-error">
              {errors.caracteristicas}
            </small>
          )}
        </label>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Siguiente paso
          </button>
        </div>
      </div>
    </form>
  );
};

export default FrmDetailsProduct;
