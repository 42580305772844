import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useCouponForm } from '../../formik/couponForm';
import { updateCoupon } from '../../services';
import CheckBox from '../../../../global/custom/checkbox';

const EditDurationForm = ({ currentData, onClose }) => {
  const [submitted, setSubmitted] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();
  const today = new Date().toISOString().split('T')[0];

  const minimumExpirationDate = currentData.fecha_expiracion && currentData.expira
    ? currentData.fecha_expiracion.split('T')[0] : today;

  const {
    values,
    isValid,
    handleChange,
    errors,
    setFieldValue,
  } = useCouponForm(currentData);

  const {
    fecha_inicio,
    fecha_expiracion,
    expira,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    try {
      setSubmitted(true);
      setSubmitting(true);

      const [yStart, mStart, dStart] = fecha_inicio.split('-');
      const [yEnd, mEnd, dEnd] = fecha_expiracion ? fecha_expiracion.split('-') : [];

      const iniciaHoy = fecha_inicio === today;
      const updateData = {
        fecha_inicio: new Date(yStart, parseInt(mStart, 10) - 1, dStart, 0, 0, 0),
        fecha_expiracion: yEnd ? new Date(yEnd, parseInt(mEnd, 10) - 1, dEnd, 0, 0, 0) : undefined,
        expira,
        estatus: currentData.estatus !== 'Activo'
          ? (iniciaHoy ? 'Activo' : 'Programado')
          : undefined,
      };

      const res = await updateCoupon(currentData._id, updateData);

      if (!res.errors) {
        toast('Cupón actualizado.', {
          className: 'message',
        });
        queryClient.invalidateQueries(['coupons', 'all']);
        onClose();
        setSubmitted(false);
      } else {
        throw res.errors;
      }
    } catch (err) {
      setSubmitting(false);
      if (err.message.includes('duplicate key error')) {
        toast('El código del cupón es duplicado.', {
          className: 'message error',
        });
      } else {
        toast('No se pudo actualizar el cupón, intente de nuevo mas tarde.', {
          className: 'message error',
        });
      }
    }
  };

  useEffect(() => {
    if (!expira) {
      setFieldValue('fecha_expiracion', '');
    }
  }, [expira]);

  return (
    <div className="row align-items-center justify-content-center">
      <div className="col-12 col-lg-8 col-xl-6 ">
        <div className="content-buttom">&nbsp;</div>
        <div className="d-flex justify-content-between">
          <h2>Editar duración</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Comienza
                  <input
                    onChange={handleChange('fecha_inicio')}
                    value={fecha_inicio}
                    id="fechaInicio"
                    min={today}
                    disabled={currentData.estatus === 'Activo'}
                    type="date"
                    className={`input ${submitted && errors.fecha_inicio && 'has-error'}`}
                  />
                  {submitted && errors.fecha_inicio && (
                    <small className="caption-error">
                      {errors.fecha_inicio}
                    </small>
                  )}
                </label>
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Termina
                  <input
                    onChange={handleChange('fecha_expiracion')}
                    value={fecha_expiracion}
                    id="fechaExpiracion"
                    type="date"
                    min={minimumExpirationDate}
                    disabled={!expira}
                    className={`input ${submitted && errors.fecha_expiracion && 'has-error'}`}
                  />
                  {submitted && errors.fecha_expiracion && (
                    <small className="caption-error">
                      {errors.fecha_expiracion}
                    </small>
                  )}
                  <div className="d-flex align-items-center">
                    <CheckBox
                      status={!expira}
                      eventC={() => setFieldValue('expira', !expira)}
                      nameC="expira"
                    />
                    <small className="ms-2">No expira.</small>
                  </div>
                </label>
              </div>
            </div>
            <div className="row mt-m">
              <div className="col-12">
                <button
                  type="button"
                  className="button-secondary p-r m-btn-prof"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  className="button"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EditDurationForm;
