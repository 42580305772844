import { useFormik } from 'formik';
import * as Yup from 'yup';

const phoneRegExp = /^[0-9\- ]{10,10}$/;

export const FormikS = () => useFormik({
  validateOnMount: true,
  initialValues: {
    contacto: '',
    fecha: '',
    hora: '',
    lugar: '',
    numero: '',
    comentarios: '',
  },
  validationSchema: Yup.object({
    contacto: Yup.string()
      .required('El Tipo de Contacto es requerido'),
    fecha: Yup.string()
      .required('La Fecha es requerida'),
    hora: Yup.string()
      .required('La Hora es requerida'),
    lugar: Yup.string().when('contacto', {
      is: (value) => value === 'Cita',
      then: Yup.string().required('El lugar es requerido'),
      otherwise: Yup.string(),
    }),
    numero: Yup.string().when('contacto', {
      is: (value) => value !== 'Cita',
      then: Yup.string()
        .required('El teléfono es requerido')
        .matches(phoneRegExp, 'El teléfono es inválido'),
      otherwise: Yup.string(),
    }),
    comentarios: Yup.string(),
  }),
});
