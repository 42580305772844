/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { StepP4 } from '../../formik/formik';
import planesList from '../../../../global/data/typePlanes.json';

const FrmPlanesProduct = ({
  setDataForm, nextStep, prevStep, defVal, arraySucursales
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [switchC, setSwitchC] = useState(false);

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = StepP4();

  const {
    sucursales
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    // nextStep();
    if (isValid && dirty) {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
    }
  };

  const checkChanges = (_field, _value, index) => {
    if (switchC) {
      arraySucursales.forEach((_element, ind) => {
        if (index !== ind) {
          setFieldValue(`sucursales[${ind}].${_field}`, _value);
        }
      });
    }
  };

  useEffect(() => {
    if (defVal) {
      setFieldValue('sucursales', defVal.sucursales);
    }
  }, [defVal]);

  useEffect(() => {
    if (switchC && sucursales) {
      if (sucursales[0] && sucursales[0].planes !== '') {
        arraySucursales.forEach((_element, ind) => {
          if (ind !== 0) {
            setFieldValue(`sucursales[${ind}].planes`, sucursales[0].planes);
          }
        });
      }
      if (sucursales[0] && sucursales[0].unidades !== '') {
        arraySucursales.forEach((_element, ind) => {
          if (ind !== 0) {
            setFieldValue(`sucursales[${ind}].unidades`, sucursales[0].unidades);
          }
        });
      }
    }
  }, [switchC]);

  useEffect(() => {
    if (!defVal) {
      const tempAS = [];
      arraySucursales.forEach((element) => {
        tempAS.push({
          sucursal: element.value,
          planes: '',
          unidades: ''
        });
      });
      setFieldValue('sucursales', tempAS);
    }
  }, [arraySucursales]);

  return (
    <form onSubmit={handleSubmit}>
      {arraySucursales && arraySucursales.map((item, index) => (
        <div className="container-fluid" key={item.value}>
          {index === 0 && (
            <>
              <div className="content-buttom">&nbsp;</div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <h2>{item.label}</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <br />
                </div>
                <div className="col-12">
                  <div className="row prospect-message-cont">
                    <div className="col-10 col-md-11 ">
                      <p>Aplicar los mismos planes para todas las sucursales</p>
                    </div>
                    <div className="col-2 col-md-1 ">
                      <Switch
                        checked={switchC}
                        onChange={() => setSwitchC(!switchC)}
                        onColor="#000000"
                        onHandleColor="#FFFFFF"
                        offColor="#CFCFCF"
                        offHandleColor="#FFFFFF"
                        borderRadius={60}
                        handleDiameter={5}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={25}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <label htmlFor={`planes_${index}`} className="label mt-m">
                    Planes disponibles
                    <select
                      id={`planes_${index}`}
                      type="select"
                      className={`input ${submitted && errors.sucursales && 'has-error'}`}
                      name={`planes_${index}`}
                      value={sucursales[index].planes}
                      onChange={(e) => {
                        setFieldValue(`sucursales[${index}].planes`, e.target.value);
                        checkChanges('planes', e.target.value, index);
                      }}
                    >
                      <option value="">Selecciona una opción</option>
                      {planesList.map((_item) => (
                        <option key={_item.value} value={_item.value}>
                          {_item.label}
                        </option>
                      ))}
                    </select>
                    {submitted && errors.sucursales && <small className="caption-error">{errors.sucursales}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <label htmlFor={`unidades_${index}`} className="label mt-m">
                    Unidades disponibles (stock)
                    <input
                      id={`unidades_${index}`}
                      type="number"
                      className={`input ${submitted && errors.sucursales && 'has-error'}`}
                      name={`unidades_${index}`}
                      value={sucursales[index].unidades}
                      onChange={(e) => {
                        setFieldValue(`sucursales[${index}].unidades`, e.target.value);
                        checkChanges('unidades', e.target.value, index);
                      }}
                    />
                    {submitted && errors.sucursales && (
                      <small className="caption-error">
                        {errors.sucursales}
                      </small>
                    )}
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
      {arraySucursales && sucursales
        && sucursales.length > 1
        && arraySucursales.map((item, index) => (
          <div className="container-fluid" key={item.value}>
            {index > 0 && (
              <>
                <div className="content-buttom">&nbsp;</div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <h2>{item.label}</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6">
                    <label htmlFor={`planes_${index}`} className="label mt-m">
                      Planes disponibles
                      <select
                        id={`planes_${index}`}
                        type="select"
                        className={`input ${submitted && errors.sucursales && 'has-error'}`}
                        name={`planes_${index}`}
                        value={sucursales[index].planes}
                        onChange={handleChange(`sucursales[${index}].planes`)}
                      >
                        <option value="">Selecciona una opción</option>
                        {planesList.map((_item) => (
                          <option key={_item.value} value={_item.value}>
                            {_item.label}
                          </option>
                        ))}
                      </select>
                      {submitted && errors.sucursales && <small className="caption-error">{errors.sucursales}</small>}
                    </label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <label htmlFor={`unidades_${index}`} className="label mt-m">
                      Unidades disponibles (stock)
                      <input
                        id={`unidades_${index}`}
                        type="number"
                        className={`input ${submitted && errors.sucursales && 'has-error'}`}
                        name={`unidades_${index}`}
                        value={sucursales[index].unidades}
                        onChange={handleChange(`sucursales[${index}].unidades`)}
                      />
                      {submitted && errors.sucursales && (
                        <small className="caption-error">
                          {errors.sucursales}
                        </small>
                      )}
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <button className="input-label-float-right button-tertiary btn-step" onClick={() => prevStep()}>
            Regresar
          </button>
        </div>
        <div className="col-12 col-md-6">
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Siguiente paso
          </button>
        </div>
      </div>
    </form>
  );
};

export default FrmPlanesProduct;
