import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    rfc: '',
    direccion: '',
    colonia: '',
    etreCalles: '',
    ciudad: '',
    estado: '',
    cp: '',
    ocupacion: '',
    fechaNacimiento: '',
    sexo: '',
    estadoCivil: '',
    telefono: '',
    email: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('El Apellido Paterno es requerido'),
    apellidoMaterno: Yup.string(),
    rfc: Yup.string()
      .required('El RFC es requerido'),
    direccion: Yup.string(),
    colonia: Yup.string(),
    etreCalles: Yup.string(),
    ciudad: Yup.string(),
    estado: Yup.string(),
    cp: Yup.string(),
    // .required('El Código postal es requerido')
    // .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
    // .min(5, 'El código postal debe tener mínimo 5 caracteres')
    // .max(5, 'El código postal debe tener maximo 5 caracteres')
    ocupacion: Yup.string(),
    fechaNacimiento: Yup.string(),
    sexo: Yup.string(),
    estadoCivil: Yup.string(),
    telefono: Yup.string()
      .required('El telefono es requerido'),
    email: Yup.string()
      .email('La dirección de correo no es válida'),
  })
});

export const FormikAddress = () => useFormik({
  validateOnMount: true,
  initialValues: {
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    direccion: '',
  },
  validationSchema: Yup.object({
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('El Estado es requerido'),
    ciudad: Yup.string()
      .required('La Localidad o Municipio es requerido'),
    colonia: Yup.string()
      .required('La Colonia es requerida'),
    direccion: Yup.string()
      .required('La Direccion es requerida'),
  }),
});
