import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import Message from '../../../shared/message/Message';
import { actualizarProducto } from '../../services';

const ToggleStatusModal = ({
  newStatus,
  onClose,
  product,
}) => {
  const [status, setStatus] = useState('idle');
  const userData = useRef(JSON.parse(localStorage.getItem('user')));

  const queryClient = useQueryClient();

  const refreshData = () => {
    queryClient.invalidateQueries(['products', 'all']);
  };

  const handleSubmit = async () => {
    setStatus('loading');
    try {
      const { current } = userData;
      const res = await actualizarProducto(product._id, {
        status: newStatus,
        borradoPor: newStatus === 0 ? {
          idEmpleado: current._id,
          nombre: `${current.nombre} ${current.apellidoPaterno} ${current.apellidoMaterno || ''}`,
          correo: current.correo,
          imagen_perfil: current.imagen_perfil,
          fecha: new Date(),
        } : {},
      });
      if (res.errors) {
        throw res.errors;
      }
      toast(
        newStatus ? 'El producto ha sido activado' : 'El producto ha sido desactivado',
        { className: 'message' }
      );
      refreshData();
    } catch (err) {
      toast('Ha ocurrido un error, vuelve a intentarlo más tarde', {
        className: 'message error'
      });
    } finally {
      onClose();
    }
  };

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="container">

        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-lg-8 col-xl-5">
            <div className="message-container">
              {status === 'loading' && (
                <Message
                  type="loading"
                  title="Cargando..."
                  content="Esta operación podría demorar algunos minutos. Por favor, espere."
                />
              )}
              {status === 'idle' && (
                <Message
                  type="alert"
                  title={newStatus ? '¿Seguro que deseas activar este producto?' : '¿Seguro que deseas desactivar este producto?'}
                  message={newStatus
                    ? 'Al hacerlo, este volverá a estará disponible para su venta.'
                    : 'Al hacerlo, ya no estará disponible para su venta. Pero podrás volver a activarlo en cualquier momento.'}
                  content=""
                  confirmText={`Sí, ${newStatus ? 'activar' : 'desactivar'}`}
                  cancelText="No, cancelar"
                  onConfirm={handleSubmit}
                  onCancel={onClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleStatusModal;
