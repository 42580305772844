/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import Accordion from 'react-bootstrap/Accordion';
import ProfileDefComponent from '../../../shared/profileDef';
import { monthDayYearFormat } from '../../../../helpers/dates';

/* const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}); */

const DetailsProspInfo = ({ data, eventW, permision }) => {
  // const checkOrigin = (count) => formatMoney.format(count);
  // const user = JSON.parse(localStorage.getItem('user'));

  const allowUpdateUser = permision('Prospectos', 'actualizarResponsable');

  /* const calcFecha = (fecha) => {
    try {
      const dateM = new Date(fecha);
      const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      const fec = fecha.split('-', 3);
      const dia = fec[2].split('', 2);
      // const date = `${dia[0]}${dia[1]}/${fec[1]}/${fec[0]}`;
      const date2 = `${monthNames[dateM.getMonth()]} ${dia[0]}${dia[1]}, ${fec[0]}`;
      return date2;
    } catch (_error) {
      return '-';
    }
  }; */

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12 col-lg-1"> </div>
        <div className="col-12 col-md-12 col-lg-10">
          <br />
          <br />
          <div className="row">
            <div className="col-12 col-sm-12 col-md-9 col-lg-10">
              <h2 className="text-title-accordion">Responsable</h2>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-2">
              {data && (!data.empleado || !data.empleado._id) && allowUpdateUser && (
                <button className="button" type="button" onClick={eventW}>
                  Asignar
                </button>
              )}
              {data && (data?.empleado && data?.empleado?._id) && allowUpdateUser && (
                <button className="button-secondary p-r" onClick={eventW}>
                  &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                  <i className="btn-icon">&#59686;</i>
                </button>
              )}
            </div>
            <br />
            <br />
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-2 col-md-1">
                  <ProfileDefComponent name={data.nombre_empleado ? data.nombre_empleado : ''} size="" imgProf={data.imagen_empleado || '/assets/img/def-user.webp'} />
                </div>
                <div className="col-10 col-md-11">
                  <label className="datos-info pos-prospect text-capitalize">{(data.nombre_empleado && data.nombre_empleado !== '  ') ? data.nombre_empleado : 'Sin asignar'}</label>
                </div>
              </div>
              <div className="col-12 col-md-6">&nbsp;</div>
            </div>
            <hr className="notif-line-spacing light" />
          </div>
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Datos del prospecto
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Nombre completo</label>
                    <label className="datos-info text-capitalize">
                      {data?.nombre ? data.nombre : ''}
                      {' '}
                      {data?.apellidoPaterno ? data.apellidoPaterno : ''}
                      {' '}
                      {data?.apellidoMaterno ? data.apellidoMaterno : ''}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Género&nbsp;</label>
                    <label className="datos-info text-capitalize">{data?.genero ? data.genero : '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Edad&nbsp;</label>
                    <label className="datos-info">{data?.edad ? data.edad : '-'}</label>
                  </div>
                  {
                    /*
                      <div className="col-12 col-md-6">
                        <label className="datos-title">Fecha de nacimiento&nbsp;</label>
                        <label className="datos-info">{data?.fecha_nacimiento ? monthDayYearFormat(data.fecha_nacimiento) : '-'}</label>
                      </div>
                     */
                  }
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Teléfono</label>
                    <label className="datos-info">{data?.telefono ? data.telefono : '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Correo electrónico</label>
                    <label className="datos-info">{data?.email ? data.email : '-'}</label>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Domicilio
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Ubicación</label>
                    <label className="datos-info">
                      {data?.ciudad ? data.ciudad : ''}
                      {', '}
                      {data?.estado ? data.estado : ''}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Código postal</label>
                    <label className="datos-info">{data?.cp ? data.cp : '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Dirección</label>
                    <label className="datos-info">
                      {data?.direccion ? data.direccion : '-'}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">&nbsp;</label>
                    <label className="datos-info">&nbsp;</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Colonia</label>
                    <label className="datos-info">{data?.colonia ? data.colonia : '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Entre calles</label>
                    <label className="datos-info">{data?.entre_calles ? data.entre_calles : '-'}</label>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          <div className="row">
            <div className="col-12">
              <h2 className="text-title-accordion">Detalles del registro</h2>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label className="datos-title">Origen</label>
                <label className="datos-info">{data?.origen ? data.origen : '-'}</label>
              </div>
              <div className="col-12 col-md-6">
                <label className="datos-title">Desde</label>
                <label className="datos-info">{monthDayYearFormat(data.createdAt) || '-'}</label>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12 ">
                    <label className="datos-title">Creado por</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 col-md-1 padd-top-img">
                    <ProfileDefComponent name={data?.creadoPor?.nombre ? data.creadoPor.nombre : ''} size="" imgProf={data?.creadoPor?.imagen_perfil || undefined} />
                  </div>
                  <div className="col-10 col-md-11">
                    <label className="datos-title pos-prospect">{(data?.creadoPor?.nombre && data?.creadoPor?.nombre !== '  ') ? data.creadoPor.nombre : '-'}</label>
                    <label className="datos-title pos-prospect">{(data?.creadoPor?.nombre && data?.creadoPor?.correo !== '  ') ? data.creadoPor.correo : '-'}</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12 ">
                    <label className="datos-title">Fecha de creaci&oacute;n</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="datos-title pos-prospect">{monthDayYearFormat(data?.createdAt) || '  '}</label>
                  </div>
                </div>
              </div>
            </div>
            <hr className="notif-line-spacing light" />
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-1"> </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default DetailsProspInfo;
