/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';

const ViewInfoClient = ({
  dataClient, nextStep
}) => {
  const [restringD, setRestringD] = useState(false);

  useEffect(() => {
    if (dataClient && dataClient._id) {
      let blockData = true;
      const user = JSON.parse(localStorage.getItem('user'));
      dataClient.Search.forEach((element) => {
        if (user._id === element) {
          blockData = false;
        }
      });
      setRestringD(blockData);
    }
  }, [dataClient]);
  return (
    <>
      {dataClient && !restringD && (
        <div className="row content-nav-padd">
          <div className="col-12 col-md-12">
            <div className="col-12 spacing-nav"> </div>
          </div>
          <div className="col-12 col-md-12">
            <h2 className="text-capitalize">
              {dataClient.nombre || ''}
              {' '}
              {dataClient.apellidoPaterno || ''}
              {' '}
              {dataClient.apellidoMaterno || ''}
            </h2>
          </div>
          <br />
          <div className="col-12 col-md-12">
            <label className="datos-title">Teléfono</label>
            <label className="datos-info">{dataClient.telefono || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Correo electrónico</label>
            <label className="datos-info">{dataClient.email || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Fecha de nacimiento</label>
            <label className="datos-info">{dataClient.fechaNacimiento || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Edad</label>
            <label className="datos-info">{dataClient.edad || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Ocupación</label>
            <label className="datos-info text-capitalize">{dataClient.ocupacion || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">RFC</label>
            <label className="datos-info">{dataClient.rfc || '-'}</label>
          </div>
          <br />
          <div className="col-12 col-md-12">
            <h2>Domicilio</h2>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Ubicación</label>
            <label className="datos-info">
              {dataClient.ciudad || ''}
              {', '}
              {dataClient.estado || ''}
            </label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Código postal</label>
            <label className="datos-info">{dataClient.cp || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Dirección</label>
            <label className="datos-info">{dataClient.direccion || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Colonia</label>
            <label className="datos-info">{dataClient.colonia || '-'}</label>
          </div>
          <div className="col-12 col-md-12">
            <label className="datos-title">Interior</label>
            <label className="datos-info">{dataClient.numInterior || '-'}</label>
          </div>
          <div className="col-12 p-r">
            <button className="button btn-full-c" type="button" onClick={nextStep}>
              Crear contrato
            </button>
          </div>
        </div>
      )}
      {dataClient && restringD && (
        <div className="row content-nav-padd">
          <div className="col-12 col-md-12">
            <div className="col-12 spacing-nav"> </div>
          </div>
          <div className="col-12 col-md-12">
            <h2>
              {dataClient.nombre || ''}
              {' '}
              {dataClient.apellidoPaterno || ''}
              {' '}
              {dataClient.apellidoMaterno || ''}
            </h2>
          </div>
          <div className="col-12 col-md-12">
            <br />
          </div>
          <div className="col-12 col-md-12">
            <label className="text-email">{dataClient.email || '-'}</label>
            <label className="text-dir datos-info">
              <i>&#59679;</i>
              {dataClient.ciudad || ''}
              {', '}
              {dataClient.estado || ''}
            </label>
          </div>
          <div className="col-12 col-md-12">
            <br />
            <br />
            <br />
          </div>
          <div className="col-12 col-md-12 content-center">
            <div className="row">
              <div className="col-12 cont-icon-info">
                <label className="icon-info-client">&#59690;</label>
              </div>
              <div className="col-12">
                <label className="text-title-info">Información restringida</label>
              </div>
              <div className="col"> </div>
              <div className="col-12 col-md-11">
                <label className="text-info">Para poder visualizar la información de este cliente, debes tener un contrato que te vincule a él.</label>
              </div>
              <div className="col"> </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <br />
            <br />
            <br />
          </div>
          <div className="col-12 p-r">
            <button className="button btn-full-c" type="button" onClick={nextStep}>
              Crear contrato
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewInfoClient;
