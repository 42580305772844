/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import StatusFormBeneficiaries from './components/addBeneficiaries/statusForm';
import FrmDataBeneficiaries from './components/addBeneficiaries/frmDataBenef';
import FrmFileBeneficiaries from './components/addBeneficiaries/frmFileBenef';
import FrmPriorityBeneficiaries from './components/addBeneficiaries/frmPriorityBenef';
import {
  uploadFileC, getContractByID, updateContract, insertBeneficiarie, updateBeneficiarie
} from './services';
import './contract.css';

const AddBeneficiariesView = ({ idContract }) => {
  const [dataContract, setdDataC] = useState(null);
  const [checked1, setChecked1] = useState('&#59666;'); // '&#59654;'
  const [checked2, setChecked2] = useState('&#59652;');
  const [checked3, setChecked3] = useState('&#59652;');

  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [insertData, setInsertData] = useState('');

  const [dataForm1, setDataForm1] = useState(null);
  const [dataForm2, setDataForm2] = useState(null);
  const [dataForm3, setDataForm3] = useState(null);
  const [urlFile, setUrlFile] = useState(null);
  const [priorBenef, setPriorBenef] = useState(false);

  const [statusInsert, setStatusInsert] = useState('loading');
  const [statusTitle, setStatusTitle] = useState('Procesando, espere');
  const [statusContent, setStatusContent] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [statusConfirmT, setStatusConfirmT] = useState('Salir');
  const navigate = useNavigate();

  const step1View = () => {
    setChecked1('&#59666;');
    setStateStep1('active');
    setStateStep2('disabled');
    setStateStep3('disabled');
    setChecked2('&#59652;');
    setChecked3('&#59652;');
  };
  const step2View = () => {
    setChecked1('&#59654;');
    setStateStep1('ok');
    setStateStep2('active');
    setStateStep3('disabled');
    setChecked2('&#59666;');
    setChecked3('&#59652;');
  };
  const step3View = () => {
    setChecked1('&#59654;');
    setChecked2('&#59654;');
    setStateStep1('ok');
    setStateStep2('ok');
    setStateStep3('active');
    setChecked3('&#59666;');
  };

  const insertServiceP = async () => {
    let tempNewBenef = dataForm1;
    tempNewBenef.url_ine = urlFile;
    const createBenef = await insertBeneficiarie(tempNewBenef);
    if (createBenef && createBenef._id) {
      const updateDataC = {
        beneficiarios: [],
      };
      dataForm3.forEach((element) => {
        if (element._id) {
          updateDataC.beneficiarios.push({
            idBeneficiario: element._id,
          });
        } else {
          updateDataC.beneficiarios.push({
            idBeneficiario: createBenef._id,
          });
        }
      });
      await updateContract(idContract, updateDataC).then(async (resp) => {
        if (resp) {
          dataForm3.forEach(async (element, _ind) => {
            if (element._id) {
              const updateNiv = {
                nivel: _ind + 1
              };
              const updateBenef = await updateBeneficiarie(element._id, updateNiv);
              if (updateBenef && (_ind + 1) === (dataForm3.length)) {
                setStatusInsert('success');
                setStatusTitle('Beneficiario registrado');
                setStatusMessage('El beneficiario ha sido registrado exitosamente.');
              }
              if (updateBenef && updateBenef.error) {
                setStatusInsert('error');
                setStatusTitle('Error al actualizar el contrato.');
                setStatusContent('Intente de nuevo más tarde.');
              }
            }
          });
        } else {
          setStatusInsert('error');
          setStatusTitle('Error al actualizar el contrato.');
          setStatusContent('Intente de nuevo más tarde.');
        }
      });
    } else {
      setStatusInsert('error');
      setStatusTitle('Error al insertar el beneficiario.');
      setStatusContent('Intente de nuevo más tarde.');
    }
  };
  const insertServiceP_D = async () => {
    let tempNewBenef = dataForm1;
    tempNewBenef.url_ine = urlFile;
    tempNewBenef.nivel = 1;
    const createBenef = await insertBeneficiarie(tempNewBenef);
    if (createBenef && createBenef._id) {
      const updateDataC = {
        beneficiarios: [{ idBeneficiario: createBenef._id }],
      };
      await updateContract(idContract, updateDataC).then(async (resp) => {
        if (resp) {
          setStatusInsert('success');
          setStatusTitle('Beneficiario registrado');
          setStatusMessage('El beneficiario ha sido registrado exitosamente.');
        } else {
          setStatusInsert('error');
          setStatusTitle('Error al actualizar el contrato.');
          setStatusContent('Intente de nuevo más tarde.');
        }
      });
    } else {
      setStatusInsert('error');
      setStatusTitle('Error al insertar el beneficiario.');
      setStatusContent('Intente de nuevo más tarde.');
    }
  };
  const ConfirmInsertProccess = async (_dataP) => {
    setStateStep3('ok');
    if (!priorBenef) {
      setStateStep2('ok');
    }
    setInsertData('active');
    if (dataForm2 || _dataP) {
      if (dataForm2) {
        await uploadFileC(dataForm2, `beneficiaries/${idContract}`).then((dataImg) => {
          if (dataImg.ok) {
            setUrlFile(dataImg.file);
          } else {
            setStatusInsert('error');
            setStatusTitle('Error al subir los archivos.');
            setStatusContent('Intente de nuevo más tarde.');
          }
        });
      } else if (_dataP) {
        await uploadFileC(_dataP, 'beneficiaries').then((dataImg) => {
          if (dataImg.ok) {
            setUrlFile(dataImg.file);
          } else {
            setStatusInsert('error');
            setStatusTitle('Error al subir los archivos.');
            setStatusContent('Intente de nuevo más tarde.');
          }
        });
      }
    } else {
      setUrlFile('');
    }
  };

  const finalConfirm = () => {
    navigate(`/clients/contract-view/${idContract}`);
  };
  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const getContractInfo = async () => {
    await getContractByID(idContract).then((resp) => {
      if (resp && resp[0] && resp[0]._id) {
        setdDataC(resp[0].beneficiarios);
        if (resp[0].beneficiarios && resp[0].beneficiarios.length > 0) {
          setPriorBenef(true);
        }
      }
    });
  };
  useEffect(() => {
    if (urlFile && priorBenef) {
      insertServiceP();
    } else if (urlFile && !priorBenef) {
      insertServiceP_D();
    }
  }, [urlFile]);
  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idContract) {
      getContractInfo();
    } else if (!token) {
      closeSession();
    }
  }, [idContract]);

  return (
    <div>
      <div className="row justify-content-center align-items-center ">
        {insertData !== 'active' && (
          <>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 ">
              <i className="step-client-icon">{parse(checked1)}</i>
              <label className="step-client small-bold">Detalles</label>
            </div>
            <div className={`col-12 col-sm-6 col-md-4 col-lg-4 ${stateStep2}`}>
              <i className="step-client-icon">{parse(checked2)}</i>
              <label className="step-client small-bold">Documentación</label>
            </div>
            {priorBenef && (
              <div className={`col-12 col-sm-6 col-md-4 col-lg-4 ${stateStep3}`}>
                <i className="step-client-icon">{parse(checked3)}</i>
                <label className="step-client small-bold">Prioridad</label>
              </div>
            )}
          </>
        )}
      </div>
      <div className="mb-xxl-5">
        {stateStep1 === 'active' && (
          <FrmDataBeneficiaries
            setDataForm={setDataForm1}
            nextStep={step2View}
            defVal={dataForm1}
            idC={idContract}
          />
        )}
        {stateStep2 === 'active' && (
          <FrmFileBeneficiaries
            setDataForm={setDataForm2}
            nextStep={step3View}
            defVal={dataForm2}
            prevStep={step1View}
            priorBenef={priorBenef}
            insertData={ConfirmInsertProccess}
          />
        )}
        {stateStep3 === 'active' && priorBenef && (
          <FrmPriorityBeneficiaries
            setDataForm={setDataForm3}
            nextStep={ConfirmInsertProccess}
            defVal={dataForm3}
            prevStep={step2View}
            contractData={dataContract}
            newBenefData={dataForm1}
          />
        )}
        {insertData === 'active' && (
          <StatusFormBeneficiaries
            type={statusInsert}
            title={statusTitle}
            content={statusContent}
            message={statusMessage}
            onConfirm={finalConfirm}
            confirmText={statusConfirmT}
          />
        )}
      </div>
    </div>
  );
};

export default AddBeneficiariesView;
