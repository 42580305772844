/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { FormikStep4 } from '../../formik/formikSteps';
import interesJSON from '../../../../global/data/interes.json';
import NavPage from '../../../navPage';
import '../../prospects.css';

const AddProspStep4 = ({
  setDataForm,
  nextStep,
  prevStep,
  defVal,
}) => {
  const [submitted4, setSubmitted4] = useState(false);
  const [status, setStatus] = useState('idle');
  const [switchC, setSwitchC] = useState(false);

  const optionNav = ['Llamada', 'Cita', 'WhatsApp'];
  const [activeNav, setActiveNav] = useState('Llamada');
  const eventChangeNav = (item) => {
    setActiveNav(item);
  };

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FormikStep4(defVal || {});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted4(true);
    // nextStep();
    if (isValid) {
      setDataForm(values);
      nextStep(values);
    } else {
      setStatus('');
    }
  };

  const returnPrevStep = () => {
    prevStep(values);
  };

  const {
    interes, comentarios, fecha, hora, lugar, agendar, numero,
  } = values;

  useEffect(() => {
    if (isValid || dirty) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [isValid, dirty]);

  useEffect(() => {
    if (defVal) {
      setActiveNav(defVal.contacto);
      if (defVal.agendar) {
        setSwitchC(defVal.agendar);
      }
    }
  }, [defVal]);

  useEffect(() => {
    setFieldValue('contacto', activeNav);
  }, [activeNav]);

  useEffect(() => {
    setFieldValue('agendar', switchC);
    if (!switchC) {
      setFieldValue('contacto', 'Llamada');
      setFieldValue('fecha', '');
      setFieldValue('hora', '');
      setFieldValue('lugar', '');
    }
  }, [switchC]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="interes" className="label mt-m">
            Nivel de interés
            <select
              type="interes"
              value={interes}
              onChange={handleChange('interes')}
            >
              <option>Seleccione una opción</option>
              {interesJSON.map((items) => (
                <option key={items.value} name={items.value} value={items.value}>
                  {items.label}
                </option>
              ))}
            </select>
            <small>(Opcional)</small>
          </label>
        </div>
        <div className="col-12 col-md-12 margin-form-title">
          <h2>Seguimiento</h2>
        </div>
        <div className="col-12">
          <div className="row prospect-message-cont">
            <div className="col-10 col-md-11 ">
              <p>Agendar seguimiento</p>
            </div>
            <div className="col-2 col-md-1 ">
              <Switch
                checked={switchC}
                onChange={() => setSwitchC(!switchC)}
                onColor="#000000"
                onHandleColor="#FFFFFF"
                offColor="#CFCFCF"
                offHandleColor="#FFFFFF"
                borderRadius={60}
                handleDiameter={5}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={15}
                width={25}
                className="react-switch"
                id="material-switch"
              />
            </div>
          </div>
        </div>
        {switchC && (
          <>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-r ">
              <label className="label mt-m">
                Tipo de contacto
                <select
                  name="contacto"
                  id="contacto"
                  value={activeNav}
                  onChange={(e) => setActiveNav(e.target.value)}
                >
                  <option value="">Seleccione una opción</option>
                  {optionNav.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-r" />
            <div className="col-12 col-sm-12 col-md-6">
              {activeNav && activeNav !== 'Cita' && (
                <>
                  <label htmlFor="fecha" className="label mt-m">
                    Número
                    <input
                      id="numero"
                      type="tel"
                      className={`input input-date ${submitted4 && errors.numero && 'has-error'}`}
                      onChange={handleChange('numero')}
                      value={numero}
                    />
                    {
                      submitted4 && errors.numero && <small className="caption-error">{errors.numero}</small>
                    }
                  </label>
                </>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-r" />
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fecha" className="label mt-m">
                Fecha
                <input
                  id="fecha"
                  type="date"
                  className={`input input-date ${submitted4 && errors.fecha && 'has-error'}`}
                  onChange={handleChange('fecha')}
                  value={fecha}
                />
                {
                  submitted4 && errors.fecha && <small className="caption-error">{errors.fecha}</small>
                }
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="hora" className="label mt-m">
                Hora
                <input
                  id="hora"
                  type="time"
                  className={`input input-date ${submitted4 && errors.hora && 'has-error'}`}
                  onChange={handleChange('hora')}
                  value={hora}
                />
                {
                  submitted4 && errors.hora && <small className="caption-error">{errors.hora}</small>
                }
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              {activeNav === 'Cita' ? (
                <label htmlFor="lugar" className="label mt-m ">
                  Lugar
                  <input
                    id="lugar"
                    type="text"
                    required
                    className={`input ${submitted4 && errors.lugar && 'has-error'}`}
                    value={lugar}
                    onChange={handleChange('lugar')}
                  />
                  {
                    submitted4 && errors.lugar && <small className="caption-error">{errors.lugar}</small>
                  }
                </label>
              ) : (<></>)}
            </div>
            <div className="col-12">
              <label htmlFor="comentarios" className="label mt-m ">
                Comentarios y observaciones
                <textarea
                  onChange={handleChange('comentarios')}
                  value={comentarios}
                  id="comentarios"
                  className={`input sizein ${submitted4 && errors && errors.comentarios && 'has-error'}`}
                />
              </label>
              <label htmlFor="comentarios" className="label font-sm">
                (Opcional)
              </label>
            </div>
          </>
        )}
        <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
        <div className="col-12 col-md-12">
          <button className="button btn-step-prev" type="button" onClick={() => returnPrevStep()}>
            Regresar
          </button>
          <button className="button btn-step" type="submit">
            Finalizar
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddProspStep4;
