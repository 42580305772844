/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPermisionRoute, checkPermissionJob } from '../shared/routeGuards/checkComponentPermission';
import { useContractPerPage, useLatestContract } from './hooks/queries';
import ListContractComponent from './components/listContracC/listContractC';
import ListContractCobranzaComponent from './components/listContracC/listContractCobranzaC';
import NotFoundAlert from '../shared/notFoundAlert/NotFoundAlert';

const ListContractView = () => {
  const navigate = useNavigate();
  const [selectFilter, setSelectFilter] = useState(null);
  const [searchInp, setSearchInp] = useState('');
  const [page, setPage] = useState(1);
  const [filterCobrador, setfilterCobrador] = useState(null);

  const [dropDown2, setDropDown2] = useState(false);
  const [listOptions, setOptions] = useState(false);
  const [orderList, setOrderList] = useState('asc');
  const [selectedOption, setSelectedOption] = useState('Más Recientes');
  const perPage = 10;

  const { data: queryData } = useContractPerPage({
    estatus: selectFilter || undefined,
    paginacion: page,
    paginas: perPage,
    noContrato: searchInp || undefined,
    sinCobrador: filterCobrador || undefined,
    search: searchInp,
    order: orderList,
  });
  const { data, isFetched: isLatestContractFetched } = useLatestContract();

  const [latestContract] = data;

  const total = queryData?.total;
  const contractList = queryData?.data;
  const totalPages = Math.ceil(total / perPage);
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const searchEnabled = searchInp.length > 0 || selectFilter !== null;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const listSucursalF = () => {
    setOptions(!listOptions);
  };

  const openDrop2 = () => {
    setDropDown2(!dropDown2);
  };

  const changeOrderList = (order) => {
    setOrderList(order);
    switch (order) {
      case 'asc':
        return setSelectedOption('Más Recientes');
      case 'desc':
        return setSelectedOption('Más Antiguos');
      case 'noContratoNumerico':
        return setSelectedOption('Por número de contrato');
      default:
        return '';
    }
  };

  const searchClients = (e) => {
    setSearchInp(e.target.value);
  };

  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      const token = localStorage.getItem('token').toString();
      if (!token) {
        closeSession();
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, [page, searchInp, orderList, selectFilter]);
  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, []);
  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp]);
  useEffect(() => {
    if (checkPermissionJob(['jefe de cobranza'])) {
      setSelectFilter('firmado');
    }
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <h2>Contratos</h2>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter">
          {isLatestContractFetched && (
            <>
              <label className="text-name">Último contrato registrado</label>
              <label className="text-title-info">{latestContract?.noContrato || latestContract?.folio || 'N/A'}</label>
            </>
          )}
          {false && (
            <button className="button p-r">
              &nbsp;&nbsp;Nuevo contrato&nbsp;&nbsp;
              <i className="btn-icon">&#59672;</i>
            </button>
          )}
        </div>
        <div className="col-12 margin-filter">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <label>{total || 0} resultados</label>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 margin-filter">
            <div className="col-12 col-md-12">
              <div className="input-container">
                <input id="buscar" onChange={(e) => searchClients(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                <span className="input-icon search-line" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 margin-filter content-on">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <button className="button-secondary btn-p-cl p-r" onClick={listSucursalF}>
              &nbsp;&nbsp;Filtro&nbsp;
              <i className="btn-icon">&#59674;</i>
              &nbsp;&nbsp;
              {listOptions && checkPermisionRoute('Contratos', 'validarContrato') && (
                <div className="drop-menu lg" onMouseLeave={() => setOptions(false)}>
                  <label onClick={() => setSelectFilter(null)}>
                    Todas
                  </label>
                  <label onClick={() => setSelectFilter('firmado')}>
                    Validado
                  </label>
                  <label onClick={() => setSelectFilter('por validar')}>
                    Por Validar
                  </label>
                </div>
              )}
              {listOptions && checkPermisionRoute('Contratos', 'asignarCobrador') && (
                <div className="drop-menu lg" onMouseLeave={() => setOptions(false)}>
                  <label onClick={() => setfilterCobrador(null)}>
                    Todas
                  </label>
                  <label onClick={() => setfilterCobrador('false')}>
                    Cobrador asignado
                  </label>
                  <label onClick={() => setfilterCobrador('true')}>
                    Sin asignar
                  </label>
                </div>
              )}
            </button>
          </div>
          <div className="col-12 col-md-4 btn-content-filt margin-filter content-on">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <button onClick={openDrop2} className="button-secondary btn-p-cl p-r">
              &nbsp;&nbsp;
              {selectedOption}
              &nbsp;
              <i className="btn-icon">&#59657;</i>
              &nbsp;&nbsp;
              {dropDown2 && (
                <div className="drop-menu">
                  {selectedOption !== 'Más Antiguos' && (
                    <label onClick={() => changeOrderList('desc')}>
                      Mas Antiguos
                    </label>
                  )}
                  {selectedOption !== 'Más Recientes' && (
                    <label onClick={() => changeOrderList('asc')}>
                      Mas Recientes
                    </label>
                  )}
                  {selectedOption !== 'Por número de contrato' && (
                    <label onClick={() => changeOrderList('noContratoNumerico')}>
                      Por número de contrato
                    </label>
                  )}
                </div>
              )}
            </button>
          </div>
        </div>
        {contractList && checkPermisionRoute('Contratos', 'verEstatus') && (<ListContractComponent data={contractList} />)}
        {contractList && !checkPermisionRoute('Contratos', 'verEstatus') && (<ListContractCobranzaComponent data={contractList} />)}
        {total === 0 && !searchEnabled && (
          <NotFoundAlert
            icon="&#59671;"
            title="Aún no se ha registrado ningún contrato"
            message="Aquí podrás visualizar el resumen de los contratos que hayan sido registrados."
          />
        )}
        {total === 0 && searchEnabled && (
          <NotFoundAlert
            icon="&#59673;"
            title="No hay resultados"
            message="Lo sentimos, parece que no hemos podido encontrar ningún resultado para tu búsqueda."
          />
        )}
        {total > 0 && (
          <div className="row m-t-l">
            <div className="col-12 col-md-6">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <label className="text-pag">{page} de {totalPages}</label>
            </div>
            <div className="col-12 col-md-6 pag-content-btn">
              <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
              <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListContractView;
