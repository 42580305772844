/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useState } from 'react';
import parse from 'html-react-parser';
import ImageDef from '../../../products/imagedefault/imagedefault';
import { updateProspectsById } from '../../servives';
import AsignarProd from '../asignarProducto/asignarProd';

const InterestsProsp = ({
  data, eventShowMessage, refresh, permision
}) => {
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const [openAsigProd, setOpenAsigProd] = useState(false);
  const [prodPos, setProdPos] = useState(null);

  const removeProduct = async (id) => {
    if (data && data.productos) {
      const tempP = [];
      data.productos.forEach((itemP) => {
        if (itemP.idProducto !== id) {
          const tempData = {
            cantidad: itemP.cantidad,
            idProducto: itemP.idProducto,
            disponibilidad: itemP.disponibilidad || 'prevision',
          };
          tempP.push(tempData);
        }
      });
      const finalP = {
        productos: tempP
      };
      await updateProspectsById(data._id, finalP).then((resp) => {
        if (resp && !resp.errors) {
          refresh();
          eventShowMessage('Producto eliminado de la lista.');
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    }
  };

  const eventAsigProd = (item) => {
    setOpenAsigProd(true);
    setProdPos(item);
  };

  const eventCloseAsigProd = () => {
    setOpenAsigProd(false);
  };
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };
  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = (`${tempM[0]}<small>${tempM[1]}</small>`);
      return (tempHtml);
    }
    return money;
  };

  const getProductStatus = (id) => (data.productosL.find((prod) => prod._id === id) ? data.productosL.find((prod) => prod._id === id).status : 0);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-1"> </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-10">
          <br />
          <br />
          <div className="row">
            <div className="col-12 col-md-9">
              <h2>Productos de interés</h2>
            </div>
            <div className="col-12 col-md-3 btn-content-filt">
              {permision('Prospectos', 'agregarProductos') && (
                <button className="button" type="button" onClick={() => eventAsigProd()}>
                  &nbsp;&nbsp;Agregar&nbsp;&nbsp;
                  <i className="btn-icon">&#59672;</i>
                </button>
              )}
            </div>
          </div>
          <br />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <small className="text-title-tab">NOMBRE</small>
              </div>
              <div className="col-12 col-sm-9 col-md-3  pad-0">
                <small className="text-title-tab">PLAN</small>
              </div>
              <div className="col-12 col-sm-3 col-md-3 pad-0">
                <small className="text-title-tab ">PRECIO</small>
              </div>
            </div>
            {data && data.productos && data.productos[0] && data.productos.map((item) => (
              <div key={generateKey(item._id)} className="row">
                <div className="option-tab">
                  <div className="col-2  col-sm-2 col-md-1">
                    <ImageDef
                      name={item?.nombre || '-'}
                      size=""
                      imgProd={(item?.imagen && item.imagen[0]) ? item.imagen[0].url : '/assets/img/imageProDef.png'}
                    />
                  </div>
                  <div className="col-10 col-sm-10 col-md-5">
                    <label className="text-name">{item.nombre || '-'}</label>
                    <label className="text-email">{item.tipo || '-'}</label>
                    {getProductStatus(item.idProducto) === 0 ? (
                      <span className="text-regular caption-error">
                        <i className="text-icon me-1">&#59668;</i>
                        Producto no disponible
                      </span>
                    ) : (<span />)}
                  </div>
                  <div className="col-12 col-md-3">
                    <label className="text-name">{item?.disponibilidad?.toUpperCase() || '-'}</label>
                    <label className="text-email">
                      X
                      {item.cantidad || '-'}
                    </label>
                  </div>
                  <div className="col-12 col-md-3 p-r">
                    {item?.precioOriginal ? (
                      <>
                        <label className="text-precio suby text-money">
                          {parse(styleMoney(preFormatMoney(item.precioOriginal)))}
                        </label>
                        <label className="text-precio  text-money">
                          {item.disponibilidad && item.disponibilidad === 'prevision'
                            ? parse(styleMoney(preFormatMoney(item?.precioPrevision || 0)))
                            : parse(styleMoney(preFormatMoney(item?.precio || 0)))}
                        </label>
                      </>
                    ) : (
                      <label className="text-precio  text-money">
                        {item.disponibilidad && item.disponibilidad === 'prevision'
                          ? parse(`${styleMoney(preFormatMoney(item?.precioPrevision || 0))}`)
                          : parse(`${styleMoney(preFormatMoney(item?.precio || 0))}`)}
                      </label>
                    )}
                    <i className="options-btn" onClick={() => removeProduct(item.idProducto)}>
                      <p>-</p>
                    </i>
                  </div>
                </div>
                <hr className="notif-line-spacing" />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-1"> </div>
      </div>
      {openAsigProd && (
        <AsignarProd
          eventCP={eventCloseAsigProd}
          dataProd={prodPos}
          datap={data}
          eventShowMessage={eventShowMessage}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default InterestsProsp;
