// import  { Link, useNavigate }  from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormikStep1 } from '../../formik/formikAdd';
import maritalStatus from '../../../../global/data/maritalStatus.json';
// const [Nombre, setNombre] = useState('');

const FrmDatosEmpleado = ({ setDataForm, nextStep, defVal }) => {
  const [submitted1, setSubmitted1] = useState(false);
  const [status, setStatus] = useState('idle');
  const [defaultVal] = useState({
    apellidoM: '',
    apellidoP: '',
    fechaN: '',
    email: '',
    genero: 'masculino',
    nombre: '',
    telefono: '',
    estadoc: '',
    RFC: ''
  });

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FormikStep1(defaultVal);

  useEffect(() => {
    if (isValid || dirty) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [isValid, dirty]);

  const {
    nombre, apellidoP, apellidoM, telefono, email, fechaN, genero, estadoc, RFC,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted1(true);
    // nextStep();
    if (isValid && dirty) {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
    }
  };

  useEffect(() => {
    if (defVal) {
      setFieldValue('nombre', defVal.nombre);
      setFieldValue('apellidoP', defVal.apellidoP);
      setFieldValue('apellidoM', defVal.apellidoM);
      setFieldValue('fechaN', defVal.fechaN);
      setFieldValue('genero', defVal.genero);
      setFieldValue('telefono', defVal.telefono);
      setFieldValue('estadoc', defVal.estadoc);
      setFieldValue('email', defVal.email);
      setFieldValue('RFC', defVal.RFC);
    }
  }, [defVal]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="nombre" className="label mt-m ">
            Nombre(s)
            <input
              id="nombre"
              type="text"
              className={`input ${submitted1 && errors.nombre && 'has-error'}`}
              name="nombre"
              value={nombre}
              onChange={handleChange('nombre')}
            />
            {submitted1 && errors.nombre && (
              <small className="caption-error">
                {errors.nombre}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="apellidoP" className="label mt-m">
            Primer Apellido
            <input
              id="apellidoP"
              type="text"
              className={`input ${submitted1 && errors.apellidoP && 'has-error'}`}
              name="apellidoP"
              value={apellidoP}
              onChange={handleChange('apellidoP')}
            />
            {submitted1 && errors.apellidoP && (
              <small className="caption-error">
                {errors.apellidoP}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="apellidoM" className="label mt-m">
            Segundo Apellido
            <input
              id="apellidoM"
              type="apellidoM"
              className={`input ${submitted1 && errors.apellidoM && 'has-error'}`}
              name="apellidoM"
              value={apellidoM}
              onChange={handleChange('apellidoM')}
            />
            {submitted1 && errors.apellidoM && (
              <small className="caption-error">
                {errors.apellidoM}
              </small>
            )}
            <small>(Opcional)</small>
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Fecha de nacimiento
            <input
              id="fechaN"
              type="date"
              className={`input input-date ${submitted1 && errors.fechaN && 'has-error'}`}
              value={fechaN}
              placeholder="DD/MM/AAAA"
              onChange={handleChange('fechaN')}
            />
            {submitted1 && errors.fechaN && (
              <small className="caption-error">
                {errors.fechaN}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Género
            <br />
            <button type="button" onClick={() => setFieldValue('genero', 'masculino')} className={`btn-gender ${genero === 'masculino' ? 'active' : ''} genderM`}>Masculino</button>
            <button type="button" onClick={() => setFieldValue('genero', 'femenino')} className={`btn-gender ${genero === 'femenino' ? 'active' : ''} genderF`}>Femenino</button>
            {submitted1 && errors.genero && (
              <small className="caption-error">
                {errors.genero}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="saestadestadoCivilCivilmple" className="label mt-m">
            Estado civil
            <select
              type="estadoCivil"
              value={estadoc}
              onChange={handleChange('estadoc')}
            >
              <option value="">Seleccione una opción</option>
              {maritalStatus.map((items) => (
                <option key={items.value} name={items.value} value={items.value}>
                  {items.label}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="Tele" className="label mt-m">
            Teléfono
            <input
              id="Tele"
              type="number"
              className={`input ${submitted1 && errors.telefono && 'has-error'}`}
              placeholder="10 dígitos"
              name="telefono"
              value={telefono}
              onChange={handleChange('telefono')}
            />
            {submitted1 && errors.phone && (
              <small className="caption-error">
                {errors.telefono}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Correo electrónico
            <input
              id="email"
              type="email"
              className={`input ${submitted1 && errors.email && 'has-error'}`}
              value={email}
              placeholder=""
              onChange={handleChange('email')}
            />
            {submitted1 && errors.email && (
              <small className="caption-error">
                {errors.email}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="rfc" className="label mt-m ">
            RFC
            <input
              id="rfc"
              type="text"
              className={`input ${submitted1 && errors.RFC && 'has-error'}`}
              name="rfc"
              value={RFC}
              onChange={handleChange('RFC')}
            />
            {submitted1 && errors.RFC && (
              <small className="caption-error">
                {errors.RFC}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Siguiente paso
          </button>
        </div>
      </div>
      <br />
      <br />
    </form>
  );
};

export default FrmDatosEmpleado;
