import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './global/styles/styles.css';
import './global/styles/input.css';
import './global/styles/margin.css';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { ENVIRONMENT } from './global/constants';
import { injectStore, injectLogout } from './store/axios';
import { logoutUser } from './features/auth/reducersAuth/auth';

// Para utilizar el store y la acción de logOut en los interceptores de Axios
injectStore(store);
injectLogout(logoutUser);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {ENVIRONMENT !== 'DEV' ? (
      <Provider store={store}>
        <App />
      </Provider>
    ) : (
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    )}
  </>
);
/*
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
 */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
