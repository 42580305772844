import axios from '../../../store/axios';

export const getProductos = async (query) => {
  try {
    const res = await axios().get(`productos/buscar?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getProductsByPage = async ({
  nombre,
  sucursal,
  fechaOrden,
  paginacion,
  paginas,
  disponibilidad = undefined,
  status,
  valorInmediato,
}) => {
  try {
    const res = await axios().get('productos/buscar', {
      params: {
        nombre,
        sucursal,
        fechaOrden,
        paginacion,
        paginas,
        disponibilidad,
        status,
        valorInmediato,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getProductByID = async (idProduct) => {
  try {
    const res = await axios().get(`productos/buscar/${idProduct}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getSucursales = async (query = '&paginacion=1&paginas=1000') => {
  try {
    const res = await axios().get(`sucursales/buscar?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadFile = async (file, pathI) => {
  try {
    const form = new FormData();
    form.append('path', pathI);
    form.append('file', file);
    form.append('ref', 'productos');
    const res = await axios().post('aws', form);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateFile = async (file, key) => {
  try {
    const form = new FormData();
    form.append('key', key);
    form.append('file', file);
    form.append('tipo', 'img');
    const res = await axios().put('aws/file/update', form);
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteFile = async (key) => {
  try {
    const res = await axios().post('aws/file/delete', { key });
    return res;
  } catch (err) {
    return err;
  }
};

export const registrarProducto = async (params) => {
  try {
    const res = await axios().post('productos', params);
    return res;
  } catch (err) {
    return err;
  }
};

export const actualizarProducto = async (id, data) => {
  try {
    const res = await axios().put(`productos/actualizar/${id}`, data);
    return res;
  } catch (err) {
    return err;
  }
};
