import axios from '../../../store/axios';

export const getContractByID = async (idContract) => {
  try {
    const res = await axios().get(`ventas/buscar/${idContract}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateContract = async (idContract, params) => {
  try {
    const res = await axios().put(`ventas/actualizar/${idContract}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const getClientByID = async (idClient) => {
  try {
    const res = await axios().get(`clientes/buscar/${idClient}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmployeeByID = async (idClient) => {
  try {
    const res = await axios().get(`empleados/buscar/${idClient}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmployeeByRol = async (query) => {
  try {
    const res = await axios().get(`empleados/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadFileC = async (file, pathI) => {
  try {
    const form = new FormData();
    form.append('path', pathI);
    form.append('file', file);
    form.append('ref', 'beneficiarios');
    const res = await axios().post('aws', form);
    return res;
  } catch (err) {
    return err;
  }
};

export const findCP_S = async (query) => {
  try {
    const res = await axios().get(`/cp/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const insertBeneficiarie = async (data) => {
  try {
    const res = await axios().post('beneficiaries', data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateBeneficiarie = async (idBenef, params) => {
  try {
    const res = await axios().put(`/beneficiaries/actualizar/${idBenef}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const getBeneficiarieByID = async (idB) => {
  try {
    const res = await axios().get(`beneficiaries/buscar/${idB}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getContractsList = async ({
  estatus, paginacion, paginas, noContrato, orden, sinCobrador,
}) => {
  try {
    const res = await axios().get('ventas/buscar', {
      params: {
        paginacion: paginacion || 1,
        orden: orden || undefined,
        paginas: paginas || 10,
        estatus,
        noContrato,
        sinCobrador: sinCobrador || undefined,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateVentaById = async (id, params) => {
  try {
    const res = await axios().put(`ventas/actualizar/${id}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadFileContract = async (file, pathI, idVenta) => {
  try {
    const form = new FormData();
    form.append('file', file);
    form.append('path', pathI);
    form.append('idVenta', idVenta);
    form.append('ref', 'clientes');
    const res = await axios().post(
      '/contratos/archivo',
      form,
      {
        method: 'HEAD',
        mode: 'no-cors',
      }
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const downloadFileContrServ = async (path) => {
  try {
    const response = await axios().get(
      `pdf/pdf-contrato?id=${path}`,
      {
        method: 'GET',
        responseType: 'blob',
      }
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getProducts = async (params) => {
  try {
    const res = await axios().get(`productos/buscar?${params}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getLatestContract = async () => {
  try {
    const res = await axios().get('ventas/ultimoNoContrato');
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllSucursales = async (query = '&paginacion=1&paginas=1000') => {
  try {
    const res = await axios().get(`sucursales/buscar?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const updateClient = async (idClient, params) => {
  try {
    const res = await axios().put(`clientes/actualizar/${idClient}`, params);
    return res;
  } catch (err) {
    return err;
  }
};
