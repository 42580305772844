import parse from 'html-react-parser';
import ImageDef from '../../products/imagedefault/imagedefault';
import { updateVentaById } from '../services';

const ProductsContract = ({
  contractEditable,
  data,
  eventShowMessage,
  refresh,
  contrato,
  eventAddProducts,
  getProductStatus,
  permissions,
}) => {
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const removeProduct = async (id) => {
    if (data && contrato && contrato.estatus_contrato.toLowerCase() !== 'firmado') {
      const tempP = [];
      data.forEach((itemP) => {
        if (itemP.idProducto !== id) {
          const tempData = {
            cantidad: itemP.cantidad,
            idProducto: itemP.idProducto,
            disponibilidad: itemP.disponibilidad || 'prevision',
            precioInicial: itemP.precioInicial,
            precioDescuento: itemP.precioDescuento,
            tipo: itemP.tipo || undefined,
            codigo: itemP.codigo || undefined,
            // sucursales: item.sucursales || undefined,
            nombre: itemP.nombre || undefined,
            caracteristicas: itemP.caracteristicas || undefined,
            categoria: itemP.categoria || undefined,
            galeria: itemP.galeria || undefined,
          };
          if (itemP.imagen) {
            const json = itemP.imagen;
            const key = '_id';
            json.forEach((_itm, inx) => {
              delete json[inx][key];
            });
            tempData.imagen = json;
          }
          if (itemP.idDescuento) {
            tempData.idDescuento = itemP.idDescuento;
          }
          tempP.push(tempData);
        }
      });
      const finalP = {
        productos: tempP
      };
      await updateVentaById(contrato._id, finalP).then((resp) => {
        if (resp && !resp.errors) {
          refresh();
          eventShowMessage('Producto eliminado de la lista.');
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    } else {
      eventShowMessage('No es posible eliminar productos y/o servicios.');
    }
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 0.00;
  };
  const styleMoney = (money) => {
    if (money && money !== 0.00) {
      const tempM = money.split('.');
      const tempHtml = (`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-12">
          <br />
          <br />
          <div className="row">
            <div className="col-12 col-md-9">
              <h2>Productos asociados</h2>
            </div>
            <div className="col-12 col-md-3 btn-content-filt">
              {contrato && ((contrato.estatus_contrato !== 'firmado' && contrato.estatus_contrato !== 'liquidado') || permissions('Contratos', 'editAnyContract')) && (
                <button
                  className="button"
                  disabled={!contractEditable}
                  type="button"
                  onClick={() => eventAddProducts()}
                >
                  &nbsp;&nbsp;Agregar&nbsp;&nbsp;
                  <i className="btn-icon">&#59672;</i>
                </button>
              )}
            </div>
          </div>
          <br />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <small className="text-title-tab">NOMBRE</small>
              </div>
              <div className="col-9 col-md-3  pad-0">
                <small className="text-title-tab">PLAN</small>
              </div>
              <div className="col-3 col-md-3 pad-0">
                <small className="text-title-tab ">PRECIO</small>
              </div>
            </div>
            {data && data[0] && data.map((item) => (
              <div key={generateKey(item._id)} className="row">
                <div className="option-tab">
                  <div className="col-1 col-md-1">
                    <ImageDef
                      name={item.nombre}
                      size=""
                      imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : '/assets/img/imageProDef.png'}
                    />
                  </div>
                  <div className="col-11 col-md-5">
                    <label className="text-name">{item.nombre}</label>
                    <label className="text-email">{item.tipo}</label>
                    {getProductStatus(item.idProducto) === 0 && (
                      <span className="text-regular caption-error">
                        <i className="text-icon me-1">&#59668;</i>
                        Producto no disponible
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-3">
                    <label className="text-name">{item.disponibilidad === 'inmediata' ? 'Uso inmediato' : 'Previsión'}</label>
                    <label className="text-email">
                      X
                      {item.cantidad}
                    </label>
                  </div>
                  <div className="col-12 col-md-3 p-r">
                    {item?.precioOriginal && item?.precioInicial
                      && item?.precioOriginal !== item?.precioInicial ? (
                        <>
                          <label className="text-precio suby text-money">
                            {parse(styleMoney(preFormatMoney(item.precioOriginal)))}
                          </label>
                          <label className="text-precio  text-money">
                            {item.disponibilidad && item.disponibilidad === 'prevision'
                              ? parse(styleMoney(preFormatMoney(item.precioPrevision || '0')))
                              : parse(styleMoney(preFormatMoney(item.precio || '0')))}
                          </label>
                        </>
                      ) : (
                        <label className="text-precio  text-money">
                          {item.disponibilidad && item.disponibilidad === 'prevision'
                            ? parse(`${styleMoney(preFormatMoney(item?.precioPrevision)) || styleMoney(preFormatMoney(item?.precioInicial)) || '-'}`)
                            : parse(`${styleMoney(preFormatMoney(item?.precio)) || styleMoney(preFormatMoney(item?.precioInicial)) || '-'}`)}
                        </label>
                      )}
                    {contractEditable && ((contrato.estatus_contrato !== 'firmado' && contrato.estatus_contrato !== 'liquidado')
                      || permissions('Contratos', 'editAnyContract')) && (
                        <i
                          className="options-btn"
                          onClick={() => removeProduct(item.idProducto)}
                        >
                          <p>-</p>
                        </i>
                    )}
                  </div>
                </div>
                <hr className="notif-line-spacing" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsContract;
