import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useDiscountForm } from '../../formik/discountForm';
import { updateDiscount } from '../../services';

const EditDiscountForm = ({ currentData, onClose }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const {
    values,
    isValid,
    handleChange,
    errors,
  } = useDiscountForm(currentData);

  const {
    nombre,
    porcentaje
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }

    const updateData = {
      nombre,
      porcentaje: parseFloat(porcentaje),
    };

    const res = await updateDiscount(currentData._id, updateData);

    if (!res.errors) {
      toast('Descuento actualizado.', {
        className: 'message',
      });
      queryClient.invalidateQueries(['discounts', 'all']);
      onClose();
    } else {
      throw res.errors;
    }
    try {
      setSubmitting(true);
    } catch (err) {
      toast('No se pudo actualizar el descuento, intente de nuevo mas tarde.', {
        className: 'message error',
      });
    }
  };

  return (
    <div className="row align-items-center justify-content-center">
      <div className="col-12 col-lg-8 col-xl-6 ">
        <div className="content-buttom">&nbsp;</div>
        <div className="d-flex justify-content-between">
          <h2>Editar descuento</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Título
                  <input
                    onChange={handleChange('nombre')}
                    value={nombre}
                    id="nombre"
                    type="text"
                    className={`input ${errors.nombre && 'has-error'}`}
                  />
                  {errors.nombre && (
                    <small className="caption-error">
                      {errors.nombre}
                    </small>
                  )}
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="porcentaje" className="label mt-m">
                  Descuento
                  <input
                    onChange={handleChange('porcentaje')}
                    value={porcentaje}
                    id="porcentaje"
                    type="text"
                    maxLength="10"
                    placeholder="%"
                    className={`input ${errors.porcentaje && 'has-error'}`}
                  />
                  {errors.porcentaje && (
                    <small className="caption-error">
                      {errors.porcentaje}
                    </small>
                  )}
                </label>
              </div>

            </div>
            <div className="row mt-m">
              <div className="col-12">
                <button
                  type="button"
                  className="button-secondary p-r m-btn-prof"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  className="button"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDiscountForm;
