import { useNavigate } from 'react-router';
import AddProspectsC from '../../features/prospects/AddProspectsC';

const AddProspects = () => {
  const navigate = useNavigate();

  const closeForm = () => {
    navigate('/prospects', { replace: true });
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={closeForm}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6">
            <AddProspectsC closeForm={closeForm} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProspects;
