/* eslint-disable no-unused-vars */
import { useState } from 'react';
import AddProspectMSuc from './components/addProspectC/addProspectMSuc';
import AddProspectMProduct from './components/addProspectC/addProspectMProdc';
import AddProspectForm from './components/addProspectC/addProspectForm';
import RespAddProspect from './components/addProspectC/respAddProspect';
import { createPropspects } from './servives/index';

const AddProspectM = ({ eventOp, statusOp }) => {
  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [respCreate, setRespCreate] = useState(null);

  const onClose = () => {
    setStateStep1('active');
    setStateStep2('disabled');
    setStateStep3('disabled');
    setData1(null);
    setData2(null);
    eventOp();
  };

  const saveData1 = (dataFrm) => {
    setData1(dataFrm);
    setStateStep1('');
    setStateStep2('active');
  };

  const saveData2 = (dataFrm) => {
    setData2(dataFrm);
    setStateStep1('');
    setStateStep2('');
    setStateStep3('active');
  };

  const saveData3 = async (dataFrm) => {
    dataFrm.sucursales = {
      idSucursal: data1._id,
    };
    const tempP = [];
    data2.forEach((item) => {
      tempP.push({
        idProducto: item._id,
        cantidad: item.Cantidad,
        disponibilidad: item._Disponibilidad,
      });
    });
    dataFrm.productos = tempP;
    const createP = await createPropspects(dataFrm);
    if (createP && createP._id) {
      setRespCreate('success');
    } else {
      setRespCreate('error');
    }
  };

  return (
    <>
      {statusOp && (
        <div className="absolute-content trasp ">
          {stateStep1 === 'active' && <AddProspectMSuc eventOp={onClose} statusOp={statusOp} setData={saveData1} />}
          {stateStep2 === 'active' && <AddProspectMProduct eventOp={onClose} statusOp={statusOp} setData={saveData2} sucId={data1} />}
          {stateStep3 === 'active' && <AddProspectForm eventOp={onClose} saveData={saveData3} />}
          {respCreate && <RespAddProspect respCreate={respCreate} eventOp={onClose} />}
        </div>
      )}
    </>
  );
};

export default AddProspectM;
