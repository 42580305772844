import { useFormik } from 'formik';
import * as Yup from 'yup';

export const StepP1 = ({
  titulo = '',
  categoria = '',
  descripcion = '',
} = {}) => useFormik({
  validateOnMount: false,
  initialValues: {
    titulo,
    categoria,
    descripcion,
    controlF: 0,
  },
  validationSchema: Yup.object({
    titulo: Yup.string()
      .required('El Título es requerido'),
    categoria: Yup.string()
      .required('La Categoría es requerida'),
    descripcion: Yup.string()
      .required('La Descripción es requerida'),
  })
});

export const StepP2 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    imgPortada: '',
    imgGaleria: [{
      img: ''
    }],
    controlF: 0,
  },
  validationSchema: Yup.object({
    imgPortada: Yup.string()
      .required('La imagen de portada es requerida'),
    imgGaleria: Yup.array().of(
      Yup.object().shape({
        img: Yup.string()
      })
    ).min(2, 'Se requiere al menos 1 imagen en la galería')
  })
});

export const StepP3 = () => useFormik({
  validateOnMount: false,
  initialValues: {
    sucursales: [{
      value: '',
      label: '',
    }],
  },
  validationSchema: Yup.object({
    sucursales: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    ).required('La Sucursal es requerida'),
  })
});

export const StepP4 = () => useFormik({
  validateOnMount: false,
  initialValues: {
    sucursales: [{
      sucursal: '',
      planes: '',
      unidades: '',
    }],
  },
  validationSchema: Yup.object({
    sucursales: Yup.array().of(
      Yup.object().shape({
        sucursal: Yup.string(),
        planes: Yup.string(),
        unidades: Yup.number(),
      })
    ).required('La información es requerida'),
  })
});

export const StepP5 = () => useFormik({
  validateOnMount: false,
  initialValues: {
    sucursales: [{
      sucursal: '',
      precio_normal: '0',
      precio_prevision: '0',
    }],
  },
  validationSchema: Yup.object({
    sucursales: Yup.array().of(
      Yup.object().shape({
        sucursal: Yup.string(),
        precio_normal: Yup.string(),
        precio_prevision: Yup.string(),
      })
    ).required('La información es requerida'),
  })
});

export const usePriceForm = ({
  sucursal = '',
  precio = '0',
  precioPrevision = '0',
  stock = '0',
  plan = '',
} = {}) => useFormik({
  validateOnMount: false,
  initialValues: {
    sucursal,
    precio,
    precioPrevision,
    stock,
    plan
  },
  validationSchema: Yup.object({
    sucursal: Yup.string(),
    precio: Yup.string()
      .when('plan', {
        is: (value) => (value === 'todos' || value === 'inmediata'),
        then: Yup.string()
          .required('El precio es requerido'),
        otherwise: Yup.string(),
      }),
    precioPrevision: Yup.string()
      .when('plan', {
        is: (value) => (value === 'todos' || value === 'prevision'),
        then: Yup.string()
          .required('El precio a previsión es requerido'),
        otherwise: Yup.string(),
      }),
    stock: Yup.string().required('Las unidades en stock son requeridas'),
  })
});
