import GMenuForm from '../menu';

const Home = () => (
  <div>
    <GMenuForm />
    <div className="container">
      <div className="row">
        <div className="col-1" />
        <div className="col-10">
          <h2>Bienvenido</h2>
        </div>
        <div className="col-1" />
      </div>
    </div>
  </div>
);

export default Home;
