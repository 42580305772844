export const checkPermission = (rolAllow = []) => {
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log(rolAllow, user.role, rolAllow.includes(user.role));
  if (rolAllow.includes(user?.role)) {
    return true;
  }
  return false;
};

const defaultJobPermissions = [
  ['gerente sistemas', 'gerente de sucursal', 'gerente administrativo', 'gerente general', 'asistente administrativo', 'asesor de ventas', 'gerente de ventas'],
  ['gerente sistemas', 'gerente de sucursal', 'gerente administrativo', 'gerente general', 'asistente administrativo'],
  ['gerente sistemas', 'gerente administrativo', 'asistente administrativo'],
  ['gerente general', 'gerente de ventas'],
  ['gerente sistemas', 'gerente de sucursal', 'gerente administrativo', 'gerente general', 'asistente administrativo', 'gerente de ventas'],
];

export const checkPermissionJob = (jobAllow = [], defJobs = -1) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user?.role && user?.puesto) {
    if (defJobs === -1) {
      if (jobAllow.includes(user.puesto)) {
        return true;
      }
    } else if (defaultJobPermissions[defJobs].includes(user.puesto)) {
      return true;
    }
  }
  return false;
};

export const checkPermisionRoute = (moduleN, actionN) => {
  const user = JSON.parse(localStorage.getItem('user'));
  let isPermited = false;
  if (user?.modulos) {
    user.modulos.forEach((mod) => {
      if (mod.nombre === moduleN) {
        mod.permisos.forEach((act) => {
          if (actionN === act.accion) {
            isPermited = true;
          }
        });
      }
    });
  }
  return isPermited;
};
