/* eslint-disable no-unused-vars */
import { useState } from 'react';
import MessageInfo from '../../../../messageInfo';
import { step1 } from '../formik';
import '../../../client.css';

const isFormValid = (errors) => !errors.idSucursal;

const ListSucu = ({ data, cambiarOpcionDos, setData }) => {
  const [selectedP, setSelectedP] = useState(null);
  const [status, setStatus] = useState('ok');
  const {
    values,
    errors,
    setFieldValue
  } = step1();

  const {
    idSucursal
  } = values;

  const selectS = (idS) => {
    setSelectedP(idS);
    setStatus('');
    setFieldValue('idSucursal', idS);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid(errors)) {
      setData(values);
      cambiarOpcionDos();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  return (
    <div className="row">
      <div className="col-12">
        <h2>Sucursal de venta</h2>
        &nbsp;
        <p>Seleccione una sucursal</p>
        &nbsp;
        <div className="row messagepp">
          <div className="col-12 col-md-12">
            <MessageInfo
              message="El precio y disponibilidad de los productos podria variar en cada sucursal"
              type=""
            />
          </div>
        </div>
        <div className="list-prod-pros">
          {
            data && data.length > 0 && data.map((item) => (
              <div className="row row-cursor" onClick={() => selectS(item._id)} key={generateKey(item._id)}>
                <div className={selectedP && selectedP === item._id ? 'option-select' : 'option-tab'} value={idSucursal}>
                  <div className="col-12 col-md-12">
                    <label className="text-name">{item.nombre}</label>
                    <label className="text-ubisuc">
                      <i>&#59679;</i>
                      {`${item.ciudad || ''}, ${item.estado || ''}`}
                    </label>
                  </div>
                </div>
                <div className="line-spacing" />
              </div>
            ))
          }
        </div>
        <div className="row ">
          <div className="col-12">
            <div className="col-12 p-r cont-btn">
              <button className="button navR-content-btn" disabled={status === 'ok' ? 'disabled' : ''} type="submit" onClick={handleSubmit}>
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListSucu;
