import axios from '../../../store/axios';

export const getCoupons = async ({
  paginacion,
  paginas,
  codigo,
  valor = '',
}) => {
  try {
    const res = await axios().get('/cupones/buscar', {
      params: {
        paginacion,
        paginas,
        codigo,
        valor
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getDiscounts = async ({
  paginacion, paginas, nombre, valor = '',
}) => {
  try {
    const res = await axios().get('/descuentos/buscar', {
      params: {
        paginacion,
        paginas,
        nombre,
        valor,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getDiscountById = async (id, valor) => {
  try {
    const res = await axios().get(`/descuentos/buscar/${id}`, {
      params: {
        valor: valor || undefined,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const createCoupon = async (data) => {
  try {
    const res = await axios().post('/cupones', data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateCoupon = async (id, data) => {
  try {
    const res = await axios().put(`/cupones/actualizar/${id}`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const createDiscount = async (data) => {
  try {
    const res = await axios().post('/descuentos', data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateDiscount = async (id, data) => {
  try {
    const res = await axios().put(`/descuentos/actualizar/${id}`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const setProductDiscounts = async ({
  idDescuento,
  porcentaje,
  allSucursales = false,
  sucursales = [],
  productos = [],
  planes = undefined,
}) => {
  try {
    const res = await axios().put(
      '/productos/asignar-descuento',
      [
        {
          _id: idDescuento,
          allSucursales,
          porcentaje,
          sucursales,
          productos,
          planes
        }
      ]
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const validateDiscount = async ({
  fecha_inicio,
  fecha_expiracion,
  idDescuento,
  planes,
  productos,
  sucursales,
  allSucursales,
}) => {
  try {
    const res = await axios().post('/productos/validacion', {
      planes: planes || undefined,
      productos: productos || undefined,
      sucursales: sucursales || undefined,
      allSucursales: allSucursales || false,
    }, {
      params: {
        fecha_inicio: typeof fecha_inicio === 'string'
          ? fecha_inicio.split('T')[0]
          : fecha_inicio.toISOString().split('T')[0],
        fecha_expiracion: typeof fecha_expiracion === 'string'
          ? fecha_expiracion.split('T')[0]
          : fecha_expiracion.toISOString().split('T')[0],
        idDescuento: idDescuento || undefined,
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};
