/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

const DropdownFilter = ({
  activeValue,
  options,
  setActiveValue,
  defaultLabel = 'Seleccionar',
}) => {
  const [open, setOpen] = useState(false);
  const [activeLabel, setActiveLabel] = useState(undefined);

  const filteredOptions = activeValue
    ? options.filter((op) => op.value !== activeValue)
    : options;

  const getLabel = (code) => options.find((el) => el.value === code);

  useEffect(() => {
    if (activeValue && options) {
      const tempLabel = getLabel(activeValue);
      setActiveLabel(tempLabel?.label || undefined);
    }
  }, [activeValue, options]);

  return (
    <div className="margin-filter position-relative content-on p-r">
      <button
        onClick={() => setOpen(!open)}
        className="button-secondary btn-p-cl p-r"
      >
        &nbsp;&nbsp;
        <span className="text-capitalize">
          {activeLabel || defaultLabel}
        </span>
        &nbsp;
        <i className="btn-icon">&#59657;</i>
        &nbsp;&nbsp;
        {open && (
          <div className="drop-menu">
            {filteredOptions.map((option) => (
              <label key={option.value} onClick={() => setActiveValue(option.value)}>
                {option.label}
              </label>
            ))}
          </div>
        )}
      </button>
    </div>
  );
};

export default DropdownFilter;
