/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import parse from 'html-react-parser';
import GMenuForm from '../menu';
import ProfileDefComponent from '../shared/profileDef';
import NavPage from '../navPage';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import InterestsProsp from './components/detailsProspects/interestsProsp';
import DetailsProspInfo from './components/detailsProspects/detailsProspInfo';
import SelectEmployee from './components/selectEmployee/selectEmployee';
import ConvertProspectView from './components/convertProspect/convertProspectView';
import { getProspectsByIdInfo, updateProspectsById } from './servives';
import ViewEditDataProsComp from './components/editDataPros/editDataPros';
import SeguimientoProspect from './components/detailsProspects/seguimientoProspect';
import FinalizeSaleM from './FinalizeSale';
import interesJSON from '../../global/data/interes.json';
import './prospects.css';
import AddProspectQuotationModal from './components/addProspectC/addProspectQuotationModal';
import Backbutton from '../shared/backbutton/backbutton';

const DetailsProspect = ({ idProspect }) => {
  const navigate = useNavigate();
  const [prospect, setProspect] = useState(null);
  const [tracking, setTracking] = useState([]);
  const [foundC, setFoundC] = useState(true);
  const [NameC, setNameC] = useState('');

  const [dropDown1, setDropDown1] = useState(false);

  const [openAsig, setOpenAsig] = useState(false);
  const [openFormConvert, setOpenFormConvert] = useState(false);

  const [openPros, setOpenPros] = useState(false);
  const [prosPos, setProsPos] = useState(null);

  const optionNav = ['Intereses', 'Seguimiento', 'Detalles'];
  const [activeNav, setActiveNav] = useState('Intereses');
  const [selectIntr, setSelectIntr] = useState('Alto');
  const [selectIntrRGB, setSelectIntrRGB] = useState('select-g');
  const [openM, setOpenM] = useState(false);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [quotationProducts, setQuotationProducts] = useState([]);
  const [quotationTotal, setQuotationTotal] = useState(0);
  const [saleEnabled, setSaleEnabled] = useState(true);

  const handleBackButtonClick = () => {
    navigate('/prospects');
  };

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const eventChangeNav = (item) => {
    setActiveNav(item);
  };

  const eventEditPros = (item) => {
    setOpenPros(true);
    setProsPos(item);
  };

  const eventSendCotization = () => {
    if (quotationProducts.length === 0) {
      setQuotationProducts(prospect.productos.map((prod) => ({
        ...prod,
        _Disponibilidad: prod.disponibilidad,
        Cantidad: prod.cantidad,
      })));
      setQuotationTotal(prospect.productos.reduce((sum, prod) => {
        const prodTotal = prod.cantidad * (prod.disponibilidad === 'inmediata' ? prod.precio : prod.precioPrevision);
        // eslint-disable-next-line no-return-assign
        return sum += prodTotal;
      }, 0));
    }
    setShowQuotationModal(true);
  };

  const eventCloseEditPros = () => {
    setOpenPros(false);
  };

  const openDrop1 = () => {
    setDropDown1(!dropDown1);
  };

  const eventOptionAs = () => {
    setOpenAsig(!openAsig);
  };
  const eventViewConvert = () => {
    if (openFormConvert) {
      getProspect();
    }
    setOpenFormConvert(!openFormConvert);
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };
  const eventShowMessage = (textM) => {
    toast(textM, {
      className: 'message'
    });
  };
  const refreshProspect = () => {
    getProspect();
  };
  const updateProspect = (dataNP) => {
    setProspect(dataNP);
  };

  const capitalizeFirstLowercaseRest = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return '-';
  };
  const getProspect = async () => {
    await getProspectsByIdInfo(idProspect).then((data) => {
      if (data && data[0] && data[0].data) {
        setFoundC(true);
        const tempdata = data[0].data;
        let nameUser = `${tempdata.nombre || ''} ${tempdata.apellidoPaterno || ''} ${tempdata.apellidoMaterno || ''}`;
        setNameC(nameUser);

        if (data[0] && tempdata.sexo && tempdata.sexo === 'M') {
          tempdata.sexo = 'Masculino';
        }
        if (data[0] && tempdata.sexo && tempdata.sexo === 'F') {
          tempdata.sexo = 'Femenino';
        }
        if (data[0] && tempdata.sexo) {
          const tempSx = tempdata.sexo;
          const capitalized = tempSx.charAt(0).toUpperCase() + tempSx.slice(1);
          tempdata.sexo = capitalized;
        }
        if (data[0] && tempdata.fecha_nacimiento) {
          const tempD = new Date(tempdata.fecha_nacimiento);
          tempdata.fecha_nacimiento = formatDate(tempD);
        }
        if (data[0] && tempdata.createdAt) {
          const tempD = new Date(tempdata.createdAt);
          tempdata.createdAt = formatDate(tempD);
        }
        if (data[0] && data[0].nombre_empleado) {
          tempdata.nombre_empleado = data[0].nombre_empleado || '';
          tempdata.imagen_empleado = data[0].imagen_empleado || '';
        }
        if (data[0] && tempdata.fecha_nacimiento) {
          const tempD = new Date(tempdata.fecha_nacimiento);
          tempdata.fecha_nacimiento = formatDate(tempD);
        }
        if (data[0] && tempdata.createdAt) {
          const tempD = new Date(tempdata.createdAt);
          tempdata.createdAt = formatDate(tempD);
        }
        if (data[0] && data[0].nombre_empleado) {
          tempdata.nombre_empleado = data[0].nombre_empleado || '';
          tempdata.imagen_empleado = data[0].imagen_empleado || '';
        }
        if (data[0] && data[0].productos) {
          tempdata.productosL = data[0].productos || '';
        }
        let tempTotal = 0;
        try {
          if (tempdata.productos) {
            tempdata.productos.forEach((itemP, index) => {
              tempdata.productosL.forEach((itemPL) => {
                if (itemP.idProducto === itemPL._id) {
                  tempdata.productos[index].descuentos = itemPL.descuentos;
                  tempdata.productos[index].nombre = itemPL.nombre;
                  tempdata.productos[index].imagen = itemPL.imagen;
                  tempdata.productos[index].tipo = capitalizeFirstLowercaseRest(itemPL.tipo);
                  tempdata.productos[index].galeria = itemPL.galeria;
                  if (itemP.disponibilidad && itemP.disponibilidad === 'prevision') {
                    if (itemPL?.descuentos?.prevision?.descuentoPrevision) {
                      tempTotal += (itemP.cantidad * itemPL.descuentos.prevision.descuentoPrevision) || 0;
                      tempdata.productos[index].precioPrevision = itemPL.descuentos.prevision.descuentoPrevision || 0;
                      tempdata.productos[index].precioOriginal = itemPL.precioPrevision || 0;
                    } else {
                      tempTotal += (itemP.cantidad * itemPL.precioPrevision) || 0;
                      tempdata.productos[index].precioPrevision = itemPL.precioPrevision || 0;
                    }
                  } else if (itemP.disponibilidad && itemP.disponibilidad === 'inmediata') {
                    if (itemPL?.descuentos?.usoInmediato?.descuentoUsoInmediato) {
                      tempTotal += (itemP.cantidad * itemPL.descuentos.usoInmediato.descuentoUsoInmediato) || 0;
                      tempdata.productos[index].precio = itemPL.descuentos.usoInmediato.descuentoUsoInmediato || 0;
                      tempdata.productos[index].precioOriginal = itemPL.precio || 0;
                    } else {
                      tempTotal += (itemP.cantidad * itemPL.precio) || 0;
                      tempdata.productos[index].precio = itemPL.precio || 0;
                    }
                  }
                }
              });
            });
          }
        } catch (_error) {
          // eslint-disable-next-line no-console
          console.log(_error);
        }
        if (tempdata.interes) {
          setSelectIntr(tempdata.interes);
        }
        tempdata.TotalP = tempTotal;
        setProspect(tempdata);
      } else {
        setFoundC(false);
        setSelectIntr(undefined);
        setProspect(undefined);
      }
    });
  };

  const eventOpenM = () => {
    setOpenM(!openM);
  };

  const changeIntes = async () => {
    if (prospect && prospect?.interes && prospect?.interes !== selectIntr) {
      const finalP = {
        interes: selectIntr
      };
      await updateProspectsById(idProspect, finalP).then((resp) => {
        if (resp && !resp.errors) {
          eventShowMessage('Interés actualizado.');
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    }
  };

  const verifyProductStatus = () => {
    if (!prospect) {
      return;
    }
    let enableSale = false;
    if (prospect.productosL.length === prospect.productos.length) {
      enableSale = prospect.productosL.length !== 0 && prospect.productosL.every((product) => {
        if (product.status === 0) {
          return false;
        }
        return true;
      });
    }
    setSaleEnabled(enableSale);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const styleMoney = (money) => {
    const tempM = money.split('.');
    const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
    return tempHtml;
  };

  useEffect(() => {
    if (!prospect) {
      return;
    }
    const seguimiento = prospect?.seguimiento ? [...prospect.seguimiento] : [];
    setTracking(seguimiento.reverse());
    verifyProductStatus();
  }, [prospect]);

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idProspect) {
      getProspect();
    } else if (!token) {
      closeSession();
    }
  }, [idProspect]);

  useEffect(() => {
    switch (selectIntr) {
      case 'Alto':
        setSelectIntrRGB('select-g');
        break;
      case 'Medio':
        setSelectIntrRGB('select-y');
        break;
      case 'Bajo':
        setSelectIntrRGB('select-r');
        break;
      default:
        setSelectIntrRGB('');
        break;
    }
    changeIntes();
  }, [selectIntr]);

  return (
    <div>
      <GMenuForm />
      {showQuotationModal && (
        <AddProspectQuotationModal
          prospect={prospect}
          onClose={() => setShowQuotationModal(false)}
          onFinish={() => setShowQuotationModal(false)}
          total={quotationTotal}
          products={quotationProducts}
          sucursal={prospect.sucursales.idSucursal}
          refreshProspectData={() => getProspect()}
        />
      )}
      <FinalizeSaleM eventOp={eventOpenM} statusOp={openM} dataP={prospect} />
      <div className="container filter-container">
        <div className="row">
          <div className="col col-md-12 col-lg-1"> </div>
          <div className="col-12 col-md-12 col-lg-10">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 margin-filter ">
                <Backbutton onClick={handleBackButtonClick} className="btn btn-primary mr-5 mt-0" />
                <br />
                <br />
                <div className="row align-items-center">
                  <div className="col-2">
                    <ProfileDefComponent name={NameC || ''} size="md" />
                  </div>
                  <div className="col-10">
                    {prospect && NameC && (
                      <>
                        <h2>{NameC}</h2>
                        <small>
                          {prospect?.sucursal && prospect?.sucursal?.nombre ? prospect?.sucursal?.nombre : '' || ''}
                        </small>
                      </>
                    )}
                    {!prospect && !foundC && (
                      <h2>No encontrado</h2>
                    )}
                  </div>
                </div>
              </div>
              {foundC && (
                <div className="col-12 col-md-12 col-lg-6 ">
                  <div className="content-btns">
                    {checkPermisionRoute('Prospectos', 'concretarVenta') && (
                      <button
                        disabled={!saleEnabled}
                        onClick={eventOpenM}
                        className="button btn-p-cl p-r"
                      >
                        &nbsp;Concretar venta&nbsp;&nbsp;
                      </button>
                    )}
                    <div className="button-espera btn-p-cl p-r">
                      <i className="btn-icon">&#59678;</i>
                      &nbsp;
                      {prospect && (
                        <>
                          {prospect?.status === 1 ? 'En atencion' : 'En espera'}
                        </>
                      )}
                      &nbsp;
                    </div>
                    <button className="button-secondary p-r" onClick={openDrop1}>
                      <i className="btn-icon">&#59683;</i>
                      {dropDown1 && (
                        <div className="drop-menu xl">
                          {checkPermisionRoute('Prospectos', 'actualizarProspecto') && (
                            <label onClick={() => eventEditPros()}>
                              <i className="btn-iconPro">&#59686;</i>
                              &nbsp;&nbsp;Editar prospecto&nbsp;&nbsp;
                            </label>
                          )}
                          {checkPermisionRoute('Prospectos', 'compartirCotizacion') && (
                            <label onClick={() => eventSendCotization()}>
                              <i className="btn-iconPro">&#59710;</i>
                              &nbsp;&nbsp;Enviar cotización&nbsp;&nbsp;
                            </label>
                          )}
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col col-md-12 col-lg-1"> </div>
          <div className="col-12">
            <br />
          </div>
          {foundC && (
            <div className="col-12">
              <div className="row">
                <div className="col col-md-12 col-lg-1"> </div>
                <div className="col-12 col-md-12 col-lg-10">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <label className="text-name">Valor estimado</label>
                      {prospect && (<label className="text-title-info text-money">{styleMoney(formatMoney.format(prospect?.TotalP))}</label>)}
                      <label className="text-name">(Total)</label>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <label className="text-name">Interés del prospecto</label>
                      <div className="row">
                        <div className="col-6 col-sm-3">
                          <label htmlFor="interes" className="label mt-m">
                            <select
                              type="interes"
                              value={selectIntr}
                              className={`interestSlt ${selectIntrRGB}`}
                              onChange={(e) => setSelectIntr(e.target.value)}
                            >
                              {interesJSON.map((items) => (
                                <option key={items.value} name={items.value} value={items.value}>
                                  {items.label}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-md-12 col-lg-1"> </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container filter-container">
        <div className="row">
          <div className="col col-md-12 col-lg-1"> </div>
          <div className="col-12 col-md-12 col-lg-10">
            <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} />
          </div>
          <div className="col col-md-12 col-lg-1"> </div>
        </div>
      </div>
      {activeNav === 'Intereses' && foundC && <InterestsProsp data={prospect} permision={checkPermisionRoute} eventShowMessage={eventShowMessage} refresh={refreshProspect} />}
      {activeNav === 'Detalles' && foundC && <DetailsProspInfo data={prospect} permision={checkPermisionRoute} eventW={eventOptionAs} eventShowMessage={eventShowMessage} refresh={refreshProspect} />}
      {activeNav === 'Seguimiento' && foundC && <SeguimientoProspect data={tracking || []} prospectId={prospect?._id} permision={checkPermisionRoute} eventW={eventOptionAs} eventShowMessage={eventShowMessage} refresh={refreshProspect} />}
      {openAsig && <SelectEmployee data={prospect} eventW={eventOptionAs} statusW={openAsig} permision={checkPermisionRoute} eventShowMessage={eventShowMessage} refresh={refreshProspect} />}
      {openPros && foundC && (<ViewEditDataProsComp data={prospect} eventDP={eventCloseEditPros} dataPros={prosPos} refresh={refreshProspect} eventShowMessage={eventShowMessage} />)}
      {openFormConvert && <ConvertProspectView data={prospect} updateData={updateProspect} eventV={eventViewConvert} permision={checkPermisionRoute} />}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

    </div>
  );
};

export default DetailsProspect;
