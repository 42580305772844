/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import CuponFormComp from './cuponForm';

const ResumeSaleComp = ({
  dataSale, eventOp, statusOp, setData, eventMessage
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [descuento, setDescuento] = useState(0.00);
  const [formCupon, setFormCupon] = useState(false);
  const [cuponInfo, setCuponInfo] = useState(null);
  const [infoSale, setInfoSale] = useState(null);

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const styleMoney = (money) => {
    const tempM = money.split('.');
    const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
    return tempHtml;
  };

  const nextStet = () => {
    let tempDataP = infoSale;
    tempDataP.descuento = descuento;
    tempDataP.totalFF = calcTotal(tempDataP.TotalP);
    if (cuponInfo) {
      tempDataP.cupones = cuponInfo._id;
    }
    setData(tempDataP);
  };

  const calcTotal = (totalP) => (totalP - descuento) || 0.00;

  const addDiscount = (cupon) => {
    if (cupon) {
      setDescuento(cupon.descuento);
      setCuponInfo(cupon);
      let tempDataP = infoSale;
      tempDataP.descuento = descuento;
      tempDataP.totalFF = calcTotal(tempDataP.TotalP);
      tempDataP.cupones = cupon._id;
      setInfoSale(tempDataP);
    }
    setFormCupon(false);
  };

  const removeDiscount = () => {
    let tempDataP = dataSale;
    setDescuento(0);
    setCuponInfo(null);
    setInfoSale(tempDataP);
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  useEffect(() => {
    if (dataSale) {
      setInfoSale(dataSale);
    }
  }, [dataSale]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid">
            <div className="row">
              {formCupon && (
                <div className="col-6">
                  <span
                    className="icon-close return-icon"
                    onClick={() => setFormCupon(false)}
                  >
                    {' '}
                  </span>
                </div>
              )}
              <div className="col close-form">
                <span
                  className="icon-close cross-line"
                  onClick={eventOp}
                >
                  {' '}
                </span>
              </div>
              {!formCupon && (
                <div className="col-12">
                  <div className="col-12 spacing-nav"> </div>
                  <div className="container-fluid">
                    <div className="row content-nav-padd">
                      <div className="col-12">
                        <h2>Resumen de compra</h2>
                        <br />
                        <br />
                      </div>
                      <div className="col-12">
                        {
                          infoSale && infoSale.productos
                          && infoSale.productos.length > 0 && infoSale.productos.map((item) => (
                            <div key={generateKey(item._id)} className="row">
                              <div className="col-12 ">
                                <p className="text-name">
                                  {item.nombre}
                                  {' '}
                                  (
                                  {item.disponibilidad || '-'}
                                  )
                                </p>
                              </div>
                              <div className="col-6 ">
                                <label className="text-email text-money">
                                  {item.cantidad}
                                  {' '}
                                  x
                                  {' '}
                                  {item.disponibilidad && item.disponibilidad === 'prevision'
                                    ? styleMoney(formatMoney.format(item.precioPrevision || 0.00))
                                    : styleMoney(formatMoney.format(item.precio))}
                                </label>
                              </div>
                              <div className="col-6 pag-content-btn">
                                <label className="text-name text-money">
                                  {item.disponibilidad && item.disponibilidad === 'prevision'
                                    ? styleMoney(formatMoney.format((item.precioPrevision * item.cantidad) || 0.00))
                                    : styleMoney(formatMoney.format((item.precio * item.cantidad)))}
                                </label>
                              </div>
                              <div className="col-12 ">
                                <br />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 ">
                            <label className="text-email">
                              Subtotal
                            </label>
                          </div>
                          <div className="col-6 pag-content-btn">
                            <label className="text-name text-money">
                              {infoSale && infoSale.TotalP && styleMoney(formatMoney.format((infoSale.TotalP)))}
                            </label>
                          </div>
                          <div className="col-12 ">
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 ">
                            <label className="text-email">
                              Descuento
                            </label>
                          </div>
                          <div className="col-6 pag-content-btn">
                            <label className="text-name text-money">
                              -
                              {styleMoney(formatMoney.format(descuento))}
                            </label>
                          </div>
                          <div className="col-12 ">
                            <br />
                          </div>
                        </div>
                      </div>
                      {cuponInfo && (
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 ">
                              <label className="cupon-info">
                                {cuponInfo.cupon}
                                <i onClick={() => removeDiscount()}>&#59655;</i>
                              </label>
                            </div>
                            <div className="col-12 ">
                              <br />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 ">
                            <label className="text-name">
                              Total
                            </label>
                          </div>
                          <div className="col-6 pag-content-btn">
                            <label className="text-name text-money">
                              {infoSale && infoSale?.TotalP && styleMoney(formatMoney.format(calcTotal(infoSale.TotalP)))}
                            </label>
                          </div>
                          <div className="col-12 ">
                            <br />
                          </div>
                        </div>
                      </div>
                      {!cuponInfo && (
                        <div className="col-12 p-r cont-btn">
                          <button className="button-secondary navR-content-btn" type="button" onClick={() => setFormCupon(true)}>
                            Ingresar cupón
                          </button>
                        </div>
                      )}
                      <div className="col-12 p-r cont-btn">
                        <button className="button navR-content-btn" onClick={() => nextStet()} type="button">
                          Crear contrato
                        </button>
                      </div>
                      <div className="col-12 spacing-nav"> </div>
                    </div>
                  </div>
                </div>
              )}
              {formCupon && (
                <CuponFormComp comeBack={eventOp} eventMessage={eventMessage} infoSale={infoSale.TotalP} cuponInfo={addDiscount} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeSaleComp;
