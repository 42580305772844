import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileDefComponent from '../../../shared/profileDef';
import { getAsesores, updateProspectsById } from '../../servives';

const SelectEmployee = ({
  data, eventW, statusW, refresh, eventShowMessage
}) => {
  const navigate = useNavigate();
  const [statusWNav, setStatusWNav] = useState(false);
  const [searchInp, setSearchInp] = useState('');
  const [respSearch, setRespSearch] = useState([]);
  const [selectEmpl, setSelectEmpl] = useState(null);

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const getAsesoresL = async () => {
    const nombre = searchInp ? `buscar=${encodeURIComponent(searchInp).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    const query = `${nombre}&paginacion=1&paginas=10`;

    await getAsesores(query).then((resp) => {
      if (resp && resp.data) {
        setRespSearch(resp.data);
      }
    });
  };

  const updateProspect = async () => {
    if (data && data._id) {
      const finalP = {
        empleados: {
          idEmpleado: selectEmpl
        }
      };
      await updateProspectsById(data._id, finalP).then((resp) => {
        if (resp && !resp.errors) {
          refresh();
          eventShowMessage('Producto eliminado de la lista.');
          eventW();
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    }
  };

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (token) {
        getAsesoresL();
      } else {
        closeSession();
      }
    }
  };

  useEffect(() => {
    setSelectEmpl(null);
  }, [searchInp]);

  useEffect(() => {
    if (statusW) {
      setStatusWNav(statusW);

      setTimeout(() => {
      }, 1000);
    }
  }, [statusW]);
  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col close-form">
                <span
                  className="icon-close cross-line"
                  onClick={eventW}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12 spacing-nav"> </div>
              <div className="container-fluid">
                <div className="row content-nav-padd">
                  <div className="col-12">
                    <h2>Buscar empleado</h2>
                  </div>
                  <div className="col-12 ">
                    <div className="input-container">
                      <input id="buscar" onChange={(e) => setSearchInp(e.target.value)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                      <span className="input-icon search-line" />
                    </div>
                  </div>
                  <div className="col-12 navR-content-ov">
                    {respSearch.map((item) => (
                      <div
                        key={generateKey(item._id)}
                        className="container-fluid "
                        onClick={() => setSelectEmpl(item._id)}
                      >
                        <div className={`row row-itemN ${selectEmpl === item._id ? 'select' : ''}`}>
                          <div className="col-2 cont-img-navR">
                            <ProfileDefComponent name={`${item.nombre || ''} ${item.apellidoPaterno} ${item.apellidoMaterno || ''}` || ''} size="" imgProf={item.imagen_perfil || null} />
                          </div>
                          <div className="col-10 ">
                            <p className="text-capitalize">
                              {item.nombre || ''}
                              {' '}
                              {item.apellidoPaterno || ''}
                              {' '}
                              {item.apellidoMaterno || ''}
                            </p>
                            <p className="nav-text-opacity">{item.email}</p>
                            <label className="text-dir">
                              <i>&#59679;</i>
                              {item.sucursal && item.sucursal.nombre ? `${item.sucursal.nombre}, ${item.sucursal.ciudad}` : ''}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 p-r">
                    <button className="button navR-content-btn" onClick={() => updateProspect()} type="button" disabled={selectEmpl ? '' : 'disabled'}>
                      Asignar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectEmployee;
