/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import countriesData from '../../../../../global/data/countries.json';
import maritalStatus from '../../../../../global/data/maritalStatus.json';
import { FormikStep1, FormikStep1_2 } from '../../../formik/formikStepsConvert';
import { findCP_S, validEmailServ } from '../../../servives';

const FormStept1 = ({
  // eslint-disable-next-line no-unused-vars
  cambiarOpcion, opcionAnterior, saveData, dataP, defDataP, disabledF = false
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = FormikStep1();
  const {
    values: valuesExt,
    handleChange: handleChangeExt,
    isValid: isValidExt,
    dirty: dirtyExt,
    errors: errorsExt,
    setFieldValue: setFieldValueExt,
  } = FormikStep1_2();
  const [localidades, setLocalidades] = useState([]);
  const [localidadesExt, setLocalidadesExt] = useState([]);
  const [validEmail, setValidEmail] = useState(false);
  const [validEmailLoad, setValidEmailLoad] = useState(0);

  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    fechaNacimiento,
    estadoCivil,
    ocupacion,
    telefono,
    email,
    estado,
    ciudad,
    cp,
    colonia,
    direccion,
    sexo,
  } = values;

  const {
    estadoExt,
    ciudadExt,
    cpExt,
    coloniaExt,
    direccionExt,
  } = valuesExt;

  const [submitted, setSubmitted] = useState(false);
  const [colonies, setColonies] = useState(null);
  const [coloniesExt, setColoniesExt] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const [addDirection, setAddDirection] = useState(false);

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };
  const getCPServiceExt = async () => {
    const cpS = `cp=${cpExt}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColoniesExt(null);
            setFieldValueExt('coloniaExt', item.asentamiento);
            setFieldValueExt('ciudadExt', item.municipio);
            setFieldValueExt('estadoExt', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValueExt('ciudadExt', item.municipio);
            setFieldValueExt('estadoExt', item.estado);
          });
          setColoniesExt(tempCol);
        } else {
          setColoniesExt(null);
          setFieldValueExt('coloniaExt', '');
          setFieldValueExt('ciudadExt', '');
          setFieldValueExt('estadoExt', '');
        }
      }
    });
  };

  const verifyEmailExist = async () => {
    setValidEmailLoad(0);
    try {
      await validEmailServ(email).then((data) => {
        if (data && data[0]) {
          setValidEmail(true);
          setValidEmailLoad(validEmailLoad + 1);
        } else {
          setValidEmail(false);
          setValidEmailLoad(validEmailLoad + 1);
        }
      });
    } catch (_error) {
      setValidEmail(false);
      setValidEmailLoad(validEmailLoad + 1);
    }
  };

  useEffect(() => {
    if (cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  useEffect(() => {
    if (cpExt.toString() && cpExt.toString().length > 4) {
      getCPServiceExt();
    } else if (cpExt.toString() && cpExt.toString().length < 5) {
      setColoniesExt(null);
      setFieldValueExt('coloniaExt', '');
      setFieldValueExt('ciudadExt', '');
      setFieldValueExt('estadoExt', '');
    }
  }, [cpExt]);

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  useEffect(() => {
    if (estadoExt) {
      const selectedEntity = countriesData.find((obj) => obj.states === estadoExt);
      if (selectedEntity) {
        setLocalidadesExt(selectedEntity.loc);
      }
    } else {
      setLocalidadesExt([]);
      setFieldValueExt('ciudadExt', '');
    }
  }, [estadoExt]);

  useEffect(() => {
    if (dataP && !defDataP) {
      setFieldValue('ciudad', dataP.ciudad || '');
      setFieldValue('nombre', dataP.nombre || '');
      setFieldValue('apellidoPaterno', dataP.apellidoPaterno || '');
      setFieldValue('apellidoMaterno', dataP.apellidoMaterno || '');
      setFieldValue('telefono', dataP.telefono || '');
      setFieldValue('email', dataP.email || '');
      setFieldValue('cp', dataP.cp || '');
      setFieldValue('colonia', dataP.colonia || '');
      setFieldValue('direccion', dataP.direccion || '');
      setFieldValue('entreCalles', dataP.entre_calles || '');
      setFieldValue('ocupacion', dataP.ocupacion || '');
      setFieldValue('sexo', dataP.sexo ? dataP.sexo.toLowerCase() : '');

      setFieldValue('fechaNacimiento', dataP.fechaNacimiento || '');
      setFieldValue('estadoCivil', dataP.estadoCivil || '');
      setFieldValue('estado', dataP.estado || '');
      setFieldValue('ciudad', dataP.ciudad || '');
      if (dataP.cpExt) {
        setFieldValueExt('cpExt', dataP.cpExt || '');
        setFieldValueExt('coloniaExt', dataP.coloniaExt || '');
        setFieldValueExt('direccionExt', dataP.direccionExt || '');
        setFieldValueExt('estadoExt', dataP.estadoExt || '');
        setFieldValueExt('ciudadExt', dataP.ciudadExt || '');
        setAddDirection(true);
      }
    }
  }, [dataP, defDataP]);

  useEffect(() => {
    if (defDataP) {
      setFieldValue('ciudad', defDataP.ciudad || '');
      setFieldValue('nombre', defDataP.nombre || '');
      setFieldValue('apellidoPaterno', defDataP.apellidoPaterno || '');
      setFieldValue('apellidoMaterno', defDataP.apellidoMaterno || '');
      setFieldValue('telefono', defDataP.telefono || '');
      setFieldValue('email', defDataP.email || '');
      setFieldValue('cp', defDataP.cp || '');
      setFieldValue('colonia', defDataP.colonia || '');
      setFieldValue('direccion', defDataP.direccion || '');
      setFieldValue('entreCalles', defDataP.entre_calles || '');
      setFieldValue('ocupacion', defDataP.ocupacion || '');

      setFieldValue('fechaNacimiento', defDataP.fechaNacimiento || '');
      setFieldValue('sexo', defDataP.sexo ? defDataP.sexo.toLowerCase() : '');
      setFieldValue('estadoCivil', defDataP.estadoCivil || '');
      setFieldValue('estado', defDataP.estado || '');
      setFieldValue('ciudad', defDataP.ciudad || '');
      if (defDataP.DirectionExt) {
        setFieldValueExt('cpExt', defDataP.DirectionExt.cpExt || '');
        setFieldValueExt('coloniaExt', defDataP.DirectionExt.coloniaExt || '');
        setFieldValueExt('direccionExt', defDataP.DirectionExt.direccionExt || '');
        setFieldValueExt('estadoExt', defDataP.DirectionExt.estadoExt || '');
        setFieldValueExt('ciudadExt', defDataP.DirectionExt.ciudadExt || '');
        setAddDirection(true);
      }
    }
  }, [defDataP]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty && !addDirection) {
      cambiarOpcion();
      let tempFormData = values;
      saveData(tempFormData);
    } else if (addDirection && isValid && dirty && isValidExt && dirtyExt && !validEmail) {
      cambiarOpcion();
      let tempFormData = values;
      tempFormData.DirectionExt = valuesExt;
      saveData(tempFormData);
    } else {
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!disabledF && email && email !== '' && email !== null && errors?.email === undefined) {
      verifyEmailExist();
    }
  }, [email, errors?.email]);
  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-xxl">
            <h2>Datos pesonales</h2>
            <div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Nombre(s)
                    <input
                      onChange={handleChange('nombre')}
                      value={nombre}
                      id="nombre"
                      type="text"
                      className={`input ${submitted && errors.nombre && 'has-error'}`}
                      disabled={disabledF}
                    />
                    {submitted && errors.nombre && <small className="caption-error">{errors.nombre}</small>}
                  </label>
                </div>
                <div className="col-12 col-md-6"> </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="apellidoPaterno" className="label mt-m">
                    Primer apellido
                    <input
                      id="apellidoPaterno"
                      type="text"
                      className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                      onChange={handleChange('apellidoPaterno')}
                      value={apellidoPaterno}
                      disabled={disabledF}
                    />
                    {
                      submitted && errors.apellidoPaterno && <small className="caption-error">{errors.apellidoPaterno}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="apellidoMaterno" className="label mt-m">
                    Segundo apellido
                    <input
                      id="apellidoMaterno"
                      type="text"
                      className="input"
                      onChange={handleChange('apellidoMaterno')}
                      value={apellidoMaterno}
                      disabled={disabledF}
                    />
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="fechaNacimiento" className="label mt-m">
                    Fecha de nacimiento
                    <input
                      id="fechaNacimiento"
                      type="date"
                      className={`input input-date ${submitted && errors.fechaNacimiento && 'has-error'}`}
                      onChange={handleChange('fechaNacimiento')}
                      value={fechaNacimiento}
                      disabled={disabledF}
                    />
                    {
                      submitted && errors.fechaNacimiento && <small className="caption-error">{errors.fechaNacimiento}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="label labels mt-m">
                    Género
                    <br />
                    <button disabled={disabledF} type="button" onClick={() => setFieldValue('sexo', 'masculino')} className={`btn-gender ${sexo === 'masculino' ? 'active' : ''} genderM`}>Masculino</button>
                    <button disabled={disabledF} type="button" onClick={() => setFieldValue('sexo', 'femenino')} className={`btn-gender ${sexo === 'femenino' ? 'active' : ''} genderF`}>Femenino</button>
                    {submitted && errors.sexo && (
                      <small className="caption-error">
                        {errors.sexo}
                      </small>
                    )}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="saestadestadoCivilCivilmple" className="label mt-m">
                    Estado civil
                    <select
                      type="estadoCivil"
                      value={estadoCivil}
                      onChange={handleChange('estadoCivil')}
                      disabled={disabledF}
                    >
                      <option value="">Seleccione una opción</option>
                      {maritalStatus.map((items) => (
                        <option key={items.value} name={items.value} value={items.value}>
                          {items.label}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.estadoCivil && <small className="caption-error">{errors.estadoCivil}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="ocupacion" className="label mt-m">
                    Ocupación
                    <input
                      id="ocupacion"
                      type="text"
                      className="input"
                      value={ocupacion}
                      onChange={handleChange('ocupacion')}
                      disabled={disabledF}
                    />
                  </label>
                  <label htmlFor="sample" className="label font-sm ">
                    (Opcional)
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="telefono" className="label mt-m">
                    Teléfono
                    <input
                      id="telefono"
                      type="text"
                      placeholder="10 dígitos"
                      className={`input ${submitted && errors.telefono && 'has-error'}`}
                      onChange={handleChange('telefono')}
                      value={telefono}
                      disabled={disabledF}
                    />
                    {
                      submitted && errors.telefono && <small className="caption-error">{errors.telefono}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="email" className="label mt-m">
                    Correo electrónico
                    <input
                      id="email"
                      type="email"
                      className={`input ${submitted && errors.email && 'has-error'}`}
                      onChange={handleChange('email')}
                      value={email}
                      disabled={disabledF}
                    />
                    {
                      submitted && errors.email && <small className="caption-error">{errors.email}</small>
                    }
                    {validEmail && validEmailLoad !== 0 && (
                      <small className="caption-error">
                        Este correo ya esta registrado
                        <br />
                      </small>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <label htmlFor="sample" className="label mt-m ">
                    Código postal
                    <input
                      id="cp"
                      type="number"
                      maxLength="5"
                      pattern="[0-9]{X,5}"
                      className={`input ${submitted && errors.cp && 'has-error'}`}
                      onChange={handleChange('cp')}
                      value={cp}
                      disabled={disabledF}
                    />
                    {
                      submitted && errors.cp && <small className="caption-error">{errors.cp}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="estado" className="label mt-m">
                    Estado
                    <select
                      type="select"
                      name="estado"
                      className={`${submitted && errors.estado && 'has-error'}`}
                      value={estado}
                      onChange={handleChange('estado')}
                      disabled={disabledF}
                    >
                      <option value="">Estado</option>
                      {countriesData.map(({ states }) => (
                        <option key={states} name={states} value={states}>
                          {states}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="ciudad" className="label mt-m">
                    Localidad o Municipio
                    <select
                      type="select"
                      name="ciudad"
                      className={`${submitted && errors.ciudad && 'has-error'}`}
                      value={ciudad}
                      onChange={handleChange('ciudad')}
                      disabled={disabledF}
                    >
                      <option value="">Localidad</option>
                      {localidades.map((loc) => (
                        <option key={loc} name={loc} value={loc}>
                          {loc}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.ciudad && <small className="caption-error">{errors.ciudad}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="colonia" className="label mt-m ">
                    Colonia
                    {!colonies && (
                      <input
                        id="colonia"
                        type="text"
                        className={`input ${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                        disabled={disabledF}
                      />
                    )}
                    {colonies && (
                      <select
                        type="select"
                        name="colonia"
                        className={`${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                        disabled={disabledF}
                      >
                        <option value="">-Seleccione la Colonia-</option>
                        {colonies.map((_colonia) => (
                          <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                            {_colonia}
                          </option>
                        ))}
                      </select>
                    )}
                    {
                      submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-md-6"> </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="direccion" className="label mt-m ">
                    Dirección
                    <input
                      id="direccion"
                      type="text"
                      className={`input ${submitted && errors.direccion && 'has-error'}`}
                      value={direccion}
                      onChange={handleChange('direccion')}
                      disabled={disabledF}
                    />
                    {
                      submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                    }
                  </label>
                </div>
                <div className="col-6"> </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            {!addDirection && (
              <div className="row">
                <div className="col-12 col-md-9">
                  <h2>Registrar domicilio adicional</h2>
                </div>
                <div className="col-12 col-md-3 btn-content-filt">
                  <button className="button-secondary " onClick={() => setAddDirection(true)}>
                    &nbsp;&nbsp;Agregar&nbsp;&nbsp;
                    <i className="btn-icon">&#59672;</i>
                  </button>
                </div>
              </div>
            )}
            {addDirection && (
              <>
                <div className="row">
                  <div className="col-12 col-md-9">
                    <h2>Domicilio adicional</h2>
                  </div>
                  <div className="col-12 col-md-3 btn-content-filt">
                    <button className="button-secondary " onClick={() => setAddDirection(false)}>
                      <i className="btn-icon">&#59697;</i>
                    </button>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                      <label htmlFor="cpExt" className="label mt-m ">
                        Código postal
                        <input
                          id="cpExt"
                          type="number"
                          maxLength="5"
                          pattern="[0-9]{X,5}"
                          className={`input ${submitted && errorsExt.cpExt && 'has-error'}`}
                          onChange={handleChangeExt('cpExt')}
                          value={cpExt}
                        />
                        {
                          submitted && errorsExt.cpExt && <small className="caption-error">{errorsExt.cpExt}</small>
                        }
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="estadoExt" className="label mt-m">
                        Estado
                        <select
                          type="select"
                          name="estadoExt"
                          className={`${submitted && errorsExt.estadoExt && 'has-error'}`}
                          value={estadoExt}
                          onChange={handleChangeExt('estadoExt')}
                        >
                          <option value="">Estado</option>
                          {countriesData.map(({ states }) => (
                            <option key={states} name={states} value={states}>
                              {states}
                            </option>
                          ))}
                        </select>
                        {
                          submitted && errorsExt.estadoExt && <small className="caption-error">{errorsExt.estadoExt}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="ciudadExt" className="label mt-m">
                        Localidad o Municipio
                        <select
                          type="select"
                          name="ciudadExt"
                          className={`${submitted && errorsExt.ciudadExt && 'has-error'}`}
                          value={ciudadExt}
                          onChange={handleChangeExt('ciudadExt')}
                        >
                          <option value="">Localidad</option>
                          {localidadesExt.map((loc) => (
                            <option key={loc} name={loc} value={loc}>
                              {loc}
                            </option>
                          ))}
                        </select>
                        {
                          submitted && errorsExt.ciudadExt && <small className="caption-error">{errorsExt.ciudadExt}</small>
                        }
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="coloniaExt" className="label mt-m ">
                        Colonia
                        {!coloniesExt && (
                          <input
                            id="coloniaExt"
                            type="text"
                            className={`input ${submitted && errorsExt.coloniaExt && 'has-error'}`}
                            value={coloniaExt}
                            onChange={handleChangeExt('coloniaExt')}
                          />
                        )}
                        {coloniesExt && (
                          <select
                            type="select"
                            name="coloniaExt"
                            className={`${submitted && errorsExt.coloniaExt && 'has-error'}`}
                            value={coloniaExt}
                            onChange={handleChangeExt('coloniaExt')}
                          >
                            <option value="">-Seleccione la Colonia-</option>
                            {coloniesExt.map((_colonia) => (
                              <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                                {_colonia}
                              </option>
                            ))}
                          </select>
                        )}
                        {
                          submitted && errorsExt.coloniaExt && <small className="caption-error">{errorsExt.coloniaExt}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-md-6"> </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="direccionExt" className="label mt-m ">
                        Dirección
                        <input
                          id="direccionExt"
                          type="text"
                          className={`input ${submitted && errorsExt.direccionExt && 'has-error'}`}
                          value={direccionExt}
                          onChange={handleChangeExt('direccionExt')}
                        />
                        {
                          submitted && errorsExt.direccionExt && <small className="caption-error">{errorsExt.direccionExt}</small>
                        }
                      </label>
                    </div>
                    <div className="col-6"> </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="mt-xxl">
            <div className="row">
              <div className="col-12 col-md-3">
                <button className="button-secondary mt-m" onClick={() => opcionAnterior(false)}>
                  Cancelar
                </button>
              </div>
              <div className="col-12 col-md-5">
                {!disabledF
                  ? (
                    <button className="button mt-m" type="submit" disabled={validEmail || validEmailLoad === 0}>
                      Continuar
                    </button>
                  )
                  : (
                    <button className="button mt-m" type="submit">
                      Continuar
                    </button>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default FormStept1;
