/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { monthDayYearFormat } from '../../../helpers/dates';

const OwnerContractNav = ({
  data, eventShowMessage, refresh, permissions, navigateFunct,
}) => {
  const { domicilio } = data || '';
  const [ciudad, setCiudad] = useState('-');
  const [estado, setEstado] = useState('-');
  const [cp, setCp] = useState(28000);
  const [direccion, setDireccion] = useState('-');
  const [colonia, setColonia] = useState('-');
  const [entreCalles, setEntreCalles] = useState('-');

  useEffect(() => {
    if (domicilio && domicilio[0]) {
      setCiudad(domicilio[0].ciudad || '-');
      setEstado(domicilio[0].estado || '-');
      setCp(domicilio[0].cp || '-');
      setDireccion(domicilio[0].direccion || '-');
      setColonia(domicilio[0].colonia || '-');
      setEntreCalles(domicilio[0].entreCalles || '-');
    }
  }, [domicilio]);
  return (
    <div className="row m-accordion">
      {data && permissions('Contratos', 'editAnyContract') && (
      <>
        <div className="col-12 col-md-8">&nbsp;&nbsp;</div>
        <div className="col-12 col-md-4 btn-content-filt">
          <button className="button-secondary" type="button" onClick={() => navigateFunct('contract-owner')}>
                        &nbsp;&nbsp;Editar
            <i className="btn-icon">&#59686;</i>
                        &nbsp;&nbsp;
          </button>
        </div>
      </>
      )}
      <div className="col-12 col-md-12 col-lg-12 ">
        {data ? (
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      Datos personales
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Nombre completo</label>
                    <label className="datos-info text-capitalize">
                      {data.nombre || ''}
                      {' '}
                      {data.apellidoPaterno || ''}
                      {' '}
                      {data.apellidoMaterno || ''}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Género</label>
                    <label className="datos-info text-capitalize">{data.sexo || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Edad</label>
                    <label className="datos-info">{data.edad || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Fecha de nacimiento</label>
                    <label className="datos-info">{monthDayYearFormat(data.fechaNacimiento)}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Estado civil</label>
                    <label className="datos-info text-capitalize">{data.estadoCivil || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Ocupación</label>
                    <label className="datos-info text-capitalize">{data.ocupacion || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Teléfono</label>
                    <label className="datos-info">{data.telefono || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Correo electrónico</label>
                    <label className="datos-info">{data.email || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">RFC</label>
                    <label className="datos-info">{data.rfc || '-'}</label>
                  </div>

                  <div className="col-12 col-md-6">
                    <label className="datos-title">Finado</label>
                    <label className="datos-info">{data.finado ? 'SI' : 'NO'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Cesion</label>
                    <label className="datos-info">{data.cesion ? 'SI' : 'NO'}</label>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Domicilio</Accordion.Header>
              {domicilio ? (
                <Accordion.Body>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Ubicación</label>
                      <label className="datos-info">
                        {ciudad || ''}
                        {', '}
                        {estado || ''}
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Código postal</label>
                      <label className="datos-info">{cp || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Dirección</label>
                      <label className="datos-info">{direccion || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Colonia</label>
                      <label className="datos-info">{colonia || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Entre calles</label>
                      <label className="datos-info">{entreCalles || '-'}</label>
                    </div>
                  </div>
                </Accordion.Body>
              ) : (
                <Accordion.Body>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Ubicación</label>
                      <label className="datos-info">
                        {data?.ciudad || ''}
                        {', '}
                        {data?.estado || ''}
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Código postal</label>
                      <label className="datos-info">{data?.cp || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Dirección</label>
                      <label className="datos-info">{data?.direccion || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Colonia</label>
                      <label className="datos-info">{data?.colonia || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Entre calles</label>
                      <label className="datos-info">{data?.entreCalles || '-'}</label>
                    </div>
                  </div>
                </Accordion.Body>
              )}
            </Accordion.Item>
          </Accordion>

        ) : (
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      Datos personales
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="datos-title"> - </label>
                    <label className="datos-info text-capitalize">
                      No hay información del cliente
                    </label>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default OwnerContractNav;
