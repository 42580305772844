/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import './optionsDropdown.css';

const Option = ({
  text, icon, onClick, theme,
}) => {
  const selectClassName = () => {
    switch (theme) {
      case 'success':
        return 'option-success';
      case 'danger':
        return 'option-danger';
      default:
        return '';
    }
  };

  return (
    <button onClick={onClick} className={`d-flex options-dropdown-btn ${selectClassName()}`}>
      {icon && <i className="btn-icon me-1">{icon}</i>}
      {text}
    </button>
  );
};

const OptionsMenu = ({ text, children }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClickOutside = (e) => {
    try {
      if (!e.target.className.includes('options-dropdown-btn')) {
        setOpen(false);
      }
    } catch (err) {
      //
    }
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="options-container">
      <button
        onClick={() => setOpen(!open)}
        className="options-dropdown"
      >
        {text && (
          <span>
            <i className="btn-icon">&#59657;</i>
            {text}
          </span>
        )}
        {!text && <i className="dots-line" />}
      </button>
      {open && (
        <div
          className="drop-menu lg"
          ref={menuRef}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
      )}
    </div>
  );
};

OptionsMenu.Item = Option;

export default OptionsMenu;
