import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { addModuleToEmployee, updateEmployeeModule } from '../../services';
import rolesPermissions from '../../../shared/routeGuards/rolesPermissions.json';
import CheckBox from '../../../../global/custom/checkbox';

const UpdatePermissionsModal = ({
  onClose,
  modules,
  moduleId,
  employeeId,
  employeeRole,
  isModuleActive,
  verifyGrantedPermission,
  comparePermissionsByModule,
  employeeModules,
  refreshData,
}) => {
  const moduleData = modules.find((mod) => mod._id === moduleId);

  const [isActive, setActive] = useState(false);
  const [status, setStatus] = useState('idle');
  const [permissions, setPermissions] = useState([]);
  const [showRestoreButton, setShowRestoreButton] = useState(false);
  const [defaultModule, setDefaultModule] = useState([]);

  const getDefaultPermissionsPerRole = () => {
    const roleItem = rolesPermissions.find((obj) => obj.puesto === employeeRole.toLowerCase());
    const defModule = roleItem
      && roleItem.modulos.find((mod) => mod.nombre === moduleData.nombre);

    if ((!defModule || !defModule.activo) && isActive) {
      setShowRestoreButton(true);
      return setDefaultModule(defModule || {
        activo: false,
        permisos: []
      });
    }

    const currentModule = {
      activo: isActive,
      permisos: permissions.filter((obj) => obj.checked).map((permission) => ({
        accion: permission.accion
      }))
    };
    const areEqual = comparePermissionsByModule(currentModule, defModule
      || { activo: isActive, permisos: [] });

    setShowRestoreButton(!areEqual);
    return setDefaultModule(defModule || {
      activo: false,
      permisos: []
    });
  };

  const restoreDefaultPermissions = () => {
    setPermissions(permissions.map((permission) => {
      const itemIndex = defaultModule.permisos.findIndex(
        (item) => item.accion === permission.accion
      );
      return {
        ...permission,
        checked: itemIndex >= 0,
      };
    }));
    setActive(defaultModule.activo);
  };

  const handleChange = (action, value) => {
    setPermissions(permissions.map((permission) => {
      if (permission.accion === action) {
        return { ...permission, checked: value };
      }
      return permission;
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    const modulo = {
      idModulo: moduleData._id,
      nombre: moduleData.nombre,
      activo: isActive,
      permisos: permissions.filter((permission) => permission.checked)
        .map((permission) => ({ accion: permission.accion }))
    };

    const userModuleId = employeeModules.find((module) => module.idModulo === moduleData._id)?._id;
    try {
      if (userModuleId) {
        const res = await updateEmployeeModule(employeeId, modulo.idModulo, modulo);
        if (res.errors) {
          throw res.errors;
        } else {
          toast('Cambios guardados', { className: 'message' });
          refreshData();
          onClose();
        }
        return;
      }
      const res = await addModuleToEmployee(employeeId, modulo);
      if (res.errors) {
        throw res.errors;
      } else {
        toast('Cambios guardados', { className: 'message' });
        refreshData();
        onClose();
      }
    } catch (err) {
      setStatus('idle');
      toast('Ha ocurrido un error, vuelve a intentarlo más tarde.', {
        className: 'message error'
      });
    }
  };

  useEffect(() => {
    if (permissions.length > 0) {
      getDefaultPermissionsPerRole();
    }
  }, [permissions, isActive]);

  useEffect(() => {
    if (permissions.length === 0) {
      setActive(isModuleActive);
      setPermissions(moduleData.permisos.map((permission) => (
        {
          ...permission,
          checked: verifyGrantedPermission(moduleId, permission.accion),
        }
      )));
    }
  }, []);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={onClose}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">

        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-lg-8 col-xl-6">
            <div className="content-buttom">&nbsp;</div>
            <div className="row">
              <div className="col-12 col-md-4">
                <h2 className="text-capitalize">{moduleData.nombre}</h2>
              </div>
              {showRestoreButton && (
                <div className="col-12 col-md-8 d-flex justify-content-md-end">
                  <button
                    className="button-secondary"
                    onClick={restoreDefaultPermissions}
                  >
                    Restaurar valores predeterminados&nbsp;&nbsp;
                    <i className="btn-icon">&#59711;</i>
                  </button>
                </div>
              )}
            </div>

            <div className="row">
              <form onSubmit={handleSubmit}>
                <div className="row prospect-message-cont m-t-l">
                  <div className="col-10 col-md-11">
                    <p>Acceder al módulo</p>
                  </div>
                  <div className="col-2 col-md-1">
                    <Switch
                      checked={isActive}
                      onChange={() => setActive(!isActive)}
                      onColor="#000000"
                      onHandleColor="#FFFFFF"
                      offColor="#CFCFCF"
                      offHandleColor="#FFFFFF"
                      borderRadius={60}
                      handleDiameter={5}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={25}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
                <div className="col-12 m-t-l">

                  {permissions.map((permission) => (
                    <div className="d-flex mb-2" key={permission.accion}>
                      <CheckBox
                        status={permission.checked}
                        eventC={() => handleChange(permission.accion, !permission.checked)}
                        nameC={permission.accion}
                      />
                      <label className="text-secondary" htmlFor={permission.accion}>
                        {permission.titulo}
                      </label>
                    </div>
                  ))}

                  <div className="col-12">
                    <button
                      type="button"
                      className="button-secondary p-r m-btn-prof"
                      onClick={onClose}
                      disabled={status === 'loading'}
                    >
                      Cancelar
                    </button>
                    <button
                      className="button mt-m"
                      type="submit"
                      disabled={status === 'loading'}
                    >
                      Guardar cambios
                    </button>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default UpdatePermissionsModal;
