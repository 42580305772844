/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TabBranchOffice from './components/listBranchOffice/tabBranchOffice';
import NoBranchOffice from './components/listBranchOffice/noBranchOffice';
import NotFoundBranches from './components/listBranchOffice/notFoundBranches';
import './branchoffice.css';
import { useSucursalsPerPage } from './hooks/queries';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';

const ListBranchOffice = () => {
  const navigate = useNavigate();
  const [searchInp, setSearchInp] = useState('');

  const [dropDown2, setDropDown2] = useState(false);
  const [orderList, setOrderList] = useState('asc');

  const { data: branchList } = useSucursalsPerPage({
    search: searchInp,
    order: orderList,
  });

  const total = branchList ? branchList.length : 0;
  const searchEnabled = searchInp.length > 0;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const openDrop2 = () => {
    setDropDown2(!dropDown2);
  };

  const searchBranches = (e) => {
    setSearchInp(e.target.value);
  };

  const addBranch = () => {
    navigate('/add-Branches');
  };

  const changeOrderList = () => {
    if (orderList === 'asc') {
      setOrderList('desc');
    } else {
      setOrderList('asc');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (!token) {
        closeSession();
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, [searchInp, orderList]);
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <h2>Sucursales</h2>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter">
          {checkPermisionRoute('Sucursales', 'crearSucursal') && (
          <button className="button" onClick={addBranch}>
            &nbsp;&nbsp;Nueva sucursal&nbsp;&nbsp;
            <i className="btn-icon">&#59672;</i>
            &nbsp;&nbsp;
          </button>
          )}
        </div>
        <div className="col-12 margin-filter">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <label>{total || 0} resultados</label>
        </div>
        <div className="col-12 col-md-4 margin-filter">
          <div className="col-12 col-md-12">
            <div className="input-container">
              <input id="buscar" onChange={(e) => searchBranches(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
              <span className="input-icon search-line" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 margin-filter"> </div>
        <div className="col-12 col-md-4 btn-content-filt margin-filter content-on">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <button onClick={openDrop2} className="button-secondary btn-p-cl p-r">
            &nbsp;&nbsp;
            {orderList === 'asc' ? 'A a Z' : 'Z a A'}
            &nbsp;
            <i className="btn-icon">&#59657;</i>
            &nbsp;&nbsp;
            {dropDown2 && (
              <div className="drop-menu">
                <label onClick={changeOrderList}>
                  {orderList !== 'asc' ? 'A a Z' : 'Z a A'}
                </label>
              </div>
            )}
          </button>
        </div>
      </div>
      {branchList && <TabBranchOffice data={branchList} />}
      {total === 0 && !searchEnabled && <NoBranchOffice />}
      {total === 0 && searchEnabled && <NotFoundBranches />}
    </div>
  );
};

export default ListBranchOffice;
