import { useFormik } from 'formik';
import * as Yup from 'yup';
// const phoneRegExp = '([0-9][^0-9]){8,10}$';
const phoneRegExp = /^[0-9\- ]{10,10}$/;
const rcRegExp = /^([a-z]{3,4})(\d{2})(\d{2})(\d{2})([0-9a-z]{3})$/i;

export const FormikStep1 = (defaultVal) => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: defaultVal?.nombre || '',
    apellidoP: defaultVal?.apellidoP || '',
    apellidoM: defaultVal?.apellidoM || '',
    telefono: defaultVal?.telefono || '',
    email: defaultVal?.email || '',
    fechaN: defaultVal?.fechaN || '',
    genero: defaultVal?.genero || 'Hombre',
    estadoc: defaultVal?.estadoc || '',
    RFC: defaultVal?.RFC || '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoP: Yup.string()
      .required('El Primer apellido es requerido'),
    apellidoM: Yup.string(),
    fechaN: Yup.string()
      .required('La Fecha de nacimiento es requerida'),
    genero: Yup.string()
      .required('El Género es requerido'),
    telefono: Yup.string()
      .matches(phoneRegExp, 'El Teléfono es invalido')
      .required('El Teléfono es requerido'),
    email: Yup.string()
      .email('El Correo Electrónico es invalido')
      .required('El Correo Electrónico es requerido'),
    RFC: Yup.string()
      .matches(rcRegExp, 'El RFC es invalido'),
  }),
});

export const FormikStep2 = (defaultVal) => useFormik({
  validateOnMount: true,
  initialValues: {
    zipCode: defaultVal.zipCode || '',
    estado: defaultVal.estado || '',
    municipio: defaultVal.municipio || '',
    colonia: defaultVal.colonia || '',
    calle: defaultVal.calle || '',
    entreCalle: defaultVal.entreCalle || '',
  },
  validationSchema: Yup.object({
    zipCode: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('El Estado es requerido'),
    municipio: Yup.string()
      .required('La Localidad o Municipio es requerido'),
    colonia: Yup.string()
      .required('La Colonia es requerida'),
    calle: Yup.string()
      .required('La Calle es requerida'),
    entreCalle: Yup.string(),
  }),
});

export const FormikStep3 = (defaultVal) => useFormik({
  validateOnMount: true,
  initialValues: {
    rol: defaultVal?.rol || '',
    puesto: defaultVal?.puesto || '',
    sucursal: defaultVal?.sucursal || '',
  },
  validationSchema: Yup.object({
    rol: Yup.string()
      .required('El rol es requerido'),
    sucursal: Yup.string()
      .required('La Sucursal es requerida'),
    puesto: Yup.string()
      .required('El puesto es requerido'),
  }),
});

export const FormikStep4 = (defaultVal) => useFormik({
  validateOnMount: true,
  initialValues: {
    fileant: defaultVal?.fileant || '',
    fileCV: defaultVal?.fileCV || '',
    fileCD: defaultVal.fileCD || '',
    fileCIF: defaultVal.fileCIF || '',
    fileINE: defaultVal.fileINE || '',
    fileLicencia: defaultVal.fileLicencia || '',
    solicitudEmpleo: defaultVal.solicitudEmpleo || '',
  },
  validationSchema: Yup.object({
    fileant: Yup.string()
      .required('Documento requerido o no valido'),
    fileCV: Yup.string()
      .required('Documento requerido o no valido'),
    fileCD: Yup.string()
      .required('Documento requerido o no valido'),
    fileCIF: Yup.string()
      .required('Documento requerido o no valido'),
    fileINE: Yup.string()
      .required('Documento requerido o no valido'),
  }),
});

export const FormikStep5 = (defaultVal) => useFormik({
  validateOnMount: false,
  initialValues: {
    puesto: defaultVal?.puesto || '',
    namefileant: defaultVal?.namefileant || '',
    namefileCD: defaultVal?.namefileCD || '',
    namefileCIF: defaultVal?.namefileCIF || '',
    namefileCV: defaultVal?.namefileCV || '',
    namefileINE: defaultVal?.namefileINE || '',
    namefileSol: defaultVal?.namefileSol || '',
    namefileLicencia: defaultVal?.namefileLicencia || '',
    fileant: defaultVal?.fileant || '',
    fileCV: defaultVal?.fileCV || '',
    fileCD: defaultVal?.fileCD || '',
    fileCIF: defaultVal?.fileCIF || '',
    fileINE: defaultVal?.fileINE || '',
    fileLicencia: defaultVal?.fileLicencia || '',
    solicitudEmpleo: defaultVal?.solicitudEmpleo || '',
  },
  validationSchema: Yup.object({
    puesto: Yup.string().required('puesto required'),
    fileant: Yup.mixed().when(
      'puesto',
      (puesto) => {
        if (puesto === 'asesor de ventas') {
          return Yup.mixed().nullable();
        }
        return Yup.mixed().required('Documento requerido o no valido');
      }
    ),
    fileCV: Yup.mixed().when(
      'puesto',
      (puesto) => {
        if (puesto === 'asesor de ventas') {
          return Yup.mixed().nullable();
        }
        return Yup.mixed().required('Documento requerido o no valido');
      }
    ),
    fileCD: Yup.mixed().when(
      'puesto',
      (puesto) => {
        if (puesto === 'asesor de ventas') {
          return Yup.mixed().required('Documento requerido o no valido');
        }
        return Yup.mixed().required('Documento requerido o no valido');
      }
    ),
    fileCIF: Yup.mixed().when(
      'puesto',
      (puesto) => {
        if (puesto === 'asesor de ventas') {
          return Yup.mixed().nullable();
        }
        return Yup.mixed().required('Documento requerido o no valido');
      }
    ),
    fileINE: Yup.mixed().when(
      'puesto',
      (puesto) => {
        if (puesto === 'asesor de ventas') {
          return Yup.mixed().required('Documento requerido o no valido');
        }
        return Yup.mixed().required('Documento requerido o no valido');
      }
    ),
  }),
});
