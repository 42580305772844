/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BeneficiariesContract = ({
  data, idC, contrato, permissions,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const [selectOP, setSelectOP] = useState(null);
  const navigate = useNavigate();
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const addBenef = () => {
    navigate(`/clients/contract-view/${idC}/new-beneficiaries`);
  };
  const changeBenef = () => {
    navigate(`/clients/contract-view/${idC}/change-priority`);
  };
  const checkInfo = () => {
    navigate(`/clients/contract-view/${idC}/edit-beneficiarie/${selectOP}`);
  };

  const openDrop = (id) => {
    setDropDown(!dropDown);
    if (id && !dropDown) {
      setSelectOP(id);
    } else {
      setSelectOP(null);
    }
  };

  const resetInfo = () => {
    setDropDown(null);
    setSelectOP(null);
  };

  return (
    <div className="container">
      <div className="row" onMouseLeave={resetInfo}>
        <div className="col-12 col-md-12">
          <br />
          <br />
          <div className="row">
            <div className="col-12 col-md-9">
              <h2>Beneficiarios</h2>
            </div>
            <div className="col-12 col-md-3 btn-content-filt">
              {contrato && ((contrato.estatus_contrato !== 'firmado' && contrato.estatus_contrato !== 'liquidado') || permissions('Contratos', 'editAnyContract')) && (
              <button className="button" type="button" onClick={addBenef}>
                &nbsp;&nbsp;Agregar&nbsp;&nbsp;
                <i className="btn-icon">&#59672;</i>
              </button>
              )}
            </div>
          </div>
          <br />
          <div className="container-fluid">
            {data && data[0] && data.map((item, index) => (
              <div key={generateKey(item._id)} className="row content-h benef-tab">
                <div className="option-tab">
                  <div className="col-2 col-md-1">
                    <label className="text-pos">{index + 1}</label>
                  </div>
                  <div className="col-10 col-md-8">
                    <label className="text-name text-capitalize">
                      {item.nombre}
                      {' '}
                      {item.apellidoPaterno || ''}
                      {' '}
                      {item.apellidoMaterno || ''}
                    </label>
                    <label className="text-email">{item.parentesco}</label>
                  </div>
                  {item.nivel === 1 ? (
                    <div className={`col-6 col-md-2 item-hidd mob-right ${item.nivel === 1 ? 'select' : ''}`}>
                      <label className="text-indicator">{item.nivel === 1 ? 'Principal' : ''}</label>
                    </div>
                  ) : (
                    <div className="col-6 col-md-2"> </div>
                  )}
                  <div className={`col-6 col-md-1 item-hidd mob-right ${item.nivel === 1 ? 'select' : ''}`}>
                    <button className="button-secondary p-r" onClick={() => openDrop(item._id)}>
                      <i className="btn-icon">&#59683;</i>
                      {dropDown && selectOP === item._id && (
                        <div className="drop-menu xl">
                          <label onClick={checkInfo}>
                            <i className="icon">&#59698;</i>
                            Consultar información
                          </label>
                          {contrato && (contrato.estatus_contrato !== 'firmado' && contrato.estatus_contrato !== 'liquidado') && (
                          <label onClick={changeBenef}>
                            <i className="icon">&#59699;</i>
                            Actualizar prioridad
                          </label>
                          )}
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <hr className="notif-line-spacing" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default BeneficiariesContract;
