/* eslint-disable react/jsx-no-constructed-context-values */
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  QueryClient, QueryClientProvider
} from 'react-query';
import AppRouter from './routers/AppRouter';
import { AppContext } from './features/context/AppContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

const App = () => {
  const [appData, setAppData] = useState({});

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ appData, setAppData }}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
