/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import GMenuForm from '../menu';
// import Config from '../../global/constants';
import { getEmployeeByID, actualizarEmpleadoStatus } from './services';

import ProfileDefComponent from '../shared/profileDef';
import NavPage from '../navPage';
import EmployeeInfoDetail from './components/employeeDetails/employeeInfo';
import EmployeeInfoDocs from './components/employeeDetails/employeeDocs';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import EmployeeForm from './components/employeeDetails/employeeForm';
import EmployeePermissions from './components/employeePermissions/EmployeePermissions';
import Backbutton from '../shared/backbutton/backbutton';
import OptionsMenu from '../shared/optionsMenu/OptionsMenu';

const EmployeeDetails = ({ idEmployee }) => {
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState(null);

  const [foundE, setFoundE] = useState(true);
  const [nameE, setNameE] = useState('');
  const [openM, setOpenM] = useState(false);
  const [message, setMessage] = useState(false);

  const optionNav = ['Detalles', 'Documentos', 'Permisos'];
  const [activeNav, setActiveNav] = useState('Detalles');

  const handleBackButtonClick = () => {
    navigate('/contributors');
  };

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const eventChangeNav = (item) => {
    setActiveNav(item);
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };

  const getEmployee = async () => {
    await getEmployeeByID(idEmployee).then((data) => {
      if (data && data[0]) {
        setFoundE(true);
        let nameUser = `${data[0].nombre || ''} ${data[0].apellidoPaterno || ''} ${data[0].apellidoMaterno || ''}`;
        setNameE(nameUser);

        if (data[0] && data[0].sexo && data[0].sexo === 'M') {
          data[0].sexo = 'Masculino';
        }
        if (data[0] && data[0].sexo && data[0].sexo === 'F') {
          data[0].sexo = 'Femenino';
        }
        if (data[0] && data[0].sexo) {
          const tempSx = data[0].sexo;
          const capitalized = tempSx.charAt(0).toUpperCase() + tempSx.slice(1);
          data[0].sexo = capitalized;
        }
        if (data[0] && data[0].fecha_nacimiento) {
          const tempD = new Date(data[0].fecha_nacimiento);
          data[0].fecha_nacimiento = formatDate(tempD);
        }
        setEmployeeDetails(data[0]);
      } else {
        setFoundE(false);
        setEmployeeDetails(null);
        setNameE(undefined);
      }
    });
  };
  const changeStatusEmployee = async (data, newStatus) => {
    await actualizarEmpleadoStatus(data._id, { status: newStatus }).then((newData) => {
      if (newData?._id) {
        setEmployeeDetails(newData);
      }
    });
  };

  const eventOpenM = () => {
    setOpenM(true);
  };
  const eventCloseM = () => {
    setOpenM(false);
  };

  const refressEmployee = () => {
    getEmployee();
  };

  const eventShowMessage = (text, type = '') => {
    setMessage(true);
    toast(text, {
      className: `message ${type}`
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idEmployee) {
      getEmployee();
    } else if (!token) {
      closeSession();
    }
  }, [idEmployee]);
  return (
    <div>
      <GMenuForm />
      <div className="container filter-container">
        <div className="row position-relative">
          {/* eslint-disable react/self-closing-comp */}
          {/* eslint-disable no-trailing-spaces */}
          <div className="col col-md-12 col-lg-2">
          </div>
          <div className="col-12 col-md-12 col-lg-8">
            <div className="row">
              <div className="col-12 col-md-5 margin-filter ">
                <Backbutton onClick={handleBackButtonClick} className="btn btn-primary mr-3 mt-0" />
                <br></br>
                <br></br>
                <div className="row align-items-center">
                  <div className="col-3">
                    <ProfileDefComponent name={nameE} size="md" imgProf={employeeDetails?.imagen_perfil} />
                  </div>
                  <div className="col-9">
                    {employeeDetails && (
                      <h2 className="text-capitalize">{nameE}</h2>
                    )}
                    {!employeeDetails && !nameE && (
                      <h2>No encontrado</h2>
                    )}
                  </div>
                </div>
              </div>
              {foundE && (
                <div className="col-12 col-md-7 ">
                  {/* content-btns */}
                  <div className="row">
                    <div className={checkPermisionRoute('Empleados', 'actDeactivateEmployee') ? 'col-10' : 'col-12'}>
                      {checkPermisionRoute('Empleados', 'actualizarEmpleado') && (
                        <button className="button-secondary p-r f-r" onClick={() => eventOpenM()}>
                          &nbsp;&nbsp;Editar perfil&nbsp;&nbsp;
                          <i className="btn-icon">&#59686;</i>
                        </button>
                      )}
                    </div>
                    {checkPermisionRoute('Empleados', 'actDeactivateEmployee') && (
                    <div className="col-2 content-btn-r">
                      <OptionsMenu>
                        {employeeDetails?.status === 1 && (
                        <OptionsMenu.Item
                          icon="&#59669;"
                          text="Desactivar"
                          onClick={() => changeStatusEmployee(employeeDetails, 0)}
                          theme="danger"
                        />
                        )}
                        {employeeDetails?.status === 0 && (
                        <OptionsMenu.Item
                          icon="&#59670;"
                          text="Activar"
                          onClick={() => changeStatusEmployee(employeeDetails, 1)}
                          theme="success"
                        />
                        )}
                      </OptionsMenu>
                    </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col col-md-12 col-lg-2"> </div>
        </div>
      </div>
      <div className="container filter-container sml">
        <div className="row">
          <div className="col col-md-12 col-lg-2"> </div>
          <div className="col-12 col-md-12 col-lg-8">
            <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} />
          </div>
          <div className="col col-md-12 col-lg-2"> </div>
        </div>
      </div>
      {activeNav === 'Detalles' && employeeDetails && foundE && <EmployeeInfoDetail data={employeeDetails} permision={checkPermisionRoute} />}
      {activeNav === 'Documentos' && foundE && <EmployeeInfoDocs data={employeeDetails} permision={checkPermisionRoute} refressEv={refressEmployee} eventMessage={eventShowMessage} />}
      {activeNav === 'Permisos' && checkPermisionRoute('Permisos', 'verPermisos') && (
        <EmployeePermissions
          employeeData={employeeDetails}
          refreshData={getEmployee}
          permissionsF={checkPermisionRoute}
        />
      )}
      {openM && employeeDetails && <EmployeeForm data={employeeDetails} eventV={eventCloseM} refressEv={refressEmployee} eventMessage={eventShowMessage} />}
      {message && (
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      )}
    </div>
  );
};

export default EmployeeDetails;
