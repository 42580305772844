/* eslint-disable max-len */
import parse from 'html-react-parser';
import ImageDef from '../../../../products/imagedefault/imagedefault';
import proddef from '../../../../../images/imageProDef.png';

const AsociarProd = (
  {
    listProducts,
    cambiarOpcionUno,
    cambiarOpcionTres,
    step4,
    changeCount,
    deleteSelectP,
    updStatus,
    updStatusLP,
  }
) => {
  const countProd = (prdSelect) => {
    let counterP = 0;
    prdSelect.forEach((item) => {
      counterP += item.Cantidad;
    });
    return counterP;
  };
  const saveListP = () => {
    step4(listProducts);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };

  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  return (
    <div className="row">
      <button className="btn-regresar" onClick={cambiarOpcionUno}><i>&#59658;</i></button>
      <div className="col-12">
        <h2>Asociar producto</h2>
        &nbsp;
        <small>{listProducts ? `${countProd(listProducts)} Productos` : '0 Productos'}</small>
        <br />
        <button className="button-addProd" onClick={cambiarOpcionTres}>
          Agregar producto
        </button>
        <div className="row">
          <br />
        </div>
        <div className="row">
          {listProducts && updStatusLP && listProducts.length > 0 && (
            <div className="col-12">
              <div className="row">
                <div className="col col-sm-12 col-md-2"> </div>
                <div className="col-12 col-sm-12 col-md-8">
                  {
                    listProducts.map((item, indx) => (
                      <div className="row" key={item._id}>
                        <div className="row row-cursor">
                          <div className="option-tab">
                            <div className="col-6 col-sm-6 col-md-6 img-prd-cent">
                              <ImageDef
                                name={item.nombre}
                                size="lg"
                                imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : proddef}
                              />
                            </div>
                            <div className="col-6 col-sm-6 col-md-6">
                              <label className="text-nameP">{item.nombre}</label>
                              {
                                item._Disponibilidad === 'inmediata' ? (
                                  !item?.descuentos?.usoInmediato?.descuentoUsoInmediato
                                    ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                    : (
                                      <>
                                        <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                        <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.usoInmediato.descuentoUsoInmediato))}</label>
                                      </>
                                    )
                                ) : (
                                  !item?.descuentos?.prevision?.descuentoPrevision
                                    ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                    : (
                                      <>
                                        <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                        <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.prevision.descuentoPrevision))}</label>
                                      </>
                                    )
                                )
                              }
                              <label className="text-email left">
                                (
                                {`${item._Disponibilidad.toUpperCase() || '-'}` || parse('&nbsp;')}
                                )
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row no-margin">
                          {updStatus && (
                            <div className="col-9">
                              <div className="container-prd-number">
                                <button
                                  id="decrement"
                                  className="button-number"
                                  onClick={() => changeCount('-', indx)}
                                  disabled={item.Cantidad <= 1}
                                >
                                  -
                                </button>
                                <span className="input-number">
                                  {item.Cantidad}
                                </span>
                                <button
                                  id="increment"
                                  className="button-number"
                                  onClick={() => changeCount('+', indx)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          )}
                          <div className="col-3 f-r">
                            <button className="button-secondary p-r f-r" onClick={() => deleteSelectP(indx)}>
                              <i className="btn-icon">&#59697;</i>
                            </button>
                          </div>
                          <div className="line-spacing" />
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className="col col-sm-12 col-md-2"> </div>
              </div>
            </div>
          )}
        </div>
        <div className="row ">
          <div className="col-12">
            <div className="col-12 col-md-12">
              &nbsp;
            </div>
            <div className="col-12 col-md-12">
              <button className="button mt-m margin_button" type="submit" onClick={saveListP}>
                Asociar al contrato
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsociarProd;
