import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import formik from './formik';
import Message from '../../../shared/message/Message';
import { resetPassword } from '../../services/auth';
import '../loginForm/loginForm.css';

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [status, setStatus] = useState('idle');
  // const [errorCode, setErrorCode] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors
  } = formik();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      setStatus('loading');
      const res = await resetPassword(params.token, password);
      // console.log(res);
      setStatus(res.ok ? 'success' : 'error');
      // setErrorCode(res.code);
    }
  };

  const handlePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  if (!params.token) {
    return <Navigate to="/login" />;
  }

  const { password } = values;

  if (status !== 'idle') {
    return (
      <div className="login-container container-fluid">
        {status === 'loading' && (
          <Message
            type="loading"
            title="Cargando..."
            subtitle="Esta operación podría demorar algunos minutos. Por favor, espere."
          />
        )}
        {status === 'success' && (
          <Message
            type="success"
            title="Contraseña actualizada"
            message="Tu contraseña se ha restablecido exitosamente. Inicia sesión para ingresar a tu cuenta."
            onConfirm={() => navigate('/login')}
            confirmText="Iniciar sesión"
          />
        )}
        {status === 'error' && (
          <Message
            type="error"
            title="Ha ocurrido un error"
            message="Vuelve a intenarlo más tarde"
            onConfirm={() => setStatus('idle')}
            confirmText="Volver"
          />
        )}
      </div>
    );
  }

  return (
    <div className="login-container container-fluid">
      <Row className="d-flex justify-content-center align-items-center h-100">
        <Col className="img-fluid">
          <Row className="justify-content-center align-items-end  h-100">
            <Col md={3} className="content-logo">
              <img src="/assets/img/logo-mdp-v-white.webp" alt="" className="logo-inicio" />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-center align-items-center">
            <Col md={10}>
              <div className="form-body">
                <form className="mt-m form-body" onSubmit={handleSubmit} noValidate>
                  <h2>Recuperar conraseña</h2>
                  <br />
                  <p>
                    Introduce tu nueva contraseña.
                  </p>
                  <div>
                    <label htmlFor="new-input" className="label input-content mt-m">
                      Nueva contraseña
                      <div className="input-container">
                        <input
                          id="new-input"
                          type={passwordType}
                          className={`input ${submitted && errors.password && 'has-error'}`}
                          value={password}
                          placeholder=""
                          onChange={handleChange('password')}
                        />
                        <span
                          className={`input-icon ${passwordType === 'password' ? 'open-eye-line' : 'closed-eye-line'}`}
                          onClick={handlePasswordType}
                        >
                          {' '}
                        </span>
                      </div>
                      {submitted && errors.password && (
                        <small className="caption-error">
                          {errors.password}
                        </small>
                      )}
                    </label>
                  </div>
                  <div className="mt-m">
                    <Row className="justify-content-md-center align-items-center">
                      <Col>
                        <button className="button" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
                          Reestablecer
                        </button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
