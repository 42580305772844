import ListNotifications from '../../features/notifications/listNotifications';
import GMenuForm from '../../features/menu';

const TabNotifications = () => (
  <>
    <GMenuForm />
    <div className="container">
      <div className="row align-items-center justify-content-center m-t-xxxl">
        <div className="col-12 col-md-12 col-lg-8">
          <ListNotifications />
        </div>
      </div>
    </div>
  </>
);

export default TabNotifications;
