/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { getEmployeeByRol } from '../../services';
import ProfileDefComponent from '../../../shared/profileDef';

const SelectCollectorC = ({ eventOp, statusOp, setData }) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [dataEmployees, setDataEmployees] = useState(null);
  const [selectEmployee, setSelectEmployee] = useState(null);
  const [searchInp, setSearchInp] = useState('');
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const searchEmployeesF = async () => {
    let nombre = '';
    if (searchInp !== '') {
      nombre = `&nombre=${encodeURIComponent(searchInp).replace(
        /[!'()*]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
      )}`;
    }
    const paramsQ = `puesto=cobrador&paginacion=1&paginas=10${nombre}`;
    await getEmployeeByRol(paramsQ).then((data) => {
      if (data && data.data) {
        setDataEmployees(data.data);
        setSelectEmployee(null);
      }
    });
  };

  const searchEmployees = (e) => {
    setSearchInp(e.target.value);
  };

  const sendData = () => {
    setData(selectEmployee);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (token) {
        searchEmployeesF();
      }
    }
    if (searchInp === '') {
      searchEmployeesF();
    }
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);
  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token) {
      searchEmployeesF();
    }
  }, []);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col close-form">
                <span
                  className="icon-close cross-line"
                  onClick={eventOp}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="col-12 spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Asignar cobrador</h2>
                      <br />
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="input-container">
                        <input id="buscar" onChange={(e) => searchEmployees(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                        <span className="input-icon search-line" />
                      </div>
                    </div>
                    <div className="col-12">
                      {dataEmployees && dataEmployees.length > 0 && dataEmployees.map((item) => (
                        <div
                          key={generateKey(item._id)}
                          className="container-fluid "
                          onClick={() => setSelectEmployee(item._id)}
                        >
                          <div className={`row row-itemN ${selectEmployee === item._id ? 'select' : ''}`}>
                            <div className="col-2">
                              <ProfileDefComponent
                                name={item?.nombre}
                                size=""
                                imgProf={item?.imagen || null}
                              />
                            </div>
                            <div className="col-8">
                              <div className="row">
                                <div className="col-12  wb-bw">
                                  <p className="text-name">
                                    {item?.nombre}
                                    &nbsp;
                                  </p>
                                </div>
                                <div className="col-12">
                                  <p className="text-email wb-ba">
                                    {item?.email || '-'}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-2">
                              <label className="radio-indicator">
                                {selectEmployee === item._id ? <i>&#59708;</i> : <i>&#59652;</i>}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-12 p-r cont-btn">
                      <button className="button navR-content-btn" onClick={() => sendData()} type="button" disabled={!selectEmployee ? 'disabled' : ''}>
                        Asignar
                      </button>
                    </div>
                    <div className="col-12 spacing-nav"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCollectorC;
