/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TabProducts from './components/listProducts/tabProducts';
import NoProducts from './components/listProducts/noProducts';
import NotFoundProducts from './components/listProducts/notFoundProducts';
import './products.css';
import { useSucursalsPerPage } from '../branchOffice/hooks/queries';
import { useProductsPerPage } from './hooks/queries';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import ProductDetailsModal from './components/productDetails/ProductDetailsModal';
import ToggleStatusModal from './components/toggleStatus/ToggleStatusModal';
import DropdownFilter from '../shared/dropdownFilter/DropdownFilter';

const ListProducts = () => {
  const navigate = useNavigate();

  const [selectSucursal, setSelectSucursal] = useState(null);
  const [searchInp, setSearchInp] = useState('');
  const [page, setPage] = useState(1);
  const [listSucursal, setListSucursal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [statusFilter, setStatusFilter] = useState('activos');
  const [paramStatus, setParamStatus] = useState('reciente');
  const [statusValorInm, setStatusValorInm] = useState(undefined);
  const perPage = 10;

  const { data: sucursals } = useSucursalsPerPage({});
  const { data: queryData } = useProductsPerPage({
    page,
    perPage,
    location: selectSucursal,
    order: paramStatus,
    search: searchInp,
    status: statusFilter,
    valorInmediato: statusValorInm,
  });

  const total = queryData?.total;
  const productList = queryData?.data;
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const totalPages = Math.ceil(total / perPage);
  const searchEnabled = searchInp.length > 0 || selectSucursal !== null;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const listSucursalF = () => {
    setListSucursal(!listSucursal);
  };

  const searchProducts = (e) => {
    setSearchInp(e.target.value);
  };

  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const addProductN = () => {
    navigate('/products/add');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (!token) {
        closeSession();
      }
    }
  };

  const handleSelect = (data) => {
    setSelectedProduct(data);
    setShowDetails(true);
  };

  const handleClose = () => {
    setShowDetails(false);
  };

  const openToggleStatusModal = (prod, value) => {
    setShowToggleModal(true);
    setNewStatus(value);
    setSelectedProduct(prod);
  };

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProduct(productList.find((item) => item._id === selectedProduct._id));
    }
  }, [productList]);

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
    if (paramStatus === 'reciente' || paramStatus === 'antiguo') {
      setParamStatus(paramStatus);
      setStatusValorInm(undefined);
    } else if (paramStatus === 'precioMayor' || paramStatus === 'precioMenor') {
      setParamStatus(undefined);
      setStatusValorInm(paramStatus);
    }
  }, [page, searchInp, selectSucursal, paramStatus]);

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, []);
  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp, statusFilter]);

  return (
    <div>
      {showDetails && (
        <ProductDetailsModal
          onClose={handleClose}
          product={selectedProduct}
        />
      )}
      {showToggleModal && (
        <ToggleStatusModal
          onClose={() => setShowToggleModal(false)}
          product={selectedProduct}
          newStatus={newStatus}
        />
      )}
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <h2>Productos</h2>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter">
          {checkPermisionRoute('Productos', 'crearProducto') && (
            <button className="button p-r" onClick={addProductN}>
              &nbsp;&nbsp;Publicar nuevo&nbsp;&nbsp;
            </button>
          )}
        </div>
        <div className="col-12 margin-filter">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <label>{total || 0} resultados</label>
        </div>
        <div className="col-12 col-md-4 margin-filter">
          <div className="col-12 col-md-12">
            <div className="input-container">
              <input id="buscar" onChange={(e) => searchProducts(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
              <span className="input-icon search-line" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2 margin-filter content-on">
          <button className="button-secondary btn-p-cl p-r" onClick={listSucursalF}>
            &nbsp;&nbsp;Filtro&nbsp;
            <i className="btn-icon">&#59674;</i>
            &nbsp;&nbsp;
            {listSucursal && (
              <div className="drop-menu lg" onMouseLeave={() => setListSucursal(false)}>
                <label onClick={() => setSelectSucursal(null)}>
                  Todas
                </label>
                {
                  sucursals && sucursals.map((item) => (
                    <label onClick={() => setSelectSucursal(item._id)} key={item._id}>
                      {item.nombre}
                    </label>
                  ))
                }
              </div>
            )}
          </button>
        </div>
        <div className="col-6 col-md-3 btn-content-filt margin-filter">
          <DropdownFilter
            activeValue={statusFilter}
            setActiveValue={setStatusFilter}
            options={[
              {
                label: 'Todos',
                value: 'todos',
              },
              {
                label: 'Activos',
                value: 'activos',
              },
              {
                label: 'Desactivados',
                value: 'desactivados',
              },
            ]}
          />
        </div>
        <div className="col-6 col-md-3 btn-content-filt margin-filter">
          <DropdownFilter
            activeValue={paramStatus}
            setActiveValue={setParamStatus}
            options={[
              {
                label: 'Más recientes',
                value: 'reciente',
              },
              {
                label: 'Más antiguos',
                value: 'antiguo',
              },
              {
                label: 'Mayor precio',
                value: 'precioMayor',
              },
              {
                label: 'Menor precio',
                value: 'precioMenor',
              },
            ]}
          />
        </div>
      </div>
      {productList
        && (
          <TabProducts
            data={productList}
            onSelect={handleSelect}
            onChangeStatus={openToggleStatusModal}
          />
        )}
      {total === 0 && !searchEnabled && <NoProducts />}
      {total === 0 && searchEnabled && <NotFoundProducts />}
      {total > 0 && (
        <div className="row m-t-l">
          <div className="col-12 col-md-6">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <label className="text-pag">{page} de {totalPages}</label>
          </div>
          <div className="col-12 col-md-6 pag-content-btn">
            <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
            <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListProducts;
