/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useCouponForm } from '../../formik/couponForm';
import CheckBox from '../../../../global/custom/checkbox';
import { createCoupon } from '../../services';

const RegisterCoupon = ({ onClose }) => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.auth);
  const today = new Date().toISOString().split('T')[0];

  const {
    values,
    isValid,
    handleChange,
    errors,
    setFieldValue,
  } = useCouponForm({});

  const {
    codigo,
    descuento,
    expira,
    fecha_expiracion,
    fecha_inicio,
    minimo_compra,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitted(true);
      setSubmitting(true);

      if (!isValid) {
        return;
      }
      const [yStart, mStart, dStart] = fecha_inicio.split('-');
      const [yEnd, mEnd, dEnd] = fecha_expiracion ? fecha_expiracion.split('-') : [];

      const iniciaHoy = fecha_inicio === today;
      const formData = {
        ...values,
        fecha_inicio: new Date(yStart, parseInt(mStart, 10) - 1, dStart, 0, 0, 0),
        fecha_expiracion: yEnd ? new Date(yEnd, parseInt(mEnd, 10) - 1, dEnd, 0, 0, 0) : undefined,
        empleado: [
          { idEmpleado: user._id }
        ],
        estatus: iniciaHoy ? 'Activo' : 'Programado',
      };

      const res = await createCoupon(formData);

      if (!res.errors) {
        toast('Cupón creado.', {
          className: 'message',
        });
        queryClient.invalidateQueries(['coupons', 'all']);
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        throw res.errors;
      }
    } catch (err) {
      setSubmitting(false);
      if (err.message.includes('duplicate key error')) {
        toast('El código del cupón es duplicado.', {
          className: 'message error',
        });
      } else {
        toast('No se pudo crear el cupón, intente de nuevo mas tarde.', {
          className: 'message error',
        });
      }
    }
  };

  const changeNumValue = (field, value) => {
    setFieldValue(field, value ? parseFloat(value) : '');
  };

  useEffect(() => {
    if (!expira) {
      setFieldValue('fecha_expiracion', '');
    }
  }, [expira]);

  return (
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-lg-8 col-xl-6 ">
          <div className="content-buttom">&nbsp;</div>
          <div className="col-12 col-md-6 margin-filter">
            <h2>Crear cupón</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Código
                    <input
                      onChange={handleChange('codigo')}
                      value={codigo}
                      id="codigo"
                      type="text"
                      maxLength="10"
                      placeholder="Máximo 10 caracteres"
                      className={`input ${submitted && errors.codigo && 'has-error'}`}
                    />
                    {submitted && errors.codigo && (
                      <small className="caption-error">
                        {errors.codigo}
                      </small>
                    )}
                    <small className="d-block">
                      {codigo.length}
                      {' '}
                      / 10
                    </small>
                  </label>
                </div>
                <div className="col-12 col-md-6" />

                <div className="col-12 col-md-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Valor
                    <CurrencyInput
                      id="descuento"
                      name="descuento"
                      placeholder="0"
                      className={`input ${submitted && errors.descuento && 'has-error'}`}
                      defaultValue={0}
                      decimalsLimit={2}
                      value={descuento}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      onValueChange={(val) => changeNumValue('descuento', val)}
                    />
                    {submitted && errors.descuento && (
                      <small className="caption-error">
                        {errors.descuento}
                      </small>
                    )}
                  </label>
                </div>

                <div className="col-12 col-md-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Mínimo de compra
                    <CurrencyInput
                      id="minimoCompra"
                      name="minimoCompra"
                      placeholder="0"
                      className={`input ${submitted && errors.minimo_compra && 'has-error'}`}
                      defaultValue={0}
                      decimalsLimit={2}
                      value={minimo_compra}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      onValueChange={(val) => changeNumValue('minimo_compra', val)}
                    />
                    {submitted && errors.minimo_compra && (
                      <small className="caption-error">
                        {errors.minimo_compra}
                      </small>
                    )}
                  </label>
                </div>

              </div>

              <div className="row mt-xxl">
                <div className="col-12">
                  <h2>Duración</h2>
                </div>

                <div className="col-12 col-md-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Comienza
                    <input
                      onChange={handleChange('fecha_inicio')}
                      value={fecha_inicio}
                      id="fechaInicio"
                      type="date"
                      min={today}
                      className={`input ${submitted && errors.fecha_inicio && 'has-error'}`}
                    />
                    {submitted && errors.fecha_inicio && (
                      <small className="caption-error">
                        {errors.fecha_inicio}
                      </small>
                    )}
                  </label>
                </div>

                <div className="col-12 col-md-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Termina
                    <input
                      onChange={handleChange('fecha_expiracion')}
                      value={fecha_expiracion}
                      id="fechaExpiracion"
                      type="date"
                      disabled={!expira}
                      min={today}
                      className={`input ${submitted && errors.fecha_expiracion && 'has-error'}`}
                    />
                    {submitted && errors.fecha_expiracion && (
                      <small className="caption-error">
                        {errors.fecha_expiracion}
                      </small>
                    )}
                    <div className="d-flex align-items-center">
                      <CheckBox
                        status={!expira}
                        eventC={() => setFieldValue('expira', !expira)}
                        nameC="expira"
                      />
                      <small className="ms-2">No expira.</small>
                    </div>
                  </label>
                </div>
              </div>
              <div className="row mt-m">
                <div className="col-12">
                  <button
                    type="button"
                    className="button-secondary p-r m-btn-prof"
                    onClick={onClose}
                  >
                    Cancelar
                  </button>
                  <button
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    className="button"
                  >
                    Crear cupón
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterCoupon;
