import { useParams } from 'react-router-dom';
import DetailsContract from '../../features/contract/detailsContract';
import GMenuForm from '../../features/menu';

const ViewContract = () => {
  const { id } = useParams();

  return (
    <>
      <GMenuForm />
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-12 col-lg-8">
            <DetailsContract id={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewContract;
