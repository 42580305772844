import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { setProductDiscounts, updateDiscount, validateDiscount } from '../../services';
import { useDiscountForm } from '../../formik/discountForm';

const EditDiscountDatesForm = ({ currentData, onClose }) => {
  const [submitted, setSubmitted] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();
  const today = new Date();
  const initialDate = today.toISOString().split('T')[0];

  const {
    values,
    isValid,
    handleChange,
    errors,
  } = useDiscountForm(currentData);

  const {
    fecha_inicio,
    fecha_expiracion,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    try {
      setSubmitted(true);
      setSubmitting(true);

      const [yStart, mStart, dStart] = fecha_inicio.split('-');
      const [yEnd, mEnd, dEnd] = fecha_expiracion ? fecha_expiracion.split('-') : [];

      const iniciaHoy = fecha_inicio === initialDate;
      const updateData = {
        fecha_inicio: new Date(yStart, parseInt(mStart, 10) - 1, dStart, 0, 0, 0),
        fecha_expiracion: yEnd ? new Date(yEnd, parseInt(mEnd, 10) - 1, dEnd, 0, 0, 0) : undefined,
        estatus: currentData.estatus !== 'Activo'
          ? (iniciaHoy ? 'Activo' : 'Programado')
          : undefined,
      };

      const validation = await validateDiscount({
        ...updateData,
        allSucursales: currentData.allSucursales,
        sucursales: currentData.sucursales,
        planes: currentData.planes,
        productos: currentData.productos,
        idDescuento: currentData._id,
      });

      if (validation.mensaje.includes('no es valido')) {
        setSubmitting(false);
        toast('No es posible guardar el descuento con las fechas seleccionadas.', {
          className: 'message error',
        });
        return;
      }

      const res = await updateDiscount(currentData._id, updateData);

      if (!res.errors) {
        toast('Descuento actualizado.', {
          className: 'message',
        });
        if (iniciaHoy && currentData.estatus !== 'Activo') {
          await setProductDiscounts({
            idDescuento: currentData._id,
            allSucursales: currentData.allSucursales,
            planes: currentData.planes,
            porcentaje: currentData.porcentaje,
            sucursales: currentData.sucursales,
            productos: currentData.productos
              ? currentData.productos.map((prod) => ({ ...prod, _id: undefined })) : [],
          });
        }
        queryClient.invalidateQueries(['discounts', 'all']);
        onClose();
        setSubmitted(false);
      } else {
        throw res.errors;
      }
    } catch (err) {
      setSubmitting(false);
      toast('No se pudo actualizar el descuento, intente de nuevo mas tarde.', {
        className: 'message error',
      });
    }
  };

  return (
    <div className="row align-items-center justify-content-center">
      <div className="col-12 col-lg-8 col-xl-6 ">
        <div className="content-buttom">&nbsp;</div>
        <div className="d-flex justify-content-between">
          <h2>Editar duración</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Comienza
                  <input
                    onChange={handleChange('fecha_inicio')}
                    value={fecha_inicio}
                    id="fechaInicio"
                    disabled={currentData.estatus === 'Activo'}
                    type="date"
                    min={initialDate}
                    className={`input ${submitted && errors.fecha_inicio && 'has-error'}`}
                  />
                  {submitted && errors.fecha_inicio && (
                    <small className="caption-error">
                      {errors.fecha_inicio}
                    </small>
                  )}
                </label>
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Termina
                  <input
                    onChange={handleChange('fecha_expiracion')}
                    value={fecha_expiracion}
                    id="fechaExpiracion"
                    type="date"
                    min={initialDate}
                    className={`input ${submitted && errors.fecha_expiracion && 'has-error'}`}
                  />
                  {submitted && errors.fecha_expiracion && (
                    <small className="caption-error">
                      {errors.fecha_expiracion}
                    </small>
                  )}
                </label>
              </div>
            </div>
            <div className="row mt-m">
              <div className="col-12">
                <button
                  type="button"
                  className="button-secondary p-r m-btn-prof"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  className="button"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EditDiscountDatesForm;
