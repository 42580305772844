import { useParams } from 'react-router-dom';
import ProfileEmployeeComp from '../../features/profile';
import GMenuForm from '../../features/menu';
// import UpdatePhotoComp from '../../features/profile/components/viewPhoto';

const ProfileEmployee = () => {
  const { id } = useParams();

  return (
    <>
      <GMenuForm />
      <div className="container">
        <div className="row align-items-center justify-content-center m-t-xxxl">
          <div className="col-12 col-md-10">
            <ProfileEmployeeComp idEmployee={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEmployee;
