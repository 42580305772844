/* eslint-disable max-len */
import ResponseCreate from '../addProspects/responseCreate';

const RespAddProspect = ({
  respCreate, eventOp, message, link
}) => (
  <div className="container-fluid navBar-right">
    <div className="row container-white container-h-full">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="container-fluid">
          <div className="row content-ovf">
            <div className="col close-form">
              <span
                className="icon-close cross-line"
                onClick={eventOp}
              >
                {' '}
              </span>
            </div>
            <div className="row align-items-center justify-content-center m-t-xxxl">
              <div className="col-12 col-md-12 col-lg-5">
                {respCreate && <ResponseCreate optionResp={respCreate} message={message} link={link} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RespAddProspect;
