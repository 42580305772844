/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import countriesData from '../../../../../global/data/countries.json';
import { FormikStep2 } from '../../../formik/formikStepsConvert';
import { findCP_S } from '../../../servives';

// eslint-disable-next-line no-unused-vars
const FormStept2 = ({
  cambiarOpcion, opcionAnterior, dataP, saveData
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = FormikStep2();
  const [localidades, setLocalidades] = useState([]);
  const [switchC1, setSwitchC1] = useState(false);
  const [switchC2, setSwitchC2] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [colonies1, setColonies1] = useState(null);
  const [colonies2, setColonies2] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    nombre1,
    apellidoPaterno1,
    apellidoMaterno1,
    telefono1,
    email1,
    cp1,
    estado1,
    ciudad1,
    colonia1,
    direccion1,
    numInterior1,
    numExt1,
    nombre2,
    apellidoPaterno2,
    apellidoMaterno2,
    telefono2,
    email2,
    cp2,
    estado2,
    ciudad2,
    colonia2,
    direccion2,
    numInterior2,
    numExt2,
  } = values;

  const checkedNI1 = (e) => {
    if (e.target.checked) {
      setFieldValue('numInterior1', 's. n.');
    } else {
      setFieldValue('numInterior1', '');
    }
  };
  const checkedNI2 = (e) => {
    if (e.target.checked) {
      setFieldValue('numInterior2', 's. n.');
    } else {
      setFieldValue('numInterior2', '');
    }
  };
  useEffect(() => {
    if (switchC1 && dataP) {
      setFieldValue('cp1', dataP.cp || '');
      setFieldValue('colonia1', dataP.colonia || '');
      setFieldValue('direccion1', dataP.direccion || '');
      setFieldValue('estado1', dataP.estado || '');
      setFieldValue('ciudad1', dataP.ciudad || '');
    } else {
      setFieldValue('cp1', '');
      setFieldValue('colonia1', '');
      setFieldValue('direccion1', '');
      setFieldValue('estado1', '');
      setFieldValue('ciudad1', '');
    }
  }, [switchC1]);

  useEffect(() => {
    if (switchC2 && dataP) {
      setFieldValue('cp2', dataP.cp || '');
      setFieldValue('colonia2', dataP.colonia || '');
      setFieldValue('direccion2', dataP.direccion || '');
      setFieldValue('estado2', dataP.estado || '');
      setFieldValue('ciudad2', dataP.ciudad || '');
    } else {
      setFieldValue('cp2', '');
      setFieldValue('colonia2', '');
      setFieldValue('direccion2', '');
      setFieldValue('estado2', '');
      setFieldValue('ciudad2', '');
    }
  }, [switchC2]);

  useEffect(() => {
    if (estado1) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado1);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      if (!ciudad1) {
        setFieldValue('ciudad1', '');
      }
    }
  }, [estado1]);
  useEffect(() => {
    if (estado2) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado2);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      if (!ciudad2) {
        setFieldValue('ciudad2', '');
      }
    }
  }, [estado2]);

  const getCPService1 = async () => {
    const cpS = `cp=${cp1}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies1(null);
            setFieldValue('colonia1', item.asentamiento);
            setFieldValue('ciudad1', item.municipio);
            setFieldValue('estado1', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad1', item.municipio);
            setFieldValue('estado1', item.estado);
          });
          setColonies1(tempCol);
        } else {
          setColonies1(null);
          setFieldValue('colonia1', '');
          setFieldValue('ciudad1', '');
          setFieldValue('estado1', '');
        }
      }
    });
  };

  useEffect(() => {
    if (cp1.toString() && cp1.toString().length > 4) {
      getCPService1();
    } else if (cp1.toString() && cp1.toString().length < 5) {
      setColonies1(null);
      setFieldValue('colonia1', '');
      setFieldValue('ciudad1', '');
      setFieldValue('estado1', '');
    }
  }, [cp1]);

  const getCPService2 = async () => {
    const cpS = `cp=${cp2}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies2(null);
            setFieldValue('colonia2', item.asentamiento);
            setFieldValue('ciudad2', item.municipio);
            setFieldValue('estado2', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad2', item.municipio);
            setFieldValue('estado2', item.estado);
          });
          setColonies2(tempCol);
        } else {
          setColonies2(null);
          setFieldValue('colonia2', '');
          setFieldValue('ciudad2', '');
          setFieldValue('estado2', '');
        }
      }
    });
  };

  useEffect(() => {
    if (cp2.toString() && cp2.toString().length > 4) {
      getCPService2();
    } else if (cp2.toString() && cp2.toString().length < 5) {
      setColonies2(null);
      setFieldValue('colonia2', '');
      setFieldValue('ciudad2', '');
      setFieldValue('estado2', '');
    }
  }, [cp2]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // console.log(isValid, dirty, errors);
    if (isValid && dirty) {
      cambiarOpcion();
      saveData(values);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-xxl">
            <h2>Referencia de contacto principal</h2>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre1" className="label mt-m">
                  Nombre(s)
                  <input
                    onChange={handleChange('nombre1')}
                    value={nombre1}
                    id="nombre1"
                    type="text"
                    className={`input ${submitted && errors.nombre1 && 'has-error'}`}
                  />
                  {submitted && errors.nombre1 && <small className="caption-error">{errors.nombre1}</small>}
                </label>
              </div>
              <div className="col-12 col-md-6"> </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="apellidoPaterno1" className="label mt-m">
                  Primer apellido
                  <input
                    id="apellidoPaterno1"
                    type="text"
                    className={`input ${submitted && errors.apellidoPaterno1 && 'has-error'}`}
                    onChange={handleChange('apellidoPaterno1')}
                    value={apellidoPaterno1}
                  />
                  {
                    submitted && errors.apellidoPaterno1 && <small className="caption-error">{errors.apellidoPaterno1}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="apellidoMaterno1" className="label mt-m">
                  Segundo apellido
                  <input
                    id="apellidoMaterno1"
                    type="text"
                    className="input"
                    onChange={handleChange('apellidoMaterno1')}
                    value={apellidoMaterno1}
                  />
                  <label htmlFor="sample" className="label font-sm ">
                    (Opcional)
                  </label>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="telefono1" className="label mt-m">
                  Teléfono
                  <input
                    id="telefono1"
                    type="text"
                    placeholder="10 dígitos"
                    className={`input ${submitted && errors.telefono1 && 'has-error'}`}
                    onChange={handleChange('telefono1')}
                    value={telefono1}
                  />
                  {
                    submitted && errors.telefono1 && <small className="caption-error">{errors.telefono1}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="email1" className="label mt-m">
                  Correo electrónico
                  <input
                    id="email1"
                    type="email1"
                    className={`input ${submitted && errors.email1 && 'has-error'}`}
                    onChange={handleChange('email1')}
                    value={email1}
                  />
                  {
                    submitted && errors.email1 && <small className="caption-error">{errors.email1}</small>
                  }
                </label>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div className="row prospect-message-cont">
              <div className="col-10 col-md-11 ">
                <p>Mi referencia tiene la misma dirección que yo</p>
              </div>
              <div className="col-2 col-md-1 ">
                <Switch
                  checked={switchC1}
                  onChange={() => setSwitchC1(!switchC1)}
                  onColor="#000000"
                  onHandleColor="#FFFFFF"
                  offColor="#CFCFCF"
                  offHandleColor="#FFFFFF"
                  borderRadius={60}
                  handleDiameter={5}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={25}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4">
                <label htmlFor="cp1" className="label mt-m ">
                  Código postal
                  <input
                    id="cp1"
                    type="number"
                    name="cp1"
                    maxLength="5"
                    pattern="[0-9]{X,5}"
                    className={`input ${submitted && errors.cp1 && 'has-error'}`}
                    onChange={handleChange('cp1')}
                    value={cp1}
                  />
                  {
                    submitted && errors.cp1 && <small className="caption-error">{errors.cp1}</small>
                  }
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="estado1" className="label mt-m">
                  Estado
                  <select
                    type="select"
                    name="estado1"
                    className={`${submitted && errors.estado1 && 'has-error'}`}
                    value={estado1}
                    onChange={handleChange('estado1')}
                  >
                    <option value="">Estado</option>
                    {countriesData.map(({ states }) => (
                      <option key={states} name={states} value={states}>
                        {states}
                      </option>
                    ))}
                  </select>
                  {
                    submitted && errors.estado1 && <small className="caption-error">{errors.estado1}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="ciudad1" className="label mt-m">
                  Localidad o Municipio
                  <select
                    type="select"
                    name="ciudad1"
                    className={`${submitted && errors.ciudad1 && 'has-error'}`}
                    value={ciudad1}
                    onChange={handleChange('ciudad1')}
                  >
                    <option value="">Localidad</option>
                    {localidades.map((loc) => (
                      <option key={loc} name={loc} value={loc}>
                        {loc}
                      </option>
                    ))}
                  </select>
                  {
                    submitted && errors.ciudad1 && <small className="caption-error">{errors.ciudad1}</small>
                  }
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="colonia1" className="label mt-m ">
                  Colonia
                  {!colonies1 && (
                  <input
                    id="colonia1"
                    type="text"
                    className={`input ${submitted && errors.colonia1 && 'has-error'}`}
                    value={colonia1}
                    onChange={handleChange('colonia1')}
                  />
                  )}
                  {colonies1 && (
                  <select
                    type="select"
                    name="colonia"
                    className={`${submitted && errors.colonia1 && 'has-error'}`}
                    value={colonia1}
                    onChange={handleChange('colonia1')}
                  >
                    <option value="">-Seleccione la Colonia-</option>
                    {colonies1.map((_colonia) => (
                      <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                        {_colonia}
                      </option>
                    ))}
                  </select>
                  )}
                  {
                    submitted && errors.colonia1 && <small className="caption-error">{errors.colonia1}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6"> </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <label htmlFor="direccion1" className="label mt-m ">
                  Calle
                  <input
                    id="direccion1"
                    type="text"
                    className={`input ${submitted && errors.direccion1 && 'has-error'}`}
                    value={direccion1}
                    onChange={handleChange('direccion1')}
                  />
                  {
                    submitted && errors.direccion1 && <small className="caption-error">{errors.direccion1}</small>
                  }
                </label>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <label htmlFor="numInterior1" className="label mt-m ">
                  No. interior
                  <input
                    id="numInterior1"
                    type="text"
                    className={`input ${submitted && errors.numInterior1 && 'has-error'}`}
                    value={numInterior1}
                    onChange={handleChange('numInterior1')}
                  />
                  {
                    submitted && errors.numInterior1 && <small className="caption-error">{errors.numInterior1}</small>
                  }
                  <small className="small-info-input">
                    <input type="checkbox" value="s. n." name="noInt" onChange={(e) => checkedNI1(e)} />
                    Sin número
                  </small>
                </label>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <label htmlFor="numExt1" className="label mt-m ">
                  No. exterior
                  <input
                    id="numExt1"
                    type="text"
                    className={`input ${submitted && errors.numExt1 && 'has-error'}`}
                    value={numExt1}
                    onChange={handleChange('numExt1')}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Referencia de contacto secundaria</h2>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre2" className="label mt-m">
                  Nombre(s)
                  <input
                    onChange={handleChange('nombre2')}
                    value={nombre2}
                    id="nombre2"
                    type="text"
                    className={`input ${submitted && errors.nombre2 && 'has-error'}`}
                  />
                  {submitted && errors.nombre2 && <small className="caption-error">{errors.nombre2}</small>}
                </label>
              </div>
              <div className="col-12 col-md-6"> </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="apellidoPaterno2" className="label mt-m">
                  Primer apellido
                  <input
                    id="apellidoPaterno2"
                    type="text"
                    className={`input ${submitted && errors.apellidoPaterno2 && 'has-error'}`}
                    onChange={handleChange('apellidoPaterno2')}
                    value={apellidoPaterno2}
                  />
                  {
                    submitted && errors.apellidoPaterno2 && <small className="caption-error">{errors.apellidoPaterno2}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="apellidoMaterno2" className="label mt-m">
                  Segundo apellido
                  <input
                    id="apellidoMaterno2"
                    type="text"
                    className="input"
                    onChange={handleChange('apellidoMaterno2')}
                    value={apellidoMaterno2}
                  />
                  <label htmlFor="sample" className="label font-sm ">
                    (Opcional)
                  </label>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="telefono2" className="label mt-m">
                  Teléfono
                  <input
                    id="telefono2"
                    type="text"
                    placeholder="10 dígitos"
                    className={`input ${submitted && errors.telefono2 && 'has-error'}`}
                    onChange={handleChange('telefono2')}
                    value={telefono2}
                  />
                  {
                    submitted && errors.telefono2 && <small className="caption-error">{errors.telefono2}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="email2" className="label mt-m">
                  Correo electrónico
                  <input
                    id="email2"
                    type="email2"
                    className={`input ${submitted && errors.email2 && 'has-error'}`}
                    onChange={handleChange('email2')}
                    value={email2}
                  />
                  {
                    submitted && errors.email2 && <small className="caption-error">{errors.email2}</small>
                  }
                </label>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div className="row prospect-message-cont">
              <div className="col-10 col-md-11 ">
                <p>Mi referencia tiene la misma dirección que yo</p>
              </div>
              <div className="col-2 col-md-1 ">
                <Switch
                  checked={switchC2}
                  onChange={() => setSwitchC2(!switchC2)}
                  onColor="#000000"
                  onHandleColor="#FFFFFF"
                  offColor="#CFCFCF"
                  offHandleColor="#FFFFFF"
                  borderRadius={60}
                  handleDiameter={5}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={15}
                  width={25}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4">
                <label htmlFor="cp2" className="label mt-m ">
                  Código postal
                  <input
                    id="cp2"
                    type="number"
                    name="cp2"
                    maxLength="5"
                    pattern="[0-9]{X,5}"
                    className={`input ${submitted && errors.cp2 && 'has-error'}`}
                    onChange={handleChange('cp2')}
                    value={cp2}
                  />
                  {
                    submitted && errors.cp2 && <small className="caption-error">{errors.cp2}</small>
                  }
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="estado2" className="label mt-m">
                  Estado
                  <select
                    type="select"
                    name="estado2"
                    className={`${submitted && errors.estado2 && 'has-error'}`}
                    value={estado2}
                    onChange={handleChange('estado2')}
                  >
                    <option value="">Estado</option>
                    {countriesData.map(({ states }) => (
                      <option key={states} name={states} value={states}>
                        {states}
                      </option>
                    ))}
                  </select>
                  {
                    submitted && errors.estado2 && <small className="caption-error">{errors.estado2}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="ciudad2" className="label mt-m">
                  Localidad o Municipio
                  <select
                    type="select"
                    name="ciudad2"
                    className={`${submitted && errors.ciudad2 && 'has-error'}`}
                    value={ciudad2}
                    onChange={handleChange('ciudad2')}
                  >
                    <option value="">Localidad</option>
                    {localidades.map((loc) => (
                      <option key={loc} name={loc} value={loc}>
                        {loc}
                      </option>
                    ))}
                  </select>
                  {
                    submitted && errors.ciudad2 && <small className="caption-error">{errors.ciudad2}</small>
                  }
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="colonia2" className="label mt-m ">
                  Colonia
                  {!colonies2 && (
                  <input
                    id="colonia2"
                    type="text"
                    className={`input ${submitted && errors.colonia2 && 'has-error'}`}
                    value={colonia2}
                    onChange={handleChange('colonia2')}
                  />
                  )}
                  {colonies2 && (
                  <select
                    type="select"
                    name="colonia"
                    className={`${submitted && errors.colonia2 && 'has-error'}`}
                    value={colonia2}
                    onChange={handleChange('colonia2')}
                  >
                    <option value="">-Seleccione la Colonia-</option>
                    {colonies2.map((_colonia) => (
                      <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                        {_colonia}
                      </option>
                    ))}
                  </select>
                  )}
                  {
                    submitted && errors.colonia2 && <small className="caption-error">{errors.colonia2}</small>
                  }
                </label>
              </div>
              <div className="col-12 col-md-6"> </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <label htmlFor="direccion2" className="label mt-m ">
                  Calle
                  <input
                    id="direccion2"
                    type="text"
                    className={`input ${submitted && errors.direccion2 && 'has-error'}`}
                    value={direccion2}
                    onChange={handleChange('direccion2')}
                  />
                  {
                    submitted && errors.direccion2 && <small className="caption-error">{errors.direccion2}</small>
                  }
                </label>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <label htmlFor="numInterior2" className="label mt-m ">
                  No. interior
                  <input
                    id="numInterior2"
                    type="text"
                    className={`input ${submitted && errors.numInterior2 && 'has-error'}`}
                    value={numInterior2}
                    onChange={handleChange('numInterior2')}
                  />
                  {
                    submitted && errors.numInterior2 && <small className="caption-error">{errors.numInterior2}</small>
                  }
                  <small className="small-info-input">
                    <input type="checkbox" value="s. n." name="noInt" onChange={(e) => checkedNI2(e)} />
                    Sin número
                  </small>
                </label>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <label htmlFor="numExt2" className="label mt-m ">
                  No. exterior
                  <input
                    id="numExt2"
                    type="text"
                    className={`input ${submitted && errors.numExt2 && 'has-error'}`}
                    value={numExt2}
                    onChange={handleChange('numExt2')}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-2">
              <button className="button-secondary mt-m" onClick={() => opcionAnterior()}>
                Regresar
              </button>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <button className="button mt-m" type="submit">
                Finalizar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default FormStept2;
