import axios from '../../../store/axios';

export const getMyNotifications = async (options) => {
  try {
    const res = await axios().get(`notifications/my-notifications?${options}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const markAsRead = async (options) => {
  try {
    const res = await axios().patch('notifications/mark-as-read', options);
    return res;
  } catch (err) {
    return err;
  }
};
