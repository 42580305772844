import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import ProfileDefComponent from '../../../shared/profileDef';

const ListContractCobranzaComponent = ({ data }) => {
  // console.log(data);
  const navigate = useNavigate();
  const redirectContractClient = (idContract) => {
    navigate(`/clients/contract-view/${idContract}`);
  };
  const getCompleteName = (item) => {
    if (item.cobradorData) {
      let nameUser = `${item.cobradorData.nombre || ''} ${item.cobradorData.apellidoPaterno || ''} ${item.cobradorData.apellidoMaterno || ''}`;
      nameUser = nameUser.trim();
      return nameUser;
    }
    return 'Sin Asignar';
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const styleMoney = (money) => {
    const tempM = money.split('.');
    const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
    return tempHtml;
  };
  return (
    <div className="m-t-l">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="row">
              <div className="col-6 col-md-4">
                <small className="text-title-tab">CONTRATO</small>
              </div>
              <div className="col-6 col-md-4">
                <small className="text-title-tab">COBRADOR</small>
              </div>
              <div className="col-6 col-md-4 p-r">
                <small className="text-title-tab ">SALDO</small>
              </div>
            </div>
          </div>
          {
            data && data.length > 0 && data.map((item) => (
              <div className="row" key={item._id}>
                <div className="row row-cursor" onClick={() => redirectContractClient(item._id)}>
                  <div className="col-6 col-md-4 p-r row-mov-h">
                    <label className="text-name">
                      {item.noContrato}
                      &nbsp;
                    </label>
                    <label className="text-dir wb-ba">
                      <i>&#59679;</i>
                      {item?.sucursaless?.nombre}
                    </label>
                  </div>
                  <div className="col-6 col-md-4 row-mov-h">
                    <div className="row">
                      <div className="col-2">
                        <ProfileDefComponent
                          name={getCompleteName(item) !== 'Sin Asignar' ? getCompleteName(item) : null}
                          size=""
                          imgProf={item?.cobradorData?.imagen_perfil || null}
                        />
                      </div>
                      <div className="col-10">
                        <div className="row">
                          <div className="col-12  wb-bw">
                            {getCompleteName(item)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 row-mov-h">
                    <label className="text-name text-money">
                      {item?.saldo && item?.saldo > 0
                        ? styleMoney(formatMoney.format(item.saldo))
                        : <i className="text-liq">Liquidado</i>}
                    </label>
                    <label className="text-dir text-money">
                      Total:&nbsp;
                      {styleMoney(formatMoney.format(item?.precioVenta))}
                    </label>
                  </div>
                </div>
                <hr className="line-spacing" />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ListContractCobranzaComponent;
