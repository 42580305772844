import axios from '../../../store/axios';

export const getEmpleado = async (query) => {
  try {
    const res = await axios().get(`empleados/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmpleadosByPage = async ({
  nombre, sucursal, paginacion, paginas, orden,
}) => {
  try {
    const res = await axios().get('empleados/all', {
      params: {
        nombre,
        sucursal,
        paginacion,
        paginas,
        orden
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllSucursales = async (query = '&paginacion=1&paginas=1000') => {
  try {
    const res = await axios().get(`sucursales/buscar?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const postImagen = async (file, idEmpleado) => {
  try {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', idEmpleado);
    formData.append('ref', 'empleados');

    let success = await axios().post(
      '/aws',
      formData,
      { headers: { 'content-type': 'multipart/form-data' } }
    );
    return success;
  } catch (err) {
    return false;
  }
};

export const registrarEmpleado = async (params) => {
  try {
    const res = await axios().post('empleados', params);
    return res;
  } catch (err) {
    return err;
  }
};
export const actualizarEmpleado = async (idEmpleado, params) => {
  try {
    const res = await axios().put(`/empleados/actualizar/${idEmpleado}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const getEmployeeByID = async (idClient) => {
  try {
    const res = await axios().get(`empleados/buscar/${idClient}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const findCP_S = async (query) => {
  try {
    const res = await axios().get(`/cp/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getModules = async () => {
  try {
    const res = await axios().get('/modulos/buscar');
    return res;
  } catch (err) {
    return err;
  }
};

export const addModuleToEmployee = async (id, data) => {
  try {
    const res = await axios().post(`/empleados/modulo/add/${id}`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEmployeeModule = async (id, idModulo, data) => {
  try {
    const res = await axios().post(`/empleados/modulo/update/${id}/${idModulo}`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const getListIngresosHistory = async ({
  paginacion, paginas, nombre, fecha, fechaInicio, fechaFinal
}) => {
  try {
    const res = await axios().get('ingresos/buscar', {
      params: {
        paginacion, paginas, nombre, fecha, fechaInicio, fechaFinal
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};
export const actualizarEmpleadoStatus = async (idEmpleado, params) => {
  try {
    const res = await axios().put(`/empleados/actualizarStatusEmpleado/${idEmpleado}`, params);
    return res;
  } catch (err) {
    return err;
  }
};
