import axios from '../../../store/axios';

export const login = async (email, password) => {
  try {
    const res = await axios().post('auth', {
      email,
      password
    });
    // console.log('login', res);
    return res;
  } catch (err) {
    return err;
  }
};

export const requestPasswordChange = async (email) => {
  try {
    const res = await axios().post('auth/send-restore-passw', { email });
    return res;
  } catch (err) {
    return err;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const res = await axios().post('auth/restore-passw', { newPassword, token }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};
