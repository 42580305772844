import parse from 'html-react-parser';
import '../profile.css';

const MenuP = ({ eventM, activeM }) => {
  const checkActiveOp = (option) => {
    if (activeM === option) {
      return 'active';
    }
    return '';
  };

  return (
    <div className="menu-prof">
      <button onClick={() => eventM(1)} className={checkActiveOp(1)}>
        <i className="icon">{checkActiveOp(1) ? parse('&#59689;') : parse('&#59687;')}</i>
        General
      </button>
      <button onClick={() => eventM(2)} className={checkActiveOp(2)}>
        <i className="icon">{checkActiveOp(2) ? parse('&#59690;') : parse('&#59688;')}</i>
        Seguridad
      </button>
    </div>
  );
};

export default MenuP;
