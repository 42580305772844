/* eslint-disable no-unused-vars */
import { useState } from 'react';
import SelectCollectorC from './addCollectorC/selectColector';
import { updateContract } from '../services';

const AddProspectM = ({
  idContract, eventOp, statusOp, refresh, eventShowMessage
}) => {
  const [stateStep1, setStateStep1] = useState('active');

  const onClose = () => {
    setStateStep1('disabled');
    eventOp();
  };

  const saveData1 = async (dataFrm) => {
    const tempData = {
      cobrador: {
        idEmpleado: dataFrm,
      }
    };
    await updateContract(idContract, tempData).then((resp) => {
      if (resp) {
        eventOp();
        refresh();
        eventShowMessage('Cobrador asignado');
      } else {
        eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
      }
    });
  };

  return (
    <>
      <div className="absolute-content trasp ">
        {statusOp && <SelectCollectorC eventOp={onClose} statusOp={statusOp} setData={saveData1} />}
      </div>
    </>
  );
};

export default AddProspectM;
