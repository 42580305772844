/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';

const TypeClientComp = ({
  eventOp, statusOp, setData, returnView,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <span
                  className="icon-close return-icon"
                  onClick={returnView}
                >
                  {' '}
                </span>
              </div>
              <div className="col close-form">
                <span
                  className="icon-close cross-line"
                  onClick={eventOp}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="col-12 spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Tipo de cliente</h2>
                      <br />
                      <small>Selecciona un tipo de cliente para este contrato</small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12">
                      <div className="row row-cursor" onClick={() => setData('NUEVO')}>
                        <div className="col-2 ">
                          <div className="row">
                            <div className="col-12 cont-icon-info sml">
                              <label className="icon-info-client sml">&#59672;</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <label className="text-name m-text-l">Crear un nuevo cliente</label>
                        </div>
                        <div className="col-2 ">
                          <label className="l-icon-btn">
                            <i className="btn-icon">&#59659;</i>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <br />
                      </div>
                      <div className="row row-cursor" onClick={() => setData('EXISTENTE')}>
                        <div className="col-2 ">
                          <div className="row">
                            <div className="col-12 cont-icon-info sml">
                              <label className="icon-info-client sml">&#59650;</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-8">
                          <label className="text-name m-text-l">Seleccionar cliente existente</label>
                        </div>
                        <div className="col-2 ">
                          <label className="l-icon-btn">
                            <i className="btn-icon">&#59659;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 spacing-nav"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeClientComp;
