import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import '../../emple.css';
import ProfileDefComponent from '../../../shared/profileDef';

const ListEmpleados = ({ data, permision }) => {
  const navigate = useNavigate();
  const redirectContractEmployee = (idEmployee) => {
    if (permision('Empleados', 'consultarEmpleado')) {
      navigate(`/contributors/${idEmployee}`);
    }
  };

  const capitalizeString = (stringP) => {
    const str_arr = stringP.split(' ');
    for (let i = 0; i < str_arr.length; i += 1) {
      if (str_arr[i].length > 3) {
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
      } else if (str_arr[i] === 'rh' || str_arr[i] === 'Recursos Humanos') {
        str_arr[i] = str_arr[i].toUpperCase();
      } else {
        str_arr[i] = str_arr[i].toLowerCase();
      }
    }
    return str_arr.join(' ');
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-7">
            <small className="text-title-tab">Empleado</small>
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            <small className="text-title-tab">SUCURSAL</small>
          </div>
          <div className="col-6 col-sm-6 col-md-2">
            <small className="text-title-tab">PUESTO</small>
          </div>
        </div>
        {
          data && data.length > 0 && data.map((item) => (
            <div className="row row-cursor" key={item._id} onClick={() => redirectContractEmployee(item._id)}>
              <div className="col-3 col-sm-2 col-md-2 col-lg-1">
                <ProfileDefComponent
                  name={item.nombre}
                  size="md"
                  imgProf={item.imagen || null}
                />
              </div>
              <div className="col-9 col-sm-10 col-md-5 col-lg-6">
                <label className="text-name">{item.nombre}</label>
                <label className="text-email">{item.email || parse('&nbsp;')}</label>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <label className="text-dir">
                  <i>&#59679;</i>
                  {item.sucursal || '-'}
                </label>
              </div>
              <div className="col-6 col-sm-6 col-md-2">
                <label className="text-dir">{capitalizeString(item.puesto) || 'No Asignado'}</label>
              </div>
              <hr className="line-spacing" />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ListEmpleados;
