import { useQuery } from 'react-query';
import { getProspectsByPage } from '../servives';

export const useProspectsPerPage = ({
  page,
  perPage,
  search,
  order,
  fechaOrden,
  location,
  onSuccess,
  onError,
}) => useQuery(
  ['prospects', 'all', {
    page, search, order, location, fechaOrden
  }],
  () => {
    const nombre = search ? `${encodeURIComponent(search).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';
    const sucursal = location;

    return getProspectsByPage({
      nombre: nombre || undefined,
      sucursal: sucursal || undefined,
      orden: order,
      fechaOrden,
      paginacion: page,
      paginas: perPage,
    });
  },
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);
