import { useState, useEffect } from 'react';
import MessageInfo from '../../../messageInfo';
import { getAllSucursales } from '../../../prospects/servives';

const SucursalSelectionStep = ({
  onClose,
  statusOp,
  setData,
  nextStep,
  prevStep,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [dataSuc, setDataSuc] = useState(null);
  const [selectSucursal, setSelectSucursal] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const searchSuc = async () => {
    const tempS = await getAllSucursales();
    const tempSuc = [];
    if (tempS && tempS.length > 0) {
      tempS.forEach((element) => {
        tempSuc.push({
          nombre: element.nombre.toUpperCase(),
          ciudad: element.ciudad,
          _id: element._id
        });
      });
      setDataSuc(tempSuc);
    }
  };
  const nextStet = () => {
    dataSuc.forEach((element) => {
      if (selectSucursal === element._id) {
        const tempS = {
          nombre: element.nombre.toUpperCase(),
          _id: element._id
        };
        setData(tempS);
        nextStep();
      }
    });
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);
  useEffect(() => {
    searchSuc();
  }, []);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid side-form-container">
            <div className="row">
              <div className="col-6">
                <span
                  className="icon-close return-icon"
                  onClick={prevStep}
                >
                  {' '}
                </span>
              </div>
              <div className="col-6 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="col-12 spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Sucursal</h2>
                      <br />
                      <small>Selecciona una sucursal.</small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12">
                      <MessageInfo
                        message="El precio y disponibilidad de los productos podría variar en cada sucursal"
                        type=""
                      />
                    </div>
                    <div className="col-12">
                      {dataSuc && dataSuc.length > 0 && dataSuc.map((item) => (
                        <div
                          key={generateKey(item._id)}
                          className="container-fluid "
                          onClick={() => setSelectSucursal(item._id)}
                        >
                          <div className={`row row-itemN ${selectSucursal === item._id ? 'select' : ''}`}>
                            <div className="col-12 ">
                              <p>
                                {item.nombre || ''}
                              </p>
                              <label className="text-dir">
                                <i>&#59679;</i>
                                {item.ciudad || ''}
                                ,
                                {item.estado || ''}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-12 p-r cont-btn">
                      <button className="button navR-content-btn" onClick={() => nextStet()} type="button" disabled={!selectSucursal ? 'disabled' : ''}>
                        Continuar
                      </button>
                    </div>
                    <div className="col-12 spacing-nav"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SucursalSelectionStep;
