import { useFormik } from 'formik';
import * as Yup from 'yup';

export const StepP1 = () => useFormik({
  validateOnMount: false,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    parentesco: '',
    telefono: '',
    edad: 0,
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    direccion: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('Ingresa el primer apellido'),
    fechaNacimiento: Yup.string()
      .required('Ingresa la fecha de nacimiento'),
    parentesco: Yup.string()
      .required('El parentesco es requerido'),
    telefono: Yup.string()
      .required('El teléfono es requerido')
      .matches(/^[0-9]+$/, 'No es un número válido')
      .max(10, 'El número debe tener máximo 10 dígitos')
      .min(10, 'El número debe tener minimo 10 dígitos'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('Selecciona un estado'),
    ciudad: Yup.string()
      .required('Selecciona una localidad o municipio'),
    colonia: Yup.string()
      .required('La colonia es requerida'),
    direccion: Yup.string()
      .required('La Dirección es requerida'),
  })
});

export const StepP3 = () => useFormik({
  validateOnMount: false,
  initialValues: {
    sucursales: [{
      value: '',
      label: '',
    }],
  },
  validationSchema: Yup.object({
    sucursales: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    ).required('La Sucursal es requerida'),
  })
});

export const FEditDataP = () => useFormik({
  validateOnMount: false,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    parentesco: '',
    telefono: '',
    edad: 0,
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('Ingresa el primer apellido'),
    fechaNacimiento: Yup.string()
      .required('Ingresa la fecha de nacimiento'),
    parentesco: Yup.string()
      .required('El parentesco es requerido'),
    telefono: Yup.string()
      .required('El teléfono es requerido')
      .matches(/^[0-9]+$/, 'No es un número válido')
      .max(10, 'El número debe tener máximo 10 dígitos')
      .min(10, 'El número debe tener minimo 10 dígitos'),
  })
});

export const FEditDataA = () => useFormik({
  validateOnMount: false,
  initialValues: {
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    direccion: '',
  },
  validationSchema: Yup.object({
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('Selecciona un estado'),
    ciudad: Yup.string()
      .required('Selecciona una localidad o municipio'),
    colonia: Yup.string()
      .required('La colonia es requerida'),
    direccion: Yup.string()
      .required('La Dirección es requerida'),
  })
});

export const FEditReference = () => useFormik({
  validateOnMount: false,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    direccion: '',
    colonia: '',
    ciudad: '',
    estado: '',
    cp: '',
    telefono: '',
    email: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('El Apellido Paterno es requerido'),
    apellidoMaterno: Yup.string(),
    direccion: Yup.string(),
    colonia: Yup.string(),
    ciudad: Yup.string(),
    estado: Yup.string(),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    telefono: Yup.string(),
    email: Yup.string()
      .email('La dirección de correo no es válida'),
  })
});

export const FValidatedContract = () => useFormik({
  validateOnMount: false,
  initialValues: {
    folio: '',
    numContrato: '',
    totalSale: '',
    typePaymend: '',
    anticipo: '',
    saldo: '',
    mensualidades: '',
    payDate: '',
    fileContract: null,
  },
  validationSchema: Yup.object({
    folio: Yup.string(),
    numContrato: Yup.string(),
    totalSale: Yup.string(),
    typePaymend: Yup.string(),
    anticipo: Yup.string(),
    saldo: Yup.string(),
    mensualidades: Yup.string(),
    payDate: Yup.string(),
    fileContract: Yup.mixed().nullable(),
  })
});

export const FContractInfo = () => useFormik({
  validateOnMount: false,
  initialValues: {
    folio: '',
    noContrato: '',
    sucursal: '',
    fecha: '',
    medioDeVenta: '',
    comentarios: '',
    precioVenta: '',
    formaPago: '',
    mensualidades: '',
    mensualidad: '',
    diaPago: '',
    pagoInicial: '',
    liquidado: '',
    saldo: '',
    estatus_contrato: '',
    lote: '',
  },
  validationSchema: Yup.object({
    folio: Yup.string(),
    noContrato: Yup.string(),
    sucursal: Yup.string(),
    fecha: Yup.string(),
    medioDeVenta: Yup.string(),
    comentarios: Yup.string(),
    precioVenta: Yup.string(),
    formaPago: Yup.string(),
    mensualidades: Yup.string(),
    mensualidad: Yup.string(),
    diaPago: Yup.string(),
    pagoInicial: Yup.string(),
    liquidado: Yup.string(),
    saldo: Yup.string(),
    estatus_contrato: Yup.string(),
    lote: Yup.string(),
  })
});

export const FContractOwnerInfo = () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    apellidoMaterno: '',
    apellidoPaterno: '',
    rfc: '',
    fechaNacimiento: '',
    estadoCivil: '',
    ocupacion: '',
    genero: 'masculino',
    telefono: '',
    email: '',
    edad: 0,
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    direccion: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre del cliente es requerido'),
    apellidoPaterno: Yup.string()
      .required('Ingresa el primer apellido'),
    fechaNacimiento: Yup.string()
      .required('Ingresa la fecha de nacimiento'),
    telefono: Yup.string()
      .required('El teléfono es requerido')
      .matches(/^[0-9]+$/, 'No es un número válido')
      .max(10, 'El número debe tener máximo 10 dígitos')
      .min(10, 'El número debe tener máximo 10 dígitos'),
    email: Yup.string()
      .email('La dirección de correo no es válida')
      .required('El correo electrónico es requerido'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('Selecciona un estado'),
    ciudad: Yup.string()
      .required('Selecciona una localidad o municipio'),
    colonia: Yup.string()
      .required('La colonia es requerida'),
    direccion: Yup.string()
      .required('La calle es requerida'),
  })
});
