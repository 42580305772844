import { useFormik } from 'formik';
import * as Yup from 'yup';

const rcRegExp = /^([a-z]{3,4})(\d{2})(\d{2})(\d{2})([0-9a-z]{3})$/i;

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fecha_nacimiento: '',
    sexo: '',
    estado_civil: '',
    telefono: '',
    email: '',
    rfc: '',
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    direccion: '',
    entreCalle: '',
    role: '',
    puesto: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('El Apellido Paterno es requerido'),
    apellidoMaterno: Yup.string(),
    fecha_nacimiento: Yup.string(),
    sexo: Yup.string()
      .required('El género es requerido'),
    estado_civil: Yup.string(),
    telefono: Yup.string(),
    email: Yup.string()
      .email('La dirección de correo no es válida'),
    rfc: Yup.string()
      .matches(rcRegExp, 'El RFC es invalido'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string(),
    ciudad: Yup.string(),
    colonia: Yup.string(),
    direccion: Yup.string(),
    entreCalle: Yup.string(),
    role: Yup.string()
      .min(3, 'El Rol es requerido'),
    puesto: Yup.string()
      .min(3, 'El Puesto es requerido'),
  })
});
