/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import parse from 'html-react-parser';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import NavPage from '../navPage';
import ProductsContract from './components/products';
import DetailsContractNav from './components/details';
import OwnerContractNav from './components/owner';
import BeneficiariesContract from './components/beneficiaries';
import ReferencesContract from './components/references';
import EditReference from './components/editReference';
import ValidatedContract from './components/validatedContract';
import AddProspectM from './components/addCollectorM';
import AddProductsM from './components/addProductsM';
import { getContractByID, downloadFileContrServ, updateVentaById } from './services';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import Backbutton from '../shared/backbutton/backbutton';
import EditContractView from './editContractView';

import './contract.css';
import { monthDayYearFormat } from '../../helpers/dates';

const DetailsContract = ({ id }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);
  const [contractInfo, setContractInfo] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [refersInfo, setRefersInfo] = useState(null);
  const [ProductsInfo, setProductsInfo] = useState(null);
  const [benefitInfo, setBenefitInfo] = useState(null);
  const [openRef, setOpenRef] = useState(false);
  const [refPos, setRefPos] = useState(null);
  const [openValidM, setOpenValidM] = useState(false);
  const [openAddPM, setOpenAddPM] = useState(false);
  const [openM, setOpenM] = useState(false);
  const [contractEditable, setContractEditable] = useState(true);
  const [openFormContr, setOpenFormContr] = useState(false);
  const [contractForm, setContractForm] = useState(null);

  const handleBackButtonClick = () => {
    navigate('/contracts');
  };

  const optionNav = ['Productos', 'Detalles', 'Titular', 'Referencias', 'Beneficiarios'];
  const [activeNav, setActiveNav] = useState('Productos');
  const eventChangeNav = (item) => {
    setActiveNav(item);
  };
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 0.00;
  };
  const styleMoney = (money) => {
    if (money && money !== '-') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }

    if (money === 0) {
      return (
        <>
          $0
          <small>00</small>
        </>
      );
    }
    return money;
  };

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }
    return [year, month, day].join('-');
  };
  const compareByNivel = (a, b) => {
    if (a.nivel < b.nivel) {
      return -1;
    }
    if (a.nivel > b.nivel) {
      return 1;
    }
    return 0;
  };
  const eventM = () => {
    setOpenM(!openM);
  };
  const capitalizeFirstLowercaseRest = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return '-';
  };
  const getContractInfo = async (updateData = false) => {
    await getContractByID(id).then(async (resp) => {
      if (resp && resp[0] && resp[0]._id) {
        if (resp[0] && resp[0].fecha) {
          const tempD = new Date(resp[0].fecha);
          resp[0].fecha = formatDate(tempD);
        }
        if (resp[0] && resp[0].precioVenta) {
          if (resp[0].saldo) {
            resp[0].abonadoT = resp[0].precioVenta - resp[0].saldo;
          } else {
            resp[0].abonadoT = 0;
          }
        } else {
          resp[0].abonadoT = 0;
        }
        setClientInfo(resp[0].clients);
        if (resp[0].beneficiarios) {
          resp[0].beneficiarios.sort(compareByNivel);
          setBenefitInfo(resp[0].beneficiarios);
        } else {
          setBenefitInfo(null);
        }
        let tempTotal = 0;
        try {
          resp[0].productos.forEach((itemP, index) => {
            resp[0].productoss.forEach((itemPL) => {
              if (itemP.idProducto === itemPL._id) {
                resp[0].productos[index].nombre = itemPL.nombre;
                resp[0].productos[index].imagen = itemPL.imagen;
                resp[0].productos[index].galeria = itemPL.galeria;
                resp[0].productos[index].tipo = capitalizeFirstLowercaseRest(itemPL.tipo);
                if (!itemP.disponibilidad) {
                  resp[0].productos[index].disponibilidad = '-';
                }
                if (resp[0].estatus_contrato.toLowerCase() === 'por validar' || resp[0].estatus_contrato.toLowerCase() === 'borrador') {
                  if (itemP.disponibilidad && itemP.disponibilidad === 'prevision') {
                    if (itemPL?.descuentos?.prevision?.descuentoPrevision) {
                      tempTotal += (itemP.cantidad * itemPL.descuentos.prevision.descuentoPrevision) || 0;
                      resp[0].productos[index].precioPrevision = itemPL.descuentos.prevision.descuentoPrevision || 0;
                      resp[0].productos[index].precioOriginal = itemPL.precioPrevision || 0;
                    } else {
                      tempTotal += (itemP.cantidad * itemPL.precioPrevision) || 0;
                      resp[0].productos[index].precioPrevision = itemPL.precioPrevision || 0;
                    }
                  } else if (itemP.disponibilidad && itemP.disponibilidad === 'inmediata') {
                    if (itemPL?.descuentos?.usoInmediato?.descuentoUsoInmediato) {
                      tempTotal += (itemP.cantidad * itemPL.descuentos.usoInmediato.descuentoUsoInmediato) || 0;
                      resp[0].productos[index].precio = itemPL.descuentos.usoInmediato.descuentoUsoInmediato || 0;
                      resp[0].productos[index].precioOriginal = itemPL.precio || 0;
                    } else {
                      tempTotal += (itemP.cantidad * itemPL.precio) || 0;
                      resp[0].productos[index].precio = itemPL.precio || 0;
                    }
                  }
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (itemP.disponibilidad && itemP.disponibilidad === 'prevision') {
                    tempTotal += (itemP.cantidad * itemP.precioDescuento) || 0;
                    resp[0].productos[index].precioPrevision = itemP.precioDescuento || 0;
                    if (itemP.precioDescuento !== itemP.precioInicial) {
                      resp[0].productos[index].precioOriginal = itemP.precioInicial || 0;
                    }
                  } else if (itemP.disponibilidad && itemP.disponibilidad === 'inmediata') {
                    tempTotal += (itemP.cantidad * itemP.precioDescuento) || 0;
                    resp[0].productos[index].precio = itemP.precioDescuento || itemP.precio;
                    if (itemP.precioDescuento && itemP.precioDescuento !== itemP.precioInicial) {
                      resp[0].productos[index].precioOriginal = itemP.precioInicial || 0;
                    }
                  }
                }
              }
            });
          });
          if (resp[0].cobradorData) {
            let nameUser = `${resp[0].cobradorData.nombre || ''} ${resp[0].cobradorData.apellidoPaterno || ''} ${resp[0].cobradorData.apellidoMaterno || ''}`;
            nameUser = nameUser.trim();
            resp[0].cobradorData.nameC = nameUser;
          }
          if (resp[0].nombre_empleado.trim() === '') {
            let nameVendedor = `${resp[0].vendedor || ''}`;
            nameVendedor = nameVendedor.trim();
            resp[0].nombre_empleado = nameVendedor;
          }
          if (resp[0].plazo) {
            resp[0].plazo = resp[0].plazo.split(' X ')[0] || '';
          }
        } catch (_error) {
          //
        }
        resp[0].precioVentaOriginal = tempTotal;
        if (resp[0]?.Cuponinfo) {
          tempTotal -= resp[0].Cuponinfo.descuento;
        }
        resp[0].precioVenta = tempTotal;
        if (updateData) {
          if (resp[0].formaPago === 'credito' || resp[0].formaPago === 'mensualidades') {
            const mesesSpl = resp[0].plazo.split('X') || resp[0].plazo;
            const mesesInt = parseInt(mesesSpl[0], 10);
            const mensualidadesTemp = (resp[0].precioVenta / mesesInt).toFixed(2);
            let updateDate = {
              precioVenta: resp[0].precioVenta,
              saldo: resp[0].precioVenta - (resp[0].pagoInicial || 200),
              mensualidad: mensualidadesTemp,
              plazo: `${mesesInt} X $${mensualidadesTemp}`,
              pagoInicial: resp[0].pagoInicial || 200,
              liquidado: (resp[0].pagoInicial || 200),
            };
            await updateVentaById(resp[0]._id, updateDate).then((respUp) => {
              if (respUp && !respUp.errors) {
                getContractInfo(false);
              }
            });
          } else if (resp[0].formaPago === 'contado') {
            let updateDate = {
              precioVenta: resp[0].precioVenta,
              saldo: resp[0].precioVenta,
              mensualidad: resp[0].precioVenta,
              plazo: `1 X $${resp[0].precioVenta}`,
              pagoInicial: resp[0].precioVenta,
              liquidado: resp[0].precioVenta,
            };
            await updateVentaById(resp[0]._id, updateDate).then((respUp) => {
              if (respUp && !respUp.errors) {
                getContractInfo(false);
              }
            });
          }
        }
        setContractInfo(resp[0]);
        setRefersInfo(resp[0].referencias || []);
        setProductsInfo(resp[0].productos);
      }
    });
  };

  const getProductStatus = (idP) => contractInfo.productoss.find((prod) => prod._id === idP)?.status || '-';

  const verifyProductStatus = () => {
    if (!contractInfo) {
      return;
    }
    const enableSale = contractInfo.productoss.every((product) => {
      if (product.status === 0) {
        return false;
      }
      return true;
    });
    setContractEditable(enableSale);
  };

  const refreshClient = () => {
    getContractInfo(true);
  };
  const eventShowMessage = (textM) => {
    setMessage(true);
    toast(textM, {
      className: 'message',
    });
  };
  const eventEditRef = (item) => {
    setOpenRef(true);
    setRefPos(item);
  };
  const eventCloseRef = () => {
    setOpenRef(false);
  };
  const eventCloseValid = () => {
    setOpenValidM(false);
  };

  const eventAddProducts = () => {
    setOpenAddPM(!openAddPM);
  };

  const eventEditContract = (option) => {
    setOpenFormContr(true);
    setContractForm(option);
  };
  const eventCloseForm = () => {
    setOpenFormContr(false);
  };

  const downloadFileF = async () => {
    const response = await downloadFileContrServ(id);
    const href = URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'contrato.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };
  const renderTooltip = (props) => (
    <Tooltip {...props}>Generar contrato</Tooltip>
  );
  const renderTooltipDw = (props) => (
    <Tooltip {...props}>Descargar contrato</Tooltip>
  );

  const calcBalance = (dataP) => {
    let balanceSum = 0;
    dataP.forEach((element) => {
      if (element.cantidad && element.precioInicial) {
        balanceSum += (element.cantidad * element.precioInicial);
      }
    });
    return balanceSum;
  };

  useEffect(() => {
    if (!contractInfo) {
      return;
    }
    verifyProductStatus();
  }, [contractInfo]);

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && id) {
      getContractInfo();
    } else if (!token) {
      closeSession();
    }
  }, [id]);
  return (
    <div>
      <div className="container filter-container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="row">
              <div className="col-12 col-md-5 margin-filter ">
                <Backbutton onClick={handleBackButtonClick} className="btn btn-primary mr-3 mt-0" />
                <br />
                <br />
                <div className="row align-items-center">
                  <div className="col-12">
                    <p className="text-title-info">
                      Contrato
                      {' '}
                      {contractInfo ? contractInfo.noContrato : ''}
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="text-email">
                      {contractInfo ? contractInfo.sucursal : ''}
                      {' '}
                      ∙
                      {' '}
                      <span>
                        {contractInfo ? monthDayYearFormat(contractInfo.fecha) : ''}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 margin-filter ">
                <div className="content-btns">
                  {contractInfo?.estatus_contrato.toLowerCase() === 'por validar' && checkPermisionRoute('Contratos', 'validarContrato') && (
                    <button className="button p-r" onClick={() => setOpenValidM(true)}>
                      &nbsp;&nbsp;Validar contrato&nbsp;&nbsp;
                    </button>
                  )}
                  {checkPermisionRoute('Contratos', 'descargarContratoValidado')
                    && contractInfo?.contratos?.url_pdf
                    && (
                      <OverlayTrigger placement="bottom" overlay={renderTooltipDw}>
                        <Link className="button-secondary p-r" to={contractInfo.contratos.url_pdf} download target="_blank" rel="documentoContrato">
                          <i className="btn-icon">&#59693;</i>
                        </Link>
                      </OverlayTrigger>
                    )}
                  {checkPermisionRoute('Contratos', 'descargarContrato')
                    && (
                      <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                        <button className="button-secondary p-r" onClick={() => downloadFileF()}>
                          <i className="btn-icon">&#59693;</i>
                        </button>
                      </OverlayTrigger>
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="text-name">Precio total</p>
                {contractInfo?.precioVenta && contractInfo?.precioVenta > 0
                  ? (
                    <>
                      {contractInfo?.precioVentaOriginal && contractInfo?.precioVentaOriginal !== contractInfo?.precioVenta && (
                        <>
                          <p className="text-title-info text-money text-dec-suby">
                            {styleMoney(preFormatMoney(contractInfo?.precioVentaOriginal))}
                          </p>
                          <p className="text-title-info text-money">
                            {styleMoney(preFormatMoney(contractInfo?.precioVenta))}
                          </p>
                        </>
                      )}
                      {contractInfo && contractInfo?.precioVentaOriginal === contractInfo?.precioVenta && (
                        <p className="text-title-info text-money">
                          {styleMoney(preFormatMoney(contractInfo?.precioVenta))}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {contractInfo?.medioDeVenta && contractInfo?.medioDeVenta === 'Carga masiva'
                        && (
                          <p className="text-title-info text-money">
                            {styleMoney(preFormatMoney(calcBalance(contractInfo.productos || [])))}
                          </p>
                        )}
                    </>
                  )}
                <p className="text-email">{contractInfo?.formaPago === 'contado' ? '(Pago unico)' : '(A mensualidades)'}</p>
              </div>
              <div className="col-12 col-md-6">
                <p className="text-name">Saldo</p>
                <p className="text-title-info text-money">{contractInfo ? styleMoney(preFormatMoney(contractInfo.saldo)) : '-'}</p>
                <p className="text-email text-money">
                  (
                  {contractInfo ? styleMoney(preFormatMoney(contractInfo.liquidado)) : '-'}
                  &nbsp;&nbsp;&nbsp;abonado)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container filter-container">
        <div className="row">
          <div className="col-12 col-md-12">
            <NavPage
              options={optionNav}
              activeOp={activeNav}
              event={eventChangeNav}
            />
          </div>
        </div>
      </div>
      {activeNav === 'Productos' && (
        <ProductsContract
          contractEditable={contractEditable}
          data={ProductsInfo}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
          contrato={contractInfo || null}
          eventAddProducts={eventAddProducts}
          getProductStatus={getProductStatus}
          permissions={checkPermisionRoute}
        />
      )}
      {activeNav === 'Detalles' && (
        <DetailsContractNav
          data={contractInfo}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
          permissions={checkPermisionRoute}
          eventOp={eventM}
          navigateFunct={eventEditContract}
        />
      )}
      {activeNav === 'Titular' && (
        <OwnerContractNav
          data={clientInfo}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
          permissions={checkPermisionRoute}
          navigateFunct={eventEditContract}
        />
      )}
      {activeNav === 'Beneficiarios' && (
        <BeneficiariesContract
          data={benefitInfo}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
          idC={id}
          contrato={contractInfo || null}
          permissions={checkPermisionRoute}
        />
      )}
      {activeNav === 'Referencias' && (
        <ReferencesContract
          data={refersInfo}
          eventShowMessage={eventShowMessage}
          eventEd={eventEditRef}
          idC={id}
          permissions={checkPermisionRoute}
          contrato={contractInfo || null}
        />
      )}
      {openRef && (
        <EditReference
          dataC={clientInfo}
          dataR={refersInfo}
          eventV={eventCloseRef}
          refers={refPos}
          eventShowMessage={eventShowMessage}
          idC={id}
        />
      )}
      {openRef && (
        <EditReference
          dataC={clientInfo}
          dataR={refersInfo}
          eventV={eventCloseRef}
          refers={refPos}
          eventShowMessage={eventShowMessage}
          idC={id}
        />
      )}
      {openValidM && (
        <ValidatedContract
          dataC={contractInfo}
          eventV={eventCloseValid}
          eventShowMessage={eventShowMessage}
          idC={id}
          refresh={refreshClient}
        />
      )}
      {openM && (
        <AddProspectM
          eventOp={eventM}
          statusOp={openM}
          refresh={refreshClient}
          eventShowMessage={eventShowMessage}
          idContract={id}
        />
      )}
      {openAddPM && (
        <AddProductsM
          eventOp={eventAddProducts}
          statusOp={openAddPM}
          dataC={contractInfo}
          refresh={refreshClient}
          eventShowMessage={eventShowMessage}
        />
      )}
      {openFormContr
        && (
        <EditContractView
          formulario={contractForm}
          eventV={eventCloseForm}
          contractInfo={contractInfo}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
        />
        )}
      {message && (
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      )}
    </div>
  );
};

export default DetailsContract;
