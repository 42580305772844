/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { getProducts } from '../../services';
import NavPage from '../../../navPage';
import ImageDef from '../../../products/imagedefault/imagedefault';
import proddef from '../../../../images/imageProDef.png';

const AddContractMProduct = ({
  eventOp, statusOp, setData, sucId
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [lProducts, setLProducts] = useState({ data: [], total: 0 });
  const [lProductsSelect, setLProductsSelect] = useState([]);
  const [selectedP, setSelectedP] = useState(null);
  const [typeP, setTypeP] = useState('inmediata');
  const [counter, setCounter] = useState(1);

  const optionNav = ['Uso Inmediato', 'Previsión'];
  const [activeNav, setActiveNav] = useState('Uso Inmediato');
  const eventChangeNav = (item) => {
    setActiveNav(item);
  };

  const searchProducts = async () => {
    let query = `sucursal=${sucId}&disponibilidad=${typeP}&paginacion=1&paginas=100`;
    await getProducts(query).then((resp) => {
      if (resp) {
        setLProducts(resp);
      } else {
        setLProducts([]);
      }
    });
  };

  const addProductL = () => {
    let tempData = selectedP;
    let findR = false;
    lProductsSelect.forEach((element) => {
      if (element._id === selectedP._id) {
        findR = true;
      }
    });
    if (!findR) {
      tempData.Cantidad = counter;
      tempData._Disponibilidad = typeP;
      tempData.precioInicial = typeP === 'inmediata' ? selectedP?.precio : selectedP?.precioPrevision;
      tempData.precioDescuento = typeP === 'inmediata'
        ? (selectedP?.descuentos?.usoInmediato?.descuentoUsoInmediato
          ? selectedP.descuentos.usoInmediato.descuentoUsoInmediato
          : selectedP?.precio)
        : (selectedP?.descuentos?.prevision?.descuentoPrevision
          ? selectedP.descuentos.prevision.descuentoPrevision
          : selectedP?.precioPrevision);
      const tempP = lProductsSelect;
      tempP.push(tempData);
      setData(tempP);
    } else {
      const tempP = lProductsSelect;
      tempP.forEach((element, ind) => {
        if (element._id === selectedP._id) {
          tempP[ind].Cantidad += counter;
        }
      });
      setData(tempP);
    }
    setCounter(1);
    setSelectedP(null);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };

  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);
  useEffect(() => {
    if (sucId) {
      searchProducts();
    }
  }, [sucId, typeP]);

  useEffect(() => {
    if (activeNav === 'Uso Inmediato') {
      setTypeP('inmediata');
    } else {
      setTypeP('prevision');
    }
  }, [activeNav]);

  return (
    <>
      <div className="container-fluid navBar-right">
        <div className="row">
          <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
          <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
            <div className="container-fluid">
              <div className="row">
                <div className="col close-form">
                  <span
                    className="icon-close cross-line"
                    onClick={eventOp}
                  >
                    {' '}
                  </span>
                </div>
                <div className="col-12">
                  <div className="col-12 spacing-nav"> </div>
                  <div className="container-fluid">
                    <div className="row content-nav-padd">
                      <div className="col-12">
                        <h2>Agregar producto</h2>
                        <br />
                        <small>{lProducts ? `${lProducts?.total} Resultados` : '0 Resultados'}</small>
                        <br />
                        <br />
                      </div>
                      <div className="col-12 p-r ">
                        <label className="label mt-m">
                          Plan
                        </label>
                        <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} classN="nav-ma" />
                      </div>
                      <div className="col-12 spacing-nav"> </div>
                      <div className="col-12 col-sm-12 col-md-12">
                        {
                          lProducts && lProducts?.data?.map((item) => (
                            <div className="row" key={item._id} onClick={() => setSelectedP(item)}>
                              <div className="row row-cursor">
                                <div className={selectedP && selectedP._id === item._id ? 'option-select' : 'option-tab'}>
                                  <div className="col-6 col-sm-6 col-md-6 img-prd-cent">
                                    <ImageDef
                                      name={item.nombre}
                                      size="lg"
                                      imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : proddef}
                                    />
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6">
                                    <label className="text-nameP">{item.nombre}</label>
                                    {
                                      activeNav === 'Uso Inmediato'
                                        ? (
                                          <>
                                            {
                                              !item?.descuentos?.usoInmediato?.descuentoUsoInmediato
                                                ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                                : (
                                                  <>
                                                    <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                                    <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.usoInmediato.descuentoUsoInmediato))}</label>
                                                  </>
                                                )
                                            }
                                          </>
                                        )
                                        : (
                                          <>
                                            {
                                              !item?.descuentos?.prevision?.descuentoPrevision
                                                ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                                : (
                                                  <>
                                                    <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                                    <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.prevision.descuentoPrevision))}</label>
                                                  </>
                                                )
                                            }
                                          </>
                                        )
                                    }
                                  </div>
                                </div>
                                <div className="line-spacing" />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className="col-12">
                        {selectedP && counter > 0 && (
                          <div className="container-prd-number">
                            <button
                              id="decrement"
                              className="button-number"
                              onClick={() => (counter > 1 ? setCounter(counter - 1) : setCounter(1))}
                              disabled={counter <= 0}
                            >
                              -
                            </button>
                            <span className="input-number">
                              {counter}
                            </span>
                            <button
                              id="increment"
                              className="button-number"
                              onClick={() => setCounter(counter + 1)}
                            >
                              +
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="col-12 p-r cont-btn">
                        <button className="button navR-content-btn" onClick={() => addProductL()} type="button" disabled={!selectedP ? 'disabled' : ''}>
                          Agregar
                        </button>
                      </div>
                      <div className="col-12 spacing-nav"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContractMProduct;
