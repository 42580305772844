import axios from '../../../store/axios';

export const getSucursales = async (query = '&paginacion=1&paginas=1000') => {
  try {
    const res = await axios().get(`sucursales/buscar?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getSucursalesByPage = async ({
  pagina, nombre, orden, total = 1000
}) => {
  try {
    const res = await axios().get('sucursales/buscar', {
      params: {
        paginacion: pagina,
        nombre: nombre || undefined,
        orden: orden || undefined,
        paginas: total || undefined,
      }
    });
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const registerSucursal = async (data) => {
  try {
    const res = await axios().post('sucursales', data);
    return res;
  } catch (err) {
    return err;
  }
};

export const findCP_S = async (query) => {
  try {
    const res = await axios().get(`/cp/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};
