import { useFormik } from 'formik';
import * as Yup from 'yup';

export const step1 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    idSucursal: ''
  },
  validationSchema: Yup.object({
    idSucursal: Yup.string()
  })

});

export const step2 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    productos: [
      {
        idProducto: ''
      },
    ]
  },
  validationSchema: Yup.object({
    productos: [
      {
        idProducto: Yup.string()
      }
    ]
  })

});

export const step3 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    fecha: '',
    noContrato: '',
    comentarios: '',
    precioVenta: 0,
    formaPago: '',
    diaPago: '',
    mensualidad: 0,
  },
  validationSchema: Yup.object({
    fecha: Yup.string(),
    noContrato: Yup.string(),
    comentarios: Yup.string(),
    precioVenta: Yup.string(),
    formaPago: Yup.string(),
    diaPago: Yup.string(),
    mensualidad: Yup.string(),
  })
});
