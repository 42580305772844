import { useFormik } from 'formik';
import * as Yup from 'yup';

export const useDiscountForm = ({
  nombre = '',
  porcentaje = '',
  fecha_inicio = '',
  fecha_expiracion = '',
  expira = true,
}) => useFormik({
  validateOnMount: true,
  enableReinitialize: true,
  initialValues: {
    nombre,
    porcentaje,
    fecha_inicio: fecha_inicio.split('T')[0],
    fecha_expiracion: fecha_expiracion.split('T')[0],
    expira,
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    porcentaje: Yup.number()
      .required('El valor del cupón es requerido.')
      .min(1, 'El porcentaje debe ser mayor a cero')
      .max(100, 'El porcentaje no puede ser mayor a 100'),
    fecha_inicio: Yup.date()
      .required('La fecha de inicio es requerida.'),
    fecha_expiracion: Yup.date()
      .min(Yup.ref('fecha_inicio'), 'La fecha de expiración debe ser mayor a la fecha de inicio.')
      .when('expira', {
        is: (exp) => exp,
        then: Yup.date()
          .required('La fecha de expiración es requerida'),
        otherwise: Yup.date(),
      }),
  })
});
