import '../../branchoffice.css';

const TabBranchOffice = ({ data }) => (
  <div className="row">
    <div className="col-12">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          <small className="text-title-tab">SUCURSAL</small>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <small className="text-title-tab">UBICACIÓN</small>
        </div>
      </div>
      {
        data && data.length > 0 && data.map((item) => (
          <div className="row" key={item._id}>
            <div className="col-12 col-sm-12 col-md-6">
              <label className="text-name">{item.nombre || '-'}</label>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <label className="text-ubisuc">
                <i>&#59679;</i>
                {`${item.ciudad || '-'}, ${item.estado || '-'}` || ''}
              </label>
            </div>
            <hr className="line-spacing" />
          </div>
        ))
      }
    </div>
  </div>
);
export default TabBranchOffice;
