import { useQuery } from 'react-query';
import { getSucursalesByPage } from '../services';

export const useSucursalsPerPage = ({
  page = 1,
  total = 1000,
  search,
  order,
  onSuccess,
  onError,
}) => useQuery(
  ['offices', 'all', {
    page, total, search, order
  }],
  () => {
    const nombre = search ? `${encodeURIComponent(search).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    return getSucursalesByPage({
      nombre,
      orden: order,
      pagina: page,
      total,
    });
  },
  {
    placeholderData: null,
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);
