/* eslint-disable max-len */
import { useState } from 'react';
import parse from 'html-react-parser';
import ImageDef from '../../../../products/imagedefault/imagedefault';
import proddef from '../../../../../images/imageProDef.png';

const TabProdContE = ({ dataP, saveP }) => {
  const [counter, setCounter] = useState(1);
  const [cont, setCont] = useState(false);
  const [selectedP, setSelectedP] = useState(null);

  const selectPros = (prod) => {
    setCont(true);
    setCounter(1);
    setSelectedP(prod);
  };

  const saveProduct = () => {
    let tempData = selectedP;
    tempData.Cantidad = counter;
    tempData._Disponibilidad = 'inmediata';
    saveP(tempData);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };

  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  return (
    <div className="row">
      <div className="col-12">
        <div className="list-prod-pros">
          {
            dataP && dataP.length > 0 && dataP.map((item) => (
              <div className="row" onClick={() => selectPros(item)} key={generateKey(item._id)}>
                <div className="row row-cursor">
                  <div className={selectedP && selectedP._id === item._id ? 'option-select' : 'option-tab'}>
                    <div className="col-4 col-md-4">
                      <ImageDef
                        name={item.nombre}
                        size="md"
                        imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : proddef}
                      />
                    </div>
                    <div className="col-8 col-md-8">
                      <label className="text-nameP">{item.nombre}</label>
                      <label className="text-type">
                        {
                          !item?.descuentos?.usoInmediato?.descuentoUsoInmediato
                            ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                            : (
                              <>
                                <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.usoInmediato.descuentoUsoInmediato))}</label>
                              </>
                            )
                        }
                      </label>
                    </div>
                  </div>
                  <div className="line-spacing" />
                </div>
              </div>
            ))
          }
        </div>
        <div className="row ">
          <div className="col-12">
            <div className="col-12 col-md-12">
              &nbsp;
            </div>
            {cont && counter > 0 && (
              <div className="container_number">
                <button
                  id="decrement"
                  className="button-number"
                  onClick={() => setCounter(counter - 1)}
                  disabled={counter <= 1}
                >
                  -
                </button>
                <span
                  className="input-number"
                >
                  {counter}
                </span>
                <button
                  id="increment"
                  className="button-number"
                  onClick={() => setCounter(counter + 1)}
                >
                  +
                </button>
              </div>
            )}
            {selectedP && (
              <div className="col-12 col-md-12">
                <button className="button mt-m btn-full-c" type="submit" onClick={saveProduct}>
                  Agregar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TabProdContE;
