import Accordion from 'react-bootstrap/Accordion';
import ProfileDefComponent from '../../../shared/profileDef';
import '../../client.css';
import { monthDayYearFormat } from '../../../../helpers/dates';
/*
const calcFecha = (fecha) => {
  try {
    const dateM = new Date(fecha);
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fec = fecha.split('-', 3);
    const dia = fec[2].split('', 2);
    // const date = `${dia[0]}${dia[1]}/${fec[1]}/${fec[0]}`;
    const date2 = `${monthNames[dateM.getMonth()]} ${dia[0]}${dia[1]}, ${fec[0]}`;
    return date2;
  } catch (_error) {
    return '-';
  }
};
*/

const DetailClientContract = ({ data, eventEdit }) => (
  <div className="container">
    <div className="row">
      <div className="col col-md-12 col-lg-2"> </div>
      <div className="col-12 col-md-12 col-lg-8 m-accordion">
        {data && (
          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Datos personales</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Nombre completo</label>
                    <label className="datos-info text-capitalize">
                      {data.nombre || ''}
                      {' '}
                      {data.apellidoPaterno || ''}
                      {' '}
                      {data.apellidoMaterno || ''}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Género</label>
                    <label className="datos-info text-capitalize">{data.sexo || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Edad</label>
                    <label className="datos-info">{data.edad || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Fecha de nacimiento</label>
                    <label className="datos-info">{monthDayYearFormat(data.fechaNacimiento) || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Estado civil</label>
                    <label className="datos-info text-capitalize">{data.estadoCivil || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Ocupación</label>
                    <label className="datos-info text-capitalize">{data.ocupacion || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Teléfono</label>
                    <label className="datos-info">{data.telefono || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Correo electrónico</label>
                    <label className="datos-info">{data.email || '-'}</label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="datos-title">RFC</label>
                    <label className="datos-info">{data.rfc || '-'}</label>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {data?.domicilio && data.domicilio.map((item, indx) => (
              <Accordion.Item eventKey={indx + 1} key={item._id}>
                <Accordion.Header>
                  Domicilio
                  {' '}
                  {indx > 0 ? 'adicional' : ''}
                </Accordion.Header>
                <button type="button" onClick={() => eventEdit(item, indx)} className="button-secondary btn-accordion">
                  &nbsp;&nbsp;Editar
                  <i className="btn-icon">&#59686;</i>
                  &nbsp;&nbsp;
                </button>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Ubicación</label>
                      <label className="datos-info">
                        {item.ciudad || ''}
                        {', '}
                        {item.estado || ''}
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Código postal</label>
                      <label className="datos-info">{item.cp || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Dirección</label>
                      <label className="datos-info">{item.direccion || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Colonia</label>
                      <label className="datos-info">{item.colonia || '-'}</label>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
        <div className="row">
          <div className="col-12">
            <br />
            <h2 className="text-title-accordion">Detalles del registro</h2>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12 ">
                    <label className="datos-title">Creado por</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 col-md-1 padd-top-img">
                    <ProfileDefComponent name={data?.creadoPor?.nombre ? data.creadoPor.nombre : ''} size="" imgProf={data?.creadoPor?.imagen_perfil || undefined} />
                  </div>
                  <div className="col-10 col-md-10">
                    <label className="datos-title pos-prospect">{(data?.creadoPor?.nombre && data?.creadoPor?.nombre !== '  ') ? data.creadoPor.nombre : '-'}</label>
                    <label className="datos-title pos-prospect">{(data?.creadoPor?.nombre && data?.creadoPor?.correo !== '  ') ? data.creadoPor.correo : '-'}</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12 ">
                    <label className="datos-title">Fecha de creaci&oacute;n</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="datos-title pos-prospect">{monthDayYearFormat(data?.createdAt) || '  '}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="notif-line-spacing light" />
        </div>
      </div>
      <div className="col col-md-12 col-lg-2"> </div>
    </div>
  </div>
);

export default DetailClientContract;
