import { useEffect, useState } from 'react';
import countriesData from '../../../../../global/data/countries.json';
import maritalStatus from '../../../../../global/data/maritalStatus.json';
import { findCP_S } from '../../../services';

const isFormValid = (errors) => !errors.nombre
  && !errors.apellidoPaterno
  && !errors.estado
  && !errors.ciudad
  && !errors.fechaNacimiento
  && !errors.telefono
  && !errors.email
  && !errors.cp
  && !errors.colonia
  && !errors.direccion
  && !errors.genero;

const DatosCliente = ({ cambiarOpcionDos, formik }) => {
  const {
    values,
    handleChange,
    errors,
    setFieldValue
  } = formik;
  const [localidades, setLocalidades] = useState([]);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    estado,
    ciudad,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    fechaNacimiento,
    estadoCivil,
    ocupacion,
    telefono,
    email,
    cp,
    colonia,
    direccion,
    genero,
    rfc,
    entreCalles
  } = values;
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      formik.setFieldValue('ciudad', '');
    }
  }, [estado]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isFormValid(errors)) {
      cambiarOpcionDos();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-xxl">
            <h2>Datos pesonales</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Nombre(s)
                    <input
                      onChange={handleChange('nombre')}
                      value={nombre}
                      id="nombre"
                      type="text"
                      className={`input ${submitted && errors.nombre && 'has-error'}`}
                    />
                    {submitted && errors.nombre && <small className="caption-error">{errors.nombre}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoPaterno" className="label mt-m">
                    Primer apellido
                    <input
                      id="apellidoPaterno"
                      type="text"
                      className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                      onChange={handleChange('apellidoPaterno')}
                      value={apellidoPaterno}
                    />
                    {
                      submitted && errors.apellidoPaterno && <small className="caption-error">{errors.apellidoPaterno}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoMaterno" className="label mt-m">
                    Segundo apellido
                    <input
                      id="apellidoMaterno"
                      type="text"
                      className="input"
                      onChange={handleChange('apellidoMaterno')}
                      value={apellidoMaterno}
                    />
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="rfc" className="label mt-m">
                    RFC
                    <input
                      id="rfc"
                      type="text"
                      className={`input ${submitted && errors.rfc && 'has-error'}`}
                      onChange={handleChange('rfc')}
                      value={rfc}
                    />
                    {
                      submitted && errors.rfc && <small className="caption-error">{errors.rfc}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fechaNacimiento" className="label mt-m">
                    Fecha de nacimiento
                    <input
                      id="fechaNacimiento"
                      type="date"
                      className={`input input-date ${submitted && errors.fechaNacimiento && 'has-error'}`}
                      onChange={handleChange('fechaNacimiento')}
                      value={fechaNacimiento}
                    />
                    {
                      submitted && errors.fechaNacimiento && <small className="caption-error">{errors.fechaNacimiento}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label className="label labels mt-m">
                    Género
                    <br />
                    <button type="button" onClick={() => setFieldValue('genero', 'masculino')} className={`btn-gender ${genero === 'masculino' ? 'active' : ''} genderM`}>Masculino</button>
                    <button type="button" onClick={() => setFieldValue('genero', 'femenino')} className={`btn-gender ${genero === 'femenino' ? 'active' : ''} genderF`}>Femenino</button>
                    {submitted && errors.genero && (
                      <small className="caption-error">
                        {errors.genero}
                      </small>
                    )}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="saestadestadoCivilCivilmple" className="label mt-m">
                    Estado civil
                    <select
                      type="estadoCivil"
                      value={estadoCivil}
                      onChange={handleChange('estadoCivil')}
                    >
                      <option>Seleccione una opción</option>
                      {maritalStatus.map((items) => (
                        <option key={items.value} name={items.value} value={items.value}>
                          {items.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="ocupacion" className="label mt-m">
                    Ocupación
                    <input
                      id="ocupacion"
                      type="text"
                      className="input"
                      value={ocupacion}
                      onChange={handleChange('ocupacion')}
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="telefono" className="label mt-m">
                    Teléfono
                    <input
                      id="telefono"
                      type="number"
                      placeholder="10 dígitos"
                      className={`input ${submitted && errors.telefono && 'has-error'}`}
                      onChange={handleChange('telefono')}
                      value={telefono}
                    />
                    {
                      submitted && errors.telefono && <small className="caption-error">{errors.telefono}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="email" className="label mt-m">
                    Correo electrónico
                    <input
                      id="email"
                      type="email"
                      className={`input ${submitted && errors.email && 'has-error'}`}
                      onChange={handleChange('email')}
                      value={email}
                    />
                    {
                      submitted && errors.email && <small className="caption-error">{errors.email}</small>
                    }
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="sample" className="label mt-m ">
                    Código postal
                    <input
                      id="sample"
                      type="number"
                      maxLength="5"
                      pattern="[0-9]{X,5}"
                      className={`input ${submitted && errors.cp && 'has-error'}`}
                      onChange={handleChange('cp')}
                      value={cp}
                    />
                    {
                      submitted && errors.cp && <small className="caption-error">{errors.cp}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="estado" className="label mt-m">
                    Estado
                    <select
                      type="select"
                      name="estado"
                      className={`${submitted && errors.estado && 'has-error'}`}
                      value={estado}
                      onChange={handleChange('estado')}
                    >
                      <option value="">Estado</option>
                      {countriesData.map(({ states }) => (
                        <option key={states} name={states} value={states}>
                          {states}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="ciudad" className="label mt-m">
                    Localidad o Municipio
                    <select
                      type="select"
                      name="ciudad"
                      className={`${submitted && errors.ciudad && 'has-error'}`}
                      value={ciudad}
                      onChange={handleChange('ciudad')}
                    >
                      <option value="">Localidad</option>
                      {localidades.map((loc) => (
                        <option key={loc} name={loc} value={loc}>
                          {loc}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.ciudad && <small className="caption-error">{errors.ciudad}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="colonia" className="label mt-m ">
                    Colonia
                    {!colonies && (
                      <input
                        id="colonia"
                        type="text"
                        className={`input ${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                      />
                    )}
                    {colonies && (
                      <select
                        type="select"
                        name="colonia"
                        className={`${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                      >
                        <option value="">-Seleccione la Colonia-</option>
                        {colonies.map((_colonia) => (
                          <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                            {_colonia}
                          </option>
                        ))}
                      </select>
                    )}
                    {
                      submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="entreCalles" className="label mt-m ">
                    Entre calles
                    <input
                      id="entreCalles"
                      type="text"
                      className={`input ${submitted && errors.entreCalles && 'has-error'}`}
                      value={entreCalles}
                      onChange={handleChange('entreCalles')}
                    />
                    {
                      submitted && errors.entreCalles && <small className="caption-error">{errors.entreCalles}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="direccion" className="label mt-m ">
                    Calle
                    <input
                      id="direccion"
                      type="text"
                      className={`input ${submitted && errors.direccion && 'has-error'}`}
                      value={direccion}
                      onChange={handleChange('direccion')}
                    />
                    {
                      submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                  <button className="button mt-m" type="submit">
                    Siguiente paso
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default DatosCliente;
