import { useEffect, useState } from 'react';
import Select from 'react-select';
import { StepP3 } from '../../formik/formik';
import { getSucursales } from '../../services';

const FrmSucursalesProduct = ({
  setDataForm, nextStep, prevStep, defVal
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [optionsS, setOptionsS] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    values,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = StepP3();

  const {
    sucursales
  } = values;

  const getSucursalesList = async () => {
    await getSucursales().then((data) => {
      const tempList = [];
      if (data && data.length && data.length > 0) {
        data.forEach((_element) => {
          tempList.push({ value: _element._id, label: _element.nombre });
        });
      }
      setOptionsS(tempList);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    // nextStep();
    if (isValid && dirty) {
      setDataForm(sucursales);
      nextStep();
    } else {
      setStatus('');
    }
  };

  useEffect(() => {
    if (defVal && optionsS) {
      setSelectedOption(defVal);
    }
  }, [defVal]);

  useEffect(() => {
    setFieldValue('sucursales', selectedOption);
    if (selectedOption && selectedOption.length > 0) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [selectedOption]);

  useEffect(() => {
    getSucursalesList();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="content-buttom">&nbsp;</div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <h2>Información general</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          {optionsS && optionsS.length > 0 && (
          <label htmlFor="estado" className="label mt-m">
            Sucursal(es)
            <Select
              isMulti
              options={optionsS}
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              className="basic-multi-select"
              classNamePrefix="selectOp"
              closeMenuOnSelect={false}
              placeholder="Selecciona una o más..."
            />
            {
              submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
            }
          </label>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <button className="input-label-float-right button-tertiary btn-step" onClick={() => prevStep()}>
            Regresar
          </button>
        </div>
        <div className="col-12 col-md-6">
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Siguiente paso
          </button>
        </div>
      </div>
    </form>
  );
};

export default FrmSucursalesProduct;
