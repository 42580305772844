/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import GMenuForm from '../menu';
// import Config from '../../global/constants';
import { getClientByID, getContractClient } from './services';
import NoContracts from './components/contracts/noContracts';
import DetailClientContract from './components/contracts/details';
import ViewDetComp from './components/detailsForm/detForm';
import ViewAddComp from './components/addProduct/addForm';
import ViewDetAddressComp from './components/detailsForm/detFormAddress';
import 'react-toastify/dist/ReactToastify.css';
import ProfileDefComponent from '../shared/profileDef';
import NavPage from '../navPage';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import Backbutton from '../shared/backbutton/backbutton';

import './client.css';
import ListContracts from './components/contracts/listContracts';

const ContractsClients = ({ idClient }) => {
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [foundC, setFoundC] = useState(true);
  const [NameC, setNameC] = useState('');
  const [openDet, setOpenDet] = useState(false);
  const [openDetAddss, setOpenDetAddss] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [addPos, setAddPos] = useState(null);
  const [detPos, setDetPos] = useState(null);
  const [detPosAddss, setDetPosAddss] = useState(null);
  const [message, setMessage] = useState(false);
  const [dataClient, setdataClient] = useState(null);

  const [optionNav, setOptionNav] = useState([]);
  const [activeNav, setActiveNav] = useState('Contratos');

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const handleBackButtonClick = () => {
    navigate('/clients');
  };

  const eventChangeNav = (item) => {
    setActiveNav(item);
  };
  // eslint-disable-next-line no-unused-vars
  const eventEditDet = (item) => {
    setOpenDet(true);
    setDetPos(item);
  };
  const eventAddProduct = (item) => {
    setOpenAdd(true);
    setAddPos(item);
  };
  const eventEditDetAddss = (item, indx) => {
    setOpenDetAddss(true);
    setDetPosAddss({ item, index: indx });
  };
  const eventCloseDet = () => {
    setOpenDet(false);
  };
  const eventCloseAdd = () => {
    setOpenAdd(false);
  };
  const eventCloseDetAddss = () => {
    setOpenDetAddss(false);
  };
  const refreshClient = () => {
    getClients();
  };
  const eventShowMessage = () => {
    setMessage(true);
    toast('Cambios guardados', {
      className: 'message'
    });
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };

  const getClients = async () => {
    await getClientByID(idClient).then((data) => {
      if (data && data[0]) {
        let nameUser = `${data[0].nombre || ''} ${data[0].apellidoPaterno || ''} ${data[0].apellidoMaterno || ''}`;
        setNameC(nameUser);

        if (data[0] && data[0].sexo && data[0].sexo === 'M') {
          data[0].sexo = 'Masculino';
        }
        if (data[0] && data[0].sexo && data[0].sexo === 'F') {
          data[0].sexo = 'Femenino';
        }
        if (data[0] && data[0].sexo) {
          const tempSx = data[0].sexo;
          const capitalized = tempSx.charAt(0).toUpperCase() + tempSx.slice(1);
          data[0].sexo = capitalized;
        }
        if (data[0] && data[0].fechaNacimiento) {
          const tempD = new Date(data[0].fechaNacimiento);
          data[0].fechaNacimiento = formatDate(tempD);
        }
        setClient(data[0]);
      } else {
        setFoundC(false);
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idClient) {
      getClients();
      let tempNav = [];
      if (checkPermisionRoute('Clientes', 'consultarCliente')) {
        tempNav.push('Contratos');
      }
      if (checkPermisionRoute('Clientes', 'consultarContrato')) {
        tempNav.push('Detalles');
      }
      setOptionNav(tempNav);
      setActiveNav(tempNav[0]);
    } else if (!token) {
      closeSession();
    }
  }, [idClient]);

  return (
    <div>
      <GMenuForm />
      <div className="container filter-container">
        <div className="row">
          <div className="col col-md-12 col-lg-2"> </div>
          <div className="col-12 col-md-12 col-lg-8">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-5 margin-filter ">
                {/* eslint-disable react/self-closing-comp */}
                <Backbutton onClick={handleBackButtonClick} className="btn btn-primary mr-3 mt-0" />
                <br></br>
                <br></br>
                <div className="row align-items-center">
                  <div className="col-3">
                    <ProfileDefComponent name={NameC} size="md" />
                  </div>
                  <div className="col-9">
                    {client && (
                      <h2 className="text-capitalize">{NameC}</h2>
                    )}
                    {!client && !foundC && (
                      <h2>No encontrado</h2>
                    )}
                  </div>
                </div>
              </div>
              {foundC && (
                <div className="col-12 col-md-12 col-lg-7 ">
                  <div className="content-btns">
                    {checkPermisionRoute('Clientes', 'crearContrato') && (
                      <button className="button p-r" onClick={() => eventAddProduct()}>
                        &nbsp;&nbsp;Nuevo contrato&nbsp;&nbsp;
                        <i className="btn-icon">&#59672;</i>
                      </button>
                    )}
                    {checkPermisionRoute('Clientes', 'editarCliente') && (
                      <button className="button-secondary p-r" onClick={() => eventEditDet(0)}>
                        &nbsp;&nbsp;Editar perfil&nbsp;&nbsp;
                        <i className="btn-icon">&#59686;</i>
                      </button>
                    )}
                    <button className="button-secondary p-r">
                      <i className="btn-icon">&#59683;</i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col col-md-12 col-lg-2"> </div>
        </div>
      </div>
      <div className="container filter-container">
        <div className="row">
          <div className="col col-md-12 col-lg-2"> </div>
          <div className="col-12 col-md-12 col-lg-8">
            <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} />
          </div>
          <div className="col col-md-12 col-lg-2"> </div>
        </div>
      </div>
      {activeNav === 'Contratos' && <ListContracts id={idClient} permision={checkPermisionRoute} />}
      {openAdd && foundC && <ViewAddComp eventCA={eventCloseAdd} Add={addPos} dataC={idClient} />}
      {activeNav === 'Detalles' && foundC && <DetailClientContract data={client} eventEdit={eventEditDetAddss} />}
      {openDet && foundC && (
        <ViewDetComp
          data={client}
          eventD={eventCloseDet}
          deta={detPos}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
          permision={checkPermisionRoute}
        />
      )}
      {openDetAddss && foundC && (
        <ViewDetAddressComp
          data={detPosAddss}
          dataC={client}
          eventD={eventCloseDetAddss}
          eventShowMessage={eventShowMessage}
          refresh={refreshClient}
          permision={checkPermisionRoute}
        />
      )}
      {message && (
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      )}
    </div>
  );
};

export default ContractsClients;
