/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import AddSeguimientoFrm from '../addSeguimiento/addSeguimientoFrm';
import EditObservationsForm from './editObservationsForm';

const EditSeguimientoForm = ({
  isEditEnabled,
  onClose,
  trackingItem,
  prospectId,
  refresh,
}) => {
  const [isEditingItem, setEditingItem] = useState(false);
  const [isEditingNotes, setEditingNotes] = useState(false);

  const formatDate = (isoDate) => {
    const tempD = new Date(isoDate).toLocaleDateString('es-Mx', {
      month: 'long', day: 'numeric', year: 'numeric',
    });
    return `${tempD}`;
  };

  const formatTime = (isoDate) => {
    const date = new Date(isoDate);
    let h = date.getUTCHours();
    const m = date.getUTCMinutes();
    const ampm = h >= 12 ? 'pm' : 'am';
    h = h === 12 ? 12 : h % 12;
    return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m} ${ampm}`;
  };

  const handleCloseEdit = () => {
    setEditingItem(false);
    setEditingNotes(false);
  };

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={onClose}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        {!isEditingItem && !isEditingNotes && (

          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
              <div className="content-buttom">&nbsp;</div>

              <div className="d-flex justify-content-between">
                <h2>Detalles del seguimiento</h2>
                {isEditEnabled && (
                  <button
                    className="button-secondary"
                    onClick={() => setEditingItem(true)}
                  >
                    Actualizar
                    <span className="button-icon pencil-line" />
                  </button>
                )}
              </div>
              <div className="row mt-m">
                <div className="col-12 col-md-6">
                  <label className="datos-title">Tipo de contacto</label>
                  <span className="datos-info">{trackingItem.tipo}</span>
                </div>

                <div className="col-12 col-md-6">
                  {!['Cita', 'Cotización enviada'].includes(trackingItem.tipo) && (
                    <>
                      <label className="datos-title">Teléfono</label>
                      <span className="datos-info">
                        {trackingItem.numero || '-'}
                      </span>
                    </>
                  )}
                </div>

                <div className="col-12 col-md-6">
                  <label className="datos-title">Fecha</label>
                  <span className="datos-info">
                    {trackingItem ? formatDate(trackingItem.fecha) : ''}
                  </span>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Hora</label>
                  <span className="datos-info">
                    {trackingItem ? formatTime(trackingItem.fecha) : ''}
                  </span>
                </div>
                {trackingItem.tipo === 'Cita' && (
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Lugar</label>
                    <span className="datos-info">
                      {trackingItem.lugar}
                    </span>
                  </div>
                )}
              </div>

              <hr />
              <div className="d-flex justify-content-between mt-xxl">
                <h2>Comentarios y anotaciones</h2>
                {isEditEnabled && (
                  <button
                    className="button-secondary"
                    onClick={() => setEditingNotes(true)}
                  >
                    Actualizar
                    <span className="button-icon pencil-line" />
                  </button>
                )}
              </div>
              <div className="row mt-m">
                <div className="col-12">
                  <label className="datos-title">Comentarios</label>
                  <span className="datos-info">{trackingItem.comentarios || '-'}</span>
                </div>
              </div>
              <hr />
            </div>
          </div>

        )}

        <div className="row justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6">
            {isEditingItem && (
              <AddSeguimientoFrm
                currentData={trackingItem}
                isEdit
                idP={prospectId}
                onClose={handleCloseEdit}
                refresh={refresh}
              />
            )}
            {isEditingNotes && (
              <EditObservationsForm
                currentData={trackingItem}
                onClose={handleCloseEdit}
                refresh={refresh}
                prospectId={prospectId}
              />
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default EditSeguimientoForm;
