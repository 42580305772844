import './loadingIndicator.css';

const LoadingIndicator = () => (
  <div className="loading-container">
    <div className="">
      <div className="col-12 cont-icon-resp default">
        <label className="icon-info-resp lg rotate">&#59675;</label>
      </div>
    </div>
  </div>
);

export default LoadingIndicator;
