/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ApplicationStep from './components/registerDiscount/ApplicationStep';
import PlansStep from './components/registerDiscount/PlansStep';
import SucursalCheckboxStep from './components/registerDiscount/SucursalCheckboxStep';
import SucursalSelectionStep from './components/registerDiscount/SucursalSelectionStep';
import ProductSelectionStep from './components/registerDiscount/ProductSelectionStep';
import ProductListScreen from './components/registerDiscount/ProductListScreen';
import RegisterDiscountModal from './components/registerDiscount/RegisterDiscountModal';
import {
  createDiscount, setProductDiscounts, updateDiscount, validateDiscount
} from './services';
import { useSucursalsPerPage } from '../branchOffice/hooks/queries';
import { useProductsPerPage } from '../products/hooks/queries';

const RegisterDiscountM = ({ currentData, onClose, eventOp }) => {
  const [step, setStep] = useState(1);
  const [discountType, setDiscountType] = useState('Todos');
  const [planType, setPlanType] = useState('Todos');
  const [showModal, setShowModal] = useState(false);
  const [selectedSucursals, setSelectedSucursals] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productSucursal, setProductSucursal] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();

  const { data: branchList } = useSucursalsPerPage({
    search: '',
    order: 'asc',
  });

  const { data: productsList } = useProductsPerPage({
    page: 1,
    perPage: 1000,
    enabled: !!currentData
  });

  const updateDiscountApplicability = async () => {
    try {
      const updatedData = {
        planes: discountType !== 'Productos' ? planType : undefined,
        allSucursales: discountType === 'Todos',
        sucursales: discountType === 'Sucursal' ? selectedSucursals.map((sucursal) => sucursal._id) : [],
        productos: discountType === 'Productos' ? selectedProducts.map((prod) => ({
          idProducto: prod._id,
          nombre: prod.nombre,
          idSucursal: prod.sucursal._id,
          sucursal: prod.sucursal.nombre,
          plan: prod.plan,
        })) : [],
      };

      const validation = await validateDiscount({
        ...updatedData,
        fecha_inicio: currentData.fecha_inicio,
        fecha_expiracion: currentData.fecha_expiracion,
        idDescuento: currentData._id,
      });

      if (validation.mensaje.includes('no es valido')) {
        setSubmitting(false);
        toast('No es posible actualizar el descuento, hay otras promociones programadas en las mismas fechas.', {
          className: 'message error',
        });
        return;
      }

      const res = await updateDiscount(currentData._id, updatedData);
      if (!res.errors) {
        queryClient.invalidateQueries(['discounts', 'all']);
        toast('Aplicables del descuento actualizados.', {
          className: 'message',
        });
        closeForm();
      } else {
        throw res.errors;
      }
    } catch (err) {
      setSubmitting(false);
      toast('No se pudieron actualizar los aplicables, intente de nuevo mas tarde.', {
        className: 'message error',
      });
    }
  };

  const createNewDiscount = async (formData) => {
    try {
      setSubmitting(true);
      const iniciaHoy = formData.fecha_inicio === new Date().toISOString().split('T')[0];

      const discountData = {
        ...formData,
        estatus: iniciaHoy ? 'Activo' : 'Programado',
        fecha_inicio: new Date(`${formData.fecha_inicio}T00:00`),
        fecha_expiracion: new Date(`${formData.fecha_expiracion}T00:00`),
        porcentaje: parseFloat(formData.porcentaje),
        planes: discountType !== 'Productos' ? planType : undefined,
        allSucursales: discountType === 'Todos',
        sucursales: discountType === 'Sucursal' ? selectedSucursals.map((sucursal) => sucursal._id) : undefined,
        productos: discountType === 'Productos' ? selectedProducts.map((prod) => ({
          idProducto: prod._id,
          nombre: prod.nombre,
          idSucursal: prod.sucursal._id,
          sucursal: prod.sucursal.nombre,
          plan: prod.plan,
        })) : undefined,
        idEmpleado: user._id,
      };

      const validation = await validateDiscount(discountData);
      if (validation.mensaje.includes('no es valido')) {
        setSubmitting(false);
        toast('No es posible registrar este descuento en las fechas seleccionadas.', {
          className: 'message error',
        });
        return;
      }

      const res = await createDiscount(discountData);

      if (!res.errors) {
        if (iniciaHoy) {
          await setProductDiscounts({ ...discountData, idDescuento: res._id });
        }
        queryClient.invalidateQueries(['discounts', 'all']);
        toast('Descuento creado.', {
          className: 'message',
        });
        setTimeout(() => {
          closeForm();
        }, 3000);
      } else {
        throw res.errors;
      }
    } catch (err) {
      setSubmitting(false);
      toast('No se pudo crear el descuento, intente de nuevo mas tarde.', {
        className: 'message error',
      });
    }
    // return;
  };

  const openDiscountModal = () => {
    setShowModal(true);
  };

  const closeForm = () => {
    setStep(1);
    setShowModal(false);
    setSubmitting(false);
    setDiscountType('Todos');
    setPlanType('Todos');
    setSelectedSucursals([]);
    setSelectedProducts([]);
    onClose();
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const addProducts = (newProducts) => {
    setSelectedProducts([...selectedProducts, ...newProducts]);
  };

  const setInitialData = () => {
    /*
     Función para inicializar la información con base en los aplicables actuales,
     para que el usuario pueda actualizarlos después
    */
    const itemType = currentData.allSucursales ? 'Todos'
      : currentData.sucursales && currentData.sucursales.length > 0 ? 'Sucursal' : 'Productos';
    setDiscountType(itemType);
    setPlanType(currentData.planes || '');

    if (itemType === 'Productos') {
      const productsData = currentData.productos.map((prod) => {
        const prodData = productsList.data.find((p) => p._id === prod.idProducto);
        return {
          ...prod,
          _id: prod.idProducto,
          idLista: `${prod._id}-${prod.plan}`,
          precio: prodData.precio,
          precioPrevision: prodData.precioPrevision
            ? parseFloat(prodData.precioPrevision) : prodData.precio,
          sucursal: prodData.sucursal,
        };
      });
      setSelectedProducts(productsData);
    } else if (itemType === 'Sucursal') {
      const branchesData = currentData.sucursales.map((sucId) => {
        const sucursal = branchList.find((branch) => branch._id === sucId);
        return sucursal;
      });
      setSelectedSucursals(branchesData);
    }
  };

  useEffect(() => {
    if (!currentData || !productsList.data) {
      return;
    }
    setInitialData();
  }, [currentData, productsList]);

  return (
    <>
      {showModal && (
        <RegisterDiscountModal
          isSubmitting={isSubmitting}
          onSubmit={createNewDiscount}
          onClose={() => setShowModal(false)}
        />
      )}
      {eventOp && (
        <div className="absolute-content trasp">
          {step === 1
            && (
              <ApplicationStep
                statusOp={eventOp}
                onClose={closeForm}
                nextStep={nextStep}
                discountType={discountType}
                setDiscountType={setDiscountType}
              />
            )}
          {step === 2
            && (
              <>
                {discountType === 'Todos' && (
                  <PlansStep
                    isUpdate={currentData && currentData._id}
                    handleUpdate={updateDiscountApplicability}
                    statusOp={eventOp}
                    onClose={closeForm}
                    nextStep={openDiscountModal}
                    prevStep={prevStep}
                    planType={planType}
                    setPlanType={setPlanType}
                  />
                )}
                {discountType === 'Sucursal' && (
                  <SucursalCheckboxStep
                    branchList={branchList}
                    statusOp={eventOp}
                    onClose={closeForm}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    selectedSucursals={selectedSucursals}
                    setSelectedSucursals={setSelectedSucursals}
                  />
                )}
                {discountType === 'Productos' && (
                  <ProductListScreen
                    isUpdate={currentData && currentData._id}
                    statusOp={eventOp}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onClose={closeForm}
                    products={selectedProducts}
                    setProducts={setSelectedProducts}
                    openDiscountModal={openDiscountModal}
                    handleUpdate={updateDiscountApplicability}
                  />
                )}
              </>
            )}
          {step === 3
            && (
              <>
                {discountType === 'Sucursal'
                  && (
                    <PlansStep
                      isUpdate={currentData && currentData._id}
                      handleUpdate={updateDiscountApplicability}
                      statusOp={eventOp}
                      onClose={closeForm}
                      nextStep={openDiscountModal}
                      prevStep={prevStep}
                      planType={planType}
                      setPlanType={setPlanType}
                    />
                  )}
                {discountType === 'Productos'
                  && (
                    <SucursalSelectionStep
                      statusOp={eventOp}
                      onClose={closeForm}
                      nextStep={nextStep}
                      prevStep={prevStep}
                      planType={planType}
                      setPlanType={setPlanType}
                      setData={(data) => setProductSucursal(data)}
                    />
                  )}
              </>
            )}
          {step === 4 && (
            <ProductSelectionStep
              statusOp={eventOp}
              onClose={closeForm}
              nextStep={() => setStep(2)}
              prevStep={prevStep}
              planType={planType}
              setPlanType={setPlanType}
              sucursal={productSucursal}
              addProducts={addProducts}
              selectedProducts={selectedProducts}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RegisterDiscountM;
