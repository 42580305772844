import { useParams } from 'react-router-dom';
import ContractsClients from '../../features/clients/contracts-clients';

const ContractsClientsPage = () => {
  const { id } = useParams();
  return (
    <>
      <ContractsClients idClient={id} />
    </>
  );
};

export default ContractsClientsPage;
