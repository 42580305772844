import React from 'react';
import { monthDayYearFormat } from '../../../../helpers/dates';
import { formatMoney, styleMoney } from '../../../../helpers/currency';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';

const CouponsTable = ({ data, onRowClick }) => {
  const setTag = (status, expires, startDate = '', expirationDate = '') => {
    switch (status) {
      case 'Activo':
        return (
          <>
            <div className="tag success">
              <span className="tag-text">Activo</span>
            </div>
            {expires ? (
              <small>
                Expira en&nbsp;
                <span>
                  {monthDayYearFormat(expirationDate)}
                </span>
              </small>
            ) : <small>No expira</small>}
          </>
        );

      case 'Vencido':
        return (
          <>
            <div className="tag expired">
              <span className="tag-text">Vencido</span>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="tag primary">
              <span className="tag-text">Programado</span>
            </div>
            <small>
              Comienza en&nbsp;
              <span>
                {monthDayYearFormat(startDate)}
              </span>
            </small>
          </>
        );
    }
  };

  const allowViewDetails = checkPermisionRoute('Descuentos', 'consultarDescuento');

  const handleClick = (item) => {
    if (allowViewDetails) {
      onRowClick(item);
    }
  };

  return (
    <div className="m-t-l">
      <div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <small className="text-title-tab">CÓDIGO</small>
              </div>
              <div className="col-6 col-lg-2">
                <small className="text-title-tab">USOS</small>
              </div>
              <div className="col-12 col-lg-3">
                <small className="text-title-tab">ESTATUS</small>
              </div>
            </div>
          </div>
        </div>
        {data.map((coupon) => (
          <div className="row" key={coupon._id}>
            <div className="col-12">
              <div className={`row ${allowViewDetails ? 'row-cursor' : ''}`} onClick={() => handleClick(coupon)}>
                <div className="col-6 column-centered">
                  <div>
                    <p className="text-bold mb-1">{coupon.codigo}</p>
                    <span className="text-secondary text-money">
                      {styleMoney(formatMoney.format(coupon.descuento))}
                    </span>
                  </div>
                </div>
                <div className="col-6 col-lg-2 column-centered">
                  <p>
                    {coupon.vecesUtilizado || 0}
                  </p>
                </div>
                <div className="col-12 col-lg-3">
                  <div>
                    {
                      setTag(
                        coupon.estatus,
                        coupon.expira,
                        coupon.fecha_inicio,
                        coupon.fecha_expiracion
                      )
                    }
                  </div>
                </div>
              </div>
              <hr className="line-spacing" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CouponsTable;
