/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { FormikAddress } from '../../formik/formdet';
import countriesData from '../../../../global/data/countries.json';
import maritalStatus from '../../../../global/data/maritalStatus.json';
import { updateEmployee, findCP_S } from '../../services';
import '../../client.css';

const ViewDetAddressComp = (
  {
    eventD,
    data,
    deta,
    eventShowMessage,
    refresh,
    dataC
  }
) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [statusApi, setStatusApi] = useState(null);
  const [localidades, setLocalidades] = useState([]);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = FormikAddress();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (isValid && dirty) {
      setStatus('loading');
      let tempAddss = dataC.domicilio;
      const json = tempAddss;
      const key = '_id';
      json.forEach((_itm, inx) => {
        delete json[inx][key];
      });
      tempAddss = json;
      const tempDeta = {
        direccion: values.direccion,
        colonia: values.colonia,
        ciudad: values.ciudad,
        estado: values.estado,
        cp: values.cp,
      };
      tempAddss[data.index] = tempDeta;
      setStatus('idle');

      await updateEmployee(dataC._id, { domicilio: tempAddss }).then((resp) => {
        if (resp) {
          eventD(false);
          refresh();
          eventShowMessage();
        } else {
          setStatusApi('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
      // const res = await requestPasswordChange(email);
    }
  };

  useEffect(() => {
    if (!isValid || !dirty) {
      setStatus('idle');
    } else {
      setStatus('');
      setStatusApi(null);
    }
  }, [values, isValid, dirty, errors]);

  const {
    direccion,
    colonia,
    ciudad,
    estado,
    cp,
  } = values;

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  useEffect(() => {
    const tempData = data?.item;
    if (tempData && tempData.cp) {
      setFieldValue('direccion', tempData.direccion);
      setFieldValue('colonia', tempData.colonia);
      setFieldValue('ciudad', tempData.ciudad);
      setFieldValue('estado', tempData.estado);
      setFieldValue('cp', tempData.cp);
    }
  }, [data]);

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((_data) => {
      if (_data && _data.respuesta && _data.respuesta.codigos_postales) {
        if (_data.respuesta.codigos_postales.length === 1) {
          _data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (_data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          _data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (cp && cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp && cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={() => eventD(false)}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>{deta === 0 ? 'Datos personales' : ''}</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="">
                <div>
                  <>
                    <div className="row">
                      <div className="col-12">&nbsp;</div>
                      <div className="col-12">
                        <h2>Domicilio</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="cp" className="label mt-m">
                          Código postal
                          <input
                            id="cp"
                            type="number"
                            maxLength="5"
                            pattern="[0-9]{X,5}"
                            className={`input ${submitted && errors.cp && 'has-error'}`}
                            value={cp}
                            onChange={handleChange('cp')}
                          />
                          {
                            submitted && errors.cp && <small className="caption-error">{errors.cp}</small>
                          }
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
                      {statusApi && <small className="caption-error">{statusApi}</small>}
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="estado" className="label mt-m">
                          Estado
                          <select
                            type="select"
                            name="estado"
                            className={`${submitted && errors.estado && 'has-error'}`}
                            value={estado}
                            onChange={handleChange('estado')}
                          >
                            <option value="">Estado</option>
                            {countriesData.map(({ states }) => (
                              <option key={generateKey(states)} name={states} value={states}>
                                {states}
                              </option>
                            ))}
                          </select>
                          {submitted && errors[0] && errors[0].estado && <small className="caption-error">{errors[0].estado}</small>}
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="ciudad" className="label mt-m">
                          Localidad o Municipio
                          <select
                            type="select"
                            name="ciudad"
                            className={`${submitted && errors.ciudad && 'has-error'}`}
                            value={ciudad}
                            onChange={handleChange('ciudad')}
                          >
                            <option value="">Localidad</option>
                            {localidades.map((loc) => (
                              <option key={generateKey(loc)} name={loc} value={loc}>
                                {loc}
                              </option>
                            ))}
                          </select>
                          {submitted && errors[0] && errors[0].ciudad && <small className="caption-error">{errors[0].ciudad}</small>}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="colonia" className="label mt-m">
                          Colonia
                          {!colonies && (
                            <input
                              id="colonia"
                              type="text"
                              className={`input ${submitted && errors.colonia && 'has-error'}`}
                              value={colonia}
                              onChange={handleChange('colonia')}
                            />
                          )}
                          {colonies && (
                            <select
                              type="select"
                              name="colonia"
                              className={`${submitted && errors.colonia && 'has-error'}`}
                              value={colonia}
                              onChange={handleChange('colonia')}
                            >
                              <option value="">-Seleccione la Colonia-</option>
                              {colonies.map((_colonia) => (
                                <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                                  {_colonia}
                                </option>
                              ))}
                            </select>
                          )}
                          {
                            submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                          }
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="direccion" className="label mt-m">
                          Dirección
                          <input
                            id="direccion"
                            type="text"
                            className={`input ${submitted && errors.direccion && 'has-error'}`}
                            value={direccion}
                            onChange={handleChange('direccion')}
                          />
                          {
                            submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                          }
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
                    </div>
                  </>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="col-12 col-md-12">
                      &nbsp;
                    </div>
                    <div className="col-12 col-md-12">
                      <button type="button" onClick={() => eventD(false)} className="button-secondary p-r m-btn-prof">
                        Cancelar
                      </button>
                      <button className="button mt-m" type="submit" disabled={status === 'idle' ? 'disabled' : ''}>
                        Guardar cambios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default ViewDetAddressComp;
