/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import parse from 'html-react-parser';
import prodef from '../../../../images/imageProDef.png';
import ImageDef from '../../imagedefault/imagedefault';
import '../../products.css';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';
import OptionsMenu from '../../../shared/optionsMenu/OptionsMenu';

const TabProducts = ({ data, onSelect, onChangeStatus }) => {
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };
  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  const allowViewDetails = checkPermisionRoute('Productos', 'consultarProducto');
  const allowChangeStatus = checkPermisionRoute('Productos', 'cambiarEstatusProducto');

  const handleClick = (item) => {
    if (allowViewDetails) {
      onSelect(item);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-5">
            <small className="text-title-tab">PRODUCTO</small>
          </div>
          <div className="col-12 col-md-4 col-lg-2">
            <small className="text-title-tab">DE VENTA EN</small>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-2">
            <small className="text-title-tab">
              PRECIO
              <br />
              (Uso Inmediato)
            </small>
          </div>
          <div className="col-6 col-sm-6 col-md-2 col-lg-2">
            <small className="text-title-tab">
              PRECIO
              <br />
              (Previsión)
            </small>
          </div>
        </div>
        {
          data && data.length > 0 && data.map((item) => (
            <div className="row row-hover" key={item._id}>
              <div className="col-3 col-md-2 col-lg-2">
                <ImageDef
                  name={item.nombre}
                  size="md"
                  imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : prodef}
                />
              </div>
              <div className="col-9 col-md-4 col-lg-3">
                <label className="text-nameP">{item.nombre}</label>
                <label className="text-type text-capitalize">{item.tipo || parse('&nbsp;')}</label>
                {item.status === 0 && (
                  <span className="text-regular caption-error">
                    <i className="text-icon me-1">&#59668;</i>
                    Producto no disponible
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-md-2 col-lg-2">
                <label className="text-suc">
                  <i>&#59679;</i>
                  {item.sucursal.nombre}
                </label>
                <label className="text-stock">{`(${item.stock || 0} disponibles)` || parse('&nbsp;')}</label>
              </div>
              <div className="col-6 col-sm-8 col-md-2 col-lg-2">
                {item?.descuentos?.usoInmediato?.descuentoUsoInmediato ? (
                  <>
                    <label className="text-precio suby text-money">{styleMoney(preFormatMoney(item?.precio))}</label>
                    <label className="text-precio  text-money">
                      {styleMoney(preFormatMoney(item?.descuentos?.usoInmediato?.descuentoUsoInmediato))}
                    </label>
                  </>
                ) : (
                  <label className="text-precio text-money">{styleMoney(preFormatMoney(item?.precio))}</label>
                )}
              </div>
              <div className="col-6 col-sm-6 col-md-2 col-lg-2">
                {item?.descuentos?.prevision?.descuentoPrevision ? (
                  <>
                    <label className="text-precio suby text-money">{styleMoney(preFormatMoney(item?.precioPrevision))}</label>
                    <label className="text-precio  text-money">
                      {styleMoney(preFormatMoney(item?.descuentos?.prevision?.descuentoPrevision))}
                    </label>
                  </>
                ) : (
                  <label className="text-precio text-money">{styleMoney(preFormatMoney(item?.precioPrevision))}</label>
                )}
              </div>
              <div className="col-3 col-lg-1">
                {(allowViewDetails || allowChangeStatus) && (
                  <div className="hidden-col">
                    <OptionsMenu>
                      {allowViewDetails && (
                        <OptionsMenu.Item
                          icon="&#59698;"
                          text="Ver detalles"
                          onClick={() => handleClick(item)}
                        />
                      )}
                      {allowChangeStatus && item.status === 1 && (
                        <OptionsMenu.Item
                          icon="&#59669;"
                          text="Desactivar"
                          onClick={() => onChangeStatus(item, 0)}
                          theme="danger"
                        />
                      )}
                      {allowChangeStatus && item.status === 0 && (
                        <OptionsMenu.Item
                          icon="&#59670;"
                          text="Activar"
                          onClick={() => onChangeStatus(item, 1)}
                          theme="success"
                        />
                      )}
                    </OptionsMenu>
                  </div>
                )}
              </div>
              <hr className="line-spacing" />
            </div>
          ))
        }
      </div>
    </div>
  );
};
export default TabProducts;
