const NotFoundBranches = () => (
  <div className="container content-center content-info">
    <div className="row">
      <div className="col-12 cont-icon-info">
        <label className="icon-info-product">&#59673;</label>
      </div>
      <div className="col-12">
        <label className="text-title-info">Sin resultados</label>
      </div>
      <div className="col col-md-4">&nbsp;</div>
      <div className="col-12 col-md-4">
        <label className="text-info">Lo sentimos, parece que no hemos podido encontrar ningún resultado para tu búsqueda.</label>
      </div>
      <div className="col col-md-4">&nbsp;</div>
    </div>
  </div>
);

export default NotFoundBranches;
