/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import FormStept1 from './formsClient/formStept1';
import FormStept1p5 from './formsClient/formStep1p5';
import FormStept2 from './formsClient/formStept2';
import FormStept3 from './formsClient/formStept3';
import FormStept4 from './formsClient/formStept4';
import '../../prospects.css';

const FormCreateClientComp = ({
  eventOp, statusOp, setData, dataSale
}) => {
  const [dataForm1, setDataForm1] = useState(null);
  const [dataForm1_5, setDataForm1_5] = useState(null);
  const [dataForm2, setDataForm2] = useState(null);
  const [dataForm3, setDataForm3] = useState(null);
  const [dataForm4, setDataForm4] = useState(null);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('uno');
  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep1_5, setStateStep1_5] = useState('disabled');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [stateStep4, setStateStep4] = useState('disabled');
  const [step1, setStep1] = useState('&#59666;');
  const [step1_5, setStep1_5] = useState('&#59652;');
  const [step2, setStep2] = useState('&#59652;');
  const [step3, setStep3] = useState('&#59652;');
  const [step4, setStep4] = useState('&#59652;');

  const saveForm1 = (dataF) => {
    setDataForm1(dataF);
  };
  const saveForm1_5 = (dataF) => {
    setDataForm1_5(dataF);
  };
  const saveForm2 = (dataF) => {
    setDataForm2(dataF);
  };
  const saveForm3 = (dataF) => {
    setDataForm3(dataF);
  };
  const saveForm4 = (dataF) => {
    setDataForm4(dataF);
  };
  const cambiarOpcionUno = () => {
    setOpcionSeleccionada('uno');
    setStateStep1('active');
    setStateStep1_5('disabled');
    setStateStep2('disabled');
    setStateStep3('disabled');
    setStateStep4('disabled');
    setStep1('&#59666;');
    setStep1_5('&#59666;');
    setStep2('&#59666;');
    setStep3('&#59666;');
    setStep4('&#59666;');
    window.scrollTo(0, 0);
  };

  const cambiarOpcionUnoPCinco = () => {
    setOpcionSeleccionada('unoPCinco');
    setStateStep1('complete');
    setStateStep1_5('active');
    setStateStep2('disabled');
    setStateStep3('disabled');
    setStateStep4('disabled');
    setStep1('&#59654;');
    setStep1_5('&#59666;');
    setStep2('&#59666;');
    setStep3('&#59666;');
    setStep4('&#59666;');
    window.scrollTo(0, 0);
  };

  const cambiarOpcionDos = () => {
    setOpcionSeleccionada('dos');
    setStateStep1('complete');
    setStateStep1_5('complete');
    setStateStep2('active');
    setStateStep3('disabled');
    setStateStep4('disabled');
    setStep1('&#59654;');
    setStep1_5('&#59654;');
    setStep2('&#59666;');
    setStep3('&#59666;');
    setStep4('&#59666;');
    window.scrollTo(0, 0);
  };
  const cambiarOpcionTres = () => {
    setOpcionSeleccionada('tres');
    setStateStep1('complete');
    setStateStep1_5('complete');
    setStateStep2('complete');
    setStateStep3('active');
    setStateStep4('disabled');
    setStep1('&#59654;');
    setStep1_5('&#59654;');
    setStep2('&#59654;');
    setStep3('&#59666;');
    setStep4('&#59666;');
    window.scrollTo(0, 0);
  };
  const cambiarOpcionCuatro = () => {
    setOpcionSeleccionada('cuatro');
    setStateStep1('complete');
    setStateStep1_5('complete');
    setStateStep2('complete');
    setStateStep3('complete');
    setStateStep4('active');
    setStep1('&#59654;');
    setStep1_5('&#59654;');
    setStep2('&#59654;');
    setStep3('&#59654;');
    setStep4('&#59666;');
    window.scrollTo(0, 0);
  };

  const finalStep = (dataT) => {
    let tempFinalData = {};
    tempFinalData.client = dataForm1;
    tempFinalData.files = dataForm1_5;
    tempFinalData.prospects = dataForm2;
    tempFinalData.contract = dataForm3;
    tempFinalData.beneficiarios = dataT;
    setData(tempFinalData);
  };
  useEffect(() => {

  }, []);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={() => eventOp(false)}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="col-12 col-md-10 m-a">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
          <div className="col-12 col-sm-12 col-md-10 col-lg-6">
            <div className="row justify-content-center align-items-center">
              <div className="col-6 col-md-2">
                <i className="step-prosp-icon">{parse(step1)}</i>
                <label className="step-prosp small-bold">Cliente</label>
              </div>
              <div className={`col-6 col-md-3 ${stateStep1_5}`}>
                <i className="step-prosp-icon">{parse(step1_5)}</i>
                <label className="step-prosp small-bold">Documentos</label>
              </div>
              <div className={`col-6 col-md-2 ${stateStep2}`}>
                <i className="step-prosp-icon">{parse(step2)}</i>
                <label className="step-prosp small-bold">Referencias</label>
              </div>
              <div className={`col-6 col-md-2 ${stateStep3}`}>
                <i className="step-prosp-icon">{parse(step3)}</i>
                <label className="step-prosp small-bold">Contrato</label>
              </div>
              <div className={`col-6 col-md-2 ${stateStep4}`}>
                <i className="step-prosp-icon">{parse(step4)}</i>
                <label className="step-prosp small-bold">Beneficiarios</label>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
          <div className="col-12 col-sm-12 col-md-10 col-lg-6">
            {opcionSeleccionada === 'uno' ? (
              <FormStept1 cambiarOpcion={cambiarOpcionUnoPCinco} saveData={saveForm1} opcionAnterior={eventOp} dataP={dataSale} defDataP={dataForm1} />
            ) : opcionSeleccionada === 'unoPCinco' ? (
              <FormStept1p5 cambiarOpcion={cambiarOpcionDos} saveData={saveForm1_5} opcionAnterior={cambiarOpcionUno} dataP={dataSale} defDataP={dataForm1_5} />
            ) : opcionSeleccionada === 'dos' ? (
              <FormStept2 cambiarOpcion={cambiarOpcionTres} saveData={saveForm2} opcionAnterior={cambiarOpcionUnoPCinco} dataP={dataForm1} disabledF />
            ) : opcionSeleccionada === 'tres' ? (
              <FormStept3 cambiarOpcion={cambiarOpcionCuatro} saveData={saveForm3} opcionAnterior={cambiarOpcionDos} productosL={dataSale.productos} dataSaleT={dataSale.totalFF} />
            ) : opcionSeleccionada === 'cuatro' ? (
              <FormStept4 cambiarOpcion={finalStep} saveData={saveForm4} opcionAnterior={cambiarOpcionTres} dataP={dataSale} />
            ) : (
              <> </>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default FormCreateClientComp;
