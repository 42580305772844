import React from 'react';
import './backbutton.css';
import '../../../global/styles/styles.css';

const Backbutton = ({ onClick }) => (
  <button className="regresar-btn" onClick={onClick}>
    {' '}
    Regresar
  </button>
);

export default Backbutton;
