/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotAllow from './notAllow';
import permissions from './permisions.json';

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  const checkPermisionRoute = () => {
    let checkedPerm = false;
    permissions.forEach((element) => {
      if (element.rol === user.role && user.puesto) {
        if (element.permissions.includes(location.pathname)) {
          checkedPerm = true;
        }
        const tempUrl = location.pathname.split('/');
        if (element.permissions.includes(`/${tempUrl[1]}/`)
          || element.permissions.includes(`/${tempUrl[1]}`)) {
          checkedPerm = true;
        }
        if (user.puesto && element.puestos) {
          element.puestos.forEach((item) => {
            if (item.puesto === user.puesto) {
              checkedPerm = true;
              if (item.deny && (item.deny.includes(`/${tempUrl[1]}/`) || item.deny.includes(`/${tempUrl[1]}`))) {
                checkedPerm = false;
              }
            }
          });
        } else {
          checkedPerm = false;
        }
      }
    });
    return checkedPerm;
  };

  return (
    <>
      {!user._id && <Navigate to="/login" />}
      {user._id && checkPermisionRoute() && children}
      {user._id && !checkPermisionRoute() && <NotAllow />}
    </>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
