import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    email: '',
    password: '',
  },
  validationSchema: Yup.object({
    email: Yup.string()
      .email('La dirección de correo no es válida')
      .required('El correo electrónico es requerido'),
    password: Yup.string()
      .required('La contraseña es requerida')
  }),
});
