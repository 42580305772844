/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import CurrencyInput from 'react-currency-input-field';
import { StepP5 } from '../../formik/formik';

const FrmPriceProduct = ({
  setDataForm, nextStep, prevStep, defVal, arraySucursales, planesPorSucursal
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [switchC, setSwitchC] = useState(false);

  const {
    values,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = StepP5();

  const {
    sucursales
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    // nextStep();
    if (isValid && dirty) {
      setDataForm(values);
      nextStep(values);
    } else {
      setStatus('');
    }
  };

  const prevStepF = () => {
    if (isValid && dirty) {
      setDataForm(values);
    }
    prevStep();
  };

  const checkChanges = (_field, _value, index) => {
    if (switchC) {
      arraySucursales.forEach((_element, ind) => {
        if ((index !== ind)) {
          setFieldValue(`sucursales[${ind}].${_field}`, _value);
        }
      });
    }
  };

  const _handleChange = (_ev, ind, _field) => {
    if (_ev) {
      setFieldValue(`sucursales[${ind}].${_field}`, _ev);
    } else {
      setFieldValue(`sucursales[${ind}].${_field}`, '0');
    }
  };

  const checkApplicability = (sucursalId, plan) => {
    const { planes } = planesPorSucursal.find((obj) => obj.sucursal === sucursalId);
    return planes.includes(plan);
  };

  useEffect(() => {
    if (defVal) {
      setFieldValue('sucursales', defVal.sucursales);
    }
  }, [defVal]);

  useEffect(() => {
    if (switchC && sucursales) {
      if (sucursales[0] && sucursales[0].precio_normal !== '') {
        arraySucursales.forEach((_element, ind) => {
          if (ind !== 0) {
            setFieldValue(`sucursales[${ind}].precio_normal`, sucursales[0].precio_normal);
          }
        });
      }
      if (sucursales[0] && sucursales[0].precio_prevision !== '') {
        arraySucursales.forEach((_element, ind) => {
          if (ind !== 0) {
            setFieldValue(`sucursales[${ind}].precio_prevision`, sucursales[0].precio_prevision);
          }
        });
      }
    }
  }, [switchC]);

  useEffect(() => {
    if (!defVal) {
      const tempAS = [];
      arraySucursales.forEach((element) => {
        tempAS.push({
          sucursal: element.value,
          precio_normal: '0',
          precio_prevision: '0'
        });
      });
      setFieldValue('sucursales', tempAS);
    }
  }, [arraySucursales]);

  useEffect(() => {
    if (sucursales[0].precio_normal && sucursales[0].precio_normal !== '0') {
      checkChanges('precio_normal', sucursales[0].precio_normal, '0');
    }
  }, [sucursales[0].precio_normal]);
  useEffect(() => {
    if (sucursales[0].precio_prevision && sucursales[0].precio_prevision !== '0') {
      checkChanges('precio_prevision', sucursales[0].precio_prevision, '0');
    }
  }, [sucursales[0].precio_prevision]);

  useEffect(() => {
    let btnStatus = '';
    if (arraySucursales && sucursales) {
      sucursales.forEach((_item, index) => {
        const { planes } = planesPorSucursal[index];
        if ((planes.includes('inmediata') && _item.precio_normal === '0')
          || (planes.includes('prevision') && _item.precio_prevision === '0')) {
          btnStatus = 'idle';
        }
      });
    } else {
      btnStatus = 'idle';
    }
    setStatus(btnStatus);
  }, [sucursales]);

  return (
    <form onSubmit={handleSubmit}>
      {arraySucursales && sucursales && arraySucursales.map((item, index) => (
        <div className="container-fluid" key={item.value}>
          {index === 0 && (
            <>
              <div className="content-buttom">&nbsp;</div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <h2>{item.label}</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <br />
                </div>
                <div className="col-12">
                  <div className="row prospect-message-cont">
                    <div className="col-10 col-md-11 ">
                      <p>Aplicar los mismos precios a todas las sucursales</p>
                    </div>
                    <div className="col-2 col-md-1 ">
                      <Switch
                        checked={switchC}
                        onChange={() => setSwitchC(!switchC)}
                        onColor="#000000"
                        onHandleColor="#FFFFFF"
                        offColor="#CFCFCF"
                        offHandleColor="#FFFFFF"
                        borderRadius={60}
                        handleDiameter={5}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={25}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {checkApplicability(item.value, 'inmediata') && (
                  <div className="col-12 col-sm-12 col-md-6">
                    <label htmlFor="precio_normal" className="label mt-m">
                      Precio emergencia
                      <CurrencyInput
                        id="precio_normal"
                        name="precio_normal"
                        placeholder=""
                        className={`input ${submitted && errors.sucursales && 'has-error'}`}
                        defaultValue={0}
                        decimalsLimit={2}
                        value={sucursales[index].precio_normal}
                        prefix="$"
                        pattern="[$]+[0-9]+([\.,][0-9]+)?"
                        onValueChange={(e) => _handleChange(e, index, 'precio_normal')}
                      />
                      {submitted && errors.sucursales && (
                        <small className="caption-error">
                          {errors.sucursales}
                        </small>
                      )}
                    </label>
                  </div>
                )}
                {checkApplicability(item.value, 'prevision') && (
                  <div className="col-12 col-sm-12 col-md-6">
                    <label htmlFor="precio_prevision" className="label mt-m">
                      Precio a previsión
                      <CurrencyInput
                        id="precio_prevision"
                        name="precio_prevision"
                        placeholder=""
                        className={`input ${submitted && errors.sucursales && 'has-error'}`}
                        decimalsLimit={2}
                        value={sucursales[index].precio_prevision}
                        prefix="$"
                        onValueChange={(e) => _handleChange(e, index, 'precio_prevision')}
                      />
                      {submitted && errors.sucursales && (
                        <small className="caption-error">
                          {errors.sucursales}
                        </small>
                      )}
                    </label>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ))}
      {arraySucursales && sucursales && sucursales.length > 1
        && arraySucursales.map((item, index) => (
          <div className="container-fluid" key={item.value}>
            {index > 0 && (
              <>
                <div className="content-buttom">&nbsp;</div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <h2>{item.label}</h2>
                  </div>
                </div>
                <div className="row">
                  {checkApplicability(item.value, 'inmediata') && (
                    <div className="col-12 col-sm-12 col-md-6">
                      <label htmlFor="precio_normal" className="label mt-m">
                        Precio emergencia
                        <CurrencyInput
                          id="precio_normal"
                          name="precio_normal"
                          placeholder=""
                          className={`input ${submitted && errors.sucursales && 'has-error'}`}
                          defaultValue={0}
                          value={sucursales[index].precio_normal}
                          decimalsLimit={2}
                          prefix="$"
                          onValueChange={(e) => _handleChange(e, index, 'precio_normal')}
                        />
                        {submitted && errors.sucursales && (
                          <small className="caption-error">
                            {errors.sucursales}
                          </small>
                        )}
                      </label>
                    </div>
                  )}
                  {checkApplicability(item.value, 'prevision') && (
                    <div className="col-12 col-sm-12 col-md-6">
                      <label htmlFor="precio_prevision" className="label mt-m">
                        Precio a previsión
                        <CurrencyInput
                          id="precio_prevision"
                          name="precio_prevision"
                          placeholder=""
                          className={`input ${submitted && errors.sucursales && 'has-error'}`}
                          defaultValue={0}
                          decimalsLimit={2}
                          value={sucursales[index].precio_prevision}
                          prefix="$"
                          onValueChange={(e) => _handleChange(e, index, 'precio_prevision')}
                        />
                        {submitted && errors.sucursales && (
                          <small className="caption-error">
                            {errors.sucursales}
                          </small>
                        )}
                      </label>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <button className="input-label-float-right button-tertiary btn-step" onClick={() => prevStepF()}>
            Regresar
          </button>
        </div>
        <div className="col-12 col-md-6">
          <button className="button btn-step" type="submit" disabled={status === 'idle' ? 'disabled' : ''}>
            Publicar
          </button>
        </div>
      </div>
    </form>
  );
};

export default FrmPriceProduct;
