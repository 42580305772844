/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { toBeVisible } from '@testing-library/jest-dom/matchers';
import { FContractOwnerInfo } from '../formik/formik';
import { updateClient, findCP_S } from '../services';
import maritalStatus from '../../../global/data/maritalStatus.json';
import countriesData from '../../../global/data/countries.json';
import CheckBox from '../../../global/custom/checkbox';

const FrmDataOwner = ({
  dataForm, eventV, setOpenConfirmDialog, openConfirmDialog, optionUpdate, eventShowMessage, refresh,
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FContractOwnerInfo();
  const {
    nombre,
    apellidoMaterno,
    apellidoPaterno,
    rfc,
    fechaNacimiento,
    estadoCivil,
    ocupacion,
    genero,
    telefono,
    email,
    edad,
    cp,
    estado,
    ciudad,
    colonia,
    direccion,
  } = values;
  const [submitted, setSubmitted] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [colonies, setColonies] = useState(null);
  const [status, setStatus] = useState('idle');

  const [statusCheckbox1, setStatusCheckbox1] = useState(false);
  const [statusCheckbox2, setStatusCheckbox2] = useState(false);

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      setStatus('idle');
      // eventV(false);
      setOpenConfirmDialog(true);
    }
  };
  const eventCheckB1 = () => {
    setStatusCheckbox1(!statusCheckbox1);
  };
  const eventCheckB2 = () => {
    setStatusCheckbox2(!statusCheckbox2);
  };

  const CofirmHandleSubmit = async () => {
    if (isValid && dirty) {
      const tempData = {
        nombre,
        apellidoMaterno,
        apellidoPaterno,
        rfc,
        fechaNacimiento,
        estadoCivil,
        ocupacion,
        sexo: genero,
        telefono,
        email,
        edad,
        domicilio: [{
          cp,
          estado,
          ciudad,
          colonia,
          direccion,
        }]
      };
      if (statusCheckbox1) {
        tempData.finado = statusCheckbox1;
      } else {
        tempData.finado = false;
      }
      if (statusCheckbox2) {
        tempData.cesion = statusCheckbox2;
      } else {
        tempData.cesion = false;
      }
      await updateClient(dataForm.clients._id, tempData).then((resp) => {
        if (resp) {
          refresh();
          eventV(false);
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    }
  };

  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth()) || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };
  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((_data) => {
      if (_data && _data.respuesta && _data.respuesta.codigos_postales) {
        if (_data.respuesta.codigos_postales.length === 1) {
          _data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (_data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          _data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };
  useEffect(() => {
    if (cp?.toString() && cp?.toString().length > 4) {
      getCPService();
    } else if (cp?.toString() && cp?.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  useEffect(() => {
    if (dataForm) {
      setFieldValue('nombre', dataForm.clients.nombre);
      setFieldValue('apellidoMaterno', dataForm.clients.apellidoMaterno);
      setFieldValue('apellidoPaterno', dataForm.clients.apellidoPaterno);
      setFieldValue('rfc', dataForm.clients.rfc);
      setFieldValue('estadoCivil', dataForm.clients.estadoCivil);
      setFieldValue('ocupacion', dataForm.clients.ocupacion);
      setFieldValue('genero', dataForm.clients.sexo);
      setFieldValue('telefono', dataForm.clients.telefono);
      setFieldValue('email', dataForm.clients.email);
      setFieldValue('cp', dataForm.clients.domicilio[0]?.cp || 0);
      setFieldValue('estado', dataForm.clients.domicilio[0]?.estado || '');
      setFieldValue('ciudad', dataForm.clients.domicilio[0]?.ciudad || '');
      setFieldValue('colonia', dataForm.clients.domicilio[0]?.colonia || '');
      setFieldValue('direccion', dataForm.clients.domicilio[0]?.direccion || '');
      setStatusCheckbox1(dataForm.clients.finado || false);
      setStatusCheckbox2(dataForm.clients.cesion || false);
      if (dataForm.clients?.fechaNacimiento) {
        let formatDate = new Date(dataForm.clients.fechaNacimiento);
        // eslint-disable-next-line prefer-destructuring
        formatDate = formatDate.toISOString().split('T')[0];
        setFieldValue('fechaNacimiento', formatDate);
      }
    }
  }, [dataForm]);
  useEffect(() => {
    if (isValid) {
      setStatus('');
    } else {
      setStatus('idle');
    }
    if (values.fechaNacimiento) {
      setFieldValue('edad', age(new Date(values.fechaNacimiento)));
    }
  }, [values, isValid]);
  useEffect(() => {
    if (optionUpdate) {
      CofirmHandleSubmit();
    }
  }, [optionUpdate]);
  return (
    <div className="container" style={{ display: openConfirmDialog ? 'none' : '' }}>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="">
            <div>
              <div className="row">
                <div className="col-12">
                  <h2>Datos del Titular</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Nombre(s)
                    <input
                      id="nombre"
                      type="text"
                      className={`input ${submitted && errors.nombre && 'has-error'}`}
                      value={nombre}
                      onChange={handleChange('nombre')}
                    />
                    {
                          submitted && errors.nombre && <small className="caption-error">{errors.nombre}</small>
                        }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoPaterno" className="label mt-m">
                    Primer Apellido
                    <input
                      id="apellidoPaterno"
                      type="text"
                      className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                      value={apellidoPaterno}
                      onChange={handleChange('apellidoPaterno')}
                    />
                    {
                          submitted && errors.apellidoPaterno && <small className="caption-error">{errors.apellidoPaterno}</small>
                        }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoMaterno" className="label mt-m">
                    Apellido Materno
                    <input
                      id="apellidoMaterno"
                      type="text"
                      className={`input ${submitted && errors.apellidoMaterno && 'has-error'}`}
                      value={apellidoMaterno}
                      onChange={handleChange('apellidoMaterno')}
                    />
                    {
                          submitted && errors.apellidoMaterno && <small className="caption-error">{errors.apellidoMaterno}</small>
                        }
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fechaNacimiento" className="label mt-m">
                    Fecha de nacimiento
                    <input
                      id="fechaNacimiento"
                      type="date"
                      className={`input input-date ${submitted && errors.fechaNacimiento && 'has-error'}`}
                      value={fechaNacimiento}
                      onChange={handleChange('fechaNacimiento')}
                    />
                    {
                          submitted && errors.fechaNacimiento && <small className="caption-error">{errors.fechaNacimiento}</small>
                        }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label className="label labels mt-m">
                    Género
                    <br />
                    <button type="button" onClick={() => setFieldValue('genero', 'masculino')} className={`btn-edc ${genero === 'masculino' ? 'active' : ''} genderM`}>Masculino</button>
                    <button type="button" onClick={() => setFieldValue('genero', 'femenino')} className={`btn-edc ${genero === 'femenino' ? 'active' : ''} genderF`}>Femenino</button>
                    {errors.sexo && (
                    <small className="caption-error">
                      {errors.sexo}
                    </small>
                    )}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="estadoCivil" className="label mt-m">
                    Estado civil
                    <select
                      type="estadoCivil"
                      value={estadoCivil}
                      onChange={handleChange('estadoCivil')}
                    >
                      <option value="">Seleccione una opción</option>
                      {maritalStatus.map((items) => (
                        <option key={items.value} name={items.value} value={items.value}>
                          {items.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="ocupacion" className="label mt-m">
                    Ocupación
                    <input
                      id="ocupacion"
                      type="text"
                      className={`input ${submitted && errors.ocupacion && 'has-error'}`}
                      value={ocupacion}
                      onChange={handleChange('ocupacion')}
                    />
                    {
                          submitted && errors.ocupacion && <small className="caption-error">{errors.ocupacion}</small>
                        }
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="telefono" className="label mt-m">
                    Teléfono
                    <input
                      id="telefono"
                      type="phone"
                      className={`input ${submitted && errors.telefono && 'has-error'}`}
                      value={telefono}
                      onChange={handleChange('telefono')}
                    />
                    {
                          submitted && errors.telefono && <small className="caption-error">{errors.telefono}</small>
                        }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="email" className="label mt-m">
                    Correo electronico
                    <input
                      id="email"
                      type="text"
                      className={`input ${submitted && errors.email && 'has-error'}`}
                      value={email}
                      onChange={handleChange('email')}
                    />
                    {
                          submitted && errors.email && <small className="caption-error">{errors.email}</small>
                        }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="rfc" className="label mt-m">
                    RFC
                    <input
                      id="rfc"
                      type="text"
                      className={`input ${submitted && errors.rfc && 'has-error'}`}
                      value={rfc}
                      onChange={handleChange('rfc')}
                    />
                    {
                          submitted && errors.rfc && <small className="caption-error">{errors.rfc}</small>
                        }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">&nbsp;</div>
                <div className="col-12 ">&nbsp;</div>
                <div className="col-6 col-sm-4 col-md-2 col-lg-2">
                  <label className="text-secondary" htmlFor="">
                    Finado
                  </label>
                  <CheckBox status={statusCheckbox1} eventC={eventCheckB1} nameC="Finado" />
                </div>
                <div className="col-6 col-sm-4 col-md-2 col-lg-2">
                  <label className="text-secondary" htmlFor="">
                    Cesión
                  </label>
                  <CheckBox status={statusCheckbox2} eventC={eventCheckB2} nameC="Cesión" />
                </div>
              </div>
              <div className="row">
                <div className="col-12">&nbsp;</div>
                <div className="col-12">
                  <h2>Domicilio</h2>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="cp" className="label mt-m ">
                      Código postal
                      <input
                        id="cp"
                        type="number"
                        maxLength="5"
                        pattern="[0-9]{X,5}"
                        className={`input ${submitted && errors.cp && 'has-error'}`}
                        onChange={handleChange('cp')}
                        value={cp}
                      />
                      {
                          errors?.cp && <small className="caption-error">{errors.cp}</small>
                        }
                    </label>
                  </div>
                  <div className="col"> </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="estado" className="label mt-m">
                      Estado
                      <select
                        type="select"
                        name="estado"
                        className={`${submitted && errors.estado && 'has-error'}`}
                        value={estado}
                        onChange={handleChange('estado')}
                      >
                        <option value="">Estado</option>
                        {countriesData.map(({ states }) => (
                          <option key={states} name={states} value={states}>
                            {states}
                          </option>
                        ))}
                      </select>
                      {
                          submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
                        }
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="ciudad" className="label mt-m">
                      Localidad o Municipio
                      <select
                        type="select"
                        name="ciudad"
                        className={`${submitted && errors.ciudad && 'has-error'}`}
                        value={ciudad}
                        onChange={handleChange('ciudad')}
                      >
                        <option value="">Localidad</option>
                        {localidades.map((loc) => (
                          <option key={loc} name={loc} value={loc}>
                            {loc}
                          </option>
                        ))}
                      </select>
                      {
                          submitted && errors.ciudad && <small className="caption-error">{errors.ciudad}</small>
                        }
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="colonia" className="label mt-m">
                      Colonia
                      {!colonies && (
                      <input
                        id="colonia"
                        type="text"
                        className={`input ${submitted && errors.colonia && 'has-error'}`}
                        onChange={handleChange('colonia')}
                        value={colonia}
                      />
                      )}
                      {colonies && (
                      <select
                        type="select"
                        name="colonia"
                        className={`${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                      >
                        <option value="">-Seleccione la Colonia-</option>
                        {colonies.map((_colonia) => (
                          <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                            {_colonia}
                          </option>
                        ))}
                      </select>
                      )}
                      {
                          submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                        }
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="direccion" className="label mt-m">
                      Dirección
                      <input
                        id="direccion"
                        type="text"
                        className={`input ${submitted && errors.direccion && 'has-error'}`}
                        onChange={handleChange('direccion')}
                        value={direccion}
                      />
                      {
                          submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                        }
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="col-12 col-md-12">&nbsp;</div>
                <div className="col-12 col-md-12">
                  <button type="button" onClick={() => eventV(false)} className="button-secondary p-r m-btn-prof">
                    Cancelar
                  </button>
                  <button className="button mt-m" type="submit" disabled={status === 'idle' ? 'disabled' : ''}>
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default FrmDataOwner;
