import axios from '../../../store/axios';

export const getMyNotifications = async (options) => {
  try {
    const res = await axios().get(`notifications/my-notifications?${options}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getMyNotificationsByPage = async ({
  page, pagination, order,
}) => {
  try {
    const res = await axios().get('notifications/my-notifications', {
      params: {
        page,
        pagination,
        order,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const markAsRead = async (options) => {
  try {
    const res = await axios().patch('notifications/mark-as-read', options);
    return res;
  } catch (err) {
    return err;
  }
};

export const markAllAsRead = async () => {
  try {
    const res = await axios().put('notifications/read-notifications-all');
    return res;
  } catch (err) {
    return err;
  }
};
