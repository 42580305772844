import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    password: '',
    confirmPassword: '',
  },
  validationSchema: Yup.object({
    password: Yup.string()
      .required('La contraseña es requerida')
      .min(6, 'La contraseña debe tener mínimo 6 caracteres'),
  }),
});
