import axios from 'axios';
import { BASE_URL } from '../global/constants';

let store;
let logoutUser;

export const injectStore = (_store) => {
  store = _store;
};

export const injectLogout = (callback) => {
  logoutUser = callback;
};

const axiosConfig = () => {
  const config = axios.create();
  config.defaults.baseURL = BASE_URL;
  config.interceptors.request.use(
    async (req) => {
      const token = localStorage.getItem('token');
      if (token && !req.headers.Authorization) {
        req.headers.Authorization = `Bearer ${token}`;
      }
      return req;
    },
    (error) => Promise.reject(error)
  );
  config.interceptors.response.use(
    (res) => {
      if (res && res.data) {
        return res.data;
      }
      return res;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        // automáticamente cierra la sesión del usuario
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        store.dispatch(logoutUser());
      }
      if (error && error.response) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    }
  );
  return config;
};

export default axiosConfig;
