/* eslint-disable no-unused-vars */
import axios from '../../../store/axios';

export const getAllSucursales = async () => {
  try {
    const res = await axios().get('sucursales/buscar');
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getColaboradores = async (query) => {
  try {
    const res = await axios().get(`empleados/all${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const createPropspects = async (params) => {
  try {
    const res = await axios().post('prospectos', params);
    return res;
  } catch (err) {
    return err;
  }
};

export const getProspects = async (query) => {
  try {
    const res = await axios().get(`/prospectos/buscar?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getProspectsByPage = async ({
  nombre, sucursal, fechaOrden, orden, paginacion, paginas,
}) => {
  try {
    const res = await axios().get('/prospectos/buscar', {
      params: {
        nombre,
        sucursal,
        fechaOrden,
        orden,
        paginacion,
        paginas
      },
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getProspectsById = async (id) => {
  try {
    const res = await axios().get(`prospectos/buscar/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getProspectsByIdInfo = async (id) => {
  try {
    const res = await axios().get(`prospectos/detalles/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateProspectsById = async (id, params) => {
  try {
    const res = await axios().put(`/prospectos/actualizar/${id}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const addTrackingToProspect = async (id, data) => {
  try {
    const res = await axios().post(`/prospectos/seguimiento-prospecto/${id}`, data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateProspectTrackingById = async (id, idSeguimiento, data) => {
  try {
    const res = await axios().put(`/prospectos/modificar-seguimiento/${id}`, data, {
      params: {
        idSeguimiento
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const downloadQuotationFile = async (data) => {
  try {
    const response = await axios().post('pdf/pdf-cotizacion', data, {
      responseType: 'blob'
    });
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    let anchor = document.createElement('a');
    let url = window.URL.createObjectURL(
      new Blob(binaryData, { type: dataType }),
      { oneTimeOnly: true }
    );
    anchor.href = url;
    anchor.target = '_blank';
    anchor.click();
    /* downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    downloadLink.setAttribute('download', 'Cotización');
    document.body.appendChild(downloadLink);
    downloadLink.click(); */
    return response;
  } catch (err) {
    return err;
  }
};

export const sendQuotationFile = async (email, data) => {
  try {
    const res = await axios().post('prospectos/cotizacion', data, {
      params: {
        email,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getAsesores = async (query) => {
  try {
    const res = await axios().get(`empleados/asesor-venta?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateProspect = async (idProspect, params) => {
  try {
    const res = await axios().put(`/prospectos/actualizar/${idProspect}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const getClientByID = async (idClient) => {
  try {
    const res = await axios().get(`clientes/buscar/${idClient}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getClientes = async (query) => {
  try {
    const res = await axios().get(`clientes/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const findCP_S = async (query) => {
  try {
    const res = await axios().get(`/cp/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getProducts = async (params) => {
  try {
    const res = await axios().get(`productos/buscar?${params}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getSucursales = async (query = '&paginacion=1&paginas=1000') => {
  try {
    const res = await axios().get(`sucursales/buscar?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const registerClient = async (data) => {
  const res = await axios().post('clientes', data);
  return res;
};

export const registerContract = async (data) => {
  const res = await axios().post('ventas', data);
  return res;
};

export const deleteProspect = async (idProspect) => {
  const res = await axios().delete(`prospectos/eliminar/${idProspect}`);
  return res;
};

export const updateEmployee = async (idEmployee, params) => {
  try {
    const res = await axios().put(`clientes/actualizar/${idEmployee}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadFileC = async (file, pathI, ref) => {
  try {
    const form = new FormData();
    form.append('path', pathI);
    form.append('file', file);
    form.append('ref', ref);
    const res = await axios().post('aws', form);
    return res;
  } catch (err) {
    return err;
  }
};

export const insertBeneficiarie = async (data) => {
  try {
    const res = await axios().post('beneficiaries', data);
    return res;
  } catch (err) {
    return err;
  }
};

export const getDescuentoSearch = async (query) => {
  try {
    const res = await axios().get(`ventas/validarCupon?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const validEmailServ = async (query) => {
  try {
    const res = await axios().get(`clientes/validar-email?email=${query}`);
    return res;
  } catch (err) {
    return err;
  }
};
