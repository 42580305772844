import { useState } from 'react';
import ProfileDefComponent from '../../shared/profileDef';
import { updateImgEmployee } from '../services';
import '../profile.css';

const ViewPhotoComp = ({
  eventV, data, NameC, id
}) => {
  const [status, setStatus] = useState('idle');
  const [fileI, setfFleI] = useState(null);
  const [tempUrl, setTempUrl] = useState(null);
  const [statusApi, setStatusApi] = useState(null);

  const getFile = async (e) => {
    if (e.target.files.length > 0) {
      const [file] = e.target.files;
      if (!(file.type.includes('jpeg') || file.type.includes('png'))) {
        setStatusApi('No se permite este tipo de archivo.');
        return;
      }
      setfFleI(file);
      setStatus('');
      setStatusApi('');
      setTempUrl(URL.createObjectURL(file));
    } else {
      setStatusApi(null);
      if (!tempUrl) {
        setStatus('idle');
        setfFleI(null);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('idle');
    await updateImgEmployee(id, fileI).then((resp) => {
      if (resp && resp._id) {
        const tempSes = JSON.parse(localStorage.getItem('user'));
        tempSes.imagen_perfil = resp.imagen_perfil;
        localStorage.setItem('user', JSON.stringify(tempSes));
        eventV(false);
        window.location.reload(false);
      } else {
        setStatusApi('No se pudo actualizar, intente denuevo mas tarde.');
      }
    });
  };

  return (
    <div className="container-fluid content-full">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={() => eventV(false)}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>Actualizar foto de perfil</h2>
            </div>
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-2">
                  <ProfileDefComponent name={NameC} size="md" imgProf={tempUrl || data.imagen_perfil || null} />
                </div>
                <div className="col-12 col-sm-12 col-md-10 ">
                  <label className="datos-title lg">Foto de perfil</label>
                  <label className="datos-title md">Los tipos de archivo admitidos son JPG y PNG</label>
                  <div className="p-r">
                    <input
                      type="file"
                      accept="image/*"
                      className="input-file"
                      onChange={(e) => getFile(e)}
                    />
                    <span className="input-file-label">
                      Subir archivo
                      <i>&#59661;</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {fileI && (
              <div className="row ">
                <div className="col-2">&nbsp;</div>
                <div className="col-10">
                  <label className="datos-title">{fileI.name}</label>
                </div>
              </div>
            )}
            {statusApi && <small className="caption-error">{statusApi}</small>}

            <div className="col-12 col-md-12">
              &nbsp;
            </div>
            <div className="col-12 col-md-12">
              &nbsp;
            </div>
            <div className="col-12 col-md-12">
              <button onClick={() => eventV(false)} className="button-secondary p-r m-btn-prof">
                Cancelar
              </button>
              <button className="button p-r" disabled={status === 'idle' ? 'disabled' : ''} onClick={handleSubmit}>
                Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPhotoComp;
