import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { updateProspectsById } from '../../servives';
import EditSeguimientoForm from '../editSeguimiento/editSeguimientoForm';
import { monthDayYearFormat } from '../../../../helpers/dates';

const SeguimientoProspect = ({
  data, prospectId, eventShowMessage, refresh, permision,
}) => {
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [isEditEnabled, setEditEnabled] = useState(true);

  const handleSelectItem = (item, index) => {
    setSelectedItem(item);
    setEditEnabled(index === 0 && !item.tipo.includes('Cotización'));
    setShowEditModal(true);
  };

  // eslint-disable-next-line no-unused-vars
  const removeSeguimiento = async (idSeg) => {
    if (data) {
      const tempP = [];
      data.forEach((itemP) => {
        if (itemP._id !== idSeg) {
          const tempData = {
            estatus: itemP.estatus,
            fecha: itemP.fecha,
            idEmpleado: itemP.idEmpleado,
            lugar: itemP.lugar || '-',
            tipo: itemP.tipo,
            comentarios: itemP.comentarios || '-',
            numero: itemP.numero || undefined,
          };
          tempP.push(tempData);
        }
      });
      const finalP = {
        seguimiento: tempP
      };
      await updateProspectsById(id, finalP).then((resp) => {
        if (resp && !resp.errors) {
          refresh();
          eventShowMessage('Seguimiento eliminado de la lista.');
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    }
  };

  const eventAsigProd = () => {
    navigate(`/prospects/${id}/seguimiento`);
  };

  const formatAMPM = (date) => {
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  };
  const formatDate = (date) => {
    try {
      /* const tempD = new Date(date).toLocaleDateString('es-Mx', {
        weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'
      }); */
      const tempD = monthDayYearFormat(date);
      const tempHr = formatAMPM(new Date(date));
      return `${tempD}, ${tempHr}`;
    } catch (_error) {
      return '-';
    }
  };

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    const currentItem = data.find((item) => item._id === selectedItem._id);
    setSelectedItem(currentItem);
  }, [data, selectedItem]);

  return (
    <>
      {showEditModal
        && (
          <EditSeguimientoForm
            onClose={() => setShowEditModal(false)}
            trackingItem={selectedItem}
            prospectId={prospectId}
            refresh={refresh}
            isEditEnabled={isEditEnabled}
          />
        )}
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-1"> </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-10">
            <br />
            <br />
            <div className="row">
              <div className="col-12 col-md-9">
                <h2>Historial de seguimiento</h2>
              </div>
              <div className="col-12 col-md-3 btn-content-filt">
                {permision('Prospectos', 'agendarSeguimiento') && (
                <button className="button" type="button" onClick={() => eventAsigProd()}>
                  &nbsp;&nbsp;Agendar&nbsp;&nbsp;
                  <i className="btn-icon">&#59672;</i>
                </button>
                )}
              </div>
            </div>
            <br />
            <div className="container-fluid">
              {(!data || !data[0]) && (
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-3 col-lg-4"> </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div className="container content-center content-info smH">
                      <div className="row">
                        <div className="col-12 cont-icon-info">
                          <label className="icon-info-product">&#59671;</label>
                        </div>
                        <div className="col-12">
                          <label className="text-title-info">Sin eventos de seguimiento</label>
                        </div>
                        <div className="col-12 col-md-12">
                          <label className="text-info">Aquí podrás visualizar los eventos de seguimiento que hayas agendado para este prospecto, como llamadas o visitas.</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-3 col-lg-4"> </div>
                </div>
              )}
              {data && data[0] && data.map((item, i) => (
                <div key={generateKey(item._id)} className="row">
                  <div className="option-tab">
                    <div className="col-2  col-sm-2 col-md-1" onClick={() => handleSelectItem(item, i)}>
                      {item.tipo === 'Cita' && (
                        <div className="btn-icon tab-seguimiento">&#59694;</div>
                      )}
                      {item.tipo === 'Llamada' && (
                        <div className="btn-icon tab-seguimiento">&#59710;</div>
                      )}
                      {item.tipo === 'WhatsApp' && (
                        <div className="btn-icon tab-seguimiento">&#59709;</div>
                      )}
                      {item.tipo === 'Cotización enviada' && (
                        <div className="btn-icon tab-seguimiento">&#59704;</div>
                      )}
                      {!['Llamada', 'Cita', 'WhatsApp', 'Cotización enviada'].includes(item.tipo) && (
                        <div className="btn-icon tab-seguimiento">&nbsp;</div>
                      )}
                    </div>
                    <div className="col-2  col-sm-8 col-md-10" onClick={() => handleSelectItem(item, i)}>
                      <p className="text-name">{item.tipo}</p>
                      <span className="text-email">{formatDate(item.fecha)}</span>
                      <p className="text-name">{item?.lugar || ''}</p>
                    </div>
                    <div className="col-2  col-sm-2 col-md-1">
                      <span className="text-name">&nbsp;</span>
                      <span className="text-name">&nbsp;</span>
                      {/* <i className="options-btn" onClick={() => removeSeguimiento(item._id)}>
                        <p>-</p>
                      </i> */}
                    </div>
                  </div>
                  <hr className="notif-line-spacing" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1"> </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default SeguimientoProspect;
