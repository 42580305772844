/* eslint-disable no-unused-vars */
import { updateContract } from '../services';
import AddContractMProduct from './addProductsC/addContractMProduct';

const AddProductsM = ({
  eventOp, statusOp, dataC, refresh, eventShowMessage,
}) => {
  const saveDataP = async (dataFrm) => {
    const tempP = [];
    dataC.productos.forEach((item) => {
      let tempData = {
        idProducto: item.idProducto,
        cantidad: item.cantidad,
        disponibilidad: item.disponibilidad,
        precioInicial: item.precioInicial || undefined,
        precioDescuento: item.precioDescuento || undefined,
        tipo: item.tipo || undefined,
        codigo: item.codigo || undefined,
        // sucursales: item.sucursales || undefined,
        nombre: item.nombre || undefined,
        caracteristicas: item.caracteristicas || undefined,
        categoria: item.categoria || undefined,
        galeria: item.galeria || undefined,
      };
      if (item.imagen) {
        const json = item.imagen;
        const key = '_id';
        json.forEach((_itm, inx) => {
          delete json[inx][key];
        });
        tempData.imagen = json;
      }
      if (item.idDescuento) {
        tempData.idDescuento = item.idDescuento;
      }
      tempP.push(tempData);
    });
    dataFrm.forEach((item) => {
      let tempData = {
        idProducto: item._id,
        cantidad: item.Cantidad,
        disponibilidad: item._Disponibilidad,
        precioInicial: item.precioInicial,
        precioDescuento: item.precioDescuento,
        tipo: item.tipo || undefined,
        codigo: item.codigo || undefined,
        // sucursales: item.sucursales || undefined,
        nombre: item.nombre || undefined,
        caracteristicas: item.caracteristicas || undefined,
        categoria: item.categoria || undefined,
        galeria: item.galeria || undefined,
      };
      if (item.imagen) {
        const json = item.imagen;
        const key = '_id';
        json.forEach((_itm, inx) => {
          delete json[inx][key];
        });
        tempData.imagen = json;
      }
      if (item.descuentos) {
        if (item._Disponibilidad === 'prevision' && item?.descuentos?.prevision?.idDescuento) {
          tempData.idDescuento = item.descuentos.prevision.idDescuento;
        }
        if (item._Disponibilidad === 'inmediata' && item?.descuentos?.usoInmediato?.idDescuento) {
          tempData.idDescuento = item.descuentos.usoInmediato.idDescuento;
        }
      }
      tempP.push(tempData);
    });
    const createP = await updateContract(dataC._id, { productos: tempP });
    if (createP && createP.ok) {
      refresh();
      eventOp();
      eventShowMessage('Producto agregado correctamente.');
    } else {
      eventShowMessage('El producto no pudo ser agregado, intente mas tarde.');
    }
  };

  return (
    <>
      {statusOp && (
        <div className="absolute-content trasp ">
          <AddContractMProduct
            eventOp={eventOp}
            statusOp={statusOp}
            setData={saveDataP}
            sucId={dataC.sucursales?.idSucursal}
          />
        </div>
      )}
    </>
  );
};

export default AddProductsM;
