/* eslint-disable max-len */
import { useState } from 'react';
import { useNavigate } from 'react-router';
import parse from 'html-react-parser';
import ProfileDefComponent from '../../../shared/profileDef';
import './stylesc.css';
import { useClientContractsPerPage } from '../../hooks/queries';
import NoContracts from './noContracts';
import { monthDayYearFormat } from '../../../../helpers/dates';

const ListContracts = ({ id, permision }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const redirectContractDetails = (idContract) => {
    if (permision('Clientes', 'consultarCliente')) {
      navigate(`/clients/contract-view/${idContract}`);
    }
  };

  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const perPage = 10;
  const { data: queryData } = useClientContractsPerPage({
    page,
    perPage,
    id,
  });

  const contractsList = queryData?.data;
  const { total } = queryData;
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const totalPages = Math.ceil(total / perPage);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-2 col-xl-3 pad-0"> </div>
        <div className="col-12 col-md-12 col-lg-8 col-xl-6 pad-0">
          <div className="container-fluid">
            <div className="row">
              <br />
              <br />
              <br />
            </div>
            <div className="row">
              {contractsList && contractsList.length > 0 ? (
                <div className="col-12">
                  <div className="row">
                    <div className="col-4 col-md-4 col-lg-2">
                      <small className="text-title-tab">CONTRATO</small>
                    </div>
                    <div className="col-4 col-md-4 col-lg-3">
                      <small className="text-title-tab">SALDO</small>
                    </div>
                    <div className="col-4 col-md-4 col-lg-2 p-r">
                      <small className="text-title-tab p-a contratos">SUCURSAL</small>
                    </div>
                    <div className="col-6 col-md-6 col-lg-3 p-r">
                      <small className="text-title-tab p-a contratos">RESPONSABLE</small>
                    </div>
                    <div className="col-6 col-md-6 col-lg-2 p-r">
                      <small className="text-title-tab p-a contratos">ESTATUS</small>
                    </div>
                  </div>
                  <br />
                  <br />
                  {
                    contractsList.map((item) => (
                      <div className="row" key={item._id || '-'}>
                        {item._id && (
                          <>
                            <div className="row row-cursor" onClick={() => redirectContractDetails(item._id)}>
                              <div className="col-4 col-md-4 col-lg-2 p-r pad-0">
                                <label className="text-name">
                                  {item.noContrato}
                                  &nbsp;
                                </label>
                                <label className="text-email">{monthDayYearFormat(item.fecha) || '-'}</label>
                              </div>
                              <div className="col-4 col-md-4 col-lg-3">
                                <label className="text-name">
                                  {item.saldo}
                                </label>
                                <label className="text-email">
                                  Total: $
                                  {item.precioVenta || parse('&nbsp;')}
                                  °°
                                </label>
                              </div>
                              <div className="col-4 col-md-4 col-lg-2 p-r pad-0">
                                <label className="text-dir">
                                  <i>&#59679;</i>
                                  {item.sucursales?.nombre || 'N/A'}
                                </label>
                              </div>
                              <div className="col-3 col-md-2 col-lg-1 p-r">
                                <ProfileDefComponent name={item.nombre} size="sm" />
                              </div>
                              <div className="col-5 col-md-5 col-lg-2 p-r pad-0">
                                <label className="text-name">
                                  {!item.nombre ? (
                                    <>
                                      Sin Asignar
                                    </>
                                  ) : (
                                    <>{item.nombre}</>
                                  )}
                                  &nbsp;
                                </label>
                              </div>
                              <div className="col-4 col-md-4 col-lg-2 p-r">
                                {item.estatus_contrato.toLowerCase() === 'firmado' ? (
                                  <label className="text-dir firma">
                                    <i className="btn-icon">&#59667;</i>
                                    Firmado
                                  </label>
                                ) : (
                                  <label className="firma">
                                    <i className="btn-icon">&#59690;</i>
                                    No Firmado
                                  </label>
                                )}
                              </div>
                            </div>
                            <hr className="line-spacing" />
                          </>
                        )}
                      </div>
                    ))
                  }
                </div>
              ) : (
                <div>
                  <NoContracts />
                </div>
              )}
            </div>

            <div className="row">
              <div className="container pag-content">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        <label className="text-pag">{page} de {totalPages}</label>
                      </div>
                      <div className="col-12 col-md-6 pag-content-btn">
                        <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
                        <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-2 col-xl-3 pad-0"> </div>
      </div>

    </>
  );
};

export default ListContracts;
