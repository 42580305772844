import { useFormik } from 'formik';
import * as Yup from 'yup';
// const phoneRegExp = '([0-9][^0-9]){8,10}$';
const phoneRegExp = /^[0-9\- ]{10,10}$/;

export const FormikStep1 = ({
  nombre = '',
  apellidoP = '',
  apellidoM = '',
  edad = '',
  gender = 'Masculino',
  estado_civil = '',
  phone = '',
  email = '',
}) => useFormik({
  validateOnMount: true,
  enableReinitialize: true,
  initialValues: {
    nombre,
    apellidoP,
    apellidoM,
    edad,
    gender,
    estado_civil,
    phone,
    email,
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoP: Yup.string()
      .required('El Primer Apellido es requerido'),
    apellidoM: Yup.string(),
    edad: Yup.string()
      .required('La Edad es requerida'),
    gender: Yup.string()
      .required('El Género es requerido'),
    estado_civil: Yup.string()
      .required('El Estado civil es requerido'),
    phone: Yup.string()
      .matches(phoneRegExp, 'El Teléfono es invalido')
      .required('El Teléfono es requerido'),
    email: Yup.string()
      .email('El Correo Electrónico es invalido'),
  }),
});

export const FormikStep2 = ({
  zipCode = '',
  estado = '',
  municipio = '',
  colonia = '',
  calle = '',
}) => useFormik({
  validateOnMount: true,
  enableReinitialize: true,
  initialValues: {
    zipCode,
    estado,
    municipio,
    colonia,
    calle,
  },
  validationSchema: Yup.object({
    zipCode: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('El Estado es requerido'),
    municipio: Yup.string()
      .required('La Localidad o Municipio es requerido'),
    colonia: Yup.string()
      .required('La Colonia es requerida'),
    calle: Yup.string(),
  }),
});

export const FormikStep3 = (defaultVal) => useFormik({
  validateOnMount: true,
  initialValues: {
    sucursal: defaultVal.sucursal || '',
    colaborador: defaultVal.colaborador || '',
  },
  validationSchema: Yup.object({
    sucursal: Yup.string()
      .required('La Sucursal es requerida'),
    colaborador: Yup.string(),
  }),
});

export const FormikStep4 = ({
  numero = '',
  interes = '',
  contacto = '',
  fecha = '',
  hora = '',
  lugar = '',
  agendar = false,
  comentarios = '',
}) => useFormik({
  validateOnMount: true,
  enableReinitialize: true,
  initialValues: {
    interes,
    contacto,
    fecha,
    hora,
    lugar,
    agendar,
    numero,
    comentarios,
  },
  validationSchema: Yup.object({
    interes: Yup.string(),
    contacto: Yup.string(),
    fecha: Yup.string(),
    hora: Yup.string(),
    lugar: Yup.string(),
    agendar: Yup.boolean(),
    numero: Yup.string().matches(phoneRegExp, 'El Teléfono es inválido'),
  }),
});
