import { makeActionCreator, makeReducer, makeType } from '../../../../reducers/creators';
import { login } from '../../services/auth';

const Auth = (resource) => {
  const {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    POST_START,
    POST_ERROR,
    POST_SUCCESS,
    UPDATE_START,
    UPDATE_ERROR,
    UPDATE_SUCCESS,
  } = makeType(resource);

  const initialState = {
    status: '',
    fetchStatus: '',
    user: JSON.parse(localStorage.getItem('user')) || {},
    error: '',
  };

  const RESET_STATUS = 'RESET_STATUS';
  const REFRESH_USER = 'REFRESH_USER';
  const LOGOUT = 'LOGOUT';

  const fetchStart = makeActionCreator(FETCH_START);
  const fetchSuccess = makeActionCreator(FETCH_SUCCESS, 'payload');
  const postStart = makeActionCreator(POST_START);
  const postSuccess = makeActionCreator(POST_SUCCESS, 'payload');
  const postError = makeActionCreator(POST_ERROR, 'error');

  const resetStatus = makeActionCreator(RESET_STATUS);
  // const refreshUser = makeActionCreator(REFRESH_USER, 'payload');
  const logout = makeActionCreator(LOGOUT);

  const postStartReduce = (state) => ({
    ...state,
    status: 'loading',
  });
  const postSuccessReduce = (state, action) => ({
    ...state,
    status: '',
    user: action.payload.user,
  });
  const postErrorReduce = (state, action) => ({
    ...state,
    status: 'error',
    error: action.error,
  });
  const resetStatusReduce = (state) => ({
    ...state,
    status: '',
  });
  const refreshUserReduce = (state, action) => ({
    ...state,
    user: { ...state.user, ...action.payload },
  });
  const fetchStartReduce = (state) => ({
    ...state,
    fetchStatus: 'loading',
  });
  const fetchSuccessReduce = (state, action) => ({
    ...state,
    user: { ...state.user, ...action.payload },
    fetchStatus: 'success',
  });
  const fetchErrorReduce = (state, action) => ({
    ...state,
    fetchStatus: 'error',
    error: action.error,
  });
  const updateStartReduce = (state) => ({
    ...state,
    status: 'loading',
  });
  const updateSuccessReduce = (state, action) => ({
    ...state,
    status: 'success',
    user: { ...state.user, ...action.payload },
  });
  const updateErrorReduce = (state, action) => ({
    ...state,
    status: '',
    error: action.error,
  });
  const logoutReduce = (state) => ({
    ...state,
    status: '',
    error: '',
    user: {},
  });

  const reducer = makeReducer(initialState, {
    [POST_START]: postStartReduce,
    [POST_SUCCESS]: postSuccessReduce,
    [POST_ERROR]: postErrorReduce,
    [RESET_STATUS]: resetStatusReduce,
    [REFRESH_USER]: refreshUserReduce,
    [FETCH_START]: fetchStartReduce,
    [FETCH_SUCCESS]: fetchSuccessReduce,
    [FETCH_ERROR]: fetchErrorReduce,
    [UPDATE_START]: updateStartReduce,
    [UPDATE_SUCCESS]: updateSuccessReduce,
    [UPDATE_ERROR]: updateErrorReduce,
    [LOGOUT]: logoutReduce,
  });

  return {
    reducer,
    login: (email, password) => async (dispatch) => {
      try {
        dispatch(postStart());
        const res = await login(email, password);
        if (res.token) {
          const { data, modulos } = res;
          const userStoreData = {
            _id: data._id,
            role: data.role.toLowerCase(),
            nombre: data.nombre,
            apellidoPaterno: data?.apellidoPaterno,
            apellidoMaterno: data?.apellidoMaterno,
            correo: data.email,
            telefono: data.telefono,
            imagen_perfil: data.imagen_perfil || null,
            puesto: data.puesto.toLowerCase() || null,
            modulos: modulos || null,
            // primerVisita: user.primerVisita,
            // tipo: user.tipo,
          };
          // console.log('userS', userStoreData);
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(userStoreData));
          dispatch(postSuccess({ user: userStoreData }));
          // const profile = await fetchClinicProfile(userStoreData._id);
          return dispatch(fetchSuccess(data));
        }
        return dispatch(postError(res.message));
      } catch (err) {
        return dispatch(postError(err.message));
      }
    },
    resetAuthStatus: () => (dispatch) => {
      dispatch(resetStatus());
    },
    refreshUser: () => async (dispatch) => {
      dispatch(fetchStart());
    },
    logoutUser: () => async (dispatch) => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      dispatch(logout({}));
    },
  };
};

export default Auth;
