import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { checkPermissionJob } from '../../../shared/routeGuards/checkComponentPermission';
import './prosp.css';

const calcFecha = (fecha) => {
  const fec = fecha.split('-', 3);
  const dia = fec[2].split('', 2);
  const date = `${dia[0]}${dia[1]}/${fec[1]}/${fec[0]}`;
  return date;
};
const ListProspectos = ({ data, permision }) => {
  const navigate = useNavigate();
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const styleMoney = (money) => {
    const tempM = money.split('.');
    const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
    return tempHtml;
  };
  const countProd = (prdSelect) => {
    let counterP = 0;
    prdSelect.forEach((item) => {
      if (item.cantidad) {
        counterP += item.cantidad;
      }
    });
    return counterP;
  };

  // const viewAllowed = checkPermissionJob([], 0);

  const redirectPage = (id) => {
    navigate(`/prospects/${id}`);
  };

  return (
    <div className="m-t-l">
      {!checkPermissionJob(['asesor de ventas']) ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6 col-sm-6 col-sm-6 col-md-6 col-lg-3">
                  <small className="text-title-tab">PROSPECTO</small>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-2">
                  <small className="text-title-tab">SUCURSAL</small>
                </div>
                {permision('Prospectos', 'actualizarResponsable') && (
                  <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                    <small className="text-title-tab">RESPONSABLE</small>
                  </div>
                )}
                <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                  <small className="text-title-tab">DESDE</small>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-2 p-r">
                  <small className="text-title-tab p-a">ESTATUS</small>
                </div>
                <div className="col-6 col-sm-6 col-md-12 col-lg-12">&nbsp;</div>
              </div>
              {
                data && data.length > 0 && data.map((item) => (
                  <div className="col-12" key={item._id}>
                    <div className="row row-cursor" onClick={() => redirectPage(item._id)}>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                        <label className="text-name">{item?.nombre}</label>
                        <label className="text-email">{item?.correo || '-'}</label>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-2">
                        <label className="text-dir">
                          <i>&#59679;</i>
                          {item?.sucursal || '-'}
                        </label>
                      </div>
                      {permision('Prospectos', 'actualizarResponsable') && (
                        <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                          <label className="text-dir">{item?.responsable || '-'}</label>
                        </div>
                      )}
                      <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                        <label className="text-dir">{calcFecha(item.createdAt)}</label>
                      </div>
                      <div className="col-6 col-sm-6 col-md-3 col-lg-2 estatus">
                        <small className="text-total-contr">{item.status === 1 ? 'En atención' : 'En espera'}</small>
                      </div>
                      <hr className="line-spacing" />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-12 col-sm-6 col-md-6 col-lg-6">
                  <small className="text-title-tab">PROSPECTO</small>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                  <small className="text-title-tab">VALOR ESTIMADO</small>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 content-center">
                  <small className="text-title-tab">NIVEL DE INTERÉS</small>
                </div>
              </div>
              <div className="row">
                {
                  data && data.length > 0 && data.map((item) => (
                    <div className="col-12" key={item._id}>
                      <div className="row row-cursor" onClick={() => redirectPage(item._id)}>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                          <label className="text-name">{item.nombre}</label>
                          <label className="text-email">{calcFecha(item.createdAt)}</label>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                          <label className="text-name text-money">{styleMoney(formatMoney.format(item.total_productos)) || '-'}</label>
                          <label className="text-email">
                            (
                            {`${countProd(item.productos)} Productos`}
                            )
                          </label>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3 content-center">
                          {
                            item.interes ? (
                              <>
                                {item.interes === 'Alto' && (<label className="text-status-pr ">Alto</label>)}
                                {item.interes === 'Medio' && (<label className="text-status-pr warn">Medio</label>)}
                                {item.interes === 'Bajo' && (<label className="text-status-pr dang">Bajo</label>)}
                              </>
                            ) : (<> </>)
                          }
                        </div>
                        <hr className="line-spacing" />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListProspectos;
