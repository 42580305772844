import { useFormik } from 'formik';
import * as Yup from 'yup';
// const phoneRegExp = '([0-9][^0-9]){8,10}$';
const phoneRegExp = /^[0-9\- ]{10,10}$/;

export const FormikStep1 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    estadoCivil: '',
    ocupacion: '',
    telefono: '',
    email: '',
    estado: '',
    ciudad: '',
    cp: '',
    colonia: '',
    direccion: '',
    sexo: '',
    rfc: '',
    entreCalles: '',
    otraCalle: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('El Primer Apellido es requerido'),
    apellidoMaterno: Yup.string(),
    fechaNacimiento: Yup.string()
      .required('La Fecha de nacimiento es requerida'),
    estadoCivil: Yup.string()
      .required('El Estado Civil es requerido'),
    ocupacion: Yup.string(),
    telefono: Yup.string()
      .matches(phoneRegExp, 'El Teléfono es invalido')
      .required('El Teléfono es requerido'),
    email: Yup.string()
      .email('El Correo Electrónico es invalido')
      .required('El Correo Electrónico es requerido'),
    estado: Yup.string()
      .required('El Estado es requerido'),
    ciudad: Yup.string()
      .required('La Localidad o municipio es requerido'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    colonia: Yup.string()
      .required('La Colonia es requerida'),
    direccion: Yup.string()
      .required('La Dirección es requerido'),
    sexo: Yup.string()
      .required('El Género es requerido'),
    rfc: Yup.string(),
    entreCalles: Yup.string(),
    otraCalle: Yup.string(),
  }),
});
export const FormikStep1_2 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    estadoExt: '',
    ciudadExt: '',
    cpExt: '',
    coloniaExt: '',
    direccionExt: '',
  },
  validationSchema: Yup.object({
    estadoExt: Yup.string()
      .required('El Estado es requerido'),
    ciudadExt: Yup.string()
      .required('La Localidad o municipio es requerido'),
    cpExt: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    coloniaExt: Yup.string()
      .required('La Colonia es requerida'),
    direccionExt: Yup.string()
      .required('La Dirección es requerido'),
  }),
});

export const FormikStep2 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre1: '',
    apellidoPaterno1: '',
    apellidoMaterno1: '',
    telefono1: '',
    email1: '',
    cp1: '',
    estado1: '',
    ciudad1: '',
    colonia1: '',
    direccion1: '',
    numInterior1: '',
    numExt1: '',
    nombre2: '',
    apellidoPaterno2: '',
    apellidoMaterno2: '',
    telefono2: '',
    email2: '',
    cp2: '',
    estado2: '',
    ciudad2: '',
    colonia2: '',
    direccion2: '',
    numInterior2: '',
    numExt2: '',
  },
  validationSchema: Yup.object({
    nombre1: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno1: Yup.string()
      .required('El Primer Apellido es requerido'),
    apellidoMaterno1: Yup.string(),
    telefono1: Yup.string()
      .matches(phoneRegExp, 'El Teléfono es invalido')
      .required('El Teléfono es requerido'),
    email1: Yup.string()
      .email('El Correo Electrónico es invalido')
      .required('El Correo Electrónico es requerido'),
    cp1: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado1: Yup.string()
      .required('El Estado es requerido'),
    ciudad1: Yup.string()
      .required('La Localidad o municipio es requerido'),
    colonia1: Yup.string()
      .required('La Colonia es requerido'),
    direccion1: Yup.string()
      .required('La Dirección es requerido'),
    numInterior1: Yup.string(),
    numExt1: Yup.string(),
    nombre2: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno2: Yup.string()
      .required('El Primer Apellido es requerido'),
    apellidoMaterno2: Yup.string(),
    telefono2: Yup.string()
      .matches(phoneRegExp, 'El Teléfono es invalido')
      .required('El Teléfono es requerido'),
    email2: Yup.string()
      .email('El Correo Electrónico es invalido')
      .required('El Correo Electrónico es requerido'),
    cp2: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado2: Yup.string()
      .required('El Estado es requerido'),
    ciudad2: Yup.string()
      .required('La Localidad o municipio es requerido'),
    colonia2: Yup.string()
      .required('La Colonia es requerido'),
    direccion2: Yup.string()
      .required('La Dirección es requerido'),
    numInterior2: Yup.string(),
    numExt2: Yup.string(),
  }),
});

export const FormikStep3 = () => useFormik({
  validateOnMount: true,
  initialValues: {
    fecha: '',
    noContrato: '',
    comentarios: '',
    precioVenta: 0,
    formaPago: 'mensualidades',
    diaPago: '',
    mensualidad: 0,
    anticipo: 200,
  },
  validationSchema: Yup.object({
    fecha: Yup.string(),
    noContrato: Yup.string()
      .required('El Número de contrato es requerido'),
    comentarios: Yup.string(),
    precioVenta: Yup.string(),
    formaPago: Yup.string()
      .required('La forma de pago es requerida'),
    diaPago: Yup.string(),
    mensualidad: Yup.string(),
    anticipo: Yup.number()
      .min(200, 'El Anticipo debe ser minimo de $200.00'),
  })
});

export const FormikStep4 = () => useFormik({
  validateOnMount: false,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fechaNacimiento: '',
    parentesco: '',
    telefono: '',
    edad: 0,
    cp: '',
    estado: '',
    ciudad: '',
    colonia: '',
    direccion: '',
    fileINE: null
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('Ingresa el primer apellido'),
    fechaNacimiento: Yup.string()
      .required('Ingresa la fecha de nacimiento'),
    parentesco: Yup.string()
      .required('El parentesco es requerido'),
    telefono: Yup.string()
      .required('El teléfono es requerido')
      .matches(/^[0-9]+$/, 'No es un número válido')
      .max(10, 'El número debe tener máximo 10 dígitos')
      .min(10, 'El número debe tener minimo 10 dígitos'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    estado: Yup.string()
      .required('Selecciona un estado'),
    ciudad: Yup.string()
      .required('Selecciona una localidad o municipio'),
    colonia: Yup.string()
      .required('La colonia es requerida'),
    direccion: Yup.string()
      .required('La Dirección es requerida'),
    fileINE: Yup.mixed().nullable(),
  })
});
