import React, { useEffect, useState } from 'react';

const PlansStep = ({
  onClose,
  statusOp,
  nextStep,
  prevStep,
  planType,
  setPlanType,
  isUpdate,
  handleUpdate,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);

  const handleSubmit = () => {
    if (isUpdate) {
      return handleUpdate();
    }
    return nextStep();
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid side-form-container">
            <div className="row">
              <div className="col-6">
                <span
                  className="icon-close return-icon"
                  onClick={prevStep}
                >
                  {' '}
                </span>
              </div>
              <div className="col-6 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Planes aplicables</h2>
                      <br />
                      <small>Selecciona los planes elegibles para este descuento</small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12">
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="allPlans"
                          id="allPlans"
                          checked={planType === 'Todos'}
                          onChange={() => setPlanType('Todos')}
                        />
                        <label className="form-check-label" htmlFor="allPlans">
                          Todos los planes
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="immediate"
                          id="immediate"
                          checked={planType === 'Inmediata'}
                          onChange={() => setPlanType('Inmediata')}
                        />
                        <label className="form-check-label" htmlFor="immediate">
                          Uso inmediato
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="prevision"
                          id="prevision"
                          checked={planType === 'Prevision'}
                          onChange={() => setPlanType('Prevision')}
                        />
                        <label className="form-check-label" htmlFor="prevision">
                          Previsión
                        </label>
                      </div>
                    </div>
                    <div className="col-12 spacing-nav" />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="form-bottom-container">
            <button
              className="button navR-content-btn"
              onClick={handleSubmit}
              type="button"
              disabled={!planType}
            >
              {isUpdate ? 'Guardar cambios' : 'Continuar'}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PlansStep;
