import { useNavigate } from 'react-router';
import FormularioEmpleados from '../../features/employees/components/addEmployees/FormularioEmpleados';

const AddEmployees = () => {
  const navigate = useNavigate();

  const closeForm = () => {
    navigate('/contributors');
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={closeForm}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <FormularioEmpleados />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployees;
