import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: false,
  initialValues: {
    oldPassword: '',
    newPassword: '',
    checkPassword: '',
  },
  validationSchema: Yup.object({
    oldPassword: Yup.string()
      .min(1, 'La Contraseña actual es requerida')
      .required('La Contraseña actual es requerida'),
    newPassword: Yup.string()
      .required('La Contraseña nueva es requerida')
      .optional().notOneOf([Yup.ref('oldPassword')], 'La nueva contraseña debe ser diferente')
      .min(1, 'La Contraseña nueva es requerida'),
    checkPassword: Yup.string()
      .required('La Verificacion de Contraseña es requerida')
      .optional().oneOf([Yup.ref('newPassword')], 'Las contraseñas no coinciden')
      .min(1, 'La Verificacion de Contraseña es requerida'),
  })
});
