/* eslint-disable max-len */
import { useState } from 'react';
import { actualizarEmpleado, postImagen } from '../../services';
import { monthDayYearFormat } from '../../../../helpers/dates';

const EmployeeInfoDocs = ({ data, refressEv, eventMessage }) => {
  // eslint-disable-next-line no-unused-vars
  const [fileI, setfFleI] = useState(null);

  /* const formatDateTime = (date) => {
    try {
      const newDate = new Date(date);
      const nowDate = new Date();
      const diffTime = Math.abs(nowDate - newDate);
      const timeDiffH = Math.ceil(diffTime / (1000 * 60 * 60));
      if (timeDiffH < 24) {
        if (timeDiffH < 2) {
          return 'Hace unos minutos.';
        }
        return `Hace ${timeDiffH} horas.`;
      }

      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${meses[newDate.getMonth()]} ${tempDate.substr(tempDate.indexOf(' ') + 1)}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  };
  */
  const getFile = async (e, typeFile) => {
    if (e.target.files) {
      setfFleI(e.target.files[0]);
      const tmepPath = `${data._id}/docs`;
      await postImagen(e.target.files[0], tmepPath).then(async (resp) => {
        if (resp.ok) {
          const tempUpdateFile = `{"${typeFile}": {
              "url_${typeFile}": "${resp.file}",
              "fecha_upd": "${new Date().getTime()}"}}`;
          await actualizarEmpleado(data._id, JSON.parse(tempUpdateFile)).then((respEmp) => {
            if (respEmp) {
              refressEv();
              eventMessage('Es archivo fue subido correctamente');
            } else {
              eventMessage('Es archivo no pudo ser enviado');
            }
          });
        }
      });
    } else {
      setfFleI(null);
    }
  };
  const downloadFile = (routeFile) => {
    try {
      fetch(routeFile, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/pdf',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          let filename = routeFile.substring(routeFile.lastIndexOf('/') + 1);
          link.setAttribute(
            'download',
            filename,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    } catch (error) {
      eventMessage('Es archivo es invalido');
    }
  };

  return (
    <div className="container">
      <div className="row">
        <br />
        <br />
        <br />
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-2"> </div>
        <div className="col-12 col-md-12 col-lg-8">
          {data && (
            <div className="row">
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">INE</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.ine && data.ine.fecha_upd && <label className="text-name">{monthDayYearFormat(data.ine.fecha_upd)}</label>}
                  {data && !data.ine && <label className="text-name">-</label>}
                </div>
                {
                  data && data.ine && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.ine.url_ine} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.ine.url_ine)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="INE_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'ine')}
                          />
                          <label className="input-file-light-label" htmlFor="INE_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.ine && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="INE_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'ine')}
                        />
                        <label className="input-file-dark-label" htmlFor="INE_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">Comprobante de domicilio</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.comprobante_domicilio && data.comprobante_domicilio.fecha_upd && <label className="text-name">{monthDayYearFormat(data.comprobante_domicilio.fecha_upd)}</label>}
                  {data && !data.comprobante_domicilio && <label className="text-name">-</label>}
                </div>
                {
                  data && data.comprobante_domicilio && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.comprobante_domicilio.url_comprobante_domicilio} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.comprobante_domicilio.url_comprobante_domicilio)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="comprobante_domicilio_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'comprobante_domicilio')}
                          />
                          <label className="input-file-light-label" htmlFor="comprobante_domicilio_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.comprobante_domicilio && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="comprobante_domicilio_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'comprobante_domicilio')}
                        />
                        <label className="input-file-dark-label" htmlFor="comprobante_domicilio_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">CIF</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.cif && data.cif.fecha_upd && <label className="text-name">{monthDayYearFormat(data.cif.fecha_upd)}</label>}
                  {data && !data.cif && <label className="text-name">-</label>}
                </div>
                {
                  data && data.cif && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.cif.url_cif} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.cif.url_cif)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="cif_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'cif')}
                          />
                          <label className="input-file-light-label" htmlFor="cif_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.cif && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="cif_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'cif')}
                        />
                        <label className="input-file-dark-label" htmlFor="cif_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">Currículum</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.curriculum && data.curriculum.fecha_upd && <label className="text-name">{monthDayYearFormat(data.curriculum.fecha_upd)}</label>}
                  {data && !data.curriculum && <label className="text-name">-</label>}
                </div>
                {
                  data && data.curriculum && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.curriculum.url_curriculum} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.curriculum.url_curriculum)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="curriculum_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'curriculum')}
                          />
                          <label className="input-file-light-label" htmlFor="curriculum_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.curriculum && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="curriculum_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'curriculum')}
                        />
                        <label className="input-file-dark-label" htmlFor="curriculum_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">Solicitud de empleo</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.solicitudEmpleo && data.solicitudEmpleo.fecha_upd && <label className="text-name">{monthDayYearFormat(data.solicitudEmpleo.fecha_upd)}</label>}
                  {data && !data.solicitudEmpleo && <label className="text-name">-</label>}
                </div>
                {
                  data && data.solicitudEmpleo && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.solicitudEmpleo.url_solicitudEmpleo} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.solicitudEmpleo.url_solicitudEmpleo)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="solicitudEmpleo_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'solicitudEmpleo')}
                          />
                          <label className="input-file-light-label" htmlFor="solicitudEmpleo_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.solicitudEmpleo && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="solicitudEmpleo_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'solicitudEmpleo')}
                        />
                        <label className="input-file-dark-label" htmlFor="solicitudEmpleo_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">Licencia de conducir</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.licencia && data.licencia.fecha_upd && <label className="text-name">{monthDayYearFormat(data.licencia.fecha_upd)}</label>}
                  {data && !data.licencia && <label className="text-name">-</label>}
                </div>
                {
                  data && data.licencia && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.licencia.url_licencia} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.licencia.url_licencia)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="licencia_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'licencia')}
                          />
                          <label className="input-file-light-label" htmlFor="licencia_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.licencia && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="licencia_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'licencia')}
                        />
                        <label className="input-file-dark-label" htmlFor="licencia_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
              <div className="row">
                <div className="col-6 col-md-8">
                  <label className="text-name">Antecedentes</label>
                  <label className="text-email">Última modificación</label>
                  {data && data.antecedentes && data.antecedentes.fecha_upd && <label className="text-name">{monthDayYearFormat(data.antecedentes.fecha_upd)}</label>}
                  {data && !data.antecedentes && <label className="text-name">-</label>}
                </div>
                {
                  data && data.antecedentes && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="row">
                        <div className="col">
                          <a href={data.antecedentes.url_antecedentes} target="_blank" download rel="noreferrer" className="link-file">
                            <button type="button" className="button-secondary " onClick={() => downloadFile(data.antecedentes.url_antecedentes)}>
                              <i className="btn-icon">&#59693;</i>
                            </button>
                          </a>
                        </div>
                        <div className="col">
                          <input
                            type="file"
                            id="antecedentes_file_upd"
                            className="input-file-light"
                            onChange={(e) => getFile(e, 'antecedentes')}
                          />
                          <label className="input-file-light-label" htmlFor="antecedentes_file_upd">
                            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                            <i className="btn-icon">&#59686;</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  data && !data.antecedentes && (
                    <div className="col-6 col-sm-6 col-md-4 cont-btn-end">
                      <div className="">
                        <input
                          type="file"
                          id="antecedentes_file"
                          className="input-file-dark"
                          onChange={(e) => getFile(e, 'antecedentes')}
                        />
                        <label className="input-file-dark-label" htmlFor="antecedentes_file">
                          &nbsp;&nbsp;Subir archivo&nbsp;&nbsp;
                        </label>
                      </div>
                    </div>
                  )
                }
                <hr className="line-spacing" />
              </div>
            </div>
          )}
        </div>
        <div className="col col-md-12 col-lg-2"> </div>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};

export default EmployeeInfoDocs;
