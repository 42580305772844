/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FrmPriorityBeneficiaries = ({
  setDataForm, nextStep, prevStep, defVal, contractData, newBenefData
}) => {
  const [listBenef, setListBenef] = useState([]);
  const [listBenefDrag, setListBenefDrag] = useState([]);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const prevStepR = () => {
    setDataForm(listBenef);
    prevStep();
  };
  const handleSubmit = () => {
    setDataForm(listBenef);
    nextStep(listBenef);
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let updatedList = [...listBenefDrag];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setListBenefDrag(updatedList);
    reorderData(updatedList);
  };

  const reorderData = (list) => {
    const newList = [];
    list.forEach((item, index) => {
      listBenef.forEach((element) => {
        const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
        if (item === tempN) {
          element.nivel = index + 1;
          newList.push(element);
        }
      });
    });
    setListBenef(newList);
  };

  const checkParentesco = (name) => {
    let tempP = '';
    listBenef.forEach((element) => {
      const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
      if (name === tempN) {
        tempP = element.parentesco;
      }
    });
    return tempP;
  };

  useEffect(() => {
    if (defVal) {
      setListBenef(defVal);
      const tempLDrag = [];
      defVal.forEach((element) => {
        const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
        tempLDrag.push(tempN);
      });
      setListBenefDrag(tempLDrag);
    }
  }, [defVal]);

  useEffect(() => {
    if (!defVal && contractData && newBenefData) {
      const tempL = [];
      const tempLDrag = [];
      contractData.forEach((element) => {
        tempL.push(element);
        const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
        tempLDrag.push(tempN);
      });
      tempL.push(newBenefData);
      const tempNN = `${newBenefData.nombre} ${newBenefData.apellidoPaterno || ''} ${newBenefData.apellidoMaterno || ''}`;
      tempLDrag.push(tempNN);
      setListBenef(tempL);
      setListBenefDrag(tempLDrag);
    }
  }, [contractData, newBenefData]);

  return (
    <div className="container">
      <div>
        <div className="mt-xxl">
          <h2>Prioridad</h2>
          <div className="content-buttom">&nbsp;</div>
          <div className="container">
            <div className="row">
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="container-fluid">
                  {(provided) => (
                    <div
                      className="container-fluid"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {listBenefDrag.map((item, index) => (
                        <Draggable
                          key={generateKey(index)}
                          draggableId={item}
                          index={index}
                        >
                          {(providedd) => (
                            <div
                              className="row content-h"
                              ref={providedd.innerRef}
                              {...providedd.dragHandleProps}
                              {...providedd.draggableProps}
                            >
                              <div className="option-tab btn-grab">
                                <div className="col-1 col-md-1">
                                  <label className="text-pos btn-grab">{index + 1}</label>
                                </div>
                                <div className="col-11 col-md-7">
                                  <label className="text-name btn-grab">
                                    {item}
                                  </label>
                                  <label className="text-email btn-grab">{checkParentesco(item)}</label>
                                </div>
                                {index === 0 ? (
                                  <div className={`col-12 col-md-3 ${index === 0 ? 'select' : ''}`}>
                                    <label className="text-indicator">{index === 0 ? 'Principal' : ''}</label>
                                  </div>
                                ) : (
                                  <div className="col-12 col-md-3"> </div>
                                )}
                                <div className={`col-12 col-md-1 ${item.nivel === 1 ? 'select' : ''}`}>
                                  <p className="button-secondary p-r btn-grab">
                                    <i className="btn-icon">&#59700;</i>
                                  </p>
                                </div>
                              </div>
                              <hr className="notif-line-spacing" />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="row mt-m">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <button className="input-label-float-right button-tertiary" onClick={prevStepR}>
                  Regresar
                </button>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <button className="button" type="submit" onClick={handleSubmit}>
                  Finalizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default FrmPriorityBeneficiaries;
