import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import ProductFeatures from './ProductFeatures';
import './styles.css';
import ProductImage from './ProductImage';
import PriceAndAvailiability from './PriceAndAvailiability';
import ProductName from './ProductName';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';
import UpdateNameForm from '../updateProduct/UpdateNameForm';
import UpdateFeaturesForm from '../updateProduct/UpdateFeaturesForm';
import UpdatePriceForm from '../updateProduct/UpdatePriceForm';
import UpdateImagesForm from '../updateProduct/UpdateImagesForm';

const ProductDetailsModal = ({ onClose, product }) => {
  const [editVisible, setEditVisible] = useState(false);
  const [selectedForm, setSelectedForm] = useState('');

  const queryClient = useQueryClient();

  const invalidateData = () => {
    queryClient.invalidateQueries(['products', 'all']);
  };

  const openEditForm = (form) => {
    setSelectedForm(form);
    setEditVisible(true);
  };

  const closeEditForm = () => {
    setSelectedForm('');
    setEditVisible(false);
  };

  const renderForm = () => {
    switch (selectedForm) {
      case 'ProductName':
        return (
          <UpdateNameForm
            initialData={product}
            onClose={closeEditForm}
            refreshData={invalidateData}
          />
        );
      case 'ProductFeatures':
        return (
          <UpdateFeaturesForm
            initialData={product}
            onClose={closeEditForm}
            refreshData={invalidateData}
          />
        );
      case 'ProductImage':
        return (
          <UpdateImagesForm
            initialData={product}
            onClose={closeEditForm}
            refreshData={invalidateData}
          />
        );
      case 'PriceAndAvailiability':
        return (
          <UpdatePriceForm
            initialData={product}
            onClose={closeEditForm}
            refreshData={invalidateData}
          />
        );
      default:
        return <></>;
    }
  };

  const allowEdit = checkPermisionRoute('Productos', 'actualizarProducto');

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={onClose}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-lg-8 col-xl-6">
            <div className="content-buttom">&nbsp;</div>
            {
              editVisible ? renderForm() : (
                <>
                  <ProductName
                    allowEdit={allowEdit}
                    data={product}
                    openEditForm={openEditForm}
                  />
                  <ProductFeatures
                    allowEdit={allowEdit}
                    data={product.caracteristicas || []}
                    openEditForm={openEditForm}
                  />
                  <ProductImage
                    allowEdit={allowEdit}
                    coverImg={product.imagen ? product.imagen[0] : null}
                    imagesArray={product.galeria}
                    openEditForm={openEditForm}
                  />
                  <PriceAndAvailiability
                    allowEdit={allowEdit}
                    data={product}
                    openEditForm={openEditForm}
                  />
                </>
              )
            }
            <div className="content-buttom">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModal;
