/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useState } from 'react';
import parse from 'html-react-parser';
import { addProductPros } from './index';
import ImageDef from '../../../products/imagedefault/imagedefault';
import proddef from '../../../../images/imageProDef.png';

const TabProdProsP = (
  {
    data,
    datap,
    eventCP,
    eventShowMessage,
    refresh
  }
) => {
  const [counter, setCounter] = useState(1);
  const [idPros, setIdPros] = useState(null);
  const [cont, setCont] = useState(false);
  const [selectedP, setSelectedP] = useState(null);

  const handleSubmit = async () => {
    let repetido = false;
    const productsAnt = [];
    if (datap && datap.productos && datap.productos.length > 0) {
      datap.productos.forEach((itemP) => {
        const tempDataP = {
          idProducto: itemP.idProducto,
          cantidad: itemP.cantidad,
          disponibilidad: itemP.disponibilidad || 'prevision',
        };
        productsAnt.push(tempDataP);
        if (itemP.idProducto === idPros) {
          repetido = true;
        }
      });
      if (!repetido) {
        const tempData = {
          idProducto: idPros,
          cantidad: counter,
          disponibilidad: 'prevision',
        };
        productsAnt.push(tempData);
      }
    } else {
      const tempData = {
        idProducto: idPros,
        cantidad: counter,
        disponibilidad: 'prevision',
      };
      productsAnt.push(tempData);
    }
    const finalP = {
      productos: productsAnt
    };
    if (!repetido) {
      await addProductPros(datap._id, finalP).then((resp) => {
        if (resp && !resp.errors) {
          eventCP(false);
          refresh();
          eventShowMessage('Producto agregado');
        } else {
          eventShowMessage('No se pudo agregar, intentelo más tarde');
        }
      });
    } else {
      refresh();
      eventCP(false);
      eventShowMessage('producto repetido');
    }
  };
  const selectPros = async (idP) => {
    setCont(true);
    setCounter(1);
    setIdPros(idP);
    setSelectedP(idP);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };

  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  return (
    <div className="row _tab-list">
      <div className="col-12">
        <div className="list-prod-pros">
          {
            data && data.length > 0 && data.map((item) => (
              <div className="row" onClick={() => selectPros(item._id)} key={item._id}>
                <div className="row row-cursor">
                  <div className={selectedP && selectedP === item._id ? 'option-select' : 'option-tab'}>
                    <div className="col-3 col-md-4">
                      <ImageDef
                        name={item.nombre}
                        size="md"
                        imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : proddef}
                      />
                    </div>
                    <div className="col-6 col-md-7">
                      <label className="text-nameP">{item.nombre}</label>
                      <label className="text-type">
                        {
                          !item?.descuentos?.prevision?.descuentoPrevision
                            ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                            : (
                              <>
                                <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.prevision.descuentoPrevision))}</label>
                              </>
                            )
                        }
                      </label>
                    </div>
                  </div>
                  <div className="line-spacing" />
                </div>
              </div>
            ))
          }
        </div>
        <div className="row">
          <div className="col-12">
            <div className="col-12 col-md-12">
              &nbsp;
            </div>
            {cont && counter > 0 && (
              <div className="container_number">
                <button
                  id="decrement"
                  className="button-number"
                  onClick={() => setCounter(counter - 1)}
                  disabled={counter <= 1}
                >
                  -
                </button>
                <span
                  className="input-number"
                >
                  {counter}
                </span>
                <button
                  id="increment"
                  className="button-number"
                  onClick={() => setCounter(counter + 1)}
                >
                  +
                </button>
              </div>
            )}
            <br />
            {cont && counter > 0 && (
              <div className="col-12 col-md-12">
                <button className="button btn-nav-r btn-full-prd" type="submit" onClick={handleSubmit}>
                  Agregar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TabProdProsP;
