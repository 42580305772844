import React, { useState } from 'react';
import { useDiscountForm } from '../../formik/discountForm';

const RegisterDiscountModal = ({ onClose, onSubmit, isSubmitting }) => {
  const [submitted, setSubmitted] = useState(false);

  const today = new Date();
  const initialDate = today.toISOString().split('T')[0];

  const {
    values,
    isValid,
    handleChange,
    errors,
  } = useDiscountForm({});

  const {
    nombre,
    porcentaje,
    fecha_inicio,
    fecha_expiracion,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    setSubmitted(true);
    onSubmit(values);
  };

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={onClose}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-lg-8 col-xl-6 ">
            <div className="content-buttom">&nbsp;</div>
            <div className="d-flex justify-content-between">
              <h2>Crear promoción</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="nombre" className="label mt-m">
                      Título
                      <input
                        onChange={handleChange('nombre')}
                        value={nombre}
                        id="nombre"
                        type="text"
                        className={`input ${submitted && errors.nombre && 'has-error'}`}
                      />
                      {submitted && errors.nombre && (
                        <small className="caption-error">
                          {errors.nombre}
                        </small>
                      )}
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="porcentaje" className="label mt-m">
                      Descuento
                      <input
                        onChange={handleChange('porcentaje')}
                        value={porcentaje}
                        id="porcentaje"
                        type="text"
                        maxLength="10"
                        placeholder="%"
                        className={`input ${submitted && errors.porcentaje && 'has-error'}`}
                      />
                      {submitted && errors.porcentaje && (
                        <small className="caption-error">
                          {errors.porcentaje}
                        </small>
                      )}
                    </label>
                  </div>

                </div>
                <div className="row mt-xxl">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <h2>Duración</h2>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="nombre" className="label mt-m">
                      Comienza
                      <input
                        onChange={handleChange('fecha_inicio')}
                        value={fecha_inicio}
                        id="fechaInicio"
                        type="date"
                        min={initialDate}
                        className={`input ${submitted && errors.fecha_inicio && 'has-error'}`}
                      />
                      {submitted && errors.fecha_inicio && (
                        <small className="caption-error">
                          {errors.fecha_inicio}
                        </small>
                      )}
                    </label>
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="nombre" className="label mt-m">
                      Termina
                      <input
                        onChange={handleChange('fecha_expiracion')}
                        value={fecha_expiracion}
                        id="fechaExpiracion"
                        type="date"
                        min={initialDate}
                        className={`input ${submitted && errors.fecha_expiracion && 'has-error'}`}
                      />
                      {submitted && errors.fecha_expiracion && (
                        <small className="caption-error">
                          {errors.fecha_expiracion}
                        </small>
                      )}
                    </label>
                  </div>
                </div>
                <div className="row mt-m">
                  <div className="col-12">
                    <button
                      type="button"
                      className="button-secondary p-r m-btn-prof"
                      onClick={onClose}
                    >
                      Cancelar
                    </button>
                    <button
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      className="button"
                    >
                      Guardar cambios
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDiscountModal;
