// import parse from 'html-react-parser';
import '../profile.css';

const PasswordP = ({ data, eventV }) => (
  <div className="col-12 col-md-12 col-lg-8">
    <div className="row">
      <div className="col-12 col-md-4 margin-filter">
        <h2>Contraseña</h2>
      </div>
      <div className="col-12 col-md-12 col-lg-8 ">
        <div className="content-btns">
          <button className="button-secondary p-r" onClick={() => eventV(true)}>
            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
            <i className="btn-icon">&#59686;</i>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div className="row">
          <div className="col-12 col-md-12">
            <label className="datos-title">Última modificación</label>
            <label className="datos-info">{data.updatedPassAt || '-'}</label>
          </div>
        </div>
      </div>
      <hr className="prof-hr" />
    </div>
    <div className="content-buttom">&nbsp;</div>
  </div>
);

export default PasswordP;
