import { useQuery } from 'react-query';
import { getContractsList, getLatestContract } from '../services';

export const useContractPerPage = ({
  order,
  estatus,
  paginacion = 1,
  paginas = 10,
  noContrato,
  sinCobrador,
  onSuccess,
  onError,
}) => useQuery(
  ['contracts', 'perPage', {
    paginacion, paginas, noContrato, order, estatus, sinCobrador
  }],
  () => getContractsList({
    orden: order,
    paginas,
    paginacion,
    noContrato,
    estatus,
    sinCobrador,
  }),
  {
    placeholderData: null,
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);

export const useLatestContract = () => useQuery(
  ['contracts', 'latest'],
  () => getLatestContract(),
  {
    placeholderData: [],
  }
);
