import { useNavigate } from 'react-router-dom';
import '../../prospects.css';

const ResponseCreate = ({
  optionResp, message, link
}) => {
  const navigate = useNavigate();
  const closeModal = () => {
    if (link) {
      navigate(link);
    } else {
      navigate('/prospects');
    }
    window.location.reload(false);
  };

  return (
    <div className="row ">
      <div className="cont-resp-add">
        {optionResp === 'success' && (
          <>
            <div className="col-12 cont-icon-resp success">
              <label className="icon-info-resp success">&#59662;</label>
            </div>
            <div className="col-12 col-md-12 cont-center">
              <h2>Prospecto registrado</h2>
            </div>
            <div className="col col-md-4">&nbsp;</div>
            <div className="col-12 col-md-12 cont-center">
              <label className="text-info">El prospecto ha sido registrado exitosamente.</label>
            </div>
            <div className="col col-md-4">&nbsp;</div>
            <div className="col-12 col-md-12 cont-center">
              <button className="button button-rep" onClick={closeModal}>
                Salir
              </button>
            </div>
          </>
        )}
        {optionResp === 'error' && (
          <>
            <div className="col-12 cont-icon-resp error">
              <label className="icon-info-resp error">&#59668;</label>
            </div>
            <div className="col-12 col-md-12 cont-center">
              <h2>{!message ? 'Prospecto no registrado' : message}</h2>
            </div>
            <div className="col col-md-4">&nbsp;</div>
            <div className="col-12 col-md-12 cont-center">
              <label className="text-info">{!message ? 'El prospecto no ha podido ser registrado, intente nuevamente más tarde.' : ''}</label>
            </div>
            <div className="col col-md-4">&nbsp;</div>
            <div className="col-12 col-md-12 cont-center">
              <button className="button button-rep" onClick={closeModal}>
                Salir
              </button>
            </div>
          </>
        )}
        {optionResp === 'loading' && (
          <>
            <div className="col-12 cont-icon-resp success">
              <label className="icon-info-resp rotate">&#59675;</label>
            </div>
            <div className="col-12 col-md-12 cont-center">
              <h2>Procesando los datos...</h2>
            </div>
            <div className="col col-md-4">&nbsp;</div>
            <div className="col col-md-4">&nbsp;</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ResponseCreate;
