import Accordion from 'react-bootstrap/Accordion';

const ReferencesContract = ({
  data, eventEd, contrato, permissions,
}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-12 col-lg-12 m-accordion">

        <Accordion defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Referencia principal
            </Accordion.Header>
            {((permissions('Contratos', 'editarReferenciasContrato') && contrato && (contrato.estatus_contrato !== 'firmado' && contrato.estatus_contrato !== 'liquidado')) || permissions('Contratos', 'editAnyContract')) && (
              <button type="button" onClick={() => eventEd(0)} className="button-secondary btn-accordion">
                &nbsp;&nbsp;Editar
                <i className="btn-icon">&#59686;</i>
                &nbsp;&nbsp;
              </button>
            )}
            <Accordion.Body>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="datos-title">Nombre completo</label>
                  <label className="datos-info text-capitalize">
                    {data[0] ? data[0].nombre : ''}
                    {' '}
                    {data[0] ? data[0].apellidoPaterno : ''}
                    {' '}
                    {data[0] ? data[0].apellidoMaterno : ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">&nbsp;</label>
                  <label className="datos-info">&nbsp;</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Teléfono</label>
                  <label className="datos-info">{data[0] && data[0].telefono ? data[0].telefono : '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Correo electrónico</label>
                  <label className="datos-info">{data[0] && data[0].email ? data[0].email : '-'}</label>
                </div>
                <div className="col-12 col-md-12">
                  <h2 className="sub-title-accordion">Domicilio</h2>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Ubicación</label>
                  <label className="datos-info">
                    {data[0] && data[0].ciudad ? data[0].ciudad : ''}
                    {', '}
                    {data[0] && data[0].estado ? data[0].estado : ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Código postal</label>
                  <label className="datos-info">{data[0] && data[0].cp ? data[0].cp : '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Dirección</label>
                  <label className="datos-info">
                    {data[0] && data[0].direccion ? data[0].direccion : '-'}
                    {' '}
                    {data[0] && data[0].numInterior ? data[0].numInterior : ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Colonia</label>
                  <label className="datos-info">{data[0] && data[0].colonia ? data[0].colonia : '-'}</label>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Referencia secundaria
            </Accordion.Header>
            {((permissions('Contratos', 'editarReferenciasContrato') && contrato && (contrato.estatus_contrato !== 'firmado' && contrato.estatus_contrato !== 'liquidado')) || permissions('Contratos', 'editAnyContract')) && (
              <button type="button" onClick={() => eventEd(1)} className="button-secondary btn-accordion">
                &nbsp;&nbsp;Editar
                <i className="btn-icon">&#59686;</i>
                &nbsp;&nbsp;
              </button>
            )}
            <Accordion.Body>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="datos-title">Nombre completo</label>
                  <label className="datos-info text-capitalize">
                    {data[1] ? data[1].nombre : ''}
                    {' '}
                    {data[1] ? data[1].apellidoPaterno : ''}
                    {' '}
                    {data[1] ? data[1].apellidoMaterno : ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">&nbsp;</label>
                  <label className="datos-info">&nbsp;</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Teléfono</label>
                  <label className="datos-info">{data[1] && data[1].telefono ? data[1].telefono : '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Correo electrónico</label>
                  <label className="datos-info">{data[1] && data[1].email ? data[1].email : '-'}</label>
                </div>
                <div className="col-12 col-md-12">
                  <h2 className="sub-title-accordion">Domicilio</h2>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Ubicación</label>
                  <label className="datos-info">
                    {data[1] ? data[1].ciudad : ''}
                    {', '}
                    {data[1] ? data[1].estado : ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Código postal</label>
                  <label className="datos-info">{data[1] && data[1].cp ? data[1].cp : '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Dirección</label>
                  <label className="datos-info">
                    {data[1] ? data[1].direccion : '-'}
                    {' '}
                    {data[1] ? data[1].numInterior : ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Colonia</label>
                  <label className="datos-info">{data[1] && data[1].colonia ? data[1].colonia : '-'}</label>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      </div>
    </div>
  </div>
);

export default ReferencesContract;
