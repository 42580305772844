/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import TabNotificatios from './components/tabNotificatios';
import { markAllAsRead, markAsRead } from './services/index';
import { AppContext } from '../context/AppContext';
import './noyification.css';
import { useMyNotifications } from './hooks/queries';

const ListNotifications = () => {
  const navigate = useNavigate();
  const [dropDown2, setDropDown2] = useState(false);
  const [page, setPage] = useState(1);
  const [orderNotif, setOrderNotif] = useState('asc');
  const [loading, setLoading] = useState(false);
  const { appData, setAppData } = useContext(AppContext);
  const perPage = 10;

  const openDrop2 = () => {
    setDropDown2(!dropDown2);
  };
  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const { data } = useMyNotifications({
    page,
    pagination: perPage,
    order: orderNotif,
  });

  const { data: notifications, total, noRead } = data;
  const totalPages = Math.ceil(total / perPage);
  const activeL = page > 1;
  const activeR = page < totalPages;

  const queryClient = useQueryClient();

  const invalidateData = () => {
    queryClient.invalidateQueries(['notifications', 'all']);
  };

  // const getMyNotif = async () => {
  //   const tempOpt = `page=${page}&pagination=10&order=${orderNotif}`;
  //   const myNotif = await getMyNotifications(tempOpt);
  //   if (myNotif && myNotif.data && myNotif.data.length > 0) {
  //     // setNotifications(myNotif.data);
  //     const tempT = Math.ceil(myNotif.total / 10);
  //     setTotalPages(tempT);
  //     getPaginado(myNotif.total || 0);
  //   }
  // };

  const setAllNotifsAsRead = async () => {
    setLoading(true);
    const resp = await markAllAsRead();
    if (resp.errors) {
      toast('Ha ocurrido un error. Intente de nuevo más tarde.', {
        className: 'message error',
      });
    }
    toast('Se han actualizado las notificaciones', {
      className: 'message',
    });
    invalidateData();
    setLoading(false);
  };

  const changeOrder = () => {
    if (orderNotif === 'asc') {
      setOrderNotif('desc');
    } else {
      setOrderNotif('asc');
    }
  };

  const markReadNotif = async (id) => {
    const optionNotf = {
      notifications: [id]
    };
    const myNotif = await markAsRead(optionNotf);
    if (myNotif && myNotif.ok) {
      invalidateData();
      setAppData({
        ...appData,
        readNotif: id,
      });
    }
  };

  const checkNotification = async (item) => {
    const optionNotf = {
      notifications: [item._id]
    };
    if (!item.read) {
      await markAsRead(optionNotf);
    }
    if (item?.link !== '') {
      navigate(item.link);
    }
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-6 col-md-6 margin-filter">
          <h2>Notificaciones</h2>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <button
            onClick={setAllNotifsAsRead}
            className="button"
            disabled={loading || noRead === 1313}
          >
            {loading ? 'Cargando...' : 'Marcar todas como leídas'}
          </button>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter"> </div>
        <div className="col-12 margin-filter">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <label>{total || 0} resultado{total !== 1 ? 's' : ''}</label>
        </div>
        <div className="col-12 col-md-6 margin-filter">
          <button className="button-secondary btn-p-cl">
            &nbsp;&nbsp;Filtro&nbsp;
            <i className="btn-icon">&#59674;</i>
            &nbsp;&nbsp;
          </button>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter content-on">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <button onClick={openDrop2} className="button-secondary btn-p-cl p-r">
            &nbsp;&nbsp;
            {orderNotif === 'asc' ? 'Más recientes' : 'Más antiguos'}
            &nbsp;
            <i className="btn-icon">&#59657;</i>
            &nbsp;&nbsp;
            {dropDown2 && (
              <div className="drop-menu">
                <label onClick={changeOrder}>
                  {orderNotif !== 'asc' ? 'Más recientes' : 'Más antiguos'}
                </label>
              </div>
            )}
          </button>
        </div>
      </div>
      {notifications && (
        <TabNotificatios
          data={notifications}
          metodRead={markReadNotif}
          checkNotification={checkNotification}
        />
      )}
      {notifications && (
        <div className="row m-t-l">
          <div className="col-12 col-md-12">
            <div className="row">
              <div className="col-12 col-md-6">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <label className="text-pag">{page} de {totalPages}</label>
              </div>
              <div className="col-12 col-md-6 pag-content-btn">
                <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
                <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ListNotifications;
