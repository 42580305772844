import Message from '../../../shared/message/Message';

const StatusFormBeneficiaries = ({
  type,
  title,
  content,
  message,
  onConfirm,
  confirmText,
}) => (
  <div>
    <div className="content-buttom">&nbsp;</div>
    <div className="content-buttom">&nbsp;</div>
    {type === 'loading' && (
    <Message
      type={type}
      title={title}
      content={content}
      message={message}
      onConfirm={null}
      confirmText={confirmText}
    />
    )}
    {type !== 'loading' && (
    <Message
      type={type}
      title={title}
      content={content}
      message={message}
      onConfirm={onConfirm}
      confirmText={confirmText}
    />
    )}
    <div className="content-buttom">&nbsp;</div>
  </div>
);

export default StatusFormBeneficiaries;
