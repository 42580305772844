/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable quotes */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TabCLients from './components/listClients/tabClientes';
import NoClients from './components/listClients/noClients';
import NoFoundClients from './components/listClients/notFountClients';
// import Config from '../../global/constants';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import './client.css';
import { useClientsPerPage } from './hooks/queries';
import { useSucursalsPerPage } from '../branchOffice/hooks/queries';

const ListClients = () => {
  const navigate = useNavigate();
  const [selectSucursal, setSelectSucursal] = useState(null);
  const [searchInp, setSearchInp] = useState('');
  const [page, setPage] = useState(1);

  const [dropDown1, setDropDown1] = useState(false);
  const [listSucursal, setListSucursal] = useState(false);
  const [orderFecha, setOrderFecha] = useState('reciente');
  const [orderList, setOrderList] = useState('asc');
  const [selectedOption, setSelectedOption] = useState('A a Z');
  const perPage = 10;

  const { data: sucursals } = useSucursalsPerPage({});
  const { data: queryData } = useClientsPerPage({
    page,
    perPage,
    location: selectSucursal,
    antiguedad: orderFecha,
    search: searchInp,
    order: orderList,
  });

  const total = queryData?.total;
  const clientsList = queryData?.data;
  const totalPages = Math.ceil(total / perPage);
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const searchEnabled = searchInp.length > 0 || selectSucursal !== null;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const openDrop1 = () => {
    setDropDown1(!dropDown1);
  };

  const listSucursalF = () => {
    setListSucursal(!listSucursal);
  };
  const changeOrderList = (type, value) => {
    if (type === 'orderList') {
      setOrderList(orderList === 'asc' ? 'desc' : 'asc');
      setOrderFecha(0);
      setSelectedOption(orderList === 'asc' ? 'Z a A' : 'A a Z');
    } else if (type === 'orderFecha') {
      setOrderFecha(value);
      setOrderList(0);
      setSelectedOption(value === 'reciente' ? 'Mas Recientes' : 'Mas Antiguos');
    }
  };

  const searchClients = (e) => {
    setSearchInp(e.target.value);
  };

  const addClientN = () => {
    navigate('/add-clients');
  };

  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      const token = localStorage.getItem('token').toString();
      if (!token) {
        closeSession();
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, [page, searchInp, orderList, selectSucursal]);
  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, []);

  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp]);

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <h2>Clientes</h2>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter">
          {false && (
            <button className="button p-r" onClick={openDrop1}>
              &nbsp;&nbsp;Vincular cliente&nbsp;&nbsp;
              {dropDown1 && checkPermisionRoute('Clientes', 'crearCliente') && (
                <div className="drop-menu">
                  <label onClick={addClientN}>
                    <i className="icon">&#59672;</i>
                    Nuevo
                  </label>
                  <label>
                    <i className="icon">&#59673;</i>
                    Existente
                  </label>
                </div>
              )}
            </button>
          )}
        </div>
        <div className="col-12 margin-filter">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <label>{total || 0} resultados</label>
        </div>
        <div className="col-12 col-md-4 margin-filter">
          <div className="col-12 col-md-12">
            {checkPermisionRoute('Clientes', 'consultarCliente') && (
              <div className="input-container">
                <input id="buscar" onChange={(e) => searchClients(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                <span className="input-icon search-line" />
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-md-4 margin-filter content-on">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <button className="button-secondary btn-p-cl p-r" onClick={listSucursalF}>
            &nbsp;&nbsp;Filtro&nbsp;
            <i className="btn-icon">&#59674;</i>
            &nbsp;&nbsp;
            {listSucursal && (
              <div className="drop-menu lg" onMouseLeave={() => setListSucursal(false)}>
                <label onClick={() => setSelectSucursal(null)}>
                  Todas
                </label>
                {
                  sucursals && sucursals.map((item) => (
                    <label onClick={() => setSelectSucursal(item.ciudad)} key={item._id}>
                      {item.nombre}
                    </label>
                  ))
                }
              </div>
            )}
          </button>
        </div>
        <div className="col-12 col-md-4 btn-content-filt margin-filter content-on">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <button onClick={openDrop1} className="button-secondary btn-p-cl p-r">
            &nbsp;&nbsp;
            {selectedOption}
            &nbsp;
            <i className="btn-icon">&#59657;</i>
            &nbsp;&nbsp;
            {dropDown1 && (
              <div className="drop-menu">
                {selectedOption !== "Z a A" && (
                  <label onClick={() => changeOrderList("orderList", "desc")}>
                    Z a A
                  </label>
                )}
                {selectedOption !== "A a Z" && (
                  <label onClick={() => changeOrderList("orderList", "asc")}>
                    A a Z
                  </label>
                )}
                {selectedOption !== "Mas Antiguos" && (
                  <label
                    onClick={() => changeOrderList("orderFecha", "antiguo")}
                  >
                    Mas Antiguos
                  </label>
                )}
                {selectedOption !== "Mas Recientes" && (
                  <label
                    onClick={() => changeOrderList("orderFecha", "reciente")}
                  >
                    Mas Recientes
                  </label>
                )}
              </div>
            )}
          </button>
        </div>
        <div className="col col-md-1"> </div>
      </div>
      {clientsList && <TabCLients data={clientsList} permision={checkPermisionRoute} />}
      {total === 0 && !searchEnabled && <NoClients />}
      {total === 0 && searchEnabled && <NoFoundClients />}
      {total > 0 && (
        <div className="row m-t-l">
          <div className="col-12 col-md-6">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <label className="text-pag">{page} de {totalPages}</label>
          </div>
          <div className="col-12 col-md-6 pag-content-btn">
            <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
            <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListClients;
