/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Switch from 'react-switch';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import pagoMeses from '../../../../global/data/pagoMeses.json';
import { useQuotationFormik } from './formik';
import Toggle from '../../../shared/toggle/Toggle';
import Message from '../../../shared/message/Message';
import {
  createPropspects, downloadQuotationFile, sendQuotationFile, updateProspectsById
} from '../../servives';

const AddProspectQuotationModal = ({
  onClose,
  total,
  products,
  onFinish,
  sucursal,
  prospect,
  refreshProspectData,
}) => {
  const {
    values,
    errors,
    isValid,
    setFieldValue,
  } = useQuotationFormik({
    total,
    email: prospect ? prospect.email : '',
    envioCotizacion: !!prospect,
    nombre: prospect ? prospect.nombre : '',
  });

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [registeredProspect, setRegisteredProspect] = useState(null);
  const [isDownloading, setDownloading] = useState(false);

  const {
    anticipo,
    mensualidad,
    tipoPago,
    totalAproximado,
    nombre,
    email,
    envioCotizacion
  } = values;

  const getProductPrice = (product) => {
    if (product._Disponibilidad === 'inmediata') {
      return (product?.descuentos?.usoInmediato
        ? product.descuentos.usoInmediato.descuentoUsoInmediato : product.precio);
    }
    return (product?.descuentos?.prevision
      ? product.descuentos.prevision.descuentoPrevision : product.precioPrevision);
  };

  const prepareProductsArray = () => products.map((prod) => ({
    nombre: prod.nombre,
    tipo: 'Producto',
    urlImg: prod.imagen && prod.imagen[0] ? prod.imagen[0].url : undefined,
    cantidad: prod.Cantidad,
    disponibilidad: prod._Disponibilidad,
    precio: (prod?.descuentos?.usoInmediato || prod?.descuentos?.prevision)
      ? (prod._Disponibilidad === 'inmediata' ? prod.precio : prod.precioPrevision)
      : undefined,
    precioFinal: getProductPrice(prod),
  }));

  const downloadFile = async () => {
    try {
      setDownloading(true);
      await downloadQuotationFile({
        productos: prepareProductsArray(),
      });
      toast('Se ha descargado el archivo', {
        className: 'message',
      });
    } catch (err) {
      toast('Ha ocurrido un error, vuelve a intentarlo más tarde', {
        className: 'message error',
      });
    } finally {
      setDownloading(false);
    }
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const calcMonthlyPayment = (months, precioV, downPayment = 0) => {
    if (downPayment > total) {
      return '';
    }
    if (months && precioV) {
      try {
        const tempM = parseInt(months, 10);
        const tempMonth = (parseFloat(precioV) - parseFloat(downPayment)) / tempM;
        return formatMoney.format(tempMonth);
      } catch (_error) {
        return '$-';
      }
    }
    return '$-';
  };

  const handleSendQuotation = async () => {
    setStatus('loading');
    const today = new Date();
    const fecha = `${today.getFullYear()}-${`${today.getMonth() + 1 < 10 ? '0' : ''}${today.getMonth() + 1}`}-${`${today.getDate() < 10 ? '0' : ''}${today.getDate()}`}`;
    const hora = `${today.getHours()}:${`${today.getMinutes() < 10 ? '0' : ''}${today.getMinutes()}`}`;

    try {
      const res = await sendQuotationFile(email, {
        productos: prepareProductsArray(),
      });
      await updateProspectsById(prospect._id, {
        seguimiento: [
          ...prospect.seguimiento,
          {
            _id: undefined,
            idEmpleado: user._id,
            tipo: 'Cotización enviada',
            fecha: `${fecha} ${hora} GMT`,
            estatus: 'abierto',
          }
        ]
      });
      if (res.errors) {
        throw res.errors;
      } else {
        onClose();
        refreshProspectData();
        toast('Cotización enviada', {
          className: 'message',
        });
      }
    } catch (err) {
      toast('Ha ocurrido un error, vuelve a intentarlo más tarde', {
        className: 'message error',
      });
    } finally {
      setStatus('idle');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (prospect) {
      return handleSendQuotation();
    }
    try {
      setSubmitted(true);
      const today = new Date();
      const utcDate = new Date(today.getTime());
      const prospectData = {
        nombre,
        email: email || undefined,
        productos: products.map((prod) => ({
          idProducto: prod._id,
          cantidad: prod.Cantidad,
          disponibilidad: prod._Disponibilidad,
        })),
        empleados: {
          idEmpleado: user._id,
        },
        sucursales: {
          idSucursal: sucursal._id,
        },
        seguimiento: envioCotizacion ? [
          {
            idEmpleado: user._id,
            tipo: 'Cotización enviada',
            fecha: utcDate,
            estatus: 'abierto',
          }
        ] : undefined
      };
      if (!isValid) {
        return '';
      }
      setStatus('loading');
      const createP = await createPropspects(prospectData);
      if (!createP._id) {
        return setStatus('error');
      }
      if (envioCotizacion) {
        await sendQuotationFile(email, {
          productos: prepareProductsArray(),
        });
      }
      setStatus('success');
      queryClient.invalidateQueries(['prospects', 'all']);
      setRegisteredProspect(createP);
      return '';
    } catch (err) {
      return '';
    }
  };

  const navigateToDetailsForm = () => {
    navigate(`/complete-prospect/${registeredProspect._id}`);
  };

  useEffect(() => {
    if (tipoPago === 'Contado') {
      setFieldValue('mensualidad', '');
    }
  }, [tipoPago]);

  useEffect(() => {
    if (anticipo > total) {
      setFieldValue('mensualidad', '');
    }
  }, [anticipo]);

  const showMonthlyPayments = !errors.anticipo && anticipo < total;

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          {status === 'idle' && (
            <span
              className="icon-close cross-line"
              onClick={onClose}
            >
              {' '}
            </span>
          )}
        </div>
      </div>
      <div className="container">
        <div
          style={{ display: status !== 'idle' ? 'flex' : 'none' }}
          className="d-flex justify-content-center"
        >
          <div className="col-12 col-lg-8 col-xl-6 ">
            {status === 'loading' && (
              <div className="message-container">
                <Message
                  type="loading"
                  title="Cargando..."
                  content="Esta operación podría demorar algunos minutos. Por favor, espere."
                />
              </div>
            )}
            {status === 'success' && (
              <div className="message-container">
                <Message
                  type="success"
                  title="Cotización registrada"
                  content="La cotización del prospecto ha sido enviada y registrada exitosamente. Si lo deseas, puedes continuar completando los datos del prospecto o finalizar con los datos capturados."
                  onConfirm={navigateToDetailsForm}
                  confirmText="Completar datos"
                  onCancel={onFinish}
                  cancelText="Finalizar"
                />
              </div>
            )}
            {status === 'error' && (
              <div className="message-container">
                <Message
                  type="error"
                  title="Ha ocurrido un error"
                  content="No se pudo registrar la cotización. Favor de intentarlo de nuevo."
                  onConfirm={() => setStatus('idle')}
                  confirmText="Cerrar"
                />
              </div>
            )}
          </div>
        </div>

        <div
          style={{ display: status === 'idle' ? 'flex' : 'none' }}
          className="row align-items-center justify-content-center"
        >
          <div className="col-12 col-lg-8 col-xl-6 ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>Cotizar productos</h2>
            </div>
            <div>
              {products.map((product) => (
                <div className="product-container" key={product._id}>
                  <div>
                    <p className="product-title">
                      {product.nombre}
                      {' '}
                      <span className="text-secondary">
                        ({product._Disponibilidad.toUpperCase()})
                      </span>
                    </p>
                    <p className="product-subtitle">
                      {product.Cantidad}
                      {' '}
                      x
                      {' '}
                      {formatMoney.format(getProductPrice(product))}
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p className="product-total">
                      {formatMoney.format(getProductPrice(product) * product.Cantidad)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="m-t-xxl">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>Tipo de pago</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="nombre" className="label mt-m">
                      Total aproximado
                      <CurrencyInput
                        id="totalAproximado"
                        name="totalAproximado"
                        placeholder=""
                        className="input"
                        disabled
                        defaultValue={0}
                        decimalsLimit={2}
                        value={totalAproximado}
                        prefix="$"
                        pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      />
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="formaPago" className="label labels mt-m">
                      Tipo de pago
                      <br />
                      <Toggle
                        options={[
                          {
                            name: 'Mensualidades',
                            value: 'Mensualidades',
                          },
                          {
                            name: 'Contado',
                            value: 'Contado',
                          }
                        ]}
                        activeOp={tipoPago}
                        event={(e) => setFieldValue('tipoPago', e)}
                      />
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="nombre" className="label mt-m">
                      Anticipo
                      <CurrencyInput
                        id="Anticipo"
                        name="Anticipo"
                        placeholder=""
                        className="input"
                        defaultValue={0}
                        decimalsLimit={2}
                        value={anticipo}
                        prefix="$"
                        pattern="[$]+[0-9]+([\.,][0-9]+)?"
                        onValueChange={(e) => setFieldValue('anticipo', e ? parseFloat(e) : '')}
                      />
                    </label>
                    {errors && errors.anticipo && <small className="caption-error">{errors.anticipo}</small>}
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="mensualidad" className="label mt-m">
                      Mensualidades
                      <select
                        name="mensualidad"
                        id="mensualidad"
                        className={`input ${submitted && errors.mensualidad && 'has-error'}`}
                        disabled={tipoPago === 'Contado'}
                        value={mensualidad}
                        onChange={(e) => setFieldValue('mensualidad', e.target.value)}
                      >
                        <option value="">Seleccione una opción</option>
                        {showMonthlyPayments && pagoMeses.map((pago) => (
                          <option key={pago.value} value={pago.value}>
                            {pago.label}
                            {' '}
                            {calcMonthlyPayment(pago.value, totalAproximado, anticipo)}
                          </option>
                        ))}
                      </select>
                      {submitted && errors.mensualidad && (
                        <small className="caption-error">
                          {errors.mensualidad}
                        </small>
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="m-t-xxl">&nbsp;</div>
              <div className="col-12 margin-filter">
                <div className="d-flex justify-content-between">
                  <h2>{!prospect ? 'Guardar cotización' : 'Enviar a'}</h2>
                  <button
                    disabled={isDownloading}
                    onClick={downloadFile}
                    className="button-secondary"
                    type="button"
                  >
                    <span className="button-icon download-line" />
                  </button>
                </div>
              </div>

              <div className="row">
                {!prospect && (
                  <>
                    <div className="col-12 col-md-6">
                      <label htmlFor="nombre" className="labels mt-m">
                        Nombre del prospecto
                        <input
                          id="nombre"
                          type="text"
                          className={`input ${submitted && errors.nombre && 'has-error'}`}
                          value={nombre}
                          onChange={(e) => setFieldValue('nombre', e.target.value)}
                        />
                        {submitted && errors.nombre && (
                          <small className="caption-error">
                            {errors.nombre}
                          </small>
                        )}
                      </label>
                    </div>
                  </>
                )}
                <div className="col-12 col-md-6">
                  <label htmlFor="email" className="labels mt-m">
                    Correo electrónico
                    <input
                      id="email"
                      type="email"
                      className={`input ${submitted && errors.email && 'has-error'}`}
                      value={email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                    {submitted && errors.email && (
                      <small className="caption-error">
                        {errors.email}
                      </small>
                    )}
                  </label>
                </div>
              </div>

              {!prospect && (
                <div className="row prospect-message-cont m-t-l">
                  <div className="col-10 col-md-11">
                    <p>Enviar cotización al correo y/o teléfono registrados</p>
                  </div>
                  <div className="col-2 col-md-1">
                    <Switch
                      checked={envioCotizacion}
                      onChange={() => setFieldValue('envioCotizacion', !envioCotizacion)}
                      onColor="#000000"
                      onHandleColor="#FFFFFF"
                      offColor="#CFCFCF"
                      offHandleColor="#FFFFFF"
                      borderRadius={60}
                      handleDiameter={5}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={25}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
              )}
              <div className="row mt-m m-b-xxl">
                <div className="col-12">
                  <button
                    type="button"
                    className="button-secondary p-r m-btn-prof"
                    onClick={onClose}
                  >
                    Cancelar
                  </button>
                  <button
                    disabled={!isValid || (envioCotizacion && !email)}
                    type="submit"
                    className="button"
                  >
                    {!prospect ? 'Guardar' : 'Enviar'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProspectQuotationModal;
