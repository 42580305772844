import { useEffect, useState } from 'react';
import countriesData from '../../../../global/data/countries.json';
import maritalStatus from '../../../../global/data/maritalStatus.json';
import formik from '../../formik/formik';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';
import rolsJobsData from '../../../shared/routeGuards/rols&jobs.json';
import { actualizarEmpleado, findCP_S } from '../../services';
import { useSucursalsPerPage } from '../../../branchOffice/hooks/queries';

const EmployeeForm = ({
  data, eventV, refressEv, eventMessage
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = formik();

  const [localidades, setLocalidades] = useState([]);

  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    fecha_nacimiento,
    sexo,
    estado_civil,
    telefono,
    email,
    rfc,
    cp,
    estado,
    ciudad,
    colonia,
    direccion,
    entreCalle,
    role,
    puesto,
    sucursal,
  } = values;

  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [colonies, setColonies] = useState(null);
  const [rolsList, setRolsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const allowViewDetails = checkPermisionRoute('Empleados', 'actualizarPuesto');

  const allowEditSucursal = checkPermisionRoute('Empleados', 'actualizarSucursalEmpleado');

  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth()) || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      const tempData = {
        nombre: values.nombre,
        apellidoPaterno: values.apellidoPaterno,
        apellidoMaterno: values.apellidoMaterno,
        fecha_nacimiento: values.fecha_nacimiento,
        sexo: values.sexo,
        estado_civil: values.estado_civil,
        telefono: values.telefono,
        email: values.email,
        rfc: values.rfc,
        cp: values.cp,
        estado: values.estado,
        ciudad: values.ciudad,
        colonia: values.colonia,
        direccion: values.direccion,
        edad: age(new Date(values.fecha_nacimiento)) || 0,
        entreCalle: values.entreCalle,
        sucursales: {
          idSucursal: sucursal,
        },
      };
      if (allowViewDetails) {
        tempData.rol = values.rol;
        tempData.puesto = values.puesto;
      }

      try {
        await actualizarEmpleado(data._id, tempData).then((resp) => {
          if (resp && resp._id) {
            setStatus('idle');
            refressEv();
            eventV();
            eventMessage('Cambios guardados');
          } else {
            throw resp.errors;
          }
        });
      } catch (err) {
        let errorMsg = '';
        if (err.message.includes('Duplicate')) {
          errorMsg = 'El correo electrónico ya está registrado con otro empleado.';
        } else {
          errorMsg = 'Ha ocurrido un error. No se pudieron efectuar los cambios.';
        }
        eventMessage(errorMsg, 'error');
      }
    } else {
      window.scrollTo(0, 0);
      setStatus('idle');
    }
  };

  const capitalizeString = (stringP) => {
    const str_arr = stringP.split(' ');
    for (let i = 0; i < str_arr.length; i += 1) {
      if (str_arr[i].length > 3) {
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
      } else if (str_arr[i] === 'rh' || str_arr[i] === 'Recursos Humanos') {
        str_arr[i] = str_arr[i].toUpperCase();
      } else {
        str_arr[i] = str_arr[i].toLowerCase();
      }
    }
    return str_arr.join(' ');
  };

  useEffect(() => {
    if (data && data._id) {
      setFieldValue('nombre', data.nombre);
      setFieldValue('apellidoPaterno', data.apellidoPaterno);
      setFieldValue('apellidoMaterno', data.apellidoMaterno || '');
      setFieldValue('fecha_nacimiento', data.fecha_nacimiento || '');
      setFieldValue('sexo', data.sexo || '');
      setFieldValue('estado_civil', data.estado_civil || '');
      setFieldValue('telefono', data.telefono || '');
      setFieldValue('email', data.email || '');
      setFieldValue('rfc', data.rfc || '');
      setFieldValue('cp', data.cp || '');
      setFieldValue('estado', data.estado || '');
      setFieldValue('ciudad', data.ciudad || '');
      setFieldValue('colonia', data.colonia || '');
      setFieldValue('direccion', data.direccion || '');
      setFieldValue('entreCalle', data.entreCalle || '');
      setFieldValue('role', capitalizeString(data.role) || '');
      setFieldValue('puesto', capitalizeString(data.puesto) || '');
      setFieldValue('sucursal', data.sucursales.idSucursal || '');
      const arrayRol = [];
      const arrayJob = [];
      rolsJobsData?.forEach((item) => {
        item.rol = capitalizeString(item.rol);
        arrayRol.push(item.rol);
        data.role = capitalizeString(data.role);
        if (item.rol === data.role) {
          item?.puestos?.forEach((itemP) => {
            itemP.puesto = capitalizeString(itemP.puesto);
            arrayJob.push(itemP.puesto);
          });
        }
      });
      setRolsList(arrayRol);
      setJobsList(arrayJob);
    }
  }, [data]);
  useEffect(() => {
    if (!isValid || !dirty) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [values, isValid, dirty, errors]);

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((_data) => {
      if (_data && _data.respuesta && _data.respuesta.codigos_postales) {
        if (_data.respuesta.codigos_postales.length === 1) {
          _data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (_data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          _data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  const { data: branchList } = useSucursalsPerPage({
    search: '',
    order: 'asc',
  });

  useEffect(() => {
    if (cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  useEffect(() => {
    if (role && role !== '') {
      const arrayJob = [];
      rolsJobsData?.forEach((item) => {
        if (item.rol === role) {
          item?.puestos?.forEach((itemP) => {
            arrayJob.push(itemP.puesto);
          });
        }
      });
      setJobsList(arrayJob);
    }
  }, [role]);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={() => eventV(false)}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col"> </div>
          <div className="col-12 col-md-11 col-lg-6">
            <form onSubmit={handleSubmit}>
              <div className="mt-xxl">
                <h2>Datos pesonales</h2>
                <div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="nombre" className="label mt-m">
                        Nombre(s)
                        <input
                          onChange={handleChange('nombre')}
                          value={nombre}
                          id="nombre"
                          type="text"
                          className={`input ${submitted && errors.nombre && 'has-error'}`}
                        />
                        {errors?.nombre && <small className="caption-error">{errors.nombre}</small>}
                      </label>
                    </div>
                    <div className="col"> </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="apellidoPaterno" className="label mt-m">
                        Primer apellido
                        <input
                          id="apellidoPaterno"
                          type="text"
                          className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                          onChange={handleChange('apellidoPaterno')}
                          value={apellidoPaterno}
                        />
                        {
                          errors?.apellidoPaterno && <small className="caption-error">{errors.apellidoPaterno}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="apellidoMaterno" className="label mt-m">
                        Segundo apellido
                        <input
                          id="apellidoMaterno"
                          type="text"
                          className="input"
                          onChange={handleChange('apellidoMaterno')}
                          value={apellidoMaterno}
                        />
                        <label htmlFor="sample" className="label font-sm ">
                          (Opcional)
                        </label>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="fecha_nacimiento" className="label mt-m">
                        Fecha de nacimiento
                        <input
                          id="fecha_nacimiento"
                          type="date"
                          className={`input input-date ${fecha_nacimiento && !errors.fecha_nacimiento ? 'date-input--has-value' : ''} ${submitted && errors.fecha_nacimiento && 'has-error'}`}
                          onChange={handleChange('fecha_nacimiento')}
                          value={fecha_nacimiento}
                        />
                        {
                          submitted && errors.fecha_nacimiento && <small className="caption-error">{errors.fecha_nacimiento}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="label labels mt-m">
                        Género
                        <br />
                        <button type="button" onClick={() => setFieldValue('sexo', 'Hombre')} className={`btn-gender ${sexo === 'Hombre' ? 'active' : ''} genderM`}>Masculino</button>
                        <button type="button" onClick={() => setFieldValue('sexo', 'Mujer')} className={`btn-gender ${sexo === 'Mujer' ? 'active' : ''} genderF`}>Femenino</button>
                        {errors?.sexo && (
                          <small className="caption-error">
                            {errors.sexo}
                          </small>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="estado_civil" className="label mt-m">
                        Estado civil
                        <select
                          type="estado_civil"
                          value={estado_civil}
                          onChange={handleChange('estado_civil')}
                        >
                          <option>Seleccione una opción</option>
                          {maritalStatus.map((items) => (
                            <option key={items.value} name={items.value} value={items.value}>
                              {items.label}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                    <div className="col"> </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="telefono" className="label mt-m">
                        Teléfono
                        <input
                          id="telefono"
                          type="number"
                          placeholder="10 dígitos"
                          className={`input ${submitted && errors.telefono && 'has-error'}`}
                          onChange={handleChange('telefono')}
                          value={telefono}
                        />
                        {
                          submitted && errors.telefono && <small className="caption-error">{errors.telefono}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="email" className="label mt-m">
                        Correo electrónico
                        <input
                          id="email"
                          type="email"
                          className={`input ${submitted && errors.email && 'has-error'}`}
                          onChange={handleChange('email')}
                          value={email}
                        />
                        {
                          submitted && errors.email && <small className="caption-error">{errors.email}</small>
                        }
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="rfc" className="label mt-m">
                        RFC
                        <input
                          id="rfc"
                          type="text"
                          className={`input ${submitted && errors.rfc && 'has-error'}`}
                          onChange={handleChange('rfc')}
                          value={rfc}
                        />
                        {
                          submitted && errors.rfc && <small className="caption-error">{errors.rfc}</small>
                        }
                      </label>
                    </div>
                    <div className="col"> </div>
                  </div>
                </div>
              </div>

              <div className="mt-xxl">
                {(allowViewDetails || allowEditSucursal) && <h2>Rol & puesto</h2>}
                {allowViewDetails && (
                  <div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <label htmlFor="role" className="label mt-m">
                          Rol
                          <select
                            type="select"
                            name="role"
                            className={`${submitted && errors.role && 'has-error'}`}
                            value={role}
                            onChange={handleChange('role')}
                          >
                            <option value="">-Seleccione un rol-</option>
                            {rolsList.map((items) => (
                              <option key={items} name={items} value={items}>
                                {items}
                              </option>
                            ))}
                          </select>
                          {
                            submitted && errors.role && <small className="caption-error">{errors.role}</small>
                          }
                        </label>
                      </div>
                      <div className="col-12 col-md-6">
                        <label htmlFor="puesto" className="label mt-m">
                          Puesto
                          <select
                            type="select"
                            name="puesto"
                            className={`${submitted && errors.puesto && 'has-error'}`}
                            value={puesto}
                            onChange={handleChange('puesto')}
                          >
                            <option value="">-Seleccione un puesto-</option>
                            {jobsList.map((items) => (
                              <option key={items} name={items} value={items}>
                                {items}
                              </option>
                            ))}
                          </select>
                          {
                            submitted && errors.puesto && <small className="caption-error">{errors.puesto}</small>
                          }
                        </label>
                      </div>

                      <div className="col"> </div>
                    </div>
                  </div>
                )}
                {allowEditSucursal && (
                  <div className="col-12 col-md-6">
                    <label htmlFor="puesto" className="label mt-m">
                      Sucursal
                      <select
                        type="select"
                        name="puesto"
                        className={`${submitted && errors.sucursal && 'has-error'}`}
                        value={sucursal}
                        onChange={handleChange('sucursal')}
                      >
                        <option value="">-Seleccione una sucursal-</option>
                        {branchList && branchList.map((item) => (
                          <option key={item._id} name={item._id} value={item._id}>
                            {item.nombre}
                          </option>
                        ))}
                      </select>
                      {
                        submitted && errors.sucursal && <small className="caption-error">{errors.sucursal}</small>
                      }
                    </label>
                  </div>
                )}
              </div>

              <div className="mt-xxl">
                <h2>Domicilio</h2>
                <div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="cp" className="label mt-m ">
                        Código postal
                        <input
                          id="cp"
                          type="number"
                          maxLength="5"
                          pattern="[0-9]{X,5}"
                          className={`input ${submitted && errors.cp && 'has-error'}`}
                          onChange={handleChange('cp')}
                          value={cp}
                        />
                        {
                          errors?.cp && <small className="caption-error">{errors.cp}</small>
                        }
                      </label>
                    </div>
                    <div className="col"> </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="estado" className="label mt-m">
                        Estado
                        <select
                          type="select"
                          name="estado"
                          className={`${submitted && errors.estado && 'has-error'}`}
                          value={estado}
                          onChange={handleChange('estado')}
                        >
                          <option value="">Estado</option>
                          {countriesData.map(({ states }) => (
                            <option key={states} name={states} value={states}>
                              {states}
                            </option>
                          ))}
                        </select>
                        {
                          submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="ciudad" className="label mt-m">
                        Localidad o Municipio
                        <select
                          type="select"
                          name="ciudad"
                          className={`${submitted && errors.ciudad && 'has-error'}`}
                          value={ciudad}
                          onChange={handleChange('ciudad')}
                        >
                          <option value="">Localidad</option>
                          {localidades.map((loc) => (
                            <option key={loc} name={loc} value={loc}>
                              {loc}
                            </option>
                          ))}
                        </select>
                        {
                          submitted && errors.ciudad && <small className="caption-error">{errors.ciudad}</small>
                        }
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="colonia" className="label mt-m">
                        Colonia
                        {!colonies && (
                          <input
                            id="colonia"
                            type="text"
                            className={`input ${submitted && errors.colonia && 'has-error'}`}
                            onChange={handleChange('colonia')}
                            value={colonia}
                          />
                        )}
                        {colonies && (
                          <select
                            type="select"
                            name="colonia"
                            className={`${submitted && errors.colonia && 'has-error'}`}
                            value={colonia}
                            onChange={handleChange('colonia')}
                          >
                            <option value="">-Seleccione la Colonia-</option>
                            {colonies.map((_colonia) => (
                              <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                                {_colonia}
                              </option>
                            ))}
                          </select>
                        )}
                        {
                          submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                        }
                      </label>
                    </div>
                    <div className="col"> </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="direccion" className="label mt-m">
                        Dirección
                        <input
                          id="direccion"
                          type="text"
                          className={`input ${submitted && errors.direccion && 'has-error'}`}
                          onChange={handleChange('direccion')}
                          value={direccion}
                        />
                        {
                          submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                        }
                      </label>
                    </div>
                    <div className="col"> </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="entreCalle" className="label mt-m">
                        Entre calles
                        <input
                          id="entreCalle"
                          type="text"
                          className={`input ${submitted && errors.entreCalle && 'has-error'}`}
                          onChange={handleChange('entreCalle')}
                          value={entreCalle}
                        />
                        {
                          errors?.entreCalle && <small className="caption-error">{errors.entreCalle}</small>
                        }
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3">
                      <button className="button-secondary  mt-m" type="button" onClick={() => eventV(false)}>
                        Cancelar
                      </button>
                    </div>
                    <div className="col-12 col-md-6">
                      <button className="button mt-m" disabled={status === 'idle' ? 'disabled' : ''} type="submit">
                        Guardar cambios
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </form>
          </div>
          <div className="col"> </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeForm;
