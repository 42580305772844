import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import GMenuForm from '../../features/menu';
import ListCoupons from '../../features/promotions/ListCoupons';
import NavPage from '../../features/navPage';
import ListDiscounts from '../../features/promotions/ListDiscounts';
import RegisterDiscountM from '../../features/promotions/RegisterDiscountM';

const Promotions = () => {
  const [activeNav, setActiveNav] = useState('Cupones');
  const [openForm, setOpenForm] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);

  const eventChangeNav = (selectedNav) => {
    setActiveNav(selectedNav);
  };

  const handleCloseForm = () => {
    setSelectedDiscount(null);
    setOpenForm(false);
  };

  const handleUpdatePlans = (data) => {
    setSelectedDiscount(data);
    setOpenForm(true);
  };

  return (
    <>
      <RegisterDiscountM
        eventOp={openForm}
        onClose={handleCloseForm}
        currentData={selectedDiscount}
      />
      <GMenuForm />
      <div className="container">
        <div className="row align-items-center justify-content-center m-t-xxxl">
          <div className="col-12 col-md-12 col-lg-8 m-b-xxl">
            <NavPage options={['Cupones', 'Descuentos']} activeOp={activeNav} event={eventChangeNav} />
          </div>
          <div className="col-12 col-md-12 col-lg-8">
            {activeNav === 'Cupones' && <ListCoupons />}
            {activeNav === 'Descuentos'
              && (
                <ListDiscounts
                  openUpdateForm={handleUpdatePlans}
                  setOpenForm={() => setOpenForm(true)}
                />
              )}
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  );
};

export default Promotions;
