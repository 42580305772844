/* eslint-disable no-unused-vars */
import { useState } from 'react';

const FrmFileBeneficiaries = ({
  setDataForm, closeFormEvent
}) => {
  const [fileI, setfFleI] = useState(null);
  const [errors, setErrors] = useState(null);

  const getFile = async (e, _typeFile) => {
    if (e.target.files) {
      setfFleI(e.target.files[0]);
    } else {
      setfFleI(null);
    }
  };
  const closeForm = () => {
    closeFormEvent();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileI) {
      setErrors(null);
      setDataForm(fileI);
    } else {
      setErrors('El INE es obligatorio');
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <div>
        <div className="mt-xxl">
          <h2>Agregar INE</h2>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="fileINE" className="label mt-m ">
                      INE
                    </label>
                    <label htmlFor="fileINE" className="label mt-m ">
                      Los Tipo de Archivos admitidos son JPG, PNG y PDF
                      <input
                        id="fileINE"
                        className={`input ${errors && 'input has-error fileant'}`}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        onChange={(e) => getFile(e, 'fileINE')}
                        hidden
                      />
                    </label>
                    <button
                      className="botonsubida button"
                      onClick={() => document.querySelector('#fileINE').click()}
                    >
                      Subir Archivo&nbsp;&nbsp;
                      <i className="btn-icon">&#59661;</i>
                    </button>
                    <br />
                    {errors && <small className="caption-error">{errors}</small>}
                    {fileI && fileI.name && (
                      <div className="subido">
                        {fileI.name}
                        {' '}
                        <i className="btn-icon">&#59662;</i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-m">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <button className="input-label-float-right button-tertiary" onClick={closeForm}>
                  Regresar
                </button>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <button className="button" type="submit" onClick={handleSubmit}>
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default FrmFileBeneficiaries;
