import React from 'react';

const ProductName = ({ data, openEditForm, allowEdit = false }) => (
  <>
    <div className="d-flex justify-content-between">
      <h2>{data.nombre || 'Detalles'}</h2>
      {allowEdit && (
        <button
          className="button-secondary"
          onClick={() => openEditForm('ProductName')}
        >
          Actualizar
          <span className="button-icon pencil-line" />
        </button>
      )}
    </div>
    <div className="row mt-5">
      <div className="col-12">
        <label className="datos-title">Categoría</label>
        <span className="datos-info text-capitalize">{(data.categoria || '')}</span>
      </div>
      <div className="col-12">
        <label className="datos-title">Descripción</label>
        <span className="datos-info">{(data.descripcionPropiedad || '')}</span>
      </div>
    </div>
    <hr />
  </>
);

export default ProductName;
