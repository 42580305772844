/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CheckBox from '../../../../global/custom/checkbox';
import { getProductsByPage } from '../../../products/services';
import Toggle from '../../../shared/toggle/Toggle';
import { formatMoney, styleMoney } from '../../../../helpers/currency';
import { useProductsPerPage } from '../../../products/hooks/queries';

const ProductSelectionStep = ({
  onClose,
  statusOp,
  nextStep,
  prevStep,
  sucursal,
  addProducts,
  selectedProducts,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);

  const [availability, setAvailiability] = useState('Inmediata');
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [totalSelected, setTotalSelected] = useState(0);

  const handleSubmit = () => {
    const productsToAdd = products.filter((prod) => prod.checked)
      .map((prod) => ({
        ...prod,
        idLista: `${prod._id}-${availability}`,
        plan: availability === 'Inmediata' ? 'Inmediata' : 'Prevision',
        checked: false
      }));
    addProducts(productsToAdd);
    nextStep();
  };

  const { data: productsList } = useProductsPerPage({
    page: 1,
    perPage: 100,
    search,
    availability: availability.toLowerCase(),
    location: sucursal._id,
  });

  const { total } = productsList;

  const checkProduct = (id) => {
    setProducts(products.map((prod) => {
      if (prod._id === id) {
        return { ...prod, checked: !prod.checked };
      }
      return prod;
    }));
  };

  useEffect(() => {
    if (productsList.data) {
      const { data } = productsList;
      const filteredProducts = data.filter((product) => {
        const foundIndex = selectedProducts.findIndex((selectedP) => (
          selectedP._id === product._id && selectedP.plan === availability
        ));

        return foundIndex < 0;
      });
      setProducts(filteredProducts);
    }
  }, [productsList]);

  useEffect(() => {
    setTotalSelected(products.reduce((prev, current) => {
      if (current.checked) {
        // eslint-disable-next-line no-return-assign
        return prev += 1;
      }
      return prev;
    }, 0));
  }, [products]);

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid side-form-container">
            <div className="row">
              <div className="col-6">
                <span
                  className="icon-close return-icon"
                  onClick={prevStep}
                >
                  {' '}
                </span>
              </div>
              <div className="col-6 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Agregar producto</h2>
                      <br />
                      <small>
                        {total}
                        {' '}
                        resultados
                      </small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12 col-sm-8 col-lg-12">
                      <label htmlFor="plan" className="label labels mt-m">
                        Plan
                        <br />
                        <Toggle
                          options={[
                            {
                              name: 'Uso inmediato',
                              value: 'Inmediata',
                            },
                            {
                              name: 'Previsión',
                              value: 'Prevision',
                            }
                          ]}
                          activeOp={availability}
                          event={(value) => setAvailiability(value)}
                        />
                      </label>
                    </div>
                    <div className="input-container mt-4 mb-4">
                      <input
                        id="buscar"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        type="text"
                        className="input"
                        placeholder="Buscar"
                      />
                      <span className="input-icon search-line" />
                    </div>
                    <div className="col-12 flex-1">
                      {products.map((product) => (

                        <div className="d-flex mb-4" key={product._id}>
                          <CheckBox
                            shape="circle"
                            status={product.checked}
                            eventC={() => checkProduct(product._id)}
                            nameC={product._id}
                          />
                          <div className="ms-2">
                            <label className="form-check-label mb-1" htmlFor={product._id}>
                              {product.nombre}
                            </label>
                            <span className="text-secondary text-money">
                              {availability === 'Prevision'
                                ? styleMoney(formatMoney.format(product.precioPrevision))
                                : styleMoney(formatMoney.format(product.precio))}
                            </span>
                          </div>
                        </div>

                      ))}

                    </div>
                    <div className="col-12 spacing-nav" />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="form-bottom-container">
            <button
              className="button navR-content-btn"
              onClick={handleSubmit}
              type="button"
              disabled={totalSelected === 0}
            >
              Agregar (
              {totalSelected}
              )
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ProductSelectionStep;
