import ListClients from '../../features/clients/listClients';
import GMenuForm from '../../features/menu';

const Clients = () => (
  <>
    <GMenuForm />
    <div className="container">
      <div className="row align-items-center justify-content-center m-t-xxxl">
        <div className="col-12 col-md-12 col-lg-8">
          <ListClients />
        </div>
      </div>
    </div>
  </>
);

export default Clients;
