/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../reducersAuth/auth';
import permissions from '../../../shared/routeGuards/permisions.json';
import formik from './formik';
import './loginForm.css';

const LoginForm = () => {
  const [passwordType, setPasswordType] = useState('password');
  const [status, setStatus] = useState('idle');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors
  } = formik();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  // const { status, error } = useSelector((state) => state.auth);

  const handlePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage(null);
    if (isValid && dirty) {
      setStatus('loading');
      const tempD = await dispatch(login(values.email, values.password));
      // console.log('permissions', permissions);
      // console.log('tempD', tempD, tempD.payload);
      let BreakException = {};
      if (tempD && tempD.payload) {
        try {
          permissions.forEach((elm) => {
            if (tempD.payload.role === elm.rol && elm.rol === 'user') {
              navigate(elm.permissions[0]);
              throw BreakException;
            } else if (tempD.payload.role === elm.rol && elm.rol === 'empleado') {
              elm.puestos.forEach((puesto) => {
                if (puesto?.puesto?.toLowerCase() === tempD?.payload?.puesto?.toLowerCase()) {
                  navigate(puesto.default);
                  throw BreakException;
                }
              });
            } else if (tempD.payload.role === elm.rol) {
              navigate(elm.permissions[0]);
              throw BreakException;
            }
          });
        } catch (_error) {
          // eslint-disable-next-line no-console
          console.log(_error);
        }
      } else {
        setStatus('idle');
        setSubmitted(false);
        setErrorMessage('El correo o contraseña son incorrectos');
      }
      // navigate('/');
    }
  };

  const { email, password } = values;

  return (
    <div className="login-container container-fluid">
      <Row className="d-flex justify-content-center align-items-center h-100">
        <Col md={6} className="img-fluid">
          <Row className="justify-content-center align-items-end  h-100">
            <Col md={3} className="content-logo">
              <img src="/assets/img/logo-mdp-v-white.webp" alt="" className="logo-inicio" />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row className="justify-content-center align-items-center">
            <Col xl={10} md={12}>
              <div className="form-body">
                <form className="mt-m form-body" onSubmit={handleSubmit}>
                  <h2>Iniciar sesión</h2>
                  <div>
                    <label htmlFor="sample" className="labels mt-m">
                      Correo electrónico
                      <input
                        id="sample"
                        type="email"
                        className={`input ${submitted && errors.email && 'has-error'}`}
                        value={email}
                        placeholder="Correo electrónico"
                        onChange={handleChange('email')}
                      />
                      {submitted && errors.email && (
                        <small className="caption-error">
                          {errors.email}
                        </small>
                      )}
                    </label>
                    <label htmlFor="new-input" className="label input-content mt-m">
                      Contraseña
                      <div className="input-container">
                        <input
                          id="new-input"
                          type={passwordType}
                          className={`input ${submitted && errors.password && 'has-error'}`}
                          value={password}
                          placeholder="Ingresar password"
                          onChange={handleChange('password')}
                        />
                        <span
                          className={`input-icon ${passwordType === 'password' ? 'open-eye-line' : 'closed-eye-line'}`}
                          onClick={handlePasswordType}
                        >
                          {' '}
                        </span>
                      </div>
                      {submitted && errors.password && (
                        <small className="caption-error">
                          {errors.password}
                        </small>
                      )}
                    </label>
                    {errorMessage && (
                      <label className="label input-content mt-m">
                        <small className="caption-error">
                          {errorMessage}
                        </small>
                      </label>
                    )}
                    <Link to="/forgot-password" className="input-label-float-right button-tertiary">
                      Olvidé mi contraseña
                    </Link>
                  </div>
                  <div className="mt-m">
                    <button className="button" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
                      Ingresar
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;
