import { Routes, Route } from 'react-router';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { refreshUser } from '../features/auth/reducersAuth/auth';
import DesignSystem from '../pages/design';
import Login from '../pages/auth/login/Login';
import ForgotPassword from '../pages/auth/forgotPassword/ForgotPassword';
import ResetPassword from '../pages/auth/resetPassword/ResetPassword';
import PublicRoute from '../features/shared/routeGuards/PublicRoute';
import PrivateRoute from '../features/shared/routeGuards/PrivateRoute';
import DashboardRouter from './DashboardRouter';
import ExampleForm from '../pages/design/exampleForm/exampleForm';

const AppRouter = () => {
  // const dispatch = useDispatch();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // dispatch(refreshUser(user));
    }
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/design-system" element={<DesignSystem />} />
        <Route path="/design-system/forms" element={<ExampleForm />} />
        <Route
          path="/forgot-password"
          element={(
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          )}
        />
        <Route
          path="/login"
          element={(
            <PublicRoute>
              <Login />
            </PublicRoute>
          )}
        />
        <Route
          path="/reset-password/:token"
          element={(
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          )}
        />
        <Route
          path="/*"
          element={(
            <PrivateRoute>
              <DashboardRouter />
            </PrivateRoute>
          )}
        />
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </div>
  );
};

export default AppRouter;
