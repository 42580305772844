import { useState, useEffect } from 'react';
import { FormikStep2 } from '../../formik/formikSteps';
import estados from '../../../../global/data/countries.json';
import { findCP_S } from '../../servives';
import '../../prospects.css';

const AddProspStep2 = ({
  setDataForm, nextStep, prevStep, defVal
}) => {
  const [submitted2, setSubmitted2] = useState(false);
  const [status, setStatus] = useState('idle');
  const [estates, setEstates] = useState([]);
  const [localM, setLocalM] = useState([]);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
    handleBlur,
  } = FormikStep2(defVal || {});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted2(true);
    // nextStep();
    if (isValid) {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
    }
  };

  const {
    zipCode, estado, municipio, colonia, calle,
  } = values;

  useEffect(() => {
    if (isValid) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [values, isValid, dirty]);

  useEffect(() => {
    if (estados) {
      const tempSt = [];
      estados.forEach((elm) => {
        tempSt.push(elm.states);
      });
      setEstates(tempSt);
    }
  }, []);

  const getCPService = async () => {
    const cpS = `cp=${zipCode}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('municipio', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('municipio', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('municipio', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (zipCode.toString() && zipCode.toString().length > 4) {
      getCPService();
    } else if (zipCode.toString() && zipCode.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('municipio', '');
      setFieldValue('estado', '');
    }
  }, [zipCode]);

  useEffect(() => {
    if (estado) {
      const selectedEntity = estados.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalM(selectedEntity.loc);
      }
    } else {
      setLocalM([]);
      setFieldValue('municipio', '');
    }
  }, [estado]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 col-md-6">
              <label className="label labels mt-m">
                Código postal
                <input
                  id="zipCode"
                  type="number"
                  maxLength="5"
                  pattern="[0-9]{X,5}"
                  onBlur={handleBlur}
                  className={`input ${submitted2 && errors.zipCode && 'has-error'}`}
                  value={zipCode}
                  placeholder=""
                  onChange={handleChange('zipCode')}
                />
                {submitted2 && errors.zipCode && (
                  <small className="caption-error">
                    {errors.zipCode}
                  </small>
                )}
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6"> </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Estado
            <select name="estado" value={estado} type="select" onChange={handleChange('estado')}>
              <option value="" label="">Seleccione una opción</option>
              {
                estates && estates.map((item) => (
                  <option key={generateKey(item)} value={item} label={item}>{item}</option>
                ))
              }
            </select>
            {submitted2 && errors.estado && (
              <small className="caption-error">
                {errors.estado}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Localidad o municipio
            <select name="municipio" value={municipio} type="select" onChange={handleChange('municipio')}>
              <option value="" label="">Seleccione una opción</option>
              {
                localM && localM.map((item) => (
                  <option key={generateKey(item)} value={item} label={item}>{item}</option>
                ))
              }
            </select>
            {submitted2 && errors.municipio && (
              <small className="caption-error">
                {errors.municipio}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Colonia
            {!colonies && (
              <input
                id="colonia"
                type="text"
                onBlur={handleBlur}
                className={`input ${submitted2 && errors.colonia && 'has-error'}`}
                value={colonia}
                placeholder=""
                onChange={handleChange('colonia')}
              />
            )}
            {colonies && (
              <select
                type="select"
                name="colonia"
                className={`${submitted2 && errors.colonia && 'has-error'}`}
                value={colonia}
                onChange={handleChange('colonia')}
              >
                <option value="">-Seleccione la Colonia-</option>
                {colonies.map((_colonia) => (
                  <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                    {_colonia}
                  </option>
                ))}
              </select>
            )}
            {submitted2 && errors.colonia && (
              <small className="caption-error">
                {errors.colonia}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6"> </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Dirección
            <input
              id="calle"
              type="text"
              onBlur={handleBlur}
              className={`input ${submitted2 && errors.calle && 'has-error'}`}
              value={calle}
              placeholder=""
              onChange={handleChange('calle')}
            />
            {submitted2 && errors.calle && (
              <small className="caption-error">
                {errors.calle}
              </small>
            )}
            <small>(Opcional)</small>
          </label>
        </div>
        <div className="col-12 col-md-12"> </div>
        <div className="col-12 col-md-12">
          <button className="button btn-step-prev" type="button" onClick={() => prevStep()}>
            Regresar
          </button>
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Siguiente paso
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddProspStep2;
