import { useParams } from 'react-router-dom';
import EmployeeDetails from '../../features/employees/Employee';

const EmployeeDetailsPage = () => {
  const { id } = useParams();
  return (
    <>
      <EmployeeDetails idEmployee={id} />
    </>
  );
};

export default EmployeeDetailsPage;
