import './navPage.css';

const NavPage = ({ options, activeOp, event }) => {
  const checkNav = (item, active) => {
    if (active === item) {
      return 'btn-nav active';
    }
    return 'btn-nav';
  };

  return (
    <div className="container">
      <div className="row row-nav-page">
        {
          options && options.map((item) => (
            <div key={(item)} className="option-nav-page">
              <button
                className={checkNav(item, activeOp)}
                onClick={() => event(item)}
                type="button"
              >
                {item}
              </button>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default NavPage;
