import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import DatosCliente from './steps/FormDatosCliente';
import Referencias from './steps/FormReferencias';
import { registerClient } from '../../services';
import formikClient from './formik';
import './addClients.css';
import Message from '../../../shared/message/Message';

const MenuForm = () => {
  const [ checkedUno, setCheckedUno ] = useState('&#59666;');
  const [ checkedDos, setCheckedDos ] = useState('&#59652;');

  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');

  const [ opcionSeleccionada, setOpcionSeleccionada ] = useState(1);

  const formik = formikClient();

  const [ response, setResponse ] = useState('idle');
  const navigate = useNavigate();

  const nextStep = () => {
    let tempActS = opcionSeleccionada;
    if (tempActS === 1) {
      setStateStep1('complete');
      setStateStep2('active');
      setCheckedUno('&#59654;');
      setCheckedDos('&#59666;');
    }
    if (tempActS === 2) {
      setStateStep1('complete');
      setStateStep2('complete');
      setCheckedDos('&#59654;');
    }
    setOpcionSeleccionada(tempActS += 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    let tempActS = opcionSeleccionada;
    if (tempActS === 2) {
      setStateStep1('active');
      setStateStep2('disabled');
      setCheckedUno('&#59654;');
      setCheckedDos('&#59652;');
    }
    if (tempActS === 3) {
      setStateStep1('complete');
      setStateStep2('active');
      setCheckedUno('&#59654;');
      setCheckedDos('&#59666;');
    }
    setOpcionSeleccionada(tempActS -= 1);
    window.scrollTo(0, 0);
  };

  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth()) || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };

  const values = {
    nombre: formik.values.nombre || '',
    apellidoMaterno: formik.values.apellidoMaterno || '',
    apellidoPaterno: formik.values.apellidoPaterno || '',
    rfc: formik.values.rfc || '',
    password: '123456789',
    fechaNacimiento: formik.values.fechaNacimiento || '',
    estadoCivil: formik.values.estadoCivil || '',
    ocupacion: formik.values.ocupacion || '',
    sexo: formik.values.genero || '',
    telefono: formik.values.telefono.toString() || '',
    email: formik.values.email || '',
    edad: age(new Date(formik.values.fechaNacimiento)) || '',
    cp: formik.values.cp || 0,
    estado: formik.values.estado || '',
    ciudad: formik.values.ciudad || '',
    colonia: formik.values.colonia || '',
    etreCalles: formik.values.entreCalles || '',
    direccion: formik.values.direccion || '',
    referencias: [{
      nombre: formik.values.referencias[0].nombre || '',
      apellidoMaterno: formik.values.referencias[0].apellidoMaterno || '',
      apellidoPaterno: formik.values.referencias[0].apellidoPaterno || '',
      telefono: formik.values.referencias[0].telefono.toString() || '',
      email: formik.values.referencias[0].email || '',
      cp: formik.values.referencias[0].cp || 0,
      estado: formik.values.referencias[0].estado || '',
      ciudad: formik.values.referencias[0].ciudad || '',
      colonia: formik.values.referencias[0].colonia || '',
      direccion: formik.values.referencias[0].direccion || '',
    },
    {
      nombre: formik.values.referencias[1].nombre || '',
      apellidoMaterno: formik.values.referencias[1].apellidoMaterno || '',
      apellidoPaterno: formik.values.referencias[1].apellidoPaterno || '',
      telefono: formik.values.referencias[1].telefono.toString() || '',
      email: formik.values.referencias[1].email || '',
      cp: formik.values.referencias[1].cp || 0,
      estado: formik.values.referencias[1].estado || '',
      ciudad: formik.values.referencias[1].ciudad || '',
      colonia: formik.values.referencias[1].colonia || '',
      direccion: formik.values.referencias[1].direccion || '',
    }],
    role: 'user'
  };

  const handleSubmit = async () => {
    setResponse('loading');
    const createClient = await registerClient(values);
    if (createClient && createClient._id) {
      setResponse('success');
    } else {
      setResponse('error');
    }
  };

  if (response !== 'idle') {
    return (
      <div className="login-container container-fluid">
        {response === 'loading' && (
          <Message
            type="loading"
            title="Cargando..."
            subtitle="Esta operación podría demorar algunos minutos. Por favor, espere."
          />
        )}
        {response === 'success' && (
          <Message
            type="success"
            title="Cliente registrado"
            message="El cliente se ha registrado exitosamente."
            onConfirm={() => navigate('/clients')}
            confirmText="Salir"
          />
        )}
        {response === 'error' && (
          <Message
            type="error"
            title="Ha ocurrido un error"
            message="Vuelve a intenarlo más tarde"
            onConfirm={() => setResponse('idle')}
            confirmText="Volver"
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-4">
          <i className="step-client-icon">{parse(checkedUno)}</i>
          <label className="step-client small-bold">Datos personales</label>
        </div>
        <div className={`col-12 col-md-4 ${stateStep2}`}>
          <i className="step-client-icon">{parse(checkedDos)}</i>
          <label className="step-client small-bold">Referencias</label>
        </div>
      </div>
      <div className="mb-xxl-5">
        {stateStep1 === 'active' && (
          <DatosCliente
            cambiarOpcionDos={nextStep}
            formik={formik}
          />
        )}
        {stateStep2 === 'active' && (
          <Referencias
            cambiarOpcionUno={prevStep}
            handleSubmitForm={handleSubmit}
            formik={formik}
            dataC={values}
          />
        )}
      </div>
    </div>
  );
};

export default MenuForm;
