import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import '../../client.css';

const TabCLients = ({ data, permision }) => {
  const navigate = useNavigate();
  const redirectContractClient = (idClient) => {
    if (permision('Clientes', 'consultarCliente')) {
      navigate(`/clients/contract-clients/${idClient}`);
    }
  };
  return (
    <div className="m-t-l">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6">
              <small className="text-title-tab">CLIENTE</small>
            </div>
            <div className="col-9 col-md-5">
              <small className="text-title-tab">LOCALIDAD</small>
            </div>
            <div className="col-3 col-md-1 p-r">
              <small className="text-title-tab p-a contratos">CONTRATOS</small>
            </div>
          </div>
          {
            data && data.length > 0 && data.map((item) => (
              <div className="row" key={item._id}>
                <div className="row row-cursor" onClick={() => redirectContractClient(item._id)}>
                  <div className="col-12 col-md-6 p-r">
                    <label className="text-name">
                      {item.nombre}
                      &nbsp;
                    </label>
                    <label className={item.correoPrueba ? 'text-email indicator' : 'text-email'}>{item.email || parse('&nbsp;')}</label>
                  </div>
                  <div className="col-9 col-md-5">
                    <label className="text-dir">
                      <i>&#59679;</i>
                      {item.direccion || ''}
                    </label>
                  </div>
                  <div className="col-3 col-md-1">
                    <label className="text-total-contr">{item.contratos || 0}</label>
                  </div>
                </div>
                <hr className="line-spacing" />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default TabCLients;
