/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import MessageInfo from '../../../../messageInfo';
import { getClientes } from '../../../servives';
import NavPage from '../../../../navPage';

const ViewSelectClient = ({
  setData, dataP, optionSearch
}) => {
  const navigate = useNavigate();
  const [LClients, setLClients] = useState(false);
  const [respSimilar, setRespSimilar] = useState(null);

  const [searchInp, setSearchInp] = useState('');
  const [orderList, setOrderList] = useState('asc');
  const [optSug, setOptSug] = useState(0);
  let optionNav = ['Mis clientes', `Sugerencias (${optSug})`];
  const [activeNav, setActiveNav] = useState('Mis clientes');
  const [statusS, setStatusS] = useState(true);

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const perPage = 15;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const eventChangeNav = (item) => {
    setActiveNav(item);
  };
  const getClientsL = async () => {
    setStatusS(false);
    const nombre = searchInp ? `filtro=${encodeURIComponent(searchInp).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';
    const pag = '&paginacion=1';
    const pags = `&paginas=${perPage}`;
    const tempSes = JSON.parse(localStorage.getItem('user'));
    let nameUser = `${tempSes.nombre || ''} ${tempSes.apellidoPaterno || ''} ${tempSes.apellidoMaterno || ''}`;
    nameUser = nameUser.trim();
    const empl = `&empleaado=${nameUser}`;

    const tempQuery = `${nombre}${pag}${pags}&orden=${orderList}${empl}`;
    await getClientes(tempQuery).then((data) => {
      if (data && data.data && data.total > 0) {
        if (activeNav === 'Mis clientes') {
          setLClients(data.data || []);
        } else if (activeNav !== 'Mis clientes') {
          setRespSimilar(data.data || []);
          setOptSug(data.data.length);
        }
      } else if (activeNav === 'Mis clientes') {
        setLClients([]);
      } else if (activeNav !== 'Mis clientes') {
        setRespSimilar([]);
        setOptSug(0);
      }
    });
  };

  const searchSimilar = async () => {
    setStatusS(false);
    let tempName = `${dataP.nombre || ''} ${dataP.apellidoPaterno || ''} ${dataP.apellidoMaterno || ''}`;
    tempName = tempName.trim();
    const nombre = `filtro=${encodeURIComponent(tempName).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}`;
    const pag = `&paginacion=${1}`;
    const pags = `&paginas=${100}`;

    const Fdata = [];

    const tempQuery = `${nombre}${pag}${pags}&orden=asc`;
    await getClientes(tempQuery).then((resp) => {
      if (resp && resp.data && resp.total > 0) {
        resp.data.forEach((element) => {
          Fdata.push(element);
        });
      }
    });

    const emailT = `filtro=${encodeURIComponent(` ${dataP.email || ''}`).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}`;
    const tempQueryE = `${emailT}${pag}${pags}&orden=asc`;
    await getClientes(tempQueryE).then((resp) => {
      if (resp && resp.data && resp.total > 0) {
        resp.data.forEach((element) => {
          if (Fdata.length > 0) {
            let tempCheckCl = false;
            Fdata.forEach((element2) => {
              if (element2._id === element._id) {
                tempCheckCl = true;
              }
            });
            if (!tempCheckCl) {
              Fdata.push(element);
            }
          } else {
            Fdata.push(element);
          }
        });
      }
    });
    setRespSimilar(Fdata);
    setOptSug(Fdata.length);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      const token = localStorage.getItem('token').toString();
      if (token) {
        getClientsL();
      } else {
        closeSession();
      }
    }
  };

  const checkIfAuthorize = (dataClient) => {
    let blockData = true;
    const user = JSON.parse(localStorage.getItem('user'));
    dataClient.idEmpleados.forEach((element) => {
      if (user._id === element) {
        blockData = false;
      }
    });
    return blockData;
  };

  useEffect(() => {
    if (activeNav === 'Mis clientes') {
      getClientsL();
    } else {
      searchSimilar();
    }
  }, [activeNav]);

  useEffect(() => {
    if (dataP) {
      searchSimilar();
    }
  }, [dataP]);

  useEffect(() => {
    if (LClients && respSimilar) {
      setStatusS(true);
    } else {
      setStatusS(false);
    }
  }, [LClients, respSimilar]);

  return (
    <div className="col-12">
      <div className="col-12 spacing-nav"> </div>
      <div className="container-fluid">
        <div className="row content-nav-padd">
          <div className="col-12">
            <h2>Seleccionar cliente</h2>
            <br />
          </div>
          <div className="col-12">
            <button className="button-secondary full" type="button" onClick={optionSearch}>
              Buscar en todos los registros
            </button>
          </div>
          <div className="col-12">
            <br />
          </div>
          <div className="col-12">
            {statusS && <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} classN="nav-ma nav-no-padd" calcContent="EQ" idC="NavClients" />}
          </div>
          <div className="col-12">
            <br />
          </div>
          {activeNav === 'Mis clientes' && (
            <div className="col-12">
              <div className="row">
                <div className="input-container">
                  <input id="buscar" onChange={(e) => setSearchInp(e.target.value)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                  <span className="input-icon search-line" />
                </div>
              </div>
            </div>
          )}
          {
            activeNav !== 'Mis clientes' && (
              <>
                <br />
                <div className="col-12">
                  <MessageInfo
                    message={`Se encontraron ${respSimilar.length} registros que coinciden con la información de este prospecto.`}
                    type=""
                  />
                </div>
              </>
            )
          }
          <div className="col-12">
            {activeNav === 'Mis clientes' && statusS && LClients && LClients.map((item) => (
              <div
                key={generateKey(item._id)}
                className="container-fluid "
                onClick={() => setData(item)}
              >
                <div className="row row-itemN ">
                  <div className="col-10 ">
                    <p>
                      {item.nombre || ''}
                      {' '}
                      {item.apellidoPaterno || ''}
                      {' '}
                      {item.apellidoMaterno || ''}
                    </p>
                    <p className="nav-text-opacity">{item.email}</p>
                    <label className="text-dir">
                      <i>&#59679;</i>
                      {item.ubicacion || '-'}
                    </label>
                  </div>
                </div>
              </div>
            ))}
            {activeNav !== 'Mis clientes' && statusS && respSimilar && respSimilar.map((item) => (
              <div
                key={generateKey(item._id)}
                className="container-fluid "
                onClick={() => setData(item)}
              >
                <div className="row row-itemN ">
                  <div className="col-10 ">
                    <p className="text-capitalize">
                      {item.nombre || ''}
                      {' '}
                      {item.apellidoPaterno || ''}
                      {' '}
                      {item.apellidoMaterno || ''}
                      {checkIfAuthorize(item) ? <i className="icon-i-select">&#59690;</i> : ''}
                    </p>
                    <p className="nav-text-opacity">{item.email}</p>
                    <label className="text-dir">
                      <i>&#59679;</i>
                      {item.ubicacion || '-'}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-12 spacing-nav"> </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSelectClient;
