/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCouponsPerPage } from './hooks/queries';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import CouponsTable from './components/couponsTable/CouponsTable';
import './promotions.css';
import NotFoundAlert from '../shared/notFoundAlert/NotFoundAlert';
import CouponDetailModal from './components/couponDetails/CouponDetailModal';
import DropdownFilter from '../shared/dropdownFilter/DropdownFilter';

const ListCoupons = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchInp, setSearchInp] = useState('');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [listOrder, setListOrder] = useState('normal');
  const perPage = 10;

  const { data: queryData } = useCouponsPerPage({
    page,
    perPage,
    search: searchInp,
    valor: listOrder,
  });

  const total = queryData?.total;
  const couponsList = queryData?.data;
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const totalPages = Math.ceil(total / perPage);
  const searchEnabled = searchInp.length > 0;

  const subsPage = () => {
    setPage(page - 1);
  };

  const addPage = () => {
    setPage(page + 1);
  };

  const handleViewCoupon = (item) => {
    setSelectedCoupon(item);
    setShowDetailModal(true);
  };

  const handleCloseModal = () => {
    setShowDetailModal(false);
  };

  useEffect(() => {
    if (selectedCoupon) {
      setSelectedCoupon(couponsList.find((item) => item._id === selectedCoupon._id));
    }
  }, [couponsList]);

  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp]);

  return (
    <>
      {showDetailModal
        && (
          <CouponDetailModal
            data={selectedCoupon}
            onClose={handleCloseModal}
          />
        )}
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <h2>Cupones</h2>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter">
          {checkPermisionRoute('Cupones', 'crearCupon') && (
            <button className="button" onClick={() => navigate('/add-coupon')}>
              &nbsp;&nbsp;Crear&nbsp;&nbsp;
              <i className="btn-icon">&#59672;</i>
              &nbsp;&nbsp;
            </button>
          )}
        </div>
        <div className="col-12 margin-filter">
          <label>
            {total || 0}
            &nbsp;
            {total === 1 ? 'resultado' : 'resultados'}
          </label>
        </div>
        <div className="col-12 col-md-4 margin-filter">
          <div className="col-12">
            <div className="input-container">
              <input
                id="buscar"
                onChange={(e) => setSearchInp(e.target.value)}
                value={searchInp}
                type="text"
                className="input"
                placeholder="Buscar"
              />
              <span className="input-icon search-line" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <DropdownFilter
            activeValue={listOrder}
            defaultLabel="Ordenar"
            setActiveValue={setListOrder}
            options={[
              {
                label: 'Por estatus',
                value: 'normal',
              },
              {
                label: 'Valor mayor',
                value: 'valorMayor',
              },
              {
                label: 'Valor menor',
                value: 'valorMenor',
              },
            ]}
          />
        </div>

        {couponsList && (
          <CouponsTable
            data={couponsList}
            onRowClick={handleViewCoupon}
          />
        )}
        {total === 0 && !searchEnabled && (
          <NotFoundAlert
            icon="&#59671;"
            title="Aún no se ha registrado ningún cupón"
            message="Aquí podrás visualizar el resumen de los cupones que hayan sido registrados."
          />
        )}
        {total === 0 && searchEnabled && (
          <NotFoundAlert
            icon="&#59673;"
            title="No hay resultados"
            message="Lo sentimos, parece que no hemos podido encontrar ningún resultado para tu búsqueda."
          />
        )}
        {total > 0 && (
          <div className="container pag-content">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6">
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    <label className="text-pag">{page} de {totalPages}</label>
                  </div>
                  <div className="col-12 col-md-6 pag-content-btn">
                    <button
                      onClick={subsPage}
                      disabled={!activeL}
                      className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}
                    >
                      <i>&#59658;</i>
                    </button>
                    <button
                      onClick={addPage}
                      disabled={!activeR}
                      className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}
                    >
                      <i>&#59659;</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListCoupons;
