/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { toast, ToastContainer } from 'react-toastify';
import { FormikS } from '../../formik/formikSeg';
import NavPage from '../../../navPage';
import {
  addTrackingToProspect, getProspectsByIdInfo, updateProspectTrackingById, updateProspectsById
} from '../../servives';
import '../../prospects.css';

const AddSeguimientoFrm = ({
  idP, isEdit, onClose, currentData, refresh,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState(false);
  const [prospect, setProspect] = useState(null);
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const navigate = useNavigate();

  const optionNav = ['Llamada', 'Cita', 'WhatsApp'];
  const [activeNav, setActiveNav] = useState('Llamada');

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FormikS();

  const eventShowMessage = (textM) => {
    setMessage(true);
    toast(textM, {
      className: 'message'
    });
  };

  useEffect(() => {
    if (currentData) {
      let date = new Date(currentData.fecha);
      let h = date.getUTCHours();
      let m = date.getUTCMinutes();
      let hora = `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;
      setFieldValue('fecha', currentData.fecha.split('T')[0]);
      setFieldValue('hora', hora);
      setFieldValue('numero', currentData.numero || '');
      setFieldValue('lugar', currentData.lugar || '');
      setFieldValue('contacto', currentData.tipo);
      setActiveNav(currentData.tipo);
    }
  }, [currentData]);

  const {
    contacto, fecha, hora, lugar, numero, comentarios,
  } = values;

  const handleUpdate = async () => {
    if (!isValid) {
      return;
    }
    try {
      const trackingId = currentData._id;
      const updatedItem = {
        ...currentData,
        fecha: `${fecha} ${hora} GMT`,
        tipo: contacto,
        numero: numero || undefined,
        lugar: contacto === 'Cita' ? lugar : '-',
        _id: undefined,
      };
      const resp = await updateProspectTrackingById(idP, trackingId, updatedItem);
      if (resp && !resp.errors) {
        eventShowMessage('Seguimiento actualizado.');
        refresh();
        returnPrevStep();
      } else {
        eventShowMessage('No se pudo actualizar, intente de nuevo más tarde.');
      }
    } catch (err) {
      eventShowMessage('No se pudo actualizar, intente de nuevo más tarde.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isEdit) {
      return handleUpdate();
    }
    if (isValid && dirty) {
      const tempUserD = JSON.parse(localStorage.getItem('user'));
      const tempS = [
        {
          idEmpleado: tempUserD._id,
          tipo: contacto,
          fecha: `${fecha} ${hora} GMT`,
          lugar: contacto === 'Cita' ? lugar : undefined,
          numero: contacto !== 'Cita' ? numero.toString() : undefined,
          comentarios: comentarios || undefined,
          estatus: 'abierto'
        }
      ];
      const finalP = {
        seguimiento: tempS
      };

      if (!prospect.seguimiento || prospect.seguimiento.length === 0) {
        updateProspectsById(idP, finalP).then((resp) => {
          if (resp && !resp.errors) {
            eventShowMessage('Seguimiento agendado.');
            setTimeout(() => {
              returnPrevStep();
            }, 2000);
          } else {
            eventShowMessage('No se pudo agendar, intente de nuevo más tarde.');
            setTimeout(() => {
              returnPrevStep();
            }, 2000);
          }
        });
      } else {
        addTrackingToProspect(idP, finalP).then((resp) => {
          if (resp && !resp.errors) {
            eventShowMessage('Seguimiento agendado.');
            setTimeout(() => {
              returnPrevStep();
            }, 2000);
          } else {
            eventShowMessage('No se pudo agendar, intente de nuevo más tarde.');
            setTimeout(() => {
              returnPrevStep();
            }, 2000);
          }
        });
      }
    }
    return '';
  };

  const returnPrevStep = () => {
    if (isEdit) {
      return onClose();
    }
    return navigate(`/prospects/${idP}`);
  };

  useEffect(() => {
    setFieldValue('contacto', activeNav);
  }, [activeNav]);

  const getProspect = async () => {
    await getProspectsByIdInfo(idP).then((data) => {
      if (data && data[0] && data[0].data) {
        setProspect(data[0].data);
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idP) {
      getProspect();
    }
  }, [idP]);

  useEffect(() => {
    if (!prospect || isEdit) {
      return;
    }
    setFieldValue('numero', prospect.telefono);
  }, [prospect]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-12 margin-form-title">
          <h2>
            {isEdit ? 'Actualizar' : 'Agendar'}
            {' '}
            seguimiento
          </h2>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-r ">
          <label className="label mt-m">
            Tipo de contacto
            <select
              name="contacto"
              id="contacto"
              value={activeNav}
              onChange={(e) => setActiveNav(e.target.value)}
            >
              <option value="">Seleccione una opción</option>
              {optionNav.map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6" />
        {activeNav && activeNav !== 'Cita' && (
          <>
            <div className="col-12 mt-m">
              <div className="row prospect-message-cont m-0">
                <div className="col-10 col-md-11">
                  <p>Registrar un teléfono diferente</p>
                </div>
                <div className="col-2 col-md-1">
                  <Switch
                    checked={switchEnabled}
                    onChange={() => setSwitchEnabled(!switchEnabled)}
                    onColor="#000000"
                    onHandleColor="#FFFFFF"
                    offColor="#CFCFCF"
                    offHandleColor="#FFFFFF"
                    borderRadius={60}
                    handleDiameter={5}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={25}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">

              <label htmlFor="numero" className="label mt-m">
                Número
                <input
                  id="numero"
                  type="tel"
                  disabled={!switchEnabled}
                  className={`input input-date ${submitted && errors.numero && 'has-error'}`}
                  onChange={handleChange('numero')}
                  value={numero}
                />
                {
                  submitted && errors.numero && <small className="caption-error">{errors.numero}</small>
                }
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6" />
          </>
        )}
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <label htmlFor="fecha" className="label mt-m">
            Fecha
            <input
              id="fecha"
              type="date"
              className={`input input-date ${submitted && errors.fecha && 'has-error'}`}
              onChange={handleChange('fecha')}
              value={fecha}
            />
            {
              submitted && errors.fecha && <small className="caption-error">{errors.fecha}</small>
            }
          </label>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <label htmlFor="hora" className="label mt-m">
            Hora
            <input
              id="hora"
              type="time"
              className={`input input-date ${submitted && errors.hora && 'has-error'}`}
              onChange={handleChange('hora')}
              value={hora}
            />
            {
              submitted && errors.hora && <small className="caption-error">{errors.hora}</small>
            }
          </label>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          {activeNav === 'Cita' ? (
            <label htmlFor="lugar" className="label mt-m ">
              Lugar
              <input
                id="lugar"
                type="text"
                className={`input ${submitted && errors.lugar && 'has-error'}`}
                value={lugar}
                onChange={handleChange('lugar')}
              />
              {
                submitted && errors.lugar && <small className="caption-error">{errors.lugar}</small>
              }
            </label>
          ) : (<></>)}
        </div>
        {!isEdit && (
          <div className="col-12">
            <label htmlFor="comentarios" className="label mt-m ">
              Comentarios y observaciones
              <textarea
                onChange={handleChange('comentarios')}
                value={comentarios}
                id="comentarios"
                className={`input sizein ${submitted && errors && errors.comentarios && 'has-error'}`}
              />
            </label>
            <label htmlFor="comentarios" className="label font-sm">
              (Opcional)
            </label>
          </div>
        )}
        <div className="col-12 col-md-12">
          <button className="button btn-step-prev" type="button" onClick={() => returnPrevStep()}>
            Cancelar
          </button>
          <button className="button btn-step" type="submit">
            {isEdit ? 'Guardar cambios' : 'Agendar'}
          </button>
        </div>
      </div>
      {message && (
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      )}
    </form>
  );
};

export default AddSeguimientoFrm;
