/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  uploadFileC, getBeneficiarieByID, updateBeneficiarie, getContractByID
} from './services';
import FrmDataBeneficiaries from './components/editBeneficiarie/frmDataBenef';
import FrmDataAddressBeneficiaries from './components/editBeneficiarie/frmDataAdBenef';
import FrmFileBeneficiaries from './components/editBeneficiarie/frmFileBenef';
import './contract.css';

const EditBeneficiarieView = ({ idBeneficiare, idContract }) => {
  const [showInfo, setShowInfo] = useState(true);
  const [dataBenf, setDataBenf] = useState(null);
  const [stateStep1, setStateStep1] = useState('');// active
  const [stateStep2, setStateStep2] = useState('');
  const [stateStep3, setStateStep3] = useState('');
  const [message, setMessage] = useState(false);
  const [contractInfo, setContractInfo] = useState(null);

  const navigate = useNavigate();

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const updateDataInfo = async (dataB) => {
    await updateBeneficiarie(idBeneficiare, dataB).then((resp) => {
      if (resp && resp._id) {
        if (resp && resp.fechaNacimiento) {
          const tempD = new Date(resp.fechaNacimiento);
          resp.fechaNacimiento = formatDate(tempD);
        }
        if (resp && resp.fecha_actualizacion) {
          const tempD = new Date(resp.fecha_actualizacion);
          resp.fecha_actualizacion = formatDate(tempD);
        }
        if (resp && resp.updatedAt) {
          const tempD = new Date(resp.updatedAt);
          resp.updatedAt = formatDate(tempD);
        }
        setDataBenf(resp);
        setStateStep1('');
        setStateStep2('');
        setStateStep3('');
        setMessage(true);
        toast('Cambios guardados', {
          className: 'message',
        });
      } else {
        setMessage(true);
        toast('Los fue posible actualizar la información.', {
          className: 'message error',
        });
      }
    });
  };

  const updateFileBenf = async (_file) => {
    await uploadFileC(_file, `${idContract}`).then((dataImg) => {
      if (dataImg.ok) {
        const tempNewData = {
          url_ine: dataImg.file,
          fecha_actualizacion: new Date(),
        };
        updateDataInfo(tempNewData);
      } else {
        setMessage(true);
        toast('Los fue posible actualizar la información.', {
          className: 'message error',
        });
      }
    });
  };

  const getContractInfo = async () => {
    await getContractByID(idContract).then((resp) => {
      if (resp && resp[0] && resp[0]._id) {
        setContractInfo(resp[0]);
      }
    });
  };

  const closeForm = () => {
    setStateStep1('');
    setStateStep2('');
    setStateStep3('');
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };
  const getBeneficiarieInfo = async () => {
    await getBeneficiarieByID(idBeneficiare).then((resp) => {
      if (resp && resp[0] && resp[0]._id) {
        if (resp[0] && resp[0].fechaNacimiento) {
          const tempD = new Date(resp[0].fechaNacimiento);
          resp[0].fechaNacimiento = formatDate(tempD);
        }
        if (resp[0] && resp[0].fecha_actualizacion) {
          const tempD = new Date(resp[0].fecha_actualizacion);
          resp[0].fecha_actualizacion = formatDate(tempD);
        }
        if (resp[0] && resp[0].updatedAt) {
          const tempD = new Date(resp[0].updatedAt);
          resp[0].updatedAt = formatDate(tempD);
        }
        setDataBenf(resp[0]);
      }
    });
  };
  const get_url_extension = (url) => url.split(/[#?]/)[0].split('.').pop().trim();

  const downloadFile = (routeFile) => {
    try {
      const extensionF = get_url_extension(routeFile);
      let tempCT = '';
      if (extensionF === 'pdf') {
        tempCT = 'application/pdf';
      } else {
        tempCT = `image/${extensionF}`;
      }
      fetch(routeFile, {
        method: 'GET',
        headers: {
          'Content-Type': tempCT,
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          let filename = routeFile.substring(routeFile.lastIndexOf('/') + 1);
          link.setAttribute(
            'download',
            filename,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    } catch (error) {
      setMessage(true);
      toast('Error al descargar el archivo.', {
        className: 'message error',
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idBeneficiare) {
      getBeneficiarieInfo();
      getContractInfo();
    } else if (!token) {
      closeSession();
    }
  }, [idBeneficiare]);

  useEffect(() => {
    if (stateStep1 !== '' || stateStep2 !== '' || stateStep3 !== '') {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, [stateStep1, stateStep2, stateStep3]);

  return (
    <div>
      <div className="mb-xxl-5">
        <div className="content-buttom">&nbsp;</div>
        {dataBenf && showInfo && (
          <div>
            <div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h2 className="title-prof">Datos personales</h2>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="content-btns">
                      {contractInfo && (contractInfo.estatus_contrato.toLowerCase() !== 'firmado' && contractInfo.estatus_contrato.toLowerCase() !== 'liquidado') && (
                      <button className="button-secondary p-r" onClick={() => setStateStep1('active')}>
                        &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                        <i className="btn-icon">&#59686;</i>
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="datos-title">Nombre completo</label>
                  <label className="datos-info text-capitalize">
                    {dataBenf.nombre || ''}
                    {' '}
                    {dataBenf.apellidoPaterno || ''}
                    {' '}
                    {dataBenf.apellidoMaterno || ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Fecha de nacimiento</label>
                  <label className="datos-info">{dataBenf.fechaNacimiento || '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Edad</label>
                  <label className="datos-info">{dataBenf.edad || '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Teléfono</label>
                  <label className="datos-info">{dataBenf.telefono || '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Parentesco</label>
                  <label className="datos-info text-capitalize">{dataBenf.parentesco || '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">&nbsp;</label>
                  <label className="datos-info">&nbsp;</label>
                </div>
              </div>
            </div>
            <hr className="prof-hr" />
            <div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h2 className="title-prof">Domicilio</h2>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="content-btns">
                      {contractInfo && (contractInfo.estatus_contrato.toLowerCase() !== 'firmado' && contractInfo.estatus_contrato.toLowerCase() !== 'liquidado') && (
                      <button className="button-secondary p-r" onClick={() => setStateStep2('active')}>
                        &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                        <i className="btn-icon">&#59686;</i>
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="datos-title">Ubicación</label>
                  <label className="datos-info">
                    {dataBenf.ciudad || ''}
                    &nbsp;
                    {dataBenf.estado || ''}
                  </label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Código postal</label>
                  <label className="datos-info">{dataBenf.cp || '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Dirección</label>
                  <label className="datos-info">{dataBenf.direccion || '-'}</label>
                </div>
                <div className="col-12 col-md-6">
                  <label className="datos-title">Colonia</label>
                  <label className="datos-info">{dataBenf.colonia || '-'}</label>
                </div>
              </div>
            </div>
            <hr className="prof-hr" />
            <div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h2 className="title-prof">INE</h2>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="content-btns">
                      {dataBenf && dataBenf.url_ine && (
                        <button className="button-secondary p-r" onClick={() => downloadFile(dataBenf.url_ine)}>
                          <i className="btn-icon">&#59693;</i>
                        </button>
                      )}
                      {contractInfo && (contractInfo.estatus_contrato.toLowerCase() !== 'firmado' && contractInfo.estatus_contrato.toLowerCase() !== 'liquidado') && (
                      <button className="button-secondary p-r" onClick={() => setStateStep3('active')}>
                        &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                        <i className="btn-icon">&#59686;</i>
                      </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Última modificación</label>
                    <label className="datos-info">
                      {dataBenf.fecha_actualizacion || dataBenf.updatedAt}
                    </label>
                  </div>
                </div>
              </div>
              <hr className="prof-hr" />
            </div>
            <div className="content-buttom">&nbsp;</div>
          </div>
        )}
        {dataBenf && stateStep1 === 'active' && (<FrmDataBeneficiaries setDataForm={updateDataInfo} defVal={dataBenf} closeFormEvent={closeForm} />)}
        {dataBenf && stateStep2 === 'active' && (<FrmDataAddressBeneficiaries setDataForm={updateDataInfo} defVal={dataBenf} closeFormEvent={closeForm} />)}
        {dataBenf && stateStep3 === 'active' && (<FrmFileBeneficiaries setDataForm={updateFileBenf} closeFormEvent={closeForm} />)}
        {message && (
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        )}
      </div>
    </div>
  );
};

export default EditBeneficiarieView;
