import { useParams } from 'react-router-dom';
import DetailsProspect from '../../features/prospects/detailsProspect';

const DetailProspectPage = () => {
  const { id } = useParams();
  return (
    <>
      <DetailsProspect idProspect={id} />
    </>
  );
};

export default DetailProspectPage;
