/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import FrmDetailsProduct from './components/newProduct/frmDetailsP';
import FrmImgProduct from './components/newProduct/frmImgP';
import FrmSucursalesProduct from './components/newProduct/frmSucursales';
import FrmPlanesProduct from './components/newProduct/frmPlanesP';
import FrmPriceProduct from './components/newProduct/frmPriceP';
import StatusFormProduct from './components/newProduct/statusForm';
import { uploadFile, registrarProducto } from './services';
import './products.css';

const NewProductV = () => {
  const [checked1, setChecked1] = useState('&#59666;'); // '&#59654;'
  const [checked2, setChecked2] = useState('&#59652;');
  const [checked3, setChecked3] = useState('&#59652;');
  const [checked4, setChecked4] = useState('&#59652;');
  const [checked5, setChecked5] = useState('&#59652;');

  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [stateStep4, setStateStep4] = useState('disabled');
  const [stateStep5, setStateStep5] = useState('disabled');
  const [insertData, setInsertData] = useState('');

  const [dataForm1, setDataForm1] = useState(null);
  const [dataForm2, setDataForm2] = useState(null);
  const [dataForm3, setDataForm3] = useState(null);
  const [dataForm4, setDataForm4] = useState(null);
  const [dataForm5, setDataForm5] = useState(null);

  const [statusInsert, setStatusInsert] = useState('loading');
  const [statusTitle, setStatusTitle] = useState('Procesando, espere');
  const [statusContent, setStatusContent] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [statusConfirmT, setStatusConfirmT] = useState('Salir');
  const [imgP, setImgP] = useState(null);
  const [imgPG, setImgPG] = useState(null);
  const navigate = useNavigate();

  const step1View = () => {
    setChecked1('&#59666;');
    setStateStep1('active');
    setStateStep2('disabled');
    setStateStep3('disabled');
    setStateStep4('disabled');
    setStateStep5('disabled');
    setChecked2('&#59652;');
    setChecked3('&#59652;');
    setChecked4('&#59652;');
    setChecked5('&#59652;');
  };
  const step2View = () => {
    setChecked1('&#59654;');
    setStateStep1('ok');
    setStateStep2('active');
    setStateStep3('disabled');
    setStateStep4('disabled');
    setStateStep5('disabled');
    setChecked2('&#59666;');
    setChecked3('&#59652;');
    setChecked4('&#59652;');
    setChecked5('&#59652;');
  };
  const step3View = () => {
    setChecked1('&#59654;');
    setChecked2('&#59654;');
    setStateStep1('ok');
    setStateStep2('ok');
    setStateStep3('active');
    setStateStep4('disabled');
    setStateStep5('disabled');
    setChecked3('&#59666;');
    setChecked4('&#59652;');
    setChecked5('&#59652;');
  };
  const step4View = () => {
    setChecked1('&#59654;');
    setChecked2('&#59654;');
    setChecked3('&#59654;');
    setStateStep1('ok');
    setStateStep2('ok');
    setStateStep3('ok');
    setStateStep4('active');
    setStateStep5('disabled');
    setChecked4('&#59666;');
    setChecked5('&#59652;');
  };
  const step5View = () => {
    setChecked1('&#59654;');
    setChecked2('&#59654;');
    setChecked3('&#59654;');
    setChecked4('&#59654;');
    setStateStep1('ok');
    setStateStep2('ok');
    setStateStep3('ok');
    setStateStep4('ok');
    setStateStep5('active');
    setChecked5('&#59666;');
  };

  const insertServiceP = async () => {
    let proccess = 'ok';
    await dataForm3.forEach(async (_sucursal, _ind) => {
      const tempDispo = dataForm4.sucursales[_ind].planes.split(',');
      const tempData = {
        nombre: dataForm1.titulo,
        descripcionPropiedad: dataForm1.descripcion,
        tipo: 'producto',
        disponibilidad: tempDispo,
        stock: dataForm4.sucursales[_ind].unidades,
        sucursales: {
          idSucursal: _sucursal.value,
        },
        status: 1,
        caracteristicas: dataForm1.caracteristicas,
        imagen: imgP,
        galeria: imgPG,
        categoria: dataForm1.categoria,
        precio: dataForm4.sucursales[_ind].planes.includes('inmediata')
          ? dataForm5.sucursales[_ind].precio_normal
          : undefined,
        precioPrevision: dataForm4.sucursales[_ind].planes.includes('prevision')
          ? dataForm5.sucursales[_ind].precio_prevision
          : undefined,
        codigo: '-',
      };
      await registrarProducto(tempData).then((respP) => {
        if (respP && !respP._id) {
          proccess = 'error';
        }
      });
      if (proccess !== 'error') {
        setStatusInsert('success');
        setStatusTitle('Producto publicado');
        setStatusMessage('El producto ha sido publicado exitosamente.');
      } else {
        setStatusInsert('error');
        setStatusTitle('Error al publicar el Producto');
        setStatusConfirmT('Intentar más tarde');
        setStatusContent('Intente nuevamente más tarde');
      }
    });
  };

  const ConfirmInsertProccess = async (_dataP) => {
    setStateStep5('ok');
    setInsertData('active');

    const tempImg = [];
    const tempImgGal = [];
    if (dataForm2 && dataForm2.imgPortada) {
      await uploadFile(dataForm2.imgPortada, dataForm1.categoria).then((dataImg) => {
        if (dataImg.ok) {
          tempImg.push({
            url: dataImg.file,
            portada: true,
          });
          setImgP(tempImg);
        }
      });
    }
    let tempGG = dataForm2.imgGaleria;
    let indexG = tempGG.findIndex((itemG) => itemG.img === '');
    if (indexG > 0) {
      tempGG = tempGG.splice((indexG), 1);
    }
    if (dataForm2 && dataForm2.imgGaleria && dataForm2.imgGaleria.length > 0) {
      await dataForm2.imgGaleria.forEach(async (_file, _ind) => {
        if (_file.img !== '') {
          const respImgG = await uploadFile(_file.img, `${dataForm1.categoria}/galeria`);
          const { file } = await respImgG;
          if (file) {
            tempImgGal.push(file);
          }
          if ((_ind + 1) === dataForm2.imgGaleria.length) {
            setImgPG(tempImgGal);
          }
        }
      });
    }
  };

  const finalConfirm = () => {
    navigate('/products');
  };
  useEffect(() => {
    if (imgP && imgPG) {
      insertServiceP();
    }
  }, [imgP, imgPG]);

  return (
    <div>
      <div className="row justify-content-center align-items-center no-margin">
        {insertData !== 'active' && (
          <>
            <div className="col-12 col-sm-6 col-md-3 col-lg-2 ">
              <i className="step-client-icon">{parse(checked1)}</i>
              <label className="step-client small-bold">Detalles</label>
            </div>
            <div className={`col-12 col-sm-6 col-md-4 col-lg-2 ${stateStep2}`}>
              <i className="step-client-icon">{parse(checked2)}</i>
              <label className="step-client small-bold">Imágenes</label>
            </div>
            <div className={`col-12 col-sm-6 col-md-4 col-lg-2 ${stateStep3}`}>
              <i className="step-client-icon">{parse(checked3)}</i>
              <label className="step-client small-bold">Sucursales</label>
            </div>
            <div className={`col-12 col-sm-6 col-md-4 col-lg-2 ${stateStep4}`}>
              <i className="step-client-icon">{parse(checked4)}</i>
              <label className="step-client small-bold">Planes</label>
            </div>
            <div className={`col-12 col-sm-6 col-md-4 col-lg-2 ${stateStep5}`}>
              <i className="step-client-icon">{parse(checked5)}</i>
              <label className="step-client small-bold">Precio</label>
            </div>
          </>
        )}
      </div>
      <div className="mb-xxl-5">
        {stateStep1 === 'active' && (
          <FrmDetailsProduct
            setDataForm={setDataForm1}
            nextStep={step2View}
            defVal={dataForm1}
          />
        )}
        {stateStep2 === 'active' && (
          <FrmImgProduct
            setDataForm={setDataForm2}
            nextStep={step3View}
            defVal={dataForm2}
            prevStep={step1View}
          />
        )}
        {stateStep3 === 'active' && (
          <FrmSucursalesProduct
            setDataForm={setDataForm3}
            nextStep={step4View}
            defVal={dataForm3}
            prevStep={step2View}
          />
        )}
        {stateStep4 === 'active' && (
          <FrmPlanesProduct
            setDataForm={setDataForm4}
            nextStep={step5View}
            defVal={dataForm4}
            prevStep={step3View}
            arraySucursales={dataForm3}
          />
        )}
        {stateStep5 === 'active' && (
          <FrmPriceProduct
            setDataForm={setDataForm5}
            nextStep={ConfirmInsertProccess}
            planesPorSucursal={dataForm4.sucursales}
            defVal={dataForm5}
            prevStep={step4View}
            arraySucursales={dataForm3}
          />
        )}
        {insertData === 'active' && (
          <StatusFormProduct
            type={statusInsert}
            title={statusTitle}
            content={statusContent}
            message={statusMessage}
            onConfirm={finalConfirm}
            confirmText={statusConfirmT}
          />
        )}
      </div>
    </div>
  );
};

export default NewProductV;
