/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { getSucursales, registerContract } from '../../services';
import ListSucu from './stepsaddprod/listSucu';
import AsociarProd from './stepsaddprod/asociarProd';
import AsigProdContract from './stepsaddprod/asigProdContract';
import DetailsContractP from './stepsaddprod/detailsContractP';
import '../../client.css';

const ViewAddComp = ({ eventCA, dataC }) => {
  const [branch, setBranch] = useState(null);

  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disable');
  const [stateStep3, setStateStep3] = useState('disable');
  const [stateStep4, setStateStep4] = useState('disable');
  const [lateral, setLateral] = useState('lateralClient');
  const [fondo, setfondo] = useState('back');
  const [step1, setStep1] = useState(null);
  const [listProdc, setListProdc] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listProdcTemp, setListProdcTemp] = useState([]);

  const [opcionSeleccionada, setOpcionSeleccionada] = useState(1);

  const [updStatus, setUpdStatus] = useState(true);
  const [updStatusLP, setUpdStatusLP] = useState(true);

  const nextStep = () => {
    let tempActS = opcionSeleccionada;
    if (tempActS === 1) {
      setStateStep1('complete');
      setStateStep2('active');
      setStateStep3('disable');
      setStateStep4('disable');
    }
    if (tempActS === 2) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('active');
      setStateStep4('disable');
      setLateral('lateralClient');
      setfondo('back');
    }
    if (tempActS === 3) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('complete');
      setStateStep4('active');
    }
    if (tempActS === 4) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('complete');
      setStateStep4('complete');
    }
    setOpcionSeleccionada(tempActS += 1);
    window.scrollTo(0, 0);
  };
  const step4 = (data) => {
    setStateStep1('complete');
    setStateStep2('complete');
    setStateStep3('conmplete');
    setStateStep4('active');
    setLateral('');
    setfondo('fondocp');
    setListProdcTemp(data);
  };

  const regresar = () => {
    nextStep();
  };

  const prevStep = (data) => {
    let tempActS = opcionSeleccionada;
    if (tempActS === 2) {
      setStateStep1('active');
      setStateStep2('disable');
      setStateStep3('disable');
      setStateStep4('disable');
      setListProdc([]);
      setStep1(null);
      setListProdcTemp([]);
    }
    if (tempActS === 3) {
      setStateStep1('complete');
      setStateStep2('active');
      setStateStep3('disable');
      setStateStep4('disable');
      if (data) {
        setListProdc(data);
      }
    }
    if (tempActS === 4) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('active');
      setStateStep4('disable');
    }
    if (tempActS === 5) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('complete');
      setStateStep4('active');
    }
    setOpcionSeleccionada(tempActS -= 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (datastep3) => {
    const user = JSON.parse(localStorage.getItem('user'));
    try {
      const tempP = [];
      let tipoP = '';
      listProdcTemp.forEach((item, indx) => {
        if (indx === 0) {
          tipoP = item._Disponibilidad;
        }
        tempP.push({
          idProducto: item._id,
          cantidad: item.Cantidad,
          disponibilidad: item._Disponibilidad,
        });
      });
      const valuesFinal = {
        folio: 1,
        fecha: new Date().getTime(),
        noContrato: datastep3.noContrato || '',
        comentarios: datastep3.comentarios || '',
        tipoPlan: tipoP,
        precioVenta: datastep3.precioVenta || 0,
        formaPago: datastep3.formaPago || '-',
        diaPago: datastep3.diaPago || 1,
        plazo: datastep3.plazo || 0,
        pagoInicial: 0,
        saldo: datastep3.precioVenta || 0,
        mensualidad: datastep3.mensualidad || 0,
        liquidado: 0,
        clients: {
          idCliente: dataC,
        },
        empleado: {
          idEmpleado: user._id,
        },
        productos: tempP,
        sucursales: {
          idSucursal: step1.idSucursal || ''
        },
        estatus_contrato: 'borrador',
        medioDeVenta: 'WEBAPP',
      };
      const createContract = await registerContract(valuesFinal);
      if (createContract) {
        eventCA(false);
        window.location.reload(false);
      }
    } catch (_error) {
      // eslint-disable-next-line no-console
      console.log(_error);
    }
  };

  const changeCount = (_operation, index) => {
    setUpdStatus(false);
    let tempLP = listProdc;
    if (_operation === '-') {
      if (tempLP[index].Cantidad > 1) {
        tempLP[index].Cantidad -= 1;
      }
    } else if (_operation === '+') {
      tempLP[index].Cantidad += 1;
    }
    setListProdc(tempLP);
    setTimeout(() => {
      setUpdStatus(true);
    }, 10);
  };
  const deleteSelectP = (index) => {
    setUpdStatusLP(false);
    const tempLP = listProdc;
    tempLP.splice(index, 1);
    setListProdc(tempLP);
    setTimeout(() => {
      setUpdStatusLP(true);
    }, 10);
  };

  useEffect(() => {
    const pag = `&paginacion=${1}`;
    const pags = `&paginas=${1000}`;
    const tempQuery = `${pag}${pags}`;
    getSucursales(tempQuery).then((data) => {
      if (data?.length > 0) {
        setBranch(data || []);
      } else {
        setBranch([]);
      }
    });
  }, []);

  return (
    <div className={`container-fluid content-full overflow-c ${fondo}`}>
      <div className={lateral}>
        <div className="row justify-content-end">
          <div className="col-2 close-form">
            <span
              className="icon-close cross-line"
              onClick={() => eventCA(false)}
            >
              {' '}
            </span>
          </div>
        </div>
        {stateStep4 === 'active' && (
          <DetailsContractP
            cambiarOpcionTres={regresar}
            handleSubmitForm={handleSubmit}
            productosL={listProdcTemp}
          // setData={setStep3}
          />
        )}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-10 col-md-12 col-12">
              <div className="content-buttom">&nbsp;</div>
              <div className="col-12 col-md-12 margin-filter">
                {stateStep1 === 'active' && (
                  <ListSucu
                    data={branch}
                    cambiarOpcionDos={nextStep}
                    setData={setStep1}
                  />
                )}
                {stateStep2 === 'active' && (
                  <AsociarProd
                    cambiarOpcionUno={prevStep}
                    cambiarOpcionTres={nextStep}
                    step4={step4}
                    listProducts={listProdc}
                    changeCount={changeCount}
                    deleteSelectP={deleteSelectP}
                    updStatus={updStatus}
                    updStatusLP={updStatusLP}
                  />
                )}
                {stateStep3 === 'active' && (
                  <AsigProdContract
                    cambiarOpcionDos={prevStep}
                    defListProduct={listProdc}
                    idSucursal={step1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAddComp;
