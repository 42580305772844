import { useQuery } from 'react-query';
import { getEmpleadosByPage, getModules, getListIngresosHistory } from '../services';

export const useEmployeesPerPage = ({
  page,
  perPage,
  search,
  order,
  location,
  onSuccess,
  onError,
}) => useQuery(
  ['employees', 'all', {
    page, search, order, location,
  }],
  () => {
    const nombre = search ? `${encodeURIComponent(search).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';
    const sucursal = location ? `&sucursal=${encodeURIComponent(location).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    return getEmpleadosByPage({
      nombre: nombre || undefined,
      sucursal: sucursal || undefined,
      orden: order,
      paginacion: page,
      paginas: perPage,
    });
  },
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);

export const useGetIngresos = ({
  paginacion,
  paginas,
  nombre,
  fecha,
  fechaInicio,
  fechaFinal,
  onSuccess,
  onError,
}) => useQuery(
  ['ingresos', 'buscar', {
    paginacion, paginas, nombre, fecha, fechaInicio, fechaFinal
  }],
  () => {
    const search = nombre ? `${encodeURIComponent(nombre).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    return getListIngresosHistory({
      nombre: search || undefined,
      paginacion,
      paginas,
      fecha: fecha === 'on',
      fechaInicio,
      fechaFinal,
    });
  },
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);

export const useModules = () => useQuery(
  ['modules', 'all'],
  () => getModules(),
  { placeholderData: { data: [] } }
);
