/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import MessageInfo from '../../../../messageInfo';
import FormBeneficiare from './formBeneficiare';

const FormStept4 = ({
  saveData, cambiarOpcion, opcionAnterior, dataP
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [arrayL, setArrayL] = useState(1);
  const [arrayLBenef, setArrayLBenef] = useState([]);
  const [validationForm, setValidationForm] = useState([]);
  const [dirtyFormm, setDirtyFormm] = useState([]);
  const divRef = useRef(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const returStep = () => {
    opcionAnterior();
    saveData(arrayLBenef);
  };
  const validationFormFunnc = (indx, value) => {
    let tempValidation = validationForm;
    tempValidation[indx] = value;
    setValidationForm(tempValidation);
  };
  const dirtyFormFunnc = (indx, value) => {
    let tempDirty = dirtyFormm;
    tempDirty[indx] = value;
    setDirtyFormm(tempDirty);
  };
  const addNewForm = () => {
    let posForm = arrayL + 1;
    setArrayL(posForm);
  };
  const removeForm = (indx) => {
    const tempDataBf = [];
    let posForm = arrayL - 1;
    setArrayL(posForm);
    arrayLBenef.forEach((item, index) => {
      if (index !== indx) {
        tempDataBf.push(item);
      }
    });
    setArrayLBenef(tempDataBf);
  };
  const saveDataForms = (indx, value) => {
    let tempData = arrayLBenef;
    tempData[indx] = value;
    setArrayLBenef(tempData);
  };

  const handleSubmitStep = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!validationForm.find((element) => element === 'false')
      && !dirtyFormm.find((element) => element === 'false')) {
      saveData(arrayLBenef);
      cambiarOpcion(arrayLBenef);
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="container" id="FORM-REF" ref={divRef}>
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <div className="mt-xxl">
            <h2>Beneficiario principal</h2>
          </div>
        </div>
        <form onSubmit={handleSubmitStep}>
          <FormBeneficiare
            returndata={saveDataForms}
            inx={0}
            submitted={submitted}
            validation={validationFormFunnc}
            dirtyForm={dirtyFormFunnc}
            defData={arrayLBenef[0] || null}
          />
          {arrayL > 1 && _.times(arrayL, (i) => (
            <div key={generateKey(i)}>
              {i > 0 && (
                <div>
                  <div className="mt-xxl">
                    <div className="row">
                      <div className="col-12 col-md-12 margin-filter">
                        <div className="mt-xxl">
                          <div className="row">
                            <div className="col-12 col-md-9">
                              <h2>
                                Beneficiario secundario
                              </h2>
                            </div>
                            <div className="col-12 col-md-3 btn-content-filt">
                              <button className="button-secondary " type="button" onClick={() => removeForm(i)}>
                                &nbsp;&nbsp;Eliminar&nbsp;&nbsp;
                                <i className="btn-icon">&#59697;</i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormBeneficiare
                    returndata={saveDataForms}
                    inx={i}
                    submitted={submitted}
                    validation={validationFormFunnc}
                    dirtyForm={dirtyFormFunnc}
                    defData={arrayLBenef[i] || null}
                  />
                </div>
              )}
            </div>
          ))}
          <div className="mt-xxl">
            {arrayL < 5 && (
              <div className="row">
                <div className="col-12 col-md-9">
                  <h2>Registrar beneficiario adicional</h2>
                </div>
                <div className="col-12 col-md-3 btn-content-filt">
                  <button className="button-secondary " type="button" onClick={() => addNewForm()}>
                    &nbsp;&nbsp;Agregar&nbsp;&nbsp;
                    <i className="btn-icon">&#59672;</i>
                  </button>
                </div>
                <div className="col-12 ">
                  <div className="mt-m">
                    <MessageInfo
                      message="Puedes registrar un máximo de cinco beneficiarios."
                      type=""
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row mt-m">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
              <button className="input-label-float-right button-tertiary" type="button" onClick={returStep}>
                Regresar
              </button>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <button className="button " type="submit">
                Crear contrato
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormStept4;
