import AddProspectsC from '../../AddProspectsC';

const AddProspectForm = ({
  eventOp, saveData
}) => (
  <div className="container-fluid navBar-right">
    <div className="row container-white container-h-full">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="container-fluid">
          <div className="row content-ovf">
            <div className="col close-form">
              <span
                className="icon-close cross-line"
                onClick={eventOp}
              >
                {' '}
              </span>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-12 col-lg-5">
                <AddProspectsC saveData={saveData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddProspectForm;
