/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import countriesData from '../../../../global/data/countries.json';
import { FEditDataA } from '../../formik/formik';
import { findCP_S } from '../../services';

const FrmDataAddressBeneficiaries = ({
  setDataForm, defVal, closeFormEvent
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FEditDataA();

  const [localidades, setLocalidades] = useState([]);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const closeForm = () => {
    closeFormEvent();
  };

  const {
    cp,
    estado,
    ciudad,
    colonia,
    direccion,
  } = values;
  const [submitted, setSubmitted] = useState(false);
  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth()) || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity && selectedEntity.loc) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      setSubmitted(false);
      setDataForm(values);
    } else {
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    if (defVal) {
      setFieldValue('cp', defVal.cp);
      setFieldValue('estado', defVal.estado);
      setFieldValue('ciudad', defVal.ciudad);
      setFieldValue('colonia', defVal.colonia);
      setFieldValue('direccion', defVal.direccion);
    }
  }, [defVal]);

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="cp" className="label mt-m ">
                    Código postal
                    <input
                      id="cp"
                      type="number"
                      maxLength="5"
                      pattern="[0-9]{X,5}"
                      className={`input ${submitted && errors.cp && 'has-error'}`}
                      onChange={handleChange('cp')}
                      value={cp}
                    />
                    {
                      submitted && errors.cp && <small className="caption-error">{errors.cp}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="estado" className="label mt-m">
                    Estado
                    <select
                      type="select"
                      name="estado"
                      className={`${submitted && errors.estado && 'has-error'}`}
                      value={estado}
                      onChange={handleChange('estado')}
                    >
                      <option value="">Estado</option>
                      {countriesData.map(({ states }) => (
                        <option key={states} name={states} value={states}>
                          {states}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="ciudad" className="label mt-m">
                    Localidad o Municipio
                    <select
                      type="select"
                      name="ciudad"
                      className={`${submitted && errors.ciudad && 'has-error'}`}
                      value={ciudad}
                      onChange={handleChange('ciudad')}
                    >
                      <option value="">Localidad</option>
                      {localidades.map((loc) => (
                        <option key={loc} name={loc} value={loc}>
                          {loc}
                        </option>
                      ))}
                    </select>
                    {
                      submitted && errors.ciudad && <small className="caption-error">{errors.ciudad}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="colonia" className="label mt-m ">
                    Colonia
                    {!colonies && (
                      <input
                        id="colonia"
                        type="text"
                        className={`input ${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                      />
                    )}
                    {colonies && (
                      <select
                        type="select"
                        name="colonia"
                        className={`${submitted && errors.colonia && 'has-error'}`}
                        value={colonia}
                        onChange={handleChange('colonia')}
                      >
                        <option value="">-Seleccione la Colonia-</option>
                        {colonies.map((_colonia) => (
                          <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                            {_colonia}
                          </option>
                        ))}
                      </select>
                    )}
                    {
                      submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                    }
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="direccion" className="label mt-m ">
                    Dirección
                    <input
                      id="direccion"
                      type="text"
                      className={`input ${submitted && errors.direccion && 'has-error'}`}
                      value={direccion}
                      onChange={handleChange('direccion')}
                    />
                    {
                      submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row mt-m">
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <button className="input-label-float-right button-tertiary" onClick={closeForm}>
                    Cancelar
                  </button>
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                  <button className="button" type="submit">
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default FrmDataAddressBeneficiaries;
