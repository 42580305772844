/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { FEditReference } from '../formik/formik';
import countriesData from '../../../global/data/countries.json';
import { updateContract, findCP_S } from '../services';

const EditReference = ({
  eventV, dataC, dataR, refers, eventShowMessage, idC
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [statusApi, setStatusApi] = useState(null);
  const [localidades, setLocalidades] = useState([]);
  const [switchC, setSwitchC] = useState(false);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = FEditReference();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (isValid && dirty) {
      setStatus('loading');
      const tempParam = {
        nombre: values.nombre,
        apellidoPaterno: values.apellidoPaterno,
        apellidoMaterno: values.apellidoMaterno,
        direccion: values.direccion,
        colonia: values.colonia,
        ciudad: values.ciudad,
        estado: values.estado,
        cp: values.cp,
        telefono: values.telefono,
        email: values.email,
      };
      setStatus('idle');

      let tempRef = dataR;
      tempRef[refers] = tempParam;
      if (refers === 1 && !dataR[0]) {
        tempRef[0] = {};
      } else if (refers === 1 && dataR[0]) {
        const json = dataR[0];
        const key = '_id';
        delete json[key];
        tempRef[0] = json;
      }
      if (refers === 0 && dataR[1]) {
        const json = dataR[1];
        const key = '_id';
        delete json[key];
        tempRef[1] = json;
      }

      const temData = {
        referencias: tempRef,
      };

      await updateContract(idC, temData).then((resp) => {
        if (resp) {
          eventV(false);
          eventShowMessage();
          // window.location.reload(false);
        } else {
          setStatusApi('No se pudo actualizar, intente denuevo mas tarde.');
        }
      });
      // const res = await requestPasswordChange(email);
      // console.log(res);
      // setStatus(res.ok ? 'success' : 'error');
    }
  };

  useEffect(() => {
    if (!isValid || !dirty) {
      setStatus('idle');
    } else {
      setStatus('');
      setStatusApi(null);
    }
  }, [values, isValid, dirty, errors]);

  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    direccion,
    colonia,
    ciudad,
    estado,
    cp,
    telefono,
    email,
  } = values;

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  useEffect(() => {
    if (
      dataR
      && dataR[refers]
      && dataR[refers].nombre
    ) {
      setFieldValue('ciudad', dataR[refers].ciudad);
      setFieldValue('nombre', dataR[refers].nombre);
      setFieldValue('apellidoPaterno', dataR[refers].apellidoPaterno);
      setFieldValue('apellidoMaterno', dataR[refers].apellidoMaterno);
      setFieldValue('direccion', dataR[refers].direccion);
      setFieldValue('colonia', dataR[refers].colonia);
      setFieldValue('estado', dataR[refers].estado);
      setFieldValue('cp', dataR[refers].cp);
      setFieldValue('telefono', dataR[refers].telefono);
      setFieldValue('email', dataR[refers].email);
    }
  }, [dataR]);

  useEffect(() => {
    if (switchC && dataC) {
      if (dataC.domicilio && dataC.domicilio[1] && dataC.domicilio[1].cp) {
        setFieldValue('estado', dataC.domicilio[1].estado);
        setFieldValue('ciudad', dataC.domicilio[1].ciudad);
        setFieldValue('direccion', dataC.domicilio[1].direccion);
        setFieldValue('colonia', dataC.domicilio[1].colonia);
        setFieldValue('cp', dataC.domicilio[1].cp);
      } else if (dataC.domicilio && dataC.domicilio[0] && dataC.domicilio[0].cp) {
        setFieldValue('estado', dataC.domicilio[0].estado);
        setFieldValue('ciudad', dataC.domicilio[0].ciudad);
        setFieldValue('direccion', dataC.domicilio[0].direccion);
        setFieldValue('colonia', dataC.domicilio[0].colonia);
        setFieldValue('cp', dataC.domicilio[0].cp);
      } else {
        setFieldValue('estado', dataC.estado);
        setFieldValue('ciudad', dataC.ciudad);
        setFieldValue('direccion', dataC.direccion);
        setFieldValue('colonia', dataC.colonia);
        setFieldValue('cp', dataC.cp);
      }
      setColonies(null);
    } else if (
      dataR
      && dataR[refers]
      && dataR[refers].nombre
    ) {
      setFieldValue('ciudad', dataR[refers].ciudad);
      setFieldValue('direccion', dataR[refers].direccion);
      setFieldValue('colonia', dataR[refers].colonia);
      setFieldValue('estado', dataR[refers].estado);
      setFieldValue('cp', dataR[refers].cp);
    } else {
      setFieldValue('ciudad', '');
      setFieldValue('direccion', '');
      setFieldValue('colonia', '');
      setFieldValue('estado', '');
      setFieldValue('cp', '');
    }
  }, [switchC]);

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((_data) => {
      if (_data && _data.respuesta && _data.respuesta.codigos_postales) {
        if (_data.respuesta.codigos_postales.length === 1) {
          _data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (_data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          _data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (!switchC && cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (!switchC && dataC
      && !dataR
      && !dataR[refers] && cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span className="icon-close cross-line" onClick={() => eventV(false)}>
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>
                {refers === 0
                  ? 'Referencia principal'
                  : 'Referencia secundaria'}
              </h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="">
                <div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="nombre" className="label mt-m">
                        Nombre(s)
                        <input
                          id="nombre"
                          type="text"
                          className={`input ${submitted && errors.nombre && 'has-error'}`}
                          onChange={handleChange('nombre')}
                          value={nombre}
                        />
                        {submitted && errors.nombre && (
                          <small className="caption-error">
                            {errors.nombre}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="apellidoPaterno" className="label mt-m">
                        Apellido Paterno
                        <input
                          id="apellidoPaterno"
                          type="text"
                          className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                          onChange={handleChange('apellidoPaterno')}
                          value={apellidoPaterno}
                        />
                        {submitted && errors.apellidoPaterno && (
                          <small className="caption-error">
                            {errors.apellidoPaterno}
                          </small>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="apellidoMaterno" className="label mt-m">
                        Apellido Materno
                        <input
                          id="apellidoMaterno"
                          type="text"
                          className={`input ${submitted && errors.apellidoMaterno && 'has-error'}`}
                          onChange={handleChange('apellidoMaterno')}
                          value={apellidoMaterno}
                        />
                        {submitted && errors.apellidoMaterno && (
                          <small className="caption-error">
                            {errors.apellidoMaterno}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="telefono" className="label mt-m">
                        Teléfono
                        <input
                          id="telefono"
                          type="text"
                          className={`input ${submitted && errors.telefono && 'has-error'}`}
                          onChange={handleChange('telefono')}
                          value={telefono}
                        />
                        {submitted && errors.telefono && (
                          <small className="caption-error">
                            {errors.telefono}
                          </small>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="email" className="label mt-m">
                        Correo electronico
                        <input
                          id="email"
                          type="text"
                          className={`input ${submitted && errors.email && 'has-error'}`}
                          onChange={handleChange('email')}
                          value={email}
                        />
                        {submitted && errors.email && (
                          <small className="caption-error">
                            {errors.email}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
                  </div>
                  <div className="row">
                    <div className="col-12">&nbsp;</div>
                    <div className="col-12">
                      <h2>Domicilio</h2>
                    </div>
                  </div>
                  <div className="row prospect-message-cont">
                    <div className="col-10 col-md-11 ">
                      <p>Mi referencia tiene la misma dirección que yo</p>
                    </div>
                    <div className="col-2 col-md-1 ">
                      <Switch
                        checked={switchC}
                        onChange={() => setSwitchC(!switchC)}
                        onColor="#000000"
                        onHandleColor="#FFFFFF"
                        offColor="#CFCFCF"
                        offHandleColor="#FFFFFF"
                        borderRadius={60}
                        handleDiameter={5}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={25}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="cp" className="label mt-m">
                        Código postal
                        <input
                          id="cp"
                          type="number"
                          maxLength="5"
                          pattern="[0-9]{X,5}"
                          className={`input ${submitted && errors.cp && 'has-error'}`}
                          onChange={handleChange('cp')}
                          value={cp}
                        />
                        {submitted && errors.cp && (
                          <small className="caption-error">{errors.cp}</small>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
                    {statusApi && (
                      <small className="caption-error">{statusApi}</small>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="estado" className="label mt-m">
                        Estado
                        <select
                          type="select"
                          name="estado"
                          className={`${submitted && errors.estado && 'has-error'}`}
                          value={estado}
                          onChange={handleChange('estado')}
                        >
                          <option value="">Estado</option>
                          {countriesData.map(({ states }) => (
                            <option
                              key={generateKey(states)}
                              name={states}
                              value={states}
                            >
                              {states}
                            </option>
                          ))}
                        </select>
                        {submitted && errors.estado && (
                          <small className="caption-error">
                            {errors.estado}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="ciudad" className="label mt-m">
                        Localidad o Municipio
                        <select
                          type="select"
                          name="ciudad"
                          className={`${submitted && errors.ciudad && 'has-error'}`}
                          value={ciudad}
                          onChange={handleChange('ciudad')}
                        >
                          <option value="">Localidad</option>
                          {localidades && localidades.map((loc) => (
                            <option
                              key={generateKey(loc)}
                              name={loc}
                              value={loc}
                            >
                              {loc}
                            </option>
                          ))}
                        </select>
                        {submitted && errors.ciudad && (
                          <small className="caption-error">
                            {errors.ciudad}
                          </small>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="direccion" className="label mt-m">
                        Dirección
                        <input
                          id="direccion"
                          type="text"
                          className={`input ${submitted && errors.direccion && 'has-error'}`}
                          onChange={handleChange('direccion')}
                          value={direccion}
                        />
                        {submitted && errors.direccion && (
                          <small className="caption-error">
                            {errors.direccion}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="colonia" className="label mt-m">
                        Colonia
                        {!colonies && (
                          <input
                            id="colonia"
                            type="text"
                            className={`input ${submitted && errors.colonia && 'has-error'}`}
                            onChange={handleChange('colonia')}
                            value={colonia}
                          />
                        )}
                        {colonies && (
                          <select
                            type="select"
                            name="colonia"
                            className={`${submitted && errors.colonia && 'has-error'}`}
                            value={colonia}
                            onChange={handleChange('colonia')}
                          >
                            <option value="">-Seleccione la Colonia-</option>
                            {colonies.map((_colonia) => (
                              <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                                {_colonia}
                              </option>
                            ))}
                          </select>
                        )}
                        {submitted && errors.colonia && (
                          <small className="caption-error">
                            {errors.colonia}
                          </small>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="col-12 col-md-12">&nbsp;</div>
                    <div className="col-12 col-md-12">
                      <button
                        type="button"
                        onClick={() => eventV(false)}
                        className="button-secondary p-r m-btn-prof"
                      >
                        Cancelar
                      </button>
                      <button
                        className="button mt-m"
                        type="submit"
                        disabled={status === 'idle' ? 'disabled' : ''}
                      >
                        Guardar cambios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default EditReference;
