import { useState, useEffect } from 'react';
import { FormikStep1 } from '../../formik/formikSteps';
import maritalStatus from '../../../../global/data/maritalStatus.json';
import '../../prospects.css';
import Toggle from '../../../shared/toggle/Toggle';
import { validEmailServ } from '../../servives';

const AddProspStep1 = ({ setDataForm, nextStep, defVal }) => {
  const [submitted1, setSubmitted1] = useState(false);
  const [status, setStatus] = useState('idle');
  const [validEmail, setValidEmail] = useState(false);

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FormikStep1(defVal || {});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted1(true);
    // nextStep();
    if (isValid && email !== '' && !validEmail) {
      setDataForm(values);
      nextStep();
    } if (isValid && email === '') {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
    }
  };

  const {
    nombre, apellidoP, apellidoM, edad, gender, estado_civil, phone, email,
  } = values;

  const verifyEmailExist = async () => {
    try {
      await validEmailServ(email).then((data) => {
        if (data && data[0]) {
          setValidEmail(true);
          setStatus('');
        } else {
          setValidEmail(false);
          setStatus('idle');
        }
      });
    } catch (_error) {
      setValidEmail(false);
      setStatus('idle');
    }
  };

  useEffect(() => {
    if (isValid) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [isValid, dirty]);

  useEffect(() => {
    if (!errors.email && email) {
      verifyEmailExist();
      // setStatus('idle');
    } else if (email === '') {
      setValidEmail(false);
    }
  }, [email]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Nombre(s)
            <input
              id="nombre"
              type="text"
              className={`input ${submitted1 && errors.nombre && 'has-error'}`}
              value={nombre}
              placeholder=""
              onChange={handleChange('nombre')}
            />
            {submitted1 && errors.nombre && (
              <small className="caption-error">
                {errors.nombre}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6"> </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Primer apellido
            <input
              id="apellidoP"
              type="text"
              className={`input ${submitted1 && errors.apellidoP && 'has-error'}`}
              value={apellidoP}
              placeholder=""
              onChange={handleChange('apellidoP')}
            />
            {submitted1 && errors.apellidoP && (
              <small className="caption-error">
                {errors.apellidoP}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Segundo apellido
            <input
              id="apellidoM"
              type="text"
              className={`input ${submitted1 && errors.apellidoM && 'has-error'}`}
              value={apellidoM}
              placeholder=""
              onChange={handleChange('apellidoM')}
            />
            {submitted1 && errors.apellidoM && (
              <small className="caption-error">
                {errors.apellidoM}
              </small>
            )}
            <small>(Opcional)</small>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Edad
            <input
              id="edad"
              type="number"
              className={`input ${submitted1 && errors.edad && 'has-error'}`}
              value={edad}
              placeholder=""
              onChange={handleChange('edad')}
            />
            {submitted1 && errors.edad && (
              <small className="caption-error">
                {errors.edad}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Género
            <br />
            <Toggle
              options={[
                {
                  name: 'Masculino',
                  value: 'masculino',
                },
                {
                  name: 'Femenino',
                  value: 'femenino',
                }
              ]}
              activeOp={gender}
              event={(e) => setFieldValue('gender', e)}
            />
            {submitted1 && errors.gender && (
              <small className="caption-error">
                {errors.gender}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label htmlFor="estado_civil" className="label mt-m">
            Estado civil
            <select
              type="estadoCivil"
              value={estado_civil}
              onChange={handleChange('estado_civil')}
            >
              <option>Seleccione una opción</option>
              {maritalStatus.map((items) => (
                <option key={items.value} name={items.value} value={items.value}>
                  {items.label}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="col-12 col-md-6"> </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Teléfono
            <input
              id="phone"
              type="text"
              className={`input ${submitted1 && errors.phone && 'has-error'}`}
              value={phone}
              placeholder="10 dígitos"
              onChange={handleChange('phone')}
            />
            {submitted1 && errors.phone && (
              <small className="caption-error">
                {errors.phone}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Correo electrónico
            <input
              id="email"
              type="email"
              className={`input ${submitted1 && errors.email && 'has-error'}`}
              value={email}
              placeholder=""
              onChange={handleChange('email')}
            />
            {submitted1 && errors.email && (
              <small className="caption-error">
                {errors.email}
                <br />
              </small>
            )}
            {validEmail && (
              <small className="caption-error">
                Este correo ya esta registrado
                <br />
              </small>
            )}
            <small>(Opcional)</small>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Siguiente paso
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddProspStep1;
