import axios from '../../../store/axios';

export const registerClient = async (data) => {
  const res = await axios().post('clientes', data);
  return res;
};

export const getClientes = async (query) => {
  try {
    const res = await axios().get(`clientes/all?${query}`, {});
    return res;
  } catch (err) {
    return err;
  }
};

export const getClientsByPage = async ({
  filtro, paginacion, paginas, ubicacion, orden, antiguedad,
}) => {
  try {
    const res = await axios().get('clientes/all', {
      params: {
        filtro,
        paginacion,
        paginas,
        ubicacion,
        orden,
        antiguedad,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getClientByID = async (idClient) => {
  try {
    const res = await axios().get(`clientes/buscar/${idClient}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getContractClient = async (idClient, paginacion, paginas) => {
  try {
    const res = await axios().get(`clientes/contratos/${idClient}`, {
      params: {
        paginacion,
        paginas,
      }
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const updateEmployee = async (idEmployee, params) => {
  try {
    const res = await axios().put(`clientes/actualizar/${idEmployee}`, params);
    return res;
  } catch (err) {
    return err;
  }
};

export const findCP_S = async (query) => {
  try {
    const res = await axios().get(`/cp/all?${query}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getSucursales = async (query = '&paginacion=1&paginas=1000') => {
  try {
    const res = await axios().get(`sucursales/buscar?${query}`);
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const registerContract = async (data) => {
  const res = await axios().post('ventas', data);
  return res;
};
