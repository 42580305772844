import axios from '../../../../store/axios';

export const addProductPros = async (id, params) => {
  try {
    const res = await axios().put(`/prospectos/actualizar/${id}`, params);
    return res;
  } catch (err) {
    return err;
  }
};
