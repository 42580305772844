import './profileDef.css';

const ProfileDefComponent = ({ name, size, imgProf }) => {
  const checkText = (textL) => {
    try {
      let tempT = textL.match(/\b(\w)/g);
      tempT = tempT.join('').toUpperCase();
      tempT = tempT.slice(0, 2);
      return tempT;
    } catch (error) {
      return '';
    }
  };
  return (
    <div className={`profile-def ${size}`}>
      {imgProf ? (
        <img src={imgProf} alt="Perfil" />
      ) : (
        <label>{checkText(name)}</label>
      )}
    </div>
  );
};

export default ProfileDefComponent;
