import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    email: '',
  },
  validationSchema: Yup.object({
    email: Yup.string()
      .email('La dirección de correo no es válida')
      .required('El correo electrónico es requerido')
  }),
});
