import React from 'react';
import { useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import RegisterCoupon from '../../features/promotions/components/registerCoupon/RegisterCoupon';

const AddCoupon = () => {
  const navigate = useNavigate();

  const closeForm = () => {
    navigate('/promotions');
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={closeForm}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <RegisterCoupon onClose={closeForm} />
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default AddCoupon;
