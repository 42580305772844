import './notFoundAlert.css';

const NotFoundAlert = ({ icon, title, message }) => (
  <div className="not-found-alert">
    <div className="row">
      <div className="col-12 icon-container">
        <label className="icon">{icon}</label>
      </div>
      <div className="col-12">
        <label className="title">{title}</label>
      </div>
      <div className="col col-md-4">&nbsp;</div>
      <div className="col-12 col-md-4">
        <label className="info">{message}</label>
      </div>
      <div className="col col-md-4">&nbsp;</div>
    </div>
  </div>
);

export default NotFoundAlert;
