/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
import parse from 'html-react-parser';
import ProfileDefComponent from '../../shared/profileDef';
import { monthDayYearFormat } from '../../../helpers/dates';

const DetailsContractNav = ({
  data, eventShowMessage, refresh, permissions, eventOp, navigateFunct
}) => {
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const styleMoney = (money) => {
    const tempM = money.split('.');
    const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
    return tempHtml;
  };

  return (
    <>
      <div>
        <div>
          <div className="row">
            <div className="col-6 col-md-8">
              <h2 className="title-prof">Detalles del contrato</h2>
            </div>
            {permissions('Contratos', 'editAnyContract') && (
              <div className="col-6 col-md-4 btn-content-filt" onClick={() => navigateFunct('contract-data')}>
                <button className="button-secondary title-prof" type="button">&nbsp;&nbsp;Editar detalles<i className="btn-icon">&#59686;</i>&nbsp;&nbsp;</button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <label className="datos-title">Número de contrato</label>
            <label className="datos-info">{data.noContrato || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Folio</label>
            <label className="datos-info">{data.folio || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Sucursal de venta</label>
            <label className="datos-info text-capitalize">{data.sucursal || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Fecha creación</label>
            <label className="datos-info">
              {monthDayYearFormat(data.fecha)}
            </label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Medio de venta</label>
            <label className="datos-info">{data.medioDeVenta || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Estatus del Contrato</label>
            <label className="datos-info">{data.estatus_contrato.toUpperCase() || '-'}</label>
          </div>
          <div className="col-12">
            <label className="datos-title">Comentarios</label>
            <label className="datos-info">{data.comentarios || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Lote Asignado</label>
            <label className="datos-info">{data.lote || '-'}</label>
          </div>
        </div>
      </div>
      <hr className="prof-hr" />
      <div>
        <div>
          <h2 className="title-prof">Detalles del pago</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <label className="datos-title">Precio total</label>
            <label className="datos-info text-money">{data ? styleMoney(formatMoney.format(data.precioVenta)) : '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Tipo de pago</label>
            <label className="datos-info">{data?.formaPago?.toUpperCase() || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Mensualidades</label>
            <label className="datos-info text-money">{data.plazo || '-'} X {styleMoney(formatMoney.format(data.mensualidad)) || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Fecha de pago</label>
            <label className="datos-info">{data.diaPago || '-'} de cada mes</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Pago inicial</label>
            <label className="datos-info text-money">{data ? styleMoney(formatMoney.format(data.pagoInicial)) : '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Total abonado</label>
            <label className="datos-info text-money">{data ? styleMoney(formatMoney.format(data.liquidado)) : '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Saldo restante</label>
            <label className="datos-info text-money">{data ? styleMoney(formatMoney.format(data.saldo)) : '-'}</label>
          </div>
        </div>
      </div>
      <hr className="prof-hr" />
      {permissions('Contratos', 'verEmpleadoAsociado') && (
        <div>
          <div>
            <h2 className="title-prof">Vendido por</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-1 item-c-contr">
              <ProfileDefComponent name={(data.nombre_empleado || '')} size="sm" imgProf={data.foto_empleado || null} />
            </div>
            <div className="col-11">
              <h2 className="text-capitalize">{data.nombre_empleado || ''}</h2>
            </div>
          </div>
        </div>
      )}
      {permissions('Contratos', 'asignarCobrador') && (
        <div>
          <div className="row ">
            <div className="col-8 col-md-10">
              <h2 className="title-prof">Cobrador</h2>
            </div>
            <div className="col-4 col-md-2">
              {!data?.cobradorData?.nameC && (
                <button className="button p-r title-prof" onClick={eventOp}>
                  &nbsp;&nbsp;Asignar&nbsp;&nbsp;
                </button>
              )}
              {data?.cobradorData?.nameC && (
                <button className="button-secondary title-prof" onClick={eventOp}>
                  &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                  <i className="btn-icon">&#59686;</i>
                </button>
              )}
            </div>
          </div>
          {data?.cobradorData?.nameC && (
            <div className="row align-items-center">
              <div className="col-1 item-c-contr">
                <ProfileDefComponent name={(data.cobradorData?.nameC || '')} size="sm" imgProf={data?.cobradorData?.imagen_perfil || null} />
              </div>
              <div className="col-11">
                <h2 className="text-capitalize">{data.cobradorData?.nameC || ''}</h2>
              </div>
            </div>
          )}
          {!data?.cobradorData?.nameC && (
            <div className="row align-items-center">
              <div className="col-1 item-c-contr">
                <ProfileDefComponent name={null} size="sm" imgProf="/assets/img/def-user.webp" />
              </div>
              <div className="col-11">
                <h2>Sin asignar</h2>
              </div>
            </div>
          )}
        </div>
      )}
      <hr className="prof-hr" />
      <div className="content-buttom">&nbsp;</div>
    </>
  );
};

export default DetailsContractNav;
