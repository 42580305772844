/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListEmpleados from './components/listEmployees/listEmpleados';
import SinEmpleados from './components/listEmployees/sinEmpleados';
import SinRegistro from './components/listEmployees/sinRegistro';
import ListLoginRegEmployee from './components/listEmployees/loginReg';
import './emple.css';
import NavPage from '../navPage';
import { getAllSucursales } from './services/index';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import { useEmployeesPerPage } from './hooks/queries';

const ListEmployees = () => {
  const navigate = useNavigate();
  const [sucursals, setSucursals] = useState(null);
  const [selectSucursal, setSelectSucursal] = useState(null);
  const [dropDown2, setDropDown2] = useState(false);
  const [searchInp, setSearchInp] = useState('');
  const [page, setPage] = useState(1);
  const [orderList, setOrderList] = useState('asc');
  const [listSucursal, setListSucursal] = useState(false);

  const [optionNav] = useState(['Empleados', 'Ingresos al sistema']);
  const [activeNav, setActiveNav] = useState('Empleados');
  const perPage = 10;

  const { data: queryData } = useEmployeesPerPage({
    perPage,
    order: orderList,
    page,
    search: searchInp,
    location: selectSucursal
  });

  const total = queryData?.total;
  const employeesList = queryData?.data;
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const totalPages = Math.ceil(total / perPage);
  const searchEnabled = searchInp.length > 0 || selectSucursal !== null;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const eventChangeNav = (item) => {
    setActiveNav(item);
  };
  const openDrop2 = () => {
    setDropDown2(!dropDown2);
  };

  const listSucursalF = () => {
    setListSucursal(!listSucursal);
  };

  const getSucursalesF = async () => {
    const pag = '&paginacion=1';
    const pags = '&paginas=1000';
    const tempQuery = `${pag}${pags}`;
    await getAllSucursales(tempQuery).then((data) => {
      if (data && data.length && data.length > 0) {
        setSucursals(data);
      }
    });
  };
  const addEmployeeN = () => {
    navigate('/add-contributors');
  };

  const searchEmployees = (e) => {
    setSearchInp(e.target.value);
  };

  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (!token) {
        closeSession();
      }
    }
  };

  const changeOrderList = () => {
    if (orderList === 'asc') {
      setOrderList('desc');
    } else {
      setOrderList('asc');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (!token) {
      closeSession();
    }
  }, [page, searchInp, orderList, selectSucursal]);
  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token) {
      getSucursalesF();
    } else {
      closeSession();
    }
  }, []);
  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp]);
  return (
    <div>
      {checkPermisionRoute('Empleados', 'verLogins') && (
        <div className="row">
          <div className="col-12 col-md-9 col-lg-7">
            <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} classNR="nav-full-r" />
            <div className="content-buttom">&nbsp;</div>
          </div>
        </div>
      )}
      {checkPermisionRoute('Empleados', 'verLogins')
        && activeNav === 'Ingresos al sistema' && <ListLoginRegEmployee />}
      {activeNav === 'Empleados' && (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-6 margin-filter">
                  <h2>Empleados</h2>
                </div>
                <div className="col-12 col-md-6 btn-content-filt margin-filter">
                  {checkPermisionRoute('Empleados', 'crearEmpleado') && (
                    <button className="button" onClick={addEmployeeN}>
                      &nbsp;&nbsp;Nuevo Empleado&nbsp;&nbsp;
                      {' '}
                      <i className="btn-icon">&#59672;</i>
                      &nbsp;&nbsp;
                    </button>
                  )}
                </div>
                <div className="col-12 margin-filter">
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <label>{total || 0} resultados</label>
                </div>
                <div className="col-12 col-md-4 margin-filter">
                  <div className="col-12 col-md-12">
                    <div className="input-container">
                      <input id="buscar" onChange={(e) => searchEmployees(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                      <span className="input-icon search-line" />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 margin-filter content-on">
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <button className="button-secondary btn-p-cl p-r" onClick={listSucursalF}>
                    &nbsp;&nbsp;Filtro&nbsp;
                    <i className="btn-icon">&#59674;</i>
                    &nbsp;&nbsp;
                    {listSucursal && (
                      <div className="drop-menu lg" onMouseLeave={() => setListSucursal(false)}>
                        <label onClick={() => setSelectSucursal(null)}>
                          Todas
                        </label>
                        {
                          sucursals && sucursals.map((item) => (
                            <label onClick={() => setSelectSucursal(item.nombre)} key={item._id}>
                              {item.nombre}
                            </label>
                          ))
                        }
                      </div>
                    )}
                  </button>
                </div>
                <div className="col-12 col-md-4 btn-content-filt margin-filter content-on">
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <button onClick={openDrop2} className="button-secondary btn-p-cl p-r">
                    &nbsp;&nbsp;
                    {orderList === 'asc' ? 'A a Z' : 'Z a A'}
                    &nbsp;
                    <i className="btn-icon">&#59657;</i>
                    &nbsp;&nbsp;
                    {dropDown2 && (
                      <div className="drop-menu">
                        <label onClick={changeOrderList}>
                          {orderList !== 'asc' ? 'A a Z' : 'Z a A'}
                        </label>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          {total > 0 && employeesList
            && <ListEmpleados data={employeesList} permision={checkPermisionRoute} />}
          {total === 0 && !searchEnabled && <SinRegistro />}
          {total === 0 && searchEnabled && <SinEmpleados />}
          {employeesList && (
            <div className="container pag-content">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      <label className="text-pag">{page} de {totalPages}</label>
                    </div>
                    <div className="col-12 col-md-6 pag-content-btn">
                      <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
                      <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListEmployees;
