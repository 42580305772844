/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import ProfileDefComponent from '../../../shared/profileDef';
import { useGetIngresos } from '../../hooks/queries';
import SinRegistro from './sinRegistro';
import { monthDayYearFormat } from '../../../../helpers/dates';

const ListLoginRegEmployee = () => {
  const navigate = useNavigate();
  const [searchInp, setSearchInp] = useState('');
  const [page, setPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [monthDropDown, setMonthDropDown] = useState(false);
  const [filterByMonth, setFilterByMonth] = useState('');

  const months = [
    { value: '', label: 'Mes Actual' },
    { value: 'lastMonth', label: 'Mes Anterior' },
  ];

  const toggleMonthDropDown = () => {
    setMonthDropDown(!monthDropDown);
  };

  const changeMonthSelection = (month) => {
    setSelectedMonth(month);
  };

  const handleFilterByMonth = (month) => {
    changeMonthSelection(month);
  };

  const perPage = 10;
  const currentDate = new Date();
  const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const fechaInicial = selectedMonth === 'lastMonth' ? lastMonth : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const fechaFin = selectedMonth === 'lastMonth' ? new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0) : new Date(fechaInicial.getFullYear(), fechaInicial.getMonth() + 1, 0);

  const { data: queryData } = useGetIngresos({
    paginacion: page,
    paginas: perPage,
    nombre: searchInp,
    selectedMonth: filterByMonth,
    fecha: selectedMonth === 'lastMonth' ? undefined : filterByMonth,
    fechaInicio: fechaInicial.toISOString().split('T')[0],
    fechaFinal: fechaFin.toISOString().split('T')[0],
  });

  useEffect(() => {
    resetPage();
  }, [selectedMonth]);

  const total = queryData?.total;
  const employeesList = queryData?.data;
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const totalPages = Math.ceil(total / perPage);

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const searchEmployees = (e) => {
    setSearchInp(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (!token) {
        closeSession();
      }
    }
  };

  const addPage = () => {
    setPage(page + 1);
  };
  const subsPage = () => {
    setPage(page - 1);
  };

  const resetPage = () => {
    setPage(1);
  };

  useEffect(() => {
    resetPage();
  }, [selectedMonth]);

  /* const formatDateTime = (date) => {
    try {
      const newDate = new Date(date);
      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      let currentHours = newDate.getHours();
      currentHours = (`0${currentHours}`).slice(-2);
      let currentMinut = newDate.getMinutes();
      currentMinut = (`0${currentMinut}`).slice(-2);
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${meses[newDate.getMonth()]} ${tempDate.substr(tempDate.indexOf(' ') + 1)}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  };
  */
  const changeTimezone = (date, ianatz) => {
    const invdate = new Date(date.toLocaleString('en-US', {
      timeZone: ianatz
    }));
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
  };
  const formatDateTimeHour = (date) => {
    try {
      const newDate = changeTimezone(new Date(date), 'UTC');
      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      let currentHours = newDate.getHours();
      const ampm = currentHours >= 12 ? 'pm' : 'am';
      currentHours %= 12;
      currentHours = currentHours || 12;
      currentHours = (`0${currentHours}`).slice(-2);
      let currentMinut = newDate.getMinutes();
      currentMinut = (`0${currentMinut}`).slice(-2);
      const tempHM = `${currentHours}:${currentMinut}`;
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${tempHM} ${ampm}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  };

  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp]);

  useEffect(() => {
    if (!selectedMonth || selectedMonth === 'lastMonth') {
      const filterDate = new Date();
      const currentMonth = (filterDate.getMonth() + 1).toString().padStart(2, '0');
      setFilterByMonth(selectedMonth === 'lastMonth' ? 'lastMonth' : currentMonth);
    } else {
      setFilterByMonth(selectedMonth);
    }
  }, [selectedMonth]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6 margin-filter">
              <h2>Historial de ingresos al sistema</h2>
            </div>
            <div className="col-12 col-md-6 btn-content-filt margin-filter"> </div>
            <div className="col-12 margin-filter">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <label>{total || 0} resultados</label>
            </div>
            <div className="col-12 col-md-4 margin-filter">
              <div className="col-12 col-md-12">
                <div className="input-container">
                  <input id="buscar" onChange={(e) => searchEmployees(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                  <span className="input-icon search-line" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 margin-filter content-on">
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <button onClick={toggleMonthDropDown} className="button-secondary btn-p-cl p-r">
                &nbsp;&nbsp;
                {selectedMonth === 'lastMonth' ? 'Mes Anterior' : 'Mes Actual'}
                &nbsp;
                <i className="btn-icon">&#59674;</i>
                &nbsp;&nbsp;
                {monthDropDown && (
                <div className="drop-menu">
                  {months.filter((month) => month.value !== selectedMonth).map((month) => (
                    <label key={month.value} onClick={() => handleFilterByMonth(month.value)}>
                      {selectedMonth !== month.label && month.label}
                    </label>
                  ))}
                </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-9">
              <small className="text-title-tab">USUARIO</small>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <small className="text-title-tab">FECHA</small>
            </div>
          </div>
          {(!employeesList || employeesList.length === 0) && <SinRegistro />}
          {employeesList && employeesList.length > 0 && employeesList.map((item) => (
            <div className="row" key={item._id}>
              <div className="col-3 col-sm-2 col-md-2 col-lg-1">
                <ProfileDefComponent
                  name={item?.empleados?.nombre}
                  size="md"
                  imgProf={null}
                />
              </div>
              <div className="col-9 col-sm-10 col-md-7 col-lg-8">
                <label className="text-name">{item?.empleados?.nombre}</label>
                <label className="text-email">{item?.empleados?.correo}</label>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <label className="text-name">{monthDayYearFormat(item?.ingreso)}</label>
                <label className="text-email">{formatDateTimeHour(item?.ingreso)}</label>
              </div>
              <hr className="line-spacing" />
            </div>
          ))}
        </div>
      </div>
      {employeesList && (
        <div className="container pag-content">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-6">
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <label className="text-pag">{page} de {totalPages}</label>
                </div>
                <div className="col-12 col-md-6 pag-content-btn">
                  <button onClick={() => subsPage()} disabled={!activeL} className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}><i>&#59658;</i></button>
                  <button onClick={() => addPage()} disabled={!activeR} className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}><i>&#59659;</i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListLoginRegEmployee;
