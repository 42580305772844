/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import ViewSelectClient from './viewFindClient/viewSelectClient';
import ViewInfoClient from './viewFindClient/viewInfoClient';
import ViewSearchAllClient from './viewFindClient/viewSearchAllClient';
import FormStept1 from './formsClient/formStept1';
import FormStept2 from './formsClient/formStept2';
import FormStept3 from './formsClient/formStept3';
import FormStept4 from './formsClient/formStept4';
import { getClientByID } from '../../servives';

const FormSelectClient = ({
  dataSale, eventOp, statusOp, setData, returnView
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [stateStep4, setStateStep4] = useState('disabled');
  const [dataV1, setDataV1] = useState(null);
  const [client, setClient] = useState(null);
  const [contract, setContract] = useState(null);

  const [confirmContract, setConfirmContract] = useState(false);
  const [NStateStep1, setNStateStep1] = useState('active');
  const [NStateStep2, setNStateStep2] = useState('disabled');
  const [NStateStep3, setNStateStep3] = useState('disabled');
  const [NStateStep4, setNStateStep4] = useState('disabled');
  const [step1, setStep1] = useState('&#59666;');
  const [step2, setStep2] = useState('&#59652;');
  const [step3, setStep3] = useState('&#59652;');
  const [step4, setStep4] = useState('&#59652;');
  const [updateC, setUpdateC] = useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  const [dataForm1, setDataForm1] = useState(null);
  const [dataForm2, setDataForm2] = useState(null);
  const [dataForm4, setDataForm4] = useState(null);

  const saveForm1 = (dataF) => {
    setDataForm1(dataF);
  };
  const saveForm2 = (dataF) => {
    setDataForm2(dataF);
  };
  const setContractF = (dataF) => {
    setContract(dataF);
  };
  const saveForm4 = (dataF) => {
    setDataForm4(dataF);
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();
    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }
    return [year, month, day].join('-');
  };

  const getClients = async () => {
    await getClientByID(dataV1._id).then((data) => {
      if (data && data[0]) {
        let nameUser = `${data[0].nombre || ''} ${data[0].apellidoPaterno || ''} ${data[0].apellidoMaterno || ''}`;
        data[0].nameUser = nameUser;

        if (data[0] && data[0].sexo && data[0].sexo === 'M') {
          data[0].sexo = 'Masculino';
        }
        if (data[0] && data[0].sexo && data[0].sexo === 'F') {
          data[0].sexo = 'Femenino';
        }
        if (data[0] && data[0].sexo) {
          const tempSx = data[0].sexo;
          const capitalized = tempSx.charAt(0).toUpperCase() + tempSx.slice(1);
          data[0].sexo = capitalized;
        }
        if (data[0] && data[0].fechaNacimiento) {
          const tempD = new Date(data[0].fechaNacimiento);
          data[0].fechaNacimiento = formatDate(tempD);
        }
        data[0].Search = dataV1.idEmpleados || [];
        setClient(data[0]);
      }
    });
  };
  const returnViewOp = () => {
    setStateStep1('active');
    setStateStep2('');
    setStateStep3('');
    setStateStep4('');
    setDataV1(null);
    setClient(null);
  };
  const openFormContranct = () => {
    setStateStep1('');
    setStateStep2('');
    setStateStep3('');
    setStateStep4('active');
    if (updateC) {
      cambiarOpcion2();
    } else {
      cambiarOpcion1();
    }
  };

  const cambiarOpcion1 = () => {
    setOpcionSeleccionada('uno');
    setNStateStep1('active');
    setNStateStep2('disabled');
    setNStateStep3('disabled');
    setNStateStep4('disabled');
    setStep1('&#59666;');
    setStep2('&#59652;');
    setStep3('&#59652;');
    setStep4('&#59652;');
    window.scrollTo(0, 0);
  };
  const cambiarOpcion2 = () => {
    setOpcionSeleccionada('dos');
    setNStateStep1('complete');
    setNStateStep2('active');
    setNStateStep3('disabled');
    setNStateStep4('disabled');
    setStep1('&#59654;');
    setStep2('&#59666;');
    setStep3('&#59652;');
    setStep4('&#59652;');
    window.scrollTo(0, 0);
  };
  const cambiarOpcion3 = () => {
    setOpcionSeleccionada('tres');
    setNStateStep1('complete');
    setNStateStep2('complete');
    setNStateStep3('active');
    setNStateStep4('disabled');
    setStep1('&#59654;');
    setStep2('&#59654;');
    setStep3('&#59666;');
    setStep4('&#59652;');
    window.scrollTo(0, 0);
  };
  const cambiarOpcion4 = () => {
    setOpcionSeleccionada('cuatro');
    setNStateStep1('complete');
    setNStateStep2('complete');
    setNStateStep3('complete');
    setNStateStep4('active');
    setStep1('&#59654;');
    setStep2('&#59654;');
    setStep3('&#59654;');
    setStep4('&#59666;');
    window.scrollTo(0, 0);
  };

  const createContract = (dataB) => {
    if (updateC) {
      const dataContrato = {
        client,
        prospecto: dataSale,
        contract,
        addToClient: updateC,
        beneficiarios: dataB,
        prospects: dataForm2,
      };
      setData(dataContrato, updateC);
    } else {
      let tempDataC = dataForm1;
      tempDataC._id = client._id;
      tempDataC.empleado = client.empleado;
      const dataContrato = {
        client: tempDataC,
        prospecto: dataSale,
        contract,
        addToClient: updateC,
        beneficiarios: dataB,
        prospects: dataForm2,
      };
      setData(dataContrato, updateC);
    }
  };

  const optionSearch = () => {
    setStateStep1('');
    setStateStep2('');
    setStateStep3('active');
    setStateStep4('');
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  useEffect(() => {
    if (dataV1 && dataV1._id) {
      getClients();
      setStateStep1('');
      setStateStep2('active');
      setStateStep3('');
      setStateStep4('');
    }
  }, [dataV1]);

  useEffect(() => {
    if (client && client._id) {
      let blockData = true;
      const user = JSON.parse(localStorage.getItem('user'));
      client.Search.forEach((element) => {
        if (user._id === element) {
          blockData = false;
        }
      });
      setUpdateC(blockData);
      setConfirmContract(blockData);
    }
  }, [client]);

  return (
    <div className="container-fluid navBar-right">
      {opcionSeleccionada === '' && (
        <div className="row">
          <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
          <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <span
                    className="icon-close return-icon"
                    onClick={returnView}
                  >
                    {' '}
                  </span>
                </div>
                <div className="col close-form">
                  <span
                    className="icon-close cross-line"
                    onClick={eventOp}
                  >
                    {' '}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {stateStep1 === 'active' && <ViewSelectClient setData={setDataV1} dataP={dataSale} optionSearch={optionSearch} />}
                  {stateStep2 === 'active' && <ViewInfoClient dataClient={client} nextStep={openFormContranct} />}
                  {stateStep3 === 'active' && <ViewSearchAllClient setData={setDataV1} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {opcionSeleccionada !== '' && (
        <div className="container-fluid content-full overflow-c">
          <div className="row">
            <div className="row justify-content-end">
              <div className="col-1 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={() => eventOp(false)}
                >
                  {' '}
                </span>
              </div>
            </div>
            {!confirmContract && !updateC && (
              <div className="col-12 col-md-10 m-a">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                  <div className="col-12 col-sm-12 col-md-10 col-lg-6">
                    <div className="row align-items-center">
                      <div className="col-6 col-md-2">
                        <i className="step-prosp-icon">{parse(step1)}</i>
                        <label className="step-prosp small-bold">Cliente</label>
                      </div>
                      <div className={`col-6 col-md-2 ${NStateStep2}`}>
                        <i className="step-prosp-icon">{parse(step2)}</i>
                        <label className="step-prosp small-bold">Referencias</label>
                      </div>
                      <div className={`col-6 col-md-2 ${NStateStep3}`}>
                        <i className="step-prosp-icon">{parse(step3)}</i>
                        <label className="step-prosp small-bold">Contrato</label>
                      </div>
                      <div className={`col-6 col-md-2 ${NStateStep4}`}>
                        <i className="step-prosp-icon">{parse(step4)}</i>
                        <label className="step-prosp small-bold">Beneficiarios</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                  <div className="col-12 col-sm-12 col-md-10 col-lg-6">
                    {opcionSeleccionada === 'uno' ? (
                      <FormStept1
                        cambiarOpcion={cambiarOpcion2}
                        saveData={saveForm1}
                        opcionAnterior={returnViewOp}
                        dataP={client}
                        defDataP={dataForm1}
                        disabledF
                      />
                    ) : opcionSeleccionada === 'dos' ? (
                      <FormStept2
                        cambiarOpcion={cambiarOpcion3}
                        saveData={saveForm2}
                        opcionAnterior={cambiarOpcion1}
                        dataP={dataForm1}
                        disabledF
                      />
                    ) : opcionSeleccionada === 'tres' ? (
                      <FormStept3
                        cambiarOpcion={cambiarOpcion4}
                        saveData={setContractF}
                        opcionAnterior={cambiarOpcion2}
                        productosL={dataSale.productos}
                        dataClient={client}
                        dataSaleT={dataSale.totalFF}
                      />
                    ) : opcionSeleccionada === 'cuatro' ? (
                      <FormStept4
                        cambiarOpcion={createContract}
                        saveData={saveForm4}
                        opcionAnterior={cambiarOpcion3}
                        dataP={dataForm1}
                      />
                    ) : (
                      <> </>
                    )}
                  </div>
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                </div>
                <div className="row">
                  <div className="col-12 spacing-nav"> </div>
                </div>
              </div>
            )}
            {!confirmContract && updateC && (
              <div className="col-12 col-md-10 m-a">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                  <div className="col-12 col-sm-12 col-md-10 col-lg-6">
                    <div className="row  align-items-center">
                      <div className={`col-6 col-md-2 ${NStateStep2}`}>
                        <i className="step-prosp-icon">{parse(step2)}</i>
                        <label className="step-prosp small-bold">Referencias</label>
                      </div>
                      <div className={`col-6 col-md-2 ${NStateStep3}`}>
                        <i className="step-prosp-icon">{parse(step3)}</i>
                        <label className="step-prosp small-bold">Contrato</label>
                      </div>
                      <div className={`col-6 col-md-2 ${NStateStep4}`}>
                        <i className="step-prosp-icon">{parse(step4)}</i>
                        <label className="step-prosp small-bold">Beneficiarios</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                  <div className="col-12 col-sm-12 col-md-10 col-lg-6">
                    {opcionSeleccionada === 'dos' ? (
                      <FormStept2
                        cambiarOpcion={cambiarOpcion3}
                        saveData={saveForm2}
                        opcionAnterior={returnViewOp}
                        dataP={client}
                        disabledF
                      />
                    ) : opcionSeleccionada === 'tres' ? (
                      <FormStept3
                        cambiarOpcion={cambiarOpcion4}
                        saveData={setContractF}
                        opcionAnterior={cambiarOpcion2}
                        productosL={dataSale.productos}
                        dataClient={client}
                        dataSaleT={dataSale.totalFF}
                      />
                    ) : opcionSeleccionada === 'cuatro' ? (
                      <FormStept4
                        cambiarOpcion={createContract}
                        saveData={saveForm4}
                        opcionAnterior={cambiarOpcion3}
                        dataP={dataForm1}
                      />
                    ) : (
                      <> </>
                    )}
                  </div>
                  <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
                </div>
                <div className="row">
                  <div className="col-12 spacing-nav"> </div>
                </div>
              </div>
            )}
            {confirmContract && (
              <div className="row">
                <div className="col-12">
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-12 col-md-12">
                  <div className="row">
                    <div className="col-12 cont-icon-resp warning">
                      <label className="icon-info-resp warning">&#59662;</label>
                    </div>
                    <div className="row">
                      <div className="col col-md-1">&nbsp;</div>
                      <div className="col-12 col-md-10 cont-center">
                        <h2>¿Seguro que deseas crear un nuevo contrato para este cliente?</h2>
                      </div>
                      <div className="col col-md-1">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col col-md-1">&nbsp;</div>
                      <div className="col-12 col-md-10 cont-center">
                        <label className="text-info">Esto lo convertirá en tu cliente y deberás reportar la venta a Administración.</label>
                      </div>
                      <div className="col col-md-1">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-12 cont-center">
                        <button className="button button-rep" onClick={() => setConfirmContract(false)}>
                          Sí, crear
                        </button>
                      </div>
                      <div className="col-12 col-md-12 cont-center">
                        <button className="button-secondary p-r" onClick={() => eventOp(false)}>
                          No, cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormSelectClient;
