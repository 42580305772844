/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  updateBeneficiarie, getContractByID, updateContract
} from './services';
import './contract.css';

const ChangePriorityBenficiarieView = ({ idContract }) => {
  const [showInfo, setShowInfo] = useState('disabled');

  const [listBenef, setListBenef] = useState(null);
  const [listBenefDrag, setListBenefDrag] = useState(null);
  const [message, setMessage] = useState(false);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const navigate = useNavigate();

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const closeForm = () => {
    navigate(`/clients/contract-view/${idContract}`);
  };

  const updateDataInfo = async () => {
    const newOrder = [];
    listBenef.forEach((_item) => {
      newOrder.push({ idBeneficiario: _item._id });
    });
    const tempC = {
      beneficiarios: newOrder
    };
    await updateContract(idContract, tempC).then(async (resp) => {
      if (resp) {
        listBenef.forEach(async (element, _ind) => {
          if (element._id) {
            const updateNiv = {
              nivel: _ind + 1
            };
            const updateBenef = await updateBeneficiarie(element._id, updateNiv);
            if (updateBenef && (_ind + 1) === listBenef.length) {
              setMessage(true);
              toast('Cambios guardados', {
                className: 'message',
              });
              setTimeout(() => {
                closeForm();
              }, 2000);
            }
            if (updateBenef && updateBenef.error) {
              setMessage(true);
              toast('Error al actualizar el contrato.', {
                className: 'message error',
              });
            }
          }
        });
      } else {
        setMessage(true);
        toast('Error al actualizar el contrato.', {
          className: 'message error',
        });
      }
    });
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let updatedList = [...listBenefDrag];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setListBenefDrag(updatedList);
    reorderData(updatedList);
  };

  const reorderData = (list) => {
    setShowInfo('');
    const newList = [];
    list.forEach((item, index) => {
      listBenef.forEach((element) => {
        const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
        if (item === tempN) {
          element.nivel = index + 1;
          newList.push(element);
        }
      });
    });
    setListBenef(newList);
  };

  const checkParentesco = (name) => {
    let tempP = '';
    listBenef.forEach((element) => {
      const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
      if (name === tempN) {
        tempP = element.parentesco;
      }
    });
    return tempP;
  };
  const compareByNivel = (a, b) => {
    if (a.nivel < b.nivel) {
      return -1;
    }
    if (a.nivel > b.nivel) {
      return 1;
    }
    return 0;
  };
  const getContractInfo = async () => {
    await getContractByID(idContract).then((resp) => {
      if (resp && resp[0] && resp[0]._id) {
        resp[0].beneficiarios.sort(compareByNivel);
        const tempL = [];
        const tempLDrag = [];
        resp[0].beneficiarios.forEach((element) => {
          tempL.push(element);
          const tempN = `${element.nombre} ${element.apellidoPaterno || ''} ${element.apellidoMaterno || ''}`;
          tempLDrag.push(tempN);
        });
        setListBenef(tempL);
        setListBenefDrag(tempLDrag);
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token && idContract) {
      getContractInfo();
    } else if (!token) {
      closeSession();
    }
  }, [idContract]);

  return (
    <div>
      <div className="mb-xxl-5">
        <div className="content-buttom">&nbsp;</div>
        {listBenefDrag && (
          <div className="container">
            <div>
              <div className="mt-xxl">
                <h2>Prioridad</h2>
                <div className="content-buttom">&nbsp;</div>
                <div className="container">
                  <div className="row">
                    <DragDropContext onDragEnd={handleDrop}>
                      <Droppable droppableId="container-fluid">
                        {(provided) => (
                          <div
                            className="container-fluid"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {listBenefDrag.map((item, index) => (
                              <Draggable
                                key={generateKey(index)}
                                draggableId={item}
                                index={index}
                              >
                                {(providedd) => (
                                  <div
                                    className="row content-h"
                                    ref={providedd.innerRef}
                                    {...providedd.dragHandleProps}
                                    {...providedd.draggableProps}
                                  >
                                    <div className="option-tab btn-grab">
                                      <div className="col-1 col-md-1">
                                        <label className="text-pos btn-grab">{index + 1}</label>
                                      </div>
                                      <div className="col-11 col-md-7">
                                        <label className="text-name btn-grab">
                                          {item}
                                        </label>
                                        <label className="text-email btn-grab">{checkParentesco(item)}</label>
                                      </div>
                                      {index === 0 ? (
                                        <div className={`col-12 col-md-3 ${index === 0 ? 'select' : ''}`}>
                                          <label className="text-indicator">{index === 0 ? 'Principal' : ''}</label>
                                        </div>
                                      ) : (
                                        <div className="col-12 col-md-3"> </div>
                                      )}
                                      <div className={`col-12 col-md-1 ${item.nivel === 1 ? 'select' : ''}`}>
                                        <p className="button-secondary p-r btn-grab">
                                          <i className="btn-icon">&#59700;</i>
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="notif-line-spacing" />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  <div className="row mt-m">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                      <button className="input-label-float-right button-tertiary" onClick={closeForm}>
                        Cancelar
                      </button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                      <button className="button" type="submit" disabled={showInfo} onClick={updateDataInfo}>
                        Guardar cambios
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <br />
            </div>
          </div>
        )}
        {message && (
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        )}
      </div>
    </div>
  );
};

export default ChangePriorityBenficiarieView;
