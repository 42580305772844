/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import pagoMeses from '../../../../../global/data/pagoMeses.json';
import pagoDia from '../../../../../global/data/diaPago.json';
import { step3 } from '../formik';

const DetailsContractP = ({ handleSubmitForm, cambiarOpcionTres, productosL }) => {
  // const isFormValid = (errors) => errors.length === 0;
  const [submitted, setSubmitted] = useState(false);

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    isValid
  } = step3();

  const {
    noContrato,
    precioVenta,
    formaPago,
    diaPago,
    mensualidad,
    comentarios
  } = values;
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleSubmitStep = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // if (isFormValid(errors)) {
    if (isValid) {
      let tempValues = values;
      if (formaPago === 'mensualidades') {
        tempValues.costoM = CalcMonthsRaw(mensualidad, precioVenta);
        tempValues.plazo = `${mensualidad} X $${CalcMonthsRaw(mensualidad, precioVenta)}`;
      } else {
        tempValues.costoM = precioVenta;
        tempValues.mensualidad = '1';
        tempValues.plazo = `1 X $${precioVenta}`;
      }
      handleSubmitForm(tempValues);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const calcTotal = () => {
    let tempTotal = 0;
    productosL.forEach((itemPL) => {
      if (itemPL._Disponibilidad === 'prevision') {
        tempTotal += (itemPL.Cantidad * itemPL.precioPrevision) || 0;
      } else {
        tempTotal += (itemPL.Cantidad * itemPL.precio) || 0;
      }
    });
    setFieldValue('precioVenta', tempTotal);
  };
  const CalcMonthsRaw = (months, precioV) => {
    if (months && precioV) {
      try {
        const tempM = parseInt(months, 10);
        const tempMonth = parseFloat(precioV) / tempM;
        return tempMonth.toFixed(2);
      } catch (_error) {
        return 0;
      }
    }
    return 0;
  };
  const CalcMonths = (months, precioV) => {
    if (months && precioV) {
      try {
        const tempM = parseInt(months, 10);
        const tempMonth = parseFloat(precioV) / tempM;
        return formatMoney.format(tempMonth);
      } catch (_error) {
        return '$-';
      }
    }
    return '$-';
  };

  useEffect(() => {
    if (productosL) {
      calcTotal();
    }
  }, [productosL]);
  return (
    <div className="container fondodcp">
      <div className="row align-items-center justify-content-center">
        <div className="col-xl-6 col-md-8 col-11">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-4">
              <i className="step-client-icon">&#59654;</i>
              <label className="step-client small-bold">Asociar producto</label>
            </div>
            <div className="col-12 col-md-4">
              <i className="step-client-icon">&#59666;</i>
              <label className="step-client small-bold">Detalles del contrato</label>
            </div>
          </div>
          <div className="content-buttom">&nbsp;</div>
          <div className="col-12 col-md-6 margin-filter">
            <h2>Detalles del contrato</h2>
          </div>
          <form onSubmit={handleSubmitStep}>
            <div className="">
              <div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <label htmlFor="noContrato" className="label mt-m">
                      Número de contrato
                      <input
                        onChange={handleChange('noContrato')}
                        value={noContrato}
                        id="noContrato"
                        type="text"
                        className={`input ${submitted && errors && errors.noContrato && 'has-error'}`}
                      />
                      {submitted && errors && errors.noContrato && <small className="caption-error">{errors.noContrato}</small>}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">&nbsp;</div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="content-buttom">&nbsp;</div>
                    <h2>Detalles del pago</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <label htmlFor="precioVenta" className="label mt-m">
                      Total de la venta
                      <input
                        value={formatMoney.format(precioVenta)}
                        id="precioVenta"
                        type="text"
                        disabled
                        className={`input not-border ${submitted && errors && errors.precioVenta && 'has-error'}`}
                      />
                      {submitted && errors && errors.precioVenta && <small className="caption-error">{errors.precioVenta}</small>}
                    </label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <label htmlFor="formaPago" className="label labels mt-m">
                      Tipo de pago
                      <br />
                      <button type="button" onClick={() => setFieldValue('formaPago', 'mensualidades')} className={`btn-edc ${formaPago === 'mensualidades' ? 'active' : ''} genderM`}>Mensualidades</button>
                      <button type="button" onClick={() => setFieldValue('formaPago', 'contado')} className={`btn-edc ${formaPago === 'contado' ? 'active' : ''} genderF`}>Contado</button>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <label htmlFor="mensualidad" className="label mt-m">
                      Mensualidades
                      <select
                        type="mensualidad"
                        value={mensualidad}
                        disabled={formaPago === 'contado' ? 'disabled' : ''}
                        onChange={handleChange('mensualidad')}
                      >
                        <option value="">Seleccione una opción</option>
                        {pagoMeses.map((items) => (
                          <option key={items.value} name={items.value} value={items.value}>
                            {items.label}
                            {' '}
                            {CalcMonths(items.value, precioVenta)}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <label htmlFor="diaPago" className="label mt-m">
                      Fecha de pago
                      <select
                        type="diaPago"
                        value={diaPago}
                        disabled={formaPago === 'contado' ? 'disabled' : ''}
                        onChange={handleChange('diaPago')}
                      >
                        <option value="">Seleccione una opción</option>
                        {pagoDia.map((items) => (
                          <option key={items.value} name={items.value} value={items.value}>
                            {items.label}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">&nbsp;</div>
                  <div className="col-12">
                    <div className="content-buttom">&nbsp;</div>
                    <h2>
                      Comentarios
                      <br />
                      y anotaciones
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <label htmlFor="comentarios" className="label mt-m">
                      Anotaciones
                      <textarea
                        onChange={handleChange('comentarios')}
                        value={comentarios}
                        id="comentarios"
                        className={`input sizein ${submitted && errors && errors.comentarios && 'has-error'}`}
                      />
                      {submitted && errors && errors.comentarios && <small className="caption-error">{errors.comentarios}</small>}
                      <label htmlFor="sample" className="label font-sm ">
                        (Opcional)
                      </label>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="col-12 col-md-12">
                    &nbsp;
                  </div>
                  <div className="col-12 col-md-12">
                    <button type="button" className="button-secondary p-r m-btn-prof" onClick={cambiarOpcionTres}>
                      Cancelar
                    </button>
                    <button className="button mt-m" type="submit">
                      Guardar cambios
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DetailsContractP;
