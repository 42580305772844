/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import countriesData from '../../../../../global/data/countries.json';
import { FormikStep4 } from '../../../formik/formikStepsConvert';
import { findCP_S } from '../../../servives';

const FormBeneficiare = ({
  returndata, inx, submitted = false, validation, dirtyForm, defData
}) => {
  const [localidades, setLocalidades] = useState([]);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    setFieldTouched,
    isValid,
    dirty,
  } = FormikStep4();

  const {
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    fechaNacimiento,
    parentesco,
    telefono,
    edad,
    cp,
    estado,
    ciudad,
    colonia,
    direccion,
  } = values;

  const age = (birthdate) => {
    const today = new Date();
    // eslint-disable-next-line max-len
    const one_or_zero = (today.getMonth() < birthdate.getMonth()) || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate());
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    const ageR = year_difference - one_or_zero;
    return ageR;
  };

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (defData) {
      setFieldValue('nombre', defData.nombre || '');
      setFieldValue('apellidoPaterno', defData.apellidoPaterno || '');
      setFieldValue('apellidoMaterno', defData.apellidoMaterno || '');
      setFieldValue('fechaNacimiento', defData.fechaNacimiento || '');
      setFieldValue('parentesco', defData.parentesco || '');
      setFieldValue('telefono', defData.telefono || '');
      setFieldValue('cp', defData.cp || '');
      setFieldValue('estado', defData.estado || '');
      setFieldValue('ciudad', defData.ciudad || '');
      setFieldValue('colonia', defData.colonia || '');
      setFieldValue('direccion', defData.direccion || '');
      setTimeout(() => setFieldTouched(colonia, true));
      setTimeout(() => setFieldTouched(ciudad, true));
      setTimeout(() => setFieldTouched(estado, true));
    }
  }, [defData]);

  useEffect(() => {
    if (fechaNacimiento && fechaNacimiento !== '') {
      let tempEdad = age(new Date(fechaNacimiento)) || 0;
      setFieldValue('edad', tempEdad);
    } else {
      setFieldValue('edad', 0);
    }
  }, [fechaNacimiento]);

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  useEffect(() => {
    if (cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);
  useEffect(() => {
    validation(inx, isValid.toString());
    dirtyForm(inx, dirty.toString());
  }, [isValid, dirty]);

  useEffect(() => {
    // console.log(inx, values);
    returndata(inx, values);
  }, [values]);

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <label htmlFor="nombre" className="label mt-m">
              Nombre(s)
              <input
                onChange={handleChange('nombre')}
                value={nombre}
                id="nombre"
                type="text"
                className={`input ${submitted && errors.nombre && 'has-error'}`}
              />
              {submitted && errors.nombre
                && <small className="caption-error">{errors.nombre}</small>}
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6"> </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <label htmlFor="apellidoPaterno" className="label mt-m">
              Primer apellido
              <input
                id="apellidoPaterno"
                type="text"
                className={`input ${submitted && errors.apellidoPaterno && 'has-error'}`}
                onChange={handleChange('apellidoPaterno')}
                value={apellidoPaterno}
              />
              {
                submitted && errors.apellidoPaterno
                && <small className="caption-error">{errors.apellidoPaterno}</small>
              }
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <label htmlFor="apellidoMaterno" className="label mt-m">
              Segundo apellido
              <input
                id="apellidoMaterno"
                type="text"
                className="input"
                onChange={handleChange('apellidoMaterno')}
                value={apellidoMaterno}
              />
              <label htmlFor="sample" className="label font-sm ">
                (Opcional)
              </label>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <label htmlFor="fechaNacimiento" className="label mt-m">
              Fecha de nacimiento
              <input
                id="fechaNacimiento"
                type="date"
                className={`input input-date ${submitted && errors.fechaNacimiento && 'has-error'}`}
                onChange={handleChange('fechaNacimiento')}
                value={fechaNacimiento}
              />
              {
                submitted && errors.fechaNacimiento
                && <small className="caption-error">{errors.fechaNacimiento}</small>
              }
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <label htmlFor="parentesco" className="label mt-m">
              Parentesco
              <input
                id="parentesco"
                type="text"
                className="input"
                value={parentesco}
                onChange={handleChange('parentesco')}
              />
              {
                submitted && errors.parentesco
                && <small className="caption-error">{errors.parentesco}</small>
              }
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <label htmlFor="telefono" className="label mt-m">
              Teléfono
              <input
                id="telefono"
                type="text"
                placeholder="10 dígitos"
                className={`input ${submitted && errors.telefono && 'has-error'}`}
                onChange={handleChange('telefono')}
                value={telefono}
              />
              {
                submitted && errors.telefono
                && <small className="caption-error">{errors.telefono}</small>
              }
            </label>
          </div>
        </div>
      </div>
      <div className="mt-xxl">
        <h2>Domicilio</h2>
        <div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="cp" className="label mt-m ">
                Código postal
                <input
                  id="cp"
                  type="number"
                  maxLength="5"
                  pattern="[0-9]{X,5}"
                  className={`input ${submitted && errors.cp && 'has-error'}`}
                  onChange={handleChange('cp')}
                  value={cp}
                />
                {
                  submitted && errors.cp
                  && <small className="caption-error">{errors.cp}</small>
                }
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="estado" className="label mt-m">
                Estado
                <select
                  type="select"
                  name="estado"
                  className={`${submitted && errors.estado && 'has-error'}`}
                  value={estado}
                  onChange={handleChange('estado')}
                >
                  <option value="">Estado</option>
                  {countriesData.map(({ states }) => (
                    <option key={states} name={states} value={states}>
                      {states}
                    </option>
                  ))}
                </select>
                {
                  submitted && errors.estado
                  && <small className="caption-error">{errors.estado}</small>
                }
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="ciudad" className="label mt-m">
                Localidad o Municipio
                <select
                  type="select"
                  name="ciudad"
                  className={`${submitted && errors.ciudad && 'has-error'}`}
                  value={ciudad}
                  onChange={handleChange('ciudad')}
                >
                  <option value="">Localidad</option>
                  {localidades.map((loc) => (
                    <option key={loc} name={loc} value={loc}>
                      {loc}
                    </option>
                  ))}
                </select>
                {
                  submitted && errors.ciudad
                  && <small className="caption-error">{errors.ciudad}</small>
                }
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="colonia" className="label mt-m ">
                Colonia
                {!colonies && (
                  <input
                    id="colonia"
                    type="text"
                    className={`input ${submitted && errors.colonia && 'has-error'}`}
                    value={colonia}
                    onChange={handleChange('colonia')}
                  />
                )}
                {colonies && (
                  <select
                    type="select"
                    name="colonia"
                    className={`${submitted && errors.colonia && 'has-error'}`}
                    value={colonia}
                    onChange={handleChange('colonia')}
                  >
                    <option value="">-Seleccione la Colonia-</option>
                    {colonies.map((_colonia) => (
                      <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                        {_colonia}
                      </option>
                    ))}
                  </select>
                )}
                {
                  submitted && errors.colonia
                  && <small className="caption-error">{errors.colonia}</small>
                }
              </label>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="direccion" className="label mt-m ">
                Dirección
                <input
                  id="direccion"
                  type="text"
                  className={`input ${submitted && errors.direccion && 'has-error'}`}
                  value={direccion}
                  onChange={handleChange('direccion')}
                />
                {
                  submitted && errors.direccion
                  && <small className="caption-error">{errors.direccion}</small>
                }
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormBeneficiare;
