/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ResumeSaleComp from './components/finalizeSaleC/resumeSale';
import TypeClientComp from './components/finalizeSaleC/typeClient';
import FormCreateClientComp from './components/finalizeSaleC/formCreateClient';
import FormSelectClient from './components/finalizeSaleC/formSelectClient';
import RespAddProspect from './components/addProspectC/respAddProspect';
import {
  registerClient, registerContract, deleteProspect, updateEmployee, uploadFileC, insertBeneficiarie,
} from './servives/index';

const FinalizeSaleM = ({ eventOp, statusOp, dataP }) => {
  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [respCreate, setRespCreate] = useState(null);
  const [respMessage, setRespMessage] = useState(null);
  const [respLink, setRespLink] = useState(null);
  const [message, setMessage] = useState(false);

  const navigate = useNavigate();

  const saveData1 = (dataFrm) => {
    setData1(dataFrm);
    setStateStep1('');
    setStateStep2('active');
  };

  const saveData2 = (dataFrm) => {
    setData2(dataFrm);
    setStateStep1('');
    setStateStep2('');
    setStateStep3('active');
  };

  const saveData3 = (dataFrm, addToClient = false) => {
    saveDataF(dataFrm, addToClient);
    setStateStep1('');
    setStateStep2('');
    setStateStep3('');
  };

  const getDiscountId = (discountItem, availability) => {
    if (!discountItem || (!discountItem.usoInmediato && !discountItem.prevision)) {
      return '';
    }
    switch (availability) {
      case 'inmediata':
        return discountItem.usoInmediato.idDescuento;
      case 'prevision':
        return discountItem.prevision.idDescuento;
      default:
        return '';
    }
  };

  const saveDataF = async (dataFrm, addToClient) => {
    setRespCreate('loading');
    setRespMessage(null);
    setRespLink(null);
    let tempProdFor = data1.productos;
    let tempProd = [];
    tempProdFor.forEach((element) => {
      if (element.disponibilidad === 'inmediata') {
        tempProd.push({
          idProducto: element.idProducto,
          cantidad: element.cantidad,
          disponibilidad: element.disponibilidad,
          precioInicial: element.precioOriginal || element.precio,
          precioDescuento: element.precio,
          idDescuento: getDiscountId(element.descuentos, element.disponibilidad) || undefined,
        });
      } else {
        tempProd.push({
          idProducto: element.idProducto,
          cantidad: element.cantidad,
          disponibilidad: element.disponibilidad,
          precioInicial: element.precioOriginal || element.precioPrevision,
          precioDescuento: element.precioPrevision,
          idDescuento: getDiscountId(element.descuentos, element.disponibilidad) || undefined,
        });
      }
    });
    if (data2 === 'NUEVO') {
      try {
        const { file: fileIneURLC } = await uploadFileC(dataFrm.files.fileINE, 'documentos/test', 'clientes');
        const { file: fileAddsURLC } = await uploadFileC(dataFrm.files.fileAdds, 'documentos/test', 'clientes');

        const tempClient = {
          nombre: dataFrm.client.nombre,
          apellidoPaterno: dataFrm.client.apellidoPaterno,
          apellidoMaterno: dataFrm.client.apellidoMaterno || undefined,
          domicilio: [{
            direccion: dataFrm.client.direccion,
            colonia: dataFrm.client.colonia,
            ciudad: dataFrm.client.ciudad,
            estado: dataFrm.client.estado,
            cp: dataFrm.client.cp,
          }],
          ocupacion: dataFrm.client.ocupacion,
          fechaNacimiento: dataFrm.client.fechaNacimiento,
          edad: data1.edad || 0,
          sexo: data1.genero,
          estadoCivil: dataFrm.client.estadoCivil,
          telefono: dataFrm.client.telefono ? dataFrm.client.telefono.toString() : '',
          email: dataFrm.client.email,
          password: 'moradadelapaz',
          role: 'user',
          empleado: [{
            idEmpleado: data1.empleados.idEmpleado || null,
          }],
          ine: {
            url_ine: fileIneURLC,
            fecha_upd: new Date().getTime()
          },
          comprobante_domicilio: {
            url_Comprobante_domicilio: fileAddsURLC,
            fecha_upd: new Date().getTime()
          }
        };
        if (dataFrm.client.DirectionExt) {
          tempClient.domicilio.push({
            direccion: dataFrm.client.DirectionExt.direccionExt,
            colonia: dataFrm.client.DirectionExt.coloniaExt,
            ciudad: dataFrm.client.DirectionExt.ciudadExt,
            estado: dataFrm.client.DirectionExt.estadoExt,
            cp: dataFrm.client.DirectionExt.cpExt,
          });
        }

        await registerClient(tempClient).then(async (data) => {
          if (data && data._id) {
            await deleteProspect(data1._id);
            const tempContract = {
              fecha: new Date().getTime(),
              folio: 1,
              noContrato: dataFrm.contract.noContrato || '-',
              comentarios: dataFrm.contract.comentarios || '-',
              tipoPlan: data1.productos[0].disponibilidad || '-',
              precioVenta: data1.totalFF,
              formaPago: dataFrm.contract.formaPago,
              diaPago: dataFrm.contract.diaPago,
              plazo: dataFrm.contract.plazo,
              pagoInicial: dataFrm.contract.anticipo,
              saldo: data1.totalFF,
              mensualidad: dataFrm.contract.costoM,
              liquidado: 0 + dataFrm.contract.anticipo,
              clients: {
                idCliente: data._id,
              },
              empleado: {
                idEmpleado: data.empleado[0].idEmpleado,
              },
              productos: tempProd,
              sucursales: {
                idSucursal: data1.sucursales.idSucursal,
              },
              estatus_contrato: 'por validar',
              medioDeVenta: 'WEBAPP',
              referencias: [{
                nombre: dataFrm.prospects.nombre1,
                apellidoPaterno: dataFrm.prospects.apellidoPaterno1,
                apellidoMaterno: dataFrm.prospects.apellidoMaterno1 || undefined,
                direccion: dataFrm.prospects.direccion1,
                colonia: dataFrm.prospects.colonia1,
                ciudad: dataFrm.prospects.ciudad1,
                estado: dataFrm.prospects.estado1,
                cp: dataFrm.prospects.cp1,
                telefono: dataFrm.prospects.telefono1,
                email: dataFrm.prospects.email1,
              }, {
                nombre: dataFrm.prospects.nombre2,
                apellidoPaterno: dataFrm.prospects.apellidoPaterno2,
                apellidoMaterno: dataFrm.prospects.apellidoMaterno2 || undefined,
                direccion: dataFrm.prospects.direccion2,
                colonia: dataFrm.prospects.colonia2,
                ciudad: dataFrm.prospects.ciudad2,
                estado: dataFrm.prospects.estado2,
                cp: dataFrm.prospects.cp2,
                telefono: dataFrm.prospects.telefono2,
                email: dataFrm.prospects.email2,
              }],
            };
            if (data1.cupones) {
              tempContract.cupones = { idCupon: data1.cupones };
            }
            let tempDC = {};
            if (tempClient.domicilio && tempClient.domicilio[1]) {
              // eslint-disable-next-line prefer-destructuring
              tempDC = tempClient.domicilio[1];
            } else if (tempClient.domicilio && tempClient.domicilio[0]) {
              // eslint-disable-next-line prefer-destructuring
              tempDC = tempClient.domicilio[0];
            }
            tempContract.domicilio = tempDC;
            AddBeneficiaries(dataFrm.beneficiarios, tempContract);
            /*
            await registerContract(tempContract).then((dataC) => {
              if (dataC && dataC._id) {
                navigate(`/clients/contract-view/${dataC._id}`);
              }
            }); */
          } else {
            setRespMessage('Error al procesar los datos');
            setRespLink(`/prospects/${data1._id}`);
            setRespCreate('error');
          }
        });
      } catch (_error) {
        // eslint-disable-next-line no-console
        console.log('_error-1', _error);
        setRespMessage('Error al procesar los datos');
        setRespLink(`/prospects/${data1._id}`);
        setRespCreate('error');
      }
    } else {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const tempContract = {
          fecha: new Date().getTime(),
          folio: 1,
          noContrato: dataFrm.contract.noContrato || '-',
          comentarios: dataFrm.contract.comentarios || '-',
          tipoPlan: data1.productos[0].disponibilidad || '-',
          precioVenta: data1.totalFF,
          formaPago: dataFrm.contract.formaPago,
          diaPago: dataFrm.contract.diaPago,
          plazo: dataFrm.contract.plazo,
          pagoInicial: dataFrm.contract.anticipo,
          saldo: data1.totalFF,
          mensualidad: dataFrm.contract.costoM,
          liquidado: 0 + dataFrm.contract.anticipo,
          clients: {
            idCliente: dataFrm.client._id,
          },
          empleado: {
            idEmpleado: user._id,
          },
          productos: tempProd,
          sucursales: {
            idSucursal: data1.sucursales.idSucursal,
          },
          estatus_contrato: 'por validar',
          medioDeVenta: 'WEBAPP',
          referencias: [{
            nombre: dataFrm.prospects.nombre1,
            apellidoPaterno: dataFrm.prospects.apellidoPaterno1,
            apellidoMaterno: dataFrm.prospects.apellidoMaterno1,
            direccion: dataFrm.prospects.direccion1,
            colonia: dataFrm.prospects.colonia1,
            ciudad: dataFrm.prospects.ciudad1,
            estado: dataFrm.prospects.estado1,
            cp: dataFrm.prospects.cp1,
            telefono: dataFrm.prospects.telefono1,
            email: dataFrm.prospects.email1,
          }, {
            nombre: dataFrm.prospects.nombre2,
            apellidoPaterno: dataFrm.prospects.apellidoPaterno2,
            apellidoMaterno: dataFrm.prospects.apellidoMaterno2,
            direccion: dataFrm.prospects.direccion2,
            colonia: dataFrm.prospects.colonia2,
            ciudad: dataFrm.prospects.ciudad2,
            estado: dataFrm.prospects.estado2,
            cp: dataFrm.prospects.cp2,
            telefono: dataFrm.prospects.telefono2,
            email: dataFrm.prospects.email2,
          }],
        };
        if (data1.cupones) {
          tempContract.cupones = { idCupon: data1.cupones };
        }
        if (dataFrm.client.DirectionExt) {
          let tempClient = dataFrm.client;
          if (tempClient.domicilio) {
            tempClient.domicilio.push({
              direccion: dataFrm.client.DirectionExt.direccionExt,
              colonia: dataFrm.client.DirectionExt.coloniaExt,
              ciudad: dataFrm.client.DirectionExt.ciudadExt,
              estado: dataFrm.client.DirectionExt.estadoExt,
              cp: dataFrm.client.DirectionExt.cpExt,
            });
          } else {
            tempClient.domicilio = [{
              direccion: dataFrm.client.DirectionExt.direccionExt,
              colonia: dataFrm.client.DirectionExt.coloniaExt,
              ciudad: dataFrm.client.DirectionExt.ciudadExt,
              estado: dataFrm.client.DirectionExt.estadoExt,
              cp: dataFrm.client.DirectionExt.cpExt,
            }];
          }

          let tempDC = {};
          if (tempClient.domicilio && tempClient.domicilio[1]) {
            // eslint-disable-next-line prefer-destructuring
            tempDC = tempClient.domicilio[1];
          } else if (tempClient.domicilio && tempClient.domicilio[0]) {
            // eslint-disable-next-line prefer-destructuring
            tempDC = tempClient.domicilio[0];
          }
          tempContract.domicilio = tempDC;
          await updateEmployee(dataFrm.client._id, { domicilio: tempClient.domicilio });
        }
        if (addToClient) {
          let arrayEmpl = [];
          if (dataFrm.client.empleado) {
            const json = dataFrm.client.empleado;
            const key = '_id';
            json.forEach((_itm, inx) => {
              delete json[inx][key];
            });
            arrayEmpl = json;
            arrayEmpl.push({ idEmpleado: user._id });
          } else {
            arrayEmpl.push({ idEmpleado: user._id });
          }
          await deleteProspect(data1._id);
          await updateEmployee(dataFrm.client._id, { empleado: arrayEmpl }).then(async (resp) => {
            if (resp) {
              AddBeneficiaries(dataFrm.beneficiarios, tempContract);
            } else {
              setRespMessage('Error al procesar los datos');
              setRespLink(`/prospects/${data1._id}`);
              setRespCreate('error');
            }
          });
        } else {
          await deleteProspect(data1._id);
          AddBeneficiaries(dataFrm.beneficiarios, tempContract);
          /*
          await registerContract(tempContract).then((dataC) => {
            if (dataC && dataC._id) {
              navigate(`/clients/contract-view/${dataC._id}`);
            }
          });
          */
        }
      } catch (_error) {
        // eslint-disable-next-line no-console
        console.log('_error-2', _error);
        setRespMessage('Error al procesar los datos');
        setRespLink(`/prospects/${data1._id}`);
        setRespCreate('error');
      }
    }
  };

  const AddBeneficiaries = async (beneficiaries, dataContract) => {
    const tempBenf = [];
    await beneficiaries.forEach(async (element, indx) => {
      if (element.fileINE) {
        const { file: fileIneURLB } = await uploadFileC(element.fileINE, 'documentos/test', 'beneficiarios');
        // eslint-disable-next-line no-console
        console.log(fileIneURLB);
        const tempNewBenef = {
          nombre: element.nombre,
          apellidoPaterno: element.apellidoPaterno,
          apellidoMaterno: element.apellidoMaterno,
          direccion: element.direccion,
          colonia: element.colonia,
          ciudad: element.ciudad,
          estado: element.estado,
          cp: element.cp,
          fechaNacimiento: element.fechaNacimiento,
          edad: element.edad,
          nivel: indx + 1,
          parentesco: element.parentesco,
          telefono: element.telefono,
          url_ine: fileIneURLB || '-',
        };
        const createBenef = await insertBeneficiarie(tempNewBenef);
        if (createBenef && createBenef._id) {
          // eslint-disable-next-line no-console
          console.log(createBenef._id);
          tempBenf.push({ idBeneficiario: createBenef._id });
        }
      } else {
        const tempNewBenef = {
          nombre: element.nombre,
          apellidoPaterno: element.apellidoPaterno,
          apellidoMaterno: element.apellidoMaterno,
          direccion: element.direccion,
          colonia: element.colonia,
          ciudad: element.ciudad,
          estado: element.estado,
          cp: element.cp,
          fechaNacimiento: element.fechaNacimiento,
          edad: element.edad,
          nivel: indx + 1,
          parentesco: element.parentesco,
          telefono: element.telefono,
        };
        const createBenef = await insertBeneficiarie(tempNewBenef);
        if (createBenef && createBenef._id) {
          // eslint-disable-next-line no-console
          console.log(createBenef._id);
          tempBenf.push({ idBeneficiario: createBenef._id });
        }
      }
      if (indx === (beneficiaries.length - 1)) {
        addContract(tempBenf, dataContract);
      }
    });
  };

  const addContract = async (beneficiaries, dataContract) => {
    let tempDataContract = dataContract;
    tempDataContract.beneficiarios = beneficiaries || [];
    // eslint-disable-next-line no-console
    console.log(beneficiaries);
    // eslint-disable-next-line no-console
    console.log(tempDataContract);
    // eslint-disable-next-line no-console
    console.clear();
    await registerContract(tempDataContract).then((dataC) => {
      if (dataC && dataC._id) {
        navigate(`/clients/contract-view/${dataC._id}`);
      }
    });
  };

  const eventShowMessage = (textM) => {
    setMessage(true);
    toast(textM, {
      className: 'message'
    });
  };

  const returnViewRes = () => {
    setStateStep1('active');
    setStateStep2('');
    setStateStep3('');
  };

  const returnViewExist = () => {
    setStateStep1('');
    setStateStep2('active');
    setStateStep3('disabled');
    setData2('');
  };

  useEffect(() => {
    setStateStep1('active');
    setStateStep2('disabled');
    setStateStep3('disabled');
  }, [statusOp]);

  return (
    <>
      {statusOp && (
        <div className="absolute-content trasp ">
          {stateStep1 === 'active' && <ResumeSaleComp eventOp={eventOp} statusOp={statusOp} setData={saveData1} dataSale={dataP} eventMessage={eventShowMessage} />}
          {stateStep2 === 'active' && <TypeClientComp eventOp={eventOp} statusOp={statusOp} setData={saveData2} dataSale={dataP} returnView={returnViewRes} />}
          {stateStep3 === 'active' && data2 === 'NUEVO' && <FormCreateClientComp eventOp={eventOp} statusOp={statusOp} setData={saveData3} dataSale={dataP} />}
          {stateStep3 === 'active' && data2 === 'EXISTENTE' && <FormSelectClient eventOp={eventOp} statusOp={statusOp} setData={saveData3} dataSale={dataP} returnView={returnViewExist} />}
          {respCreate && <RespAddProspect respCreate={respCreate} eventOp={eventOp} message={respMessage} link={respLink} />}
          {message && (
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          )}
        </div>
      )}
    </>
  );
};

export default FinalizeSaleM;
