import React, { useEffect, useState } from 'react';
import { useSucursalsPerPage } from '../../../branchOffice/hooks/queries';
import { useProductsPerPage } from '../../../products/hooks/queries';
import { formatMoney, styleMoney } from '../../../../helpers/currency';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';

const DiscountPlanDetails = ({
  data, statusOp, onClose, openUpdateForm,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);

  const {
    allSucursales = false,
    sucursales = [],
    productos = [],
    estatus
  } = data;

  const handleUpdate = () => {
    onClose();
    openUpdateForm();
  };

  const planType = data.allSucursales ? 'Todas'
    : data.sucursales && data.sucursales.length > 0 ? 'Sucursal' : 'Producto';

  const {
    data: branchList,
    isFetched: isFetchedSucursal
  } = useSucursalsPerPage({});

  const {
    data: productsList = [],
    isFetched: isFetchedProduct,
  } = useProductsPerPage({ page: 1, perPage: 1000, enabled: planType === 'Producto' });

  const allProducts = productsList.data;

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid h-100">
            <div className="row">
              <div className="col-12 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="spacing-nav"> </div>
                <div className="container-fluid">

                  <div className="row content-nav-padd">
                    {planType !== 'Producto' && isFetchedSucursal && (
                      <>
                        <div className="col-12">
                          <h2>Planes aplicables</h2>
                          <br />
                          <p>
                            {data.planes === 'Todos' ? 'Todos los planes'
                              : data.planes === 'Prevision' ? 'Sólo previsión' : 'Sólo de uso inmediato'}
                          </p>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 m-t-m">
                          <h2>Sucursales aplicables</h2>
                          <br />
                          {allSucursales ? <p>Todas las sucursales</p> : (
                            <div className="row">
                              <div className="col-12">
                                {sucursales.map((sucId) => {
                                  const sucursal = branchList.find((branch) => (
                                    branch._id === sucId
                                  ));
                                  return (
                                    <div className="d-flex mb-4" key={sucursal._id}>
                                      <div className="ms-2">
                                        <label className="mb-2" htmlFor={sucursal._id}>
                                          {sucursal.nombre}
                                        </label>
                                        <span className="text-secondary">
                                          <i className="text-icon me-1">&#59679;</i>
                                          {`${sucursal.ciudad}${sucursal.estado ? `, ${sucursal.estado}` : ''}`}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {planType === 'Producto' && isFetchedProduct && isFetchedSucursal && (
                      <>
                        <div className="col-12">
                          <h2>Productos aplicables</h2>
                          <small>
                            {productos.length}
                            {' '}
                            {productos.length === 1 ? 'producto' : 'productos'}
                          </small>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 m-t-l">
                          <div className="row">
                            <div className="col-12">
                              {productos.map((prod) => {
                                const prodData = allProducts.find((p) => p._id === prod.idProducto);
                                const sucursal = branchList.find((branch) => (
                                  branch._id === prod.idSucursal
                                ));
                                return (
                                  <div className="d-flex m-b-xl" key={prod._id}>
                                    <div className="ms-2">
                                      <p className="mb-2 text-bold" htmlFor={prod._id}>
                                        {prod.nombre}
                                        {' '}
                                        <span className="text-secondary">
                                          (
                                          {prod.plan === 'Inmediata' ? 'Uso inmediato' : 'Previsión'}
                                          )
                                        </span>
                                      </p>
                                      <span className="d-block text-secondary text-money" htmlFor={prod._id}>
                                        {prod.plan === 'Prevision'
                                          ? styleMoney(formatMoney.format(prodData.precioPrevision))
                                          : styleMoney(formatMoney.format(prodData.precio))}
                                      </span>
                                      <p className="text-regular m-t-s">
                                        <i className="text-icon me-1">&#59679;</i>
                                        {`${sucursal.ciudad}${sucursal.estado ? `, ${sucursal.estado}` : ''}`}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-12 spacing-nav" />
                  </div>

                </div>
              </div>
            </div>

          </div>

          {estatus === 'Programado' && checkPermisionRoute('Descuentos', 'actualizarAplicables') && (
            <div className="form-bottom-container">
              <button
                className="button navR-content-btn button-secondary"
                onClick={handleUpdate}
                type="button"
              >
                Actualizar
              </button>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default DiscountPlanDetails;
