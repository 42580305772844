// import parse from 'html-react-parser';
import ProfileDefComponent from '../../shared/profileDef';
import '../profile.css';

const GeneralP = ({ NameC, data, eventV }) => (
  <div className="col-12 col-md-12 col-lg-8">
    <div className="row">
      <div className="col-12 col-md-6 margin-filter">
        <h2>Foto de perfil</h2>
      </div>
      <div className="col-12 col-md-6 ">
        <div className="content-btns">
          <button className="button-secondary p-r" onClick={() => eventV(true)}>
            &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
            <i className="btn-icon">&#59686;</i>
          </button>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="row">
          <div className="col-3">
            <ProfileDefComponent name={NameC} size="md" imgProf={data.imagen_perfil || null} />
          </div>
          <div className="col-9">
            <p className="name-prof datos-info text-capitalize">{NameC}</p>
          </div>
        </div>
      </div>
    </div>
    <hr className="prof-hr" />
    <div>
      <div>
        <div>
          <h2 className="title-prof">Datos personales</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <label className="datos-title">Nombre completo</label>
            <label className="datos-info text-capitalize">
              {data.nombre || ''}
              {' '}
              {data.apellidoPaterno || ''}
              {' '}
              {data.apellidoMaterno || ''}
            </label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Género</label>
            <label className="datos-info text-capitalize">{data.sexo || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Edad</label>
            <label className="datos-info">{data.edad || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Fecha de nacimiento</label>
            <label className="datos-info">{data.fecha_nacimiento || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Estado civil</label>
            <label className="datos-info text-capitalize">{data.estado_civil || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Ocupación</label>
            <label className="datos-info text-capitalize">{data.puesto || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Teléfono</label>
            <label className="datos-info">{data.telefono || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Correo electrónico</label>
            <label className="datos-info">{data.email || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">RFC</label>
            <label className="datos-info">{data.rfc || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">&nbsp;</label>
            <label className="datos-info">&nbsp;</label>
          </div>
        </div>
      </div>
      <hr className="prof-hr" />
      <div>
        <div>
          <h2 className="title-prof">Domicilio</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <label className="datos-title">Ubicación</label>
            <label className="datos-info">
              {data.ciudad || ''}
              &nbsp;
              {data.estado || ''}
            </label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Código postal</label>
            <label className="datos-info">{data.cp || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Dirección</label>
            <label className="datos-info">{data.calle || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Interior</label>
            <label className="datos-info">{data.numInterior || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Colonia</label>
            <label className="datos-info">{data.colonia || '-'}</label>
          </div>
          <div className="col-12 col-md-6">
            <label className="datos-title">Entre calles</label>
            <label className="datos-info">{data.etreCalles || '-'}</label>
          </div>
        </div>
      </div>
      <hr className="prof-hr" />
    </div>
    <div className="content-buttom">&nbsp;</div>
  </div>
);

export default GeneralP;
