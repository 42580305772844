/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react';
// import { postImagen, registrarEmpleado, actualizarEmpleado } from '../../services/index';

import { FormikStep5 } from '../../formik/formikAdd';
import '../../emple.css';

const FrmDocsEmpleado = ({
  dataForm, setDataForm, nextStep, prevStep, defVal, typeEmployee
}) => {
  const [submitted4, setSubmitted4] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);

  const {
    values,
    isValid,
    errors,
    setFieldValue
  } = FormikStep5(dataForm);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted4(false);
    if (isValid && clickSubmit) {
      setDataForm(values);
      nextStep();
    }
  };
  const onChangeFileF = async (event, nameFile, nameField) => {
    event.preventDefault();
    await setFieldValue(nameFile, event.target.files[0]);
    await setFieldValue(nameField, event.target.files[0].name);
  };

  useEffect(() => {
    if (defVal) {
      setFieldValue('fileant', defVal.fileant);
      setFieldValue('fileCV', defVal.fileCV);
      setFieldValue('fileCD', defVal.fileCD);
      setFieldValue('fileCIF', defVal.fileCIF);
      setFieldValue('fileLicencia', defVal.fileLicencia);
      setFieldValue('solicitudEmpleo', defVal.solicitudEmpleo);
    }
  }, [defVal]);

  useEffect(() => {
    if (typeEmployee && typeEmployee.puesto) {
      setFieldValue('puesto', typeEmployee.puesto);
    }
  }, [typeEmployee]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-xxl">
        <h2>Datos pesonales</h2>
        <div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fileant" className="label mt-m ">
                Antecedentes
              </label>
              <label htmlFor="fileant" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#fileant').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
                {/* archivo subido */}
              </button>
              <input
                id="fileant"
                className={`input ${submitted4 && errors.fileant && 'input has-error fileant'}`}
                type="file"
                onChange={(event) => {
                  onChangeFileF(event, 'fileant', 'namefileant');
                }}
                hidden
              />
              <br />
              {errors.fileant && <small className="caption-error">{errors.fileant}</small>}
              {values.namefileant && <div className="subido">{values.namefileant} <i className="btn-icon">&#59662;</i></div>}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fileCV" className="label mt-m ">
                Curriculum
              </label>
              <label htmlFor="fileCV" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
                <input
                  id="fileCV"
                  className={`input ${submitted4 && errors.fileCV && 'input has-error fileant'}`}
                  type="file"
                  onChange={(event) => {
                    onChangeFileF(event, 'fileCV', 'namefileCV');
                  }}
                  hidden
                />
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#fileCV').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
              </button>
              <br />
              {errors.fileCV && <small className="caption-error">{errors.fileCV}</small>}
              {values.namefileCV && <div className="subido">{values.namefileCV} <i className="btn-icon">&#59662;</i></div>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fileCD" className="label mt-m ">
                Comprobante de domicilio
              </label>
              <label htmlFor="fileCD" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
                <input
                  id="fileCD"
                  className={`input ${submitted4 && errors.fileCD && 'input has-error fileant'}`}
                  type="file"
                  onChange={(event) => {
                    onChangeFileF(event, 'fileCD', 'namefileCD');
                  }}
                  hidden
                />
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#fileCD').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
                {/* archivo subido */}
              </button>
              <br />
              {errors.fileCD && <small className="caption-error">{errors.fileCD}</small>}
              {values.namefileCD && <div className="subido">{values.namefileCD} <i className="btn-icon">&#59662;</i></div>}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fileCIF" className="label mt-m ">
                CIF
              </label>
              <label htmlFor="fileCIF" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
                <input
                  id="fileCIF"
                  className={`input ${submitted4 && errors.fileCIF && 'input has-error fileant'}`}
                  type="file"
                  onChange={(event) => {
                    onChangeFileF(event, 'fileCIF', 'namefileCIF');
                  }}
                  hidden
                />
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#fileCIF').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
                {/* archivo subido */}
              </button>
              <br />
              {errors.fileCIF && <small className="caption-error">{errors.fileCIF}</small>}
              {values.namefileCIF && <div className="subido">{values.namefileCIF} <i className="btn-icon">&#59662;</i></div>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fileINE" className="label mt-m ">
                INE
              </label>
              <label htmlFor="fileINE" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
                <input
                  id="fileINE"
                  className={`input ${submitted4 && errors.fileINE && 'input has-error fileant'}`}
                  type="file"
                  onChange={(event) => {
                    onChangeFileF(event, 'fileINE', 'namefileINE');
                  }}
                  hidden
                />
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#fileINE').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
                {/* archivo subido */}
              </button>
              <br />
              {errors.fileINE && <small className="caption-error">{errors.fileINE}</small>}
              {values.namefileINE && <div className="subido">{values.namefileINE} <i className="btn-icon">&#59662;</i></div>}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="fileLicencia" className="label mt-m ">
                Licencia
              </label>
              <label htmlFor="fileLicencia" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
                <input
                  id="fileLicencia"
                  className={`input ${submitted4 && errors.fileLicencia && 'input has-error fileant'}`}
                  type="file"
                  onChange={(event) => {
                    onChangeFileF(event, 'fileLicencia', 'namefileLicencia');
                  }}
                  hidden
                />
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#fileLicencia').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
                {/* archivo subido */}
              </button>
              <br />
              {errors.fileLicencia && <small className="caption-error">{errors.fileLicencia}</small>}
              {values.namefileLicencia && <div className="subido">{values.namefileLicencia} <i className="btn-icon">&#59662;</i></div>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="solicitudEmpleo" className="label mt-m ">
                Solicitud Empleo
              </label>
              <label htmlFor="solicitudEmpleo" className="label mt-m ">
                Los Tipo de Archivos admitidos son JPG, PNG y PDF
                <input
                  id="solicitudEmpleo"
                  className={`input ${submitted4 && errors.solicitudEmpleo && 'input has-error fileant'}`}
                  type="file"
                  onChange={(event) => {
                    onChangeFileF(event, 'solicitudEmpleo', 'namefileSol');
                  }}
                  hidden
                />
                <br />
                {errors.solicitudEmpleo && <small className="caption-error">{errors.solicitudEmpleo}</small>}
                {values.namefileSol && <div className="subido">{values.namefileSol} <i className="btn-icon">&#59662;</i></div>}
              </label>
              <button
                className="botonsubida button"
                onClick={() => document.querySelector('#solicitudEmpleo').click()}
              >
                Subir Archivo&nbsp;&nbsp;
                <i className="btn-icon">&#59661;</i>
                {/* archivo subido */}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <button className="button btn-step-prev" type="button" onClick={() => prevStep()}>
          Regresar
        </button>
        <button onClick={() => setClickSubmit(true)} className="button btn-step" type="submit" disabled={!isValid}>
          Finalizar
        </button>
        <br />
        <br />
      </div>
    </form>
  );
};
export default FrmDocsEmpleado;
