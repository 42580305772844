import React, { useEffect, useState } from 'react';
import CheckBox from '../../../../global/custom/checkbox';

const SucursalCheckboxStep = ({
  branchList,
  onClose,
  statusOp,
  nextStep,
  prevStep,
  selectedSucursals,
  setSelectedSucursals,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [sucursals, setSucursals] = useState([]);
  const [btnEnabled, setBtnEnabled] = useState(false);

  const handleSubmit = () => {
    setSelectedSucursals(sucursals.filter((sucursal) => {
      if (sucursal.checked) {
        return {
          ...sucursal,
          checked: undefined,
        };
      }
      return null;
    }));
    nextStep();
  };

  const checkSucursal = (id) => {
    setSucursals(sucursals.map((sucursal) => {
      if (sucursal._id === id) {
        return { ...sucursal, checked: !sucursal.checked };
      }
      return sucursal;
    }));
  };

  useEffect(() => {
    sucursals.forEach((sucursal) => {
      if (sucursal.checked) {
        setBtnEnabled(true);
      }
    });
  }, [sucursals]);

  useEffect(() => {
    if (branchList && branchList.length > 0) {
      const filteredBranchList = branchList;
      setSucursals(filteredBranchList.map((sucursal) => ({
        ...sucursal,
        checked: selectedSucursals.findIndex(
          (selectedSucursal) => selectedSucursal._id === sucursal._id
        ) >= 0 || false
      })));
    }
  }, [branchList]);

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid side-form-container">
            <div className="row">
              <div className="col-6">
                <span
                  className="icon-close return-icon"
                  onClick={prevStep}
                >
                  {' '}
                </span>
              </div>
              <div className="col-6 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Sucursales aplicables</h2>
                      <br />
                      <small>Selecciona las sucursales elegibles para este descuento.</small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12">

                      {sucursals.map((sucursal) => (

                        <div className="d-flex mb-4" key={sucursal._id}>
                          <CheckBox
                            shape="circle"
                            status={sucursal.checked}
                            eventC={() => checkSucursal(sucursal._id)}
                            nameC={sucursal._id}
                          />
                          <div className="ms-2">
                            <label className="form-check-label" htmlFor={sucursal._id}>
                              {sucursal.nombre}
                            </label>
                            <small>
                              {sucursal.ciudad}
                              ,
                              {' '}
                              {sucursal.estado}
                            </small>
                          </div>
                        </div>

                      ))}
                      {/*  */}

                    </div>
                    <div className="col-12 spacing-nav" />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="form-bottom-container">
            <button
              className="button navR-content-btn"
              onClick={handleSubmit}
              type="button"
              disabled={!btnEnabled}
            >
              Continuar
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SucursalCheckboxStep;
