/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { FContractInfo } from '../formik/formik';
import { getAllSucursales, updateContract } from '../services';
import pagoMeses from '../../../global/data/pagoMeses.json';
import EstatusContract from '../../../global/data/estatusContract.json';

const FrmDataContract = ({
  dataForm, eventV, setOpenConfirmDialog, openConfirmDialog, optionUpdate, eventShowMessage, refresh,
}) => {
  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue
  } = FContractInfo();

  const {
    folio,
    noContrato,
    sucursal,
    fecha,
    comentarios,
    precioVenta,
    formaPago,
    mensualidades,
    mensualidad,
    diaPago,
    pagoInicial,
    liquidado,
    saldo,
    estatus_contrato,
    lote,
  } = values;
  const [submitted, setSubmitted] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [status, setStatus] = useState('idle');
  const [showMonthlyPayments, setShowMonthlyPayments] = useState(true);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid && dirty) {
      setStatus('idle');
      // eventV(false);
      setOpenConfirmDialog(true);
    }
  };
  const CofirmHandleSubmit = async () => {
    if (isValid && dirty) {
      const tempMens = ((parseFloat(precioVenta) - parseFloat(pagoInicial)) / mensualidades).toFixed(2);
      const tempData = {
        folio,
        noContrato,
        sucursales: { idSucursal: sucursal },
        fecha,
        comentarios,
        precioVenta: parseInt(precioVenta, 10),
        formaPago,
        mensualidad: tempMens,
        plazo: `${mensualidades}X $${tempMens}`,
        diaPago: diaPago.toString(),
        pagoInicial: parseInt(pagoInicial, 10),
        liquidado: parseInt(liquidado, 10),
        saldo: parseInt(saldo, 10),
        estatus_contrato,
      };
      if (lote && lote !== '') {
        tempData.lote = lote;
      }
      await updateContract(dataForm._id, tempData).then((resp) => {
        if (resp) {
          refresh();
          eventV(false);
        } else {
          eventShowMessage('No se pudo actualizar, intente de nuevo mas tarde.');
        }
      });
    }
  };
  const getServiveSucursal = async () => {
    const tempS = await getAllSucursales();
    const tempSuc = [];
    if (tempS && tempS.length > 0) {
      tempS.forEach((element) => {
        tempSuc.push({
          nombre: element.nombre.toUpperCase(),
          id: element._id
        });
      });
      setSucursales(tempSuc);
    }
  };

  const setTextCaract = (e) => {
    setFieldValue('comentarios', e);
  };
  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const calcMonthlyPayment = (months, precioV, downPayment = 0) => {
    if (parseInt(downPayment, 10) > parseInt(precioVenta, 10)) {
      return '';
    }
    if (months && precioV) {
      try {
        const tempM = parseInt(months, 10);
        const tempMonth = (parseFloat(precioV) - parseFloat(downPayment)) / tempM;
        return formatMoney.format(tempMonth);
      } catch (_error) {
        return '$-';
      }
    }
    return '$-';
  };
  const _handleChange = (_field, _ev) => {
    if (_ev) {
      setFieldValue(`${_field}`, _ev);
    } else {
      setFieldValue(`${_field}`, '0');
    }
  };

  useEffect(() => {
    if (dataForm) {
      getServiveSucursal();
      setFieldValue('folio', dataForm.folio);
      setFieldValue('noContrato', dataForm.noContrato);
      setFieldValue('sucursal', dataForm.sucursales.idSucursal);
      setFieldValue('fecha', dataForm.fecha);
      setFieldValue('comentarios', dataForm.comentarios);
      setFieldValue('precioVenta', dataForm.precioVenta);
      setFieldValue('formaPago', dataForm.formaPago === 'contado' || dataForm.formaPago === 'cortesia' ? (dataForm.formaPago === 'cortesia' ? 'cortesia' : 'contado') : 'mensualidades');
      setFieldValue('diaPago', dataForm.diaPago);
      setFieldValue('pagoInicial', dataForm.pagoInicial);
      setFieldValue('liquidado', dataForm.liquidado);
      setFieldValue('saldo', dataForm.saldo);
      setFieldValue('mensualidades', dataForm.plazo);
      setFieldValue('estatus_contrato', dataForm.estatus_contrato.toLowerCase());
    }
  }, [dataForm]);

  useEffect(() => {
    try {
      const showP = !errors?.anticipo && parseInt(pagoInicial, 10) <= parseInt(precioVenta, 10);
      if (parseInt(liquidado, 10) <= parseInt(precioVenta, 10) && showP && isValid) {
        setTimeout(() => {
          setStatus('');
        }, 1000);
        setShowMonthlyPayments(showP);

        setFieldValue('saldo', parseInt(precioVenta, 10) - parseInt(liquidado, 10));
      } else {
        setTimeout(() => {
          setStatus('idle');
        }, 1000);
        setShowMonthlyPayments(false);
      }
    } catch (_error) {
      setTimeout(() => {
        setStatus('idle');
      }, 1000);
      setShowMonthlyPayments(false);
    }
  }, [errors, pagoInicial, precioVenta, liquidado, isValid]);

  useEffect(() => {
    if (formaPago === 'contado') {
      setFieldValue('mensualidades', 1);
    } else {
      setFieldValue('mensualidades', dataForm.plazo);
    }
  }, [formaPago]);
  useEffect(() => {
    if (isValid) {
      setStatus('');
    } else {
      setStatus('idle');
    }
  }, [values, isValid]);
  useEffect(() => {
    if (optionUpdate) {
      CofirmHandleSubmit();
    }
  }, [optionUpdate]);
  return (
    <div className="container" style={{ display: openConfirmDialog ? 'none' : '' }}>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-xxl">
            <h2>Detalles del contrato</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="noContrato" className="label mt-m">
                    Número de contrato
                    <input
                      onChange={handleChange('noContrato')}
                      value={noContrato}
                      id=""
                      type="text"
                      className={`input ${submitted && errors.noContrato && 'has-error'}`}
                    />
                    {submitted && errors.noContrato && <small className="caption-error">{errors.noContrato}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="folio" className="label mt-m">
                    Folio
                    <input
                      onChange={handleChange('folio')}
                      value={folio}
                      id="folio"
                      type="text"
                      className={`input ${submitted && errors.folio && 'has-error'}`}
                    />
                    {submitted && errors.folio && <small className="caption-error">{errors.folio}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="label labels mt-m">
                    Sucursal
                    <select name="sucursal" value={sucursal} type="select" onChange={handleChange('sucursal')}>
                      <option value="" label="">Seleccione una opción</option>
                      {
                        sucursales && sucursales.map((item) => (
                          <option key={item.id} value={item.id} label={item.nombre}>{item.nombre}</option>
                        ))
                      }
                    </select>
                    {submitted && errors.sucursal && (
                      <small className="caption-error">
                        {errors.sucursal}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="fecha" className="label mt-m">
                    Fecha de creación
                    <input
                      id="fecha"
                      type="date"
                      className={`input input-date ${submitted && errors.fecha && 'has-error'}`}
                      onChange={handleChange('fecha')}
                      value={fecha}
                    />
                    {
                      submitted && errors.fecha && <small className="caption-error">{errors.fecha}</small>
                    }
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <label htmlFor="comentarios" className="label mt-m">
                    Comentarios
                    <textarea
                      id="comentarios"
                      placeholder="Ingresa aquí los comentarios"
                      className={`input textarea ${submitted && errors.comentarios && 'has-error'}`}
                      name="comentarios"
                      value={comentarios}
                      onChange={(e) => setTextCaract(e.target.value)}
                      rows="5"
                    />
                    {submitted && errors.comentarios && (
                      <small className="caption-error">
                        {errors.comentarios}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label className="label labels mt-m">
                    Estatus del contrato
                    <select name="estatus_contrato" value={estatus_contrato} type="select" onChange={handleChange('estatus_contrato')}>
                      <option value="" label="">Seleccione una opción</option>
                      {
                        EstatusContract && EstatusContract.map((item) => (
                          <option key={generateKey(item.value)} value={item.value} label={item.label}>{item.label}</option>
                        ))
                      }
                    </select>
                    {submitted && errors.estatus_contrato && (
                    <small className="caption-error">
                      {errors.estatus_contrato}
                    </small>
                    )}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="lote" className="label mt-m">
                    Lote Asignado
                    <input
                      onChange={handleChange('lote')}
                      value={lote}
                      id="lote"
                      type="text"
                      className={`input ${submitted && errors.lote && 'has-error'}`}
                    />
                    {submitted && errors.lote && <small className="caption-error">{errors.lote}</small>}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Detalles del pago</h2>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="precioVenta" className="label mt-m">
                    Total de la venta
                    <CurrencyInput
                      id="precioVenta"
                      name="precioVenta"
                      placeholder=""
                      className="input "
                      defaultValue={200}
                      decimalsLimit={2}
                      value={precioVenta}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      allowNegativeValue={false}
                      onValueChange={(e) => _handleChange('precioVenta', e || 0)}
                    />
                    {submitted && errors && errors.precioVenta && <small className="caption-error">{errors.precioVenta}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <label htmlFor="Tipo de pago" className="label mt-m">Tipo de pago</label>
                  <label htmlFor="formaPago" className="label labels">
                    <button type="button" onClick={() => setFieldValue('formaPago', 'mensualidades')} className={`btn-edc ${formaPago === 'mensualidades' ? 'active' : ''} genderM`}>Mensualidades</button>
                    <button type="button" onClick={() => setFieldValue('formaPago', 'contado')} className={`btn-edc ${formaPago === 'contado' ? 'active' : ''} genderF`}>Contado</button>
                    <button type="button" onClick={() => setFieldValue('formaPago', 'cortesia')} className={`btn-edc ${formaPago === 'cortesia' ? 'active' : ''} genderF`}>Cortesía</button>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="mensualidades" className="label mt-m">
                    Mensualidades
                    <select
                      name="mensualidades"
                      id="mensualidades"
                      className={`input ${submitted && errors.mensualidades && 'has-error'}`}
                      disabled={formaPago === 'contado'}
                      value={mensualidades}
                      onChange={(e) => setFieldValue('mensualidades', e.target.value)}
                    >
                      <option value="">Seleccione una opción</option>
                      {showMonthlyPayments && pagoMeses.map((pago) => (
                        <option key={pago.value} value={pago.value}>
                          {pago.label.toUpperCase()}
                          {' '}
                          {calcMonthlyPayment(pago.value, precioVenta, pagoInicial)}
                        </option>
                      ))}
                    </select>
                    {!showMonthlyPayments && (
                      <small className="caption-error">
                        Los montos del contrato son inconsistentes
                      </small>
                    )}
                    {submitted && errors.mensualidades && (
                      <small className="caption-error">
                        {errors.mensualidades}
                      </small>
                    )}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="diaPago" className="label mt-m">
                    Fecha de pago
                    <input
                      onChange={handleChange('diaPago')}
                      value={diaPago}
                      id="diaPago"
                      type="number"
                      className={`input ${submitted && errors.diaPago && 'has-error'}`}
                    />
                    {submitted && errors.diaPago && <small className="caption-error">{errors.diaPago}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="pagoInicial" className="label mt-m">
                    Pago inicial
                    <CurrencyInput
                      id="pagoInicial"
                      name="pagoInicial"
                      placeholder=""
                      className="input "
                      defaultValue={200}
                      decimalsLimit={2}
                      value={pagoInicial}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      allowNegativeValue={false}
                      onValueChange={(e) => _handleChange('pagoInicial', e || 0)}
                    />
                    {submitted && errors.pagoInicial && <small className="caption-error">{errors.pagoInicial}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="liquidado" className="label mt-m">
                    Total abonado
                    <CurrencyInput
                      id="liquidado"
                      name="liquidado"
                      placeholder=""
                      className="input "
                      defaultValue={0}
                      decimalsLimit={2}
                      value={liquidado}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      allowNegativeValue={false}
                      onValueChange={(e) => _handleChange('liquidado', e || 0)}
                    />
                    {submitted && errors.liquidado && <small className="caption-error">{errors.liquidado}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="saldo" className="label mt-m">
                    Saldo restante
                    <CurrencyInput
                      id="saldo"
                      name="saldo"
                      placeholder=""
                      className="input "
                      defaultValue={0}
                      decimalsLimit={2}
                      value={saldo}
                      prefix="$"
                      pattern="[$]+[0-9]+([\.,][0-9]+)?"
                      allowNegativeValue={false}
                      onValueChange={(e) => _handleChange('saldo', e || 0)}
                    />
                    {submitted && errors.saldo && <small className="caption-error">{errors.saldo}</small>}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <div className="row">
              <div className="col-12">
                <div className="col-12 col-md-12">
                  &nbsp;
                </div>
                <div className="col-12 col-md-12">
                  <button type="button" onClick={() => eventV(false)} className="button-secondary p-r m-btn-prof">
                    Cancelar
                  </button>
                  <button className="button mt-m" type="submit" disabled={status === 'idle' ? 'disabled' : ''}>
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};
export default FrmDataContract;
