import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Message from '../../../shared/message/Message';
import { actualizarEmpleado } from '../../services';

const RestorePermissionsModal = ({
  onClose,
  refreshData,
  modules,
  employeeId,
  defaultModules,
}) => {
  const [status, setStatus] = useState('idle');

  const handleSubmit = async () => {
    setStatus('loading');
    const updatedModules = modules.data.map((module) => {
      const defaultModule = defaultModules.find((mod) => mod.nombre === module.nombre);
      if (!defaultModule) {
        return {
          idModulo: module._id,
          nombre: module.nombre,
          activo: false,
        };
      }
      return {
        idModulo: module._id,
        nombre: module.nombre,
        activo: defaultModule.activo,
        permisos: defaultModule.permisos,
      };
    }).filter((module) => module.activo);

    try {
      const res = await actualizarEmpleado(employeeId, { modulos: updatedModules });
      if (res.errors) {
        throw res.errors;
      } else {
        toast('Permisos restaurados', { className: 'message' });
        refreshData();
      }
    } catch (err) {
      toast('Ha ocurrido un error, vuelve a intentarlo más tarde', {
        className: 'message error'
      });
    } finally {
      onClose();
    }
  };

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="container">

        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-lg-8 col-xl-5">
            <div className="message-container">
              {status === 'loading' && (
                <Message
                  type="loading"
                  title="Cargando..."
                  content="Esta operación podría demorar algunos minutos. Por favor, espere."
                />
              )}
              {status === 'idle' && (
                <Message
                  type="alert"
                  title="¿Seguro que deseas restaurar los permisos de este usuario?"
                  message="Esto revertirá todos los cambios realizados en los permisos del usuario para todos los módulos."
                  content=""
                  confirmText="Sí, restaurar"
                  cancelText="No, cancelar"
                  onConfirm={handleSubmit}
                  onCancel={onClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestorePermissionsModal;
