import React from 'react';

const ProductFeatures = ({ allowEdit, data, openEditForm }) => (
  <>
    <div className="d-flex justify-content-between mt-xxl">
      <h2>Características</h2>
      {allowEdit && (
        <button
          className="button-secondary"
          onClick={() => openEditForm('ProductFeatures')}
        >
          Actualizar
          <span className="button-icon pencil-line" />
        </button>
      )}
    </div>
    <div className="row mt-5">
      {data.map((spec) => (
        <div className="feature" key={spec}>
          <span className="d-block">{spec}</span>
        </div>
      ))}
      {data.length === 0
        && <p className="datos-info">No hay características disponibles</p>}
    </div>
    <hr />
  </>
);

export default ProductFeatures;
