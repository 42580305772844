/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClientes } from '../../../servives';

const ViewSearchAllClient = ({
  setData
}) => {
  const navigate = useNavigate();
  const [LClients, setLClients] = useState([]);
  const [searchInp, setSearchInp] = useState('');
  const [orderList, setOrderList] = useState('asc');

  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;
  const perPage = 15;

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  const getClientsL = async () => {
    const nombre = searchInp ? `filtro=${encodeURIComponent(searchInp).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';
    const pag = '&paginacion=1';
    const pags = `&paginas=${perPage}`;
    if (searchInp !== '') {
      const tempQuery = `${nombre}${pag}${pags}&orden=${orderList}`;
      await getClientes(tempQuery).then((data) => {
        if (data && data.data && data.total > 0) {
          setLClients(data.data || []);
        } else {
          setLClients([]);
        }
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      const token = localStorage.getItem('token').toString();
      if (token) {
        getClientsL();
      } else {
        closeSession();
      }
    }
  };

  const checkIfAuthorize = (dataClient) => {
    let blockData = true;
    const user = JSON.parse(localStorage.getItem('user'));
    dataClient.idEmpleados.forEach((element) => {
      if (user._id === element) {
        blockData = false;
      }
    });
    return blockData;
  };

  useEffect(() => {
    if (searchInp === '') {
      setLClients([]);
    }
  }, [searchInp]);

  return (
    <div className="col-12">
      <div className="col-12 spacing-nav"> </div>
      <div className="container-fluid">
        <div className="row content-nav-padd">
          <div className="col-12">
            <h2>Buscar cliente</h2>
            <br />
          </div>
          <div className="col-12">
            {(searchInp === '' || LClients.length === 0) && (
              <small>
                Introduce el teléfono o correo electrónico del cliente que deseas consultar.
              </small>
            )}
            {searchInp !== '' && (
              <small>
                {LClients.length}
                {' '}
                Resultado
              </small>
            )}
            <br />
          </div>
          <div className="col-12">
            <br />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="input-container">
                <input id="buscar" onChange={(e) => setSearchInp(e.target.value)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Teléfono o correo" />
                <span className="input-icon search-line" />
              </div>
            </div>
          </div>
          <div className="col-12">
            {LClients && LClients.map((item) => (
              <div
                key={generateKey(item._id)}
                className="container-fluid "
                onClick={() => setData(item)}
              >
                <div className="row row-itemN ">
                  <div className="col-10 ">
                    <p className="text-capitalize">
                      {item.nombre || ''}
                      {' '}
                      {item.apellidoPaterno || ''}
                      {' '}
                      {item.apellidoMaterno || ''}
                      {checkIfAuthorize(item) ? <i className="icon-i-select">&#59690;</i> : ''}
                    </p>
                    <p className="nav-text-opacity">{item.email}</p>
                    <label className="text-dir">
                      <i>&#59679;</i>
                      {item.ubicacion || '-'}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-12 spacing-nav"> </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSearchAllClient;
