import { useState } from 'react';
import ListProspects from '../../features/prospects/ListProspects';
import GMenuForm from '../../features/menu';
import AddProspectM from '../../features/prospects/addProspectM';
// AddProspectM
const Prospects = () => {
  const [openM, setOpenM] = useState(false);
  const eventM = () => {
    setOpenM(!openM);
  };
  return (
    <>
      <AddProspectM eventOp={eventM} statusOp={openM} />
      <GMenuForm />
      <div className="container">
        <div className="row align-items-center justify-content-center m-t-xxxl">
          <div className="col-12 col-md-12 col-lg-8">
            <ListProspects eventOp={eventM} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Prospects;
