/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Switch from 'react-switch';
import { ToastContainer } from 'react-toastify';
import UpdatePermissionsModal from './UpdatePermissionsModal';
import { useModules } from '../../hooks/queries';
import rolesPermissions from '../../../shared/routeGuards/rolesPermissions.json';
import RestorePermissionsModal from './RestorePermissionsModal';
import CheckBox from '../../../../global/custom/checkbox';
import Message from '../../../shared/message/Message';

const EmployeePermissions = ({ employeeData, refreshData, permissionsF }) => {
  const [showModal, setShowModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showRestoreButton, setShowRestoreButton] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [defaultModules, setDefaultModules] = useState([]);

  const { data: modules, isFetched } = useModules();

  const verifyActiveModule = (id) => {
    const moduleIndex = employeeData.modulos.find((mod) => mod.idModulo === id);
    return moduleIndex ? moduleIndex.activo : false;
  };

  const verifyGrantedPermission = (modId, action) => {
    const module = employeeData.modulos.find((mod) => mod.idModulo === modId);
    const granted = module
      ? module.permisos.find((permission) => permission.accion === action)
      : false;
    return !!granted;
  };

  const comparePermissionsByModule = (module, defaultModule) => {
    if (!module.activo) {
      if (module.permisos.length > 0) {
        return false;
      }
      return true;
    }
    const permissions = defaultModule.permisos.map((obj) => obj.accion);
    const grantedPermissions = module.permisos.map((obj) => obj.accion);
    if (permissions.toString() !== grantedPermissions.toString()) {
      return false;
    }
    return true;
  };

  const compareDefaultPermissions = () => {
    const activeModules = employeeData.modulos.filter((mod) => mod.activo).length;

    if (activeModules !== defaultModules.length) {
      setShowRestoreButton(true);
    } else {
      const equalPermissions = employeeData.modulos.every((module) => {
        const defaultModule = defaultModules.find((obj) => obj.nombre === module.nombre);
        return comparePermissionsByModule(module, defaultModule);
      });
      setShowRestoreButton(!equalPermissions);
    }
  };

  const handleSelectModule = (id) => {
    setSelectedModule(id);
    setShowModal(true);
  };

  useEffect(() => {
    compareDefaultPermissions();
  }, [defaultModules]);

  useEffect(() => {
    if (defaultModules.length === 0) {
      const roleItem = rolesPermissions.find(
        (obj) => obj.puesto.toLowerCase() === employeeData.puesto.toLowerCase()
      );
      setDefaultModules(roleItem ? roleItem.modulos : []);
    } else {
      compareDefaultPermissions();
    }
  }, [employeeData]);

  return (
    <>
      {showModal && (
        <UpdatePermissionsModal
          employeeId={employeeData._id}
          employeeRole={employeeData.puesto}
          onClose={() => setShowModal(false)}
          modules={modules?.data}
          employeeModules={employeeData.modulos || []}
          moduleId={selectedModule}
          verifyGrantedPermission={verifyGrantedPermission}
          comparePermissionsByModule={comparePermissionsByModule}
          isModuleActive={verifyActiveModule(selectedModule)}
          refreshData={refreshData}
        />
      )}
      {showRestoreModal && (
        <RestorePermissionsModal
          onClose={() => setShowRestoreModal(false)}
          modules={modules}
          employeeId={employeeData._id}
          defaultModules={defaultModules}
          refreshData={refreshData}
        />
      )}
      {!isFetched && (
        <Message
          type="loading"
          title="Cargando..."
          content=""
          message="Por favor, espere."
        />
      )}
      <div className="container" style={{ display: isFetched ? 'block' : 'none' }}>
        <div className="row">
          <div className="col col-md-12 col-lg-2"> </div>
          <div className="col-12 col-md-12 col-lg-8 m-accordion">

            {modules.data.map((module) => {
              const isModuleActive = verifyActiveModule(module._id) || false;
              return (
                <Accordion
                  defaultActiveKey={[]}
                  key={module._id}
                  alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="m-t-l">
                      {module.nombre}
                    </Accordion.Header>

                    {permissionsF('Permisos', 'actualizarPermisos') && (
                    <button className="button-secondary btn-accordion" onClick={() => handleSelectModule(module._id)}>
                      &nbsp;&nbsp;Actualizar&nbsp;&nbsp;
                      <i className="btn-icon">&#59686;</i>
                    </button>
                    )}

                    <Accordion.Body>
                      <div className="row">
                        <div className="row prospect-message-cont m-t-l">
                          <div className="col-10 col-md-11">
                            <p>Acceder al módulo</p>
                          </div>
                          <div className="col-2 col-md-1">
                            <Switch
                              checked={isModuleActive}
                              onChange={() => {}}
                              onColor="#000000"
                              onHandleColor="#FFFFFF"
                              offColor="#CFCFCF"
                              offHandleColor="#FFFFFF"
                              borderRadius={60}
                              handleDiameter={5}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={15}
                              width={25}
                              className="react-switch"
                              id="material-switch"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="m-t-l">
                          {module.permisos.map((permission) => (
                            <div className="d-flex mb-2" key={permission.accion}>
                              <CheckBox
                                status={verifyGrantedPermission(module._id, permission.accion)}
                                eventC={() => {}}
                                disabled
                                nameC={permission.accion}
                              />
                              <label className="text-secondary" htmlFor={permission.accion}>
                                {permission.titulo}
                              </label>
                            </div>
                          ))}
                        </div>

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              );
            })}

            {showRestoreButton && (
              <div className="d-flex justify-content-end m-t-xl">
                <button
                  className="button-secondary"
                  onClick={() => setShowRestoreModal(true)}
                >
                  &nbsp;&nbsp;Restaurar valores predeterminados&nbsp;&nbsp;
                  <i className="btn-icon">&#59711;</i>
                </button>
              </div>
            )}

          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default EmployeePermissions;
