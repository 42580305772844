import React, { useEffect, useState } from 'react';
import CheckBox from '../../../../global/custom/checkbox';
import { formatMoney, styleMoney } from '../../../../helpers/currency';

const ProductListScreen = ({
  onClose,
  statusOp,
  nextStep,
  prevStep,
  products,
  setProducts,
  openDiscountModal,
  handleUpdate,
  isUpdate,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);

  const handleSubmit = () => {
    if (isUpdate) {
      return handleUpdate();
    }
    return openDiscountModal();
  };

  const checkProduct = (idLista) => {
    setProducts(products.map((prod) => {
      if (prod.idLista === idLista) {
        return { ...prod, checked: !prod.checked };
      }
      return prod;
    }));
  };

  const deleteProducts = () => {
    setProducts(products.filter((prod) => !prod.checked));
  };

  useEffect(() => {
    setTotalSelected(products.reduce((prev, current) => {
      if (current.checked) {
        // eslint-disable-next-line no-return-assign
        return prev += 1;
      }
      return prev;
    }, 0));
  }, [products]);

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid side-form-container">
            <div className="row">
              <div className="col-6">
                <span
                  className="icon-close return-icon"
                  onClick={prevStep}
                >
                  {' '}
                </span>
              </div>
              <div className="col-6 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Productos aplicables</h2>
                      <br />
                      <small>
                        {products.length}
                        {' '}
                        {products.length === 1 ? 'producto' : 'productos'}
                      </small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12 m-b-xxl">
                      <button className="button-secondary w-100" onClick={nextStep}>
                        Agregar
                      </button>
                    </div>
                    <div className="col-12">
                      {products.map((product) => (
                        <div className="d-flex mb-4" key={product.idLista}>
                          <CheckBox
                            shape="circle"
                            status={product.checked}
                            eventC={() => checkProduct(product.idLista)}
                            nameC={product.idLista}
                          />
                          <div className="ms-2">
                            <label className="form-check-label" htmlFor={product.idLista}>
                              {product.nombre}
                              {' '}
                              <span className="text-secondary">
                                (
                                {product.plan === 'Inmediata' ? 'Uso inmediato' : 'Previsión'}
                                )
                              </span>
                            </label>
                            <span className="text-money text-secondary">
                              {product.plan === 'Prevision'
                                ? styleMoney(formatMoney.format(product.precioPrevision))
                                : styleMoney(formatMoney.format(product.precio))}
                            </span>
                            <span className="d-block text-regular mt-2">
                              <i className="text-icon">&#59679;</i>
                              {' '}
                              {product.sucursal.ciudad}
                            </span>

                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-12 spacing-nav" />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="form-bottom-container">
            {totalSelected === 0 ? (
              <button
                className="button navR-content-btn"
                onClick={handleSubmit}
                type="button"
                disabled={products.length === 0}
              >
                {isUpdate ? 'Guardar cambios' : 'Crear promoción'}
              </button>
            )
              : (
                <button
                  className="button navR-content-btn button-secondary"
                  onClick={deleteProducts}
                  type="button"
                >
                  Eliminar (
                  {totalSelected}
                  )
                </button>
              )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default ProductListScreen;
