/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import parse from 'html-react-parser';
import '../noyification.css';

const TabNotificatios = ({ data, metodRead, checkNotification }) => {
  const [activeOpti, setActiveOpti] = useState('');

  const checkTypeN = (item) => {
    if (item === 'agenda') {
      return parse('&#59681;');
    }
    if (item === 'pago') {
      return parse('&#59682;');
    }
    if (item === 'directorio') {
      return parse('&#59676;');
    }
    if (item === 'Registro') {
      return parse('&#59650;');
    }
    if (item === 'Desactivacion') {
      return parse('&#59691;');
    }
    return parse('&nbsp;');
  };

  const checkOption = (item) => {
    if (item === activeOpti) {
      return true;
    }
    return false;
  };

  const formatDateTime = (date) => {
    try {
      const newDate = new Date(date);
      const nowDate = new Date();
      const diffTime = Math.abs(nowDate - newDate);
      const timeDiffH = Math.ceil(diffTime / (1000 * 60 * 60));
      if (timeDiffH < 24) {
        if (timeDiffH < 2) {
          return 'Hace unos minutos.';
        }
        return `Hace ${timeDiffH} horas.`;
      }

      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      let currentHours = newDate.getHours();
      currentHours = (`0${currentHours}`).slice(-2);
      let currentMinut = newDate.getMinutes();
      currentMinut = (`0${currentMinut}`).slice(-2);
      const tempHM = `${currentHours}:${currentMinut}`;
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${meses[newDate.getMonth()]} ${tempDate.substr(tempDate.indexOf(' ') + 1)} · ${tempHM}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  };

  const setActiveOption = (item) => {
    setActiveOpti(item);
  };
  const setQuitOption = (itemID) => {
    setActiveOption('');
    metodRead(itemID);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-12">
          {
            data && data.length > 0 && data.map((item) => (
              <div className="row" key={item._id} onMouseLeave={() => setActiveOption('')}>
                <div className="option-tab fdc">
                  <div className="row">
                    <div className="col-11" onClick={() => checkNotification(item)}>
                      <div className="row">
                        <div className="col-2 col-md-1">
                          <i className="type-notif">{checkTypeN(item.type)}</i>
                        </div>
                        <div className="col-10 col-md-10">
                          <label className="text-name">{item.message}</label>
                          <label className="text-email">{formatDateTime(item.time)}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="row">
                        <div className="col-12 p-r">
                          <i className="read-notif">{!item.read ? parse('&#59666;') : parse('&nbsp;')}</i>
                          <i className="options-notif" onClick={() => setActiveOption(item._id)}>&#59683;</i>
                          {checkOption(item._id) && (
                            <div className="drop-menu lg">
                              <label onClick={() => setQuitOption(item._id)}>
                                Marcar como Leido
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="notif-line-spacing" />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default TabNotificatios;
