/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
// import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
// import Config from '../../../global/constants';
import AddProspStep1 from './components/addProspects/addProspStep1';
import AddProspStep2 from './components/addProspects/addProspStep2';
import AddProspStep4 from './components/addProspects/addProspStep4';
import './prospects.css';
import { getProspectsById, updateProspectsById } from './servives';
import Message from '../shared/message/Message';

const AddProspectsC = ({ saveData, closeForm }) => {
  const { id: prospectId } = useParams();
  const queryClient = useQueryClient();

  const [step1, setStep1] = useState('&#59666;');
  const [step2, setStep2] = useState('&#59652;');
  const [step3, setStep3] = useState('&#59652;');

  const [stateStep1, setStateStep1] = useState('active');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [stateStep3, setStateStep3] = useState('disabled');
  const [activeStep, setActiveStep] = useState(1);

  const [dataForm1, setData1] = useState(null);
  const [dataForm2, setData2] = useState(null);
  const [dataForm3, setData3] = useState(null);

  const [seguimiento, setSeguimiento] = useState([]);
  const [status, setStatus] = useState(prospectId ? 'loading' : 'idle');

  const nextStep = () => {
    let tempActS = activeStep;
    if (tempActS === 1) {
      setStateStep1('complete');
      setStateStep2('active');
      setStep1('&#59654;');
      setStep2('&#59666;');
    }
    if (tempActS === 2) {
      setStateStep1('complete');
      setStateStep2('complete');
      setStateStep3('active');
      setStep2('&#59654;');
      setStep3('&#59666;');
    }
    setActiveStep(tempActS += 1);
  };

  const prevStep = (tempData) => {
    let tempActS = activeStep;
    if (tempActS === 2) {
      setStateStep1('active');
      setStateStep2('disabled');
      setStateStep3('disabled');
      setStep1('&#59654;');
      setStep2('&#59652;');
      setStep3('&#59652;');
    }
    if (tempActS === 3) {
      setStateStep1('complete');
      setStateStep2('active');
      setStateStep3('disabled');
      setStep1('&#59654;');
      setStep2('&#59666;');
      setStep3('&#59652;');
      if (tempData) {
        setData3(tempData);
      }
    }
    setActiveStep(tempActS -= 1);
  };

  const setDataForm1 = async (data) => {
    await setData1(data);
    setData3({ numero: data.phone });
  };
  const setDataForm2 = async (data) => {
    await setData2(data);
  };
  const setDataForm3 = async (data) => {
    await setData3(data);
    createProspecto(data);
  };

  const createProspecto = async (dataF3) => {
    const tempSeg = [];
    const tempUserD = JSON.parse(localStorage.getItem('user'));
    if (dataF3.agendar) {
      if (dataF3.contacto !== 'Cita') {
        tempSeg.push({
          idEmpleado: tempUserD._id,
          tipo: dataF3.contacto,
          fecha: `${dataF3.fecha} ${dataF3.hora} GMT`,
          numero: dataF3.numero,
          comentarios: dataF3.comentarios || undefined,
        });
      } else {
        tempSeg.push({
          idEmpleado: tempUserD._id,
          tipo: dataF3.contacto,
          fecha: `${dataF3.fecha} ${dataF3.hora} GMT`,
          lugar: dataF3.lugar,
          comentarios: dataF3.comentarios || undefined,
        });
      }
    }
    const dataProps = {
      nombre: dataForm1.nombre || '',
      apellidoPaterno: dataForm1.apellidoP || '',
      apellidoMaterno: dataForm1.apellidoM || '',
      genero: dataForm1.gender || '',
      estado_civil: dataForm1.estado_civil || '',
      edad: dataForm1.edad || 0,
      email: dataForm1.email || '',
      telefono: dataForm1.phone || '',
      direccion: dataForm2.calle || '',
      colonia: dataForm2.colonia || '',
      ciudad: dataForm2.municipio || '',
      estado: dataForm2.estado || '',
      cp: dataForm2.zipCode || 0,
      origen: 'WEBAPP',
      interes: dataF3.interes || '',
      seguimiento: tempSeg,
      empleados: {
        idEmpleado: tempUserD._id,
      }
    };
    if (prospectId) {
      updateProspectData(dataProps);
    } else {
      setStatus('loading');
      saveData(dataProps);
    }
  };

  const updateProspectData = async (data) => {
    setStatus('loading');
    try {
      const dataToUpdate = {
        ...data,
        empleados: undefined,
        apellidoMaterno: data.apellidoMaterno || undefined,
        seguimiento: [...seguimiento, ...data.seguimiento],
      };
      const res = await updateProspectsById(prospectId, dataToUpdate);
      if (res.errors) {
        throw res.errors;
      } else {
        setStatus('success');
        queryClient.invalidateQueries(['prospects', 'all']);
      }
    } catch (err) {
      setStatus('error');
    }
  };

  const getInitialProspectData = async () => {
    const [prospect] = await getProspectsById(prospectId);
    if (prospect) {
      setDataForm1({
        ...dataForm1,
        nombre: prospect.nombre,
        phone: prospect?.telefono || '',
        email: prospect?.email || ''
      });
      setSeguimiento(prospect.seguimiento.map((obj) => ({ ...obj, _id: undefined })));
      setStatus('idle');
    }
  };

  useEffect(() => {
    if (!prospectId) {
      return;
    }
    getInitialProspectData();
  }, [prospectId]);

  if (status !== 'idle') {
    return (
      <div className="message-container">
        {status === 'success' && (
          <Message
            type="success"
            title="Cambios guardados"
            content="El prospecto se ha actualizado exitosamente."
            onConfirm={closeForm}
            confirmText="Salir"
          />
        )}
        {status === 'error' && (
          <Message
            type="error"
            title="Ha ocurrido un error"
            content="Vuelve a intentarlo más tarde."
            onConfirm={() => setStatus('idle')}
            confirmText="Volver"
          />
        )}
        {status === 'loading' && (
          <div className="col-12 align-items-center">
            <Message
              type="loading"
              title="Cargando..."
              content="Esta operación podría demorar algunos minutos. Por favor, espere."
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="row justify-content-center align-items-center m-t-xxxl">
        <div className="col-12 col-md-12">
          <div className="row">
            <div className="col-12 col-md-3">
              <i className="step-prosp-icon">&#59654;</i>
              <label className="step-prosp small-bold">Productos</label>
            </div>
            <div className="col-12 col-md-3">
              <i className="step-prosp-icon">{parse(step1)}</i>
              <label className="step-prosp small-bold">Datos personales</label>
            </div>
            <div className={`col-12 col-md-3 ${stateStep2}`}>
              <i className="step-prosp-icon">{parse(step2)}</i>
              <label className="step-prosp small-bold">Domicilio</label>
            </div>
            <div className={`col-12 col-md-3 ${stateStep3}`}>
              <i className="step-prosp-icon">{parse(step3)}</i>
              <label className="step-prosp small-bold">Interés</label>
            </div>
          </div>
          <div className="col-12">
            {stateStep1 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Datos personales</h2>
                </div>
                <AddProspStep1
                  setDataForm={setDataForm1}
                  nextStep={nextStep}
                  defVal={dataForm1}
                />
              </div>
            )}
            {stateStep2 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Domicilio</h2>
                </div>
                <AddProspStep2
                  setDataForm={setDataForm2}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  defVal={dataForm2}
                />
              </div>
            )}
            {stateStep3 === 'active' && (
              <div className="row">
                <div className="col-12 col-md-6 margin-form-title">
                  <h2>Interés</h2>
                </div>
                <AddProspStep4
                  setDataForm={setDataForm3}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  defVal={dataForm3}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col col-md-2"> </div>
      </div>
      <div className="line-spacing" />
      <div className="line-spacing" />
    </div>
  );
};

export default AddProspectsC;
