import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useCouponForm } from '../../formik/couponForm';
import { updateCoupon } from '../../services';

const EditCouponForm = ({ currentData, onClose }) => {
  const [submitted, setSubmitted] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const {
    values,
    isValid,
    handleChange,
    errors,
    setFieldValue,
  } = useCouponForm(currentData);

  const {
    codigo,
    descuento,
    minimo_compra,
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    try {
      setSubmitted(true);
      setSubmitting(true);

      const updateData = {
        codigo,
        descuento,
        minimo_compra
      };

      const res = await updateCoupon(currentData._id, updateData);

      if (!res.errors) {
        toast('Cupón actualizado.', {
          className: 'message',
        });
        queryClient.invalidateQueries(['coupons', 'all']);
        onClose();
        setSubmitted(false);
      } else {
        throw res.errors;
      }
    } catch (err) {
      if (err.message.includes('duplicate key error')) {
        toast('El código del cupón es duplicado.', {
          className: 'message error',
        });
      } else {
        toast('No se pudo actualizar el cupón, intente de nuevo mas tarde.', {
          className: 'message error',
        });
      }
    }
  };

  const changeNumValue = (field, value) => {
    setFieldValue(field, value ? parseFloat(value) : '');
  };

  return (
    <div className="row align-items-center justify-content-center">
      <div className="col-12 col-lg-8 col-xl-6 ">
        <div className="content-buttom">&nbsp;</div>
        <div className="d-flex justify-content-between">
          <h2>Editar cupón</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Código
                  <input
                    onChange={handleChange('codigo')}
                    value={codigo}
                    id="codigo"
                    type="text"
                    maxLength="10"
                    placeholder="Máximo 10 caracteres"
                    className={`input ${submitted && errors.codigo && 'has-error'}`}
                  />
                  {submitted && errors.codigo && (
                    <small className="caption-error">
                      {errors.codigo}
                    </small>
                  )}
                  <small className="d-block">
                    {codigo.length}
                    {' '}
                    / 10
                  </small>
                </label>
              </div>
              <div className="col-12 col-md-6" />

              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Valor
                  <CurrencyInput
                    id="descuento"
                    name="descuento"
                    placeholder="0"
                    className={`input ${submitted && errors.descuento && 'has-error'}`}
                    defaultValue={0}
                    decimalsLimit={2}
                    value={descuento}
                    prefix="$"
                    pattern="[$]+[0-9]+([\.,][0-9]+)?"
                    onValueChange={(val) => changeNumValue('descuento', val)}
                  />
                  {submitted && errors.descuento && (
                    <small className="caption-error">
                      {errors.descuento}
                    </small>
                  )}
                </label>
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="label mt-m">
                  Mínimo de compra
                  <CurrencyInput
                    id="minimoCompra"
                    name="minimoCompra"
                    placeholder="0"
                    className={`input ${submitted && errors.minimo_compra && 'has-error'}`}
                    defaultValue={0}
                    decimalsLimit={2}
                    value={minimo_compra}
                    prefix="$"
                    pattern="[$]+[0-9]+([\.,][0-9]+)?"
                    onValueChange={(val) => changeNumValue('minimo_compra', val)}
                  />
                  {submitted && errors.minimo_compra && (
                    <small className="caption-error">
                      {errors.minimo_compra}
                    </small>
                  )}
                </label>
              </div>

            </div>
            <div className="row mt-m">
              <div className="col-12">
                <button
                  type="button"
                  className="button-secondary p-r m-btn-prof"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  className="button"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EditCouponForm;
