/* eslint-disable no-unused-vars */
import Styles from './custom.module.css';

const CheckBox = ({
  status, eventC, nameC, shape, disabled
}) => {
  const onClickCheck = () => {
    eventC();
  };
  return (
    <div className={Styles.c_checkbox}>
      <label className={`${Styles.l_checkbox} ${Styles.small} ${shape === 'circle' ? Styles.rounded : ''}`}>
        <input
          className={`${Styles.i_checkbox} ${status ? Styles.checked : ''}`}
          type="checkbox"
          name={nameC}
          id={nameC}
          defaultValue={status}
          onChange={onClickCheck}
          disabled={disabled}
        />
      </label>
    </div>
  );
};

export default CheckBox;
