import '../menu.css';

const NoNotifications = () => (
  <div className="content-prof-notif no-notif">
    <div className="container">
      <div className="row">
        <div className="col-12 content-no-notif">
          <div>
            <i>&#59684;</i>
          </div>
        </div>
        <div className="col-12 content-no-notif">
          <div>
            <label className="title">Sin notificaciones por el momento</label>
          </div>
        </div>
        <div className="col-12 content-no-notif">
          <div>
            <label className="message notif-bg-transp ">
              Aquí podrás visualizar las notificaciones de tu cuenta.
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NoNotifications;
