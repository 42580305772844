/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { getDescuentoSearch } from '../../servives';

const CuponFormComp = ({
  comeBack, eventMessage, infoSale, cuponInfo
}) => {
  const [submitted1, setSubmitted1] = useState(false);
  const [cuponF, setCuponF] = useState('');
  const [errorCuponF, setErrorCuponF] = useState(null);

  const searchCupon = async () => {
    const cuponValue = cuponF ? `codigo=${encodeURIComponent(cuponF).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    const query = `${cuponValue}`;
    await getDescuentoSearch(query).then((data) => {
      if (data && data[0] && data[0]._id) {
        if (data[0].minimo_compra < infoSale) {
          cuponInfo({ _id: data[0]._id, descuento: data[0].descuento, cupon: cuponF });
          eventMessage('El cupón ha sido aplicado.');
          // comeBack();
        } else {
          eventMessage('El cupón no es aplicable a esta compra.');
        }
      } else {
        eventMessage('El cupón no es valido.');
      }
    });
  };

  return (
    <div className="col-12">
      <div className="col-12 spacing-nav"> </div>
      <div className="container-fluid">
        <div className="row content-nav-padd">
          <div className="col-12">
            <h2>Cupón de descuento</h2>
            <br />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 ">
                <label className="label labels mt-m">
                  <input
                    id="cuponF"
                    type="text"
                    className={`input ${submitted1 && errorCuponF && 'has-error'}`}
                    value={cuponF}
                    placeholder="Ingresar cupón"
                    onChange={(e) => setCuponF(e.target.value)}
                  />
                  {submitted1 && errorCuponF && (
                    <small className="caption-error">
                      {errorCuponF}
                    </small>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="col-12 p-r cont-btn">
            <button className="button navR-content-btn" onClick={() => searchCupon()} type="button" disabled={cuponF === '' ? 'disabled' : ''}>
              Aplicar
            </button>
          </div>
          <div className="col-12 spacing-nav"> </div>
        </div>
      </div>
    </div>
  );
};

export default CuponFormComp;
