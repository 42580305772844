import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import countriesData from '../../../../../global/data/countries.json';
import { findCP_S } from '../../../services';

const isFormValid = (errors) => errors.length === 0;
/* && !errors[0].nombre
  && !errors[0].apellidoPaterno
  && !errors[0].estado
  && !errors[0].ciudad
  && !errors[0].telefono
  && !errors[0].email
  && !errors[0].cp
  && !errors[0].colonia
  && !errors[0].direccion
  && !errors[0].genero
  && !errors[1].nombre
  && !errors[1].apellidoPaterno
  && !errors[1].estado
  && !errors[1].ciudad
  && !errors[1].telefono
  && !errors[1].email
  && !errors[1].cp
  && !errors[1].colonia
  && !errors[1].direccion
  && !errors[1].genero; */

const Referencias = ({
  cambiarOpcionUno, formik, handleSubmitForm, dataC
}) => {
  const {
    values,
    handleChange,
    errors: { referencias: errors = [] },
    setFieldValue
  } = formik;
  const [localidades, setLocalidades] = useState([]);
  const [localidadesRef2, setLocalidadesRef2] = useState([]);
  const [switchC, setSwitchC] = useState(false);
  const [switchC2, setSwitchC2] = useState(false);
  const [colonies0, setColonies0] = useState(null);
  const [colonies1, setColonies1] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    referencias
  } = values;

  const [submitted, setSubmitted] = useState(false);

  useEffect(
    () => {
      if (referencias[0].estado) {
        const selectedEntity = countriesData.find(
          (obj) => obj.states === referencias[0].estado
        );
        setLocalidades(selectedEntity.loc);
      } else {
        setLocalidades([]);
        formik.setFieldValue('referencias[0].ciudad', '');
      }
    },
    [referencias[0].estado]
  );

  useEffect(
    () => {
      if (referencias[1].estado) {
        const selectedEntity = countriesData.find(
          (obj) => obj.states === referencias[1].estado
        );
        setLocalidadesRef2(selectedEntity.loc);
      } else {
        setLocalidadesRef2([]);
        formik.setFieldValue('referencias[1].ciudad', '');
      }
    },
    [referencias[1].estado]
  );

  const handleSubmitStep = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isFormValid(errors)) {
      handleSubmitForm();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const getCPService0 = async () => {
    const cpS = `cp=${referencias[0].cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies0(null);
            setFieldValue('referencias[0].colonia', item.asentamiento);
            setFieldValue('referencias[0].ciudad', item.municipio);
            setFieldValue('referencias[0].estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('referencias[0].ciudad', item.municipio);
            setFieldValue('referencias[0].estado', item.estado);
          });
          setColonies0(tempCol);
        } else {
          setColonies0(null);
          setFieldValue('referencias[0].colonia', '');
          setFieldValue('referencias[0].ciudad', '');
          setFieldValue('referencias[0].estado', '');
        }
      }
    });
  };
  const getCPService1 = async () => {
    const cpS = `cp=${referencias[1].cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies0(null);
            setFieldValue('referencias[1].colonia', item.asentamiento);
            setFieldValue('referencias[1].ciudad', item.municipio);
            setFieldValue('referencias[1].estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('referencias[1].ciudad', item.municipio);
            setFieldValue('referencias[1].estado', item.estado);
          });
          setColonies1(tempCol);
        } else {
          setColonies0(null);
          setFieldValue('referencias[1].colonia', '');
          setFieldValue('referencias[1].ciudad', '');
          setFieldValue('referencias[1].estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (referencias[0].cp.toString() && referencias[0].cp.toString().length > 4) {
      getCPService0();
    } else if (referencias[0].cp.toString() && referencias[0].cp.toString().length < 5) {
      setColonies0(null);
      setFieldValue('referencias[0].colonia', '');
      setFieldValue('referencias[0].ciudad', '');
      setFieldValue('referencias[0].estado', '');
    }
  }, [referencias[0].cp]);
  useEffect(() => {
    if (referencias[1].cp.toString() && referencias[1].cp.toString().length > 4) {
      getCPService1();
    } else if (referencias[1].cp.toString() && referencias[1].cp.toString().length < 5) {
      setColonies1(null);
      setFieldValue('referencias[1].colonia', '');
      setFieldValue('referencias[1].ciudad', '');
      setFieldValue('referencias[1].estado', '');
    }
  }, [referencias[1].cp]);

  useEffect(() => {
    if (switchC && dataC) {
      setFieldValue('referencias[0].estado', dataC.estado);
      setFieldValue('referencias[0].ciudad', dataC.ciudad);
      setFieldValue('referencias[0].direccion', dataC.direccion);
      setFieldValue('referencias[0].colonia', dataC.colonia);
      setFieldValue('referencias[0].cp', dataC.cp);
      setColonies1(null);
    } else if (
      referencias[0]
      && referencias[0]
      && referencias[0].nombre
    ) {
      setFieldValue('referencias[0].ciudad', referencias[0].ciudad);
      setFieldValue('referencias[0].direccion', referencias[0].direccion);
      setFieldValue('referencias[0].colonia', referencias[0].colonia);
      setFieldValue('referencias[0].estado', referencias[0].estado);
      setFieldValue('referencias[0].cp', referencias[0].cp);
    } else {
      setFieldValue('referencias[0].ciudad', '');
      setFieldValue('referencias[0].direccion', '');
      setFieldValue('referencias[0].colonia', '');
      setFieldValue('referencias[0].estado', '');
      setFieldValue('referencias[0].cp', '');
    }
  }, [switchC]);
  useEffect(() => {
    if (switchC2 && dataC) {
      setFieldValue('referencias[1].estado', dataC.estado);
      setFieldValue('referencias[1].ciudad', dataC.ciudad);
      setFieldValue('referencias[1].direccion', dataC.direccion);
      setFieldValue('referencias[1].colonia', dataC.colonia);
      setFieldValue('referencias[1].cp', dataC.cp);
      setColonies1(null);
    } else if (
      referencias[1]
      && referencias[1]
      && referencias[1].nombre
    ) {
      setFieldValue('referencias[1].ciudad', referencias[1].ciudad);
      setFieldValue('referencias[1].direccion', referencias[1].direccion);
      setFieldValue('referencias[1].colonia', referencias[1].colonia);
      setFieldValue('referencias[1].estado', referencias[1].estado);
      setFieldValue('referencias[1].cp', referencias[1].cp);
    } else {
      setFieldValue('referencias[1].ciudad', '');
      setFieldValue('referencias[1].direccion', '');
      setFieldValue('referencias[1].colonia', '');
      setFieldValue('referencias[1].estado', '');
      setFieldValue('referencias[1].cp', '');
    }
  }, [switchC2]);

  return (
    <div className="container">
      <div>
        <form onSubmit={handleSubmitStep}>
          <div className="mt-xxl">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <h2>Referencia de contacto principal</h2>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Nombre(s)
                    <input
                      onChange={handleChange('referencias[0].nombre')}
                      value={referencias[0].nombre}
                      id="nombre"
                      type="text"
                      className={`input ${submitted && errors[0] && errors[0].nombre && 'has-error'}`}
                    />
                    {submitted && errors[0] && errors[0].nombre && <small className="caption-error">{errors[0].nombre}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoPaterno" className="label mt-m">
                    Primer apellido
                    <input
                      id="apellidoPaterno"
                      type="text"
                      className={`input ${submitted && errors[0] && errors[0].apellidoPaterno && 'has-error'}`}
                      onChange={handleChange('referencias[0].apellidoPaterno')}
                      value={referencias[0].apellidoPaterno}
                    />
                    {submitted && errors[0] && errors[0].apellidoPaterno && (
                      <small className="caption-error">{errors[0].apellidoPaterno}</small>
                    )}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoMaterno" className="label mt-m">
                    Segundo apellido
                    <input
                      id="apellidoMaterno"
                      type="text"
                      className="input"
                      onChange={handleChange('referencias[0].apellidoMaterno')}
                      value={referencias[0].apellidoMaterno}
                    />
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="telefono" className="label mt-m">
                    Teléfono
                    <input
                      id="telefono"
                      type="number"
                      placeholder="10 dígitos"
                      className={`input ${submitted && errors[0] && errors[0].telefono && 'has-error'}`}
                      onChange={handleChange('referencias[0].telefono')}
                      value={referencias[0].telefono}
                    />
                    {submitted && errors[0] && errors[0].telefono && <small className="caption-error">{errors[0].telefono}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="email" className="label mt-m">
                    Correo electrónico
                    <input
                      id="email"
                      type="email"
                      className={`input ${submitted && errors[0] && errors[0].email && 'has-error'}`}
                      onChange={handleChange('referencias[0].email')}
                      value={referencias[0].email}
                    />
                    {submitted && errors[0] && errors[0].email && <small className="caption-error">{errors[0].email}</small>}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="row prospect-message-cont">
                    <div className="col-10 col-md-11 ">
                      <p>Mi referencia tiene la misma dirección que yo</p>
                    </div>
                    <div className="col-2 col-md-1 ">
                      <Switch
                        checked={switchC}
                        onChange={() => setSwitchC(!switchC)}
                        onColor="#000000"
                        onHandleColor="#FFFFFF"
                        offColor="#CFCFCF"
                        offHandleColor="#FFFFFF"
                        borderRadius={60}
                        handleDiameter={5}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={25}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="sample" className="label mt-m ">
                    Código postal
                    <input
                      id="sample"
                      type="number"
                      maxLength="5"
                      pattern="[0-9]{X,5}"
                      className={`input ${submitted && errors[0] && errors[0].cp && 'has-error'}`}
                      onChange={handleChange('referencias[0].cp')}
                      value={referencias[0].cp}
                    />
                    {submitted && errors[0] && errors[0].cp && <small className="caption-error">{errors[0].cp}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="estado" className="label mt-m">
                    Estado
                    <select
                      type="select"
                      name="estado"
                      className={`${submitted && errors[0] && errors[0].estado && 'has-error'}`}
                      value={referencias[0].estado}
                      onChange={handleChange('referencias[0].estado')}
                    >
                      <option selected value="">
                        Estado
                      </option>
                      {countriesData.map(({ states }) => (
                        <option key={states} name={states} value={states}>
                          {states}
                        </option>
                      ))}
                    </select>
                    {submitted && errors[0] && errors[0].estado && <small className="caption-error">{errors[0].estado}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="ciudad" className="label mt-m">
                    Localidad o Municipio
                    <select
                      type="select"
                      name="ciudad"
                      className={`${submitted && errors[0] && errors[0].ciudad && 'has-error'}`}
                      value={referencias[0].ciudad}
                      onChange={handleChange('referencias[0].ciudad')}
                    >
                      <option selected value="">
                        Localidad
                      </option>
                      {localidades.map((loc) => (
                        <option key={loc} name={loc} value={loc}>
                          {loc}
                        </option>
                      ))}
                    </select>
                    {submitted && errors[0] && errors[0].ciudad && <small className="caption-error">{errors[0].ciudad}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="colonia" className="label mt-m ">
                    Colonia
                    {!colonies0 && (
                      <input
                        id="colonia"
                        type="text"
                        className={`input ${submitted && errors[0] && errors[0].colonia && 'has-error'}`}
                        value={referencias[0].colonia}
                        onChange={handleChange('referencias[0].colonia')}
                      />
                    )}
                    {colonies0 && (
                      <select
                        type="select"
                        name="colonia"
                        className={`${submitted && errors[0].colonia && 'has-error'}`}
                        value={referencias[0].colonia}
                        onChange={handleChange('referencias[0].colonia')}
                      >
                        <option value="">-Seleccione la Colonia-</option>
                        {colonies0.map((_colonia) => (
                          <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                            {_colonia}
                          </option>
                        ))}
                      </select>
                    )}
                    {submitted && errors[0] && errors[0].colonia && <small className="caption-error">{errors[0].colonia}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="direccion" className="label mt-m ">
                    Calle
                    <input
                      id="direccion"
                      type="text"
                      className={`input ${submitted && errors[0] && errors[0].direccion && 'has-error'}`}
                      value={referencias[0].direccion}
                      onChange={handleChange('referencias[0].direccion')}
                    />
                    {submitted && errors[0] && errors[0].direccion && <small className="caption-error">{errors[0].direccion}</small>}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <h2>Referencia de contacto secundaria</h2>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="nombre" className="label mt-m">
                    Nombre(s)
                    <input
                      onChange={handleChange('referencias[1].nombre')}
                      value={referencias[1].nombre}
                      id="nombre"
                      type="text"
                      className={`input ${submitted && errors[1] && errors[1].nombre && 'has-error'}`}
                    />
                    {submitted && errors[1] && errors[1].nombre && <small className="caption-error">{errors[1].nombre}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoPaterno" className="label mt-m">
                    Primer apellido
                    <input
                      id="apellidoPaterno"
                      type="text"
                      className={`input ${submitted && errors[1] && errors[1].apellidoPaterno && 'has-error'}`}
                      onChange={handleChange('referencias[1].apellidoPaterno')}
                      value={referencias[1].apellidoPaterno}
                    />
                    {submitted && errors[1] && errors[1].apellidoPaterno && (
                      <small className="caption-error">{errors[1].apellidoPaterno}</small>
                    )}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="apellidoMaterno" className="label mt-m">
                    Segundo apellido
                    <input
                      id="apellidoMaterno"
                      type="text"
                      className="input"
                      onChange={handleChange('referencias[1].apellidoMaterno')}
                      value={referencias[1].apellidoMaterno}
                    />
                    <label htmlFor="sample" className="label font-sm ">
                      (Opcional)
                    </label>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="telefono" className="label mt-m">
                    Teléfono
                    <input
                      id="telefono"
                      type="number"
                      placeholder="10 dígitos"
                      className={`input ${submitted && errors[1] && errors[1].telefono && 'has-error'}`}
                      onChange={handleChange('referencias[1].telefono')}
                      value={referencias[1].telefono}
                    />
                    {submitted && errors[1] && errors[1].telefono && <small className="caption-error">{errors[1].telefono}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="email" className="label mt-m">
                    Correo electrónico
                    <input
                      id="email"
                      type="email"
                      className={`input ${submitted && errors[1] && errors[1].email && 'has-error'}`}
                      onChange={handleChange('referencias[1].email')}
                      value={referencias[1].email}
                    />
                    {submitted && errors[1] && errors[1].email && <small className="caption-error">{errors[1].email}</small>}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-xxl">
            <h2>Domicilio</h2>
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="row prospect-message-cont">
                    <div className="col-10 col-md-11 ">
                      <p>Mi referencia tiene la misma dirección que yo</p>
                    </div>
                    <div className="col-2 col-md-1 ">
                      <Switch
                        checked={switchC2}
                        onChange={() => setSwitchC2(!switchC2)}
                        onColor="#000000"
                        onHandleColor="#FFFFFF"
                        offColor="#CFCFCF"
                        offHandleColor="#FFFFFF"
                        borderRadius={60}
                        handleDiameter={5}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={25}
                        className="react-switch"
                        id="material-switch"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="cp" className="label mt-m ">
                    Código postal
                    <input
                      id="cp"
                      type="number"
                      maxLength="5"
                      pattern="[0-9]{X,5}"
                      className={`input ${submitted && errors[1] && errors[1].cp && 'has-error'}`}
                      onChange={handleChange('referencias[1].cp')}
                      value={referencias[1].cp}
                    />
                    {submitted && errors[1] && errors[1].cp && <small className="caption-error">{errors[1].cp}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="estado" className="label mt-m">
                    Estado
                    <select
                      type="select"
                      name="estado"
                      className={`${submitted && errors[1] && errors[1].estado && 'has-error'}`}
                      value={referencias[1].estado}
                      onChange={handleChange('referencias[1].estado')}
                    >
                      <option selected value="">
                        Estado
                      </option>
                      {countriesData.map(({ states }) => (
                        <option key={states} name={states} value={states}>
                          {states}
                        </option>
                      ))}
                    </select>
                    {submitted && errors[1] && errors[1].estado && <small className="caption-error">{errors[1].estado}</small>}
                  </label>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="ciudad" className="label mt-m">
                    Localidad o Municipio
                    <select
                      type="select"
                      name="ciudad"
                      className={`${submitted && errors[1] && errors[1].ciudad && 'has-error'}`}
                      value={referencias[1].ciudad}
                      onChange={handleChange('referencias[1].ciudad')}
                    >
                      <option selected value="">
                        Localidad
                      </option>
                      {localidadesRef2.map((loc) => (
                        <option key={loc} name={loc} value={loc}>
                          {loc}
                        </option>
                      ))}
                    </select>
                    {submitted && errors[1] && errors[1].ciudad && <small className="caption-error">{errors[1].ciudad}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="colonia" className="label mt-m ">
                    Colonia
                    {!colonies1 && (
                      <input
                        id="colonia"
                        type="text"
                        className={`input ${submitted && errors[1] && errors[1].colonia && 'has-error'}`}
                        value={referencias[1].colonia}
                        onChange={handleChange('referencias[1].colonia')}
                      />
                    )}
                    {colonies1 && (
                      <select
                        type="select"
                        name="colonia"
                        className={`${submitted && errors[1].colonia && 'has-error'}`}
                        value={referencias[1].colonia}
                        onChange={handleChange('referencias[1].colonia')}
                      >
                        <option value="">-Seleccione la Colonia-</option>
                        {colonies1.map((_colonia) => (
                          <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                            {_colonia}
                          </option>
                        ))}
                      </select>
                    )}
                    {submitted && errors[1] && errors[1].colonia && <small className="caption-error">{errors[1].colonia}</small>}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="direccion" className="label mt-m ">
                    Calle
                    <input
                      id="direccion"
                      type="text"
                      className={`input ${submitted && errors[1] && errors[1].direccion && 'has-error'}`}
                      value={referencias[1].direccion}
                      onChange={handleChange('referencias[1].direccion')}
                    />
                    {submitted && errors[1] && errors[1].direccion && <small className="caption-error">{errors[1].direccion}</small>}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-m">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
              <button className="input-label-float-right button-tertiary" onClick={() => cambiarOpcionUno()}>
                Regresar
              </button>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
              <button className="button" type="submit">
                Siguiente paso
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};

export default Referencias;
