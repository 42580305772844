import React from 'react';
import { monthDayYearFormat } from '../../../../helpers/dates';
import OptionsMenu from '../../../shared/optionsMenu/OptionsMenu';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';

const DiscountsTable = ({
  data, onClickDetail, onClickApplicable
}) => {
  const setTag = (status, expires, startDate = '', expirationDate = '') => {
    switch (status) {
      case 'Activo':
        return (
          <>
            <div className="tag success">
              <span className="tag-text">Activo</span>
            </div>
            {expires ? (
              <small>
                Expira en&nbsp;
                <span>
                  {monthDayYearFormat(expirationDate)}
                </span>
              </small>
            ) : <small>No expira</small>}
          </>
        );

      case 'Vencido':
        return (
          <>
            <div className="tag expired">
              <span className="tag-text">Vencido</span>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="tag primary">
              <span className="tag-text">Programado</span>
            </div>
            <small>
              Comienza en&nbsp;
              <span>
                {monthDayYearFormat(startDate)}
              </span>
            </small>
          </>
        );
    }
  };

  const allowViewDetails = checkPermisionRoute('Descuentos', 'consultarAplicables');

  return (
    <div className="m-t-l">
      <div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <small className="text-title-tab">CÓDIGO</small>
              </div>
              <div className="col-6 col-lg-2">
                <small className="text-title-tab">USOS</small>
              </div>
              <div className="col-9 col-lg-3">
                <small className="text-title-tab">ESTATUS</small>
              </div>
              <div className="col-1" />
            </div>
          </div>
        </div>
        {data.map((discount) => (
          <div className="row row-hover" key={discount._id}>
            <div className="col-12">
              <div className="row" onClick={() => {}}>
                <div className="col-6 column-centered">
                  <div>
                    <p className="text-bold mb-1">{discount.nombre}</p>
                    <span className="text-secondary">
                      {discount.porcentaje}
                      %
                    </span>
                  </div>
                </div>
                <div className="col-6 col-lg-2 column-centered">
                  <p>
                    {discount.productosAplicados || 0}
                  </p>
                </div>
                <div className="col-9 col-lg-3">
                  <div>
                    {setTag(
                      discount.estatus,
                      discount.expira,
                      discount.fecha_inicio,
                      discount.fecha_expiracion
                    )}
                  </div>
                </div>
                <div className="col-3 col-lg-1">
                  {allowViewDetails && (
                    <div className="hidden-col">
                      <OptionsMenu>
                        <OptionsMenu.Item
                          icon="&#59698;"
                          text="Ver detalles"
                          onClick={() => onClickDetail(discount)}
                        />
                        <OptionsMenu.Item
                          icon="&#59705;"
                          text="Ver aplicables"
                          onClick={() => onClickApplicable(discount)}
                        />
                      </OptionsMenu>
                    </div>
                  )}
                </div>
              </div>
              <hr className="line-spacing" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiscountsTable;
