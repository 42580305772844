import { useQuery } from 'react-query';
import { getCoupons, getDiscounts } from '../services';

export const useCouponsPerPage = ({
  page = 1,
  perPage = 10,
  search,
  valor,
  onSuccess,
  onError,
}) => useQuery(
  ['coupons', 'all', {
    page, search, valor,
  }],
  () => {
    const nombre = search ? `${encodeURIComponent(search).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    return getCoupons({
      paginas: perPage,
      paginacion: page,
      codigo: nombre || undefined,
      valor,
    });
  },
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);

export const useDiscountsPerPage = ({
  page = 1,
  perPage = 10,
  search,
  valor,
  onSuccess,
  onError,
}) => useQuery(
  ['discounts', 'all', {
    page, search, valor
  }],
  () => {
    const nombre = search ? `${encodeURIComponent(search).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    return getDiscounts({
      paginas: perPage,
      paginacion: page,
      nombre: nombre || undefined,
      valor,
    });
  },
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);
