import './asigpro.css';

const NavProdPros = ({ options, activeOp, event }) => {
  const checkNav = (item, active) => {
    if (active === item) {
      return 'margin-navprodpros active';
    }
    return 'margin-navprodpros';
  };

  return (
    <div className="container">
      <div className="row row-nav-prodpros">
        {
          options && options.map((item) => (
            <div key={(item)} className="option-nav-prodpros">
              <button
                type="button"
                className={checkNav(item, activeOp)}
                onClick={() => event(item)}
              >
                {item}
              </button>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default NavProdPros;
