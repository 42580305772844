import { useFormik } from 'formik';
import * as Yup from 'yup';

const telefonoRegExp = /^[0-9\- ]{10,10}$/;

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    direccion: '',
    colonia: '',
    ciudad: '',
    estado: '',
    cp: '',
    fecha_creacion: '',
    url_map: '',
    telefono: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    direccion: Yup.string()
      .required('La direccion es requerida'),
    colonia: Yup.string()
      .required('La colonia es requerida'),
    ciudad: Yup.string()
      .required('La ciudad es requerida'),
    estado: Yup.string()
      .required('El estado es requerido'),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    fecha_creacion: Yup.string(),
    url_map: Yup.string(),
    telefono: Yup.string()
      .matches(telefonoRegExp, 'El Teléfono es invalido')
      .required('El nombre es requerido'),
  })
});
