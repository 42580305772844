import React, { useState } from 'react';
import { monthDayYearFormat } from '../../../../helpers/dates';
import { checkPermisionRoute } from '../../../shared/routeGuards/checkComponentPermission';
import EditDiscountForm from '../editDiscount/EditDiscountForm';
import EditDiscountDatesForm from '../editDiscount/EditDiscountDatesForm';
import ProfileDefComponent from '../../../shared/profileDef';

const DiscountDetailsModal = ({ data, onClose }) => {
  const [isEditingItem, setEditingItem] = useState(false);
  const [isEditingDates, setEditingDates] = useState(false);

  const allowEditData = !['Activo', 'Vencido'].includes(data.estatus) && checkPermisionRoute('Descuentos', 'actualizarDescuento');
  const allowEditDuration = data.estatus !== 'Vencido' && checkPermisionRoute('Descuentos', 'actualizarDescuento');

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={onClose}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        {isEditingItem && (
          <EditDiscountForm
            currentData={data}
            onClose={() => setEditingItem(false)}
          />
        )}
        {isEditingDates && (
          <EditDiscountDatesForm
            currentData={data}
            onClose={() => setEditingDates(false)}
          />
        )}
        {!isEditingItem && !isEditingDates && (
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-8 col-xl-6 ">
              <div className="content-buttom">&nbsp;</div>
              <div className="d-flex justify-content-between">
                <h2>Detalles del descuento</h2>
                {allowEditData && (
                  <button
                    className="button-secondary"
                    onClick={() => setEditingItem(true)}
                  >
                    Actualizar
                    <span className="button-icon pencil-line" />
                  </button>
                )}
              </div>
              <div className="row mt-m">
                <div className="col-12 col-md-6">
                  <label className="datos-title">Título</label>
                  <span className="datos-info text-capitalize">{data.nombre}</span>
                </div>

                <div className="col-12 col-md-6">
                  <label className="datos-title">Descuento</label>
                  <span className="datos-info text-money">
                    {data.porcentaje}
                    {' '}
                    %
                  </span>
                </div>

                <hr />
                <div className="d-flex justify-content-between mt-xxl">
                  <h2>Duración</h2>
                  {allowEditDuration && (
                    <button
                      className="button-secondary"
                      onClick={() => setEditingDates(true)}
                    >
                      Actualizar
                      <span className="button-icon pencil-line" />
                    </button>
                  )}
                </div>
                <div className="row mt-m">
                  <div className="col-12 col-md-6">
                    <label className="datos-title">Comienza</label>
                    <span className="datos-info">
                      {monthDayYearFormat(data.fecha_inicio)}
                    </span>
                  </div>

                  <div className="col-12 col-md-6">
                    <label className="datos-title">Termina</label>
                    <span className="datos-info">
                      {data.expira ? <span>{monthDayYearFormat(data.fecha_expiracion)}</span> : 'Nunca (No expira)'}
                    </span>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between mt-xxl">
                      <h2>Sistema</h2>
                    </div>
                    <div className="row mt-4">
                      {data.creadoPor && (
                        <div className="col-12 col-md-6">
                          <label className="datos-title">Creado por</label>
                          <div className="d-flex align-items-center mb-5">
                            <ProfileDefComponent
                              name="Leonardo Rodríguez"
                              size=""
                              imgProf={data.creadoPor.imagen_perfil || '/assets/img/def-user.webp'}
                            />
                            <div className="ms-2">
                              <label className="datos-info text-capitalize m-0">{data.creadoPor.nombre}</label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12 col-lg-6">
                        <label className="datos-title">Fecha de creación</label>
                        <span className="datos-info">{monthDayYearFormat(data.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscountDetailsModal;
