import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { getAllSucursales } from '../../services/index';
import { FormikStep3 } from '../../formik/formikAdd';
import rolsJobsData from '../../../shared/routeGuards/rols&jobs.json';

const FrmRolEmpleado = ({
  setDataForm, nextStep, prevStep, defVal
}) => {
  const navigate = useNavigate();
  const [submitted3, setSubmitted3] = useState(false);
  const [status, setStatus] = useState('idle');

  const [rolsList, setRolsList] = useState([]);
  const [jobsList, setJobsList] = useState([]);

  const token = localStorage.getItem('token').toString();
  // eslint-disable-next-line no-unused-vars
  const [sucursales, setSucursales] = useState([]);

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
  } = FormikStep3(defVal);

  const {
    sucursal, rol, puesto
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted3(true);
    if (isValid) {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
    }
  };

  const getServiveSucursal = async () => {
    const tempS = await getAllSucursales();
    const tempSuc = [];
    if (tempS && tempS.length > 0) {
      tempS.forEach((element) => {
        tempSuc.push({
          nombre: element.nombre.toUpperCase(),
          id: element._id
        });
      });
      setSucursales(tempSuc);
    }
  };
  const capitalizeString = (stringP) => {
    const str_arr = stringP.split(' ');
    for (let i = 0; i < str_arr.length; i += 1) {
      if (str_arr[i].length > 3) {
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
      } else if (str_arr[i] === 'rh' || str_arr[i] === 'Recursos Humanos') {
        str_arr[i] = str_arr[i].toUpperCase();
      } else {
        str_arr[i] = str_arr[i].toLowerCase();
      }
    }
    return str_arr.join(' ');
  };

  useEffect(() => {
    if (isValid || dirty) {
      setStatus('idle');
    } else {
      setStatus('');
    }
  }, [values, isValid, dirty]);

  useEffect(() => {
    if (rolsJobsData) {
      const arrayRol = [];
      rolsJobsData?.forEach((item) => {
        item.rol = capitalizeString(item.rol);
        arrayRol.push(item.rol);
      });
      setRolsList(arrayRol);
    }
  }, [rolsJobsData]);

  useEffect(() => {
    // setSucursales(['test']);
    if (token) {
      getServiveSucursal(token);
    } else {
      closeSession();
    }
  }, []);

  useEffect(() => {
    if (rol && rol !== '') {
      const arrayJob = [];
      rolsJobsData?.forEach((item) => {
        if (item.rol === rol) {
          item?.puestos?.forEach((itemP) => {
            itemP.puesto = capitalizeString(itemP.puesto);
            arrayJob.push(itemP.puesto);
          });
        }
      });
      setJobsList(arrayJob);
    }
  }, [rol]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Rol de Usuario
            <select name="rol" value={rol} type="select" onChange={handleChange('rol')}>
              <option value="" label="">Seleccione una opción</option>
              {rolsList.map((items) => (
                <option key={items} name={items} value={items}>
                  {items}
                </option>
              ))}
            </select>
            {submitted3 && errors.rol && (
              <small className="caption-error">
                {errors.rol}
              </small>
            )}
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Puesto
            <select name="puesto" value={puesto} type="select" onChange={handleChange('puesto')}>
              <option value="" label="">Seleccione una opción</option>
              {jobsList.map((items) => (
                <option key={items} name={items} value={items}>
                  {items}
                </option>
              ))}
            </select>
            {submitted3 && errors.puesto && (
              <small className="caption-error">
                {errors.puesto}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="label labels mt-m">
            Sucursal
            <select name="sucursal" value={sucursal} type="select" onChange={handleChange('sucursal')}>
              <option value="" label="">Seleccione una opción</option>
              {
                sucursales && sucursales.map((item) => (
                  <option key={item.id} value={item.id} label={item.nombre}>{item.nombre}</option>
                ))
              }
            </select>
            {submitted3 && errors.sucursal && (
              <small className="caption-error">
                {errors.sucursal}
              </small>
            )}
          </label>
        </div>
      </div>
      <div className="row mt-m">
        <div className="col-12 col-md-6">
          <button className="button btn-step-prev" type="button" onClick={() => prevStep()}>
            Regresar
          </button>
          <button className="button btn-step" type="submit" disabled={status !== 'idle' ? 'disabled' : ''}>
            Continuar
          </button>
        </div>
      </div>
    </form>
  );
};
export default FrmRolEmpleado;
