import React, { useEffect, useState } from 'react';
import { useDiscountsPerPage } from './hooks/queries';
import { checkPermisionRoute } from '../shared/routeGuards/checkComponentPermission';
import './promotions.css';
import NotFoundAlert from '../shared/notFoundAlert/NotFoundAlert';
import DiscountsTable from './components/discountsTable/DiscountsTable';
import DiscountDetailsModal from './components/discountDetails/DiscountDetailsModal';
import DiscountPlanDetails from './components/discountPlanDetails/DiscountPlanDetails';
import DropdownFilter from '../shared/dropdownFilter/DropdownFilter';

const ListDiscounts = ({ setOpenForm, openUpdateForm }) => {
  const [page, setPage] = useState(1);
  const [searchInp, setSearchInp] = useState('');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [listOrder, setListOrder] = useState('normal');

  const perPage = 10;

  const { data: queryData } = useDiscountsPerPage({
    page,
    perPage,
    search: searchInp,
    valor: listOrder,
  });

  const total = queryData?.total;
  const discountsList = queryData?.data;
  const activeL = page > 1;
  const activeR = (page * perPage) <= total;
  const totalPages = Math.ceil(total / perPage);
  const searchEnabled = searchInp.length > 0;

  const handleViewDiscount = (item) => {
    setSelectedDiscount(item);
    setShowDetailModal(true);
  };

  const handleViewPlans = (item) => {
    setSelectedDiscount(item);
    setShowPlanModal(true);
  };

  const handleCloseModal = () => {
    setShowDetailModal(false);
  };

  const subsPage = () => {
    setPage(page - 1);
  };

  const addPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (selectedDiscount) {
      setSelectedDiscount(discountsList.find((item) => item._id === selectedDiscount._id));
    }
  }, [discountsList]);
  useEffect(() => {
    if (page && page > 1) {
      setPage(1);
    }
  }, [searchInp]);

  return (
    <>
      {showDetailModal && (
        <DiscountDetailsModal
          data={selectedDiscount}
          onClose={handleCloseModal}
        />
      )}
      {showPlanModal && (
        <DiscountPlanDetails
          statusOp={showPlanModal}
          data={selectedDiscount}
          onClose={() => setShowPlanModal(false)}
          openUpdateForm={() => openUpdateForm(selectedDiscount)}
        />
      )}
      <div className="row">
        <div className="col-12 col-md-6 margin-filter">
          <h2>Descuentos</h2>
        </div>
        <div className="col-12 col-md-6 btn-content-filt margin-filter">
          {checkPermisionRoute('Descuentos', 'crearDescuento') && (
            <button className="button" onClick={setOpenForm}>
              &nbsp;&nbsp;Crear&nbsp;&nbsp;
              <i className="btn-icon">&#59672;</i>
              &nbsp;&nbsp;
            </button>
          )}
        </div>
        <div className="col-12 margin-filter">
          <label>
            {total || 0}
            &nbsp;
            {total === 1 ? 'resultado' : 'resultados'}
          </label>
        </div>
        <div className="col-12 col-md-4 margin-filter">
          <div className="col-12">
            <div className="input-container">
              <input
                id="buscar"
                onChange={(e) => setSearchInp(e.target.value)}
                value={searchInp}
                type="text"
                className="input"
                placeholder="Buscar"
              />
              <span className="input-icon search-line" />
            </div>
            {/* eslint-disable quotes */}
            <div className="col-12 col-md-3" style={{ marginTop: '-58px', marginRight: '-130px', marginLeft: '290px' }}>
              <DropdownFilter
                activeValue={listOrder}
                defaultLabel="Ordenar"
                setActiveValue={setListOrder}
                options={[
                  {
                    label: "Porcentaje mayor",
                    value: "porcentajeMayor",
                  },
                  {
                    label: "Porcentaje menor",
                    value: "porcentajeMenor",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {discountsList && (
          <DiscountsTable
            data={discountsList}
            onClickDetail={handleViewDiscount}
            onClickApplicable={handleViewPlans}
          />
        )}
        {total === 0 && !searchEnabled && (
          <NotFoundAlert
            icon="&#59671;"
            title="Aún no se ha registrado ningún descuento"
            message="Aquí podrás visualizar el resumen de los descuentos que hayan sido registrados."
          />
        )}
        {total === 0 && searchEnabled && (
          <NotFoundAlert
            icon="&#59673;"
            title="No hay resultados"
            message="Lo sentimos, parece que no hemos podido encontrar ningún resultado para tu búsqueda."
          />
        )}
        {total > 0 && (
          <div className="container pag-content">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6">
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    <label className="text-pag">{page} de {totalPages}</label>
                  </div>
                  <div className="col-12 col-md-6 pag-content-btn">
                    <button
                      onClick={subsPage}
                      disabled={!activeL}
                      className={`button-pag ${activeL ? 'active' : ''} btn-pag-fotmar`}
                    >
                      <i>&#59658;</i>
                    </button>
                    <button
                      onClick={addPage}
                      disabled={!activeR}
                      className={`button-pag ${activeR ? 'active' : ''} btn-pag-fotmar`}
                    >
                      <i>&#59659;</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListDiscounts;
