import * as Yup from 'yup';

const { useFormik } = require('formik');

export const useQuotationFormik = ({
  total,
  email,
  telefono,
  envioCotizacion,
  nombre,
}) => {
  const anticipo = total >= 200 ? 200 : total;
  return useFormik({
    validateOnMount: true,
    initialValues: {
      totalAproximado: total || 0,
      tipoPago: 'Mensualidades',
      anticipo,
      mensualidad: 0,
      nombre,
      telefono,
      email,
      envioCotizacion,
    },
    validationSchema: Yup.object({
      anticipo: Yup.number()
        .required('El anticipo es requerido')
        .min(anticipo, `El anticipo no puede ser menor a ${anticipo} MXN.`)
        .max(total, 'El anticipo no puede ser mayor al total.'),
      nombre: !email ? Yup.string()
        .required('El nombre del prospecto es requerido') : Yup.string(),
      email: Yup.string()
        .email('La dirección de correo no es válida.'),
      mensualidad: Yup.string()
        .when('tipoPago', {
          is: (tipo) => tipo === 'Mensualidades',
          then: Yup.string()
            .required('Seleccione un pago a meses'),
          otherwise: Yup.string(),
        }),
    }),
  });
};
