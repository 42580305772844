import { useState, useEffect } from 'react';
import NavProdPros from '../../../../prospects/components/asignarProducto/navProdPros';
import TabProdContE from '../tabprodcontract/tabProdContE';
import TabProdContP from '../tabprodcontract/tabProdContP';
import { getProductos } from '../../../../products/services';

const AsigProdContract = ({ cambiarOpcionDos, defListProduct, idSucursal }) => {
  const [product, setProduct] = useState(null);
  const [lProductsSelect, setLProductsSelect] = useState([]);
  const [totalPro, setTotalPro] = useState(0);
  const [searchInp, setSearchInp] = useState('');
  const optionNav = ['Uso Inmediato', 'Previsión'];
  const [activeNav, setActiveNav] = useState('Uso Inmediato');
  let disp;

  const eventChangeNav = (item) => {
    setActiveNav(item);
  };
  const getProducts = async () => {
    const nombre = searchInp ? `nombre=${encodeURIComponent(searchInp).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';
    const pags = `&paginas=${100}`;
    let sucurFilt = '';
    if (idSucursal) {
      sucurFilt = `&sucursal=${idSucursal.idSucursal}`;
    }
    const query = `${nombre}${pags}&disponibilidad=${disp}${sucurFilt}`;
    await getProductos(query).then((data) => {
      if (data && data.data && data.total > 0) {
        setProduct(data.data || []);
        setTotalPro(data.total || 0);
      } else if (searchInp !== '') {
        setProduct([]);
        setTotalPro(0);
      }
    });
  };

  const addProductL = (dataP) => {
    let tempData = dataP;
    let findR = false;
    lProductsSelect.forEach((element) => {
      if (element._id === tempData._id) {
        findR = true;
      }
    });
    if (!findR) {
      const tempP = lProductsSelect;
      tempP.push(tempData);
      setLProductsSelect(tempP);
      cambiarOpcionDos(tempP);
    } else {
      const tempP = lProductsSelect;
      tempP.forEach((element, ind) => {
        if (element._id === tempData._id) {
          tempP[ind].Cantidad += tempData.Cantidad;
        }
      });
      setLProductsSelect(tempP);
      cambiarOpcionDos(tempP);
    }
  };

  useEffect(() => {
    if (activeNav === 'Uso Inmediato') {
      disp = 'inmediata';
      getProducts();
    }
    if (activeNav === 'Previsión') {
      disp = 'prevision';
      getProducts();
    }
  }, [activeNav]);

  const searchProducts = (e) => {
    setSearchInp(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (token) {
        getProducts(token);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token) {
      if (searchInp === '' || searchInp.length >= 3) {
        getProducts(token);
      }
    }
  }, [searchInp]);
  useEffect(() => {
    if (defListProduct) {
      setLProductsSelect(defListProduct);
    }
  }, [defListProduct]);
  return (
    <div className="row">
      <button className="btn-regresar" onClick={cambiarOpcionDos}><i>&#59658;</i></button>
      <div className="col-12">
        <h2>Agregar producto</h2>
        <div className="col-12">
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <label>{totalPro || 0} resultados</label>
          <div className="col-12 col-md-12">
            <label className="label mt-m">
              Plan
            </label>
            <NavProdPros options={optionNav} activeOp={activeNav} event={eventChangeNav} />
          </div>
          <br />
          <div className="col-12 col-md-12 margin-filter">
            <div className="col-12 col-md-12">
              <div className="input-container">
                <input id="buscar" onChange={(e) => searchProducts(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                <span className="input-icon search-line" />
              </div>
            </div>
          </div>
          <br />
          <br />
          {activeNav === 'Uso Inmediato' && <TabProdContE dataP={product} saveP={addProductL} />}
          {activeNav === 'Previsión' && <TabProdContP dataP={product} saveP={addProductL} />}
        </div>
      </div>
    </div>
  );
};
export default AsigProdContract;
