/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { FValidatedContract } from '../formik/formik';
import { uploadFileContract } from '../services';
import pagoDia from '../../../global/data/diaPago.json';
import CheckBox from '../../../global/custom/checkbox';

const ValidatedContract = ({
  eventV, dataC, eventShowMessage, idC, refresh
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [statusCheckbox, setStatusCheckbox] = useState(false);

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = FValidatedContract();

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (isValid && dirty && statusCheckbox) {
      setStatus('loading');
      await uploadFileContract(fileContract, 'contratos', idC).then((resp) => {
        if (resp && resp.ok) {
          eventV(false);
          refresh();
          eventShowMessage(`Contrato ${numContrato} validado`);
          // window.location.reload(false);
        } else {
          eventShowMessage('No se pudo actualizar, intente denuevo mas tarde.');
        }
      });
    }
  };

  const getFileContract = async (e, _typeFile) => {
    if (e.target.files) {
      setFieldValue('fileContract', e.target.files[0]);
    } else {
      setFieldValue('fileContract', null);
    }
  };
  const eventCheckB = () => {
    if (fileContract) {
      setStatusCheckbox(!statusCheckbox);
    }
  };

  const {
    folio,
    numContrato,
    totalSale,
    typePaymend,
    anticipo,
    saldo,
    mensualidades,
    payDate,
    fileContract,
  } = values;

  useEffect(() => {
    if (dataC) {
      setFieldValue('folio', dataC.folio);
      setFieldValue('numContrato', dataC.noContrato);
      setFieldValue('totalSale', dataC.precioVenta);
      setFieldValue('typePaymend', dataC?.formaPago?.toUpperCase() || '');
      setFieldValue('anticipo', dataC.pagoInicial);
      setFieldValue('saldo', dataC.saldo);
      setFieldValue('mensualidades', dataC.plazo);
      setFieldValue('payDate', dataC.diaPago);
    }
  }, [dataC]);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span className="icon-close cross-line" onClick={() => eventV(false)}>
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>Validar contrato</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="">
                <div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="folio" className="label mt-m">
                        Folio
                        <input
                          onChange={handleChange('folio')}
                          value={folio}
                          id="folio"
                          type="text"
                          className="input "
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="numContrato" className="label mt-m">
                        Número de contrato
                        <input
                          onChange={handleChange('numContrato')}
                          value={numContrato}
                          id="numContrato"
                          type="text"
                          className="input "
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="totalSale" className="label mt-m">
                        Total de la venta
                        <input
                          value={formatMoney.format(totalSale)}
                          id="totalSale"
                          type="text"
                          disabled
                          className="input "
                        />
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="typePaymend" className="label labels mt-m">
                        Tipo de pago
                        <input
                          value={typePaymend}
                          id="typePaymend"
                          type="text"
                          disabled
                          className="input "
                        />
                      </label>
                    </div>
                  </div>
                  {typePaymend && typePaymend !== 'CONTADO' && (
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="anticipo" className="label mt-m">
                          Anticipo
                          <CurrencyInput
                            id="anticipo"
                            name="anticipo"
                            placeholder=""
                            className="input "
                            defaultValue={200}
                            decimalsLimit={2}
                            value={anticipo}
                            prefix="$"
                            pattern="[$]+[0-9]+([\.,][0-9]+)?"
                            allowNegativeValue={false}
                            disabled
                          />
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="saldo" className="label mt-m">
                          Saldo
                          <CurrencyInput
                            id="saldo"
                            name="saldo"
                            placeholder=""
                            className="input "
                            defaultValue={200}
                            decimalsLimit={2}
                            value={saldo}
                            prefix="$"
                            pattern="[$]+[0-9]+([\.,][0-9]+)?"
                            allowNegativeValue={false}
                            disabled
                          />
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="mensualidades" className="label mt-m">
                          Mensualidades
                          <input
                            value={mensualidades}
                            id="mensualidades"
                            type="text"
                            disabled
                            className="input "
                          />
                        </label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor="payDate" className="label mt-m">
                          Fecha de pago
                          <select
                            type="payDate"
                            value={payDate}
                            disabled
                            onChange={handleChange('payDate')}
                          >
                            <option value="">Seleccione una opción</option>
                            {pagoDia.map((items) => (
                              <option key={items.value} name={items.value} value={items.value}>
                                {items.label}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="content-buttom">&nbsp;</div>
                    <div className="col-12 col-md-6 margin-filter">
                      <h2>Subir firma</h2>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="fileContract" className="label mt-m ">
                      Contrato firmado
                    </label>
                    <label htmlFor="fileContract" className="label mt-m ">
                      Los Tipo de Archivos admitidos son JPG, PNG y PDF
                      <input
                        id="fileContract"
                        className="input "
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        onChange={(e) => getFileContract(e, 'fileContract')}
                        hidden
                      />
                    </label>
                    <button
                      className="botonsubida button"
                      onClick={() => document.querySelector('#fileContract').click()}
                    >
                      Subir Archivo&nbsp;&nbsp;
                      <i className="btn-icon">&#59661;</i>
                    </button>
                    <br />
                    {fileContract && fileContract.name && (
                      <div className="subido">
                        {fileContract.name}
                        {' '}
                        <i className="btn-icon">&#59662;</i>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <br />
                    </div>
                    <div className="col-1 p-r">
                      <CheckBox status={statusCheckbox} eventC={eventCheckB} nameC="validacion" />
                    </div>
                    <div className="col-11 p-r">
                      <p>Confirmo que los datos del contrato coinciden con los datos registrados en el sistema.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="col-12 col-md-12">
                      &nbsp;
                    </div>
                    <div className="col-12 col-md-12">
                      <button type="button" className="button-secondary p-r m-btn-prof" onClick={() => eventV(false)}>
                        Cancelar
                      </button>
                      <button disabled={!statusCheckbox} className="button mt-m" type="submit">
                        Validar contrato
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default ValidatedContract;
