import { useState, useEffect } from 'react';
import NavProdPros from './navProdPros';
import TabProdProsP from '../addProdPros/tabProdProsP';
import TabProdProsE from '../addProdPros/tabProdProsE';
import { getProductos } from '../../../products/services';
import './asigpro.css';

const AsignarProd = (
  {
    eventCP,
    datap,
    eventShowMessage,
    refresh
  }
) => {
  const [product, setProduct] = useState(null);
  const [totalPro, setTotalPro] = useState(0);
  const [searchInp, setSearchInp] = useState('');
  const optionNav = ['Uso Inmediato', 'Previsión'];
  const [activeNav, setActiveNav] = useState('Uso Inmediato');
  // let disp;

  const eventChangeNav = (item) => {
    setActiveNav(item);
  };

  const getProducts = async () => {
    const nombre = searchInp ? `nombre=${encodeURIComponent(searchInp).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';
    let disp;
    if (activeNav === 'Uso Inmediato') {
      disp = 'inmediata';
    }
    if (activeNav === 'Previsión') {
      disp = 'prevision';
    }
    const query = `${nombre}&sucursal=${datap?.sucursal?._id}&disponibilidad=${disp}`;

    await getProductos(query).then((data) => {
      if (data && data.data && data.total > 0) {
        setProduct(data.data || []);
        setTotalPro(data.total || 0);
      } else if (searchInp !== '') {
        setProduct([]);
        setTotalPro(0);
      }
    });
  };

  const searchProducts = (e) => {
    setSearchInp(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (token) {
        getProducts(token);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token) {
      if (searchInp === '' || searchInp.length >= 3) {
        getProducts(token);
      }
    }
  }, [searchInp]);

  return (
    <div className="container-fluid content-full overflow-c back">
      <div className="lateral">
        <div className="row justify-content-end">
          <div className="col-2 close-form">
            <span
              className="icon-close cross-line"
              onClick={() => eventCP(false)}
            >
              {' '}
            </span>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-10 col-sm-10 col-md-10 col-lg-12 col-xl-10 ">
              <div className="content-buttom">&nbsp;</div>
              <div className="col-12 col-md-12 col-lg-8 margin-filter">
                <h2>Agregar producto</h2>
              </div>
              <div className="col-12 margin-filter">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <label>{totalPro || 0} resultados</label>
              </div>
              <div className="col-12 col-md-12">
                <label className="label mt-m">
                  Plan
                </label>
                <NavProdPros options={optionNav} activeOp={activeNav} event={eventChangeNav} />
              </div>
              <br />
              <div className="col-12 col-md-12 margin-filter">
                <div className="col-12 col-md-12">
                  <div className="input-container">
                    <input id="buscar" onChange={(e) => searchProducts(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                    <span className="input-icon search-line" />
                  </div>
                </div>
              </div>
              <br />
              <br />
              {activeNav === 'Uso Inmediato' && <TabProdProsE data={product} datap={datap} eventCP={eventCP} eventShowMessage={eventShowMessage} refresh={refresh} />}
              {activeNav === 'Previsión' && <TabProdProsP data={product} datap={datap} eventCP={eventCP} eventShowMessage={eventShowMessage} refresh={refresh} />}
              <div className="">
                <div> </div>
                <div className="row">
                  <div className="col-12">
                    <div className="col-12 col-md-12">
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsignarProd;
