/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query';
import { getClientsByPage, getContractClient } from '../services';

export const useClientsPerPage = ({
  page,
  perPage = 10,
  search,
  order,
  antiguedad,
  location,
  onSuccess,
  onError,
}) => useQuery(
  ['clients', 'all', {
    page, search, order, location, antiguedad,
  }],
  () => {
    const nombre = search ? `${encodeURIComponent(search).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    return getClientsByPage({
      filtro: nombre || undefined,
      paginacion: page,
      paginas: perPage,
      ubicacion: location || undefined,
      orden: order,
      antiguedad,
    });
  },
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  },
);

export const useClientContractsPerPage = ({
  page,
  id,
  perPage = 10,
  onSuccess,
  onError
}) => useQuery(
  ['clients', 'detail', id, 'contracts', { page }],
  () => getContractClient(id, page, perPage),
  {
    placeholderData: { pages: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
  }
);
