/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { updateProspectTrackingById } from '../../servives';

const EditObservationsForm = ({
  currentData, prospectId, onClose, refresh,
}) => {
  const [comentarios, setComentarios] = useState('');
  const [message, setMessage] = useState(false);

  const eventShowMessage = (textM) => {
    setMessage(true);
    toast(textM, {
      className: 'message'
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const trackingId = currentData._id;
      const updatedItem = {
        ...currentData,
        comentarios: comentarios || '-',
        _id: undefined,
      };
      const resp = await updateProspectTrackingById(prospectId, trackingId, updatedItem);
      if (resp && !resp.errors) {
        eventShowMessage('Seguimiento actualizado.');
        refresh();
        onClose();
      } else {
        eventShowMessage('No se pudo actualizar, intente de nuevo más tarde.');
      }
    } catch (err) {
      eventShowMessage('No se pudo actualizar, intente de nuevo más tarde.');
    }
  };

  useEffect(() => {
    if (!currentData) {
      return;
    }
    setComentarios(currentData.comentarios || '');
  }, [currentData]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-12 margin-form-title">
          <h2>
            Actualizar comentarios
          </h2>
        </div>
        <div className="col-12">
          <label htmlFor="comentarios" className="label mt-m ">
            Comentarios y observaciones
            <textarea
              onChange={(e) => setComentarios(e.target.value)}
              value={comentarios}
              id="comentarios"
              className="input sizein"
            />
          </label>
          <label htmlFor="comentarios" className="label font-sm">
            (Opcional)
          </label>
        </div>
        <div className="col-12 col-md-12">
          <button className="button btn-step-prev" type="button" onClick={() => onClose()}>
            Cancelar
          </button>
          <button className="button btn-step" type="submit">
            Guardar cambios
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditObservationsForm;
