import parse from 'html-react-parser';

export const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const styleMoney = (money) => {
  const tempM = money.split('.');
  const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
  return tempHtml;
};
