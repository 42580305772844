/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import NotAllow from '../shared/routeGuards/notAllow';
import FrmDataContract from './components/frmDataContract';
import FrmDataOwner from './components/frmDataOwner';
import ConfirmSaveView from './components/confirmSave';

import {
  getContractByID
} from './services';
import './contract.css';

const EditContractView = ({
  idContract, formulario, eventV, contractInfo, eventShowMessage, refresh,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [optionUpdate, setOptionUpdate] = useState(false);
  const navigate = useNavigate();

  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };
  /*
  const updateDataInfo = async (dataB) => {

  }; */

  const closeForm = () => { };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };

  useEffect(() => { }, []);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span className="icon-close cross-line" onClick={() => eventV(false)}>
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12">
              {formulario === 'contract-data' && (
              <FrmDataContract
                dataForm={contractInfo}
                eventV={eventV}
                openConfirmDialog={openConfirmDialog}
                setOpenConfirmDialog={setOpenConfirmDialog}
                optionUpdate={optionUpdate}
                eventShowMessage={eventShowMessage}
                refresh={refresh}
              />
              )}
              {formulario === 'contract-owner' && (
              <FrmDataOwner
                dataForm={contractInfo}
                eventV={eventV}
                openConfirmDialog={openConfirmDialog}
                setOpenConfirmDialog={setOpenConfirmDialog}
                optionUpdate={optionUpdate}
                eventShowMessage={eventShowMessage}
                refresh={refresh}
              />
              )}
              {formulario !== 'contract-owner' && formulario !== 'contract-data' && <NotAllow />}
              {openConfirmDialog && <ConfirmSaveView eventV={eventV} option={setOptionUpdate} />}
            </div>
          </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default EditContractView;
