/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { getProducts } from '../../servives';
import NavPage from '../../../navPage';
import ImageDef from '../../../products/imagedefault/imagedefault';
import proddef from '../../../../images/imageProDef.png';
import AddProspectQuotationModal from './addProspectQuotationModal';

const AddProspectMProduct = ({
  eventOp, statusOp, setData, sucId
}) => {
  const [statusWNav, setStatusWNav] = useState(false);
  const [lProducts, setLProducts] = useState({ data: [], total: 0 });
  const [showAddP, setShowAddP] = useState(false);
  const [lProductsSelect, setLProductsSelect] = useState([]);
  const [selectedP, setSelectedP] = useState(null);
  const [typeP, setTypeP] = useState('inmediata');
  const [counter, setCounter] = useState(1);
  const [updStatus, setUpdStatus] = useState(true);
  const [updStatusLP, setUpdStatusLP] = useState(true);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [productTotal, setProductTotal] = useState(0);
  const [searchInp, setSearchInp] = useState('');

  const optionNav = ['Uso Inmediato', 'Previsión'];
  const [activeNav, setActiveNav] = useState('Uso Inmediato');
  const eventChangeNav = (item) => {
    setActiveNav(item);
  };

  const searchProducts = async () => {
    const nombre = searchInp ? `nombre=${encodeURIComponent(searchInp).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    )}` : '';

    let query = `${nombre}&sucursal=${sucId._id}&disponibilidad=${typeP}`;
    await getProducts(query).then((resp) => {
      if (resp) {
        setLProducts(resp);
      } else {
        setLProducts([]);
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const token = localStorage.getItem('token').toString();
      if (token) {
        searchProducts(token);
      }
    }
  };

  const addProductL = () => {
    let tempData = selectedP;
    let findR = false;
    lProductsSelect.forEach((element) => {
      if (element._id === selectedP._id) {
        findR = true;
      }
    });
    if (!findR) {
      tempData.Cantidad = counter;
      tempData._Disponibilidad = typeP;
      const tempP = lProductsSelect;
      tempP.push(tempData);
      setLProductsSelect(tempP);
    } else {
      const tempP = lProductsSelect;
      tempP.forEach((element, ind) => {
        if (element._id === selectedP._id) {
          tempP[ind].Cantidad += counter;
        }
      });
      setLProductsSelect(tempP);
    }

    setShowAddP(false);
    setCounter(1);
    setSelectedP(null);
  };
  const nextStep = () => {
    setShowQuotationModal(false);
    setData(lProductsSelect);
  };

  const changeCount = (_operation, index) => {
    setUpdStatus(false);
    let tempLP = lProductsSelect;
    if (_operation === '-') {
      if (tempLP[index].Cantidad > 1) {
        tempLP[index].Cantidad -= 1;
      }
    } else if (_operation === '+') {
      tempLP[index].Cantidad += 1;
    }
    setLProductsSelect(tempLP);
    setTimeout(() => {
      setUpdStatus(true);
    }, 10);
  };
  const deleteSelectP = (index) => {
    setUpdStatusLP(false);
    const tempLP = lProductsSelect;
    tempLP.splice(index, 1);
    setLProductsSelect(tempLP);
    setTimeout(() => {
      setUpdStatusLP(true);
    }, 10);
  };

  const countProd = (prdSelect) => {
    let counterP = 0;
    prdSelect.forEach((item) => {
      counterP += item.Cantidad;
    });
    return counterP;
  };
  const getProductPrice = (product) => {
    try {
      if (product._Disponibilidad === 'inmediata') {
        return (product?.descuentos?.usoInmediato
          ? product.descuentos.usoInmediato.descuentoUsoInmediato : product.precio);
      }
      return (product?.descuentos?.prevision
        ? product.descuentos.prevision.descuentoPrevision : product.precioPrevision);
    } catch (_error) {
      return 0;
    }
  };

  const handleOpenQuotationForm = () => {
    setProductTotal(lProductsSelect.reduce((acc, product) => {
      const newTotal = acc + (getProductPrice(product) * product.Cantidad);
      return newTotal;
    }, 0));
    setShowQuotationModal(true);
  };

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const preFormatMoney = (money) => {
    if (money) {
      return formatMoney.format(money);
    }
    return 'Sin asignar';
  };

  const styleMoney = (money) => {
    if (money && money !== 'Sin asignar') {
      const tempM = money.split('.');
      const tempHtml = parse(`${tempM[0]}<small>${tempM[1]}</small>`);
      return tempHtml;
    }
    return money;
  };

  const closeAllForms = () => {
    setShowQuotationModal(false);
    eventOp();
  };

  const searchProductsInp = (e) => {
    setSearchInp(e.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token) {
      if (searchInp === '' || searchInp.length >= 3) {
        searchProducts(token);
      }
    }
  }, [searchInp]);

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);
  useEffect(() => {
    if (sucId) {
      searchProducts();
    }
  }, [sucId, typeP]);

  useEffect(() => {
    if (activeNav === 'Uso Inmediato') {
      setTypeP('inmediata');
    } else {
      setTypeP('prevision');
    }
  }, [activeNav]);

  return (
    <>
      {showQuotationModal && (
        <AddProspectQuotationModal
          onClose={() => setShowQuotationModal(false)}
          onFinish={closeAllForms}
          total={productTotal}
          nextStep={nextStep}
          products={lProductsSelect}
          sucursal={sucId}
        />
      )}
      <div className="container-fluid navBar-right">
        <div className="row">
          <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
          <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
            <div className="container-fluid">
              <div className="row">
                <div className="col close-form">
                  <span
                    className="icon-close cross-line"
                    onClick={eventOp}
                  >
                    {' '}
                  </span>
                </div>
                {!showAddP && (
                  <div className="col-12">
                    <div className="col-12 spacing-nav"> </div>
                    <div className="container-fluid">
                      <div className="row content-nav-padd">
                        <div className="col-12">
                          <h2>Productos de interés</h2>
                          <br />
                          <small>{lProductsSelect ? `${countProd(lProductsSelect)} Productos` : '0 Productos'}</small>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 p-r ">
                          <button className="button-secondary navR-btn" type="button" onClick={() => setShowAddP(true)}>
                            Agregar producto
                          </button>
                        </div>
                        <div className="col-12 spacing-nav"> </div>
                        {lProductsSelect && lProductsSelect.length < 1 && (
                          <div className="col-12">
                            <div className="container content-center content-info smH">
                              <div className="row">
                                <div className="col-12 cont-icon-info">
                                  <label className="icon-info-product">&#59671;</label>
                                </div>
                                <div className="col-12">
                                  <label className="text-title-info">Aún no has asociado ningún producto</label>
                                </div>
                                <div className="col-12 col-md-12">
                                  <label className="text-info">Aquí podrás visualizar el resumen de los productos de interés asociados al prospecto.</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {lProductsSelect && updStatusLP && lProductsSelect.length > 0 && (
                          <div className="col-12">
                            <div className="row">
                              <div className="col col-sm-12 col-md-2"> </div>
                              <div className="col-12 col-sm-12 col-md-8">
                                {
                                  lProductsSelect.map((item, indx) => (
                                    <div className="row" key={item._id}>
                                      <div className="row row-cursor">
                                        <div className="option-tab">
                                          <div className="col-6 col-sm-6 col-md-6 img-prd-cent">
                                            <ImageDef
                                              name={item.nombre}
                                              size="lg"
                                              imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : proddef}
                                            />
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6">
                                            <label className="text-nameP">{item.nombre}</label>
                                            {
                                              item._Disponibilidad === 'inmediata'
                                                ? (
                                                  <>
                                                    {
                                                      !item?.descuentos?.usoInmediato?.descuentoUsoInmediato
                                                        ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                                        : (
                                                          <>
                                                            <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                                            <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.usoInmediato.descuentoUsoInmediato))}</label>
                                                          </>
                                                        )
                                                    }
                                                  </>
                                                )
                                                : (
                                                  <>
                                                    {
                                                      !item?.descuentos?.prevision?.descuentoPrevision
                                                        ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                                        : (
                                                          <>
                                                            <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                                            <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.prevision.descuentoPrevision))}</label>
                                                          </>
                                                        )
                                                    }
                                                  </>
                                                )
                                            }
                                            <label className="text-email left">
                                              (
                                              {`${item._Disponibilidad.toUpperCase() || '-'}` || parse('&nbsp;')}
                                              )
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row no-margin">
                                        {updStatus && (
                                          <div className="col-9">
                                            <div className="container-prd-number">
                                              <button
                                                id="decrement"
                                                className="button-number"
                                                onClick={() => changeCount('-', indx)}
                                                disabled={item.Cantidad <= 1}
                                              >
                                                -
                                              </button>
                                              <span className="input-number">
                                                {item.Cantidad}
                                              </span>
                                              <button
                                                id="increment"
                                                className="button-number"
                                                onClick={() => changeCount('+', indx)}
                                              >
                                                +
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                        <div className="col-3 f-r">
                                          <button className="button-secondary p-r f-r" onClick={() => deleteSelectP(indx)}>
                                            <i className="btn-icon">&#59697;</i>
                                          </button>
                                        </div>
                                        <div className="line-spacing" />
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                              <div className="col col-sm-12 col-md-2"> </div>
                            </div>
                          </div>
                        )}
                        <div className="col-12 p-r cont-btn">
                          <div className="row">
                            <div className="col-lg-6">
                              <button className="button-secondary navR-content-btn" onClick={handleOpenQuotationForm} type="button" disabled={lProductsSelect.length < 1 ? 'disabled' : ''}>
                                Crear cotización
                              </button>
                            </div>
                            <div className="col-lg-6">
                              <button className="button navR-content-btn" onClick={() => nextStep()} type="button" disabled={lProductsSelect.length < 1 ? 'disabled' : ''}>
                                Crear prospecto
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 spacing-nav"> </div>
                      </div>
                    </div>
                  </div>
                )}
                {showAddP && (
                  <div className="col-12">
                    <div className="col-12 spacing-nav"> </div>
                    <div className="container-fluid">
                      <div className="row content-nav-padd">
                        <div className="col-12">
                          <h2>Agregar producto</h2>
                          <br />
                          <label>{lProducts ? `${lProducts.total} Resultados` : '0 Resultados'}</label>
                          <br />
                          <br />
                        </div>
                        <div className="col-12 col-md-12">
                          <label className="label mt-m">
                            Plan
                          </label>
                          <NavPage options={optionNav} activeOp={activeNav} event={eventChangeNav} classN="nav-ma" />
                        </div>
                        <div className="col-12 spacing-nav"> </div>
                        <div className="col-12 col-md-12 margin-filter">
                          <div className="col-12 col-md-12">
                            <div className="input-container">
                              <input id="buscar" onChange={(e) => searchProductsInp(e)} value={searchInp} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Buscar" />
                              <span className="input-icon search-line" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 spacing-nav"> </div>
                        <div className="col-12 col-sm-12 col-xl-9">
                          {
                            lProducts && lProducts.data.map((item) => (
                              <div className="row" key={item._id} onClick={() => setSelectedP(item)}>
                                <div className="row row-cursor">
                                  <div className={selectedP && selectedP._id === item._id ? 'option-select' : 'option-tab'}>
                                    <div className="col-6 col-sm-6 col-md-6 img-prd-cent">
                                      <ImageDef
                                        name={item.nombre}
                                        size="lg"
                                        imgProd={item.imagen && item.imagen[0] && item.imagen[0].url ? item.imagen[0].url : proddef}
                                      />
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                      <label className="text-nameP">{item.nombre}</label>
                                      {
                                        activeNav === 'Uso Inmediato'
                                          ? (
                                            <>
                                              {
                                                !item?.descuentos?.usoInmediato?.descuentoUsoInmediato
                                                  ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                                  : (
                                                    <>
                                                      <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precio))}</label>
                                                      <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.usoInmediato.descuentoUsoInmediato))}</label>
                                                    </>
                                                  )
                                              }
                                            </>
                                          )
                                          : (
                                            <>
                                              {
                                                !item?.descuentos?.prevision?.descuentoPrevision
                                                  ? <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                                  : (
                                                    <>
                                                      <label className="text-precio left suby text-money">{styleMoney(preFormatMoney(item.precioPrevision))}</label>
                                                      <label className="text-precio left text-money">{styleMoney(preFormatMoney(item.descuentos.prevision.descuentoPrevision))}</label>
                                                    </>
                                                  )
                                              }
                                            </>
                                          )
                                      }
                                    </div>
                                  </div>
                                  <div className="line-spacing" />
                                </div>
                              </div>
                            ))
                          }
                        </div>
                        <div className="col col-sm-12 col-md-2"> </div>
                        <div className="col-12">
                          {selectedP && counter > 0 && (
                            <div className="container-prd-number">
                              <button
                                id="decrement"
                                className="button-number"
                                onClick={() => (counter > 1 ? setCounter(counter - 1) : setCounter(1))}
                                disabled={counter <= 0}
                              >
                                -
                              </button>
                              <span className="input-number">
                                {counter}
                              </span>
                              <button
                                id="increment"
                                className="button-number"
                                onClick={() => setCounter(counter + 1)}
                              >
                                +
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="col-12 p-r cont-btn">
                          <button className="button navR-content-btn" onClick={() => addProductL()} type="button" disabled={!selectedP ? 'disabled' : ''}>
                            Agregar
                          </button>
                        </div>
                        <div className="col-12 spacing-nav"> </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProspectMProduct;
