import { Routes, Route } from 'react-router';
import AddClients from '../pages/clients/AddClients';
import Clients from '../pages/clients/clients';
import Products from '../pages/products/products';
import BranchOffice from '../pages/branchOffice/branchOffice';
import AddBranches from '../pages/branchOffice/addBranches';
import ContractsClientsPage from '../pages/clients/ContractsClientsPage';
import HomePage from '../pages/home/home';
import WelcomePage from '../pages/home/welcome';
import AddProspects from '../pages/prospects/AddProspects';
import TabNotifications from '../pages/notifications/tabNotifications';
import ProfileEmployee from '../pages/profileEmployee';
import Prospects from '../pages/prospects/Prospects';
import DetailProspectPage from '../pages/prospects/detailProspectPage';
import Employees from '../pages/employees/Employees';
import AddEmployees from '../pages/employees/AddEmployees';
import EmployeeDetailsPage from '../pages/employees/EmployeeDetails';
import ViewContract from '../pages/contract/viewContract';
import AddProduct from '../pages/products/addProduct';
import AddBeneficiaries from '../pages/contract/addBeneficiaries';
import EditBeneficiarie from '../pages/contract/editBeneficiarie';
import ChangePriorityBenficiarie from '../pages/contract/changePriorityBenficiarie';
import AddSeguimProspectView from '../pages/prospects/addSeguimProspectView';
import Promotions from '../pages/promotions';
import AddCoupon from '../pages/promotions/addCoupon';
import ListContractPage from '../pages/contract/listContract';
import NotAllow from '../features/shared/routeGuards/notAllow';
import { checkPermisionRoute } from '../features/shared/routeGuards/checkComponentPermission';

const DashboardRouter = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route exact path="/profile/:id" element={<ProfileEmployee />} />
    <Route exact path="/my-notifications" element={<TabNotifications />} />
    <Route path="/welcome" element={<WelcomePage />} />
    <Route path="/coupons/:id" element={checkPermisionRoute('', '') ? <Promotions /> : <NotAllow />} />
    <Route path="/complete-prospect/:id" element={checkPermisionRoute('Prospectos', 'consultarProspecto') ? <AddProspects /> : <NotAllow />} />
    <Route path="/products" element={checkPermisionRoute('Productos', 'listarProductos') ? <Products /> : <NotAllow />} />
    <Route path="/products/add" element={checkPermisionRoute('Productos', 'crearProducto') ? <AddProduct /> : <NotAllow />} />
    <Route path="/branchOffice" element={checkPermisionRoute('Sucursales', 'listarSucursales') ? <BranchOffice /> : <NotAllow />} />
    <Route path="/add-Branches" element={checkPermisionRoute('Sucursales', 'crearSucursal') ? <AddBranches /> : <NotAllow />} />
    <Route path="/add-coupon" element={checkPermisionRoute('Descuentos', 'crearDescuento') ? <AddCoupon /> : <NotAllow />} />
    <Route
      path="/promotions"
      element={(checkPermisionRoute('Cupones', 'listarCupones') || checkPermisionRoute('Descuentos', 'listarDescuentos')) ? <Promotions /> : <NotAllow />}
    />
    <Route path="/add-prospects" element={checkPermisionRoute('Prospectos', 'crearProspecto') ? <AddProspects /> : <NotAllow />} />
    <Route path="/prospects" element={checkPermisionRoute('Prospectos', 'listarProspectos') ? <Prospects /> : <NotAllow />} />
    <Route exact path="/prospects/:id" element={checkPermisionRoute('Prospectos', 'consultarProspecto') ? <DetailProspectPage /> : <NotAllow />} />
    <Route exact path="/prospects/:idP/seguimiento" element={checkPermisionRoute('Prospectos', 'agendarSeguimiento') ? <AddSeguimProspectView /> : <NotAllow />} />
    <Route path="/contributors" element={checkPermisionRoute('Empleados', 'listarEmpleados') ? <Employees /> : <NotAllow />} />
    <Route path="/add-contributors" element={checkPermisionRoute('Empleados', 'crearEmpleado') ? <AddEmployees /> : <NotAllow />} />
    <Route exact path="/contributors/:id" element={checkPermisionRoute('Empleados', 'consultarEmpleado') ? <EmployeeDetailsPage /> : <NotAllow />} />
    <Route path="/clients" element={checkPermisionRoute('Clientes', 'listarClientes') ? <Clients /> : <NotAllow />} />
    <Route path="/add-clients" element={checkPermisionRoute('Clientes', 'crearCliente') ? <AddClients /> : <NotAllow />} />
    <Route exact path="/clients/contract-clients/:id" element={checkPermisionRoute('Clientes', 'consultarCliente') ? <ContractsClientsPage /> : <NotAllow />} />
    <Route path="/contracts" element={checkPermisionRoute('Contratos', 'listarContratos') ? <ListContractPage /> : <NotAllow />} />
    <Route exact path="/clients/contract-view/:id" element={checkPermisionRoute('Contratos', 'consultarContrato') ? <ViewContract /> : <NotAllow />} />
    <Route
      exact
      path="/clients/contract-view/:id/new-beneficiaries"
      element={(checkPermisionRoute('Clientes', 'agregarBeneficiariosContrato') || checkPermisionRoute('Clientes', 'agregarBeneficiariosContratoValidado')) ? <AddBeneficiaries /> : <NotAllow />}
    />
    <Route exact path="/clients/contract-view/:idC/edit-beneficiarie/:idB" element={checkPermisionRoute('Clientes', 'consultarBeneficiarioContrato') ? <EditBeneficiarie /> : <NotAllow />} />
    <Route
      exact
      path="/clients/contract-view/:idC/change-priority"
      element={(checkPermisionRoute('Clientes', 'actualizarPrioridadBeneficiario') || checkPermisionRoute('Clientes', 'actualizarPrioridadBeneficiarioValidado')) ? <ChangePriorityBenficiarie /> : <NotAllow />}
    />
  </Routes>
);

export default DashboardRouter;
