import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { StepP1 } from '../../formik/formik';
import categoryProd from '../../../../global/data/typeProducts.json';
import { actualizarProducto } from '../../services';

const UpdateNameForm = ({ initialData, onClose, refreshData }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    values,
    handleChange,
    setFieldValue,
    isValid,
    errors,
  } = StepP1();

  const { titulo, categoria, descripcion } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      setSubmitting(false);
      return;
    }
    try {
      setSubmitting(true);
      const resp = await actualizarProducto(initialData._id, {
        nombre: titulo,
        categoria,
        descripcionPropiedad: descripcion
      });
      if (resp.errors) {
        throw Error('');
      }
      toast('Producto actualizado.', {
        className: 'message',
      });
      setSubmitting(false);
      refreshData();
      onClose();
    } catch (err) {
      toast('Ha ocurrido un error, intente de nuevo más tarde.', {
        className: 'message error',
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (initialData) {
      setFieldValue('titulo', initialData.nombre);
      setFieldValue('categoria', initialData.categoria || 'servicios');
      setFieldValue('descripcion', initialData.descripcionPropiedad);
    }
  }, [initialData]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <h2>Información general</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <label htmlFor="titulo" className="label mt-m">
              Título
              <input
                id="titulo"
                type="text"
                className={`input ${(isSubmitting && errors.titulo) && 'has-error'}`}
                name="titulo"
                value={titulo}
                onChange={handleChange('titulo')}
              />
              {isSubmitting && errors.titulo && (
                <small className="caption-error">
                  {errors.titulo}
                </small>
              )}
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <label htmlFor="categoria" className="label mt-m">
              Categoría
              <select
                id="categoria"
                type="select"
                className={`input ${(isSubmitting && errors.categoria) && 'has-error'}`}
                value={categoria}
                name="categoria"
                onChange={handleChange('categoria')}
              >
                <option value="">Selecciona una opción</option>
                {categoryProd.map((_option) => (
                  <option key={_option.value} value={_option.value}>
                    {_option.label}
                  </option>
                ))}
              </select>
              {isSubmitting && errors.categoria && (
                <small className="caption-error">
                  {errors.categoria}
                </small>
              )}
            </label>
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            <label htmlFor="descripcion" className="label mt-m">
              Descripción
              <textarea
                type="text"
                className="input sizein"
                value={descripcion}
                onChange={handleChange('descripcion')}
              />
              {isSubmitting && errors.descripcion && (
                <small className="caption-error">
                  {errors.descripcion}
                </small>
              )}
            </label>
          </div>
        </div>
        <div className="row mt-m">
          <div className="col-12">
            <button
              type="button"
              className="button-secondary p-r m-btn-prof"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              disabled={!isValid || isSubmitting}
              type="submit"
              className="button"
            >
              Guardar cambios
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateNameForm;
