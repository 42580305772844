import axios from '../../../store/axios';

export const getEmployeeByID = async (idClient) => {
  try {
    const res = await axios().get(`empleados/buscar/${idClient}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const uploadFile = async (file, pathI) => {
  try {
    const form = new FormData();
    form.append('path', pathI);
    form.append('file', file);
    form.append('ref', 'empleados');
    const res = await axios().post('aws', form);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateImgEmployee = async (idEmployee, file) => {
  try {
    const form = new FormData();
    form.append('file', file);
    const res = await axios().put(`empleados/profile/image/${idEmployee}`, form);
    return res;
  } catch (err) {
    return err;
  }
};

export const updatePasswEmployee = async (idEmployee, params) => {
  try {
    const res = await axios().patch(`empleados/contrasena/${idEmployee}`, params);
    return res;
  } catch (err) {
    return err;
  }
};
