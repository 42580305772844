import Auth from './boilerplates/auth';

export const {
  reducer: auth,
  login,
  resetAuthStatus,
  refreshUser,
  logoutUser,
  updatePassword,
} = Auth('auth');
