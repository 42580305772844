import React, { useEffect, useState } from 'react';

const ApplicationStep = ({
  onClose,
  statusOp,
  nextStep,
  discountType,
  setDiscountType,
}) => {
  const [statusWNav, setStatusWNav] = useState(false);

  const handleSubmit = () => {
    nextStep();
  };

  useEffect(() => {
    if (statusOp) {
      setStatusWNav(true);
    } else {
      setStatusWNav(false);
    }
  }, [statusOp]);

  return (
    <div className="container-fluid navBar-right">
      <div className="row">
        <div className={`col-1 col-md-1 col-lg-8 content-void ${statusWNav ? 'content-mov-void' : ''}`}> </div>
        <div className={`col-11 col-md-11 col-lg-4 content-navR ${statusWNav ? 'content-mov-in' : ''}`}>
          <div className="container-fluid side-form-container">
            <div className="row">
              <div className="col-12 close-form">
                <span
                  className="icon-close cross-line"
                  onClick={onClose}
                >
                  {' '}
                </span>
              </div>
              <div className="col-12">
                <div className="spacing-nav"> </div>
                <div className="container-fluid">
                  <div className="row content-nav-padd">
                    <div className="col-12">
                      <h2>Aplica para</h2>
                      <br />
                      <small>Este descuento es aplicable a</small>
                      <br />
                      <br />
                    </div>
                    <div className="col-12">
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="allSucursal"
                          id="allSucursal"
                          checked={discountType === 'Todos'}
                          onChange={() => setDiscountType('Todos')}
                        />
                        <label className="form-check-label" htmlFor="allSucursal">
                          Todas las sucursales
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="specificSucursal"
                          id="specificSucursal"
                          checked={discountType === 'Sucursal'}
                          onChange={() => setDiscountType('Sucursal')}
                        />
                        <label className="form-check-label" htmlFor="specificSucursal">
                          Sucursales específicas
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="specificProduct"
                          id="specificProduct"
                          checked={discountType === 'Productos'}
                          onChange={() => setDiscountType('Productos')}
                        />
                        <label className="form-check-label" htmlFor="specificProduct">
                          Productos específicos
                        </label>
                      </div>
                    </div>
                    <div className="col-12 spacing-nav" />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="form-bottom-container">
            <button
              className="button navR-content-btn"
              onClick={handleSubmit}
              type="button"
              disabled={!discountType}
            >
              Continuar
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ApplicationStep;
