import parse from 'html-react-parser';
import { Link, useNavigate } from 'react-router-dom';
import '../menu.css';

const ListNotifications = ({ listNotif, markAsRead }) => {
  const navigate = useNavigate();

  const checkTypeN = (item) => {
    if (item === 'agenda') {
      return parse('&#59681;');
    }
    if (item === 'pago') {
      return parse('&#59682;');
    }
    if (item === 'directorio') {
      return parse('&#59676;');
    }
    if (item === 'Registro') {
      return parse('&#59650;');
    }
    if (item === 'Desactivacion') {
      return parse('&#59691;');
    }
    return parse('&nbsp;');
  };

  const checkRead = (item) => {
    if (!item) {
      return parse('&#59666;');
    }
    return parse('&nbsp;');
  };
  const formatDateTime = (date) => {
    try {
      const newDate = new Date(date);
      const nowDate = new Date();
      const diffTime = Math.abs(nowDate - newDate);
      const timeDiffH = Math.ceil(diffTime / (1000 * 60 * 60));
      if (timeDiffH < 24) {
        if (timeDiffH < 2) {
          return 'Hace unos minutos.';
        }
        return `Hace ${timeDiffH} horas.`;
      }

      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      let currentHours = newDate.getHours();
      currentHours = (`0${currentHours}`).slice(-2);
      let currentMinut = newDate.getMinutes();
      currentMinut = (`0${currentMinut}`).slice(-2);
      const tempHM = `${currentHours}:${currentMinut}`;
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${meses[newDate.getMonth()]} ${tempDate.substr(tempDate.indexOf(' ') + 1)} · ${tempHM}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  };

  const checkNotification = async (item) => {
    const optionNotf = {
      notifications: [item._id]
    };
    if (!item.read) {
      await markAsRead(optionNotf);
    }
    if (item?.link !== '') {
      navigate(item.link);
    }
  };

  return (
    <div className="content-prof-notif">
      {
        listNotif && listNotif.length > 0 && listNotif.map((item) => (
          <div className="prof-notif" key={item._id} onClick={() => checkNotification(item)}>
            <i>{checkTypeN(item.type)}</i>
            <div>
              <label>{item.message}</label>
              <small>{formatDateTime(item.time)}</small>
            </div>
            <u>{checkRead(item.read)}</u>
          </div>
        ))
      }
      <div className="prof-notif-more">
        <Link to="/my-notifications">Ver todas</Link>
      </div>
    </div>
  );
};

export default ListNotifications;
