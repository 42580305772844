import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import 'react-responsive-modal/styles.css';
import './design.css';
import Toggle from '../../features/shared/toggle/Toggle';

const DesignSystem = () => {
  const [passwordType, setPasswordType] = useState('password');
  const [date, setDate] = useState('');
  const [genero, setGenero] = useState('masculino');

  const navigate = useNavigate();

  const onOpenModal = () => {
    navigate('/add-prospects');
  };

  const handlePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const eampleFormOpen = () => {
    navigate('/design-system/forms');
  };

  return (
    <div className="page-container">
      <section id="texts">
        <h1>Espaciado</h1>
        <p className="m-xxxs">Espaciado xxxs</p>
        <p className="m-xxs">Espaciado xxs</p>
        <p className="m-xs">Espaciado xs</p>
        <p className="m-s">Espaciado s</p>
        <p className="m-m">Espaciado m</p>
        <p className="m-l">Espaciado l</p>
        <p className="m-xl">Espaciado xl</p>
        <p className="m-xxl">Espaciado xxl</p>
        <p className="m-xxxl">Espaciado xxxl</p>
      </section>
      <hr />
      <hr />
      <section id="texts">
        <h1>Tipografía</h1>
        <h1>Encabezados</h1>
        <h2>Títulos</h2>
        <p>Textos bold</p>
        <p>Textos </p>
        <small className="small-bold">Leyendas bold</small>
        <br />
        <small className="small">Leyendas</small>
        <br />
      </section>
      <hr />
      <hr />
      <section>
        <h1>Colores</h1>
        <h2>Dark</h2>
        <div className="content-color-dark b100">b100</div>
        <div className="content-color-dark b50">b50</div>
        <div className="content-color-light b20">b20</div>
        <div className="content-color-light b05">b05</div>
        <br />
        <h2>Light</h2>
        <div className="content-color-light w100">w100</div>
        <div className="content-color-dark w40">w40</div>
        <div className="content-color-dark w30">w30</div>
        <div className="content-color-dark w20">w20</div>
        <br />
        <h2>Primary</h2>
        <div className="content-color-dark bluedark">Primary Default</div>
        <div className="content-color-dark bluelight">Primary Light</div>
        <br />
        <h2>Feedback</h2>
        <div className="content-color-dark green">Success Default</div>
        <div className="content-color-light greenlight">Success Light</div>
        <br />
        <h2>Information</h2>
        <div className="content-color-dark blueinf">Information Default</div>
        <div className="content-color-light blueinflight">Information Light</div>
        <br />
        <h2>Warning</h2>
        <div className="content-color-dark yellow">Warning Default</div>
        <div className="content-color-light yellowlight">Warning Light</div>
        <br />
        <h2>Error</h2>
        <div className="content-color-dark red">Error Default</div>
        <div className="content-color-light redlight">Error Light</div>
        <br />
      </section>
      <hr />
      <hr />
      <section>
        <h1>Formularios</h1>
        <h2>Container forms</h2>
        <button className="button m-m" type="button" onClick={() => eampleFormOpen()}>
          Open Form Example
        </button>
        <div className="block">
          <label htmlFor="sample" className="label">
            Caja de texto simple
            <input id="sample" type="text" className="input" placeholder="Caja de texto simple" />
          </label>

          <label htmlFor="new-input" className="label input-content">
            Caja de texto con icono
            <div className="input-container">
              <input id="new-input" type="text" className="input" placeholder="Ingresar texto" />
              <span className="input-icon user-list-line" />
            </div>
          </label>

          <label htmlFor="new-input" className="label input-content">
            Caja de texto con error
            <div className="input-container">
              <input id="new-input" type="text" className="input has-error" placeholder="Ingresar texto" />
              <span className="input-icon user-list-line" />
            </div>
            <small className="caption-error">Mensaje de error</small>
          </label>

          <label htmlFor="sample" className="label input-content">
            Caja de texto simple
            <input id="sample" type="text" className="input" disabled="disabled" placeholder="Caja de texto simple" />
          </label>

          <label htmlFor="pass" className="label input-content">
            Caja de texto tipo password
            <div className="input-container">
              <input id="pass" type={passwordType} className="input" placeholder="Ingresar password" />
              <span
                className={`input-icon ${passwordType === 'password' ? 'open-eye-line' : 'closed-eye-line'}`}
                onClick={handlePasswordType}
              >
                {' '}
              </span>
            </div>
          </label>

          <label htmlFor="sample" className="label mt-m">
            Fecha de nacimiento
            <input
              id="sample"
              type="date"
              className={`input input-date ${date ? 'date-input--has-value' : ''}`}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </label>

          <label className="label labels mt-m">
            Estado civil
            <select name="ec" type="select">
              <option value="" label="">Seleccione una opción</option>
              <option value="soltero" label="">Soltero (a) </option>
              <option value="casado" label="">Casado (a) </option>
            </select>
          </label>

          <label className="label labels mt-m">
            Género
            <br />
            <Toggle
              options={[
                { name: 'Masculino', value: 'masculino' },
                { name: 'Femenino', value: 'femenino' },
              ]}
              activeOp={genero}
              event={(value) => setGenero(value)}
            />
          </label>
        </div>
      </section>
      <hr />
      <hr />
      <section id="buttons">
        <h2>Botones</h2>
        <div className="">
          <div style={{ display: 'flex' }}>
            <button className="button m-m" type="button">
              Primary
            </button>
            <button className="button m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
              Primary icon
            </button>
            <button className="button m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
            </button>
          </div>

          <div style={{ display: 'flex' }}>
            <button disabled="disabled" className="button m-m" type="button">
              Primary
            </button>
            <button disabled="disabled" className="button m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
              Primary icon
            </button>
            <button disabled="disabled" className="button m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
            </button>
          </div>

          <div style={{ display: 'flex' }}>
            <button className="button-secondary m-m" type="button">
              Secondary
            </button>
            <button className="button-secondary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
              Secondary icon
            </button>
            <button className="button-secondary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
            </button>
          </div>

          <div style={{ display: 'flex' }}>
            <button disabled="disabled" className="button-secondary m-m" type="button">
              Secondary
            </button>
            <button disabled="disabled" className="button-secondary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
              Secondary icon
            </button>
            <button disabled="disabled" className="button-secondary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
            </button>
          </div>

          <div style={{ display: 'flex' }}>
            <button className="button-tertiary m-m" type="button">
              Tertiary
            </button>
            <button className="button-tertiary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
              Tertiary icon
            </button>
            <button className="button-tertiary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
            </button>
          </div>

          <div style={{ display: 'flex' }}>
            <button disabled="disabled" className="button-tertiary m-m" type="button">
              Tertiary
            </button>
            <button disabled="disabled" className="button-tertiary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
              Tertiary icon
            </button>
            <button disabled="disabled" className="button-tertiary m-m" type="button">
              <span className="button-icon open-eye-line">
                {' '}
              </span>
            </button>
          </div>
        </div>

        <hr />
        <hr />
      </section>
      <section id="modals">
        <h2>Componentes fijos</h2>
        <div>
          <button className="button" onClick={() => onOpenModal()}>Agregar Prospecto</button>
        </div>
        <hr />
        <hr />
      </section>
    </div>
  );
};

export default DesignSystem;
