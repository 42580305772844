import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import './message.css';

const Message = ({
  img,
  type,
  title,
  content = '',
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  contentConfirmText,
  contentOnConfirm
}) => (
  <>
    <div className="d-flex justify-content-center align-items-center h-100">
      <Row className="justify-content-center align-items-center">
        <Col md={12}>
          {type === 'loading' && (
            <div className="alert-img">
              <LoadingIndicator />
            </div>
          )}
          {
            img && type !== 'loading' && (
              <div className="success-icon">
                <img src={img} alt="" className="alert-img" />
              </div>
            )
          }
          {type === 'success' && !img && (
            <div className="success-icon">
              <img src="/assets/img/alerts/check-green.webp" alt="" className="alert-img" />
            </div>
          )}
          {/* {type === 'error' && !img && (
            <div className="success-icon">
              <img src="/assets/img/alerts/Incorrecto.png" alt="" className="alert-img" />
            </div>
          )} */}
          {type === 'alert' && !img && (
            <div className="success-icon">
              <img src="/assets/img/alerts/warning-yellow.webp" alt="" className="alert-img" />
            </div>
          )}
          <h2 className="alert-title">{title}</h2>
          {message && (
            <p className="alert-message">
              {message}
            </p>
          )}

          {content && (
            <p className="alert-message">
              {content}
              <button type="button" className="input-label-float-right button-tertiary ps-1" onClick={contentOnConfirm}>
                {contentConfirmText}
              </button>
            </p>
          )}

          <div className={`alert-footer ${onConfirm && onCancel ? 'alert-footer-space-between' : ''}`}>
            {onConfirm && (
              <button className="button" onClick={onConfirm}>{confirmText}</button>
            )}
            {onCancel && (
              <button className="button-tertiary m-t-l" onClick={onCancel}>
                {' '}
                {cancelText || 'Volver'}
              </button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  </>
);

Message.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  img: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  contentConfirmText: PropTypes.string,
  contentOnConfirm: PropTypes.func,
};

Message.defaultProps = {
  img: '',
  type: '',
  message: '',
  onConfirm: null,
  content: '',
  confirmText: 'Aceptar',
  contentConfirmText: '',
  contentOnConfirm: null,
};

export default Message;
