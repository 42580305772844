import '../products.css';

const ImageDef = ({ name, size, imgProd }) => {
  const checkText = (textL) => {
    try {
      let tempT = textL.match(/\b(\w)/g);
      tempT = tempT.join('').toUpperCase();
      tempT = tempT.slice(0, 2);
      return tempT;
    } catch (error) {
      return '';
    }
  };
  return (
    <div className={`prodefault ${size}`}>
      {!imgProd && (<label>{checkText(name)}</label>)}
      {imgProd && (<img src={imgProd} alt={'Not \n Found'} />)}
    </div>
  );
};

export default ImageDef;
