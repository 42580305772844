/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuP from './components/menu';
import { getEmployeeByID } from './services/index';
import GeneralP from './components/general';
import PasswordP from './components/password';
import ViewPhotoComp from './components/viewPhoto';
import ViewPAsswComp from './components/viewUpdatePass';
import './profile.css';

const ProfileEmployeeComp = ({ idEmployee }) => {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [activeM, setActiveM] = useState(1);
  const [NameC, setNameC] = useState('');
  const [openView, setOpenView] = useState(false);
  const [openViewPass, setOpenViewPass] = useState(false);

  const eventMenu = (option) => {
    setActiveM(option);
  };
  const closeSession = () => {
    localStorage.clear();
    navigate('/login');
    window.location.reload(false);
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    let year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };

  const getEmployeeInfo = async () => {
    await getEmployeeByID(idEmployee).then((data) => {
      if (data && data[0]) {
        if (data[0].fecha_nacimiento) {
          const tempD = new Date(data[0].fecha_nacimiento);
          data[0].fecha_nacimiento = formatDate(tempD);
        }
        if (data[0].updatedPassAt) {
          const tempDU = new Date(data[0].updatedAt);
          data[0].updatedPassAt = formatDate(tempDU);
        }
        setEmployee(data[0]);
        let nameUser = `${data[0].nombre || ''} ${data[0].apellidoPaterno || ''} ${data[0].apellidoMaterno || ''}`;
        setNameC(nameUser);
      } else {
        setEmployee(null);
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token').toString();
    if (token) {
      getEmployeeInfo();
    } else {
      closeSession();
    }
  }, []);

  return (
    <>
      {openView && (
        <ViewPhotoComp
          eventV={setOpenView}
          NameC={NameC}
          data={employee}
          id={idEmployee}
        />
      )}
      {openViewPass && (<ViewPAsswComp eventV={setOpenViewPass} id={idEmployee} />)}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-5 col-lg-3">
            <MenuP eventM={eventMenu} activeM={activeM} />
          </div>
          <div className="col-12 col-md-1">  </div>
          {employee && activeM === 1 && (
            <GeneralP
              NameC={NameC}
              data={employee}
              eventV={setOpenView}
            />
          )}
          {employee && activeM === 2 && <PasswordP data={employee} eventV={setOpenViewPass} />}
        </div>
      </div>
    </>
  );
};

export default ProfileEmployeeComp;
