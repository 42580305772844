/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import LoadingIndicator from '../../shared/loadingIndicator/LoadingIndicator';

const ConfirmSaveView = ({
  eventV, option,
}) => {
  const [confirmAction, setConfirmAction] = useState(false);

  const continueActionn = () => {
    option(true);
    setConfirmAction(true);
  };

  useEffect(() => { }, []);

  return (
    <div className="col-12 justify-content-center item-c-contr content-alert">
      {!confirmAction && (
      <>
        <div className="col-12 ">
          <label className="icon-content alert">&#59668;</label>
        </div>
        <div className="col-12 ">
          <p>¿Esta seguro de cotinuar?</p>
        </div>
        <div className="col-12">
          <button type="button" onClick={() => eventV(false)} className="button-secondary p-r m-btn-prof">
            NO
          </button>
          <button className="button mt-m" type="button" onClick={() => continueActionn()}>
            SI
          </button>
        </div>
      </>
      )}
      {
        confirmAction && <LoadingIndicator />
      }
    </div>
  );
};

export default ConfirmSaveView;
