// const tempOpt = 'page=1&pagination=5&order=asc';
// const myNotif = await getMyNotifications(tempOpt);

import { useQuery } from 'react-query';
import { getMyNotificationsByPage } from '../services';

export const useMyNotifications = ({
  page,
  pagination,
  order,
  refetchInterval = false,
  onSuccess,
  onError,
}) => useQuery(
  ['notifications', 'all', {
    page, pagination, order,
  }],
  () => getMyNotificationsByPage({ page, pagination, order }),
  {
    placeholderData: { data: null, total: 0 },
    keepPreviousData: true,
    onSuccess,
    onError,
    refetchInterval,
  },
);
