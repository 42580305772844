const ExampleForm = () => (
  <div className="container-fluid">
    <div className="row justify-content-end">
      <div className="col-1 close-form">
        <span
          className="icon-close cross-line"
        >
          {' '}
        </span>
      </div>
    </div>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
          Conten t fd ddddd d ddd ddd dddd dd dd dd ddd
          dddddd d d
          dd ddd ddd ddd dd ddd dd d ddddddd dd
          ddd ddd d dd dd dd ddd dddd ddddd dddddd
          ddd ddd ddd ddd dddddd ddd ddddddd ddd dddd ddddd
        </div>
      </div>
    </div>
  </div>
);
export default ExampleForm;
