import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerSucursal, findCP_S } from './services';
import countriesData from '../../global/data/countries.json';
import formikSuc from './components/formik/formik';
import Message from '../shared/message/Message';

const AddBranchesF = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState('idle');
  const [localidades, setLocalidades] = useState([]);
  const [colonies, setColonies] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
  } = formikSuc();

  const {
    nombre,
    direccion,
    colonia,
    ciudad,
    estado,
    cp,
    url_map,
    telefono,
  } = values;

  const handleSubmit = async () => {
    let fecha = new Date();
    let fechahoy = new Date(
      fecha.getFullYear(),
      fecha.getMonth(),
      fecha.getDate()
    ).getTime();

    const sucursal = {
      nombre: values.nombre,
      direccion: values.direccion,
      colonia: values.colonia,
      ciudad: values.ciudad,
      estado: values.estado,
      cp: values.cp,
      fecha_creacion: fechahoy,
      url_map: values.url_map,
      telefono: values.telefono
    };
    setResponse('loading');
    setSubmitted(true);
    const createSucursal = await registerSucursal(sucursal);
    if (createSucursal && createSucursal._id) {
      setResponse('success');
    } else {
      setResponse('error');
    }
  };

  const branchOffice = () => {
    navigate('/branchOffice');
  };

  const getCPService = async () => {
    const cpS = `cp=${cp}`;
    const pag = '&paginacion=1';
    const pags = '&paginas=500';
    const tempQuery = `${cpS}${pag}${pags}`;
    await findCP_S(tempQuery).then((data) => {
      if (data && data.respuesta && data.respuesta.codigos_postales) {
        if (data.respuesta.codigos_postales.length === 1) {
          data.respuesta.codigos_postales.forEach((item) => {
            setColonies(null);
            setFieldValue('colonia', item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
        } else if (data.respuesta.codigos_postales.length > 1) {
          const tempCol = [];
          data.respuesta.codigos_postales.forEach((item) => {
            tempCol.push(item.asentamiento);
            setFieldValue('ciudad', item.municipio);
            setFieldValue('estado', item.estado);
          });
          setColonies(tempCol);
        } else {
          setColonies(null);
          setFieldValue('colonia', '');
          setFieldValue('ciudad', '');
          setFieldValue('estado', '');
        }
      }
    });
  };

  useEffect(() => {
    if (cp.toString() && cp.toString().length > 4) {
      getCPService();
    } else if (cp.toString() && cp.toString().length < 5) {
      setColonies(null);
      setFieldValue('colonia', '');
      setFieldValue('ciudad', '');
      setFieldValue('estado', '');
    }
  }, [cp]);

  useEffect(() => {
    if (estado) {
      const selectedEntity = countriesData.find((obj) => obj.states === estado);
      if (selectedEntity) {
        setLocalidades(selectedEntity.loc);
      }
    } else {
      setLocalidades([]);
      setFieldValue('ciudad', '');
    }
  }, [estado]);

  if (response !== 'idle') {
    return (
      <div className="login-container container-fluid">
        {response === 'loading' && (
          <Message
            type="loading"
            title="Cargando..."
            subtitle="Esta operación podría demorar algunos minutos. Por favor, espere."
          />
        )}
        {response === 'success' && (
          <Message
            type="success"
            title="Sucursal registrada"
            message="La sucursal se ha registrado exitosamente."
            onConfirm={() => navigate('/branchOffice')}
            confirmText="Salir"
          />
        )}
        {response === 'error' && (
          <Message
            type="error"
            title="Ha ocurrido un error"
            message="Vuelve a intenarlo más tarde"
            onConfirm={() => setResponse('idle')}
            confirmText="Volver"
          />
        )}
      </div>
    );
  }

  return (
    <div className="container">
      <div>
        <form className="" onSubmit={handleSubmit}>
          <h2>Nueva sucursal</h2>
          <div className="row">
            <div className="col-12 col-md-6">
              <label htmlFor="nombre" className="label mt-m">
                Nombre
                <input
                  id="nombre"
                  type="text"
                  className={`input ${submitted && errors.nombre && 'has-error'}`}
                  value={nombre}
                  name="nombre"
                  onChange={handleChange('nombre')}
                />
                {submitted && errors.nombre && (
                  <small className="caption-error">
                    {errors.nombre}
                  </small>
                )}
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="telefono" className="label mt-m">
                Teléfono
                <input
                  id="telefono"
                  type="text"
                  className={`input ${submitted && errors.telefono && 'has-error'}`}
                  value={telefono}
                  placeholder="10 digitos"
                  name="telefono"
                  onChange={handleChange('telefono')}
                />
                {submitted && errors.telefono && (
                  <small className="caption-error">
                    {errors.telefono}
                  </small>
                )}
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="cp" className="label mt-m">
                Código postal
                <input
                  id="cp"
                  type="number"
                  className={`input ${submitted && errors.cp && 'has-error'}`}
                  value={cp}
                  name="cp"
                  onChange={handleChange('cp')}
                />
                {submitted && errors.cp && (
                  <small className="caption-error">
                    {errors.cp}
                  </small>
                )}
              </label>
            </div>
            <div className="col-12 col-md-6"> </div>
            <div className="col-12 col-md-6">
              <label htmlFor="estado" className="label mt-m">
                Estado
                <select
                  id="estado"
                  type="select"
                  className={`input ${submitted && errors.estado && 'has-error'}`}
                  value={estado}
                  name="estado"
                  onChange={handleChange('estado')}
                >
                  <option value="">Selecciona una opción</option>
                  {countriesData.map(({ states }) => (
                    <option key={states} value={states}>
                      {states}
                    </option>
                  ))}
                </select>
                {
                  submitted && errors.estado && <small className="caption-error">{errors.estado}</small>
                }
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="ciudad" className="label mt-m">
                Localidad o Municipio
                <select
                  id="ciudad"
                  type="select"
                  className={`input ${submitted && errors.ciudad && 'has-error'}`}
                  value={ciudad}
                  name="ciudad"
                  onChange={handleChange('ciudad')}
                >
                  <option value="">Selecciona una opción</option>
                  {localidades.map((loc) => (
                    <option key={loc} value={loc}>
                      {loc}
                    </option>
                  ))}
                </select>
                {
                  submitted && errors.ciudad && <small className="caption-error">{errors.ciudad}</small>
                }
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="colonia" className="label mt-m ">
                Colonia
                {!colonies && (
                <input
                  id="colonia"
                  type="text"
                  className={`input ${submitted && errors.colonia && 'has-error'}`}
                  value={colonia}
                  name="colonia"
                  onChange={handleChange('colonia')}
                />
                )}
                {colonies && (
                  <select
                    type="select"
                    name="colonia"
                    className={`${submitted && errors.colonia && 'has-error'}`}
                    value={colonia}
                    onChange={handleChange('colonia')}
                  >
                    <option value="">-Seleccione la Colonia-</option>
                    {colonies.map((_colonia) => (
                      <option key={generateKey(_colonia)} name={_colonia} value={_colonia}>
                        {_colonia}
                      </option>
                    ))}
                  </select>
                )}
                {
                  submitted && errors.colonia && <small className="caption-error">{errors.colonia}</small>
                }
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="direccion" className="label mt-m ">
                Dirección
                <input
                  id="direccion"
                  type="text"
                  className={`input ${submitted && errors.direccion && 'has-error'}`}
                  value={direccion}
                  name="direccion"
                  onChange={handleChange('direccion')}
                />
                {
                  submitted && errors.direccion && <small className="caption-error">{errors.direccion}</small>
                }
              </label>
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="url_map" className="label mt-m ">
                URL de mapa
                <input
                  id="url_map"
                  type="text"
                  className={`input ${submitted && errors.url_map && 'has-error'}`}
                  value={url_map}
                  placeholder="https://"
                  name="url_map"
                  onChange={handleChange('url_map')}
                />
                {
                  submitted && errors.url_map && <small className="caption-error">{errors.url_map}</small>
                }
              </label>
            </div>
            <div className="col-12 col-md-12">
              <button type="button" className="button-secondary p-r m-btn-prof" onClick={branchOffice}>
                Cancelar
              </button>
              <button className="button mt-m" type="submit">
                Finalizar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <br />
      </div>
    </div>
  );
};

export default AddBranchesF;
