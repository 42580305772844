import { useFormik } from 'formik';
import * as Yup from 'yup';

export default () => useFormik({
  validateOnMount: true,
  initialValues: {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    direccion: '',
    colonia: '',
    ciudad: '',
    estado: '',
    cp: '',
    fecha_nacimiento: '',
    telefono: '',
    email: '',
    genero: '',
    entre_calles: '',
  },
  validationSchema: Yup.object({
    nombre: Yup.string()
      .required('El nombre es requerido'),
    apellidoPaterno: Yup.string()
      .required('El Apellido Paterno es requerido'),
    apellidoMaterno: Yup.string(),
    direccion: Yup.string(),
    colonia: Yup.string(),
    ciudad: Yup.string(),
    estado: Yup.string(),
    cp: Yup.string()
      .required('El Código postal es requerido')
      .matches(/^[0-9]+$/, 'El código postal debe contener solo números')
      .min(5, 'El código postal debe tener mínimo 5 caracteres')
      .max(5, 'El código postal debe tener maximo 5 caracteres'),
    fecha_nacimiento: Yup.string(),
    telefono: Yup.string()
      .required('El telefono es requerido'),
    email: Yup.string()
      .email('La dirección de correo no es válida'),
    genero: Yup.string(),
    entre_calles: Yup.string(),
  })
});
