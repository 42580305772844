import { useFormik } from 'formik';
import * as Yup from 'yup';

export const useCouponForm = ({
  codigo = '',
  descuento = '',
  minimo_compra = '',
  fecha_inicio = '',
  fecha_expiracion = '',
  expira = true,
}) => useFormik({
  validateOnMount: true,
  enableReinitialize: true,
  initialValues: {
    codigo,
    descuento,
    minimo_compra,
    fecha_inicio: fecha_inicio.split('T')[0],
    fecha_expiracion: fecha_expiracion.split('T')[0],
    expira,
  },
  validationSchema: Yup.object({
    codigo: Yup.string()
      .required('El código es requerido')
      .max(10, 'El código debe ser menor a 10 caracteres.'),
    descuento: Yup.number()
      .required('El valor del cupón es requerido.'),
    minimo_compra: Yup.number()
      .required('El mínimo de compra es requerido.')
      .min(Yup.ref('descuento'), 'El mínimo de compra debe ser mayor o igual que el valor del cupón.'),
    fecha_inicio: Yup.date()
      .required('La fecha de inicio es requerida.'),
    fecha_expiracion: Yup.date()
      .min(Yup.ref('fecha_inicio'), 'La fecha de expiración debe ser mayor a la fecha de inicio.')
      .when('expira', {
        is: (exp) => exp,
        then: Yup.date()
          .required('La fecha de expiración es requerida'),
        otherwise: Yup.date(),
      }),
  })
});
