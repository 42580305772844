import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FrmImgProduct from '../newProduct/frmImgP';
import {
  actualizarProducto, deleteFile, uploadFile
} from '../../services';

const UpdateImagesForm = ({ initialData, onClose, refreshData }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const deleteImages = async (urls) => {
    try {
      const promises = urls.map((url) => deleteFile(url));
      const resp = await Promise.all(promises);
      return resp;
    } catch (err) {
      return err;
    }
  };

  const uploadGallery = async (files) => {
    const promises = files.map((file) => uploadFile(file.img, `${initialData.categoria}/galeria`));
    const resp = await Promise.all(promises);
    return resp.map((obj) => obj.file);
  };

  const handleSubmit = async ({
    imgPortada,
    imgGaleria,
    imgEliminadas,
  }) => {
    try {
      setSubmitting(true);
      let urlPortada = '';
      if (imgPortada && typeof imgPortada !== 'string') {
        const resp = await uploadFile(imgPortada, initialData.categoria);
        if (!resp.ok) {
          throw Error('');
        }
        urlPortada = resp.file;
      }

      let galeria = imgGaleria.filter((item) => (typeof item.img === 'string' && item.img !== ''))
        .map((item) => item.img);
      const imagesToUpload = imgGaleria.filter((item) => (typeof item.img !== 'string'));

      let filesUrl = [];
      if (imagesToUpload.length > 0) {
        filesUrl = await uploadGallery(imagesToUpload);
      }
      galeria = [...galeria, ...filesUrl];

      const portada = {
        url: urlPortada,
        portada: true,
      };
      await actualizarProducto(initialData._id, {
        imagen: urlPortada ? [portada] : undefined,
        galeria,
      });

      if (imgEliminadas.length > 0) {
        await deleteImages(imgEliminadas);
      }

      toast('Imágenes actualizadas.', {
        className: 'message',
      });
      refreshData();
      onClose();
      setSubmitting(false);
    } catch (err) {
      toast('Ha ocurrido un error, intente de nuevo más tarde.', {
        className: 'message error',
      });
      setSubmitting(false);
    }
  };

  return (
    <FrmImgProduct
      initialData={initialData}
      isSubmitting={isSubmitting}
      onClose={onClose}
      saveImages={handleSubmit}
    />
  );
};

export default UpdateImagesForm;
