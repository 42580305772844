import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import permissions from './permisions.json';

const NotAllow = () => {
  const [linkDef, setLinkDef] = useState('/');

  const searchDefaultPage = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    permissions.forEach((element) => {
      if (element.rol === user.role) {
        if (element.puestos) {
          let puestoVal = false;
          element.puestos.forEach((item) => {
            if (user.puesto && item.puesto === user.puesto) {
              setLinkDef(item.default);
              puestoVal = true;
            }
          });
          if (!puestoVal) {
            setLinkDef(element.permissions[0]);
          }
        } else {
          setLinkDef(element.permissions[0]);
        }
      }
    });
  };

  useEffect(() => {
    searchDefaultPage();
  }, []);

  return (
    <div className="container">
      <div className="row ">
        <div className="cont-resp-add m-top-half">
          <div className="col-12 cont-icon-resp ">
            <label className="icon-info-resp back-circle">&#59685;</label>
          </div>
          <div className="col-12 col-md-12 cont-center">
            <h2>Página no encontrada</h2>
          </div>
          <div className="row ">
            <div className="col col-md-4">&nbsp;</div>
            <div className="col-12 col-md-4 cont-center m-top-xxxs">
              <label className="text-info">Lo sentimos, parece que no hemos podido encontrar la página que buscabas.</label>
            </div>
            <div className="col col-md-4">&nbsp;</div>
          </div>
          <div className="col-12 col-md-12 cont-center">
            <Link to={linkDef} className="button button-rep">
              Ir al inicio
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAllow;
