import { useState, useEffect } from 'react';
import Formik from '../formik/formik';
import { updatePasswEmployee } from '../services';
import '../profile.css';

const ViewPAsswComp = ({ eventV, id }) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [statusApi, setStatusApi] = useState(null);
  const [passwordType1, setPasswordType1] = useState('password');
  const [passwordType2, setPasswordType2] = useState('password');
  const [passwordType3, setPasswordType3] = useState('password');

  const {
    values,
    handleChange,
    isValid,
    dirty,
    errors
  } = Formik();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (isValid && dirty) {
      setStatus('loading');
      const tempParam = {
        passwordNew: values.newPassword,
        passwordOld: values.oldPassword,
      };
      setStatus('idle');
      await updatePasswEmployee(id, tempParam).then((resp) => {
        if (resp && resp._id) {
          eventV(false);
          window.location.reload(false);
        } else {
          setStatusApi('No se pudo actualizar, intente denuevo mas tarde.');
        }
      });
      // const res = await requestPasswordChange(email);
      // console.log(res);
      // setStatus(res.ok ? 'success' : 'error');
    }
  };

  const handlePasswordType1 = () => {
    setPasswordType1(passwordType1 === 'password' ? 'text' : 'password');
  };
  const handlePasswordType2 = () => {
    setPasswordType2(passwordType2 === 'password' ? 'text' : 'password');
  };
  const handlePasswordType3 = () => {
    setPasswordType3(passwordType3 === 'password' ? 'text' : 'password');
  };
  useEffect(() => {
    if (!isValid || !dirty) {
      setStatus('idle');
    } else {
      setStatus('');
      setStatusApi(null);
    }
  }, [values, isValid, dirty, errors]);

  const {
    oldPassword,
    newPassword,
    checkPassword,
  } = values;
  return (
    <div className="container-fluid content-full">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={() => eventV(false)}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-11 col-sm-12 col-md-12 col-lg-8 col-xl-6  ">
            <div className="content-buttom">&nbsp;</div>
            <div className="col-12 col-md-6 margin-filter">
              <h2>Actualizar contraseña</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="">
                <div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                      <label htmlFor="oldPassword" className="label mt-m">
                        Contraseña actual
                        <div className="input-container">
                          <input
                            id="oldPassword"
                            type={passwordType1}
                            className={`input ${submitted && errors.oldPassword && 'has-error'}`}
                            onChange={handleChange('oldPassword')}
                            value={oldPassword}
                          />
                          <span
                            className={`input-icon ${passwordType1 === 'password' ? 'open-eye-line' : 'closed-eye-line'}`}
                            onClick={handlePasswordType1}
                          >
                            {' '}
                          </span>
                        </div>
                        {
                          submitted && errors.oldPassword && <small className="caption-error">{errors.oldPassword}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6"> </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                      <label htmlFor="newPassword" className="label mt-m">
                        Nueva contraseña
                        <div className="input-container">
                          <input
                            id="newPassword"
                            type={passwordType2}
                            className={`input ${submitted && errors.newPassword && 'has-error'}`}
                            onChange={handleChange('newPassword')}
                            value={newPassword}
                          />
                          <span
                            className={`input-icon ${passwordType2 === 'password' ? 'open-eye-line' : 'closed-eye-line'}`}
                            onClick={handlePasswordType2}
                          >
                            {' '}
                          </span>
                        </div>
                        {
                          errors.newPassword && <small className="caption-error">{errors.newPassword}</small>
                        }
                      </label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <label htmlFor="checkPassword" className="label mt-m">
                        Confirmar nueva contraseña
                        <div className="input-container">
                          <input
                            id="checkPassword"
                            type={passwordType3}
                            className={`input ${submitted && errors.checkPassword && 'has-error'}`}
                            onChange={handleChange('checkPassword')}
                            value={checkPassword}
                          />
                          <span
                            className={`input-icon ${passwordType3 === 'password' ? 'open-eye-line' : 'closed-eye-line'}`}
                            onClick={handlePasswordType3}
                          >
                            {' '}
                          </span>
                        </div>
                        {
                          errors.checkPassword && <small className="caption-error">{errors.checkPassword}</small>
                        }
                      </label>
                    </div>
                  </div>
                  {statusApi && <small className="caption-error">{statusApi}</small>}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="col-12 col-md-12">
                    &nbsp;
                  </div>
                  <div className="col-12 col-md-12">
                    <button type="button" onClick={() => eventV(false)} className="button-secondary p-r m-btn-prof">
                      Cancelar
                    </button>
                    <button className="button mt-m" type="submit" disabled={status === 'idle' ? 'disabled' : ''}>
                      Guardar cambios
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPAsswComp;
