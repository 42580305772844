import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { StepP1 } from '../../formik/formik';
import { actualizarProducto } from '../../services';

const UpdateFeaturesForm = ({ initialData, onClose, refreshData }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [textCaract, setTextCaract] = useState('');

  const {
    values,
    errors,
    setFieldValue,
  } = StepP1();

  const { caracteristicas } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (errors.caracteristicas) {
      return;
    }
    try {
      setSubmitting(true);
      const resp = await actualizarProducto(initialData._id, {
        caracteristicas,
      });
      if (resp.errors) {
        throw Error('');
      }
      toast('Producto actualizado', {
        className: 'message',
      });
      setSubmitting(false);
      refreshData();
      onClose();
    } catch (err) {
      toast('Ha ocurrido un error, intente de nuevo más tarde.', {
        className: 'message error',
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (initialData) {
      if (initialData.caracteristicas) {
        const strValue = initialData.caracteristicas.join('\r\n');
        // setFieldValue('caracteristicas', strValue);
        setTextCaract(strValue);
      }
    }
  }, [initialData]);
  useEffect(() => {
    try {
      const result = textCaract.split(/\r?\n/);
      const tempCaract = [];
      result.forEach((element) => {
        const whitespaceRemoved = element.replace(/\s/g, '');
        if (whitespaceRemoved !== '') {
          tempCaract.push(element);
        }
      });
      setFieldValue('caracteristicas', tempCaract);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [textCaract]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-9">
          <h2>Características</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="col-12 col-sm-12 col-md-12">
          <label htmlFor="caracteristicas" className="label mt-m">
            <textarea
              id="caracteristicas"
              placeholder="Ingresa aquí las característica"
              className={`input textarea ${errors.caracteristicas && 'has-error'}`}
              name="caracteristicas"
              value={textCaract}
              onChange={(e) => setTextCaract(e.target.value)}
              rows="10"
            />
            {errors.caracteristicas && (
              <small className="caption-error">
                {errors.caracteristicas}
              </small>
            )}
          </label>
        </div>
        <div className="row mt-m">
          <div className="col-12">
            <button
              type="button"
              className="button-secondary p-r m-btn-prof"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              disabled={caracteristicas?.length === 0 || isSubmitting}
              type="submit"
              className="button"
            >
              Guardar cambios
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateFeaturesForm;
