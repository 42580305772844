import './toggle.css';

const Toggle = ({ options, activeOp, event }) => {
  const checkNav = (item, active) => {
    if (active === item.value) {
      return 'toggle-margin active';
    }
    return 'toggle-margin';
  };

  return (
    <div className="container">
      <div className="row toggle">
        {
          options && options.map((item) => (
            <div key={item.value} className="toggle-option">
              <button
                type="button"
                className={checkNav(item, activeOp)}
                onClick={() => event(item.value)}
              >
                {item.name}
              </button>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Toggle;
