import { ToastContainer } from 'react-toastify';
import ListProducts from '../../features/products/listProducts';
import GMenuForm from '../../features/menu';

const Products = () => (
  <>
    <GMenuForm />
    <div className="container">
      <div className="row align-items-center justify-content-center m-t-xxxl">
        <div className="col-12 col-md-12 col-lg-8">
          <ListProducts />
        </div>
      </div>
    </div>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </>
);

export default Products;
