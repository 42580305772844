/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { StepP2 } from '../../formik/formik';
import MessageInfo from '../../../messageInfo';

const FrmImgProduct = ({
  setDataForm,
  nextStep,
  prevStep,
  defVal,
  initialData,
  isSubmitting,
  saveImages,
  onClose,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('idle');
  const [statusFormInp, setStatusFormInp] = useState(true);
  const [statusBtnADd, setStatusBtnADd] = useState('');
  const [image, setImage] = useState(null);
  const [imageG, setImageG] = useState(null);
  const generateKey = (pre) => `${pre}_${new Date().getTime()}`;

  const editMode = useRef(false);
  const erasedImages = useRef([]);

  const {
    values,
    setFieldValue,
    isValid,
    errors,
  } = StepP2();

  const {
    imgPortada, imgGaleria, controlF
  } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (initialData) {
      return saveImages({
        imgPortada,
        imgEliminadas: erasedImages.current,
        imgGaleria,
      });
    }
    setSubmitted(true);
    // nextStep();
    if (imgPortada !== '' && imgGaleria.length > 1) {
      setDataForm(values);
      nextStep();
    } else {
      setStatus('');
    }
    return '';
  };

  const insertImageG = (imgInfo, inx, _update = false) => {
    setStatusFormInp(false);
    const tempG = imgGaleria;
    tempG[inx] = ({ img: imgInfo });
    if (tempG.length < 5 && !_update) {
      tempG.push({ img: '' });
    }
    setFieldValue('controlF', 0);
    setFieldValue('imgGaleria', tempG);
    const tempImg = [];
    tempG.forEach((_item) => {
      if (_item && _item.img && _item.img !== '') {
        tempImg.push(typeof _item.img === 'string' ? _item.img : URL.createObjectURL(_item.img));
      }
    });
    setImageG(tempImg);
    setTimeout(() => {
      setStatusFormInp(true);
      setFieldValue('controlF', tempG.length);
      if (tempG.length === 5) {
        if (tempG[4].img !== '') {
          setStatusBtnADd('disabled');
        } else {
          setStatusBtnADd('');
        }
      } else {
        setStatusBtnADd('');
      }
    }, 100);
  };

  const deleteImgG = (index) => {
    setStatusFormInp(false);
    const tempG = imgGaleria;
    const tempGImg = imageG;
    tempG.splice(index, 1);
    const deleted = tempGImg.splice(index, 1);

    const result = tempG.find(({ img }) => img === '');
    if (tempG.length < 5 && !result) {
      tempG.push({ img: '' });
    }
    if (!deleted[0].includes('blob')) {
      // imágenes descartadas para eliminar de AWS
      erasedImages.current.push(deleted[0]);
    }
    setFieldValue('imgGaleria', tempG);
    setFieldValue('controlF', tempG.length);
    setImageG(tempGImg);
    setTimeout(() => {
      setStatusFormInp(true);
      setStatusBtnADd('');
    }, 10);
  };

  const deletePort = () => {
    setImage(null);
    setFieldValue('imgPortada', '');
  };

  const initializeImages = () => {
    editMode.current = true;
    if (initialData.imagen && initialData.imagen.length !== 0) {
      setImage(initialData.imagen[0].url || '');
      setFieldValue('imgPortada', initialData.imagen[0].url);
    }
    if (initialData.galeria.length > 0) {
      setImageG(initialData.galeria);
      const imgArray = initialData.galeria.map((item) => ({ img: item }));
      setFieldValue('imgGaleria', [...imgArray, { img: '' }]);
    }
  };

  useEffect(() => {
    if (defVal) {
      setFieldValue('imgPortada', defVal.imgPortada);
      setFieldValue('imgGaleria', defVal.imgGaleria);
      setFieldValue('controlF', defVal.controlF);
      setImage(URL.createObjectURL(defVal.imgPortada));
      const tempImg = [];
      defVal.imgGaleria.forEach((_item) => {
        if (_item && _item.img && _item.img !== '') {
          tempImg.push(URL.createObjectURL(_item.img));
        }
      });
      setImageG(tempImg);
    }
  }, [defVal]);

  useEffect(() => {
    if (imgPortada === '' || imgGaleria.length <= 1) {
      setStatus('');
    } else {
      setStatus('idle');
    }
  }, [values]);

  useEffect(() => {
    if (!initialData) {
      return;
    }
    initializeImages();
  }, [initialData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="content-buttom">&nbsp;</div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-9">
          <h2>Imagen de portada</h2>
        </div>
        <div className="col-12 col-sm-12 col-md-3">
          <button className="button-secondary" type="button" onClick={() => document.querySelector('#imgPortada').click()}>
            &nbsp;&nbsp;Agregar&nbsp;&nbsp;
            <i className="btn-icon">&#59672;</i>
          </button>
        </div>
      </div>
      <div className="row">
        <br />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="p-r">
            <input
              type="file"
              name="imgPortada"
              id="imgPortada"
              className="input-img-file"
              onChange={(event) => {
                setFieldValue('imgPortada', event.target.files[0]);
                setImage(URL.createObjectURL(event.target.files[0]));
              }}
            />
            {!image && (
              <div className="span-img-file">
                <div className="container content-center content-product">
                  <div className="row">
                    <div className="col-12 cont-icon-info">
                      <label className="icon-info-client">&#59701;</label>
                    </div>
                    <div className="col-12">
                      <label className="text-title-info">Agrega o arrastra aquí tu imagen</label>
                    </div>
                    <div className="col col-md-2">&nbsp;</div>
                    <div className="col-12 col-md-8">
                      <label className="text-info">Los tipos de archivo admitidos son JPG y PNG. El ancho mínimo recomendado es de 780px.</label>
                    </div>
                    <div className="col col-md-2">&nbsp;</div>
                  </div>
                </div>
              </div>
            )}
            {image && (
              <div className="span-img-file">
                <img src={image} className="img-product-select" alt="" />
                <span className="btn-icon btn-drop" onClick={deletePort}>&#59697;</span>
              </div>
            )}
            {editMode.current && !image && (
              <small className="caption-error">
                {errors.imgPortada}
              </small>
            )}
          </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-9">
          <h2>Galería de imágenes</h2>
        </div>
        <div className="col-12 col-sm-12 col-md-3">
          {statusFormInp && (
            <button className="button-secondary" type="button" disabled={statusBtnADd} onClick={() => document.querySelector('#imgGaleria').click()}>
              &nbsp;&nbsp;Agregar&nbsp;&nbsp;
              <i className="btn-icon">&#59672;</i>
            </button>
          )}
        </div>
      </div>
      {imgGaleria
        && imgGaleria[imgGaleria.length - 1].img === ''
        && (
          <>
            <div className="row">
              <br />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="p-r">
                  <input
                    type="file"
                    name="imgGaleria"
                    id="imgGaleria"
                    className="input-img-file"
                    onChange={(event) => {
                      insertImageG(event.target.files[0], (imgGaleria.length - 1));
                    }}
                  />
                  {imgGaleria && imgGaleria[imgGaleria.length - 1].img === '' && (
                    <div className="span-img-file">
                      <div className="container content-center content-product">
                        <div className="row">
                          <div className="col-12 cont-icon-info">
                            <label className="icon-info-client">&#59701;</label>
                          </div>
                          <div className="col-12">
                            <label className="text-title-info">Agrega o arrastra aquí tu imagen</label>
                          </div>
                          <div className="col col-md-2">&nbsp;</div>
                          <div className="col-12 col-md-8">
                            <label className="text-info">Los tipos de archivo admitidos son JPG y PNG. El ancho mínimo recomendado es de 780px.</label>
                          </div>
                          <div className="col col-md-2">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {editMode.current && errors.imgGaleria && (
              <small className="caption-error">
                {errors.imgGaleria}
              </small>
            )}
          </>
        )}
      {statusFormInp && imgGaleria
        && imgGaleria.length > 1
        && imgGaleria.map((_item, index) => (
          <div className="row" key={generateKey(index)}>
            {_item.img !== '' && (
              <>
                <div className="content-buttom">&nbsp;</div>
                <div className="col-12">
                  <div className="p-r">
                    <input
                      type="file"
                      name="imgGaleriaR"
                      id="imgGaleriaR"
                      className="input-img-file"
                      onChange={(event) => {
                        insertImageG(event.target.files[0], (index), true);
                      }}
                    />
                    <div className="span-img-file">
                      {imageG && imageG[index] && (<img src={imageG[index]} className="img-product-select" alt="" />)}
                      <span className="btn-icon btn-drop" onClick={() => deleteImgG(index)}>&#59697;</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      <div className="row">
        <br />
      </div>
      <div className="row">
        <div className="col-12">
          <MessageInfo message="Puedes subir un máximo de cinco imágenes" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          {!initialData ? (
            <button className="input-label-float-right button-tertiary btn-step" onClick={prevStep}>
              Regresar
            </button>
          ) : (
            <button
              type="button"
              className="button-secondary btn-step"
              onClick={onClose}
            >
              Cancelar
            </button>
          )}
        </div>
        <div className="col-12 col-md-6">
          {!initialData ? (
            <button
              className="button btn-step"
              type="submit"
              disabled={status !== 'idle' ? 'disabled' : ''}
            >
              Siguiente paso
            </button>
          ) : (
            <button
              className="button btn-step"
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              Guardar cambios
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default FrmImgProduct;
