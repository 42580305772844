/* eslint-disable max-len */
import Accordion from 'react-bootstrap/Accordion';
import ProfileDefComponent from '../../../shared/profileDef';
import { monthDayYearFormat } from '../../../../helpers/dates';

const EmployeeInfoDetail = ({ data, permision }) => {
  /* const formatDateTime = (date) => {
    try {
      const newDate = new Date(date);
      const options = {
        month: 'long',
        day: 'numeric',
      };
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${meses[newDate.getMonth()]} ${tempDate.substr(tempDate.indexOf(' ') + 1)}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  }; */
  const changeTimezone = (date, ianatz) => {
    const invdate = new Date(date.toLocaleString('en-US', {
      timeZone: ianatz
    }));
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
  };
  const formatDateTimeHour = (date) => {
    try {
      const newDate = changeTimezone(new Date(date), 'UTC');
      const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
      let currentHours = newDate.getHours();
      const ampm = currentHours >= 12 ? 'pm' : 'am';
      currentHours %= 12;
      currentHours = currentHours || 12;
      currentHours = (`0${currentHours}`).slice(-2);
      let currentMinut = newDate.getMinutes();
      currentMinut = (`0${currentMinut}`).slice(-2);
      const tempHM = `${currentHours}:${currentMinut}`;
      let tempDate = newDate.toLocaleDateString('en-us', options);
      tempDate = `${tempHM} ${ampm}`;
      return tempDate;
    } catch (error) {
      return '--';
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col col-md-12 col-lg-2"> </div>
        <div className="col-12 col-md-12 col-lg-8 m-accordion">
          {data && (
            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Datos personales</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Nombre completo</label>
                      <label className="datos-info text-capitalize">
                        {data.nombre || ''}
                        {' '}
                        {data.apellidoPaterno || ''}
                        {' '}
                        {data.apellidoMaterno || ''}
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Género</label>
                      <label className="datos-info text-capitalize">{data.sexo || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Edad</label>
                      <label className="datos-info">{data.edad || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Fecha de nacimiento</label>
                      <label className="datos-info">{monthDayYearFormat(data.fecha_nacimiento) || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Estado civil</label>
                      <label className="datos-info text-capitalize">{data.estado_civil || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Ocupación</label>
                      <label className="datos-info text-capitalize">{data.puesto || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Teléfono</label>
                      <label className="datos-info">{data.telefono || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Correo electrónico</label>
                      <label className="datos-info">{data.email || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">RFC</label>
                      <label className="datos-info">{data.rfc || '-'}</label>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Domicilio</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Ubicación</label>
                      <label className="datos-info">
                        {data.ciudad || ''}
                        {', '}
                        {data.estado || ''}
                      </label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Código postal</label>
                      <label className="datos-info">{data.cp || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Dirección</label>
                      <label className="datos-info">{data.direccion || '-'}</label>
                    </div>
                    {/* <div className="col-12 col-md-6">
                    <label className="datos-title">Interior</label>
                    <label className="datos-info">{data.numInterior || '-'}</label>
                   </div> */ }
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Colonia</label>
                      <label className="datos-info">{data.colonia || '-'}</label>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="datos-title">Entre calles</label>
                      <label className="datos-info">{data.entreCalle || '-'}</label>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {permision('Empleados', 'verLogins') && (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Sistema</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <label className="datos-title">Último ingreso</label>
                        <label className="datos-info">
                          {data?.ultimo_ingreso ? monthDayYearFormat(data?.ultimo_ingreso) || '' : 'No hay registros disponibles'}
                          {' · '}
                          {data?.ultimo_ingreso ? formatDateTimeHour(data?.ultimo_ingreso) || '' : ''}
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="row mt-4">
                          {data.creadoPor && (
                            <div className="col-12 col-md-6">
                              <label className="datos-title">Creado por</label>
                              <div className="d-flex align-items-center mb-5">
                                <ProfileDefComponent
                                  name={data.creadoPor.nombre}
                                  size=""
                                  imgProf={data.creadoPor.imagen_perfil || '/assets/img/def-user.webp'}
                                />
                                <div className="ms-2">
                                  <label className="datos-info m-0">{data.creadoPor.nombre}</label>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-12 col-md-6">
                            <label className="datos-title">Fecha de creación</label>
                            <span className="datos-info">{monthDayYearFormat(data.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          )}
        </div>
        <div className="col col-md-12 col-lg-2"> </div>
      </div>
    </div>
  );
};

export default EmployeeInfoDetail;
