/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import FormStept1 from './steps/formStept1';
import FormStept2 from './steps/formStept2';

import '../../prospects.css';

const ConvertProspectView = ({ eventV, data, updateData }) => {
  const [checkedUno, setCheckedUno] = useState(true);
  const [checkedDos, setCheckedDos] = useState(false);
  const [dataForm1, setDataForm1] = useState(null);
  const [dataForm2, setDataForm2] = useState(null);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('uno');
  const [stateStep2, setStateStep2] = useState('disabled');
  const [step1, setStep1] = useState('&#59666;');
  const [step2, setStep2] = useState('&#59652;');

  const saveForm1 = (dataF) => {
    setDataForm1(dataF);
    updateData(dataF);
  };
  const saveForm2 = (dataF) => {
    setDataForm2(dataF);
    updateData(dataF);
  };

  const cambiarOpcionUno = () => {
    setOpcionSeleccionada('uno');
    setCheckedUno(!checkedUno);
    setCheckedDos(false);
    setStateStep2('active');
    setStep1('&#59654;');
    setStep2('&#59666;');
    window.scrollTo(0, 0);
  };

  const cambiarOpcionDos = () => {
    setOpcionSeleccionada('dos');
    setCheckedDos(!checkedDos);
    setCheckedUno(false);
    setStateStep2('complete');
    setStep1('&#59654;');
    setStep2('&#59666;');
    window.scrollTo(0, 0);
  };
  useEffect(() => {

  }, [data]);

  return (
    <div className="container-fluid content-full overflow-c">
      <div className="row justify-content-end">
        <div className="col-1 close-form">
          <span
            className="icon-close cross-line"
            onClick={() => eventV(false)}
          >
            {' '}
          </span>
        </div>
      </div>
      <div className="col-12 col-md-10 m-a">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
          <div className="col-12 col-sm-12 col-md-10 col-lg-6">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-4">
                <i className="step-prosp-icon">{parse(step1)}</i>
                <label className="step-prosp small-bold">Datos personales</label>
              </div>
              <div className={`col-12 col-md-4 ${stateStep2}`}>
                <i className="step-prosp-icon">{parse(step2)}</i>
                <label className="step-prosp small-bold">Referencias</label>
              </div>
              <div className="col-4"> </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
          <div className="col-12 col-sm-12 col-md-10 col-lg-6">
            {opcionSeleccionada === 'uno' ? (
              <FormStept1 cambiarOpcion={cambiarOpcionDos} saveData={saveForm1} opcionAnterior={eventV} dataP={data} />
            ) : opcionSeleccionada === 'dos' ? (
              <FormStept2 cambiarOpcion={cambiarOpcionDos} saveData={saveForm2} opcionAnterior={cambiarOpcionUno} dataP={data} />
            ) : (
              <> </>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-1 col-lg-3"> </div>
        </div>
      </div>
      <div className="content-buttom">&nbsp;</div>
    </div>
  );
};

export default ConvertProspectView;
