import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { usePriceForm } from '../../formik/formik';
import { actualizarProducto } from '../../services';
import { getDiscountById } from '../../../promotions/services';

const planesList = [
  {
    value: 'inmediata,prevision',
    label: 'Todos'
  },
  {
    value: 'inmediata',
    label: 'Uso Inmediato'
  },
  {
    value: 'prevision',
    label: 'Previsión'
  }
];

const UpdatePriceForm = ({ initialData, onClose, refreshData }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [percentageImmediate, setPercentageImmediate] = useState(0);
  const [percentagePrevision, setPercentagePrevision] = useState(0);

  const {
    values,
    isValid,
    errors,
    initialValues,
    setFieldValue,
    handleChange,
  } = usePriceForm({
    ...initialData,
    sucursal: initialData.sucursal.ciudad,
  });

  const { descuentos } = initialData;
  const {
    sucursal, stock, precio, precioPrevision, plan
  } = values;

  const descuentoUsoInmediato = precio - (precio * (percentageImmediate / 100));
  const descuentoPrevision = precio - (precio * (percentagePrevision / 100));

  const getDiscounts = async () => {
    if (descuentos?.usoInmediato) {
      const res = await getDiscountById(descuentos.usoInmediato.idDescuento);
      setPercentageImmediate(res[0].porcentaje);
    }
    if (descuentos?.prevision) {
      const res = await getDiscountById(descuentos.preivision.idDescuento);
      setPercentagePrevision(res[0].porcentaje);
    }
  };

  const changeNumValue = (field, value) => {
    setFieldValue(field, value ? parseFloat(value) : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid) {
      return;
    }
    try {
      setSubmitting(true);
      const usoInmediato = percentageImmediate ? {
        idDescuento: descuentos.usoInmediato.idDescuento,
        descuentoUsoInmediato,
      } : undefined;
      const prevision = percentagePrevision ? {
        idDescuento: descuentos.prevision.idDescuento,
        descuentoPrevision,
      } : undefined;

      const resp = await actualizarProducto(initialData._id, {
        // actualiza el stock solamente si el usuario modificó la cantidad
        stock: initialValues.stock !== stock ? stock.toString() : undefined,
        precio: plan !== 'prevision' ? precio : 0,
        precioPrevision: plan !== 'inmediata' ? precioPrevision : 0,
        disponibilidad: plan.split(','),
        descuentos: initialData.descuentos
          ? {
            usoInmediato,
            prevision,
          } : undefined,
      });
      if (resp.errors) {
        throw Error('');
      }
      toast('Producto actualizado.', {
        className: 'message',
      });
      setSubmitting(false);
      refreshData();
      onClose();
    } catch (err) {
      toast('Ha ocurrido un error, intente de nuevo más tarde.', {
        className: 'message error',
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (descuentos?.usoInmediato || descuentos?.prevision) {
      getDiscounts();
    }
  }, [descuentos]);

  useEffect(() => {
    if (!initialData) {
      return;
    }
    setFieldValue('plan', (precio && precioPrevision) ? 'todos' : precioPrevision ? 'prevision' : 'inmediata');
  }, [initialData]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h2>Información general</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="titulo" className="label mt-m">
              Sucursal
              <input
                id="titulo"
                type="text"
                className="input"
                name="titulo"
                value={sucursal}
                disabled
              />
            </label>
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="titulo" className="label mt-m">
              Unidades disponibles
              <input
                id="titulo"
                type="text"
                className="input"
                name="titulo"
                value={stock}
                onChange={handleChange('stock')}
              />
              {errors.stock && (
                <small className="caption-error">
                  {errors.stock}
                </small>
              )}
            </label>
          </div>
        </div>
        <div className="row mt-xxl">
          <div className="col-12">
            <h2>Planes y precios</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="planes" className="label mt-m">
              Planes disponibles
              <select
                id="planes"
                type="select"
                className={`input ${errors.sucursales && 'has-error'}`}
                name="planes"
                onChange={(e) => setFieldValue('plan', e.target.value)}
                value={plan}
                disabled={descuentos?.usoInmediato || descuentos?.prevision}
              >
                <option value="">Selecciona una opción</option>
                {planesList.map((_item) => (
                  <option key={_item.value} value={_item.value}>
                    {_item.label}
                  </option>
                ))}
              </select>
              {errors.sucursales && <small className="caption-error">{errors.sucursales}</small>}
            </label>
          </div>

          <div className="col-12 col-md-6" />

          <div className="col-12 col-md-6">
            {plan !== 'prevision' && (
              <label htmlFor="precio" className="label mt-m">
                Precio emergencia
                <CurrencyInput
                  id="precio"
                  name="precio"
                  placeholder=""
                  className={`input ${errors.precio && 'has-error'}`}
                  defaultValue={0}
                  decimalsLimit={2}
                  value={precio}
                  prefix="$"
                  pattern="[$]+[0-9]+([\.,][0-9]+)?"
                  onValueChange={(val) => changeNumValue('precio', val)}
                />
                {errors.precio && (
                  <small className="caption-error">
                    {errors.precio}
                  </small>
                )}
              </label>
            )}
          </div>

          <div className="col-12 col-md-6">
            {plan !== 'inmediata' && (
              <label htmlFor="precioPrevision" className="label mt-m">
                Precio a previsión
                <CurrencyInput
                  id="precioPrevision"
                  name="precioPrevision"
                  placeholder=""
                  className={`input ${errors.precioPrevision && 'has-error'}`}
                  defaultValue={0}
                  decimalsLimit={2}
                  value={precioPrevision}
                  prefix="$"
                  pattern="[$]+[0-9]+([\.,][0-9]+)?"
                  onValueChange={(val) => changeNumValue('precioPrevision', val)}
                />
                {errors.precioPrevision && (
                  <small className="caption-error">
                    {errors.precioPrevision}
                  </small>
                )}
              </label>
            )}
          </div>

        </div>

        <div style={{ display: percentageImmediate || percentagePrevision ? 'block' : 'none' }}>
          <div className="row mt-xxl">
            <div className="col-12">
              <h2>Precios con descuentos</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              {percentageImmediate ? (
                <label htmlFor="precioDescuento" className="label mt-m">
                  Precio emergencia
                  <CurrencyInput
                    id="precioDescuento"
                    name="precioDescuento"
                    placeholder=""
                    className="input"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={descuentoUsoInmediato}
                    prefix="$"
                    disabled
                    pattern="[$]+[0-9]+([\.,][0-9]+)?"
                  />
                </label>
              ) : 0}
            </div>

            <div className="col-12 col-md-6">
              {percentagePrevision ? (
                <label htmlFor="previsionDescuento" className="label mt-m">
                  Precio a previsión
                  <CurrencyInput
                    id="previsionDescuento"
                    name="previsionDescuento"
                    placeholder=""
                    className="input"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={descuentoPrevision}
                    prefix="$"
                    disabled
                    pattern="[$]+[0-9]+([\.,][0-9]+)?"
                  />
                </label>
              ) : <></>}
            </div>

          </div>
        </div>
        <div className="row mt-m">
          <div className="col-12">
            <button
              type="button"
              className="button-secondary p-r m-btn-prof"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              disabled={!isValid || isSubmitting}
              type="submit"
              className="button"
            >
              Guardar cambios
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdatePriceForm;
